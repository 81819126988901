/* tslint:disable */
/* eslint-disable */
import { Injectable } from '@angular/core';
import { HttpClient, HttpResponse } from '@angular/common/http';
import { BaseService } from '../base-service';
import { ApiConfiguration } from '../api-configuration';
import { StrictHttpResponse } from '../strict-http-response';
import { RequestBuilder } from '../request-builder';
import { Observable } from 'rxjs';
import { map, filter } from 'rxjs/operators';

import { AccessTokenResponse } from '../models/access-token-response';
import { AccountOpeningApplication } from '../models/account-opening-application';
import { AccountOpeningApplicationItem } from '../models/account-opening-application-item';
import { AccountOpeningApplicationRequest } from '../models/account-opening-application-request';
import { AccountOpeningApplicationValidation } from '../models/account-opening-application-validation';
import { AccountOpeningApplicationValidationRequest } from '../models/account-opening-application-validation-request';
import { ActionPlanItem } from '../models/action-plan-item';
import { ActionStep } from '../models/action-step';
import { AddLoanMaturityNotesRequest } from '../models/add-loan-maturity-notes-request';
import { AddMonthlyReviewRequest } from '../models/add-monthly-review-request';
import { AdjustCDs13MonthPromoRateRequest } from '../models/adjust-c-ds-13-month-promo-rate-request';
import { AdjustCDs17MonthPromoRateRequest } from '../models/adjust-c-ds-17-month-promo-rate-request';
import { AdjustCreditCardBalanceTransferFeeRateRequest } from '../models/adjust-credit-card-balance-transfer-fee-rate-request';
import { AdjustCreditCardIntroAprRateRequest } from '../models/adjust-credit-card-intro-apr-rate-request';
import { AdjustCreditCardVariableAprRateRequest } from '../models/adjust-credit-card-variable-apr-rate-request';
import { AdjustWsjPrimeRateRequest } from '../models/adjust-wsj-prime-rate-request';
import { AllDocumentsAssignedByManagerRequest } from '../models/all-documents-assigned-by-manager-request';
import { AllDocumentsAssignedForABusinessLineRequest } from '../models/all-documents-assigned-for-a-business-line-request';
import { AllMenuForMenuGroupRequest } from '../models/all-menu-for-menu-group-request';
import { AllNotificationGetRequest } from '../models/all-notification-get-request';
import { ApiBuildVersionInfo } from '../models/api-build-version-info';
import { ApiBuildVersionRequest } from '../models/api-build-version-request';
import { ApiLogFilter } from '../models/api-log-filter';
import { ApiLogInfo } from '../models/api-log-info';
import { ApplicationCreditCardPersonal } from '../models/application-credit-card-personal';
import { ApplicationCreditCardPersonalAuthorizedUserItem } from '../models/application-credit-card-personal-authorized-user-item';
import { ApplicationCreditCardPersonalAuthorizedUserRequest } from '../models/application-credit-card-personal-authorized-user-request';
import { ApplicationCreditCardPersonalItem } from '../models/application-credit-card-personal-item';
import { ApplicationDecisioning } from '../models/application-decisioning';
import { ApplicationDecisioningRequest } from '../models/application-decisioning-request';
import { ApplicationDecisioningStatus } from '../models/application-decisioning-status';
import { ApplicationDecisioningStatusUpdateRequest } from '../models/application-decisioning-status-update-request';
import { ApplicationFileSaveRequest } from '../models/application-file-save-request';
import { ApplicationReviewStatus } from '../models/application-review-status';
import { ApplicationStatus } from '../models/application-status';
import { ApplicationTestStatus } from '../models/application-test-status';
import { AssignReferralsToUserRequest } from '../models/assign-referrals-to-user-request';
import { AssignServiceDeskTicketToUserRequest } from '../models/assign-service-desk-ticket-to-user-request';
import { AvailableTimeSlotDay } from '../models/available-time-slot-day';
import { BodyDto } from '../models/body-dto';
import { BranchAdminServiceDeskCategoryType } from '../models/branch-admin-service-desk-category-type';
import { BranchAdminServiceDeskTicketRequest } from '../models/branch-admin-service-desk-ticket-request';
import { BranchAdminServiceItem } from '../models/branch-admin-service-item';
import { BranchItem } from '../models/branch-item';
import { BranchType } from '../models/branch-type';
import { CDs13MonthPromoRate } from '../models/c-ds-13-month-promo-rate';
import { CDs17MonthPromoRate } from '../models/c-ds-17-month-promo-rate';
import { CalendarEvent } from '../models/calendar-event';
import { CalendarEventListItem } from '../models/calendar-event-list-item';
import { CalendarEventListRequest } from '../models/calendar-event-list-request';
import { CalendarEventXAttendee } from '../models/calendar-event-x-attendee';
import { CalendarEventXAttendeeListItem } from '../models/calendar-event-x-attendee-list-item';
import { CalendarEventXAttendeeListRequest } from '../models/calendar-event-x-attendee-list-request';
import { CalendarListItem } from '../models/calendar-list-item';
import { CalendarListRequest } from '../models/calendar-list-request';
import { CalenderEvent } from '../models/calender-event';
import { CalenderEventRequest } from '../models/calender-event-request';
import { CampaignManagementDetails } from '../models/campaign-management-details';
import { CampaignManagementDropdownDetailsModel } from '../models/campaign-management-dropdown-details-model';
import { ChatAddRequest } from '../models/chat-add-request';
import { ChatMessageModel } from '../models/chat-message-model';
import { ChatMessageResponseModel } from '../models/chat-message-response-model';
import { ChatMessagesForUser } from '../models/chat-messages-for-user';
import { ChatModel } from '../models/chat-model';
import { ChatRequest } from '../models/chat-request';
import { CitizenshipType } from '../models/citizenship-type';
import { CollateralServiceDeskCategory } from '../models/collateral-service-desk-category';
import { CollateralServiceDeskItem } from '../models/collateral-service-desk-item';
import { CollateralServiceDeskLoanItem } from '../models/collateral-service-desk-loan-item';
import { CollateralServiceDeskStatus } from '../models/collateral-service-desk-status';
import { CollateralServiceDeskSubCategory } from '../models/collateral-service-desk-sub-category';
import { CollateralServiceDeskSubCategoryActionRequest } from '../models/collateral-service-desk-sub-category-action-request';
import { CollateralServiceDeskSubCategoryRequest } from '../models/collateral-service-desk-sub-category-request';
import { CollateralServiceDeskTicketRequest } from '../models/collateral-service-desk-ticket-request';
import { CommentAddRequest } from '../models/comment-add-request';
import { CommentListItem } from '../models/comment-list-item';
import { CommentUpdateRequest } from '../models/comment-update-request';
import { CompleteMonthlyReviewRequest } from '../models/complete-monthly-review-request';
import { ConfigureIncentiveRequestList } from '../models/configure-incentive-request-list';
import { ConnectedUserDetail } from '../models/connected-user-detail';
import { ConsumerOrCommercial } from '../models/consumer-or-commercial';
import { ConvertEncryptedZipS3FileRequest } from '../models/convert-encrypted-zip-s-3-file-request';
import { ConvertToEpsModel } from '../models/convert-to-eps-model';
import { CountryList } from '../models/country-list';
import { CreateEventRequest } from '../models/create-event-request';
import { CreateLinkTokenRequest } from '../models/create-link-token-request';
import { CreditCardBalanceTransferFeeRate } from '../models/credit-card-balance-transfer-fee-rate';
import { CreditCardIntroAprRate } from '../models/credit-card-intro-apr-rate';
import { CreditCardIntroAprTime } from '../models/credit-card-intro-apr-time';
import { CreditCardReferralItem } from '../models/credit-card-referral-item';
import { CreditCardReferralRequest } from '../models/credit-card-referral-request';
import { CreditCardVariableAprRate } from '../models/credit-card-variable-apr-rate';
import { CustomEntityGetRequest } from '../models/custom-entity-get-request';
import { CustomerAppointmentItem } from '../models/customer-appointment-item';
import { CustomerAppointmentMeetingPreferenceItem } from '../models/customer-appointment-meeting-preference-item';
import { CustomerAppointmentService } from '../models/customer-appointment-service';
import { CustomerAppointmentStaffPreferenceItem } from '../models/customer-appointment-staff-preference-item';
import { CustomerAppointmentUpdateRequest } from '../models/customer-appointment-update-request';
import { CustomerAppointmentVerificationItem } from '../models/customer-appointment-verification-item';
import { CustomerAppointmentVerificationRequest } from '../models/customer-appointment-verification-request';
import { DashboardLinkedMenu } from '../models/dashboard-linked-menu';
import { DashboardMenuReportOption } from '../models/dashboard-menu-report-option';
import { DashboardMenuReportRequest } from '../models/dashboard-menu-report-request';
import { DashboardModel } from '../models/dashboard-model';
import { DashboardUiModel } from '../models/dashboard-ui-model';
import { DashboardUpdateRequest } from '../models/dashboard-update-request';
import { DebitCardApplication } from '../models/debit-card-application';
import { DebitCardApplicationItem } from '../models/debit-card-application-item';
import { DebitCardApplicationRequest } from '../models/debit-card-application-request';
import { DeleteCommentRequest } from '../models/delete-comment-request';
import { DeleteRequest } from '../models/delete-request';
import { DepositAccountReferralItem } from '../models/deposit-account-referral-item';
import { DepositAccountReferralRequest } from '../models/deposit-account-referral-request';
import { DepositReferralAccountType } from '../models/deposit-referral-account-type';
import { DepositReferralSource } from '../models/deposit-referral-source';
import { DocumentAddRequest } from '../models/document-add-request';
import { DocumentGetRequest } from '../models/document-get-request';
import { DocumentOrderUpdateRequest } from '../models/document-order-update-request';
import { DocumentUpdateRequest } from '../models/document-update-request';
import { DomesticPrivateClientReferralItem } from '../models/domestic-private-client-referral-item';
import { DomesticPrivateClientReferralRequest } from '../models/domestic-private-client-referral-request';
import { EditOverDraftNoticeRequest } from '../models/edit-over-draft-notice-request';
import { EditS3FileNameRequest } from '../models/edit-s-3-file-name-request';
import { EmailApplicationLinkToUserRequest } from '../models/email-application-link-to-user-request';
import { EmployeeAnniversaryItem } from '../models/employee-anniversary-item';
import { EmployeeAnniversaryRequest } from '../models/employee-anniversary-request';
import { EmployeeBirthdayItem } from '../models/employee-birthday-item';
import { EmployeeBirthdayRequest } from '../models/employee-birthday-request';
import { EmployeeMobileNumberRequest } from '../models/employee-mobile-number-request';
import { EmployeePhotoFetchRequest } from '../models/employee-photo-fetch-request';
import { EmployeePhotoItem } from '../models/employee-photo-item';
import { EmployeeProfileRequest } from '../models/employee-profile-request';
import { EmployeeReferralItem } from '../models/employee-referral-item';
import { EmployeeReferralRequest } from '../models/employee-referral-request';
import { EmploymentStatus } from '../models/employment-status';
import { EmploymentType } from '../models/employment-type';
import { EmploymentTypeListRequest } from '../models/employment-type-list-request';
import { EntityGetRequest } from '../models/entity-get-request';
import { EntityReferralAttachmentUpdateRequest } from '../models/entity-referral-attachment-update-request';
import { EntityUpdateRequest } from '../models/entity-update-request';
import { EventIdentifier } from '../models/event-identifier';
import { FacilitiesServiceDeskCategoryType } from '../models/facilities-service-desk-category-type';
import { FacilitiesServiceDeskTicketRequest } from '../models/facilities-service-desk-ticket-request';
import { FacilitiesServiceDeskXVendorEmail } from '../models/facilities-service-desk-x-vendor-email';
import { FacilitiesServiceItem } from '../models/facilities-service-item';
import { FacilitiesVendorServiceDeskItem } from '../models/facilities-vendor-service-desk-item';
import { FeedbackRequest } from '../models/feedback-request';
import { FeedbackTypeItem } from '../models/feedback-type-item';
import { FetchBranchRequest } from '../models/fetch-branch-request';
import { FetchCustomerAppointmentManagerRequest } from '../models/fetch-customer-appointment-manager-request';
import { FetchCustomerAppointmentRequest } from '../models/fetch-customer-appointment-request';
import { FetchEmployeeRoleUserRequest } from '../models/fetch-employee-role-user-request';
import { FetchEntityRequest } from '../models/fetch-entity-request';
import { FetchInternalWikiModuleRequest } from '../models/fetch-internal-wiki-module-request';
import { FetchLoanMaturityRequest } from '../models/fetch-loan-maturity-request';
import { FetchLookupRequest } from '../models/fetch-lookup-request';
import { FetchMailchimpCampaignRequest } from '../models/fetch-mailchimp-campaign-request';
import { FetchMenuRequest } from '../models/fetch-menu-request';
import { FetchNotificationTopicModel } from '../models/fetch-notification-topic-model';
import { FetchReferralAttachmentRequest } from '../models/fetch-referral-attachment-request';
import { FetchReferralDisqualificationRequest } from '../models/fetch-referral-disqualification-request';
import { FetchReferralRequest } from '../models/fetch-referral-request';
import { FetchServiceDeskCategoryListRequest } from '../models/fetch-service-desk-category-list-request';
import { FetchServiceDeskRequest } from '../models/fetch-service-desk-request';
import { FetchToDoEventRequest } from '../models/fetch-to-do-event-request';
import { FetchToDoRequest } from '../models/fetch-to-do-request';
import { FetchTravelRequest } from '../models/fetch-travel-request';
import { FetchUserDetails } from '../models/fetch-user-details';
import { FetchVehicleLienRequest } from '../models/fetch-vehicle-lien-request';
import { FilteredRateGetRequest } from '../models/filtered-rate-get-request';
import { FxReferralAttachmentList } from '../models/fx-referral-attachment-list';
import { FxReferralItem } from '../models/fx-referral-item';
import { FxReferralMaterialDeleteRequest } from '../models/fx-referral-material-delete-request';
import { FxReferralMaterialRequest } from '../models/fx-referral-material-request';
import { FxReferralRequest } from '../models/fx-referral-request';
import { GenerateOtpRequest } from '../models/generate-otp-request';
import { GetCalenderRequest } from '../models/get-calender-request';
import { GetSessionRequest } from '../models/get-session-request';
import { GetUserIdentityVerificationRequest } from '../models/get-user-identity-verification-request';
import { GoldstarEmail } from '../models/goldstar-email';
import { GoldstarEmailRequest } from '../models/goldstar-email-request';
import { GoldstarEmailStatus } from '../models/goldstar-email-status';
import { GsJob } from '../models/gs-job';
import { GsJobCategory } from '../models/gs-job-category';
import { GsJobHistory } from '../models/gs-job-history';
import { GsJobHistoryRequest } from '../models/gs-job-history-request';
import { GsJobItem } from '../models/gs-job-item';
import { GsJobNotification } from '../models/gs-job-notification';
import { GsJobNotificationTemplate } from '../models/gs-job-notification-template';
import { GsJobNotificationTemplateType } from '../models/gs-job-notification-template-type';
import { GsJobParameterItem } from '../models/gs-job-parameter-item';
import { GsJobRequest } from '../models/gs-job-request';
import { GsJobResultType } from '../models/gs-job-result-type';
import { GsJobScheduleHolidayAdjustment } from '../models/gs-job-schedule-holiday-adjustment';
import { GsJobScheduleType } from '../models/gs-job-schedule-type';
import { GsJobType } from '../models/gs-job-type';
import { GsJobTypeDetail } from '../models/gs-job-type-detail';
import { GsJobTypeRequest } from '../models/gs-job-type-request';
import { GsParameterType } from '../models/gs-parameter-type';
import { GsScheduleDowType } from '../models/gs-schedule-dow-type';
import { HolidayItem } from '../models/holiday-item';
import { HomeOwnershipType } from '../models/home-ownership-type';
import { HomeOwnershipTypeListRequest } from '../models/home-ownership-type-list-request';
import { IdentityVerificationItemResponse } from '../models/identity-verification-item-response';
import { InheritedRoleListItem } from '../models/inherited-role-list-item';
import { InheritedRoleSaveList } from '../models/inherited-role-save-list';
import { InheritedRoleXEntityRequest } from '../models/inherited-role-x-entity-request';
import { InsuranceReferralAttachmentList } from '../models/insurance-referral-attachment-list';
import { InsuranceReferralItem } from '../models/insurance-referral-item';
import { InsuranceReferralMaterialDeleteRequest } from '../models/insurance-referral-material-delete-request';
import { InsuranceReferralMaterialRequest } from '../models/insurance-referral-material-request';
import { InsuranceReferralRequest } from '../models/insurance-referral-request';
import { InsuranceReferralSourceItem } from '../models/insurance-referral-source-item';
import { InternalPermission } from '../models/internal-permission';
import { InternalPermissionListItem } from '../models/internal-permission-list-item';
import { InternalPermissionTypeItem } from '../models/internal-permission-type-item';
import { InternalRecentMenuRequest } from '../models/internal-recent-menu-request';
import { InternalRegion } from '../models/internal-region';
import { InternalRole } from '../models/internal-role';
import { InternalRoleListItem } from '../models/internal-role-list-item';
import { InternalRoleXPermissionListItem } from '../models/internal-role-x-permission-list-item';
import { InternalRoleXPermissionSaveList } from '../models/internal-role-x-permission-save-list';
import { InternalRoleXTitleListItem } from '../models/internal-role-x-title-list-item';
import { InternalRoleXTitleSaveList } from '../models/internal-role-x-title-save-list';
import { InternalTitle } from '../models/internal-title';
import { InternalTitleListItem } from '../models/internal-title-list-item';
import { InternalTitlePermissionListItem } from '../models/internal-title-permission-list-item';
import { InternalTitlePermissionRequest } from '../models/internal-title-permission-request';
import { InternalToDoEvent } from '../models/internal-to-do-event';
import { InternalToDoEventModel } from '../models/internal-to-do-event-model';
import { InternalToDoEventTypeModel } from '../models/internal-to-do-event-type-model';
import { InternalToDoStatusModel } from '../models/internal-to-do-status-model';
import { InternalUser } from '../models/internal-user';
import { InternalUserItem } from '../models/internal-user-item';
import { InternalUserPermissionListItem } from '../models/internal-user-permission-list-item';
import { InternalUserPermissionRequest } from '../models/internal-user-permission-request';
import { InternalUserProfileRequest } from '../models/internal-user-profile-request';
import { InternalUserWikiFavoriteModel } from '../models/internal-user-wiki-favorite-model';
import { InternalUserXMenuRequest } from '../models/internal-user-x-menu-request';
import { InternalUserXRole } from '../models/internal-user-x-role';
import { InternalUserXRoleListItem } from '../models/internal-user-x-role-list-item';
import { InternalUserXRoleSaveList } from '../models/internal-user-x-role-save-list';
import { InternalWikiModuleXs3Video } from '../models/internal-wiki-module-xs-3-video';
import { InternalWikiUpdateResponseModel } from '../models/internal-wiki-update-response-model';
import { InternationalPrivateClientReferralItem } from '../models/international-private-client-referral-item';
import { InternationalPrivateClientReferralRequest } from '../models/international-private-client-referral-request';
import { IsUserWorkingElsewhere } from '../models/is-user-working-elsewhere';
import { IsUserWorkingElsewhereRequest } from '../models/is-user-working-elsewhere-request';
import { Job } from '../models/job';
import { JobItem } from '../models/job-item';
import { JobRequest } from '../models/job-request';
import { LasAccountRenewalStatusItem } from '../models/las-account-renewal-status-item';
import { LanguagePreference } from '../models/language-preference';
import { LanguagePreferenceItem } from '../models/language-preference-item';
import { LanguagePreferenceRequest } from '../models/language-preference-request';
import { LinkToken } from '../models/link-token';
import { ListUserIdentityVerificationRequest } from '../models/list-user-identity-verification-request';
import { LoanAccountRequest } from '../models/loan-account-request';
import { LoanCodAccountItem } from '../models/loan-cod-account-item';
import { LoanDdaAccountItem } from '../models/loan-dda-account-item';
import { LoanGuarantorItem } from '../models/loan-guarantor-item';
import { LoanInsuranceAccountItem } from '../models/loan-insurance-account-item';
import { LoanMaturityCommentItem } from '../models/loan-maturity-comment-item';
import { LoanMaturityCommentTypeItem } from '../models/loan-maturity-comment-type-item';
import { LoanMaturityItem } from '../models/loan-maturity-item';
import { LoanMortgageManagementAccountItem } from '../models/loan-mortgage-management-account-item';
import { LoanOfficerItem } from '../models/loan-officer-item';
import { LoanPurpose } from '../models/loan-purpose';
import { LoanReferralItem } from '../models/loan-referral-item';
import { LoanReferralRegion } from '../models/loan-referral-region';
import { LoanReferralSource } from '../models/loan-referral-source';
import { LoanTrustManagementAccountItem } from '../models/loan-trust-management-account-item';
import { LoanWealthManagementAccountItem } from '../models/loan-wealth-management-account-item';
import { LookupDeleteRequest } from '../models/lookup-delete-request';
import { LookupItem } from '../models/lookup-item';
import { MloRecord } from '../models/mlo-record';
import { MailchimpCampaignItem } from '../models/mailchimp-campaign-item';
import { ManageAppointmentItem } from '../models/manage-appointment-item';
import { MarkChatMessageAsDelivered } from '../models/mark-chat-message-as-delivered';
import { MarkNotificationAsDeliveredRequest } from '../models/mark-notification-as-delivered-request';
import { MarketingServiceDeskCategoryType } from '../models/marketing-service-desk-category-type';
import { MarketingServiceDeskTicketRequest } from '../models/marketing-service-desk-ticket-request';
import { MarketingServiceItem } from '../models/marketing-service-item';
import { MeetingPreferenceItem } from '../models/meeting-preference-item';
import { MenuGroupRequest } from '../models/menu-group-request';
import { MenuItem } from '../models/menu-item';
import { MenuItemRequest } from '../models/menu-item-request';
import { MenuList } from '../models/menu-list';
import { MerchantReferralAttachmentList } from '../models/merchant-referral-attachment-list';
import { MerchantReferralItem } from '../models/merchant-referral-item';
import { MerchantReferralMaterialDeleteRequest } from '../models/merchant-referral-material-delete-request';
import { MerchantReferralMaterialRequest } from '../models/merchant-referral-material-request';
import { MerchantReferralRequest } from '../models/merchant-referral-request';
import { MergeInternalUserItem } from '../models/merge-internal-user-item';
import { MonthlyReviewDocumentItem } from '../models/monthly-review-document-item';
import { MonthlyReviewDocumentListItem } from '../models/monthly-review-document-list-item';
import { MonthlyReviewDocumentUpdateRequest } from '../models/monthly-review-document-update-request';
import { MonthlyReviewEditRequest } from '../models/monthly-review-edit-request';
import { MonthlyReviewListItem } from '../models/monthly-review-list-item';
import { MortgageReferralAttachmentList } from '../models/mortgage-referral-attachment-list';
import { MortgageReferralItem } from '../models/mortgage-referral-item';
import { MortgageReferralMaterialDeleteRequest } from '../models/mortgage-referral-material-delete-request';
import { MortgageReferralMaterialRequest } from '../models/mortgage-referral-material-request';
import { MortgageReferralRequest } from '../models/mortgage-referral-request';
import { NameSuffix } from '../models/name-suffix';
import { NameSuffixListRequest } from '../models/name-suffix-list-request';
import { NotificationDetailInfo } from '../models/notification-detail-info';
import { NotificationForTopicRequest } from '../models/notification-for-topic-request';
import { NotificationForUserGroupModel } from '../models/notification-for-user-group-model';
import { NotificationForUserModel } from '../models/notification-for-user-model';
import { NotificationGetRequest } from '../models/notification-get-request';
import { NotificationGroupModel } from '../models/notification-group-model';
import { NotificationInfo } from '../models/notification-info';
import { NotificationModel } from '../models/notification-model';
import { NotificationTopicInfo } from '../models/notification-topic-info';
import { NotificationTopicRequestModel } from '../models/notification-topic-request-model';
import { NotificationTopicSubscriberInfo } from '../models/notification-topic-subscriber-info';
import { NotificationTopicSubscriberRequestModel } from '../models/notification-topic-subscriber-request-model';
import { NotificationTopicSubscriberUpdateInfo } from '../models/notification-topic-subscriber-update-info';
import { NotificationTopicUpdateRequestModel } from '../models/notification-topic-update-request-model';
import { NotificationUpdateModel } from '../models/notification-update-model';
import { OdpAccountDataRequest } from '../models/odp-account-data-request';
import { OdpEntity } from '../models/odp-entity';
import { OdpEntityOverDraftLimit } from '../models/odp-entity-over-draft-limit';
import { OdpNotice } from '../models/odp-notice';
import { OdpRegE } from '../models/odp-reg-e';
import { OdpRequest } from '../models/odp-request';
import { OdpReportBranchXRegion } from '../models/odp-report-branch-x-region';
import { OdpReportData } from '../models/odp-report-data';
import { OdpReportNsfSegData } from '../models/odp-report-nsf-seg-data';
import { OdpReportNsfSegRequest } from '../models/odp-report-nsf-seg-request';
import { OldAccountOpeningApplicationRequest } from '../models/old-account-opening-application-request';
import { OldDebitCardApplicationRequest } from '../models/old-debit-card-application-request';
import { OverDraftNoticeType } from '../models/over-draft-notice-type';
import { OverDraftProgramFramework } from '../models/over-draft-program-framework';
import { OwnerStateList } from '../models/owner-state-list';
import { PerformanceManagementEntityRequest } from '../models/performance-management-entity-request';
import { PerformanceManagementStatusListItem } from '../models/performance-management-status-list-item';
import { PermissionForRolesRequest } from '../models/permission-for-roles-request';
import { PhoneType } from '../models/phone-type';
import { PlaidIdentityInfoRequest } from '../models/plaid-identity-info-request';
import { PrivateBankingAccountItem } from '../models/private-banking-account-item';
import { PrivateBankingDashboardItem } from '../models/private-banking-dashboard-item';
import { PrivateBankingDepartmentItem } from '../models/private-banking-department-item';
import { PrivateBankingFetchRequest } from '../models/private-banking-fetch-request';
import { PrivateBankingRelationshipAccountRequest } from '../models/private-banking-relationship-account-request';
import { PrivateBankingRelationshipItem } from '../models/private-banking-relationship-item';
import { PrivateBankingRelationshipRequest } from '../models/private-banking-relationship-request';
import { PrivateBankingRequest } from '../models/private-banking-request';
import { PrivateBankingXInternalUserItem } from '../models/private-banking-x-internal-user-item';
import { ProjectActionPlanDetailItem } from '../models/project-action-plan-detail-item';
import { ProjectActionRequest } from '../models/project-action-request';
import { ProjectCommentItem } from '../models/project-comment-item';
import { ProjectCommentListItem } from '../models/project-comment-list-item';
import { ProjectItem } from '../models/project-item';
import { ProjectMaterialAddRequest } from '../models/project-material-add-request';
import { ProjectMilestoneItem } from '../models/project-milestone-item';
import { ProjectMilestoneNotesItem } from '../models/project-milestone-notes-item';
import { ProjectMilestoneNotesRequest } from '../models/project-milestone-notes-request';
import { ProjectMilestoneRequest } from '../models/project-milestone-request';
import { ProjectMilestoneStatusItem } from '../models/project-milestone-status-item';
import { ProjectRequest } from '../models/project-request';
import { ProjectStakeholderDeleteRequest } from '../models/project-stakeholder-delete-request';
import { ProjectStakeholderItem } from '../models/project-stakeholder-item';
import { ProjectStakeholderRequest } from '../models/project-stakeholder-request';
import { ProjectStakeholderRoleItem } from '../models/project-stakeholder-role-item';
import { ProjectStatusUpdateRequest } from '../models/project-status-update-request';
import { ProjectTrackerCategoryItem } from '../models/project-tracker-category-item';
import { ProjectTrackerItem } from '../models/project-tracker-item';
import { ProjectTrackerStatusItem } from '../models/project-tracker-status-item';
import { ReferralByIdCommentRequest } from '../models/referral-by-id-comment-request';
import { ReferralClosedWonReport } from '../models/referral-closed-won-report';
import { ReferralCommentAddRequest } from '../models/referral-comment-add-request';
import { ReferralCommentRequest } from '../models/referral-comment-request';
import { ReferralComments } from '../models/referral-comments';
import { ReferralContactTypeItem } from '../models/referral-contact-type-item';
import { ReferralDeleteRequest } from '../models/referral-delete-request';
import { ReferralDisqualificationItem } from '../models/referral-disqualification-item';
import { ReferralEmailNotificationRequest } from '../models/referral-email-notification-request';
import { ReferralForUserListItem } from '../models/referral-for-user-list-item';
import { ReferralForUserRequest } from '../models/referral-for-user-request';
import { ReferralIncentiveConfigItem } from '../models/referral-incentive-config-item';
import { ReferralIncentiveCriteriaItem } from '../models/referral-incentive-criteria-item';
import { ReferralIncentiveTypeItem } from '../models/referral-incentive-type-item';
import { ReferralInsuranceProductTypeItem } from '../models/referral-insurance-product-type-item';
import { ReferralMaterialAddRequest } from '../models/referral-material-add-request';
import { ReferralMaterialItem } from '../models/referral-material-item';
import { ReferralRegionalReportRecord } from '../models/referral-regional-report-record';
import { ReferralRegionalReportRequest } from '../models/referral-regional-report-request';
import { ReferralReportFilterRequest } from '../models/referral-report-filter-request';
import { ReferralStatusItem } from '../models/referral-status-item';
import { ReferralStatusSummaryReport } from '../models/referral-status-summary-report';
import { ReferralStatusUpdateRequest } from '../models/referral-status-update-request';
import { ReferralTreasuryProductTypeItem } from '../models/referral-treasury-product-type-item';
import { ReferralTypeItem } from '../models/referral-type-item';
import { RegEOverDraftBalanceOption } from '../models/reg-e-over-draft-balance-option';
import { RelationshipAnalysisDepositAccountItem } from '../models/relationship-analysis-deposit-account-item';
import { RelationshipAnalysisEntityItem } from '../models/relationship-analysis-entity-item';
import { RelationshipAnalysisFetchRequest } from '../models/relationship-analysis-fetch-request';
import { RelationshipAnalysisIraAccountItem } from '../models/relationship-analysis-ira-account-item';
import { RelationshipAnalysisLoanAccountItem } from '../models/relationship-analysis-loan-account-item';
import { RelationshipAnalysisSearchRequest } from '../models/relationship-analysis-search-request';
import { RelationshipDepartmentInternalUserRequest } from '../models/relationship-department-internal-user-request';
import { RemoveFromOutboundBucketRequest } from '../models/remove-from-outbound-bucket-request';
import { ReportCounts } from '../models/report-counts';
import { ReportCountsRequest } from '../models/report-counts-request';
import { ReportLinkedMenu } from '../models/report-linked-menu';
import { ReportMenuOption } from '../models/report-menu-option';
import { ReportMenuRequest } from '../models/report-menu-request';
import { ReportModel } from '../models/report-model';
import { ReportUiModel } from '../models/report-ui-model';
import { ReportUpdateRequest } from '../models/report-update-request';
import { RetryIdentityVerificationItemResponse } from '../models/retry-identity-verification-item-response';
import { RoleForUserList } from '../models/role-for-user-list';
import { RolesForTitleRequest } from '../models/roles-for-title-request';
import { RolesForUserSaveList } from '../models/roles-for-user-save-list';
import { Root } from '../models/root';
import { RouteInfo } from '../models/route-info';
import { S3DeleteFileRequest } from '../models/s-3-delete-file-request';
import { S3DeleteFileVersionRequest } from '../models/s-3-delete-file-version-request';
import { S3DeleteResponse } from '../models/s-3-delete-response';
import { S3File } from '../models/s-3-file';
import { S3FileDownloadResponse } from '../models/s-3-file-download-response';
import { S3FileInfoRequest } from '../models/s-3-file-info-request';
import { S3FileUploadRequest } from '../models/s-3-file-upload-request';
import { S3FileUploadResponse } from '../models/s-3-file-upload-response';
import { S3FileVersionUpdateRequest } from '../models/s-3-file-version-update-request';
import { S3GetFileDownloadRequest } from '../models/s-3-get-file-download-request';
import { S3PresignedFileUploadRequest } from '../models/s-3-presigned-file-upload-request';
import { S3PresignedUrlResponse } from '../models/s-3-presigned-url-response';
import { S3VideoResponse } from '../models/s-3-video-response';
import { S3VideoThumbnailUploadRequest } from '../models/s-3-video-thumbnail-upload-request';
import { ScheduleAppointmentCategoryItem } from '../models/schedule-appointment-category-item';
import { SendMessageRequest } from '../models/send-message-request';
import { ServiceDeskDeleteRequest } from '../models/service-desk-delete-request';
import { ServiceDeskEmailNotificationRequest } from '../models/service-desk-email-notification-request';
import { ServiceDeskMaterialAddRequest } from '../models/service-desk-material-add-request';
import { ServiceDeskPriorityType } from '../models/service-desk-priority-type';
import { ServiceDeskReportRequest } from '../models/service-desk-report-request';
import { ServiceDeskStatusItem } from '../models/service-desk-status-item';
import { ServiceDeskTicketListItem } from '../models/service-desk-ticket-list-item';
import { ServiceDeskTicketsRequest } from '../models/service-desk-tickets-request';
import { ServiceDeskTypeItem } from '../models/service-desk-type-item';
import { ServiceDeskUserCommentStatus } from '../models/service-desk-user-comment-status';
import { ServiceReportRecord } from '../models/service-report-record';
import { SetTokenRequest } from '../models/set-token-request';
import { SignApplicationRequest } from '../models/sign-application-request';
import { SignApplicationResponse } from '../models/sign-application-response';
import { SingleMenuGroup } from '../models/single-menu-group';
import { SingleMenuItem } from '../models/single-menu-item';
import { State } from '../models/state';
import { StateList } from '../models/state-list';
import { SuggestionChatRequest } from '../models/suggestion-chat-request';
import { SuggestionItem } from '../models/suggestion-item';
import { SuggestionItemSummary } from '../models/suggestion-item-summary';
import { SuggestionRequest } from '../models/suggestion-request';
import { SuggestionSummaryRequest } from '../models/suggestion-summary-request';
import { TaskItem } from '../models/task-item';
import { TaskListItem } from '../models/task-list-item';
import { TitlePerUserGroup } from '../models/title-per-user-group';
import { TitlePerUserGroupModel } from '../models/title-per-user-group-model';
import { TitlePerUserGroupRequest } from '../models/title-per-user-group-request';
import { ToDoEventRequest } from '../models/to-do-event-request';
import { ToDoEventSnoozeHistory } from '../models/to-do-event-snooze-history';
import { TodoTemplate } from '../models/todo-template';
import { ToggleJobStatusRequest } from '../models/toggle-job-status-request';
import { Token } from '../models/token';
import { TotalCount } from '../models/total-count';
import { Training } from '../models/training';
import { TrainingListItem } from '../models/training-list-item';
import { TrainingMaterial } from '../models/training-material';
import { TrainingMaterialListItem } from '../models/training-material-list-item';
import { TrainingXCalendarEvent } from '../models/training-x-calendar-event';
import { TrainingXCalendarEventListItem } from '../models/training-x-calendar-event-list-item';
import { TrainingXCalendarEventListRequest } from '../models/training-x-calendar-event-list-request';
import { TravelRequestItem } from '../models/travel-request-item';
import { TravelRequestMaterialAddRequest } from '../models/travel-request-material-add-request';
import { TravelRequestStatusItem } from '../models/travel-request-status-item';
import { TreasuryReferralAttachmentList } from '../models/treasury-referral-attachment-list';
import { TreasuryReferralItem } from '../models/treasury-referral-item';
import { TreasuryReferralMaterialDeleteRequest } from '../models/treasury-referral-material-delete-request';
import { TreasuryReferralMaterialRequest } from '../models/treasury-referral-material-request';
import { TreasuryReferralRequest } from '../models/treasury-referral-request';
import { UpdateApplicationStatusRequest } from '../models/update-application-status-request';
import { UpdateApplicationTestRequest } from '../models/update-application-test-request';
import { UpdateEventRequest } from '../models/update-event-request';
import { UpdateFavoriteOrderForUserRequest } from '../models/update-favorite-order-for-user-request';
import { UpdateInternalWikiModuleRequest } from '../models/update-internal-wiki-module-request';
import { UpdateMonthlyReviewRequest } from '../models/update-monthly-review-request';
import { UpdateRenewalStatusRequest } from '../models/update-renewal-status-request';
import { UpdateSortOrderForMenuGroupRequest } from '../models/update-sort-order-for-menu-group-request';
import { UpdateWorkLocationRequest } from '../models/update-work-location-request';
import { UserAvailabilityRequest } from '../models/user-availability-request';
import { UserCreditDeleteRequest } from '../models/user-credit-delete-request';
import { UserCreditItem } from '../models/user-credit-item';
import { UserCreditRequest } from '../models/user-credit-request';
import { UserGroupAddRequest } from '../models/user-group-add-request';
import { UserGroupFetchRequest } from '../models/user-group-fetch-request';
import { UserGroupItem } from '../models/user-group-item';
import { UserGroupNotificationMessage } from '../models/user-group-notification-message';
import { UserGroupUpdateRequest } from '../models/user-group-update-request';
import { UserGroupXAddRequest } from '../models/user-group-x-add-request';
import { UserIdentityDocumentDetail } from '../models/user-identity-document-detail';
import { UserIdentityDocumentRequest } from '../models/user-identity-document-request';
import { UserIdentityVerificationRequest } from '../models/user-identity-verification-request';
import { UserListRequest } from '../models/user-list-request';
import { UserNotificationMessage } from '../models/user-notification-message';
import { UserOtpValidationRequest } from '../models/user-otp-validation-request';
import { UserPeerGroupRequest } from '../models/user-peer-group-request';
import { UserPeerRequest } from '../models/user-peer-request';
import { UserPerGroupGridModel } from '../models/user-per-group-grid-model';
import { UserRecentMenuItem } from '../models/user-recent-menu-item';
import { VehicleBodyType } from '../models/vehicle-body-type';
import { VehicleErrorMessage } from '../models/vehicle-error-message';
import { VehicleFuelType } from '../models/vehicle-fuel-type';
import { VehicleLienDetails } from '../models/vehicle-lien-details';
import { VehicleLienMaintenanceListItem } from '../models/vehicle-lien-maintenance-list-item';
import { VehicleLienReleaseReason } from '../models/vehicle-lien-release-reason';
import { VehicleLienRequest } from '../models/vehicle-lien-request';
import { VehicleLienStatus } from '../models/vehicle-lien-status';
import { VehicleMake } from '../models/vehicle-make';
import { VehicleModel } from '../models/vehicle-model';
import { WealthManagementReferralItem } from '../models/wealth-management-referral-item';
import { WealthManagementReferralMaterialDeleteRequest } from '../models/wealth-management-referral-material-delete-request';
import { WealthManagementReferralMaterialRequest } from '../models/wealth-management-referral-material-request';
import { WealthManagementReferralRequest } from '../models/wealth-management-referral-request';
import { WealthReferralAttachmentList } from '../models/wealth-referral-attachment-list';
import { WikiManualBookResponse } from '../models/wiki-manual-book-response';
import { WikiManualKeywordResponse } from '../models/wiki-manual-keyword-response';
import { WikiPermissionGetRequest } from '../models/wiki-permission-get-request';
import { WikiPermissionRequest } from '../models/wiki-permission-request';
import { WikiRoleXPermissionItem } from '../models/wiki-role-x-permission-item';
import { WorkLocationBranch } from '../models/work-location-branch';
import { WorkflowItem } from '../models/workflow-item';
import { WorkflowJobLogItem } from '../models/workflow-job-log-item';
import { WorkflowJobTaskItem } from '../models/workflow-job-task-item';
import { WorkflowRequest } from '../models/workflow-request';
import { WorkflowTaskInputOutputItem } from '../models/workflow-task-input-output-item';
import { WorkflowTaskRequest } from '../models/workflow-task-request';
import { WorkflowVersionItem } from '../models/workflow-version-item';
import { WorkflowVersionRequest } from '../models/workflow-version-request';
import { WsjPrimeRate } from '../models/wsj-prime-rate';
import { LoanReferralRequest } from '../models/loan-referral-request';

@Injectable({
  providedIn: 'root',
})
export class ApiService extends BaseService {
  constructor(
    config: ApiConfiguration,
    http: HttpClient
  ) {
    super(config, http);
  }

  /**
   * Path part for operation getLookupData
   */
  static readonly GetLookupDataPath = '/v1/lookup/getLookupData';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getLookupData()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  getLookupData$Response(params: {
    body: FetchLookupRequest
  }): Observable<StrictHttpResponse<{
'isSuccess'?: boolean;
'message'?: string;
'data'?: {
'pageNumber'?: number;
'pageSize'?: number;
'totalRowCount'?: number;
'items'?: Array<LookupItem>;
};
}>> {

    const rb = new RequestBuilder(this.rootUrl, ApiService.GetLookupDataPath, 'post');
    if (params) {
      rb.body(params.body, 'application/json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<{
        'isSuccess'?: boolean;
        'message'?: string;
        'data'?: {
        'pageNumber'?: number;
        'pageSize'?: number;
        'totalRowCount'?: number;
        'items'?: Array<LookupItem>;
        };
        }>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `getLookupData$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  getLookupData(params: {
    body: FetchLookupRequest
  }): Observable<{
'isSuccess'?: boolean;
'message'?: string;
'data'?: {
'pageNumber'?: number;
'pageSize'?: number;
'totalRowCount'?: number;
'items'?: Array<LookupItem>;
};
}> {

    return this.getLookupData$Response(params).pipe(
      map((r: StrictHttpResponse<{
'isSuccess'?: boolean;
'message'?: string;
'data'?: {
'pageNumber'?: number;
'pageSize'?: number;
'totalRowCount'?: number;
'items'?: Array<LookupItem>;
};
}>) => r.body as {
'isSuccess'?: boolean;
'message'?: string;
'data'?: {
'pageNumber'?: number;
'pageSize'?: number;
'totalRowCount'?: number;
'items'?: Array<LookupItem>;
};
})
    );
  }

  /**
   * Path part for operation lookupAdd
   */
  static readonly LookupAddPath = '/v1/lookup/add/lookup';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `lookupAdd()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  lookupAdd$Response(params: {
    body: FetchLookupRequest
  }): Observable<StrictHttpResponse<{
'isSuccess'?: boolean;
'message'?: string;
'data'?: string;
}>> {

    const rb = new RequestBuilder(this.rootUrl, ApiService.LookupAddPath, 'post');
    if (params) {
      rb.body(params.body, 'application/json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<{
        'isSuccess'?: boolean;
        'message'?: string;
        'data'?: string;
        }>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `lookupAdd$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  lookupAdd(params: {
    body: FetchLookupRequest
  }): Observable<{
'isSuccess'?: boolean;
'message'?: string;
'data'?: string;
}> {

    return this.lookupAdd$Response(params).pipe(
      map((r: StrictHttpResponse<{
'isSuccess'?: boolean;
'message'?: string;
'data'?: string;
}>) => r.body as {
'isSuccess'?: boolean;
'message'?: string;
'data'?: string;
})
    );
  }

  /**
   * Path part for operation deleteLookupTableItem
   */
  static readonly DeleteLookupTableItemPath = '/v1/lookup/delete/lookupTableItem';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `deleteLookupTableItem()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  deleteLookupTableItem$Response(params: {
    body: LookupDeleteRequest
  }): Observable<StrictHttpResponse<{
'isSuccess'?: boolean;
'message'?: string;
'data'?: string;
}>> {

    const rb = new RequestBuilder(this.rootUrl, ApiService.DeleteLookupTableItemPath, 'delete');
    if (params) {
      rb.body(params.body, 'application/json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<{
        'isSuccess'?: boolean;
        'message'?: string;
        'data'?: string;
        }>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `deleteLookupTableItem$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  deleteLookupTableItem(params: {
    body: LookupDeleteRequest
  }): Observable<{
'isSuccess'?: boolean;
'message'?: string;
'data'?: string;
}> {

    return this.deleteLookupTableItem$Response(params).pipe(
      map((r: StrictHttpResponse<{
'isSuccess'?: boolean;
'message'?: string;
'data'?: string;
}>) => r.body as {
'isSuccess'?: boolean;
'message'?: string;
'data'?: string;
})
    );
  }

  /**
   * Path part for operation fetchLookUpHoliday
   */
  static readonly FetchLookUpHolidayPath = '/v1/lookup/fetch/lookUpHoliday';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `fetchLookUpHoliday()` instead.
   *
   * This method doesn't expect any request body.
   */
  fetchLookUpHoliday$Response(params?: {
  }): Observable<StrictHttpResponse<{
'isSuccess'?: boolean;
'message'?: string;
'data'?: {
'pageNumber'?: number;
'pageSize'?: number;
'totalRowCount'?: number;
'items'?: Array<HolidayItem>;
};
}>> {

    const rb = new RequestBuilder(this.rootUrl, ApiService.FetchLookUpHolidayPath, 'post');
    if (params) {
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<{
        'isSuccess'?: boolean;
        'message'?: string;
        'data'?: {
        'pageNumber'?: number;
        'pageSize'?: number;
        'totalRowCount'?: number;
        'items'?: Array<HolidayItem>;
        };
        }>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `fetchLookUpHoliday$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  fetchLookUpHoliday(params?: {
  }): Observable<{
'isSuccess'?: boolean;
'message'?: string;
'data'?: {
'pageNumber'?: number;
'pageSize'?: number;
'totalRowCount'?: number;
'items'?: Array<HolidayItem>;
};
}> {

    return this.fetchLookUpHoliday$Response(params).pipe(
      map((r: StrictHttpResponse<{
'isSuccess'?: boolean;
'message'?: string;
'data'?: {
'pageNumber'?: number;
'pageSize'?: number;
'totalRowCount'?: number;
'items'?: Array<HolidayItem>;
};
}>) => r.body as {
'isSuccess'?: boolean;
'message'?: string;
'data'?: {
'pageNumber'?: number;
'pageSize'?: number;
'totalRowCount'?: number;
'items'?: Array<HolidayItem>;
};
})
    );
  }

  /**
   * Path part for operation isHealthy
   */
  static readonly IsHealthyPath = '/v1/healthCheck';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `isHealthy()` instead.
   *
   * This method doesn't expect any request body.
   */
  isHealthy$Response(params?: {
  }): Observable<StrictHttpResponse<void>> {

    const rb = new RequestBuilder(this.rootUrl, ApiService.IsHealthyPath, 'get');
    if (params) {
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: '*/*'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return (r as HttpResponse<any>).clone({ body: undefined }) as StrictHttpResponse<void>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `isHealthy$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  isHealthy(params?: {
  }): Observable<void> {

    return this.isHealthy$Response(params).pipe(
      map((r: StrictHttpResponse<void>) => r.body as void)
    );
  }

  /**
   * Path part for operation getBody
   */
  static readonly GetBodyPath = '/v1/getBody';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getBody()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  getBody$Response(params: {
    body: BodyDto
  }): Observable<StrictHttpResponse<BodyDto>> {

    const rb = new RequestBuilder(this.rootUrl, ApiService.GetBodyPath, 'post');
    if (params) {
      rb.body(params.body, 'application/json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<BodyDto>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `getBody$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  getBody(params: {
    body: BodyDto
  }): Observable<BodyDto> {

    return this.getBody$Response(params).pipe(
      map((r: StrictHttpResponse<BodyDto>) => r.body as BodyDto)
    );
  }

  /**
   * Path part for operation signApplication
   */
  static readonly SignApplicationPath = '/v1/adobeSign/application';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `signApplication()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  signApplication$Response(params: {
    body: SignApplicationRequest
  }): Observable<StrictHttpResponse<{
'isSuccess'?: boolean;
'message'?: string;
'data'?: SignApplicationResponse;
}>> {

    const rb = new RequestBuilder(this.rootUrl, ApiService.SignApplicationPath, 'post');
    if (params) {
      rb.body(params.body, 'application/json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<{
        'isSuccess'?: boolean;
        'message'?: string;
        'data'?: SignApplicationResponse;
        }>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `signApplication$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  signApplication(params: {
    body: SignApplicationRequest
  }): Observable<{
'isSuccess'?: boolean;
'message'?: string;
'data'?: SignApplicationResponse;
}> {

    return this.signApplication$Response(params).pipe(
      map((r: StrictHttpResponse<{
'isSuccess'?: boolean;
'message'?: string;
'data'?: SignApplicationResponse;
}>) => r.body as {
'isSuccess'?: boolean;
'message'?: string;
'data'?: SignApplicationResponse;
})
    );
  }

  /**
   * Path part for operation getS3FileDownload
   */
  static readonly GetS3FileDownloadPath = '/v1/document/getFileDownload';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getS3FileDownload()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  getS3FileDownload$Response(params: {
    body: S3GetFileDownloadRequest
  }): Observable<StrictHttpResponse<{
'isSuccess'?: boolean;
'message'?: string;
'data'?: S3FileDownloadResponse;
}>> {

    const rb = new RequestBuilder(this.rootUrl, ApiService.GetS3FileDownloadPath, 'post');
    if (params) {
      rb.body(params.body, 'application/json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<{
        'isSuccess'?: boolean;
        'message'?: string;
        'data'?: S3FileDownloadResponse;
        }>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `getS3FileDownload$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  getS3FileDownload(params: {
    body: S3GetFileDownloadRequest
  }): Observable<{
'isSuccess'?: boolean;
'message'?: string;
'data'?: S3FileDownloadResponse;
}> {

    return this.getS3FileDownload$Response(params).pipe(
      map((r: StrictHttpResponse<{
'isSuccess'?: boolean;
'message'?: string;
'data'?: S3FileDownloadResponse;
}>) => r.body as {
'isSuccess'?: boolean;
'message'?: string;
'data'?: S3FileDownloadResponse;
})
    );
  }

  /**
   * Path part for operation getClientS3FileDownload
   */
  static readonly GetClientS3FileDownloadPath = '/v1/document/getClientS3FileDownload';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getClientS3FileDownload()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  getClientS3FileDownload$Response(params: {
    body: S3GetFileDownloadRequest
  }): Observable<StrictHttpResponse<void>> {

    const rb = new RequestBuilder(this.rootUrl, ApiService.GetClientS3FileDownloadPath, 'post');
    if (params) {
      rb.body(params.body, 'application/json');
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: '*/*'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return (r as HttpResponse<any>).clone({ body: undefined }) as StrictHttpResponse<void>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `getClientS3FileDownload$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  getClientS3FileDownload(params: {
    body: S3GetFileDownloadRequest
  }): Observable<void> {

    return this.getClientS3FileDownload$Response(params).pipe(
      map((r: StrictHttpResponse<void>) => r.body as void)
    );
  }

  /**
   * Path part for operation getFileInfo
   */
  static readonly GetFileInfoPath = '/v1/document/getFileInfo';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getFileInfo()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  getFileInfo$Response(params: {
    body: S3FileInfoRequest
  }): Observable<StrictHttpResponse<{
'isSuccess'?: boolean;
'message'?: string;
'data'?: S3File;
}>> {

    const rb = new RequestBuilder(this.rootUrl, ApiService.GetFileInfoPath, 'post');
    if (params) {
      rb.body(params.body, 'application/json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<{
        'isSuccess'?: boolean;
        'message'?: string;
        'data'?: S3File;
        }>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `getFileInfo$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  getFileInfo(params: {
    body: S3FileInfoRequest
  }): Observable<{
'isSuccess'?: boolean;
'message'?: string;
'data'?: S3File;
}> {

    return this.getFileInfo$Response(params).pipe(
      map((r: StrictHttpResponse<{
'isSuccess'?: boolean;
'message'?: string;
'data'?: S3File;
}>) => r.body as {
'isSuccess'?: boolean;
'message'?: string;
'data'?: S3File;
})
    );
  }

  /**
   * Path part for operation deleteS3File
   */
  static readonly DeleteS3FilePath = '/v1/document/deleteS3File';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `deleteS3File()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  deleteS3File$Response(params: {
    body: S3DeleteFileRequest
  }): Observable<StrictHttpResponse<{
'isSuccess'?: boolean;
'message'?: string;
'data'?: S3DeleteResponse;
}>> {

    const rb = new RequestBuilder(this.rootUrl, ApiService.DeleteS3FilePath, 'post');
    if (params) {
      rb.body(params.body, 'application/json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<{
        'isSuccess'?: boolean;
        'message'?: string;
        'data'?: S3DeleteResponse;
        }>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `deleteS3File$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  deleteS3File(params: {
    body: S3DeleteFileRequest
  }): Observable<{
'isSuccess'?: boolean;
'message'?: string;
'data'?: S3DeleteResponse;
}> {

    return this.deleteS3File$Response(params).pipe(
      map((r: StrictHttpResponse<{
'isSuccess'?: boolean;
'message'?: string;
'data'?: S3DeleteResponse;
}>) => r.body as {
'isSuccess'?: boolean;
'message'?: string;
'data'?: S3DeleteResponse;
})
    );
  }

  /**
   * Path part for operation uploadS3File
   */
  static readonly UploadS3FilePath = '/v1/document/uploadS3File';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `uploadS3File()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  uploadS3File$Response(params: {
    body: S3FileUploadRequest
  }): Observable<StrictHttpResponse<{
'isSuccess'?: boolean;
'message'?: string;
'data'?: S3FileUploadResponse;
}>> {

    const rb = new RequestBuilder(this.rootUrl, ApiService.UploadS3FilePath, 'post');
    if (params) {
      rb.body(params.body, 'application/json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<{
        'isSuccess'?: boolean;
        'message'?: string;
        'data'?: S3FileUploadResponse;
        }>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `uploadS3File$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  uploadS3File(params: {
    body: S3FileUploadRequest
  }): Observable<{
'isSuccess'?: boolean;
'message'?: string;
'data'?: S3FileUploadResponse;
}> {

    return this.uploadS3File$Response(params).pipe(
      map((r: StrictHttpResponse<{
'isSuccess'?: boolean;
'message'?: string;
'data'?: S3FileUploadResponse;
}>) => r.body as {
'isSuccess'?: boolean;
'message'?: string;
'data'?: S3FileUploadResponse;
})
    );
  }

  /**
   * Path part for operation uploadS3FileWithoutEncryption
   */
  static readonly UploadS3FileWithoutEncryptionPath = '/v1/document/uploadS3FileWithoutEncryption';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `uploadS3FileWithoutEncryption()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  uploadS3FileWithoutEncryption$Response(params: {
    body: S3FileUploadRequest
  }): Observable<StrictHttpResponse<{
'isSuccess'?: boolean;
'message'?: string;
'data'?: S3FileUploadResponse;
}>> {

    const rb = new RequestBuilder(this.rootUrl, ApiService.UploadS3FileWithoutEncryptionPath, 'post');
    if (params) {
      rb.body(params.body, 'application/json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<{
        'isSuccess'?: boolean;
        'message'?: string;
        'data'?: S3FileUploadResponse;
        }>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `uploadS3FileWithoutEncryption$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  uploadS3FileWithoutEncryption(params: {
    body: S3FileUploadRequest
  }): Observable<{
'isSuccess'?: boolean;
'message'?: string;
'data'?: S3FileUploadResponse;
}> {

    return this.uploadS3FileWithoutEncryption$Response(params).pipe(
      map((r: StrictHttpResponse<{
'isSuccess'?: boolean;
'message'?: string;
'data'?: S3FileUploadResponse;
}>) => r.body as {
'isSuccess'?: boolean;
'message'?: string;
'data'?: S3FileUploadResponse;
})
    );
  }

  /**
   * Path part for operation editS3FileName
   */
  static readonly EditS3FileNamePath = '/v1/document/editS3FileName';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `editS3FileName()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  editS3FileName$Response(params: {
    body: EditS3FileNameRequest
  }): Observable<StrictHttpResponse<{
'isSuccess'?: boolean;
'message'?: string;
'data'?: string;
}>> {

    const rb = new RequestBuilder(this.rootUrl, ApiService.EditS3FileNamePath, 'post');
    if (params) {
      rb.body(params.body, 'application/json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<{
        'isSuccess'?: boolean;
        'message'?: string;
        'data'?: string;
        }>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `editS3FileName$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  editS3FileName(params: {
    body: EditS3FileNameRequest
  }): Observable<{
'isSuccess'?: boolean;
'message'?: string;
'data'?: string;
}> {

    return this.editS3FileName$Response(params).pipe(
      map((r: StrictHttpResponse<{
'isSuccess'?: boolean;
'message'?: string;
'data'?: string;
}>) => r.body as {
'isSuccess'?: boolean;
'message'?: string;
'data'?: string;
})
    );
  }

  /**
   * Path part for operation uploadS3FileFromLink
   */
  static readonly UploadS3FileFromLinkPath = '/v1/document/uploadS3FileFromLink';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `uploadS3FileFromLink()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  uploadS3FileFromLink$Response(params: {
    body: S3FileUploadRequest
  }): Observable<StrictHttpResponse<{
'isSuccess'?: boolean;
'message'?: string;
'data'?: S3FileUploadResponse;
}>> {

    const rb = new RequestBuilder(this.rootUrl, ApiService.UploadS3FileFromLinkPath, 'post');
    if (params) {
      rb.body(params.body, 'application/json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<{
        'isSuccess'?: boolean;
        'message'?: string;
        'data'?: S3FileUploadResponse;
        }>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `uploadS3FileFromLink$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  uploadS3FileFromLink(params: {
    body: S3FileUploadRequest
  }): Observable<{
'isSuccess'?: boolean;
'message'?: string;
'data'?: S3FileUploadResponse;
}> {

    return this.uploadS3FileFromLink$Response(params).pipe(
      map((r: StrictHttpResponse<{
'isSuccess'?: boolean;
'message'?: string;
'data'?: S3FileUploadResponse;
}>) => r.body as {
'isSuccess'?: boolean;
'message'?: string;
'data'?: S3FileUploadResponse;
})
    );
  }

  /**
   * Path part for operation getAllS3Videos
   */
  static readonly GetAllS3VideosPath = '/v1/document/getAllS3Videos';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getAllS3Videos()` instead.
   *
   * This method doesn't expect any request body.
   */
  getAllS3Videos$Response(params?: {
  }): Observable<StrictHttpResponse<{
'isSuccess'?: boolean;
'message'?: string;
'data'?: {
'pageNumber'?: number;
'pageSize'?: number;
'totalRowCount'?: number;
'items'?: Array<S3VideoResponse>;
};
}>> {

    const rb = new RequestBuilder(this.rootUrl, ApiService.GetAllS3VideosPath, 'get');
    if (params) {
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<{
        'isSuccess'?: boolean;
        'message'?: string;
        'data'?: {
        'pageNumber'?: number;
        'pageSize'?: number;
        'totalRowCount'?: number;
        'items'?: Array<S3VideoResponse>;
        };
        }>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `getAllS3Videos$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getAllS3Videos(params?: {
  }): Observable<{
'isSuccess'?: boolean;
'message'?: string;
'data'?: {
'pageNumber'?: number;
'pageSize'?: number;
'totalRowCount'?: number;
'items'?: Array<S3VideoResponse>;
};
}> {

    return this.getAllS3Videos$Response(params).pipe(
      map((r: StrictHttpResponse<{
'isSuccess'?: boolean;
'message'?: string;
'data'?: {
'pageNumber'?: number;
'pageSize'?: number;
'totalRowCount'?: number;
'items'?: Array<S3VideoResponse>;
};
}>) => r.body as {
'isSuccess'?: boolean;
'message'?: string;
'data'?: {
'pageNumber'?: number;
'pageSize'?: number;
'totalRowCount'?: number;
'items'?: Array<S3VideoResponse>;
};
})
    );
  }

  /**
   * Path part for operation streamS3Video
   */
  static readonly StreamS3VideoPath = '/v1/document/streamS3Video/{fileGUID}';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `streamS3Video()` instead.
   *
   * This method doesn't expect any request body.
   */
  streamS3Video$Response(params: {
    fileGUID: string;
  }): Observable<StrictHttpResponse<void>> {

    const rb = new RequestBuilder(this.rootUrl, ApiService.StreamS3VideoPath, 'get');
    if (params) {
      rb.path('fileGUID', params.fileGUID, {});
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: '*/*'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return (r as HttpResponse<any>).clone({ body: undefined }) as StrictHttpResponse<void>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `streamS3Video$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  streamS3Video(params: {
    fileGUID: string;
  }): Observable<void> {

    return this.streamS3Video$Response(params).pipe(
      map((r: StrictHttpResponse<void>) => r.body as void)
    );
  }

  /**
   * Path part for operation uploadVideoToS3
   */
  static readonly UploadVideoToS3Path = '/v1/document/uploadVideoToS3';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `uploadVideoToS3()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  uploadVideoToS3$Response(params: {
    body: S3FileUploadRequest
  }): Observable<StrictHttpResponse<{
'isSuccess'?: boolean;
'message'?: string;
'data'?: S3FileUploadResponse;
}>> {

    const rb = new RequestBuilder(this.rootUrl, ApiService.UploadVideoToS3Path, 'post');
    if (params) {
      rb.body(params.body, 'application/json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<{
        'isSuccess'?: boolean;
        'message'?: string;
        'data'?: S3FileUploadResponse;
        }>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `uploadVideoToS3$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  uploadVideoToS3(params: {
    body: S3FileUploadRequest
  }): Observable<{
'isSuccess'?: boolean;
'message'?: string;
'data'?: S3FileUploadResponse;
}> {

    return this.uploadVideoToS3$Response(params).pipe(
      map((r: StrictHttpResponse<{
'isSuccess'?: boolean;
'message'?: string;
'data'?: S3FileUploadResponse;
}>) => r.body as {
'isSuccess'?: boolean;
'message'?: string;
'data'?: S3FileUploadResponse;
})
    );
  }

  /**
   * Path part for operation uploadS3Video
   */
  static readonly UploadS3VideoPath = '/v1/document/UploadS3Video';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `uploadS3Video()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  uploadS3Video$Response(params: {
    body: S3FileUploadRequest
  }): Observable<StrictHttpResponse<{
'isSuccess'?: boolean;
'message'?: string;
'data'?: S3PresignedUrlResponse;
}>> {

    const rb = new RequestBuilder(this.rootUrl, ApiService.UploadS3VideoPath, 'post');
    if (params) {
      rb.body(params.body, 'application/json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<{
        'isSuccess'?: boolean;
        'message'?: string;
        'data'?: S3PresignedUrlResponse;
        }>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `uploadS3Video$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  uploadS3Video(params: {
    body: S3FileUploadRequest
  }): Observable<{
'isSuccess'?: boolean;
'message'?: string;
'data'?: S3PresignedUrlResponse;
}> {

    return this.uploadS3Video$Response(params).pipe(
      map((r: StrictHttpResponse<{
'isSuccess'?: boolean;
'message'?: string;
'data'?: S3PresignedUrlResponse;
}>) => r.body as {
'isSuccess'?: boolean;
'message'?: string;
'data'?: S3PresignedUrlResponse;
})
    );
  }

  /**
   * Path part for operation uploadVideoThumbnail
   */
  static readonly UploadVideoThumbnailPath = '/v1/document/uploadVideoThumbnail';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `uploadVideoThumbnail()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  uploadVideoThumbnail$Response(params: {
    body: S3VideoThumbnailUploadRequest
  }): Observable<StrictHttpResponse<{
'isSuccess'?: boolean;
'message'?: string;
'data'?: S3FileUploadResponse;
}>> {

    const rb = new RequestBuilder(this.rootUrl, ApiService.UploadVideoThumbnailPath, 'post');
    if (params) {
      rb.body(params.body, 'application/json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<{
        'isSuccess'?: boolean;
        'message'?: string;
        'data'?: S3FileUploadResponse;
        }>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `uploadVideoThumbnail$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  uploadVideoThumbnail(params: {
    body: S3VideoThumbnailUploadRequest
  }): Observable<{
'isSuccess'?: boolean;
'message'?: string;
'data'?: S3FileUploadResponse;
}> {

    return this.uploadVideoThumbnail$Response(params).pipe(
      map((r: StrictHttpResponse<{
'isSuccess'?: boolean;
'message'?: string;
'data'?: S3FileUploadResponse;
}>) => r.body as {
'isSuccess'?: boolean;
'message'?: string;
'data'?: S3FileUploadResponse;
})
    );
  }

  /**
   * Path part for operation fetchVideoThumbnailPresignedUrl
   */
  static readonly FetchVideoThumbnailPresignedUrlPath = '/v1/document/fetchVideoThumbnailPresignedUrl/{fileGUID}';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `fetchVideoThumbnailPresignedUrl()` instead.
   *
   * This method doesn't expect any request body.
   */
  fetchVideoThumbnailPresignedUrl$Response(params: {
    fileGUID: string;
  }): Observable<StrictHttpResponse<{
'isSuccess'?: boolean;
'message'?: string;
'data'?: S3PresignedUrlResponse;
}>> {

    const rb = new RequestBuilder(this.rootUrl, ApiService.FetchVideoThumbnailPresignedUrlPath, 'get');
    if (params) {
      rb.path('fileGUID', params.fileGUID, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<{
        'isSuccess'?: boolean;
        'message'?: string;
        'data'?: S3PresignedUrlResponse;
        }>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `fetchVideoThumbnailPresignedUrl$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  fetchVideoThumbnailPresignedUrl(params: {
    fileGUID: string;
  }): Observable<{
'isSuccess'?: boolean;
'message'?: string;
'data'?: S3PresignedUrlResponse;
}> {

    return this.fetchVideoThumbnailPresignedUrl$Response(params).pipe(
      map((r: StrictHttpResponse<{
'isSuccess'?: boolean;
'message'?: string;
'data'?: S3PresignedUrlResponse;
}>) => r.body as {
'isSuccess'?: boolean;
'message'?: string;
'data'?: S3PresignedUrlResponse;
})
    );
  }

  /**
   * Path part for operation viewS3File
   */
  static readonly ViewS3FilePath = '/v1/document/assets/trb-content/{fileGUID}';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `viewS3File()` instead.
   *
   * This method doesn't expect any request body.
   */
  viewS3File$Response(params: {
    fileGUID: string;
  }): Observable<StrictHttpResponse<void>> {

    const rb = new RequestBuilder(this.rootUrl, ApiService.ViewS3FilePath, 'get');
    if (params) {
      rb.path('fileGUID', params.fileGUID, {});
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: '*/*'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return (r as HttpResponse<any>).clone({ body: undefined }) as StrictHttpResponse<void>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `viewS3File$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  viewS3File(params: {
    fileGUID: string;
  }): Observable<void> {

    return this.viewS3File$Response(params).pipe(
      map((r: StrictHttpResponse<void>) => r.body as void)
    );
  }

  /**
   * Path part for operation convertToEps
   */
  static readonly ConvertToEpsPath = '/v1/document/convertToEps';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `convertToEps()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  convertToEps$Response(params: {
    body: ConvertToEpsModel
  }): Observable<StrictHttpResponse<{
'isSuccess'?: boolean;
'message'?: string;
'data'?: ConvertToEpsModel;
}>> {

    const rb = new RequestBuilder(this.rootUrl, ApiService.ConvertToEpsPath, 'post');
    if (params) {
      rb.body(params.body, 'application/json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<{
        'isSuccess'?: boolean;
        'message'?: string;
        'data'?: ConvertToEpsModel;
        }>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `convertToEps$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  convertToEps(params: {
    body: ConvertToEpsModel
  }): Observable<{
'isSuccess'?: boolean;
'message'?: string;
'data'?: ConvertToEpsModel;
}> {

    return this.convertToEps$Response(params).pipe(
      map((r: StrictHttpResponse<{
'isSuccess'?: boolean;
'message'?: string;
'data'?: ConvertToEpsModel;
}>) => r.body as {
'isSuccess'?: boolean;
'message'?: string;
'data'?: ConvertToEpsModel;
})
    );
  }

  /**
   * Path part for operation getS3FileDownloadWithoutDecryption
   */
  static readonly GetS3FileDownloadWithoutDecryptionPath = '/v1/document/getS3FileDownloadWithoutDecryption';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getS3FileDownloadWithoutDecryption()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  getS3FileDownloadWithoutDecryption$Response(params: {
    body: S3GetFileDownloadRequest
  }): Observable<StrictHttpResponse<{
'isSuccess'?: boolean;
'message'?: string;
'data'?: S3FileDownloadResponse;
}>> {

    const rb = new RequestBuilder(this.rootUrl, ApiService.GetS3FileDownloadWithoutDecryptionPath, 'post');
    if (params) {
      rb.body(params.body, 'application/json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<{
        'isSuccess'?: boolean;
        'message'?: string;
        'data'?: S3FileDownloadResponse;
        }>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `getS3FileDownloadWithoutDecryption$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  getS3FileDownloadWithoutDecryption(params: {
    body: S3GetFileDownloadRequest
  }): Observable<{
'isSuccess'?: boolean;
'message'?: string;
'data'?: S3FileDownloadResponse;
}> {

    return this.getS3FileDownloadWithoutDecryption$Response(params).pipe(
      map((r: StrictHttpResponse<{
'isSuccess'?: boolean;
'message'?: string;
'data'?: S3FileDownloadResponse;
}>) => r.body as {
'isSuccess'?: boolean;
'message'?: string;
'data'?: S3FileDownloadResponse;
})
    );
  }

  /**
   * Path part for operation getUserIdentityDocuments
   */
  static readonly GetUserIdentityDocumentsPath = '/v1/document/getUserIdentityDocuments';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getUserIdentityDocuments()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  getUserIdentityDocuments$Response(params: {
    body: S3GetFileDownloadRequest
  }): Observable<StrictHttpResponse<{
'isSuccess'?: boolean;
'message'?: string;
'data'?: S3FileDownloadResponse;
}>> {

    const rb = new RequestBuilder(this.rootUrl, ApiService.GetUserIdentityDocumentsPath, 'post');
    if (params) {
      rb.body(params.body, 'application/json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<{
        'isSuccess'?: boolean;
        'message'?: string;
        'data'?: S3FileDownloadResponse;
        }>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `getUserIdentityDocuments$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  getUserIdentityDocuments(params: {
    body: S3GetFileDownloadRequest
  }): Observable<{
'isSuccess'?: boolean;
'message'?: string;
'data'?: S3FileDownloadResponse;
}> {

    return this.getUserIdentityDocuments$Response(params).pipe(
      map((r: StrictHttpResponse<{
'isSuccess'?: boolean;
'message'?: string;
'data'?: S3FileDownloadResponse;
}>) => r.body as {
'isSuccess'?: boolean;
'message'?: string;
'data'?: S3FileDownloadResponse;
})
    );
  }

  /**
   * Path part for operation decryptAndSaveZipS3File
   */
  static readonly DecryptAndSaveZipS3FilePath = '/v1/document/decryptAndSaveZipS3File';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `decryptAndSaveZipS3File()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  decryptAndSaveZipS3File$Response(params: {
    body: ConvertEncryptedZipS3FileRequest
  }): Observable<StrictHttpResponse<{
'isSuccess'?: boolean;
'message'?: string;
'data'?: string;
}>> {

    const rb = new RequestBuilder(this.rootUrl, ApiService.DecryptAndSaveZipS3FilePath, 'post');
    if (params) {
      rb.body(params.body, 'application/json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<{
        'isSuccess'?: boolean;
        'message'?: string;
        'data'?: string;
        }>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `decryptAndSaveZipS3File$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  decryptAndSaveZipS3File(params: {
    body: ConvertEncryptedZipS3FileRequest
  }): Observable<{
'isSuccess'?: boolean;
'message'?: string;
'data'?: string;
}> {

    return this.decryptAndSaveZipS3File$Response(params).pipe(
      map((r: StrictHttpResponse<{
'isSuccess'?: boolean;
'message'?: string;
'data'?: string;
}>) => r.body as {
'isSuccess'?: boolean;
'message'?: string;
'data'?: string;
})
    );
  }

  /**
   * Path part for operation fetchS3UploadPresignedUrl
   */
  static readonly FetchS3UploadPresignedUrlPath = '/v1/document/fetchS3UploadPresignedUrl';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `fetchS3UploadPresignedUrl()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  fetchS3UploadPresignedUrl$Response(params: {
    body: S3FileUploadRequest
  }): Observable<StrictHttpResponse<{
'isSuccess'?: boolean;
'message'?: string;
'data'?: S3PresignedUrlResponse;
}>> {

    const rb = new RequestBuilder(this.rootUrl, ApiService.FetchS3UploadPresignedUrlPath, 'post');
    if (params) {
      rb.body(params.body, 'application/json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<{
        'isSuccess'?: boolean;
        'message'?: string;
        'data'?: S3PresignedUrlResponse;
        }>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `fetchS3UploadPresignedUrl$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  fetchS3UploadPresignedUrl(params: {
    body: S3FileUploadRequest
  }): Observable<{
'isSuccess'?: boolean;
'message'?: string;
'data'?: S3PresignedUrlResponse;
}> {

    return this.fetchS3UploadPresignedUrl$Response(params).pipe(
      map((r: StrictHttpResponse<{
'isSuccess'?: boolean;
'message'?: string;
'data'?: S3PresignedUrlResponse;
}>) => r.body as {
'isSuccess'?: boolean;
'message'?: string;
'data'?: S3PresignedUrlResponse;
})
    );
  }

  /**
   * Path part for operation saveS3File
   */
  static readonly SaveS3FilePath = '/v1/document/save/s3File';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `saveS3File()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  saveS3File$Response(params: {
    body: S3PresignedFileUploadRequest
  }): Observable<StrictHttpResponse<{
'isSuccess'?: boolean;
'message'?: string;
'data'?: S3FileUploadResponse;
}>> {

    const rb = new RequestBuilder(this.rootUrl, ApiService.SaveS3FilePath, 'post');
    if (params) {
      rb.body(params.body, 'application/json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<{
        'isSuccess'?: boolean;
        'message'?: string;
        'data'?: S3FileUploadResponse;
        }>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `saveS3File$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  saveS3File(params: {
    body: S3PresignedFileUploadRequest
  }): Observable<{
'isSuccess'?: boolean;
'message'?: string;
'data'?: S3FileUploadResponse;
}> {

    return this.saveS3File$Response(params).pipe(
      map((r: StrictHttpResponse<{
'isSuccess'?: boolean;
'message'?: string;
'data'?: S3FileUploadResponse;
}>) => r.body as {
'isSuccess'?: boolean;
'message'?: string;
'data'?: S3FileUploadResponse;
})
    );
  }

  /**
   * Path part for operation fetchS3ViewPresignedUrl
   */
  static readonly FetchS3ViewPresignedUrlPath = '/v1/document/fetchS3ViewPresignedUrl';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `fetchS3ViewPresignedUrl()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  fetchS3ViewPresignedUrl$Response(params: {
    body: S3GetFileDownloadRequest
  }): Observable<StrictHttpResponse<{
'isSuccess'?: boolean;
'message'?: string;
'data'?: S3PresignedUrlResponse;
}>> {

    const rb = new RequestBuilder(this.rootUrl, ApiService.FetchS3ViewPresignedUrlPath, 'post');
    if (params) {
      rb.body(params.body, 'application/json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<{
        'isSuccess'?: boolean;
        'message'?: string;
        'data'?: S3PresignedUrlResponse;
        }>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `fetchS3ViewPresignedUrl$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  fetchS3ViewPresignedUrl(params: {
    body: S3GetFileDownloadRequest
  }): Observable<{
'isSuccess'?: boolean;
'message'?: string;
'data'?: S3PresignedUrlResponse;
}> {

    return this.fetchS3ViewPresignedUrl$Response(params).pipe(
      map((r: StrictHttpResponse<{
'isSuccess'?: boolean;
'message'?: string;
'data'?: S3PresignedUrlResponse;
}>) => r.body as {
'isSuccess'?: boolean;
'message'?: string;
'data'?: S3PresignedUrlResponse;
})
    );
  }

  /**
   * Path part for operation removeFromOutboundBucket
   */
  static readonly RemoveFromOutboundBucketPath = '/v1/document/removeFromOutboundBucket';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `removeFromOutboundBucket()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  removeFromOutboundBucket$Response(params: {
    body: RemoveFromOutboundBucketRequest
  }): Observable<StrictHttpResponse<void>> {

    const rb = new RequestBuilder(this.rootUrl, ApiService.RemoveFromOutboundBucketPath, 'post');
    if (params) {
      rb.body(params.body, 'application/json');
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: '*/*'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return (r as HttpResponse<any>).clone({ body: undefined }) as StrictHttpResponse<void>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `removeFromOutboundBucket$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  removeFromOutboundBucket(params: {
    body: RemoveFromOutboundBucketRequest
  }): Observable<void> {

    return this.removeFromOutboundBucket$Response(params).pipe(
      map((r: StrictHttpResponse<void>) => r.body as void)
    );
  }

  /**
   * Path part for operation deleteS3FileVersion
   */
  static readonly DeleteS3FileVersionPath = '/v1/document/deleteS3FileVersion';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `deleteS3FileVersion()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  deleteS3FileVersion$Response(params: {
    body: S3DeleteFileVersionRequest
  }): Observable<StrictHttpResponse<{
'isSuccess'?: boolean;
'message'?: string;
'data'?: S3DeleteResponse;
}>> {

    const rb = new RequestBuilder(this.rootUrl, ApiService.DeleteS3FileVersionPath, 'post');
    if (params) {
      rb.body(params.body, 'application/json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<{
        'isSuccess'?: boolean;
        'message'?: string;
        'data'?: S3DeleteResponse;
        }>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `deleteS3FileVersion$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  deleteS3FileVersion(params: {
    body: S3DeleteFileVersionRequest
  }): Observable<{
'isSuccess'?: boolean;
'message'?: string;
'data'?: S3DeleteResponse;
}> {

    return this.deleteS3FileVersion$Response(params).pipe(
      map((r: StrictHttpResponse<{
'isSuccess'?: boolean;
'message'?: string;
'data'?: S3DeleteResponse;
}>) => r.body as {
'isSuccess'?: boolean;
'message'?: string;
'data'?: S3DeleteResponse;
})
    );
  }

  /**
   * Path part for operation updateS3FileWithVersionGuid
   */
  static readonly UpdateS3FileWithVersionGuidPath = '/v1/document/updateS3FileWithVersionGUID';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `updateS3FileWithVersionGuid()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  updateS3FileWithVersionGuid$Response(params: {
    body: S3FileVersionUpdateRequest
  }): Observable<StrictHttpResponse<{
'isSuccess'?: boolean;
'message'?: string;
'data'?: string;
}>> {

    const rb = new RequestBuilder(this.rootUrl, ApiService.UpdateS3FileWithVersionGuidPath, 'post');
    if (params) {
      rb.body(params.body, 'application/json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<{
        'isSuccess'?: boolean;
        'message'?: string;
        'data'?: string;
        }>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `updateS3FileWithVersionGuid$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  updateS3FileWithVersionGuid(params: {
    body: S3FileVersionUpdateRequest
  }): Observable<{
'isSuccess'?: boolean;
'message'?: string;
'data'?: string;
}> {

    return this.updateS3FileWithVersionGuid$Response(params).pipe(
      map((r: StrictHttpResponse<{
'isSuccess'?: boolean;
'message'?: string;
'data'?: string;
}>) => r.body as {
'isSuccess'?: boolean;
'message'?: string;
'data'?: string;
})
    );
  }

  /**
   * Path part for operation queueGoldstarEmail
   */
  static readonly QueueGoldstarEmailPath = '/v1/email/queueGoldstarEmail';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `queueGoldstarEmail()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  queueGoldstarEmail$Response(params: {
    body: GoldstarEmailRequest
  }): Observable<StrictHttpResponse<void>> {

    const rb = new RequestBuilder(this.rootUrl, ApiService.QueueGoldstarEmailPath, 'post');
    if (params) {
      rb.body(params.body, 'application/json');
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: '*/*'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return (r as HttpResponse<any>).clone({ body: undefined }) as StrictHttpResponse<void>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `queueGoldstarEmail$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  queueGoldstarEmail(params: {
    body: GoldstarEmailRequest
  }): Observable<void> {

    return this.queueGoldstarEmail$Response(params).pipe(
      map((r: StrictHttpResponse<void>) => r.body as void)
    );
  }

  /**
   * Path part for operation getExternalAllowedRoutes
   */
  static readonly GetExternalAllowedRoutesPath = '/v1/common/allowedRoutes';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getExternalAllowedRoutes()` instead.
   *
   * This method doesn't expect any request body.
   */
  getExternalAllowedRoutes$Response(params?: {
  }): Observable<StrictHttpResponse<{
'isSuccess'?: boolean;
'message'?: string;
'data'?: {
'pageNumber'?: number;
'pageSize'?: number;
'totalRowCount'?: number;
'items'?: Array<RouteInfo>;
};
}>> {

    const rb = new RequestBuilder(this.rootUrl, ApiService.GetExternalAllowedRoutesPath, 'get');
    if (params) {
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<{
        'isSuccess'?: boolean;
        'message'?: string;
        'data'?: {
        'pageNumber'?: number;
        'pageSize'?: number;
        'totalRowCount'?: number;
        'items'?: Array<RouteInfo>;
        };
        }>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `getExternalAllowedRoutes$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getExternalAllowedRoutes(params?: {
  }): Observable<{
'isSuccess'?: boolean;
'message'?: string;
'data'?: {
'pageNumber'?: number;
'pageSize'?: number;
'totalRowCount'?: number;
'items'?: Array<RouteInfo>;
};
}> {

    return this.getExternalAllowedRoutes$Response(params).pipe(
      map((r: StrictHttpResponse<{
'isSuccess'?: boolean;
'message'?: string;
'data'?: {
'pageNumber'?: number;
'pageSize'?: number;
'totalRowCount'?: number;
'items'?: Array<RouteInfo>;
};
}>) => r.body as {
'isSuccess'?: boolean;
'message'?: string;
'data'?: {
'pageNumber'?: number;
'pageSize'?: number;
'totalRowCount'?: number;
'items'?: Array<RouteInfo>;
};
})
    );
  }

  /**
   * Path part for operation getLatestBuildVersionInfo
   */
  static readonly GetLatestBuildVersionInfoPath = '/v1/common/buildVersion';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getLatestBuildVersionInfo()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  getLatestBuildVersionInfo$Response(params: {
    body: ApiBuildVersionRequest
  }): Observable<StrictHttpResponse<{
'isSuccess'?: boolean;
'message'?: string;
'data'?: {
'pageNumber'?: number;
'pageSize'?: number;
'totalRowCount'?: number;
'items'?: Array<ApiBuildVersionInfo>;
};
}>> {

    const rb = new RequestBuilder(this.rootUrl, ApiService.GetLatestBuildVersionInfoPath, 'post');
    if (params) {
      rb.body(params.body, 'application/json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<{
        'isSuccess'?: boolean;
        'message'?: string;
        'data'?: {
        'pageNumber'?: number;
        'pageSize'?: number;
        'totalRowCount'?: number;
        'items'?: Array<ApiBuildVersionInfo>;
        };
        }>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `getLatestBuildVersionInfo$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  getLatestBuildVersionInfo(params: {
    body: ApiBuildVersionRequest
  }): Observable<{
'isSuccess'?: boolean;
'message'?: string;
'data'?: {
'pageNumber'?: number;
'pageSize'?: number;
'totalRowCount'?: number;
'items'?: Array<ApiBuildVersionInfo>;
};
}> {

    return this.getLatestBuildVersionInfo$Response(params).pipe(
      map((r: StrictHttpResponse<{
'isSuccess'?: boolean;
'message'?: string;
'data'?: {
'pageNumber'?: number;
'pageSize'?: number;
'totalRowCount'?: number;
'items'?: Array<ApiBuildVersionInfo>;
};
}>) => r.body as {
'isSuccess'?: boolean;
'message'?: string;
'data'?: {
'pageNumber'?: number;
'pageSize'?: number;
'totalRowCount'?: number;
'items'?: Array<ApiBuildVersionInfo>;
};
})
    );
  }

  /**
   * Path part for operation citizenshipTypeList
   */
  static readonly CitizenshipTypeListPath = '/v1/base-application/list/citizenshipType';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `citizenshipTypeList()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  citizenshipTypeList$Response(params: {
    body: EntityGetRequest
  }): Observable<StrictHttpResponse<{
'isSuccess'?: boolean;
'message'?: string;
'data'?: {
'pageNumber'?: number;
'pageSize'?: number;
'totalRowCount'?: number;
'items'?: Array<CitizenshipType>;
};
}>> {

    const rb = new RequestBuilder(this.rootUrl, ApiService.CitizenshipTypeListPath, 'post');
    if (params) {
      rb.body(params.body, 'application/json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<{
        'isSuccess'?: boolean;
        'message'?: string;
        'data'?: {
        'pageNumber'?: number;
        'pageSize'?: number;
        'totalRowCount'?: number;
        'items'?: Array<CitizenshipType>;
        };
        }>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `citizenshipTypeList$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  citizenshipTypeList(params: {
    body: EntityGetRequest
  }): Observable<{
'isSuccess'?: boolean;
'message'?: string;
'data'?: {
'pageNumber'?: number;
'pageSize'?: number;
'totalRowCount'?: number;
'items'?: Array<CitizenshipType>;
};
}> {

    return this.citizenshipTypeList$Response(params).pipe(
      map((r: StrictHttpResponse<{
'isSuccess'?: boolean;
'message'?: string;
'data'?: {
'pageNumber'?: number;
'pageSize'?: number;
'totalRowCount'?: number;
'items'?: Array<CitizenshipType>;
};
}>) => r.body as {
'isSuccess'?: boolean;
'message'?: string;
'data'?: {
'pageNumber'?: number;
'pageSize'?: number;
'totalRowCount'?: number;
'items'?: Array<CitizenshipType>;
};
})
    );
  }

  /**
   * Path part for operation employeeStatusList
   */
  static readonly EmployeeStatusListPath = '/v1/base-application/list/employeeStatus';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `employeeStatusList()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  employeeStatusList$Response(params: {
    body: EntityGetRequest
  }): Observable<StrictHttpResponse<{
'isSuccess'?: boolean;
'message'?: string;
'data'?: {
'pageNumber'?: number;
'pageSize'?: number;
'totalRowCount'?: number;
'items'?: Array<EmploymentStatus>;
};
}>> {

    const rb = new RequestBuilder(this.rootUrl, ApiService.EmployeeStatusListPath, 'post');
    if (params) {
      rb.body(params.body, 'application/json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<{
        'isSuccess'?: boolean;
        'message'?: string;
        'data'?: {
        'pageNumber'?: number;
        'pageSize'?: number;
        'totalRowCount'?: number;
        'items'?: Array<EmploymentStatus>;
        };
        }>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `employeeStatusList$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  employeeStatusList(params: {
    body: EntityGetRequest
  }): Observable<{
'isSuccess'?: boolean;
'message'?: string;
'data'?: {
'pageNumber'?: number;
'pageSize'?: number;
'totalRowCount'?: number;
'items'?: Array<EmploymentStatus>;
};
}> {

    return this.employeeStatusList$Response(params).pipe(
      map((r: StrictHttpResponse<{
'isSuccess'?: boolean;
'message'?: string;
'data'?: {
'pageNumber'?: number;
'pageSize'?: number;
'totalRowCount'?: number;
'items'?: Array<EmploymentStatus>;
};
}>) => r.body as {
'isSuccess'?: boolean;
'message'?: string;
'data'?: {
'pageNumber'?: number;
'pageSize'?: number;
'totalRowCount'?: number;
'items'?: Array<EmploymentStatus>;
};
})
    );
  }

  /**
   * Path part for operation stateListWithBranches
   */
  static readonly StateListWithBranchesPath = '/v1/base-application/list/stateList';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `stateListWithBranches()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  stateListWithBranches$Response(params: {
    body: EntityGetRequest
  }): Observable<StrictHttpResponse<{
'isSuccess'?: boolean;
'message'?: string;
'data'?: {
'pageNumber'?: number;
'pageSize'?: number;
'totalRowCount'?: number;
'items'?: Array<State>;
};
}>> {

    const rb = new RequestBuilder(this.rootUrl, ApiService.StateListWithBranchesPath, 'post');
    if (params) {
      rb.body(params.body, 'application/json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<{
        'isSuccess'?: boolean;
        'message'?: string;
        'data'?: {
        'pageNumber'?: number;
        'pageSize'?: number;
        'totalRowCount'?: number;
        'items'?: Array<State>;
        };
        }>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `stateListWithBranches$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  stateListWithBranches(params: {
    body: EntityGetRequest
  }): Observable<{
'isSuccess'?: boolean;
'message'?: string;
'data'?: {
'pageNumber'?: number;
'pageSize'?: number;
'totalRowCount'?: number;
'items'?: Array<State>;
};
}> {

    return this.stateListWithBranches$Response(params).pipe(
      map((r: StrictHttpResponse<{
'isSuccess'?: boolean;
'message'?: string;
'data'?: {
'pageNumber'?: number;
'pageSize'?: number;
'totalRowCount'?: number;
'items'?: Array<State>;
};
}>) => r.body as {
'isSuccess'?: boolean;
'message'?: string;
'data'?: {
'pageNumber'?: number;
'pageSize'?: number;
'totalRowCount'?: number;
'items'?: Array<State>;
};
})
    );
  }

  /**
   * Path part for operation applicationStatusList
   */
  static readonly ApplicationStatusListPath = '/v1/base-application/list/applicationStatus';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `applicationStatusList()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  applicationStatusList$Response(params: {
    body: EntityGetRequest
  }): Observable<StrictHttpResponse<{
'isSuccess'?: boolean;
'message'?: string;
'data'?: {
'pageNumber'?: number;
'pageSize'?: number;
'totalRowCount'?: number;
'items'?: Array<ApplicationStatus>;
};
}>> {

    const rb = new RequestBuilder(this.rootUrl, ApiService.ApplicationStatusListPath, 'post');
    if (params) {
      rb.body(params.body, 'application/json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<{
        'isSuccess'?: boolean;
        'message'?: string;
        'data'?: {
        'pageNumber'?: number;
        'pageSize'?: number;
        'totalRowCount'?: number;
        'items'?: Array<ApplicationStatus>;
        };
        }>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `applicationStatusList$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  applicationStatusList(params: {
    body: EntityGetRequest
  }): Observable<{
'isSuccess'?: boolean;
'message'?: string;
'data'?: {
'pageNumber'?: number;
'pageSize'?: number;
'totalRowCount'?: number;
'items'?: Array<ApplicationStatus>;
};
}> {

    return this.applicationStatusList$Response(params).pipe(
      map((r: StrictHttpResponse<{
'isSuccess'?: boolean;
'message'?: string;
'data'?: {
'pageNumber'?: number;
'pageSize'?: number;
'totalRowCount'?: number;
'items'?: Array<ApplicationStatus>;
};
}>) => r.body as {
'isSuccess'?: boolean;
'message'?: string;
'data'?: {
'pageNumber'?: number;
'pageSize'?: number;
'totalRowCount'?: number;
'items'?: Array<ApplicationStatus>;
};
})
    );
  }

  /**
   * Path part for operation putLogsInTempTable
   */
  static readonly PutLogsInTempTablePath = '/v1/log/put/tempTable';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `putLogsInTempTable()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  putLogsInTempTable$Response(params: {
    body: ApiLogInfo
  }): Observable<StrictHttpResponse<{
'isSuccess'?: boolean;
'message'?: string;
'data'?: string;
}>> {

    const rb = new RequestBuilder(this.rootUrl, ApiService.PutLogsInTempTablePath, 'put');
    if (params) {
      rb.body(params.body, 'application/json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<{
        'isSuccess'?: boolean;
        'message'?: string;
        'data'?: string;
        }>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `putLogsInTempTable$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  putLogsInTempTable(params: {
    body: ApiLogInfo
  }): Observable<{
'isSuccess'?: boolean;
'message'?: string;
'data'?: string;
}> {

    return this.putLogsInTempTable$Response(params).pipe(
      map((r: StrictHttpResponse<{
'isSuccess'?: boolean;
'message'?: string;
'data'?: string;
}>) => r.body as {
'isSuccess'?: boolean;
'message'?: string;
'data'?: string;
})
    );
  }

  /**
   * Path part for operation filterLogs
   */
  static readonly FilterLogsPath = '/v1/log/filter';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `filterLogs()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  filterLogs$Response(params: {
    body: ApiLogFilter
  }): Observable<StrictHttpResponse<{
'isSuccess'?: boolean;
'message'?: string;
'data'?: {
'pageNumber'?: number;
'pageSize'?: number;
'totalRowCount'?: number;
'items'?: Array<ApiLogInfo>;
};
}>> {

    const rb = new RequestBuilder(this.rootUrl, ApiService.FilterLogsPath, 'post');
    if (params) {
      rb.body(params.body, 'application/json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<{
        'isSuccess'?: boolean;
        'message'?: string;
        'data'?: {
        'pageNumber'?: number;
        'pageSize'?: number;
        'totalRowCount'?: number;
        'items'?: Array<ApiLogInfo>;
        };
        }>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `filterLogs$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  filterLogs(params: {
    body: ApiLogFilter
  }): Observable<{
'isSuccess'?: boolean;
'message'?: string;
'data'?: {
'pageNumber'?: number;
'pageSize'?: number;
'totalRowCount'?: number;
'items'?: Array<ApiLogInfo>;
};
}> {

    return this.filterLogs$Response(params).pipe(
      map((r: StrictHttpResponse<{
'isSuccess'?: boolean;
'message'?: string;
'data'?: {
'pageNumber'?: number;
'pageSize'?: number;
'totalRowCount'?: number;
'items'?: Array<ApiLogInfo>;
};
}>) => r.body as {
'isSuccess'?: boolean;
'message'?: string;
'data'?: {
'pageNumber'?: number;
'pageSize'?: number;
'totalRowCount'?: number;
'items'?: Array<ApiLogInfo>;
};
})
    );
  }

  /**
   * Path part for operation allMenuListRevised
   */
  static readonly AllMenuListRevisedPath = '/v1/menu/list/revised';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `allMenuListRevised()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  allMenuListRevised$Response(params: {
    body: FetchMenuRequest
  }): Observable<StrictHttpResponse<{
'isSuccess'?: boolean;
'message'?: string;
'data'?: {
'pageNumber'?: number;
'pageSize'?: number;
'totalRowCount'?: number;
'items'?: Array<MenuList>;
};
}>> {

    const rb = new RequestBuilder(this.rootUrl, ApiService.AllMenuListRevisedPath, 'post');
    if (params) {
      rb.body(params.body, 'application/json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<{
        'isSuccess'?: boolean;
        'message'?: string;
        'data'?: {
        'pageNumber'?: number;
        'pageSize'?: number;
        'totalRowCount'?: number;
        'items'?: Array<MenuList>;
        };
        }>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `allMenuListRevised$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  allMenuListRevised(params: {
    body: FetchMenuRequest
  }): Observable<{
'isSuccess'?: boolean;
'message'?: string;
'data'?: {
'pageNumber'?: number;
'pageSize'?: number;
'totalRowCount'?: number;
'items'?: Array<MenuList>;
};
}> {

    return this.allMenuListRevised$Response(params).pipe(
      map((r: StrictHttpResponse<{
'isSuccess'?: boolean;
'message'?: string;
'data'?: {
'pageNumber'?: number;
'pageSize'?: number;
'totalRowCount'?: number;
'items'?: Array<MenuList>;
};
}>) => r.body as {
'isSuccess'?: boolean;
'message'?: string;
'data'?: {
'pageNumber'?: number;
'pageSize'?: number;
'totalRowCount'?: number;
'items'?: Array<MenuList>;
};
})
    );
  }

  /**
   * Path part for operation menuGroupList
   */
  static readonly MenuGroupListPath = '/v1/menu/list/group';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `menuGroupList()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  menuGroupList$Response(params: {
    body: EntityGetRequest
  }): Observable<StrictHttpResponse<{
'isSuccess'?: boolean;
'message'?: string;
'data'?: {
'pageNumber'?: number;
'pageSize'?: number;
'totalRowCount'?: number;
'items'?: Array<MenuItem>;
};
}>> {

    const rb = new RequestBuilder(this.rootUrl, ApiService.MenuGroupListPath, 'post');
    if (params) {
      rb.body(params.body, 'application/json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<{
        'isSuccess'?: boolean;
        'message'?: string;
        'data'?: {
        'pageNumber'?: number;
        'pageSize'?: number;
        'totalRowCount'?: number;
        'items'?: Array<MenuItem>;
        };
        }>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `menuGroupList$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  menuGroupList(params: {
    body: EntityGetRequest
  }): Observable<{
'isSuccess'?: boolean;
'message'?: string;
'data'?: {
'pageNumber'?: number;
'pageSize'?: number;
'totalRowCount'?: number;
'items'?: Array<MenuItem>;
};
}> {

    return this.menuGroupList$Response(params).pipe(
      map((r: StrictHttpResponse<{
'isSuccess'?: boolean;
'message'?: string;
'data'?: {
'pageNumber'?: number;
'pageSize'?: number;
'totalRowCount'?: number;
'items'?: Array<MenuItem>;
};
}>) => r.body as {
'isSuccess'?: boolean;
'message'?: string;
'data'?: {
'pageNumber'?: number;
'pageSize'?: number;
'totalRowCount'?: number;
'items'?: Array<MenuItem>;
};
})
    );
  }

  /**
   * Path part for operation menuRecentList
   */
  static readonly MenuRecentListPath = '/v1/menu/list/recent';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `menuRecentList()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  menuRecentList$Response(params: {
    body: EntityGetRequest
  }): Observable<StrictHttpResponse<{
'isSuccess'?: boolean;
'message'?: string;
'data'?: {
'pageNumber'?: number;
'pageSize'?: number;
'totalRowCount'?: number;
'items'?: Array<UserRecentMenuItem>;
};
}>> {

    const rb = new RequestBuilder(this.rootUrl, ApiService.MenuRecentListPath, 'post');
    if (params) {
      rb.body(params.body, 'application/json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<{
        'isSuccess'?: boolean;
        'message'?: string;
        'data'?: {
        'pageNumber'?: number;
        'pageSize'?: number;
        'totalRowCount'?: number;
        'items'?: Array<UserRecentMenuItem>;
        };
        }>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `menuRecentList$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  menuRecentList(params: {
    body: EntityGetRequest
  }): Observable<{
'isSuccess'?: boolean;
'message'?: string;
'data'?: {
'pageNumber'?: number;
'pageSize'?: number;
'totalRowCount'?: number;
'items'?: Array<UserRecentMenuItem>;
};
}> {

    return this.menuRecentList$Response(params).pipe(
      map((r: StrictHttpResponse<{
'isSuccess'?: boolean;
'message'?: string;
'data'?: {
'pageNumber'?: number;
'pageSize'?: number;
'totalRowCount'?: number;
'items'?: Array<UserRecentMenuItem>;
};
}>) => r.body as {
'isSuccess'?: boolean;
'message'?: string;
'data'?: {
'pageNumber'?: number;
'pageSize'?: number;
'totalRowCount'?: number;
'items'?: Array<UserRecentMenuItem>;
};
})
    );
  }

  /**
   * Path part for operation recentAddOrUpdate
   */
  static readonly RecentAddOrUpdatePath = '/v1/menu/addOrUpdate/recent';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `recentAddOrUpdate()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  recentAddOrUpdate$Response(params: {
    body: InternalRecentMenuRequest
  }): Observable<StrictHttpResponse<{
'isSuccess'?: boolean;
'message'?: string;
'data'?: string;
}>> {

    const rb = new RequestBuilder(this.rootUrl, ApiService.RecentAddOrUpdatePath, 'post');
    if (params) {
      rb.body(params.body, 'application/json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<{
        'isSuccess'?: boolean;
        'message'?: string;
        'data'?: string;
        }>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `recentAddOrUpdate$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  recentAddOrUpdate(params: {
    body: InternalRecentMenuRequest
  }): Observable<{
'isSuccess'?: boolean;
'message'?: string;
'data'?: string;
}> {

    return this.recentAddOrUpdate$Response(params).pipe(
      map((r: StrictHttpResponse<{
'isSuccess'?: boolean;
'message'?: string;
'data'?: string;
}>) => r.body as {
'isSuccess'?: boolean;
'message'?: string;
'data'?: string;
})
    );
  }

  /**
   * Path part for operation favoriteMenuAdd
   */
  static readonly FavoriteMenuAddPath = '/v1/menu/add/favorite';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `favoriteMenuAdd()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  favoriteMenuAdd$Response(params: {
    body: InternalUserXMenuRequest
  }): Observable<StrictHttpResponse<{
'isSuccess'?: boolean;
'message'?: string;
'data'?: string;
}>> {

    const rb = new RequestBuilder(this.rootUrl, ApiService.FavoriteMenuAddPath, 'post');
    if (params) {
      rb.body(params.body, 'application/json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<{
        'isSuccess'?: boolean;
        'message'?: string;
        'data'?: string;
        }>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `favoriteMenuAdd$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  favoriteMenuAdd(params: {
    body: InternalUserXMenuRequest
  }): Observable<{
'isSuccess'?: boolean;
'message'?: string;
'data'?: string;
}> {

    return this.favoriteMenuAdd$Response(params).pipe(
      map((r: StrictHttpResponse<{
'isSuccess'?: boolean;
'message'?: string;
'data'?: string;
}>) => r.body as {
'isSuccess'?: boolean;
'message'?: string;
'data'?: string;
})
    );
  }

  /**
   * Path part for operation favoriteMenuUpdate
   */
  static readonly FavoriteMenuUpdatePath = '/v1/menu/update/favorite';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `favoriteMenuUpdate()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  favoriteMenuUpdate$Response(params: {
    body: UpdateFavoriteOrderForUserRequest
  }): Observable<StrictHttpResponse<{
'isSuccess'?: boolean;
'message'?: string;
'data'?: string;
}>> {

    const rb = new RequestBuilder(this.rootUrl, ApiService.FavoriteMenuUpdatePath, 'put');
    if (params) {
      rb.body(params.body, 'application/json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<{
        'isSuccess'?: boolean;
        'message'?: string;
        'data'?: string;
        }>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `favoriteMenuUpdate$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  favoriteMenuUpdate(params: {
    body: UpdateFavoriteOrderForUserRequest
  }): Observable<{
'isSuccess'?: boolean;
'message'?: string;
'data'?: string;
}> {

    return this.favoriteMenuUpdate$Response(params).pipe(
      map((r: StrictHttpResponse<{
'isSuccess'?: boolean;
'message'?: string;
'data'?: string;
}>) => r.body as {
'isSuccess'?: boolean;
'message'?: string;
'data'?: string;
})
    );
  }

  /**
   * Path part for operation allMenuGroupList
   */
  static readonly AllMenuGroupListPath = '/v1/menu/list/allGroup';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `allMenuGroupList()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  allMenuGroupList$Response(params: {
    body: EntityGetRequest
  }): Observable<StrictHttpResponse<{
'isSuccess'?: boolean;
'message'?: string;
'data'?: {
'pageNumber'?: number;
'pageSize'?: number;
'totalRowCount'?: number;
'items'?: Array<SingleMenuGroup>;
};
}>> {

    const rb = new RequestBuilder(this.rootUrl, ApiService.AllMenuGroupListPath, 'post');
    if (params) {
      rb.body(params.body, 'application/json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<{
        'isSuccess'?: boolean;
        'message'?: string;
        'data'?: {
        'pageNumber'?: number;
        'pageSize'?: number;
        'totalRowCount'?: number;
        'items'?: Array<SingleMenuGroup>;
        };
        }>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `allMenuGroupList$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  allMenuGroupList(params: {
    body: EntityGetRequest
  }): Observable<{
'isSuccess'?: boolean;
'message'?: string;
'data'?: {
'pageNumber'?: number;
'pageSize'?: number;
'totalRowCount'?: number;
'items'?: Array<SingleMenuGroup>;
};
}> {

    return this.allMenuGroupList$Response(params).pipe(
      map((r: StrictHttpResponse<{
'isSuccess'?: boolean;
'message'?: string;
'data'?: {
'pageNumber'?: number;
'pageSize'?: number;
'totalRowCount'?: number;
'items'?: Array<SingleMenuGroup>;
};
}>) => r.body as {
'isSuccess'?: boolean;
'message'?: string;
'data'?: {
'pageNumber'?: number;
'pageSize'?: number;
'totalRowCount'?: number;
'items'?: Array<SingleMenuGroup>;
};
})
    );
  }

  /**
   * Path part for operation allMenuList
   */
  static readonly AllMenuListPath = '/v1/menu/list/all';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `allMenuList()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  allMenuList$Response(params: {
    body: EntityGetRequest
  }): Observable<StrictHttpResponse<{
'isSuccess'?: boolean;
'message'?: string;
'data'?: {
'pageNumber'?: number;
'pageSize'?: number;
'totalRowCount'?: number;
'items'?: Array<SingleMenuItem>;
};
}>> {

    const rb = new RequestBuilder(this.rootUrl, ApiService.AllMenuListPath, 'post');
    if (params) {
      rb.body(params.body, 'application/json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<{
        'isSuccess'?: boolean;
        'message'?: string;
        'data'?: {
        'pageNumber'?: number;
        'pageSize'?: number;
        'totalRowCount'?: number;
        'items'?: Array<SingleMenuItem>;
        };
        }>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `allMenuList$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  allMenuList(params: {
    body: EntityGetRequest
  }): Observable<{
'isSuccess'?: boolean;
'message'?: string;
'data'?: {
'pageNumber'?: number;
'pageSize'?: number;
'totalRowCount'?: number;
'items'?: Array<SingleMenuItem>;
};
}> {

    return this.allMenuList$Response(params).pipe(
      map((r: StrictHttpResponse<{
'isSuccess'?: boolean;
'message'?: string;
'data'?: {
'pageNumber'?: number;
'pageSize'?: number;
'totalRowCount'?: number;
'items'?: Array<SingleMenuItem>;
};
}>) => r.body as {
'isSuccess'?: boolean;
'message'?: string;
'data'?: {
'pageNumber'?: number;
'pageSize'?: number;
'totalRowCount'?: number;
'items'?: Array<SingleMenuItem>;
};
})
    );
  }

  /**
   * Path part for operation menuGroupAdd
   */
  static readonly MenuGroupAddPath = '/v1/menu/add/group';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `menuGroupAdd()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  menuGroupAdd$Response(params: {
    body: SingleMenuGroup
  }): Observable<StrictHttpResponse<{
'isSuccess'?: boolean;
'message'?: string;
'data'?: string;
}>> {

    const rb = new RequestBuilder(this.rootUrl, ApiService.MenuGroupAddPath, 'post');
    if (params) {
      rb.body(params.body, 'application/json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<{
        'isSuccess'?: boolean;
        'message'?: string;
        'data'?: string;
        }>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `menuGroupAdd$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  menuGroupAdd(params: {
    body: SingleMenuGroup
  }): Observable<{
'isSuccess'?: boolean;
'message'?: string;
'data'?: string;
}> {

    return this.menuGroupAdd$Response(params).pipe(
      map((r: StrictHttpResponse<{
'isSuccess'?: boolean;
'message'?: string;
'data'?: string;
}>) => r.body as {
'isSuccess'?: boolean;
'message'?: string;
'data'?: string;
})
    );
  }

  /**
   * Path part for operation menuGroupDelete
   */
  static readonly MenuGroupDeletePath = '/v1/menu/delete/group';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `menuGroupDelete()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  menuGroupDelete$Response(params: {
    body: DeleteRequest
  }): Observable<StrictHttpResponse<{
'isSuccess'?: boolean;
'message'?: string;
'data'?: string;
}>> {

    const rb = new RequestBuilder(this.rootUrl, ApiService.MenuGroupDeletePath, 'delete');
    if (params) {
      rb.body(params.body, 'application/json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<{
        'isSuccess'?: boolean;
        'message'?: string;
        'data'?: string;
        }>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `menuGroupDelete$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  menuGroupDelete(params: {
    body: DeleteRequest
  }): Observable<{
'isSuccess'?: boolean;
'message'?: string;
'data'?: string;
}> {

    return this.menuGroupDelete$Response(params).pipe(
      map((r: StrictHttpResponse<{
'isSuccess'?: boolean;
'message'?: string;
'data'?: string;
}>) => r.body as {
'isSuccess'?: boolean;
'message'?: string;
'data'?: string;
})
    );
  }

  /**
   * Path part for operation menuGroupUpdate
   */
  static readonly MenuGroupUpdatePath = '/v1/menu/update/group';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `menuGroupUpdate()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  menuGroupUpdate$Response(params: {
    body: SingleMenuGroup
  }): Observable<StrictHttpResponse<{
'isSuccess'?: boolean;
'message'?: string;
'data'?: string;
}>> {

    const rb = new RequestBuilder(this.rootUrl, ApiService.MenuGroupUpdatePath, 'put');
    if (params) {
      rb.body(params.body, 'application/json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<{
        'isSuccess'?: boolean;
        'message'?: string;
        'data'?: string;
        }>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `menuGroupUpdate$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  menuGroupUpdate(params: {
    body: SingleMenuGroup
  }): Observable<{
'isSuccess'?: boolean;
'message'?: string;
'data'?: string;
}> {

    return this.menuGroupUpdate$Response(params).pipe(
      map((r: StrictHttpResponse<{
'isSuccess'?: boolean;
'message'?: string;
'data'?: string;
}>) => r.body as {
'isSuccess'?: boolean;
'message'?: string;
'data'?: string;
})
    );
  }

  /**
   * Path part for operation menuGroupSortOrderUpdate
   */
  static readonly MenuGroupSortOrderUpdatePath = '/v1/menu/update/sortOrder';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `menuGroupSortOrderUpdate()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  menuGroupSortOrderUpdate$Response(params: {
    body: UpdateSortOrderForMenuGroupRequest
  }): Observable<StrictHttpResponse<{
'isSuccess'?: boolean;
'message'?: string;
'data'?: string;
}>> {

    const rb = new RequestBuilder(this.rootUrl, ApiService.MenuGroupSortOrderUpdatePath, 'put');
    if (params) {
      rb.body(params.body, 'application/json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<{
        'isSuccess'?: boolean;
        'message'?: string;
        'data'?: string;
        }>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `menuGroupSortOrderUpdate$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  menuGroupSortOrderUpdate(params: {
    body: UpdateSortOrderForMenuGroupRequest
  }): Observable<{
'isSuccess'?: boolean;
'message'?: string;
'data'?: string;
}> {

    return this.menuGroupSortOrderUpdate$Response(params).pipe(
      map((r: StrictHttpResponse<{
'isSuccess'?: boolean;
'message'?: string;
'data'?: string;
}>) => r.body as {
'isSuccess'?: boolean;
'message'?: string;
'data'?: string;
})
    );
  }

  /**
   * Path part for operation menuGroupAddOrUpdate
   */
  static readonly MenuGroupAddOrUpdatePath = '/v1/menu/addOrUpdate/group';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `menuGroupAddOrUpdate()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  menuGroupAddOrUpdate$Response(params: {
    body: MenuGroupRequest
  }): Observable<StrictHttpResponse<{
'isSuccess'?: boolean;
'message'?: string;
'data'?: string;
}>> {

    const rb = new RequestBuilder(this.rootUrl, ApiService.MenuGroupAddOrUpdatePath, 'put');
    if (params) {
      rb.body(params.body, 'application/json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<{
        'isSuccess'?: boolean;
        'message'?: string;
        'data'?: string;
        }>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `menuGroupAddOrUpdate$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  menuGroupAddOrUpdate(params: {
    body: MenuGroupRequest
  }): Observable<{
'isSuccess'?: boolean;
'message'?: string;
'data'?: string;
}> {

    return this.menuGroupAddOrUpdate$Response(params).pipe(
      map((r: StrictHttpResponse<{
'isSuccess'?: boolean;
'message'?: string;
'data'?: string;
}>) => r.body as {
'isSuccess'?: boolean;
'message'?: string;
'data'?: string;
})
    );
  }

  /**
   * Path part for operation menuAdd
   */
  static readonly MenuAddPath = '/v1/menu/add';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `menuAdd()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  menuAdd$Response(params: {
    body: MenuItemRequest
  }): Observable<StrictHttpResponse<{
'isSuccess'?: boolean;
'message'?: string;
'data'?: string;
}>> {

    const rb = new RequestBuilder(this.rootUrl, ApiService.MenuAddPath, 'post');
    if (params) {
      rb.body(params.body, 'application/json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<{
        'isSuccess'?: boolean;
        'message'?: string;
        'data'?: string;
        }>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `menuAdd$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  menuAdd(params: {
    body: MenuItemRequest
  }): Observable<{
'isSuccess'?: boolean;
'message'?: string;
'data'?: string;
}> {

    return this.menuAdd$Response(params).pipe(
      map((r: StrictHttpResponse<{
'isSuccess'?: boolean;
'message'?: string;
'data'?: string;
}>) => r.body as {
'isSuccess'?: boolean;
'message'?: string;
'data'?: string;
})
    );
  }

  /**
   * Path part for operation menuUpdate
   */
  static readonly MenuUpdatePath = '/v1/menu/update';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `menuUpdate()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  menuUpdate$Response(params: {
    body: MenuItemRequest
  }): Observable<StrictHttpResponse<{
'isSuccess'?: boolean;
'message'?: string;
'data'?: string;
}>> {

    const rb = new RequestBuilder(this.rootUrl, ApiService.MenuUpdatePath, 'put');
    if (params) {
      rb.body(params.body, 'application/json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<{
        'isSuccess'?: boolean;
        'message'?: string;
        'data'?: string;
        }>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `menuUpdate$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  menuUpdate(params: {
    body: MenuItemRequest
  }): Observable<{
'isSuccess'?: boolean;
'message'?: string;
'data'?: string;
}> {

    return this.menuUpdate$Response(params).pipe(
      map((r: StrictHttpResponse<{
'isSuccess'?: boolean;
'message'?: string;
'data'?: string;
}>) => r.body as {
'isSuccess'?: boolean;
'message'?: string;
'data'?: string;
})
    );
  }

  /**
   * Path part for operation allMenuForAMenuGroupList
   */
  static readonly AllMenuForAMenuGroupListPath = '/v1/menu/allList/group';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `allMenuForAMenuGroupList()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  allMenuForAMenuGroupList$Response(params: {
    body: AllMenuForMenuGroupRequest
  }): Observable<StrictHttpResponse<{
'isSuccess'?: boolean;
'message'?: string;
'data'?: {
'pageNumber'?: number;
'pageSize'?: number;
'totalRowCount'?: number;
'items'?: Array<SingleMenuItem>;
};
}>> {

    const rb = new RequestBuilder(this.rootUrl, ApiService.AllMenuForAMenuGroupListPath, 'post');
    if (params) {
      rb.body(params.body, 'application/json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<{
        'isSuccess'?: boolean;
        'message'?: string;
        'data'?: {
        'pageNumber'?: number;
        'pageSize'?: number;
        'totalRowCount'?: number;
        'items'?: Array<SingleMenuItem>;
        };
        }>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `allMenuForAMenuGroupList$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  allMenuForAMenuGroupList(params: {
    body: AllMenuForMenuGroupRequest
  }): Observable<{
'isSuccess'?: boolean;
'message'?: string;
'data'?: {
'pageNumber'?: number;
'pageSize'?: number;
'totalRowCount'?: number;
'items'?: Array<SingleMenuItem>;
};
}> {

    return this.allMenuForAMenuGroupList$Response(params).pipe(
      map((r: StrictHttpResponse<{
'isSuccess'?: boolean;
'message'?: string;
'data'?: {
'pageNumber'?: number;
'pageSize'?: number;
'totalRowCount'?: number;
'items'?: Array<SingleMenuItem>;
};
}>) => r.body as {
'isSuccess'?: boolean;
'message'?: string;
'data'?: {
'pageNumber'?: number;
'pageSize'?: number;
'totalRowCount'?: number;
'items'?: Array<SingleMenuItem>;
};
})
    );
  }

  /**
   * Path part for operation menuDelete
   */
  static readonly MenuDeletePath = '/v1/menu/delete';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `menuDelete()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  menuDelete$Response(params: {
    body: DeleteRequest
  }): Observable<StrictHttpResponse<{
'isSuccess'?: boolean;
'message'?: string;
'data'?: string;
}>> {

    const rb = new RequestBuilder(this.rootUrl, ApiService.MenuDeletePath, 'delete');
    if (params) {
      rb.body(params.body, 'application/json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<{
        'isSuccess'?: boolean;
        'message'?: string;
        'data'?: string;
        }>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `menuDelete$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  menuDelete(params: {
    body: DeleteRequest
  }): Observable<{
'isSuccess'?: boolean;
'message'?: string;
'data'?: string;
}> {

    return this.menuDelete$Response(params).pipe(
      map((r: StrictHttpResponse<{
'isSuccess'?: boolean;
'message'?: string;
'data'?: string;
}>) => r.body as {
'isSuccess'?: boolean;
'message'?: string;
'data'?: string;
})
    );
  }

  /**
   * Path part for operation getMenuGroupDetails
   */
  static readonly GetMenuGroupDetailsPath = '/v1/menu/fetch/menuGroup{internalMenuGroupID}';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getMenuGroupDetails()` instead.
   *
   * This method doesn't expect any request body.
   */
  getMenuGroupDetails$Response(params: {
    internalMenuGroupID: string;
  }): Observable<StrictHttpResponse<{
'isSuccess'?: boolean;
'message'?: string;
'data'?: {
'pageNumber'?: number;
'pageSize'?: number;
'totalRowCount'?: number;
'items'?: Array<MenuList>;
};
}>> {

    const rb = new RequestBuilder(this.rootUrl, ApiService.GetMenuGroupDetailsPath, 'get');
    if (params) {
      rb.path('internalMenuGroupID', params.internalMenuGroupID, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<{
        'isSuccess'?: boolean;
        'message'?: string;
        'data'?: {
        'pageNumber'?: number;
        'pageSize'?: number;
        'totalRowCount'?: number;
        'items'?: Array<MenuList>;
        };
        }>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `getMenuGroupDetails$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getMenuGroupDetails(params: {
    internalMenuGroupID: string;
  }): Observable<{
'isSuccess'?: boolean;
'message'?: string;
'data'?: {
'pageNumber'?: number;
'pageSize'?: number;
'totalRowCount'?: number;
'items'?: Array<MenuList>;
};
}> {

    return this.getMenuGroupDetails$Response(params).pipe(
      map((r: StrictHttpResponse<{
'isSuccess'?: boolean;
'message'?: string;
'data'?: {
'pageNumber'?: number;
'pageSize'?: number;
'totalRowCount'?: number;
'items'?: Array<MenuList>;
};
}>) => r.body as {
'isSuccess'?: boolean;
'message'?: string;
'data'?: {
'pageNumber'?: number;
'pageSize'?: number;
'totalRowCount'?: number;
'items'?: Array<MenuList>;
};
})
    );
  }

  /**
   * Path part for operation addUpdateMenuGroupItem
   */
  static readonly AddUpdateMenuGroupItemPath = '/v1/menu/addUpdate/groupItem';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `addUpdateMenuGroupItem()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  addUpdateMenuGroupItem$Response(params: {
    body: MenuGroupRequest
  }): Observable<StrictHttpResponse<{
'isSuccess'?: boolean;
'message'?: string;
'data'?: string;
}>> {

    const rb = new RequestBuilder(this.rootUrl, ApiService.AddUpdateMenuGroupItemPath, 'put');
    if (params) {
      rb.body(params.body, 'application/json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<{
        'isSuccess'?: boolean;
        'message'?: string;
        'data'?: string;
        }>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `addUpdateMenuGroupItem$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  addUpdateMenuGroupItem(params: {
    body: MenuGroupRequest
  }): Observable<{
'isSuccess'?: boolean;
'message'?: string;
'data'?: string;
}> {

    return this.addUpdateMenuGroupItem$Response(params).pipe(
      map((r: StrictHttpResponse<{
'isSuccess'?: boolean;
'message'?: string;
'data'?: string;
}>) => r.body as {
'isSuccess'?: boolean;
'message'?: string;
'data'?: string;
})
    );
  }

  /**
   * Path part for operation getCurrentWsjPrimeRates
   */
  static readonly GetCurrentWsjPrimeRatesPath = '/v1/adjustRates/getCurrentWsjPrimeRates';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getCurrentWsjPrimeRates()` instead.
   *
   * This method doesn't expect any request body.
   */
  getCurrentWsjPrimeRates$Response(params?: {
  }): Observable<StrictHttpResponse<{
'isSuccess'?: boolean;
'message'?: string;
'data'?: {
'pageNumber'?: number;
'pageSize'?: number;
'totalRowCount'?: number;
'items'?: Array<WsjPrimeRate>;
};
}>> {

    const rb = new RequestBuilder(this.rootUrl, ApiService.GetCurrentWsjPrimeRatesPath, 'post');
    if (params) {
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<{
        'isSuccess'?: boolean;
        'message'?: string;
        'data'?: {
        'pageNumber'?: number;
        'pageSize'?: number;
        'totalRowCount'?: number;
        'items'?: Array<WsjPrimeRate>;
        };
        }>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `getCurrentWsjPrimeRates$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getCurrentWsjPrimeRates(params?: {
  }): Observable<{
'isSuccess'?: boolean;
'message'?: string;
'data'?: {
'pageNumber'?: number;
'pageSize'?: number;
'totalRowCount'?: number;
'items'?: Array<WsjPrimeRate>;
};
}> {

    return this.getCurrentWsjPrimeRates$Response(params).pipe(
      map((r: StrictHttpResponse<{
'isSuccess'?: boolean;
'message'?: string;
'data'?: {
'pageNumber'?: number;
'pageSize'?: number;
'totalRowCount'?: number;
'items'?: Array<WsjPrimeRate>;
};
}>) => r.body as {
'isSuccess'?: boolean;
'message'?: string;
'data'?: {
'pageNumber'?: number;
'pageSize'?: number;
'totalRowCount'?: number;
'items'?: Array<WsjPrimeRate>;
};
})
    );
  }

  /**
   * Path part for operation getCurrentCreditCardBalanceTransferFeeRates
   */
  static readonly GetCurrentCreditCardBalanceTransferFeeRatesPath = '/v1/adjustRates/getCurrentCreditCardBalanceTransferFeeRates';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getCurrentCreditCardBalanceTransferFeeRates()` instead.
   *
   * This method doesn't expect any request body.
   */
  getCurrentCreditCardBalanceTransferFeeRates$Response(params?: {
  }): Observable<StrictHttpResponse<{
'isSuccess'?: boolean;
'message'?: string;
'data'?: {
'pageNumber'?: number;
'pageSize'?: number;
'totalRowCount'?: number;
'items'?: Array<CreditCardBalanceTransferFeeRate>;
};
}>> {

    const rb = new RequestBuilder(this.rootUrl, ApiService.GetCurrentCreditCardBalanceTransferFeeRatesPath, 'post');
    if (params) {
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<{
        'isSuccess'?: boolean;
        'message'?: string;
        'data'?: {
        'pageNumber'?: number;
        'pageSize'?: number;
        'totalRowCount'?: number;
        'items'?: Array<CreditCardBalanceTransferFeeRate>;
        };
        }>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `getCurrentCreditCardBalanceTransferFeeRates$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getCurrentCreditCardBalanceTransferFeeRates(params?: {
  }): Observable<{
'isSuccess'?: boolean;
'message'?: string;
'data'?: {
'pageNumber'?: number;
'pageSize'?: number;
'totalRowCount'?: number;
'items'?: Array<CreditCardBalanceTransferFeeRate>;
};
}> {

    return this.getCurrentCreditCardBalanceTransferFeeRates$Response(params).pipe(
      map((r: StrictHttpResponse<{
'isSuccess'?: boolean;
'message'?: string;
'data'?: {
'pageNumber'?: number;
'pageSize'?: number;
'totalRowCount'?: number;
'items'?: Array<CreditCardBalanceTransferFeeRate>;
};
}>) => r.body as {
'isSuccess'?: boolean;
'message'?: string;
'data'?: {
'pageNumber'?: number;
'pageSize'?: number;
'totalRowCount'?: number;
'items'?: Array<CreditCardBalanceTransferFeeRate>;
};
})
    );
  }

  /**
   * Path part for operation getCurrentCreditCardIntroAprRates
   */
  static readonly GetCurrentCreditCardIntroAprRatesPath = '/v1/adjustRates/getCurrentCreditCardIntroAprRates';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getCurrentCreditCardIntroAprRates()` instead.
   *
   * This method doesn't expect any request body.
   */
  getCurrentCreditCardIntroAprRates$Response(params?: {
  }): Observable<StrictHttpResponse<{
'isSuccess'?: boolean;
'message'?: string;
'data'?: {
'pageNumber'?: number;
'pageSize'?: number;
'totalRowCount'?: number;
'items'?: Array<CreditCardIntroAprRate>;
};
}>> {

    const rb = new RequestBuilder(this.rootUrl, ApiService.GetCurrentCreditCardIntroAprRatesPath, 'post');
    if (params) {
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<{
        'isSuccess'?: boolean;
        'message'?: string;
        'data'?: {
        'pageNumber'?: number;
        'pageSize'?: number;
        'totalRowCount'?: number;
        'items'?: Array<CreditCardIntroAprRate>;
        };
        }>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `getCurrentCreditCardIntroAprRates$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getCurrentCreditCardIntroAprRates(params?: {
  }): Observable<{
'isSuccess'?: boolean;
'message'?: string;
'data'?: {
'pageNumber'?: number;
'pageSize'?: number;
'totalRowCount'?: number;
'items'?: Array<CreditCardIntroAprRate>;
};
}> {

    return this.getCurrentCreditCardIntroAprRates$Response(params).pipe(
      map((r: StrictHttpResponse<{
'isSuccess'?: boolean;
'message'?: string;
'data'?: {
'pageNumber'?: number;
'pageSize'?: number;
'totalRowCount'?: number;
'items'?: Array<CreditCardIntroAprRate>;
};
}>) => r.body as {
'isSuccess'?: boolean;
'message'?: string;
'data'?: {
'pageNumber'?: number;
'pageSize'?: number;
'totalRowCount'?: number;
'items'?: Array<CreditCardIntroAprRate>;
};
})
    );
  }

  /**
   * Path part for operation getCurrentCreditCardVariableAprRates
   */
  static readonly GetCurrentCreditCardVariableAprRatesPath = '/v1/adjustRates/getCurrentCreditCardVariableAprRates';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getCurrentCreditCardVariableAprRates()` instead.
   *
   * This method doesn't expect any request body.
   */
  getCurrentCreditCardVariableAprRates$Response(params?: {
  }): Observable<StrictHttpResponse<{
'isSuccess'?: boolean;
'message'?: string;
'data'?: {
'pageNumber'?: number;
'pageSize'?: number;
'totalRowCount'?: number;
'items'?: Array<CreditCardVariableAprRate>;
};
}>> {

    const rb = new RequestBuilder(this.rootUrl, ApiService.GetCurrentCreditCardVariableAprRatesPath, 'post');
    if (params) {
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<{
        'isSuccess'?: boolean;
        'message'?: string;
        'data'?: {
        'pageNumber'?: number;
        'pageSize'?: number;
        'totalRowCount'?: number;
        'items'?: Array<CreditCardVariableAprRate>;
        };
        }>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `getCurrentCreditCardVariableAprRates$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getCurrentCreditCardVariableAprRates(params?: {
  }): Observable<{
'isSuccess'?: boolean;
'message'?: string;
'data'?: {
'pageNumber'?: number;
'pageSize'?: number;
'totalRowCount'?: number;
'items'?: Array<CreditCardVariableAprRate>;
};
}> {

    return this.getCurrentCreditCardVariableAprRates$Response(params).pipe(
      map((r: StrictHttpResponse<{
'isSuccess'?: boolean;
'message'?: string;
'data'?: {
'pageNumber'?: number;
'pageSize'?: number;
'totalRowCount'?: number;
'items'?: Array<CreditCardVariableAprRate>;
};
}>) => r.body as {
'isSuccess'?: boolean;
'message'?: string;
'data'?: {
'pageNumber'?: number;
'pageSize'?: number;
'totalRowCount'?: number;
'items'?: Array<CreditCardVariableAprRate>;
};
})
    );
  }

  /**
   * Path part for operation getCurrentCDs13MonthPromoRates
   */
  static readonly GetCurrentCDs13MonthPromoRatesPath = '/v1/adjustRates/getCurrentCDs13MonthPromoRates';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getCurrentCDs13MonthPromoRates()` instead.
   *
   * This method doesn't expect any request body.
   */
  getCurrentCDs13MonthPromoRates$Response(params?: {
  }): Observable<StrictHttpResponse<{
'isSuccess'?: boolean;
'message'?: string;
'data'?: {
'pageNumber'?: number;
'pageSize'?: number;
'totalRowCount'?: number;
'items'?: Array<CDs13MonthPromoRate>;
};
}>> {

    const rb = new RequestBuilder(this.rootUrl, ApiService.GetCurrentCDs13MonthPromoRatesPath, 'post');
    if (params) {
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<{
        'isSuccess'?: boolean;
        'message'?: string;
        'data'?: {
        'pageNumber'?: number;
        'pageSize'?: number;
        'totalRowCount'?: number;
        'items'?: Array<CDs13MonthPromoRate>;
        };
        }>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `getCurrentCDs13MonthPromoRates$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getCurrentCDs13MonthPromoRates(params?: {
  }): Observable<{
'isSuccess'?: boolean;
'message'?: string;
'data'?: {
'pageNumber'?: number;
'pageSize'?: number;
'totalRowCount'?: number;
'items'?: Array<CDs13MonthPromoRate>;
};
}> {

    return this.getCurrentCDs13MonthPromoRates$Response(params).pipe(
      map((r: StrictHttpResponse<{
'isSuccess'?: boolean;
'message'?: string;
'data'?: {
'pageNumber'?: number;
'pageSize'?: number;
'totalRowCount'?: number;
'items'?: Array<CDs13MonthPromoRate>;
};
}>) => r.body as {
'isSuccess'?: boolean;
'message'?: string;
'data'?: {
'pageNumber'?: number;
'pageSize'?: number;
'totalRowCount'?: number;
'items'?: Array<CDs13MonthPromoRate>;
};
})
    );
  }

  /**
   * Path part for operation getCurrentCDs17MonthPromoRates
   */
  static readonly GetCurrentCDs17MonthPromoRatesPath = '/v1/adjustRates/getCurrentCDs17MonthPromoRates';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getCurrentCDs17MonthPromoRates()` instead.
   *
   * This method doesn't expect any request body.
   */
  getCurrentCDs17MonthPromoRates$Response(params?: {
  }): Observable<StrictHttpResponse<{
'isSuccess'?: boolean;
'message'?: string;
'data'?: {
'pageNumber'?: number;
'pageSize'?: number;
'totalRowCount'?: number;
'items'?: Array<CDs17MonthPromoRate>;
};
}>> {

    const rb = new RequestBuilder(this.rootUrl, ApiService.GetCurrentCDs17MonthPromoRatesPath, 'post');
    if (params) {
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<{
        'isSuccess'?: boolean;
        'message'?: string;
        'data'?: {
        'pageNumber'?: number;
        'pageSize'?: number;
        'totalRowCount'?: number;
        'items'?: Array<CDs17MonthPromoRate>;
        };
        }>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `getCurrentCDs17MonthPromoRates$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getCurrentCDs17MonthPromoRates(params?: {
  }): Observable<{
'isSuccess'?: boolean;
'message'?: string;
'data'?: {
'pageNumber'?: number;
'pageSize'?: number;
'totalRowCount'?: number;
'items'?: Array<CDs17MonthPromoRate>;
};
}> {

    return this.getCurrentCDs17MonthPromoRates$Response(params).pipe(
      map((r: StrictHttpResponse<{
'isSuccess'?: boolean;
'message'?: string;
'data'?: {
'pageNumber'?: number;
'pageSize'?: number;
'totalRowCount'?: number;
'items'?: Array<CDs17MonthPromoRate>;
};
}>) => r.body as {
'isSuccess'?: boolean;
'message'?: string;
'data'?: {
'pageNumber'?: number;
'pageSize'?: number;
'totalRowCount'?: number;
'items'?: Array<CDs17MonthPromoRate>;
};
})
    );
  }

  /**
   * Path part for operation getWsjPrimeRatesHistory
   */
  static readonly GetWsjPrimeRatesHistoryPath = '/v1/adjustRates/getWsjPrimeRateHistory';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getWsjPrimeRatesHistory()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  getWsjPrimeRatesHistory$Response(params: {
    body: FilteredRateGetRequest
  }): Observable<StrictHttpResponse<{
'isSuccess'?: boolean;
'message'?: string;
'data'?: {
'pageNumber'?: number;
'pageSize'?: number;
'totalRowCount'?: number;
'items'?: Array<WsjPrimeRate>;
};
}>> {

    const rb = new RequestBuilder(this.rootUrl, ApiService.GetWsjPrimeRatesHistoryPath, 'post');
    if (params) {
      rb.body(params.body, 'application/json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<{
        'isSuccess'?: boolean;
        'message'?: string;
        'data'?: {
        'pageNumber'?: number;
        'pageSize'?: number;
        'totalRowCount'?: number;
        'items'?: Array<WsjPrimeRate>;
        };
        }>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `getWsjPrimeRatesHistory$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  getWsjPrimeRatesHistory(params: {
    body: FilteredRateGetRequest
  }): Observable<{
'isSuccess'?: boolean;
'message'?: string;
'data'?: {
'pageNumber'?: number;
'pageSize'?: number;
'totalRowCount'?: number;
'items'?: Array<WsjPrimeRate>;
};
}> {

    return this.getWsjPrimeRatesHistory$Response(params).pipe(
      map((r: StrictHttpResponse<{
'isSuccess'?: boolean;
'message'?: string;
'data'?: {
'pageNumber'?: number;
'pageSize'?: number;
'totalRowCount'?: number;
'items'?: Array<WsjPrimeRate>;
};
}>) => r.body as {
'isSuccess'?: boolean;
'message'?: string;
'data'?: {
'pageNumber'?: number;
'pageSize'?: number;
'totalRowCount'?: number;
'items'?: Array<WsjPrimeRate>;
};
})
    );
  }

  /**
   * Path part for operation getCreditCardBalanceTransferFeeRateHistory
   */
  static readonly GetCreditCardBalanceTransferFeeRateHistoryPath = '/v1/adjustRates/getCreditCardBalanceTransferFeeRateHistory';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getCreditCardBalanceTransferFeeRateHistory()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  getCreditCardBalanceTransferFeeRateHistory$Response(params: {
    body: FilteredRateGetRequest
  }): Observable<StrictHttpResponse<{
'isSuccess'?: boolean;
'message'?: string;
'data'?: {
'pageNumber'?: number;
'pageSize'?: number;
'totalRowCount'?: number;
'items'?: Array<CreditCardBalanceTransferFeeRate>;
};
}>> {

    const rb = new RequestBuilder(this.rootUrl, ApiService.GetCreditCardBalanceTransferFeeRateHistoryPath, 'post');
    if (params) {
      rb.body(params.body, 'application/json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<{
        'isSuccess'?: boolean;
        'message'?: string;
        'data'?: {
        'pageNumber'?: number;
        'pageSize'?: number;
        'totalRowCount'?: number;
        'items'?: Array<CreditCardBalanceTransferFeeRate>;
        };
        }>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `getCreditCardBalanceTransferFeeRateHistory$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  getCreditCardBalanceTransferFeeRateHistory(params: {
    body: FilteredRateGetRequest
  }): Observable<{
'isSuccess'?: boolean;
'message'?: string;
'data'?: {
'pageNumber'?: number;
'pageSize'?: number;
'totalRowCount'?: number;
'items'?: Array<CreditCardBalanceTransferFeeRate>;
};
}> {

    return this.getCreditCardBalanceTransferFeeRateHistory$Response(params).pipe(
      map((r: StrictHttpResponse<{
'isSuccess'?: boolean;
'message'?: string;
'data'?: {
'pageNumber'?: number;
'pageSize'?: number;
'totalRowCount'?: number;
'items'?: Array<CreditCardBalanceTransferFeeRate>;
};
}>) => r.body as {
'isSuccess'?: boolean;
'message'?: string;
'data'?: {
'pageNumber'?: number;
'pageSize'?: number;
'totalRowCount'?: number;
'items'?: Array<CreditCardBalanceTransferFeeRate>;
};
})
    );
  }

  /**
   * Path part for operation getCreditCardIntroAprRateHistory
   */
  static readonly GetCreditCardIntroAprRateHistoryPath = '/v1/adjustRates/getCreditCardIntroAprRateHistory';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getCreditCardIntroAprRateHistory()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  getCreditCardIntroAprRateHistory$Response(params: {
    body: FilteredRateGetRequest
  }): Observable<StrictHttpResponse<{
'isSuccess'?: boolean;
'message'?: string;
'data'?: {
'pageNumber'?: number;
'pageSize'?: number;
'totalRowCount'?: number;
'items'?: Array<CreditCardIntroAprRate>;
};
}>> {

    const rb = new RequestBuilder(this.rootUrl, ApiService.GetCreditCardIntroAprRateHistoryPath, 'post');
    if (params) {
      rb.body(params.body, 'application/json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<{
        'isSuccess'?: boolean;
        'message'?: string;
        'data'?: {
        'pageNumber'?: number;
        'pageSize'?: number;
        'totalRowCount'?: number;
        'items'?: Array<CreditCardIntroAprRate>;
        };
        }>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `getCreditCardIntroAprRateHistory$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  getCreditCardIntroAprRateHistory(params: {
    body: FilteredRateGetRequest
  }): Observable<{
'isSuccess'?: boolean;
'message'?: string;
'data'?: {
'pageNumber'?: number;
'pageSize'?: number;
'totalRowCount'?: number;
'items'?: Array<CreditCardIntroAprRate>;
};
}> {

    return this.getCreditCardIntroAprRateHistory$Response(params).pipe(
      map((r: StrictHttpResponse<{
'isSuccess'?: boolean;
'message'?: string;
'data'?: {
'pageNumber'?: number;
'pageSize'?: number;
'totalRowCount'?: number;
'items'?: Array<CreditCardIntroAprRate>;
};
}>) => r.body as {
'isSuccess'?: boolean;
'message'?: string;
'data'?: {
'pageNumber'?: number;
'pageSize'?: number;
'totalRowCount'?: number;
'items'?: Array<CreditCardIntroAprRate>;
};
})
    );
  }

  /**
   * Path part for operation getCreditCardVariableAprRateHistory
   */
  static readonly GetCreditCardVariableAprRateHistoryPath = '/v1/adjustRates/getCreditCardVariableAprRateHistory';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getCreditCardVariableAprRateHistory()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  getCreditCardVariableAprRateHistory$Response(params: {
    body: FilteredRateGetRequest
  }): Observable<StrictHttpResponse<{
'isSuccess'?: boolean;
'message'?: string;
'data'?: {
'pageNumber'?: number;
'pageSize'?: number;
'totalRowCount'?: number;
'items'?: Array<CreditCardVariableAprRate>;
};
}>> {

    const rb = new RequestBuilder(this.rootUrl, ApiService.GetCreditCardVariableAprRateHistoryPath, 'post');
    if (params) {
      rb.body(params.body, 'application/json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<{
        'isSuccess'?: boolean;
        'message'?: string;
        'data'?: {
        'pageNumber'?: number;
        'pageSize'?: number;
        'totalRowCount'?: number;
        'items'?: Array<CreditCardVariableAprRate>;
        };
        }>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `getCreditCardVariableAprRateHistory$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  getCreditCardVariableAprRateHistory(params: {
    body: FilteredRateGetRequest
  }): Observable<{
'isSuccess'?: boolean;
'message'?: string;
'data'?: {
'pageNumber'?: number;
'pageSize'?: number;
'totalRowCount'?: number;
'items'?: Array<CreditCardVariableAprRate>;
};
}> {

    return this.getCreditCardVariableAprRateHistory$Response(params).pipe(
      map((r: StrictHttpResponse<{
'isSuccess'?: boolean;
'message'?: string;
'data'?: {
'pageNumber'?: number;
'pageSize'?: number;
'totalRowCount'?: number;
'items'?: Array<CreditCardVariableAprRate>;
};
}>) => r.body as {
'isSuccess'?: boolean;
'message'?: string;
'data'?: {
'pageNumber'?: number;
'pageSize'?: number;
'totalRowCount'?: number;
'items'?: Array<CreditCardVariableAprRate>;
};
})
    );
  }

  /**
   * Path part for operation getCDs13MonthPromoRateHistory
   */
  static readonly GetCDs13MonthPromoRateHistoryPath = '/v1/adjustRates/getCDs13MonthPromoRateHistory';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getCDs13MonthPromoRateHistory()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  getCDs13MonthPromoRateHistory$Response(params: {
    body: FilteredRateGetRequest
  }): Observable<StrictHttpResponse<{
'isSuccess'?: boolean;
'message'?: string;
'data'?: {
'pageNumber'?: number;
'pageSize'?: number;
'totalRowCount'?: number;
'items'?: Array<CDs13MonthPromoRate>;
};
}>> {

    const rb = new RequestBuilder(this.rootUrl, ApiService.GetCDs13MonthPromoRateHistoryPath, 'post');
    if (params) {
      rb.body(params.body, 'application/json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<{
        'isSuccess'?: boolean;
        'message'?: string;
        'data'?: {
        'pageNumber'?: number;
        'pageSize'?: number;
        'totalRowCount'?: number;
        'items'?: Array<CDs13MonthPromoRate>;
        };
        }>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `getCDs13MonthPromoRateHistory$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  getCDs13MonthPromoRateHistory(params: {
    body: FilteredRateGetRequest
  }): Observable<{
'isSuccess'?: boolean;
'message'?: string;
'data'?: {
'pageNumber'?: number;
'pageSize'?: number;
'totalRowCount'?: number;
'items'?: Array<CDs13MonthPromoRate>;
};
}> {

    return this.getCDs13MonthPromoRateHistory$Response(params).pipe(
      map((r: StrictHttpResponse<{
'isSuccess'?: boolean;
'message'?: string;
'data'?: {
'pageNumber'?: number;
'pageSize'?: number;
'totalRowCount'?: number;
'items'?: Array<CDs13MonthPromoRate>;
};
}>) => r.body as {
'isSuccess'?: boolean;
'message'?: string;
'data'?: {
'pageNumber'?: number;
'pageSize'?: number;
'totalRowCount'?: number;
'items'?: Array<CDs13MonthPromoRate>;
};
})
    );
  }

  /**
   * Path part for operation getCDs17MonthPromoRateHistory
   */
  static readonly GetCDs17MonthPromoRateHistoryPath = '/v1/adjustRates/getCDs17MonthPromoRateHistory';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getCDs17MonthPromoRateHistory()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  getCDs17MonthPromoRateHistory$Response(params: {
    body: FilteredRateGetRequest
  }): Observable<StrictHttpResponse<{
'isSuccess'?: boolean;
'message'?: string;
'data'?: {
'pageNumber'?: number;
'pageSize'?: number;
'totalRowCount'?: number;
'items'?: Array<CDs17MonthPromoRate>;
};
}>> {

    const rb = new RequestBuilder(this.rootUrl, ApiService.GetCDs17MonthPromoRateHistoryPath, 'post');
    if (params) {
      rb.body(params.body, 'application/json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<{
        'isSuccess'?: boolean;
        'message'?: string;
        'data'?: {
        'pageNumber'?: number;
        'pageSize'?: number;
        'totalRowCount'?: number;
        'items'?: Array<CDs17MonthPromoRate>;
        };
        }>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `getCDs17MonthPromoRateHistory$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  getCDs17MonthPromoRateHistory(params: {
    body: FilteredRateGetRequest
  }): Observable<{
'isSuccess'?: boolean;
'message'?: string;
'data'?: {
'pageNumber'?: number;
'pageSize'?: number;
'totalRowCount'?: number;
'items'?: Array<CDs17MonthPromoRate>;
};
}> {

    return this.getCDs17MonthPromoRateHistory$Response(params).pipe(
      map((r: StrictHttpResponse<{
'isSuccess'?: boolean;
'message'?: string;
'data'?: {
'pageNumber'?: number;
'pageSize'?: number;
'totalRowCount'?: number;
'items'?: Array<CDs17MonthPromoRate>;
};
}>) => r.body as {
'isSuccess'?: boolean;
'message'?: string;
'data'?: {
'pageNumber'?: number;
'pageSize'?: number;
'totalRowCount'?: number;
'items'?: Array<CDs17MonthPromoRate>;
};
})
    );
  }

  /**
   * Path part for operation getCreditCardIntroAprTimeOptions
   */
  static readonly GetCreditCardIntroAprTimeOptionsPath = '/v1/adjustRates/getCreditCardIntroAprTimeOptions';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getCreditCardIntroAprTimeOptions()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  getCreditCardIntroAprTimeOptions$Response(params: {
    body: EntityGetRequest
  }): Observable<StrictHttpResponse<{
'isSuccess'?: boolean;
'message'?: string;
'data'?: {
'pageNumber'?: number;
'pageSize'?: number;
'totalRowCount'?: number;
'items'?: Array<CreditCardIntroAprTime>;
};
}>> {

    const rb = new RequestBuilder(this.rootUrl, ApiService.GetCreditCardIntroAprTimeOptionsPath, 'post');
    if (params) {
      rb.body(params.body, 'application/json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<{
        'isSuccess'?: boolean;
        'message'?: string;
        'data'?: {
        'pageNumber'?: number;
        'pageSize'?: number;
        'totalRowCount'?: number;
        'items'?: Array<CreditCardIntroAprTime>;
        };
        }>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `getCreditCardIntroAprTimeOptions$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  getCreditCardIntroAprTimeOptions(params: {
    body: EntityGetRequest
  }): Observable<{
'isSuccess'?: boolean;
'message'?: string;
'data'?: {
'pageNumber'?: number;
'pageSize'?: number;
'totalRowCount'?: number;
'items'?: Array<CreditCardIntroAprTime>;
};
}> {

    return this.getCreditCardIntroAprTimeOptions$Response(params).pipe(
      map((r: StrictHttpResponse<{
'isSuccess'?: boolean;
'message'?: string;
'data'?: {
'pageNumber'?: number;
'pageSize'?: number;
'totalRowCount'?: number;
'items'?: Array<CreditCardIntroAprTime>;
};
}>) => r.body as {
'isSuccess'?: boolean;
'message'?: string;
'data'?: {
'pageNumber'?: number;
'pageSize'?: number;
'totalRowCount'?: number;
'items'?: Array<CreditCardIntroAprTime>;
};
})
    );
  }

  /**
   * Path part for operation postWsjPrimeRate
   */
  static readonly PostWsjPrimeRatePath = '/v1/adjustRates/postWsjPrimeRate';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `postWsjPrimeRate()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  postWsjPrimeRate$Response(params: {
    body: AdjustWsjPrimeRateRequest
  }): Observable<StrictHttpResponse<{
'isSuccess'?: boolean;
'message'?: string;
'data'?: string;
}>> {

    const rb = new RequestBuilder(this.rootUrl, ApiService.PostWsjPrimeRatePath, 'post');
    if (params) {
      rb.body(params.body, 'application/json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<{
        'isSuccess'?: boolean;
        'message'?: string;
        'data'?: string;
        }>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `postWsjPrimeRate$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  postWsjPrimeRate(params: {
    body: AdjustWsjPrimeRateRequest
  }): Observable<{
'isSuccess'?: boolean;
'message'?: string;
'data'?: string;
}> {

    return this.postWsjPrimeRate$Response(params).pipe(
      map((r: StrictHttpResponse<{
'isSuccess'?: boolean;
'message'?: string;
'data'?: string;
}>) => r.body as {
'isSuccess'?: boolean;
'message'?: string;
'data'?: string;
})
    );
  }

  /**
   * Path part for operation postCreditCardBalanceTransferFeeRate
   */
  static readonly PostCreditCardBalanceTransferFeeRatePath = '/v1/adjustRates/postCreditCardBalanceTransferFeeRate';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `postCreditCardBalanceTransferFeeRate()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  postCreditCardBalanceTransferFeeRate$Response(params: {
    body: AdjustCreditCardBalanceTransferFeeRateRequest
  }): Observable<StrictHttpResponse<{
'isSuccess'?: boolean;
'message'?: string;
'data'?: string;
}>> {

    const rb = new RequestBuilder(this.rootUrl, ApiService.PostCreditCardBalanceTransferFeeRatePath, 'post');
    if (params) {
      rb.body(params.body, 'application/json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<{
        'isSuccess'?: boolean;
        'message'?: string;
        'data'?: string;
        }>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `postCreditCardBalanceTransferFeeRate$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  postCreditCardBalanceTransferFeeRate(params: {
    body: AdjustCreditCardBalanceTransferFeeRateRequest
  }): Observable<{
'isSuccess'?: boolean;
'message'?: string;
'data'?: string;
}> {

    return this.postCreditCardBalanceTransferFeeRate$Response(params).pipe(
      map((r: StrictHttpResponse<{
'isSuccess'?: boolean;
'message'?: string;
'data'?: string;
}>) => r.body as {
'isSuccess'?: boolean;
'message'?: string;
'data'?: string;
})
    );
  }

  /**
   * Path part for operation postCreditCardIntroAprRate
   */
  static readonly PostCreditCardIntroAprRatePath = '/v1/adjustRates/postCreditCardIntroAprRate';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `postCreditCardIntroAprRate()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  postCreditCardIntroAprRate$Response(params: {
    body: AdjustCreditCardIntroAprRateRequest
  }): Observable<StrictHttpResponse<{
'isSuccess'?: boolean;
'message'?: string;
'data'?: string;
}>> {

    const rb = new RequestBuilder(this.rootUrl, ApiService.PostCreditCardIntroAprRatePath, 'post');
    if (params) {
      rb.body(params.body, 'application/json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<{
        'isSuccess'?: boolean;
        'message'?: string;
        'data'?: string;
        }>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `postCreditCardIntroAprRate$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  postCreditCardIntroAprRate(params: {
    body: AdjustCreditCardIntroAprRateRequest
  }): Observable<{
'isSuccess'?: boolean;
'message'?: string;
'data'?: string;
}> {

    return this.postCreditCardIntroAprRate$Response(params).pipe(
      map((r: StrictHttpResponse<{
'isSuccess'?: boolean;
'message'?: string;
'data'?: string;
}>) => r.body as {
'isSuccess'?: boolean;
'message'?: string;
'data'?: string;
})
    );
  }

  /**
   * Path part for operation postCreditCardVariableAprRate
   */
  static readonly PostCreditCardVariableAprRatePath = '/v1/adjustRates/postCreditCardVariableAprRate';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `postCreditCardVariableAprRate()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  postCreditCardVariableAprRate$Response(params: {
    body: AdjustCreditCardVariableAprRateRequest
  }): Observable<StrictHttpResponse<{
'isSuccess'?: boolean;
'message'?: string;
'data'?: string;
}>> {

    const rb = new RequestBuilder(this.rootUrl, ApiService.PostCreditCardVariableAprRatePath, 'post');
    if (params) {
      rb.body(params.body, 'application/json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<{
        'isSuccess'?: boolean;
        'message'?: string;
        'data'?: string;
        }>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `postCreditCardVariableAprRate$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  postCreditCardVariableAprRate(params: {
    body: AdjustCreditCardVariableAprRateRequest
  }): Observable<{
'isSuccess'?: boolean;
'message'?: string;
'data'?: string;
}> {

    return this.postCreditCardVariableAprRate$Response(params).pipe(
      map((r: StrictHttpResponse<{
'isSuccess'?: boolean;
'message'?: string;
'data'?: string;
}>) => r.body as {
'isSuccess'?: boolean;
'message'?: string;
'data'?: string;
})
    );
  }

  /**
   * Path part for operation postCDs13MonthPromoRate
   */
  static readonly PostCDs13MonthPromoRatePath = '/v1/adjustRates/postCDs13MonthPromoRate';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `postCDs13MonthPromoRate()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  postCDs13MonthPromoRate$Response(params: {
    body: AdjustCDs13MonthPromoRateRequest
  }): Observable<StrictHttpResponse<{
'isSuccess'?: boolean;
'message'?: string;
'data'?: string;
}>> {

    const rb = new RequestBuilder(this.rootUrl, ApiService.PostCDs13MonthPromoRatePath, 'post');
    if (params) {
      rb.body(params.body, 'application/json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<{
        'isSuccess'?: boolean;
        'message'?: string;
        'data'?: string;
        }>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `postCDs13MonthPromoRate$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  postCDs13MonthPromoRate(params: {
    body: AdjustCDs13MonthPromoRateRequest
  }): Observable<{
'isSuccess'?: boolean;
'message'?: string;
'data'?: string;
}> {

    return this.postCDs13MonthPromoRate$Response(params).pipe(
      map((r: StrictHttpResponse<{
'isSuccess'?: boolean;
'message'?: string;
'data'?: string;
}>) => r.body as {
'isSuccess'?: boolean;
'message'?: string;
'data'?: string;
})
    );
  }

  /**
   * Path part for operation postCDs17MonthPromoRate
   */
  static readonly PostCDs17MonthPromoRatePath = '/v1/adjustRates/postCDs17MonthPromoRate';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `postCDs17MonthPromoRate()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  postCDs17MonthPromoRate$Response(params: {
    body: AdjustCDs17MonthPromoRateRequest
  }): Observable<StrictHttpResponse<{
'isSuccess'?: boolean;
'message'?: string;
'data'?: string;
}>> {

    const rb = new RequestBuilder(this.rootUrl, ApiService.PostCDs17MonthPromoRatePath, 'post');
    if (params) {
      rb.body(params.body, 'application/json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<{
        'isSuccess'?: boolean;
        'message'?: string;
        'data'?: string;
        }>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `postCDs17MonthPromoRate$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  postCDs17MonthPromoRate(params: {
    body: AdjustCDs17MonthPromoRateRequest
  }): Observable<{
'isSuccess'?: boolean;
'message'?: string;
'data'?: string;
}> {

    return this.postCDs17MonthPromoRate$Response(params).pipe(
      map((r: StrictHttpResponse<{
'isSuccess'?: boolean;
'message'?: string;
'data'?: string;
}>) => r.body as {
'isSuccess'?: boolean;
'message'?: string;
'data'?: string;
})
    );
  }

  /**
   * Path part for operation applicationCreditCardPersonalList
   */
  static readonly ApplicationCreditCardPersonalListPath = '/v1/applicationCreditCardPersonal/list';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `applicationCreditCardPersonalList()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  applicationCreditCardPersonalList$Response(params: {
    body: EntityGetRequest
  }): Observable<StrictHttpResponse<{
'isSuccess'?: boolean;
'message'?: string;
'data'?: {
'pageNumber'?: number;
'pageSize'?: number;
'totalRowCount'?: number;
'items'?: Array<ApplicationCreditCardPersonalItem>;
};
}>> {

    const rb = new RequestBuilder(this.rootUrl, ApiService.ApplicationCreditCardPersonalListPath, 'post');
    if (params) {
      rb.body(params.body, 'application/json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<{
        'isSuccess'?: boolean;
        'message'?: string;
        'data'?: {
        'pageNumber'?: number;
        'pageSize'?: number;
        'totalRowCount'?: number;
        'items'?: Array<ApplicationCreditCardPersonalItem>;
        };
        }>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `applicationCreditCardPersonalList$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  applicationCreditCardPersonalList(params: {
    body: EntityGetRequest
  }): Observable<{
'isSuccess'?: boolean;
'message'?: string;
'data'?: {
'pageNumber'?: number;
'pageSize'?: number;
'totalRowCount'?: number;
'items'?: Array<ApplicationCreditCardPersonalItem>;
};
}> {

    return this.applicationCreditCardPersonalList$Response(params).pipe(
      map((r: StrictHttpResponse<{
'isSuccess'?: boolean;
'message'?: string;
'data'?: {
'pageNumber'?: number;
'pageSize'?: number;
'totalRowCount'?: number;
'items'?: Array<ApplicationCreditCardPersonalItem>;
};
}>) => r.body as {
'isSuccess'?: boolean;
'message'?: string;
'data'?: {
'pageNumber'?: number;
'pageSize'?: number;
'totalRowCount'?: number;
'items'?: Array<ApplicationCreditCardPersonalItem>;
};
})
    );
  }

  /**
   * Path part for operation applicationCreditCardPersonalAdd
   */
  static readonly ApplicationCreditCardPersonalAddPath = '/v1/applicationCreditCardPersonal/add';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `applicationCreditCardPersonalAdd()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  applicationCreditCardPersonalAdd$Response(params: {
    body: ApplicationCreditCardPersonal
  }): Observable<StrictHttpResponse<{
'isSuccess'?: boolean;
'message'?: string;
'data'?: string;
}>> {

    const rb = new RequestBuilder(this.rootUrl, ApiService.ApplicationCreditCardPersonalAddPath, 'post');
    if (params) {
      rb.body(params.body, 'application/json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<{
        'isSuccess'?: boolean;
        'message'?: string;
        'data'?: string;
        }>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `applicationCreditCardPersonalAdd$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  applicationCreditCardPersonalAdd(params: {
    body: ApplicationCreditCardPersonal
  }): Observable<{
'isSuccess'?: boolean;
'message'?: string;
'data'?: string;
}> {

    return this.applicationCreditCardPersonalAdd$Response(params).pipe(
      map((r: StrictHttpResponse<{
'isSuccess'?: boolean;
'message'?: string;
'data'?: string;
}>) => r.body as {
'isSuccess'?: boolean;
'message'?: string;
'data'?: string;
})
    );
  }

  /**
   * Path part for operation applicationPersonalCreditCardUserAdd
   */
  static readonly ApplicationPersonalCreditCardUserAddPath = '/v1/applicationCreditCardPersonal/add/authorizedUser';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `applicationPersonalCreditCardUserAdd()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  applicationPersonalCreditCardUserAdd$Response(params: {
    body: ApplicationCreditCardPersonalAuthorizedUserRequest
  }): Observable<StrictHttpResponse<{
'isSuccess'?: boolean;
'message'?: string;
'data'?: Array<string>;
}>> {

    const rb = new RequestBuilder(this.rootUrl, ApiService.ApplicationPersonalCreditCardUserAddPath, 'post');
    if (params) {
      rb.body(params.body, 'application/json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<{
        'isSuccess'?: boolean;
        'message'?: string;
        'data'?: Array<string>;
        }>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `applicationPersonalCreditCardUserAdd$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  applicationPersonalCreditCardUserAdd(params: {
    body: ApplicationCreditCardPersonalAuthorizedUserRequest
  }): Observable<{
'isSuccess'?: boolean;
'message'?: string;
'data'?: Array<string>;
}> {

    return this.applicationPersonalCreditCardUserAdd$Response(params).pipe(
      map((r: StrictHttpResponse<{
'isSuccess'?: boolean;
'message'?: string;
'data'?: Array<string>;
}>) => r.body as {
'isSuccess'?: boolean;
'message'?: string;
'data'?: Array<string>;
})
    );
  }

  /**
   * Path part for operation applicationPersonalCreditCardUserList
   */
  static readonly ApplicationPersonalCreditCardUserListPath = '/v1/applicationCreditCardPersonal/list/authorizedUser';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `applicationPersonalCreditCardUserList()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  applicationPersonalCreditCardUserList$Response(params: {
    body: EntityGetRequest
  }): Observable<StrictHttpResponse<{
'isSuccess'?: boolean;
'message'?: string;
'data'?: {
'pageNumber'?: number;
'pageSize'?: number;
'totalRowCount'?: number;
'items'?: Array<ApplicationCreditCardPersonalAuthorizedUserItem>;
};
}>> {

    const rb = new RequestBuilder(this.rootUrl, ApiService.ApplicationPersonalCreditCardUserListPath, 'post');
    if (params) {
      rb.body(params.body, 'application/json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<{
        'isSuccess'?: boolean;
        'message'?: string;
        'data'?: {
        'pageNumber'?: number;
        'pageSize'?: number;
        'totalRowCount'?: number;
        'items'?: Array<ApplicationCreditCardPersonalAuthorizedUserItem>;
        };
        }>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `applicationPersonalCreditCardUserList$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  applicationPersonalCreditCardUserList(params: {
    body: EntityGetRequest
  }): Observable<{
'isSuccess'?: boolean;
'message'?: string;
'data'?: {
'pageNumber'?: number;
'pageSize'?: number;
'totalRowCount'?: number;
'items'?: Array<ApplicationCreditCardPersonalAuthorizedUserItem>;
};
}> {

    return this.applicationPersonalCreditCardUserList$Response(params).pipe(
      map((r: StrictHttpResponse<{
'isSuccess'?: boolean;
'message'?: string;
'data'?: {
'pageNumber'?: number;
'pageSize'?: number;
'totalRowCount'?: number;
'items'?: Array<ApplicationCreditCardPersonalAuthorizedUserItem>;
};
}>) => r.body as {
'isSuccess'?: boolean;
'message'?: string;
'data'?: {
'pageNumber'?: number;
'pageSize'?: number;
'totalRowCount'?: number;
'items'?: Array<ApplicationCreditCardPersonalAuthorizedUserItem>;
};
})
    );
  }

  /**
   * Path part for operation nameSuffixList
   */
  static readonly NameSuffixListPath = '/v1/applicationCreditCardPersonal/list/nameSuffix';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `nameSuffixList()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  nameSuffixList$Response(params: {
    body: NameSuffixListRequest
  }): Observable<StrictHttpResponse<{
'isSuccess'?: boolean;
'message'?: string;
'data'?: {
'pageNumber'?: number;
'pageSize'?: number;
'totalRowCount'?: number;
'items'?: Array<NameSuffix>;
};
}>> {

    const rb = new RequestBuilder(this.rootUrl, ApiService.NameSuffixListPath, 'post');
    if (params) {
      rb.body(params.body, 'application/json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<{
        'isSuccess'?: boolean;
        'message'?: string;
        'data'?: {
        'pageNumber'?: number;
        'pageSize'?: number;
        'totalRowCount'?: number;
        'items'?: Array<NameSuffix>;
        };
        }>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `nameSuffixList$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  nameSuffixList(params: {
    body: NameSuffixListRequest
  }): Observable<{
'isSuccess'?: boolean;
'message'?: string;
'data'?: {
'pageNumber'?: number;
'pageSize'?: number;
'totalRowCount'?: number;
'items'?: Array<NameSuffix>;
};
}> {

    return this.nameSuffixList$Response(params).pipe(
      map((r: StrictHttpResponse<{
'isSuccess'?: boolean;
'message'?: string;
'data'?: {
'pageNumber'?: number;
'pageSize'?: number;
'totalRowCount'?: number;
'items'?: Array<NameSuffix>;
};
}>) => r.body as {
'isSuccess'?: boolean;
'message'?: string;
'data'?: {
'pageNumber'?: number;
'pageSize'?: number;
'totalRowCount'?: number;
'items'?: Array<NameSuffix>;
};
})
    );
  }

  /**
   * Path part for operation homeOwnershipTypeList
   */
  static readonly HomeOwnershipTypeListPath = '/v1/applicationCreditCardPersonal/list/homeOwnershipType';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `homeOwnershipTypeList()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  homeOwnershipTypeList$Response(params: {
    body: HomeOwnershipTypeListRequest
  }): Observable<StrictHttpResponse<{
'isSuccess'?: boolean;
'message'?: string;
'data'?: {
'pageNumber'?: number;
'pageSize'?: number;
'totalRowCount'?: number;
'items'?: Array<HomeOwnershipType>;
};
}>> {

    const rb = new RequestBuilder(this.rootUrl, ApiService.HomeOwnershipTypeListPath, 'post');
    if (params) {
      rb.body(params.body, 'application/json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<{
        'isSuccess'?: boolean;
        'message'?: string;
        'data'?: {
        'pageNumber'?: number;
        'pageSize'?: number;
        'totalRowCount'?: number;
        'items'?: Array<HomeOwnershipType>;
        };
        }>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `homeOwnershipTypeList$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  homeOwnershipTypeList(params: {
    body: HomeOwnershipTypeListRequest
  }): Observable<{
'isSuccess'?: boolean;
'message'?: string;
'data'?: {
'pageNumber'?: number;
'pageSize'?: number;
'totalRowCount'?: number;
'items'?: Array<HomeOwnershipType>;
};
}> {

    return this.homeOwnershipTypeList$Response(params).pipe(
      map((r: StrictHttpResponse<{
'isSuccess'?: boolean;
'message'?: string;
'data'?: {
'pageNumber'?: number;
'pageSize'?: number;
'totalRowCount'?: number;
'items'?: Array<HomeOwnershipType>;
};
}>) => r.body as {
'isSuccess'?: boolean;
'message'?: string;
'data'?: {
'pageNumber'?: number;
'pageSize'?: number;
'totalRowCount'?: number;
'items'?: Array<HomeOwnershipType>;
};
})
    );
  }

  /**
   * Path part for operation employmentTypeList
   */
  static readonly EmploymentTypeListPath = '/v1/applicationCreditCardPersonal/list/employmentType';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `employmentTypeList()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  employmentTypeList$Response(params: {
    body: EmploymentTypeListRequest
  }): Observable<StrictHttpResponse<{
'isSuccess'?: boolean;
'message'?: string;
'data'?: {
'pageNumber'?: number;
'pageSize'?: number;
'totalRowCount'?: number;
'items'?: Array<EmploymentType>;
};
}>> {

    const rb = new RequestBuilder(this.rootUrl, ApiService.EmploymentTypeListPath, 'post');
    if (params) {
      rb.body(params.body, 'application/json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<{
        'isSuccess'?: boolean;
        'message'?: string;
        'data'?: {
        'pageNumber'?: number;
        'pageSize'?: number;
        'totalRowCount'?: number;
        'items'?: Array<EmploymentType>;
        };
        }>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `employmentTypeList$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  employmentTypeList(params: {
    body: EmploymentTypeListRequest
  }): Observable<{
'isSuccess'?: boolean;
'message'?: string;
'data'?: {
'pageNumber'?: number;
'pageSize'?: number;
'totalRowCount'?: number;
'items'?: Array<EmploymentType>;
};
}> {

    return this.employmentTypeList$Response(params).pipe(
      map((r: StrictHttpResponse<{
'isSuccess'?: boolean;
'message'?: string;
'data'?: {
'pageNumber'?: number;
'pageSize'?: number;
'totalRowCount'?: number;
'items'?: Array<EmploymentType>;
};
}>) => r.body as {
'isSuccess'?: boolean;
'message'?: string;
'data'?: {
'pageNumber'?: number;
'pageSize'?: number;
'totalRowCount'?: number;
'items'?: Array<EmploymentType>;
};
})
    );
  }

  /**
   * Path part for operation rolesForUserAdd
   */
  static readonly RolesForUserAddPath = '/v1/user/add';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `rolesForUserAdd()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  rolesForUserAdd$Response(params: {
    body: EntityUpdateRequest
  }): Observable<StrictHttpResponse<{
'isSuccess'?: boolean;
'message'?: string;
'data'?: string;
}>> {

    const rb = new RequestBuilder(this.rootUrl, ApiService.RolesForUserAddPath, 'post');
    if (params) {
      rb.body(params.body, 'application/json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<{
        'isSuccess'?: boolean;
        'message'?: string;
        'data'?: string;
        }>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `rolesForUserAdd$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  rolesForUserAdd(params: {
    body: EntityUpdateRequest
  }): Observable<{
'isSuccess'?: boolean;
'message'?: string;
'data'?: string;
}> {

    return this.rolesForUserAdd$Response(params).pipe(
      map((r: StrictHttpResponse<{
'isSuccess'?: boolean;
'message'?: string;
'data'?: string;
}>) => r.body as {
'isSuccess'?: boolean;
'message'?: string;
'data'?: string;
})
    );
  }

  /**
   * Path part for operation userUpdate
   */
  static readonly UserUpdatePath = '/v1/user/update';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `userUpdate()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  userUpdate$Response(params: {
    body: InternalUser
  }): Observable<StrictHttpResponse<{
'isSuccess'?: boolean;
'message'?: string;
'data'?: string;
}>> {

    const rb = new RequestBuilder(this.rootUrl, ApiService.UserUpdatePath, 'put');
    if (params) {
      rb.body(params.body, 'application/json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<{
        'isSuccess'?: boolean;
        'message'?: string;
        'data'?: string;
        }>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `userUpdate$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  userUpdate(params: {
    body: InternalUser
  }): Observable<{
'isSuccess'?: boolean;
'message'?: string;
'data'?: string;
}> {

    return this.userUpdate$Response(params).pipe(
      map((r: StrictHttpResponse<{
'isSuccess'?: boolean;
'message'?: string;
'data'?: string;
}>) => r.body as {
'isSuccess'?: boolean;
'message'?: string;
'data'?: string;
})
    );
  }

  /**
   * Path part for operation userList
   */
  static readonly UserListPath = '/v1/user/list';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `userList()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  userList$Response(params: {
    body: UserListRequest
  }): Observable<StrictHttpResponse<{
'isSuccess'?: boolean;
'message'?: string;
'data'?: {
'pageNumber'?: number;
'pageSize'?: number;
'totalRowCount'?: number;
'items'?: Array<InternalUserItem>;
};
}>> {

    const rb = new RequestBuilder(this.rootUrl, ApiService.UserListPath, 'post');
    if (params) {
      rb.body(params.body, 'application/json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<{
        'isSuccess'?: boolean;
        'message'?: string;
        'data'?: {
        'pageNumber'?: number;
        'pageSize'?: number;
        'totalRowCount'?: number;
        'items'?: Array<InternalUserItem>;
        };
        }>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `userList$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  userList(params: {
    body: UserListRequest
  }): Observable<{
'isSuccess'?: boolean;
'message'?: string;
'data'?: {
'pageNumber'?: number;
'pageSize'?: number;
'totalRowCount'?: number;
'items'?: Array<InternalUserItem>;
};
}> {

    return this.userList$Response(params).pipe(
      map((r: StrictHttpResponse<{
'isSuccess'?: boolean;
'message'?: string;
'data'?: {
'pageNumber'?: number;
'pageSize'?: number;
'totalRowCount'?: number;
'items'?: Array<InternalUserItem>;
};
}>) => r.body as {
'isSuccess'?: boolean;
'message'?: string;
'data'?: {
'pageNumber'?: number;
'pageSize'?: number;
'totalRowCount'?: number;
'items'?: Array<InternalUserItem>;
};
})
    );
  }

  /**
   * Path part for operation userDelete
   */
  static readonly UserDeletePath = '/v1/user/delete';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `userDelete()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  userDelete$Response(params: {
    body: DeleteRequest
  }): Observable<StrictHttpResponse<{
'isSuccess'?: boolean;
'message'?: string;
'data'?: string;
}>> {

    const rb = new RequestBuilder(this.rootUrl, ApiService.UserDeletePath, 'delete');
    if (params) {
      rb.body(params.body, 'application/json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<{
        'isSuccess'?: boolean;
        'message'?: string;
        'data'?: string;
        }>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `userDelete$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  userDelete(params: {
    body: DeleteRequest
  }): Observable<{
'isSuccess'?: boolean;
'message'?: string;
'data'?: string;
}> {

    return this.userDelete$Response(params).pipe(
      map((r: StrictHttpResponse<{
'isSuccess'?: boolean;
'message'?: string;
'data'?: string;
}>) => r.body as {
'isSuccess'?: boolean;
'message'?: string;
'data'?: string;
})
    );
  }

  /**
   * Path part for operation userPeersList
   */
  static readonly UserPeersListPath = '/v1/user/peers/list';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `userPeersList()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  userPeersList$Response(params: {
    body: UserPeerRequest
  }): Observable<StrictHttpResponse<{
'isSuccess'?: boolean;
'message'?: string;
'data'?: {
'pageNumber'?: number;
'pageSize'?: number;
'totalRowCount'?: number;
'items'?: Array<InternalUserItem>;
};
}>> {

    const rb = new RequestBuilder(this.rootUrl, ApiService.UserPeersListPath, 'post');
    if (params) {
      rb.body(params.body, 'application/json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<{
        'isSuccess'?: boolean;
        'message'?: string;
        'data'?: {
        'pageNumber'?: number;
        'pageSize'?: number;
        'totalRowCount'?: number;
        'items'?: Array<InternalUserItem>;
        };
        }>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `userPeersList$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  userPeersList(params: {
    body: UserPeerRequest
  }): Observable<{
'isSuccess'?: boolean;
'message'?: string;
'data'?: {
'pageNumber'?: number;
'pageSize'?: number;
'totalRowCount'?: number;
'items'?: Array<InternalUserItem>;
};
}> {

    return this.userPeersList$Response(params).pipe(
      map((r: StrictHttpResponse<{
'isSuccess'?: boolean;
'message'?: string;
'data'?: {
'pageNumber'?: number;
'pageSize'?: number;
'totalRowCount'?: number;
'items'?: Array<InternalUserItem>;
};
}>) => r.body as {
'isSuccess'?: boolean;
'message'?: string;
'data'?: {
'pageNumber'?: number;
'pageSize'?: number;
'totalRowCount'?: number;
'items'?: Array<InternalUserItem>;
};
})
    );
  }

  /**
   * Path part for operation userRolesAdd
   */
  static readonly UserRolesAddPath = '/v1/user/roles/user/add';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `userRolesAdd()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  userRolesAdd$Response(params: {
    body: RolesForUserSaveList
  }): Observable<StrictHttpResponse<{
'isSuccess'?: boolean;
'message'?: string;
'data'?: string;
}>> {

    const rb = new RequestBuilder(this.rootUrl, ApiService.UserRolesAddPath, 'post');
    if (params) {
      rb.body(params.body, 'application/json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<{
        'isSuccess'?: boolean;
        'message'?: string;
        'data'?: string;
        }>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `userRolesAdd$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  userRolesAdd(params: {
    body: RolesForUserSaveList
  }): Observable<{
'isSuccess'?: boolean;
'message'?: string;
'data'?: string;
}> {

    return this.userRolesAdd$Response(params).pipe(
      map((r: StrictHttpResponse<{
'isSuccess'?: boolean;
'message'?: string;
'data'?: string;
}>) => r.body as {
'isSuccess'?: boolean;
'message'?: string;
'data'?: string;
})
    );
  }

  /**
   * Path part for operation userRolesDelete
   */
  static readonly UserRolesDeletePath = '/v1/user/user/roles/delete';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `userRolesDelete()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  userRolesDelete$Response(params: {
    body: RolesForUserSaveList
  }): Observable<StrictHttpResponse<{
'isSuccess'?: boolean;
'message'?: string;
'data'?: string;
}>> {

    const rb = new RequestBuilder(this.rootUrl, ApiService.UserRolesDeletePath, 'delete');
    if (params) {
      rb.body(params.body, 'application/json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<{
        'isSuccess'?: boolean;
        'message'?: string;
        'data'?: string;
        }>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `userRolesDelete$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  userRolesDelete(params: {
    body: RolesForUserSaveList
  }): Observable<{
'isSuccess'?: boolean;
'message'?: string;
'data'?: string;
}> {

    return this.userRolesDelete$Response(params).pipe(
      map((r: StrictHttpResponse<{
'isSuccess'?: boolean;
'message'?: string;
'data'?: string;
}>) => r.body as {
'isSuccess'?: boolean;
'message'?: string;
'data'?: string;
})
    );
  }

  /**
   * Path part for operation employeeRoleUserList
   */
  static readonly EmployeeRoleUserListPath = '/v1/user/employeeRoleUser/list';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `employeeRoleUserList()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  employeeRoleUserList$Response(params: {
    body: FetchEmployeeRoleUserRequest
  }): Observable<StrictHttpResponse<{
'isSuccess'?: boolean;
'message'?: string;
'data'?: {
'pageNumber'?: number;
'pageSize'?: number;
'totalRowCount'?: number;
'items'?: Array<InternalUserItem>;
};
}>> {

    const rb = new RequestBuilder(this.rootUrl, ApiService.EmployeeRoleUserListPath, 'post');
    if (params) {
      rb.body(params.body, 'application/json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<{
        'isSuccess'?: boolean;
        'message'?: string;
        'data'?: {
        'pageNumber'?: number;
        'pageSize'?: number;
        'totalRowCount'?: number;
        'items'?: Array<InternalUserItem>;
        };
        }>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `employeeRoleUserList$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  employeeRoleUserList(params: {
    body: FetchEmployeeRoleUserRequest
  }): Observable<{
'isSuccess'?: boolean;
'message'?: string;
'data'?: {
'pageNumber'?: number;
'pageSize'?: number;
'totalRowCount'?: number;
'items'?: Array<InternalUserItem>;
};
}> {

    return this.employeeRoleUserList$Response(params).pipe(
      map((r: StrictHttpResponse<{
'isSuccess'?: boolean;
'message'?: string;
'data'?: {
'pageNumber'?: number;
'pageSize'?: number;
'totalRowCount'?: number;
'items'?: Array<InternalUserItem>;
};
}>) => r.body as {
'isSuccess'?: boolean;
'message'?: string;
'data'?: {
'pageNumber'?: number;
'pageSize'?: number;
'totalRowCount'?: number;
'items'?: Array<InternalUserItem>;
};
})
    );
  }

  /**
   * Path part for operation roleXUserList
   */
  static readonly RoleXUserListPath = '/v1/user/list/roleXUser';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `roleXUserList()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  roleXUserList$Response(params: {
    body: EntityGetRequest
  }): Observable<StrictHttpResponse<{
'isSuccess'?: boolean;
'message'?: string;
'data'?: {
'pageNumber'?: number;
'pageSize'?: number;
'totalRowCount'?: number;
'items'?: Array<InternalUserXRoleListItem>;
};
}>> {

    const rb = new RequestBuilder(this.rootUrl, ApiService.RoleXUserListPath, 'post');
    if (params) {
      rb.body(params.body, 'application/json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<{
        'isSuccess'?: boolean;
        'message'?: string;
        'data'?: {
        'pageNumber'?: number;
        'pageSize'?: number;
        'totalRowCount'?: number;
        'items'?: Array<InternalUserXRoleListItem>;
        };
        }>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `roleXUserList$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  roleXUserList(params: {
    body: EntityGetRequest
  }): Observable<{
'isSuccess'?: boolean;
'message'?: string;
'data'?: {
'pageNumber'?: number;
'pageSize'?: number;
'totalRowCount'?: number;
'items'?: Array<InternalUserXRoleListItem>;
};
}> {

    return this.roleXUserList$Response(params).pipe(
      map((r: StrictHttpResponse<{
'isSuccess'?: boolean;
'message'?: string;
'data'?: {
'pageNumber'?: number;
'pageSize'?: number;
'totalRowCount'?: number;
'items'?: Array<InternalUserXRoleListItem>;
};
}>) => r.body as {
'isSuccess'?: boolean;
'message'?: string;
'data'?: {
'pageNumber'?: number;
'pageSize'?: number;
'totalRowCount'?: number;
'items'?: Array<InternalUserXRoleListItem>;
};
})
    );
  }

  /**
   * Path part for operation roleXUserAdd
   */
  static readonly RoleXUserAddPath = '/v1/user/add/roleXUser';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `roleXUserAdd()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  roleXUserAdd$Response(params: {
    body: InternalUserXRoleSaveList
  }): Observable<StrictHttpResponse<{
'isSuccess'?: boolean;
'message'?: string;
'data'?: string;
}>> {

    const rb = new RequestBuilder(this.rootUrl, ApiService.RoleXUserAddPath, 'post');
    if (params) {
      rb.body(params.body, 'application/json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<{
        'isSuccess'?: boolean;
        'message'?: string;
        'data'?: string;
        }>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `roleXUserAdd$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  roleXUserAdd(params: {
    body: InternalUserXRoleSaveList
  }): Observable<{
'isSuccess'?: boolean;
'message'?: string;
'data'?: string;
}> {

    return this.roleXUserAdd$Response(params).pipe(
      map((r: StrictHttpResponse<{
'isSuccess'?: boolean;
'message'?: string;
'data'?: string;
}>) => r.body as {
'isSuccess'?: boolean;
'message'?: string;
'data'?: string;
})
    );
  }

  /**
   * Path part for operation fetchWikiRoleXUserList
   */
  static readonly FetchWikiRoleXUserListPath = '/v1/user/list/fetchWikiRoleXUserList';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `fetchWikiRoleXUserList()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  fetchWikiRoleXUserList$Response(params: {
    body: EntityGetRequest
  }): Observable<StrictHttpResponse<{
'isSuccess'?: boolean;
'message'?: string;
'data'?: {
'pageNumber'?: number;
'pageSize'?: number;
'totalRowCount'?: number;
'items'?: Array<InternalUserXRoleListItem>;
};
}>> {

    const rb = new RequestBuilder(this.rootUrl, ApiService.FetchWikiRoleXUserListPath, 'post');
    if (params) {
      rb.body(params.body, 'application/json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<{
        'isSuccess'?: boolean;
        'message'?: string;
        'data'?: {
        'pageNumber'?: number;
        'pageSize'?: number;
        'totalRowCount'?: number;
        'items'?: Array<InternalUserXRoleListItem>;
        };
        }>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `fetchWikiRoleXUserList$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  fetchWikiRoleXUserList(params: {
    body: EntityGetRequest
  }): Observable<{
'isSuccess'?: boolean;
'message'?: string;
'data'?: {
'pageNumber'?: number;
'pageSize'?: number;
'totalRowCount'?: number;
'items'?: Array<InternalUserXRoleListItem>;
};
}> {

    return this.fetchWikiRoleXUserList$Response(params).pipe(
      map((r: StrictHttpResponse<{
'isSuccess'?: boolean;
'message'?: string;
'data'?: {
'pageNumber'?: number;
'pageSize'?: number;
'totalRowCount'?: number;
'items'?: Array<InternalUserXRoleListItem>;
};
}>) => r.body as {
'isSuccess'?: boolean;
'message'?: string;
'data'?: {
'pageNumber'?: number;
'pageSize'?: number;
'totalRowCount'?: number;
'items'?: Array<InternalUserXRoleListItem>;
};
})
    );
  }

  /**
   * Path part for operation fetchUserCalenderEvent
   */
  static readonly FetchUserCalenderEventPath = '/v1/user/get/calenderEvent';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `fetchUserCalenderEvent()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  fetchUserCalenderEvent$Response(params: {
    body: GetCalenderRequest
  }): Observable<StrictHttpResponse<{
'isSuccess'?: boolean;
'message'?: string;
'data'?: {
'pageNumber'?: number;
'pageSize'?: number;
'totalRowCount'?: number;
'items'?: Array<CalenderEvent>;
};
}>> {

    const rb = new RequestBuilder(this.rootUrl, ApiService.FetchUserCalenderEventPath, 'post');
    if (params) {
      rb.body(params.body, 'application/json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<{
        'isSuccess'?: boolean;
        'message'?: string;
        'data'?: {
        'pageNumber'?: number;
        'pageSize'?: number;
        'totalRowCount'?: number;
        'items'?: Array<CalenderEvent>;
        };
        }>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `fetchUserCalenderEvent$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  fetchUserCalenderEvent(params: {
    body: GetCalenderRequest
  }): Observable<{
'isSuccess'?: boolean;
'message'?: string;
'data'?: {
'pageNumber'?: number;
'pageSize'?: number;
'totalRowCount'?: number;
'items'?: Array<CalenderEvent>;
};
}> {

    return this.fetchUserCalenderEvent$Response(params).pipe(
      map((r: StrictHttpResponse<{
'isSuccess'?: boolean;
'message'?: string;
'data'?: {
'pageNumber'?: number;
'pageSize'?: number;
'totalRowCount'?: number;
'items'?: Array<CalenderEvent>;
};
}>) => r.body as {
'isSuccess'?: boolean;
'message'?: string;
'data'?: {
'pageNumber'?: number;
'pageSize'?: number;
'totalRowCount'?: number;
'items'?: Array<CalenderEvent>;
};
})
    );
  }

  /**
   * Path part for operation fetchAllUserCalenderEvent
   */
  static readonly FetchAllUserCalenderEventPath = '/v1/user/get/allCalenderEvents';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `fetchAllUserCalenderEvent()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  fetchAllUserCalenderEvent$Response(params: {
    body: Array<GetCalenderRequest>
  }): Observable<StrictHttpResponse<{
'isSuccess'?: boolean;
'message'?: string;
'data'?: {
'pageNumber'?: number;
'pageSize'?: number;
'totalRowCount'?: number;
'items'?: Array<CalenderEvent>;
};
}>> {

    const rb = new RequestBuilder(this.rootUrl, ApiService.FetchAllUserCalenderEventPath, 'post');
    if (params) {
      rb.body(params.body, 'application/json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<{
        'isSuccess'?: boolean;
        'message'?: string;
        'data'?: {
        'pageNumber'?: number;
        'pageSize'?: number;
        'totalRowCount'?: number;
        'items'?: Array<CalenderEvent>;
        };
        }>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `fetchAllUserCalenderEvent$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  fetchAllUserCalenderEvent(params: {
    body: Array<GetCalenderRequest>
  }): Observable<{
'isSuccess'?: boolean;
'message'?: string;
'data'?: {
'pageNumber'?: number;
'pageSize'?: number;
'totalRowCount'?: number;
'items'?: Array<CalenderEvent>;
};
}> {

    return this.fetchAllUserCalenderEvent$Response(params).pipe(
      map((r: StrictHttpResponse<{
'isSuccess'?: boolean;
'message'?: string;
'data'?: {
'pageNumber'?: number;
'pageSize'?: number;
'totalRowCount'?: number;
'items'?: Array<CalenderEvent>;
};
}>) => r.body as {
'isSuccess'?: boolean;
'message'?: string;
'data'?: {
'pageNumber'?: number;
'pageSize'?: number;
'totalRowCount'?: number;
'items'?: Array<CalenderEvent>;
};
})
    );
  }

  /**
   * Path part for operation fetchUsersCalenderEvent
   */
  static readonly FetchUsersCalenderEventPath = '/v1/user/get/bulk/calenderEvent';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `fetchUsersCalenderEvent()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  fetchUsersCalenderEvent$Response(params: {
    body: Array<GetCalenderRequest>
  }): Observable<StrictHttpResponse<{
'isSuccess'?: boolean;
'message'?: string;
'data'?: {
'pageNumber'?: number;
'pageSize'?: number;
'totalRowCount'?: number;
'items'?: Array<CalenderEvent>;
};
}>> {

    const rb = new RequestBuilder(this.rootUrl, ApiService.FetchUsersCalenderEventPath, 'post');
    if (params) {
      rb.body(params.body, 'application/json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<{
        'isSuccess'?: boolean;
        'message'?: string;
        'data'?: {
        'pageNumber'?: number;
        'pageSize'?: number;
        'totalRowCount'?: number;
        'items'?: Array<CalenderEvent>;
        };
        }>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `fetchUsersCalenderEvent$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  fetchUsersCalenderEvent(params: {
    body: Array<GetCalenderRequest>
  }): Observable<{
'isSuccess'?: boolean;
'message'?: string;
'data'?: {
'pageNumber'?: number;
'pageSize'?: number;
'totalRowCount'?: number;
'items'?: Array<CalenderEvent>;
};
}> {

    return this.fetchUsersCalenderEvent$Response(params).pipe(
      map((r: StrictHttpResponse<{
'isSuccess'?: boolean;
'message'?: string;
'data'?: {
'pageNumber'?: number;
'pageSize'?: number;
'totalRowCount'?: number;
'items'?: Array<CalenderEvent>;
};
}>) => r.body as {
'isSuccess'?: boolean;
'message'?: string;
'data'?: {
'pageNumber'?: number;
'pageSize'?: number;
'totalRowCount'?: number;
'items'?: Array<CalenderEvent>;
};
})
    );
  }

  /**
   * Path part for operation createCalenderEvent
   */
  static readonly CreateCalenderEventPath = '/v1/user/add/calenderEvent';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `createCalenderEvent()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  createCalenderEvent$Response(params: {
    body: CreateEventRequest
  }): Observable<StrictHttpResponse<{
'isSuccess'?: boolean;
'message'?: string;
'data'?: string;
}>> {

    const rb = new RequestBuilder(this.rootUrl, ApiService.CreateCalenderEventPath, 'post');
    if (params) {
      rb.body(params.body, 'application/json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<{
        'isSuccess'?: boolean;
        'message'?: string;
        'data'?: string;
        }>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `createCalenderEvent$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  createCalenderEvent(params: {
    body: CreateEventRequest
  }): Observable<{
'isSuccess'?: boolean;
'message'?: string;
'data'?: string;
}> {

    return this.createCalenderEvent$Response(params).pipe(
      map((r: StrictHttpResponse<{
'isSuccess'?: boolean;
'message'?: string;
'data'?: string;
}>) => r.body as {
'isSuccess'?: boolean;
'message'?: string;
'data'?: string;
})
    );
  }

  /**
   * Path part for operation updateCalenderEvent
   */
  static readonly UpdateCalenderEventPath = '/v1/user/update/calenderEvent';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `updateCalenderEvent()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  updateCalenderEvent$Response(params: {
    body: UpdateEventRequest
  }): Observable<StrictHttpResponse<{
'isSuccess'?: boolean;
'message'?: string;
'data'?: string;
}>> {

    const rb = new RequestBuilder(this.rootUrl, ApiService.UpdateCalenderEventPath, 'put');
    if (params) {
      rb.body(params.body, 'application/json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<{
        'isSuccess'?: boolean;
        'message'?: string;
        'data'?: string;
        }>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `updateCalenderEvent$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  updateCalenderEvent(params: {
    body: UpdateEventRequest
  }): Observable<{
'isSuccess'?: boolean;
'message'?: string;
'data'?: string;
}> {

    return this.updateCalenderEvent$Response(params).pipe(
      map((r: StrictHttpResponse<{
'isSuccess'?: boolean;
'message'?: string;
'data'?: string;
}>) => r.body as {
'isSuccess'?: boolean;
'message'?: string;
'data'?: string;
})
    );
  }

  /**
   * Path part for operation deleteCalenderEvent
   */
  static readonly DeleteCalenderEventPath = '/v1/user/delete/calenderEvent';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `deleteCalenderEvent()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  deleteCalenderEvent$Response(params: {
    body: EventIdentifier
  }): Observable<StrictHttpResponse<{
'isSuccess'?: boolean;
'message'?: string;
'data'?: string;
}>> {

    const rb = new RequestBuilder(this.rootUrl, ApiService.DeleteCalenderEventPath, 'delete');
    if (params) {
      rb.body(params.body, 'application/json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<{
        'isSuccess'?: boolean;
        'message'?: string;
        'data'?: string;
        }>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `deleteCalenderEvent$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  deleteCalenderEvent(params: {
    body: EventIdentifier
  }): Observable<{
'isSuccess'?: boolean;
'message'?: string;
'data'?: string;
}> {

    return this.deleteCalenderEvent$Response(params).pipe(
      map((r: StrictHttpResponse<{
'isSuccess'?: boolean;
'message'?: string;
'data'?: string;
}>) => r.body as {
'isSuccess'?: boolean;
'message'?: string;
'data'?: string;
})
    );
  }

  /**
   * Path part for operation forwardCalenderEvent
   */
  static readonly ForwardCalenderEventPath = '/v1/user/forward/calenderEvent';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `forwardCalenderEvent()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  forwardCalenderEvent$Response(params: {
    body: EventIdentifier
  }): Observable<StrictHttpResponse<{
'isSuccess'?: boolean;
'message'?: string;
'data'?: string;
}>> {

    const rb = new RequestBuilder(this.rootUrl, ApiService.ForwardCalenderEventPath, 'put');
    if (params) {
      rb.body(params.body, 'application/json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<{
        'isSuccess'?: boolean;
        'message'?: string;
        'data'?: string;
        }>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `forwardCalenderEvent$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  forwardCalenderEvent(params: {
    body: EventIdentifier
  }): Observable<{
'isSuccess'?: boolean;
'message'?: string;
'data'?: string;
}> {

    return this.forwardCalenderEvent$Response(params).pipe(
      map((r: StrictHttpResponse<{
'isSuccess'?: boolean;
'message'?: string;
'data'?: string;
}>) => r.body as {
'isSuccess'?: boolean;
'message'?: string;
'data'?: string;
})
    );
  }

  /**
   * Path part for operation cancelCalenderEvent
   */
  static readonly CancelCalenderEventPath = '/v1/user/cancel/calenderEvent';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `cancelCalenderEvent()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  cancelCalenderEvent$Response(params: {
    body: EventIdentifier
  }): Observable<StrictHttpResponse<{
'isSuccess'?: boolean;
'message'?: string;
'data'?: string;
}>> {

    const rb = new RequestBuilder(this.rootUrl, ApiService.CancelCalenderEventPath, 'put');
    if (params) {
      rb.body(params.body, 'application/json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<{
        'isSuccess'?: boolean;
        'message'?: string;
        'data'?: string;
        }>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `cancelCalenderEvent$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  cancelCalenderEvent(params: {
    body: EventIdentifier
  }): Observable<{
'isSuccess'?: boolean;
'message'?: string;
'data'?: string;
}> {

    return this.cancelCalenderEvent$Response(params).pipe(
      map((r: StrictHttpResponse<{
'isSuccess'?: boolean;
'message'?: string;
'data'?: string;
}>) => r.body as {
'isSuccess'?: boolean;
'message'?: string;
'data'?: string;
})
    );
  }

  /**
   * Path part for operation acceptCalenderEvent
   */
  static readonly AcceptCalenderEventPath = '/v1/user/accept/calenderEvent';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `acceptCalenderEvent()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  acceptCalenderEvent$Response(params: {
    body: EventIdentifier
  }): Observable<StrictHttpResponse<{
'isSuccess'?: boolean;
'message'?: string;
'data'?: string;
}>> {

    const rb = new RequestBuilder(this.rootUrl, ApiService.AcceptCalenderEventPath, 'put');
    if (params) {
      rb.body(params.body, 'application/json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<{
        'isSuccess'?: boolean;
        'message'?: string;
        'data'?: string;
        }>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `acceptCalenderEvent$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  acceptCalenderEvent(params: {
    body: EventIdentifier
  }): Observable<{
'isSuccess'?: boolean;
'message'?: string;
'data'?: string;
}> {

    return this.acceptCalenderEvent$Response(params).pipe(
      map((r: StrictHttpResponse<{
'isSuccess'?: boolean;
'message'?: string;
'data'?: string;
}>) => r.body as {
'isSuccess'?: boolean;
'message'?: string;
'data'?: string;
})
    );
  }

  /**
   * Path part for operation fetchCalenderEventById
   */
  static readonly FetchCalenderEventByIdPath = '/v1/user/fetchCalenderEventById';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `fetchCalenderEventById()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  fetchCalenderEventById$Response(params: {
    body: CalenderEventRequest
  }): Observable<StrictHttpResponse<{
'isSuccess'?: boolean;
'message'?: string;
'data'?: CalenderEvent;
}>> {

    const rb = new RequestBuilder(this.rootUrl, ApiService.FetchCalenderEventByIdPath, 'post');
    if (params) {
      rb.body(params.body, 'application/json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<{
        'isSuccess'?: boolean;
        'message'?: string;
        'data'?: CalenderEvent;
        }>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `fetchCalenderEventById$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  fetchCalenderEventById(params: {
    body: CalenderEventRequest
  }): Observable<{
'isSuccess'?: boolean;
'message'?: string;
'data'?: CalenderEvent;
}> {

    return this.fetchCalenderEventById$Response(params).pipe(
      map((r: StrictHttpResponse<{
'isSuccess'?: boolean;
'message'?: string;
'data'?: CalenderEvent;
}>) => r.body as {
'isSuccess'?: boolean;
'message'?: string;
'data'?: CalenderEvent;
})
    );
  }

  /**
   * Path part for operation languagePreferenceList
   */
  static readonly LanguagePreferenceListPath = '/v1/user/list/languagePreference';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `languagePreferenceList()` instead.
   *
   * This method doesn't expect any request body.
   */
  languagePreferenceList$Response(params?: {
  }): Observable<StrictHttpResponse<{
'isSuccess'?: boolean;
'message'?: string;
'data'?: {
'pageNumber'?: number;
'pageSize'?: number;
'totalRowCount'?: number;
'items'?: Array<LanguagePreference>;
};
}>> {

    const rb = new RequestBuilder(this.rootUrl, ApiService.LanguagePreferenceListPath, 'post');
    if (params) {
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<{
        'isSuccess'?: boolean;
        'message'?: string;
        'data'?: {
        'pageNumber'?: number;
        'pageSize'?: number;
        'totalRowCount'?: number;
        'items'?: Array<LanguagePreference>;
        };
        }>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `languagePreferenceList$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  languagePreferenceList(params?: {
  }): Observable<{
'isSuccess'?: boolean;
'message'?: string;
'data'?: {
'pageNumber'?: number;
'pageSize'?: number;
'totalRowCount'?: number;
'items'?: Array<LanguagePreference>;
};
}> {

    return this.languagePreferenceList$Response(params).pipe(
      map((r: StrictHttpResponse<{
'isSuccess'?: boolean;
'message'?: string;
'data'?: {
'pageNumber'?: number;
'pageSize'?: number;
'totalRowCount'?: number;
'items'?: Array<LanguagePreference>;
};
}>) => r.body as {
'isSuccess'?: boolean;
'message'?: string;
'data'?: {
'pageNumber'?: number;
'pageSize'?: number;
'totalRowCount'?: number;
'items'?: Array<LanguagePreference>;
};
})
    );
  }

  /**
   * Path part for operation customerAppointmentServiceList
   */
  static readonly CustomerAppointmentServiceListPath = '/v1/user/list/customerAppointmentServiceList';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `customerAppointmentServiceList()` instead.
   *
   * This method doesn't expect any request body.
   */
  customerAppointmentServiceList$Response(params?: {
  }): Observable<StrictHttpResponse<{
'isSuccess'?: boolean;
'message'?: string;
'data'?: {
'pageNumber'?: number;
'pageSize'?: number;
'totalRowCount'?: number;
'items'?: Array<CustomerAppointmentService>;
};
}>> {

    const rb = new RequestBuilder(this.rootUrl, ApiService.CustomerAppointmentServiceListPath, 'post');
    if (params) {
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<{
        'isSuccess'?: boolean;
        'message'?: string;
        'data'?: {
        'pageNumber'?: number;
        'pageSize'?: number;
        'totalRowCount'?: number;
        'items'?: Array<CustomerAppointmentService>;
        };
        }>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `customerAppointmentServiceList$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  customerAppointmentServiceList(params?: {
  }): Observable<{
'isSuccess'?: boolean;
'message'?: string;
'data'?: {
'pageNumber'?: number;
'pageSize'?: number;
'totalRowCount'?: number;
'items'?: Array<CustomerAppointmentService>;
};
}> {

    return this.customerAppointmentServiceList$Response(params).pipe(
      map((r: StrictHttpResponse<{
'isSuccess'?: boolean;
'message'?: string;
'data'?: {
'pageNumber'?: number;
'pageSize'?: number;
'totalRowCount'?: number;
'items'?: Array<CustomerAppointmentService>;
};
}>) => r.body as {
'isSuccess'?: boolean;
'message'?: string;
'data'?: {
'pageNumber'?: number;
'pageSize'?: number;
'totalRowCount'?: number;
'items'?: Array<CustomerAppointmentService>;
};
})
    );
  }

  /**
   * Path part for operation employeeProfileDetailsAdd
   */
  static readonly EmployeeProfileDetailsAddPath = '/v1/user/add/employeeProfileDetailsAdd';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `employeeProfileDetailsAdd()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  employeeProfileDetailsAdd$Response(params: {
    body: EmployeeProfileRequest
  }): Observable<StrictHttpResponse<{
'isSuccess'?: boolean;
'message'?: string;
'data'?: string;
}>> {

    const rb = new RequestBuilder(this.rootUrl, ApiService.EmployeeProfileDetailsAddPath, 'post');
    if (params) {
      rb.body(params.body, 'application/json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<{
        'isSuccess'?: boolean;
        'message'?: string;
        'data'?: string;
        }>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `employeeProfileDetailsAdd$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  employeeProfileDetailsAdd(params: {
    body: EmployeeProfileRequest
  }): Observable<{
'isSuccess'?: boolean;
'message'?: string;
'data'?: string;
}> {

    return this.employeeProfileDetailsAdd$Response(params).pipe(
      map((r: StrictHttpResponse<{
'isSuccess'?: boolean;
'message'?: string;
'data'?: string;
}>) => r.body as {
'isSuccess'?: boolean;
'message'?: string;
'data'?: string;
})
    );
  }

  /**
   * Path part for operation getEmployeeDetails
   */
  static readonly GetEmployeeDetailsPath = '/v1/user/list/getEmployeeDetails';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getEmployeeDetails()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  getEmployeeDetails$Response(params: {
    body: FetchUserDetails
  }): Observable<StrictHttpResponse<{
'isSuccess'?: boolean;
'message'?: string;
'data'?: {
'pageNumber'?: number;
'pageSize'?: number;
'totalRowCount'?: number;
'items'?: Array<EmployeeProfileRequest>;
};
}>> {

    const rb = new RequestBuilder(this.rootUrl, ApiService.GetEmployeeDetailsPath, 'post');
    if (params) {
      rb.body(params.body, 'application/json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<{
        'isSuccess'?: boolean;
        'message'?: string;
        'data'?: {
        'pageNumber'?: number;
        'pageSize'?: number;
        'totalRowCount'?: number;
        'items'?: Array<EmployeeProfileRequest>;
        };
        }>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `getEmployeeDetails$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  getEmployeeDetails(params: {
    body: FetchUserDetails
  }): Observable<{
'isSuccess'?: boolean;
'message'?: string;
'data'?: {
'pageNumber'?: number;
'pageSize'?: number;
'totalRowCount'?: number;
'items'?: Array<EmployeeProfileRequest>;
};
}> {

    return this.getEmployeeDetails$Response(params).pipe(
      map((r: StrictHttpResponse<{
'isSuccess'?: boolean;
'message'?: string;
'data'?: {
'pageNumber'?: number;
'pageSize'?: number;
'totalRowCount'?: number;
'items'?: Array<EmployeeProfileRequest>;
};
}>) => r.body as {
'isSuccess'?: boolean;
'message'?: string;
'data'?: {
'pageNumber'?: number;
'pageSize'?: number;
'totalRowCount'?: number;
'items'?: Array<EmployeeProfileRequest>;
};
})
    );
  }

  /**
   * Path part for operation userListForScheduleAppointment
   */
  static readonly UserListForScheduleAppointmentPath = '/v1/user/list/userForScheduleAppointment';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `userListForScheduleAppointment()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  userListForScheduleAppointment$Response(params: {
    body: EntityGetRequest
  }): Observable<StrictHttpResponse<{
'isSuccess'?: boolean;
'message'?: string;
'data'?: {
'pageNumber'?: number;
'pageSize'?: number;
'totalRowCount'?: number;
'items'?: Array<InternalUserItem>;
};
}>> {

    const rb = new RequestBuilder(this.rootUrl, ApiService.UserListForScheduleAppointmentPath, 'post');
    if (params) {
      rb.body(params.body, 'application/json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<{
        'isSuccess'?: boolean;
        'message'?: string;
        'data'?: {
        'pageNumber'?: number;
        'pageSize'?: number;
        'totalRowCount'?: number;
        'items'?: Array<InternalUserItem>;
        };
        }>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `userListForScheduleAppointment$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  userListForScheduleAppointment(params: {
    body: EntityGetRequest
  }): Observable<{
'isSuccess'?: boolean;
'message'?: string;
'data'?: {
'pageNumber'?: number;
'pageSize'?: number;
'totalRowCount'?: number;
'items'?: Array<InternalUserItem>;
};
}> {

    return this.userListForScheduleAppointment$Response(params).pipe(
      map((r: StrictHttpResponse<{
'isSuccess'?: boolean;
'message'?: string;
'data'?: {
'pageNumber'?: number;
'pageSize'?: number;
'totalRowCount'?: number;
'items'?: Array<InternalUserItem>;
};
}>) => r.body as {
'isSuccess'?: boolean;
'message'?: string;
'data'?: {
'pageNumber'?: number;
'pageSize'?: number;
'totalRowCount'?: number;
'items'?: Array<InternalUserItem>;
};
})
    );
  }

  /**
   * Path part for operation fetchEmployeeBirthdayList
   */
  static readonly FetchEmployeeBirthdayListPath = '/v1/user/list/employeeBirthday';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `fetchEmployeeBirthdayList()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  fetchEmployeeBirthdayList$Response(params: {
    body: EmployeeBirthdayRequest
  }): Observable<StrictHttpResponse<{
'isSuccess'?: boolean;
'message'?: string;
'data'?: {
'pageNumber'?: number;
'pageSize'?: number;
'totalRowCount'?: number;
'items'?: Array<EmployeeBirthdayItem>;
};
}>> {

    const rb = new RequestBuilder(this.rootUrl, ApiService.FetchEmployeeBirthdayListPath, 'post');
    if (params) {
      rb.body(params.body, 'application/json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<{
        'isSuccess'?: boolean;
        'message'?: string;
        'data'?: {
        'pageNumber'?: number;
        'pageSize'?: number;
        'totalRowCount'?: number;
        'items'?: Array<EmployeeBirthdayItem>;
        };
        }>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `fetchEmployeeBirthdayList$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  fetchEmployeeBirthdayList(params: {
    body: EmployeeBirthdayRequest
  }): Observable<{
'isSuccess'?: boolean;
'message'?: string;
'data'?: {
'pageNumber'?: number;
'pageSize'?: number;
'totalRowCount'?: number;
'items'?: Array<EmployeeBirthdayItem>;
};
}> {

    return this.fetchEmployeeBirthdayList$Response(params).pipe(
      map((r: StrictHttpResponse<{
'isSuccess'?: boolean;
'message'?: string;
'data'?: {
'pageNumber'?: number;
'pageSize'?: number;
'totalRowCount'?: number;
'items'?: Array<EmployeeBirthdayItem>;
};
}>) => r.body as {
'isSuccess'?: boolean;
'message'?: string;
'data'?: {
'pageNumber'?: number;
'pageSize'?: number;
'totalRowCount'?: number;
'items'?: Array<EmployeeBirthdayItem>;
};
})
    );
  }

  /**
   * Path part for operation fetchActiveUserList
   */
  static readonly FetchActiveUserListPath = '/v1/user/list/fetchActiveUserList';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `fetchActiveUserList()` instead.
   *
   * This method doesn't expect any request body.
   */
  fetchActiveUserList$Response(params?: {
  }): Observable<StrictHttpResponse<{
'isSuccess'?: boolean;
'message'?: string;
'data'?: {
'pageNumber'?: number;
'pageSize'?: number;
'totalRowCount'?: number;
'items'?: Array<InternalUserItem>;
};
}>> {

    const rb = new RequestBuilder(this.rootUrl, ApiService.FetchActiveUserListPath, 'post');
    if (params) {
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<{
        'isSuccess'?: boolean;
        'message'?: string;
        'data'?: {
        'pageNumber'?: number;
        'pageSize'?: number;
        'totalRowCount'?: number;
        'items'?: Array<InternalUserItem>;
        };
        }>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `fetchActiveUserList$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  fetchActiveUserList(params?: {
  }): Observable<{
'isSuccess'?: boolean;
'message'?: string;
'data'?: {
'pageNumber'?: number;
'pageSize'?: number;
'totalRowCount'?: number;
'items'?: Array<InternalUserItem>;
};
}> {

    return this.fetchActiveUserList$Response(params).pipe(
      map((r: StrictHttpResponse<{
'isSuccess'?: boolean;
'message'?: string;
'data'?: {
'pageNumber'?: number;
'pageSize'?: number;
'totalRowCount'?: number;
'items'?: Array<InternalUserItem>;
};
}>) => r.body as {
'isSuccess'?: boolean;
'message'?: string;
'data'?: {
'pageNumber'?: number;
'pageSize'?: number;
'totalRowCount'?: number;
'items'?: Array<InternalUserItem>;
};
})
    );
  }

  /**
   * Path part for operation fetchEmployeeAnniversaryList
   */
  static readonly FetchEmployeeAnniversaryListPath = '/v1/user/list/employeeAnniversary';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `fetchEmployeeAnniversaryList()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  fetchEmployeeAnniversaryList$Response(params: {
    body: EmployeeAnniversaryRequest
  }): Observable<StrictHttpResponse<{
'isSuccess'?: boolean;
'message'?: string;
'data'?: {
'pageNumber'?: number;
'pageSize'?: number;
'totalRowCount'?: number;
'items'?: Array<EmployeeAnniversaryItem>;
};
}>> {

    const rb = new RequestBuilder(this.rootUrl, ApiService.FetchEmployeeAnniversaryListPath, 'post');
    if (params) {
      rb.body(params.body, 'application/json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<{
        'isSuccess'?: boolean;
        'message'?: string;
        'data'?: {
        'pageNumber'?: number;
        'pageSize'?: number;
        'totalRowCount'?: number;
        'items'?: Array<EmployeeAnniversaryItem>;
        };
        }>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `fetchEmployeeAnniversaryList$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  fetchEmployeeAnniversaryList(params: {
    body: EmployeeAnniversaryRequest
  }): Observable<{
'isSuccess'?: boolean;
'message'?: string;
'data'?: {
'pageNumber'?: number;
'pageSize'?: number;
'totalRowCount'?: number;
'items'?: Array<EmployeeAnniversaryItem>;
};
}> {

    return this.fetchEmployeeAnniversaryList$Response(params).pipe(
      map((r: StrictHttpResponse<{
'isSuccess'?: boolean;
'message'?: string;
'data'?: {
'pageNumber'?: number;
'pageSize'?: number;
'totalRowCount'?: number;
'items'?: Array<EmployeeAnniversaryItem>;
};
}>) => r.body as {
'isSuccess'?: boolean;
'message'?: string;
'data'?: {
'pageNumber'?: number;
'pageSize'?: number;
'totalRowCount'?: number;
'items'?: Array<EmployeeAnniversaryItem>;
};
})
    );
  }

  /**
   * Path part for operation updateUserBirthdayVisibility
   */
  static readonly UpdateUserBirthdayVisibilityPath = '/v1/user/updateUserBirthdayVisibility';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `updateUserBirthdayVisibility()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  updateUserBirthdayVisibility$Response(params: {
    body: EmployeeBirthdayRequest
  }): Observable<StrictHttpResponse<{
'isSuccess'?: boolean;
'message'?: string;
'data'?: string;
}>> {

    const rb = new RequestBuilder(this.rootUrl, ApiService.UpdateUserBirthdayVisibilityPath, 'post');
    if (params) {
      rb.body(params.body, 'application/json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<{
        'isSuccess'?: boolean;
        'message'?: string;
        'data'?: string;
        }>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `updateUserBirthdayVisibility$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  updateUserBirthdayVisibility(params: {
    body: EmployeeBirthdayRequest
  }): Observable<{
'isSuccess'?: boolean;
'message'?: string;
'data'?: string;
}> {

    return this.updateUserBirthdayVisibility$Response(params).pipe(
      map((r: StrictHttpResponse<{
'isSuccess'?: boolean;
'message'?: string;
'data'?: string;
}>) => r.body as {
'isSuccess'?: boolean;
'message'?: string;
'data'?: string;
})
    );
  }

  /**
   * Path part for operation updateUserMobileNumberVisibility
   */
  static readonly UpdateUserMobileNumberVisibilityPath = '/v1/user/updateUserMobileNumberVisibility';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `updateUserMobileNumberVisibility()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  updateUserMobileNumberVisibility$Response(params: {
    body: EmployeeMobileNumberRequest
  }): Observable<StrictHttpResponse<{
'isSuccess'?: boolean;
'message'?: string;
'data'?: string;
}>> {

    const rb = new RequestBuilder(this.rootUrl, ApiService.UpdateUserMobileNumberVisibilityPath, 'post');
    if (params) {
      rb.body(params.body, 'application/json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<{
        'isSuccess'?: boolean;
        'message'?: string;
        'data'?: string;
        }>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `updateUserMobileNumberVisibility$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  updateUserMobileNumberVisibility(params: {
    body: EmployeeMobileNumberRequest
  }): Observable<{
'isSuccess'?: boolean;
'message'?: string;
'data'?: string;
}> {

    return this.updateUserMobileNumberVisibility$Response(params).pipe(
      map((r: StrictHttpResponse<{
'isSuccess'?: boolean;
'message'?: string;
'data'?: string;
}>) => r.body as {
'isSuccess'?: boolean;
'message'?: string;
'data'?: string;
})
    );
  }

  /**
   * Path part for operation fetchAllEmployeePhotoItem
   */
  static readonly FetchAllEmployeePhotoItemPath = '/v1/user/all/employeePhoto';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `fetchAllEmployeePhotoItem()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  fetchAllEmployeePhotoItem$Response(params: {
    body: EmployeePhotoFetchRequest
  }): Observable<StrictHttpResponse<{
'isSuccess'?: boolean;
'message'?: string;
'data'?: {
'pageNumber'?: number;
'pageSize'?: number;
'totalRowCount'?: number;
'items'?: Array<EmployeePhotoItem>;
};
}>> {

    const rb = new RequestBuilder(this.rootUrl, ApiService.FetchAllEmployeePhotoItemPath, 'post');
    if (params) {
      rb.body(params.body, 'application/json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<{
        'isSuccess'?: boolean;
        'message'?: string;
        'data'?: {
        'pageNumber'?: number;
        'pageSize'?: number;
        'totalRowCount'?: number;
        'items'?: Array<EmployeePhotoItem>;
        };
        }>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `fetchAllEmployeePhotoItem$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  fetchAllEmployeePhotoItem(params: {
    body: EmployeePhotoFetchRequest
  }): Observable<{
'isSuccess'?: boolean;
'message'?: string;
'data'?: {
'pageNumber'?: number;
'pageSize'?: number;
'totalRowCount'?: number;
'items'?: Array<EmployeePhotoItem>;
};
}> {

    return this.fetchAllEmployeePhotoItem$Response(params).pipe(
      map((r: StrictHttpResponse<{
'isSuccess'?: boolean;
'message'?: string;
'data'?: {
'pageNumber'?: number;
'pageSize'?: number;
'totalRowCount'?: number;
'items'?: Array<EmployeePhotoItem>;
};
}>) => r.body as {
'isSuccess'?: boolean;
'message'?: string;
'data'?: {
'pageNumber'?: number;
'pageSize'?: number;
'totalRowCount'?: number;
'items'?: Array<EmployeePhotoItem>;
};
})
    );
  }

  /**
   * Path part for operation uploadEmployeeProfileImage
   */
  static readonly UploadEmployeeProfileImagePath = '/v1/user/upload/employeeProfileImage';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `uploadEmployeeProfileImage()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  uploadEmployeeProfileImage$Response(params: {
    body: InternalUserProfileRequest
  }): Observable<StrictHttpResponse<{
'isSuccess'?: boolean;
'message'?: string;
'data'?: string;
}>> {

    const rb = new RequestBuilder(this.rootUrl, ApiService.UploadEmployeeProfileImagePath, 'post');
    if (params) {
      rb.body(params.body, 'application/json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<{
        'isSuccess'?: boolean;
        'message'?: string;
        'data'?: string;
        }>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `uploadEmployeeProfileImage$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  uploadEmployeeProfileImage(params: {
    body: InternalUserProfileRequest
  }): Observable<{
'isSuccess'?: boolean;
'message'?: string;
'data'?: string;
}> {

    return this.uploadEmployeeProfileImage$Response(params).pipe(
      map((r: StrictHttpResponse<{
'isSuccess'?: boolean;
'message'?: string;
'data'?: string;
}>) => r.body as {
'isSuccess'?: boolean;
'message'?: string;
'data'?: string;
})
    );
  }

  /**
   * Path part for operation deleteEmployeePhotoVersion
   */
  static readonly DeleteEmployeePhotoVersionPath = '/v1/user/delete/employeePhotoVersion';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `deleteEmployeePhotoVersion()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  deleteEmployeePhotoVersion$Response(params: {
    body: EmployeePhotoItem
  }): Observable<StrictHttpResponse<{
'isSuccess'?: boolean;
'message'?: string;
'data'?: string;
}>> {

    const rb = new RequestBuilder(this.rootUrl, ApiService.DeleteEmployeePhotoVersionPath, 'post');
    if (params) {
      rb.body(params.body, 'application/json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<{
        'isSuccess'?: boolean;
        'message'?: string;
        'data'?: string;
        }>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `deleteEmployeePhotoVersion$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  deleteEmployeePhotoVersion(params: {
    body: EmployeePhotoItem
  }): Observable<{
'isSuccess'?: boolean;
'message'?: string;
'data'?: string;
}> {

    return this.deleteEmployeePhotoVersion$Response(params).pipe(
      map((r: StrictHttpResponse<{
'isSuccess'?: boolean;
'message'?: string;
'data'?: string;
}>) => r.body as {
'isSuccess'?: boolean;
'message'?: string;
'data'?: string;
})
    );
  }

  /**
   * Path part for operation updateActiveEmployeePhoto
   */
  static readonly UpdateActiveEmployeePhotoPath = '/v1/user/update/activeEmployeePhoto';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `updateActiveEmployeePhoto()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  updateActiveEmployeePhoto$Response(params: {
    body: EmployeePhotoItem
  }): Observable<StrictHttpResponse<{
'isSuccess'?: boolean;
'message'?: string;
'data'?: string;
}>> {

    const rb = new RequestBuilder(this.rootUrl, ApiService.UpdateActiveEmployeePhotoPath, 'post');
    if (params) {
      rb.body(params.body, 'application/json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<{
        'isSuccess'?: boolean;
        'message'?: string;
        'data'?: string;
        }>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `updateActiveEmployeePhoto$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  updateActiveEmployeePhoto(params: {
    body: EmployeePhotoItem
  }): Observable<{
'isSuccess'?: boolean;
'message'?: string;
'data'?: string;
}> {

    return this.updateActiveEmployeePhoto$Response(params).pipe(
      map((r: StrictHttpResponse<{
'isSuccess'?: boolean;
'message'?: string;
'data'?: string;
}>) => r.body as {
'isSuccess'?: boolean;
'message'?: string;
'data'?: string;
})
    );
  }

  /**
   * Path part for operation fetchAllUserList
   */
  static readonly FetchAllUserListPath = '/v1/user/list/fetchAllUserList';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `fetchAllUserList()` instead.
   *
   * This method doesn't expect any request body.
   */
  fetchAllUserList$Response(params?: {
  }): Observable<StrictHttpResponse<{
'isSuccess'?: boolean;
'message'?: string;
'data'?: {
'pageNumber'?: number;
'pageSize'?: number;
'totalRowCount'?: number;
'items'?: Array<InternalUserItem>;
};
}>> {

    const rb = new RequestBuilder(this.rootUrl, ApiService.FetchAllUserListPath, 'post');
    if (params) {
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<{
        'isSuccess'?: boolean;
        'message'?: string;
        'data'?: {
        'pageNumber'?: number;
        'pageSize'?: number;
        'totalRowCount'?: number;
        'items'?: Array<InternalUserItem>;
        };
        }>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `fetchAllUserList$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  fetchAllUserList(params?: {
  }): Observable<{
'isSuccess'?: boolean;
'message'?: string;
'data'?: {
'pageNumber'?: number;
'pageSize'?: number;
'totalRowCount'?: number;
'items'?: Array<InternalUserItem>;
};
}> {

    return this.fetchAllUserList$Response(params).pipe(
      map((r: StrictHttpResponse<{
'isSuccess'?: boolean;
'message'?: string;
'data'?: {
'pageNumber'?: number;
'pageSize'?: number;
'totalRowCount'?: number;
'items'?: Array<InternalUserItem>;
};
}>) => r.body as {
'isSuccess'?: boolean;
'message'?: string;
'data'?: {
'pageNumber'?: number;
'pageSize'?: number;
'totalRowCount'?: number;
'items'?: Array<InternalUserItem>;
};
})
    );
  }

  /**
   * Path part for operation mergeInternalUser
   */
  static readonly MergeInternalUserPath = '/v1/user/merge/internalUser';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `mergeInternalUser()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  mergeInternalUser$Response(params: {
    body: MergeInternalUserItem
  }): Observable<StrictHttpResponse<{
'isSuccess'?: boolean;
'message'?: string;
'data'?: string;
}>> {

    const rb = new RequestBuilder(this.rootUrl, ApiService.MergeInternalUserPath, 'post');
    if (params) {
      rb.body(params.body, 'application/json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<{
        'isSuccess'?: boolean;
        'message'?: string;
        'data'?: string;
        }>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `mergeInternalUser$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  mergeInternalUser(params: {
    body: MergeInternalUserItem
  }): Observable<{
'isSuccess'?: boolean;
'message'?: string;
'data'?: string;
}> {

    return this.mergeInternalUser$Response(params).pipe(
      map((r: StrictHttpResponse<{
'isSuccess'?: boolean;
'message'?: string;
'data'?: string;
}>) => r.body as {
'isSuccess'?: boolean;
'message'?: string;
'data'?: string;
})
    );
  }

  /**
   * Path part for operation acquireLinkToken
   */
  static readonly AcquireLinkTokenPath = '/v1/userFinancialIdentity/linkToken';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `acquireLinkToken()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  acquireLinkToken$Response(params: {
    body: CreateLinkTokenRequest
  }): Observable<StrictHttpResponse<{
'isSuccess'?: boolean;
'message'?: string;
'data'?: LinkToken;
}>> {

    const rb = new RequestBuilder(this.rootUrl, ApiService.AcquireLinkTokenPath, 'post');
    if (params) {
      rb.body(params.body, 'application/json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<{
        'isSuccess'?: boolean;
        'message'?: string;
        'data'?: LinkToken;
        }>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `acquireLinkToken$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  acquireLinkToken(params: {
    body: CreateLinkTokenRequest
  }): Observable<{
'isSuccess'?: boolean;
'message'?: string;
'data'?: LinkToken;
}> {

    return this.acquireLinkToken$Response(params).pipe(
      map((r: StrictHttpResponse<{
'isSuccess'?: boolean;
'message'?: string;
'data'?: LinkToken;
}>) => r.body as {
'isSuccess'?: boolean;
'message'?: string;
'data'?: LinkToken;
})
    );
  }

  /**
   * Path part for operation acquireAccessToken
   */
  static readonly AcquireAccessTokenPath = '/v1/userFinancialIdentity/accessToken';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `acquireAccessToken()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  acquireAccessToken$Response(params: {
    body: SetTokenRequest
  }): Observable<StrictHttpResponse<{
'isSuccess'?: boolean;
'message'?: string;
'data'?: AccessTokenResponse;
}>> {

    const rb = new RequestBuilder(this.rootUrl, ApiService.AcquireAccessTokenPath, 'post');
    if (params) {
      rb.body(params.body, 'application/json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<{
        'isSuccess'?: boolean;
        'message'?: string;
        'data'?: AccessTokenResponse;
        }>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `acquireAccessToken$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  acquireAccessToken(params: {
    body: SetTokenRequest
  }): Observable<{
'isSuccess'?: boolean;
'message'?: string;
'data'?: AccessTokenResponse;
}> {

    return this.acquireAccessToken$Response(params).pipe(
      map((r: StrictHttpResponse<{
'isSuccess'?: boolean;
'message'?: string;
'data'?: AccessTokenResponse;
}>) => r.body as {
'isSuccess'?: boolean;
'message'?: string;
'data'?: AccessTokenResponse;
})
    );
  }

  /**
   * Path part for operation identityVerificationAdd
   */
  static readonly IdentityVerificationAddPath = '/v1/userFinancialIdentity/add';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `identityVerificationAdd()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  identityVerificationAdd$Response(params: {
    body: UserIdentityVerificationRequest
  }): Observable<StrictHttpResponse<{
'isSuccess'?: boolean;
'message'?: string;
'data'?: string;
}>> {

    const rb = new RequestBuilder(this.rootUrl, ApiService.IdentityVerificationAddPath, 'post');
    if (params) {
      rb.body(params.body, 'application/json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<{
        'isSuccess'?: boolean;
        'message'?: string;
        'data'?: string;
        }>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `identityVerificationAdd$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  identityVerificationAdd(params: {
    body: UserIdentityVerificationRequest
  }): Observable<{
'isSuccess'?: boolean;
'message'?: string;
'data'?: string;
}> {

    return this.identityVerificationAdd$Response(params).pipe(
      map((r: StrictHttpResponse<{
'isSuccess'?: boolean;
'message'?: string;
'data'?: string;
}>) => r.body as {
'isSuccess'?: boolean;
'message'?: string;
'data'?: string;
})
    );
  }

  /**
   * Path part for operation identityVerificationInfoAdd
   */
  static readonly IdentityVerificationInfoAddPath = '/v1/userFinancialIdentity/add/identityVerificationInfo';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `identityVerificationInfoAdd()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  identityVerificationInfoAdd$Response(params: {
    body: PlaidIdentityInfoRequest
  }): Observable<StrictHttpResponse<{
'isSuccess'?: boolean;
'message'?: string;
'data'?: string;
}>> {

    const rb = new RequestBuilder(this.rootUrl, ApiService.IdentityVerificationInfoAddPath, 'post');
    if (params) {
      rb.body(params.body, 'application/json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<{
        'isSuccess'?: boolean;
        'message'?: string;
        'data'?: string;
        }>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `identityVerificationInfoAdd$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  identityVerificationInfoAdd(params: {
    body: PlaidIdentityInfoRequest
  }): Observable<{
'isSuccess'?: boolean;
'message'?: string;
'data'?: string;
}> {

    return this.identityVerificationInfoAdd$Response(params).pipe(
      map((r: StrictHttpResponse<{
'isSuccess'?: boolean;
'message'?: string;
'data'?: string;
}>) => r.body as {
'isSuccess'?: boolean;
'message'?: string;
'data'?: string;
})
    );
  }

  /**
   * Path part for operation identityVerificationList
   */
  static readonly IdentityVerificationListPath = '/v1/userFinancialIdentity/list';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `identityVerificationList()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  identityVerificationList$Response(params: {
    body: ListUserIdentityVerificationRequest
  }): Observable<StrictHttpResponse<{
'isSuccess'?: boolean;
'message'?: string;
'data'?: IdentityVerificationItemResponse;
}>> {

    const rb = new RequestBuilder(this.rootUrl, ApiService.IdentityVerificationListPath, 'post');
    if (params) {
      rb.body(params.body, 'application/json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<{
        'isSuccess'?: boolean;
        'message'?: string;
        'data'?: IdentityVerificationItemResponse;
        }>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `identityVerificationList$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  identityVerificationList(params: {
    body: ListUserIdentityVerificationRequest
  }): Observable<{
'isSuccess'?: boolean;
'message'?: string;
'data'?: IdentityVerificationItemResponse;
}> {

    return this.identityVerificationList$Response(params).pipe(
      map((r: StrictHttpResponse<{
'isSuccess'?: boolean;
'message'?: string;
'data'?: IdentityVerificationItemResponse;
}>) => r.body as {
'isSuccess'?: boolean;
'message'?: string;
'data'?: IdentityVerificationItemResponse;
})
    );
  }

  /**
   * Path part for operation getIdentityVerification
   */
  static readonly GetIdentityVerificationPath = '/v1/userFinancialIdentity/single';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getIdentityVerification()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  getIdentityVerification$Response(params: {
    body: GetUserIdentityVerificationRequest
  }): Observable<StrictHttpResponse<{
'isSuccess'?: boolean;
'message'?: string;
'data'?: Root;
}>> {

    const rb = new RequestBuilder(this.rootUrl, ApiService.GetIdentityVerificationPath, 'post');
    if (params) {
      rb.body(params.body, 'application/json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<{
        'isSuccess'?: boolean;
        'message'?: string;
        'data'?: Root;
        }>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `getIdentityVerification$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  getIdentityVerification(params: {
    body: GetUserIdentityVerificationRequest
  }): Observable<{
'isSuccess'?: boolean;
'message'?: string;
'data'?: Root;
}> {

    return this.getIdentityVerification$Response(params).pipe(
      map((r: StrictHttpResponse<{
'isSuccess'?: boolean;
'message'?: string;
'data'?: Root;
}>) => r.body as {
'isSuccess'?: boolean;
'message'?: string;
'data'?: Root;
})
    );
  }

  /**
   * Path part for operation identityVerificationRetry
   */
  static readonly IdentityVerificationRetryPath = '/v1/userFinancialIdentity/retry';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `identityVerificationRetry()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  identityVerificationRetry$Response(params: {
    body: ListUserIdentityVerificationRequest
  }): Observable<StrictHttpResponse<{
'isSuccess'?: boolean;
'message'?: string;
'data'?: RetryIdentityVerificationItemResponse;
}>> {

    const rb = new RequestBuilder(this.rootUrl, ApiService.IdentityVerificationRetryPath, 'post');
    if (params) {
      rb.body(params.body, 'application/json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<{
        'isSuccess'?: boolean;
        'message'?: string;
        'data'?: RetryIdentityVerificationItemResponse;
        }>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `identityVerificationRetry$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  identityVerificationRetry(params: {
    body: ListUserIdentityVerificationRequest
  }): Observable<{
'isSuccess'?: boolean;
'message'?: string;
'data'?: RetryIdentityVerificationItemResponse;
}> {

    return this.identityVerificationRetry$Response(params).pipe(
      map((r: StrictHttpResponse<{
'isSuccess'?: boolean;
'message'?: string;
'data'?: RetryIdentityVerificationItemResponse;
}>) => r.body as {
'isSuccess'?: boolean;
'message'?: string;
'data'?: RetryIdentityVerificationItemResponse;
})
    );
  }

  /**
   * Path part for operation permissionList
   */
  static readonly PermissionListPath = '/v1/permission/list';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `permissionList()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  permissionList$Response(params: {
    body: CustomEntityGetRequest
  }): Observable<StrictHttpResponse<{
'isSuccess'?: boolean;
'message'?: string;
'data'?: {
'pageNumber'?: number;
'pageSize'?: number;
'totalRowCount'?: number;
'items'?: Array<InternalPermissionListItem>;
};
}>> {

    const rb = new RequestBuilder(this.rootUrl, ApiService.PermissionListPath, 'post');
    if (params) {
      rb.body(params.body, 'application/json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<{
        'isSuccess'?: boolean;
        'message'?: string;
        'data'?: {
        'pageNumber'?: number;
        'pageSize'?: number;
        'totalRowCount'?: number;
        'items'?: Array<InternalPermissionListItem>;
        };
        }>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `permissionList$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  permissionList(params: {
    body: CustomEntityGetRequest
  }): Observable<{
'isSuccess'?: boolean;
'message'?: string;
'data'?: {
'pageNumber'?: number;
'pageSize'?: number;
'totalRowCount'?: number;
'items'?: Array<InternalPermissionListItem>;
};
}> {

    return this.permissionList$Response(params).pipe(
      map((r: StrictHttpResponse<{
'isSuccess'?: boolean;
'message'?: string;
'data'?: {
'pageNumber'?: number;
'pageSize'?: number;
'totalRowCount'?: number;
'items'?: Array<InternalPermissionListItem>;
};
}>) => r.body as {
'isSuccess'?: boolean;
'message'?: string;
'data'?: {
'pageNumber'?: number;
'pageSize'?: number;
'totalRowCount'?: number;
'items'?: Array<InternalPermissionListItem>;
};
})
    );
  }

  /**
   * Path part for operation userPermissionList
   */
  static readonly UserPermissionListPath = '/v1/permission/list/user';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `userPermissionList()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  userPermissionList$Response(params: {
    body: InternalUserPermissionRequest
  }): Observable<StrictHttpResponse<{
'isSuccess'?: boolean;
'message'?: string;
'data'?: {
'pageNumber'?: number;
'pageSize'?: number;
'totalRowCount'?: number;
'items'?: Array<InternalUserPermissionListItem>;
};
}>> {

    const rb = new RequestBuilder(this.rootUrl, ApiService.UserPermissionListPath, 'post');
    if (params) {
      rb.body(params.body, 'application/json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<{
        'isSuccess'?: boolean;
        'message'?: string;
        'data'?: {
        'pageNumber'?: number;
        'pageSize'?: number;
        'totalRowCount'?: number;
        'items'?: Array<InternalUserPermissionListItem>;
        };
        }>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `userPermissionList$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  userPermissionList(params: {
    body: InternalUserPermissionRequest
  }): Observable<{
'isSuccess'?: boolean;
'message'?: string;
'data'?: {
'pageNumber'?: number;
'pageSize'?: number;
'totalRowCount'?: number;
'items'?: Array<InternalUserPermissionListItem>;
};
}> {

    return this.userPermissionList$Response(params).pipe(
      map((r: StrictHttpResponse<{
'isSuccess'?: boolean;
'message'?: string;
'data'?: {
'pageNumber'?: number;
'pageSize'?: number;
'totalRowCount'?: number;
'items'?: Array<InternalUserPermissionListItem>;
};
}>) => r.body as {
'isSuccess'?: boolean;
'message'?: string;
'data'?: {
'pageNumber'?: number;
'pageSize'?: number;
'totalRowCount'?: number;
'items'?: Array<InternalUserPermissionListItem>;
};
})
    );
  }

  /**
   * Path part for operation allUserPermission
   */
  static readonly AllUserPermissionPath = '/v1/permission/list/user/allPermission';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `allUserPermission()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  allUserPermission$Response(params: {
    body: InternalUserPermissionRequest
  }): Observable<StrictHttpResponse<{
'isSuccess'?: boolean;
'message'?: string;
'data'?: {
'pageNumber'?: number;
'pageSize'?: number;
'totalRowCount'?: number;
'items'?: Array<InternalTitlePermissionListItem>;
};
}>> {

    const rb = new RequestBuilder(this.rootUrl, ApiService.AllUserPermissionPath, 'post');
    if (params) {
      rb.body(params.body, 'application/json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<{
        'isSuccess'?: boolean;
        'message'?: string;
        'data'?: {
        'pageNumber'?: number;
        'pageSize'?: number;
        'totalRowCount'?: number;
        'items'?: Array<InternalTitlePermissionListItem>;
        };
        }>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `allUserPermission$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  allUserPermission(params: {
    body: InternalUserPermissionRequest
  }): Observable<{
'isSuccess'?: boolean;
'message'?: string;
'data'?: {
'pageNumber'?: number;
'pageSize'?: number;
'totalRowCount'?: number;
'items'?: Array<InternalTitlePermissionListItem>;
};
}> {

    return this.allUserPermission$Response(params).pipe(
      map((r: StrictHttpResponse<{
'isSuccess'?: boolean;
'message'?: string;
'data'?: {
'pageNumber'?: number;
'pageSize'?: number;
'totalRowCount'?: number;
'items'?: Array<InternalTitlePermissionListItem>;
};
}>) => r.body as {
'isSuccess'?: boolean;
'message'?: string;
'data'?: {
'pageNumber'?: number;
'pageSize'?: number;
'totalRowCount'?: number;
'items'?: Array<InternalTitlePermissionListItem>;
};
})
    );
  }

  /**
   * Path part for operation permissionAdd
   */
  static readonly PermissionAddPath = '/v1/permission/add';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `permissionAdd()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  permissionAdd$Response(params: {
    body: InternalPermission
  }): Observable<StrictHttpResponse<{
'isSuccess'?: boolean;
'message'?: string;
'data'?: string;
}>> {

    const rb = new RequestBuilder(this.rootUrl, ApiService.PermissionAddPath, 'post');
    if (params) {
      rb.body(params.body, 'application/json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<{
        'isSuccess'?: boolean;
        'message'?: string;
        'data'?: string;
        }>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `permissionAdd$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  permissionAdd(params: {
    body: InternalPermission
  }): Observable<{
'isSuccess'?: boolean;
'message'?: string;
'data'?: string;
}> {

    return this.permissionAdd$Response(params).pipe(
      map((r: StrictHttpResponse<{
'isSuccess'?: boolean;
'message'?: string;
'data'?: string;
}>) => r.body as {
'isSuccess'?: boolean;
'message'?: string;
'data'?: string;
})
    );
  }

  /**
   * Path part for operation permissionUpdate
   */
  static readonly PermissionUpdatePath = '/v1/permission/update';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `permissionUpdate()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  permissionUpdate$Response(params: {
    body: InternalPermission
  }): Observable<StrictHttpResponse<{
'isSuccess'?: boolean;
'message'?: string;
'data'?: string;
}>> {

    const rb = new RequestBuilder(this.rootUrl, ApiService.PermissionUpdatePath, 'put');
    if (params) {
      rb.body(params.body, 'application/json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<{
        'isSuccess'?: boolean;
        'message'?: string;
        'data'?: string;
        }>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `permissionUpdate$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  permissionUpdate(params: {
    body: InternalPermission
  }): Observable<{
'isSuccess'?: boolean;
'message'?: string;
'data'?: string;
}> {

    return this.permissionUpdate$Response(params).pipe(
      map((r: StrictHttpResponse<{
'isSuccess'?: boolean;
'message'?: string;
'data'?: string;
}>) => r.body as {
'isSuccess'?: boolean;
'message'?: string;
'data'?: string;
})
    );
  }

  /**
   * Path part for operation permissionDelete
   */
  static readonly PermissionDeletePath = '/v1/permission/delete';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `permissionDelete()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  permissionDelete$Response(params: {
    body: DeleteRequest
  }): Observable<StrictHttpResponse<{
'isSuccess'?: boolean;
'message'?: string;
'data'?: string;
}>> {

    const rb = new RequestBuilder(this.rootUrl, ApiService.PermissionDeletePath, 'delete');
    if (params) {
      rb.body(params.body, 'application/json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<{
        'isSuccess'?: boolean;
        'message'?: string;
        'data'?: string;
        }>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `permissionDelete$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  permissionDelete(params: {
    body: DeleteRequest
  }): Observable<{
'isSuccess'?: boolean;
'message'?: string;
'data'?: string;
}> {

    return this.permissionDelete$Response(params).pipe(
      map((r: StrictHttpResponse<{
'isSuccess'?: boolean;
'message'?: string;
'data'?: string;
}>) => r.body as {
'isSuccess'?: boolean;
'message'?: string;
'data'?: string;
})
    );
  }

  /**
   * Path part for operation roleList
   */
  static readonly RoleListPath = '/v1/permission/list/role';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `roleList()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  roleList$Response(params: {
    body: EntityGetRequest
  }): Observable<StrictHttpResponse<{
'isSuccess'?: boolean;
'message'?: string;
'data'?: {
'pageNumber'?: number;
'pageSize'?: number;
'totalRowCount'?: number;
'items'?: Array<InternalRoleListItem>;
};
}>> {

    const rb = new RequestBuilder(this.rootUrl, ApiService.RoleListPath, 'post');
    if (params) {
      rb.body(params.body, 'application/json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<{
        'isSuccess'?: boolean;
        'message'?: string;
        'data'?: {
        'pageNumber'?: number;
        'pageSize'?: number;
        'totalRowCount'?: number;
        'items'?: Array<InternalRoleListItem>;
        };
        }>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `roleList$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  roleList(params: {
    body: EntityGetRequest
  }): Observable<{
'isSuccess'?: boolean;
'message'?: string;
'data'?: {
'pageNumber'?: number;
'pageSize'?: number;
'totalRowCount'?: number;
'items'?: Array<InternalRoleListItem>;
};
}> {

    return this.roleList$Response(params).pipe(
      map((r: StrictHttpResponse<{
'isSuccess'?: boolean;
'message'?: string;
'data'?: {
'pageNumber'?: number;
'pageSize'?: number;
'totalRowCount'?: number;
'items'?: Array<InternalRoleListItem>;
};
}>) => r.body as {
'isSuccess'?: boolean;
'message'?: string;
'data'?: {
'pageNumber'?: number;
'pageSize'?: number;
'totalRowCount'?: number;
'items'?: Array<InternalRoleListItem>;
};
})
    );
  }

  /**
   * Path part for operation roleAdd
   */
  static readonly RoleAddPath = '/v1/permission/add/role';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `roleAdd()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  roleAdd$Response(params: {
    body: InternalRole
  }): Observable<StrictHttpResponse<{
'isSuccess'?: boolean;
'message'?: string;
'data'?: string;
}>> {

    const rb = new RequestBuilder(this.rootUrl, ApiService.RoleAddPath, 'post');
    if (params) {
      rb.body(params.body, 'application/json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<{
        'isSuccess'?: boolean;
        'message'?: string;
        'data'?: string;
        }>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `roleAdd$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  roleAdd(params: {
    body: InternalRole
  }): Observable<{
'isSuccess'?: boolean;
'message'?: string;
'data'?: string;
}> {

    return this.roleAdd$Response(params).pipe(
      map((r: StrictHttpResponse<{
'isSuccess'?: boolean;
'message'?: string;
'data'?: string;
}>) => r.body as {
'isSuccess'?: boolean;
'message'?: string;
'data'?: string;
})
    );
  }

  /**
   * Path part for operation roleDelete
   */
  static readonly RoleDeletePath = '/v1/permission/delete/role';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `roleDelete()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  roleDelete$Response(params: {
    body: DeleteRequest
  }): Observable<StrictHttpResponse<{
'isSuccess'?: boolean;
'message'?: string;
'data'?: string;
}>> {

    const rb = new RequestBuilder(this.rootUrl, ApiService.RoleDeletePath, 'delete');
    if (params) {
      rb.body(params.body, 'application/json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<{
        'isSuccess'?: boolean;
        'message'?: string;
        'data'?: string;
        }>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `roleDelete$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  roleDelete(params: {
    body: DeleteRequest
  }): Observable<{
'isSuccess'?: boolean;
'message'?: string;
'data'?: string;
}> {

    return this.roleDelete$Response(params).pipe(
      map((r: StrictHttpResponse<{
'isSuccess'?: boolean;
'message'?: string;
'data'?: string;
}>) => r.body as {
'isSuccess'?: boolean;
'message'?: string;
'data'?: string;
})
    );
  }

  /**
   * Path part for operation roleForUserList
   */
  static readonly RoleForUserListPath = '/v1/permission/list/roleForUser';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `roleForUserList()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  roleForUserList$Response(params: {
    body: EntityGetRequest
  }): Observable<StrictHttpResponse<{
'isSuccess'?: boolean;
'message'?: string;
'data'?: {
'pageNumber'?: number;
'pageSize'?: number;
'totalRowCount'?: number;
'items'?: Array<RoleForUserList>;
};
}>> {

    const rb = new RequestBuilder(this.rootUrl, ApiService.RoleForUserListPath, 'post');
    if (params) {
      rb.body(params.body, 'application/json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<{
        'isSuccess'?: boolean;
        'message'?: string;
        'data'?: {
        'pageNumber'?: number;
        'pageSize'?: number;
        'totalRowCount'?: number;
        'items'?: Array<RoleForUserList>;
        };
        }>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `roleForUserList$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  roleForUserList(params: {
    body: EntityGetRequest
  }): Observable<{
'isSuccess'?: boolean;
'message'?: string;
'data'?: {
'pageNumber'?: number;
'pageSize'?: number;
'totalRowCount'?: number;
'items'?: Array<RoleForUserList>;
};
}> {

    return this.roleForUserList$Response(params).pipe(
      map((r: StrictHttpResponse<{
'isSuccess'?: boolean;
'message'?: string;
'data'?: {
'pageNumber'?: number;
'pageSize'?: number;
'totalRowCount'?: number;
'items'?: Array<RoleForUserList>;
};
}>) => r.body as {
'isSuccess'?: boolean;
'message'?: string;
'data'?: {
'pageNumber'?: number;
'pageSize'?: number;
'totalRowCount'?: number;
'items'?: Array<RoleForUserList>;
};
})
    );
  }

  /**
   * Path part for operation nestedRoleXPermissionList
   */
  static readonly NestedRoleXPermissionListPath = '/v1/permission/list/nestedRoleXPermission';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `nestedRoleXPermissionList()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  nestedRoleXPermissionList$Response(params: {
    body: PermissionForRolesRequest
  }): Observable<StrictHttpResponse<{
'isSuccess'?: boolean;
'message'?: string;
'data'?: {
'pageNumber'?: number;
'pageSize'?: number;
'totalRowCount'?: number;
'items'?: Array<InternalRoleXPermissionListItem>;
};
}>> {

    const rb = new RequestBuilder(this.rootUrl, ApiService.NestedRoleXPermissionListPath, 'post');
    if (params) {
      rb.body(params.body, 'application/json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<{
        'isSuccess'?: boolean;
        'message'?: string;
        'data'?: {
        'pageNumber'?: number;
        'pageSize'?: number;
        'totalRowCount'?: number;
        'items'?: Array<InternalRoleXPermissionListItem>;
        };
        }>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `nestedRoleXPermissionList$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  nestedRoleXPermissionList(params: {
    body: PermissionForRolesRequest
  }): Observable<{
'isSuccess'?: boolean;
'message'?: string;
'data'?: {
'pageNumber'?: number;
'pageSize'?: number;
'totalRowCount'?: number;
'items'?: Array<InternalRoleXPermissionListItem>;
};
}> {

    return this.nestedRoleXPermissionList$Response(params).pipe(
      map((r: StrictHttpResponse<{
'isSuccess'?: boolean;
'message'?: string;
'data'?: {
'pageNumber'?: number;
'pageSize'?: number;
'totalRowCount'?: number;
'items'?: Array<InternalRoleXPermissionListItem>;
};
}>) => r.body as {
'isSuccess'?: boolean;
'message'?: string;
'data'?: {
'pageNumber'?: number;
'pageSize'?: number;
'totalRowCount'?: number;
'items'?: Array<InternalRoleXPermissionListItem>;
};
})
    );
  }

  /**
   * Path part for operation roleXPermissionList
   */
  static readonly RoleXPermissionListPath = '/v1/permission/list/roleXPermission';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `roleXPermissionList()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  roleXPermissionList$Response(params: {
    body: EntityGetRequest
  }): Observable<StrictHttpResponse<{
'isSuccess'?: boolean;
'message'?: string;
'data'?: {
'pageNumber'?: number;
'pageSize'?: number;
'totalRowCount'?: number;
'items'?: Array<InternalRoleXPermissionListItem>;
};
}>> {

    const rb = new RequestBuilder(this.rootUrl, ApiService.RoleXPermissionListPath, 'post');
    if (params) {
      rb.body(params.body, 'application/json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<{
        'isSuccess'?: boolean;
        'message'?: string;
        'data'?: {
        'pageNumber'?: number;
        'pageSize'?: number;
        'totalRowCount'?: number;
        'items'?: Array<InternalRoleXPermissionListItem>;
        };
        }>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `roleXPermissionList$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  roleXPermissionList(params: {
    body: EntityGetRequest
  }): Observable<{
'isSuccess'?: boolean;
'message'?: string;
'data'?: {
'pageNumber'?: number;
'pageSize'?: number;
'totalRowCount'?: number;
'items'?: Array<InternalRoleXPermissionListItem>;
};
}> {

    return this.roleXPermissionList$Response(params).pipe(
      map((r: StrictHttpResponse<{
'isSuccess'?: boolean;
'message'?: string;
'data'?: {
'pageNumber'?: number;
'pageSize'?: number;
'totalRowCount'?: number;
'items'?: Array<InternalRoleXPermissionListItem>;
};
}>) => r.body as {
'isSuccess'?: boolean;
'message'?: string;
'data'?: {
'pageNumber'?: number;
'pageSize'?: number;
'totalRowCount'?: number;
'items'?: Array<InternalRoleXPermissionListItem>;
};
})
    );
  }

  /**
   * Path part for operation roleXPermissionAdd
   */
  static readonly RoleXPermissionAddPath = '/v1/permission/add/roleXPermission';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `roleXPermissionAdd()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  roleXPermissionAdd$Response(params: {
    body: InternalRoleXPermissionSaveList
  }): Observable<StrictHttpResponse<{
'isSuccess'?: boolean;
'message'?: string;
'data'?: string;
}>> {

    const rb = new RequestBuilder(this.rootUrl, ApiService.RoleXPermissionAddPath, 'post');
    if (params) {
      rb.body(params.body, 'application/json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<{
        'isSuccess'?: boolean;
        'message'?: string;
        'data'?: string;
        }>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `roleXPermissionAdd$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  roleXPermissionAdd(params: {
    body: InternalRoleXPermissionSaveList
  }): Observable<{
'isSuccess'?: boolean;
'message'?: string;
'data'?: string;
}> {

    return this.roleXPermissionAdd$Response(params).pipe(
      map((r: StrictHttpResponse<{
'isSuccess'?: boolean;
'message'?: string;
'data'?: string;
}>) => r.body as {
'isSuccess'?: boolean;
'message'?: string;
'data'?: string;
})
    );
  }

  /**
   * Path part for operation roleXPermissionDelete
   */
  static readonly RoleXPermissionDeletePath = '/v1/permission/delete/roleXPermission';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `roleXPermissionDelete()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  roleXPermissionDelete$Response(params: {
    body: DeleteRequest
  }): Observable<StrictHttpResponse<{
'isSuccess'?: boolean;
'message'?: string;
'data'?: string;
}>> {

    const rb = new RequestBuilder(this.rootUrl, ApiService.RoleXPermissionDeletePath, 'delete');
    if (params) {
      rb.body(params.body, 'application/json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<{
        'isSuccess'?: boolean;
        'message'?: string;
        'data'?: string;
        }>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `roleXPermissionDelete$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  roleXPermissionDelete(params: {
    body: DeleteRequest
  }): Observable<{
'isSuccess'?: boolean;
'message'?: string;
'data'?: string;
}> {

    return this.roleXPermissionDelete$Response(params).pipe(
      map((r: StrictHttpResponse<{
'isSuccess'?: boolean;
'message'?: string;
'data'?: string;
}>) => r.body as {
'isSuccess'?: boolean;
'message'?: string;
'data'?: string;
})
    );
  }

  /**
   * Path part for operation inheritedRolesAdd
   */
  static readonly InheritedRolesAddPath = '/v1/permission/add/inheritedRoles';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `inheritedRolesAdd()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  inheritedRolesAdd$Response(params: {
    body: InheritedRoleSaveList
  }): Observable<StrictHttpResponse<{
'isSuccess'?: boolean;
'message'?: string;
'data'?: string;
}>> {

    const rb = new RequestBuilder(this.rootUrl, ApiService.InheritedRolesAddPath, 'post');
    if (params) {
      rb.body(params.body, 'application/json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<{
        'isSuccess'?: boolean;
        'message'?: string;
        'data'?: string;
        }>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `inheritedRolesAdd$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  inheritedRolesAdd(params: {
    body: InheritedRoleSaveList
  }): Observable<{
'isSuccess'?: boolean;
'message'?: string;
'data'?: string;
}> {

    return this.inheritedRolesAdd$Response(params).pipe(
      map((r: StrictHttpResponse<{
'isSuccess'?: boolean;
'message'?: string;
'data'?: string;
}>) => r.body as {
'isSuccess'?: boolean;
'message'?: string;
'data'?: string;
})
    );
  }

  /**
   * Path part for operation inheritedRolesList
   */
  static readonly InheritedRolesListPath = '/v1/permission/list/inheritedRoles';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `inheritedRolesList()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  inheritedRolesList$Response(params: {
    body: EntityGetRequest
  }): Observable<StrictHttpResponse<{
'isSuccess'?: boolean;
'message'?: string;
'data'?: {
'pageNumber'?: number;
'pageSize'?: number;
'totalRowCount'?: number;
'items'?: Array<InheritedRoleListItem>;
};
}>> {

    const rb = new RequestBuilder(this.rootUrl, ApiService.InheritedRolesListPath, 'post');
    if (params) {
      rb.body(params.body, 'application/json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<{
        'isSuccess'?: boolean;
        'message'?: string;
        'data'?: {
        'pageNumber'?: number;
        'pageSize'?: number;
        'totalRowCount'?: number;
        'items'?: Array<InheritedRoleListItem>;
        };
        }>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `inheritedRolesList$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  inheritedRolesList(params: {
    body: EntityGetRequest
  }): Observable<{
'isSuccess'?: boolean;
'message'?: string;
'data'?: {
'pageNumber'?: number;
'pageSize'?: number;
'totalRowCount'?: number;
'items'?: Array<InheritedRoleListItem>;
};
}> {

    return this.inheritedRolesList$Response(params).pipe(
      map((r: StrictHttpResponse<{
'isSuccess'?: boolean;
'message'?: string;
'data'?: {
'pageNumber'?: number;
'pageSize'?: number;
'totalRowCount'?: number;
'items'?: Array<InheritedRoleListItem>;
};
}>) => r.body as {
'isSuccess'?: boolean;
'message'?: string;
'data'?: {
'pageNumber'?: number;
'pageSize'?: number;
'totalRowCount'?: number;
'items'?: Array<InheritedRoleListItem>;
};
})
    );
  }

  /**
   * Path part for operation childRoleList
   */
  static readonly ChildRoleListPath = '/v1/permission/list/childRoles';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `childRoleList()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  childRoleList$Response(params: {
    body: InheritedRoleXEntityRequest
  }): Observable<StrictHttpResponse<{
'isSuccess'?: boolean;
'message'?: string;
'data'?: {
'pageNumber'?: number;
'pageSize'?: number;
'totalRowCount'?: number;
'items'?: Array<InheritedRoleListItem>;
};
}>> {

    const rb = new RequestBuilder(this.rootUrl, ApiService.ChildRoleListPath, 'post');
    if (params) {
      rb.body(params.body, 'application/json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<{
        'isSuccess'?: boolean;
        'message'?: string;
        'data'?: {
        'pageNumber'?: number;
        'pageSize'?: number;
        'totalRowCount'?: number;
        'items'?: Array<InheritedRoleListItem>;
        };
        }>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `childRoleList$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  childRoleList(params: {
    body: InheritedRoleXEntityRequest
  }): Observable<{
'isSuccess'?: boolean;
'message'?: string;
'data'?: {
'pageNumber'?: number;
'pageSize'?: number;
'totalRowCount'?: number;
'items'?: Array<InheritedRoleListItem>;
};
}> {

    return this.childRoleList$Response(params).pipe(
      map((r: StrictHttpResponse<{
'isSuccess'?: boolean;
'message'?: string;
'data'?: {
'pageNumber'?: number;
'pageSize'?: number;
'totalRowCount'?: number;
'items'?: Array<InheritedRoleListItem>;
};
}>) => r.body as {
'isSuccess'?: boolean;
'message'?: string;
'data'?: {
'pageNumber'?: number;
'pageSize'?: number;
'totalRowCount'?: number;
'items'?: Array<InheritedRoleListItem>;
};
})
    );
  }

  /**
   * Path part for operation userXRoleList
   */
  static readonly UserXRoleListPath = '/v1/permission/list/userXRole';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `userXRoleList()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  userXRoleList$Response(params: {
    body: EntityGetRequest
  }): Observable<StrictHttpResponse<{
'isSuccess'?: boolean;
'message'?: string;
'data'?: {
'pageNumber'?: number;
'pageSize'?: number;
'totalRowCount'?: number;
'items'?: Array<InternalUserXRoleListItem>;
};
}>> {

    const rb = new RequestBuilder(this.rootUrl, ApiService.UserXRoleListPath, 'post');
    if (params) {
      rb.body(params.body, 'application/json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<{
        'isSuccess'?: boolean;
        'message'?: string;
        'data'?: {
        'pageNumber'?: number;
        'pageSize'?: number;
        'totalRowCount'?: number;
        'items'?: Array<InternalUserXRoleListItem>;
        };
        }>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `userXRoleList$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  userXRoleList(params: {
    body: EntityGetRequest
  }): Observable<{
'isSuccess'?: boolean;
'message'?: string;
'data'?: {
'pageNumber'?: number;
'pageSize'?: number;
'totalRowCount'?: number;
'items'?: Array<InternalUserXRoleListItem>;
};
}> {

    return this.userXRoleList$Response(params).pipe(
      map((r: StrictHttpResponse<{
'isSuccess'?: boolean;
'message'?: string;
'data'?: {
'pageNumber'?: number;
'pageSize'?: number;
'totalRowCount'?: number;
'items'?: Array<InternalUserXRoleListItem>;
};
}>) => r.body as {
'isSuccess'?: boolean;
'message'?: string;
'data'?: {
'pageNumber'?: number;
'pageSize'?: number;
'totalRowCount'?: number;
'items'?: Array<InternalUserXRoleListItem>;
};
})
    );
  }

  /**
   * Path part for operation userXRoleAdd
   */
  static readonly UserXRoleAddPath = '/v1/permission/add/userXRole';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `userXRoleAdd()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  userXRoleAdd$Response(params: {
    body: InternalUserXRole
  }): Observable<StrictHttpResponse<{
'isSuccess'?: boolean;
'message'?: string;
'data'?: string;
}>> {

    const rb = new RequestBuilder(this.rootUrl, ApiService.UserXRoleAddPath, 'post');
    if (params) {
      rb.body(params.body, 'application/json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<{
        'isSuccess'?: boolean;
        'message'?: string;
        'data'?: string;
        }>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `userXRoleAdd$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  userXRoleAdd(params: {
    body: InternalUserXRole
  }): Observable<{
'isSuccess'?: boolean;
'message'?: string;
'data'?: string;
}> {

    return this.userXRoleAdd$Response(params).pipe(
      map((r: StrictHttpResponse<{
'isSuccess'?: boolean;
'message'?: string;
'data'?: string;
}>) => r.body as {
'isSuccess'?: boolean;
'message'?: string;
'data'?: string;
})
    );
  }

  /**
   * Path part for operation userXRoleDelete
   */
  static readonly UserXRoleDeletePath = '/v1/permission/delete/userXRole';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `userXRoleDelete()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  userXRoleDelete$Response(params: {
    body: DeleteRequest
  }): Observable<StrictHttpResponse<{
'isSuccess'?: boolean;
'message'?: string;
'data'?: string;
}>> {

    const rb = new RequestBuilder(this.rootUrl, ApiService.UserXRoleDeletePath, 'delete');
    if (params) {
      rb.body(params.body, 'application/json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<{
        'isSuccess'?: boolean;
        'message'?: string;
        'data'?: string;
        }>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `userXRoleDelete$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  userXRoleDelete(params: {
    body: DeleteRequest
  }): Observable<{
'isSuccess'?: boolean;
'message'?: string;
'data'?: string;
}> {

    return this.userXRoleDelete$Response(params).pipe(
      map((r: StrictHttpResponse<{
'isSuccess'?: boolean;
'message'?: string;
'data'?: string;
}>) => r.body as {
'isSuccess'?: boolean;
'message'?: string;
'data'?: string;
})
    );
  }

  /**
   * Path part for operation titleList
   */
  static readonly TitleListPath = '/v1/permission/list/title';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `titleList()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  titleList$Response(params: {
    body: CustomEntityGetRequest
  }): Observable<StrictHttpResponse<{
'isSuccess'?: boolean;
'message'?: string;
'data'?: {
'pageNumber'?: number;
'pageSize'?: number;
'totalRowCount'?: number;
'items'?: Array<InternalTitleListItem>;
};
}>> {

    const rb = new RequestBuilder(this.rootUrl, ApiService.TitleListPath, 'post');
    if (params) {
      rb.body(params.body, 'application/json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<{
        'isSuccess'?: boolean;
        'message'?: string;
        'data'?: {
        'pageNumber'?: number;
        'pageSize'?: number;
        'totalRowCount'?: number;
        'items'?: Array<InternalTitleListItem>;
        };
        }>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `titleList$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  titleList(params: {
    body: CustomEntityGetRequest
  }): Observable<{
'isSuccess'?: boolean;
'message'?: string;
'data'?: {
'pageNumber'?: number;
'pageSize'?: number;
'totalRowCount'?: number;
'items'?: Array<InternalTitleListItem>;
};
}> {

    return this.titleList$Response(params).pipe(
      map((r: StrictHttpResponse<{
'isSuccess'?: boolean;
'message'?: string;
'data'?: {
'pageNumber'?: number;
'pageSize'?: number;
'totalRowCount'?: number;
'items'?: Array<InternalTitleListItem>;
};
}>) => r.body as {
'isSuccess'?: boolean;
'message'?: string;
'data'?: {
'pageNumber'?: number;
'pageSize'?: number;
'totalRowCount'?: number;
'items'?: Array<InternalTitleListItem>;
};
})
    );
  }

  /**
   * Path part for operation titleAdd
   */
  static readonly TitleAddPath = '/v1/permission/add/title';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `titleAdd()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  titleAdd$Response(params: {
    body: InternalTitle
  }): Observable<StrictHttpResponse<{
'isSuccess'?: boolean;
'message'?: string;
'data'?: string;
}>> {

    const rb = new RequestBuilder(this.rootUrl, ApiService.TitleAddPath, 'post');
    if (params) {
      rb.body(params.body, 'application/json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<{
        'isSuccess'?: boolean;
        'message'?: string;
        'data'?: string;
        }>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `titleAdd$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  titleAdd(params: {
    body: InternalTitle
  }): Observable<{
'isSuccess'?: boolean;
'message'?: string;
'data'?: string;
}> {

    return this.titleAdd$Response(params).pipe(
      map((r: StrictHttpResponse<{
'isSuccess'?: boolean;
'message'?: string;
'data'?: string;
}>) => r.body as {
'isSuccess'?: boolean;
'message'?: string;
'data'?: string;
})
    );
  }

  /**
   * Path part for operation titleUpdate
   */
  static readonly TitleUpdatePath = '/v1/permission/update/title';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `titleUpdate()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  titleUpdate$Response(params: {
    body: InternalTitle
  }): Observable<StrictHttpResponse<{
'isSuccess'?: boolean;
'message'?: string;
'data'?: string;
}>> {

    const rb = new RequestBuilder(this.rootUrl, ApiService.TitleUpdatePath, 'put');
    if (params) {
      rb.body(params.body, 'application/json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<{
        'isSuccess'?: boolean;
        'message'?: string;
        'data'?: string;
        }>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `titleUpdate$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  titleUpdate(params: {
    body: InternalTitle
  }): Observable<{
'isSuccess'?: boolean;
'message'?: string;
'data'?: string;
}> {

    return this.titleUpdate$Response(params).pipe(
      map((r: StrictHttpResponse<{
'isSuccess'?: boolean;
'message'?: string;
'data'?: string;
}>) => r.body as {
'isSuccess'?: boolean;
'message'?: string;
'data'?: string;
})
    );
  }

  /**
   * Path part for operation titleDelete
   */
  static readonly TitleDeletePath = '/v1/permission/delete/title';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `titleDelete()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  titleDelete$Response(params: {
    body: DeleteRequest
  }): Observable<StrictHttpResponse<{
'isSuccess'?: boolean;
'message'?: string;
'data'?: string;
}>> {

    const rb = new RequestBuilder(this.rootUrl, ApiService.TitleDeletePath, 'delete');
    if (params) {
      rb.body(params.body, 'application/json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<{
        'isSuccess'?: boolean;
        'message'?: string;
        'data'?: string;
        }>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `titleDelete$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  titleDelete(params: {
    body: DeleteRequest
  }): Observable<{
'isSuccess'?: boolean;
'message'?: string;
'data'?: string;
}> {

    return this.titleDelete$Response(params).pipe(
      map((r: StrictHttpResponse<{
'isSuccess'?: boolean;
'message'?: string;
'data'?: string;
}>) => r.body as {
'isSuccess'?: boolean;
'message'?: string;
'data'?: string;
})
    );
  }

  /**
   * Path part for operation titlePermissionList
   */
  static readonly TitlePermissionListPath = '/v1/permission/list/title/permission';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `titlePermissionList()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  titlePermissionList$Response(params: {
    body: InternalTitlePermissionRequest
  }): Observable<StrictHttpResponse<{
'isSuccess'?: boolean;
'message'?: string;
'data'?: {
'pageNumber'?: number;
'pageSize'?: number;
'totalRowCount'?: number;
'items'?: Array<InternalTitlePermissionListItem>;
};
}>> {

    const rb = new RequestBuilder(this.rootUrl, ApiService.TitlePermissionListPath, 'post');
    if (params) {
      rb.body(params.body, 'application/json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<{
        'isSuccess'?: boolean;
        'message'?: string;
        'data'?: {
        'pageNumber'?: number;
        'pageSize'?: number;
        'totalRowCount'?: number;
        'items'?: Array<InternalTitlePermissionListItem>;
        };
        }>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `titlePermissionList$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  titlePermissionList(params: {
    body: InternalTitlePermissionRequest
  }): Observable<{
'isSuccess'?: boolean;
'message'?: string;
'data'?: {
'pageNumber'?: number;
'pageSize'?: number;
'totalRowCount'?: number;
'items'?: Array<InternalTitlePermissionListItem>;
};
}> {

    return this.titlePermissionList$Response(params).pipe(
      map((r: StrictHttpResponse<{
'isSuccess'?: boolean;
'message'?: string;
'data'?: {
'pageNumber'?: number;
'pageSize'?: number;
'totalRowCount'?: number;
'items'?: Array<InternalTitlePermissionListItem>;
};
}>) => r.body as {
'isSuccess'?: boolean;
'message'?: string;
'data'?: {
'pageNumber'?: number;
'pageSize'?: number;
'totalRowCount'?: number;
'items'?: Array<InternalTitlePermissionListItem>;
};
})
    );
  }

  /**
   * Path part for operation roleXTitle
   */
  static readonly RoleXTitlePath = '/v1/permission/list/roleXTitle';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `roleXTitle()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  roleXTitle$Response(params: {
    body: EntityGetRequest
  }): Observable<StrictHttpResponse<{
'isSuccess'?: boolean;
'message'?: string;
'data'?: {
'pageNumber'?: number;
'pageSize'?: number;
'totalRowCount'?: number;
'items'?: Array<InternalRoleXTitleListItem>;
};
}>> {

    const rb = new RequestBuilder(this.rootUrl, ApiService.RoleXTitlePath, 'post');
    if (params) {
      rb.body(params.body, 'application/json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<{
        'isSuccess'?: boolean;
        'message'?: string;
        'data'?: {
        'pageNumber'?: number;
        'pageSize'?: number;
        'totalRowCount'?: number;
        'items'?: Array<InternalRoleXTitleListItem>;
        };
        }>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `roleXTitle$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  roleXTitle(params: {
    body: EntityGetRequest
  }): Observable<{
'isSuccess'?: boolean;
'message'?: string;
'data'?: {
'pageNumber'?: number;
'pageSize'?: number;
'totalRowCount'?: number;
'items'?: Array<InternalRoleXTitleListItem>;
};
}> {

    return this.roleXTitle$Response(params).pipe(
      map((r: StrictHttpResponse<{
'isSuccess'?: boolean;
'message'?: string;
'data'?: {
'pageNumber'?: number;
'pageSize'?: number;
'totalRowCount'?: number;
'items'?: Array<InternalRoleXTitleListItem>;
};
}>) => r.body as {
'isSuccess'?: boolean;
'message'?: string;
'data'?: {
'pageNumber'?: number;
'pageSize'?: number;
'totalRowCount'?: number;
'items'?: Array<InternalRoleXTitleListItem>;
};
})
    );
  }

  /**
   * Path part for operation roleXTitleAdd
   */
  static readonly RoleXTitleAddPath = '/v1/permission/add/roleXTitle';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `roleXTitleAdd()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  roleXTitleAdd$Response(params: {
    body: InternalRoleXTitleSaveList
  }): Observable<StrictHttpResponse<{
'isSuccess'?: boolean;
'message'?: string;
'data'?: string;
}>> {

    const rb = new RequestBuilder(this.rootUrl, ApiService.RoleXTitleAddPath, 'post');
    if (params) {
      rb.body(params.body, 'application/json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<{
        'isSuccess'?: boolean;
        'message'?: string;
        'data'?: string;
        }>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `roleXTitleAdd$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  roleXTitleAdd(params: {
    body: InternalRoleXTitleSaveList
  }): Observable<{
'isSuccess'?: boolean;
'message'?: string;
'data'?: string;
}> {

    return this.roleXTitleAdd$Response(params).pipe(
      map((r: StrictHttpResponse<{
'isSuccess'?: boolean;
'message'?: string;
'data'?: string;
}>) => r.body as {
'isSuccess'?: boolean;
'message'?: string;
'data'?: string;
})
    );
  }

  /**
   * Path part for operation roleXTitleDelete
   */
  static readonly RoleXTitleDeletePath = '/v1/permission/delete/roleXTitle';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `roleXTitleDelete()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  roleXTitleDelete$Response(params: {
    body: DeleteRequest
  }): Observable<StrictHttpResponse<{
'isSuccess'?: boolean;
'message'?: string;
'data'?: string;
}>> {

    const rb = new RequestBuilder(this.rootUrl, ApiService.RoleXTitleDeletePath, 'delete');
    if (params) {
      rb.body(params.body, 'application/json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<{
        'isSuccess'?: boolean;
        'message'?: string;
        'data'?: string;
        }>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `roleXTitleDelete$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  roleXTitleDelete(params: {
    body: DeleteRequest
  }): Observable<{
'isSuccess'?: boolean;
'message'?: string;
'data'?: string;
}> {

    return this.roleXTitleDelete$Response(params).pipe(
      map((r: StrictHttpResponse<{
'isSuccess'?: boolean;
'message'?: string;
'data'?: string;
}>) => r.body as {
'isSuccess'?: boolean;
'message'?: string;
'data'?: string;
})
    );
  }

  /**
   * Path part for operation allRoleXTitle
   */
  static readonly AllRoleXTitlePath = '/v1/permission/list/allRoleXTitle';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `allRoleXTitle()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  allRoleXTitle$Response(params: {
    body: RolesForTitleRequest
  }): Observable<StrictHttpResponse<{
'isSuccess'?: boolean;
'message'?: string;
'data'?: {
'pageNumber'?: number;
'pageSize'?: number;
'totalRowCount'?: number;
'items'?: Array<InternalRoleXTitleListItem>;
};
}>> {

    const rb = new RequestBuilder(this.rootUrl, ApiService.AllRoleXTitlePath, 'post');
    if (params) {
      rb.body(params.body, 'application/json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<{
        'isSuccess'?: boolean;
        'message'?: string;
        'data'?: {
        'pageNumber'?: number;
        'pageSize'?: number;
        'totalRowCount'?: number;
        'items'?: Array<InternalRoleXTitleListItem>;
        };
        }>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `allRoleXTitle$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  allRoleXTitle(params: {
    body: RolesForTitleRequest
  }): Observable<{
'isSuccess'?: boolean;
'message'?: string;
'data'?: {
'pageNumber'?: number;
'pageSize'?: number;
'totalRowCount'?: number;
'items'?: Array<InternalRoleXTitleListItem>;
};
}> {

    return this.allRoleXTitle$Response(params).pipe(
      map((r: StrictHttpResponse<{
'isSuccess'?: boolean;
'message'?: string;
'data'?: {
'pageNumber'?: number;
'pageSize'?: number;
'totalRowCount'?: number;
'items'?: Array<InternalRoleXTitleListItem>;
};
}>) => r.body as {
'isSuccess'?: boolean;
'message'?: string;
'data'?: {
'pageNumber'?: number;
'pageSize'?: number;
'totalRowCount'?: number;
'items'?: Array<InternalRoleXTitleListItem>;
};
})
    );
  }

  /**
   * Path part for operation permissionXWikiModuleDelete
   */
  static readonly PermissionXWikiModuleDeletePath = '/v1/permission/delete/permissionXWikiModule';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `permissionXWikiModuleDelete()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  permissionXWikiModuleDelete$Response(params: {
    body: DeleteRequest
  }): Observable<StrictHttpResponse<{
'isSuccess'?: boolean;
'message'?: string;
'data'?: string;
}>> {

    const rb = new RequestBuilder(this.rootUrl, ApiService.PermissionXWikiModuleDeletePath, 'delete');
    if (params) {
      rb.body(params.body, 'application/json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<{
        'isSuccess'?: boolean;
        'message'?: string;
        'data'?: string;
        }>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `permissionXWikiModuleDelete$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  permissionXWikiModuleDelete(params: {
    body: DeleteRequest
  }): Observable<{
'isSuccess'?: boolean;
'message'?: string;
'data'?: string;
}> {

    return this.permissionXWikiModuleDelete$Response(params).pipe(
      map((r: StrictHttpResponse<{
'isSuccess'?: boolean;
'message'?: string;
'data'?: string;
}>) => r.body as {
'isSuccess'?: boolean;
'message'?: string;
'data'?: string;
})
    );
  }

  /**
   * Path part for operation userListBasedOnPermissions
   */
  static readonly UserListBasedOnPermissionsPath = '/v1/permission/userListBasedOnPermissions';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `userListBasedOnPermissions()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  userListBasedOnPermissions$Response(params: {
    body: InternalUserPermissionRequest
  }): Observable<StrictHttpResponse<{
'isSuccess'?: boolean;
'message'?: string;
'data'?: {
'pageNumber'?: number;
'pageSize'?: number;
'totalRowCount'?: number;
'items'?: Array<InternalUserPermissionListItem>;
};
}>> {

    const rb = new RequestBuilder(this.rootUrl, ApiService.UserListBasedOnPermissionsPath, 'post');
    if (params) {
      rb.body(params.body, 'application/json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<{
        'isSuccess'?: boolean;
        'message'?: string;
        'data'?: {
        'pageNumber'?: number;
        'pageSize'?: number;
        'totalRowCount'?: number;
        'items'?: Array<InternalUserPermissionListItem>;
        };
        }>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `userListBasedOnPermissions$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  userListBasedOnPermissions(params: {
    body: InternalUserPermissionRequest
  }): Observable<{
'isSuccess'?: boolean;
'message'?: string;
'data'?: {
'pageNumber'?: number;
'pageSize'?: number;
'totalRowCount'?: number;
'items'?: Array<InternalUserPermissionListItem>;
};
}> {

    return this.userListBasedOnPermissions$Response(params).pipe(
      map((r: StrictHttpResponse<{
'isSuccess'?: boolean;
'message'?: string;
'data'?: {
'pageNumber'?: number;
'pageSize'?: number;
'totalRowCount'?: number;
'items'?: Array<InternalUserPermissionListItem>;
};
}>) => r.body as {
'isSuccess'?: boolean;
'message'?: string;
'data'?: {
'pageNumber'?: number;
'pageSize'?: number;
'totalRowCount'?: number;
'items'?: Array<InternalUserPermissionListItem>;
};
})
    );
  }

  /**
   * Path part for operation userGroupAdd
   */
  static readonly UserGroupAddPath = '/v1/userGroup/add';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `userGroupAdd()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  userGroupAdd$Response(params: {
    body: UserGroupAddRequest
  }): Observable<StrictHttpResponse<{
'isSuccess'?: boolean;
'message'?: string;
'data'?: string;
}>> {

    const rb = new RequestBuilder(this.rootUrl, ApiService.UserGroupAddPath, 'post');
    if (params) {
      rb.body(params.body, 'application/json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<{
        'isSuccess'?: boolean;
        'message'?: string;
        'data'?: string;
        }>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `userGroupAdd$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  userGroupAdd(params: {
    body: UserGroupAddRequest
  }): Observable<{
'isSuccess'?: boolean;
'message'?: string;
'data'?: string;
}> {

    return this.userGroupAdd$Response(params).pipe(
      map((r: StrictHttpResponse<{
'isSuccess'?: boolean;
'message'?: string;
'data'?: string;
}>) => r.body as {
'isSuccess'?: boolean;
'message'?: string;
'data'?: string;
})
    );
  }

  /**
   * Path part for operation userGroupUpdate
   */
  static readonly UserGroupUpdatePath = '/v1/userGroup/update';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `userGroupUpdate()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  userGroupUpdate$Response(params: {
    body: UserGroupUpdateRequest
  }): Observable<StrictHttpResponse<{
'isSuccess'?: boolean;
'message'?: string;
'data'?: string;
}>> {

    const rb = new RequestBuilder(this.rootUrl, ApiService.UserGroupUpdatePath, 'put');
    if (params) {
      rb.body(params.body, 'application/json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<{
        'isSuccess'?: boolean;
        'message'?: string;
        'data'?: string;
        }>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `userGroupUpdate$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  userGroupUpdate(params: {
    body: UserGroupUpdateRequest
  }): Observable<{
'isSuccess'?: boolean;
'message'?: string;
'data'?: string;
}> {

    return this.userGroupUpdate$Response(params).pipe(
      map((r: StrictHttpResponse<{
'isSuccess'?: boolean;
'message'?: string;
'data'?: string;
}>) => r.body as {
'isSuccess'?: boolean;
'message'?: string;
'data'?: string;
})
    );
  }

  /**
   * Path part for operation userGroupList
   */
  static readonly UserGroupListPath = '/v1/userGroup/list';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `userGroupList()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  userGroupList$Response(params: {
    body: EntityGetRequest
  }): Observable<StrictHttpResponse<{
'isSuccess'?: boolean;
'message'?: string;
'data'?: {
'pageNumber'?: number;
'pageSize'?: number;
'totalRowCount'?: number;
'items'?: Array<UserGroupItem>;
};
}>> {

    const rb = new RequestBuilder(this.rootUrl, ApiService.UserGroupListPath, 'post');
    if (params) {
      rb.body(params.body, 'application/json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<{
        'isSuccess'?: boolean;
        'message'?: string;
        'data'?: {
        'pageNumber'?: number;
        'pageSize'?: number;
        'totalRowCount'?: number;
        'items'?: Array<UserGroupItem>;
        };
        }>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `userGroupList$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  userGroupList(params: {
    body: EntityGetRequest
  }): Observable<{
'isSuccess'?: boolean;
'message'?: string;
'data'?: {
'pageNumber'?: number;
'pageSize'?: number;
'totalRowCount'?: number;
'items'?: Array<UserGroupItem>;
};
}> {

    return this.userGroupList$Response(params).pipe(
      map((r: StrictHttpResponse<{
'isSuccess'?: boolean;
'message'?: string;
'data'?: {
'pageNumber'?: number;
'pageSize'?: number;
'totalRowCount'?: number;
'items'?: Array<UserGroupItem>;
};
}>) => r.body as {
'isSuccess'?: boolean;
'message'?: string;
'data'?: {
'pageNumber'?: number;
'pageSize'?: number;
'totalRowCount'?: number;
'items'?: Array<UserGroupItem>;
};
})
    );
  }

  /**
   * Path part for operation userPeerList
   */
  static readonly UserPeerListPath = '/v1/userGroup/list/userPeer';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `userPeerList()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  userPeerList$Response(params: {
    body: UserPeerGroupRequest
  }): Observable<StrictHttpResponse<{
'isSuccess'?: boolean;
'message'?: string;
'data'?: {
'pageNumber'?: number;
'pageSize'?: number;
'totalRowCount'?: number;
'items'?: Array<InternalUserItem>;
};
}>> {

    const rb = new RequestBuilder(this.rootUrl, ApiService.UserPeerListPath, 'post');
    if (params) {
      rb.body(params.body, 'application/json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<{
        'isSuccess'?: boolean;
        'message'?: string;
        'data'?: {
        'pageNumber'?: number;
        'pageSize'?: number;
        'totalRowCount'?: number;
        'items'?: Array<InternalUserItem>;
        };
        }>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `userPeerList$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  userPeerList(params: {
    body: UserPeerGroupRequest
  }): Observable<{
'isSuccess'?: boolean;
'message'?: string;
'data'?: {
'pageNumber'?: number;
'pageSize'?: number;
'totalRowCount'?: number;
'items'?: Array<InternalUserItem>;
};
}> {

    return this.userPeerList$Response(params).pipe(
      map((r: StrictHttpResponse<{
'isSuccess'?: boolean;
'message'?: string;
'data'?: {
'pageNumber'?: number;
'pageSize'?: number;
'totalRowCount'?: number;
'items'?: Array<InternalUserItem>;
};
}>) => r.body as {
'isSuccess'?: boolean;
'message'?: string;
'data'?: {
'pageNumber'?: number;
'pageSize'?: number;
'totalRowCount'?: number;
'items'?: Array<InternalUserItem>;
};
})
    );
  }

  /**
   * Path part for operation userGroupDelete
   */
  static readonly UserGroupDeletePath = '/v1/userGroup/delete/userGroup';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `userGroupDelete()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  userGroupDelete$Response(params: {
    body: DeleteRequest
  }): Observable<StrictHttpResponse<{
'isSuccess'?: boolean;
'message'?: string;
'data'?: string;
}>> {

    const rb = new RequestBuilder(this.rootUrl, ApiService.UserGroupDeletePath, 'delete');
    if (params) {
      rb.body(params.body, 'application/json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<{
        'isSuccess'?: boolean;
        'message'?: string;
        'data'?: string;
        }>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `userGroupDelete$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  userGroupDelete(params: {
    body: DeleteRequest
  }): Observable<{
'isSuccess'?: boolean;
'message'?: string;
'data'?: string;
}> {

    return this.userGroupDelete$Response(params).pipe(
      map((r: StrictHttpResponse<{
'isSuccess'?: boolean;
'message'?: string;
'data'?: string;
}>) => r.body as {
'isSuccess'?: boolean;
'message'?: string;
'data'?: string;
})
    );
  }

  /**
   * Path part for operation addUserToUserGroup
   */
  static readonly AddUserToUserGroupPath = '/v1/userGroup/add/user/userGroup';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `addUserToUserGroup()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  addUserToUserGroup$Response(params: {
    body: UserGroupUpdateRequest
  }): Observable<StrictHttpResponse<{
'isSuccess'?: boolean;
'message'?: string;
'data'?: string;
}>> {

    const rb = new RequestBuilder(this.rootUrl, ApiService.AddUserToUserGroupPath, 'put');
    if (params) {
      rb.body(params.body, 'application/json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<{
        'isSuccess'?: boolean;
        'message'?: string;
        'data'?: string;
        }>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `addUserToUserGroup$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  addUserToUserGroup(params: {
    body: UserGroupUpdateRequest
  }): Observable<{
'isSuccess'?: boolean;
'message'?: string;
'data'?: string;
}> {

    return this.addUserToUserGroup$Response(params).pipe(
      map((r: StrictHttpResponse<{
'isSuccess'?: boolean;
'message'?: string;
'data'?: string;
}>) => r.body as {
'isSuccess'?: boolean;
'message'?: string;
'data'?: string;
})
    );
  }

  /**
   * Path part for operation removeFromUserGroup
   */
  static readonly RemoveFromUserGroupPath = '/v1/userGroup/delete/user/userGroup';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `removeFromUserGroup()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  removeFromUserGroup$Response(params: {
    body: UserGroupUpdateRequest
  }): Observable<StrictHttpResponse<{
'isSuccess'?: boolean;
'message'?: string;
'data'?: string;
}>> {

    const rb = new RequestBuilder(this.rootUrl, ApiService.RemoveFromUserGroupPath, 'put');
    if (params) {
      rb.body(params.body, 'application/json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<{
        'isSuccess'?: boolean;
        'message'?: string;
        'data'?: string;
        }>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `removeFromUserGroup$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  removeFromUserGroup(params: {
    body: UserGroupUpdateRequest
  }): Observable<{
'isSuccess'?: boolean;
'message'?: string;
'data'?: string;
}> {

    return this.removeFromUserGroup$Response(params).pipe(
      map((r: StrictHttpResponse<{
'isSuccess'?: boolean;
'message'?: string;
'data'?: string;
}>) => r.body as {
'isSuccess'?: boolean;
'message'?: string;
'data'?: string;
})
    );
  }

  /**
   * Path part for operation fetchUserGroup
   */
  static readonly FetchUserGroupPath = '/v1/userGroup/fetch/userGroup';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `fetchUserGroup()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  fetchUserGroup$Response(params: {
    body: UserGroupFetchRequest
  }): Observable<StrictHttpResponse<{
'isSuccess'?: boolean;
'message'?: string;
'data'?: {
'pageNumber'?: number;
'pageSize'?: number;
'totalRowCount'?: number;
'items'?: Array<UserGroupItem>;
};
}>> {

    const rb = new RequestBuilder(this.rootUrl, ApiService.FetchUserGroupPath, 'post');
    if (params) {
      rb.body(params.body, 'application/json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<{
        'isSuccess'?: boolean;
        'message'?: string;
        'data'?: {
        'pageNumber'?: number;
        'pageSize'?: number;
        'totalRowCount'?: number;
        'items'?: Array<UserGroupItem>;
        };
        }>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `fetchUserGroup$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  fetchUserGroup(params: {
    body: UserGroupFetchRequest
  }): Observable<{
'isSuccess'?: boolean;
'message'?: string;
'data'?: {
'pageNumber'?: number;
'pageSize'?: number;
'totalRowCount'?: number;
'items'?: Array<UserGroupItem>;
};
}> {

    return this.fetchUserGroup$Response(params).pipe(
      map((r: StrictHttpResponse<{
'isSuccess'?: boolean;
'message'?: string;
'data'?: {
'pageNumber'?: number;
'pageSize'?: number;
'totalRowCount'?: number;
'items'?: Array<UserGroupItem>;
};
}>) => r.body as {
'isSuccess'?: boolean;
'message'?: string;
'data'?: {
'pageNumber'?: number;
'pageSize'?: number;
'totalRowCount'?: number;
'items'?: Array<UserGroupItem>;
};
})
    );
  }

  /**
   * Path part for operation fetchUserPerUserGroup
   */
  static readonly FetchUserPerUserGroupPath = '/v1/userGroup/fetch/userPerUserGroup';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `fetchUserPerUserGroup()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  fetchUserPerUserGroup$Response(params: {
    body: EntityGetRequest
  }): Observable<StrictHttpResponse<{
'isSuccess'?: boolean;
'message'?: string;
'data'?: {
'pageNumber'?: number;
'pageSize'?: number;
'totalRowCount'?: number;
'items'?: Array<UserPerGroupGridModel>;
};
}>> {

    const rb = new RequestBuilder(this.rootUrl, ApiService.FetchUserPerUserGroupPath, 'post');
    if (params) {
      rb.body(params.body, 'application/json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<{
        'isSuccess'?: boolean;
        'message'?: string;
        'data'?: {
        'pageNumber'?: number;
        'pageSize'?: number;
        'totalRowCount'?: number;
        'items'?: Array<UserPerGroupGridModel>;
        };
        }>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `fetchUserPerUserGroup$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  fetchUserPerUserGroup(params: {
    body: EntityGetRequest
  }): Observable<{
'isSuccess'?: boolean;
'message'?: string;
'data'?: {
'pageNumber'?: number;
'pageSize'?: number;
'totalRowCount'?: number;
'items'?: Array<UserPerGroupGridModel>;
};
}> {

    return this.fetchUserPerUserGroup$Response(params).pipe(
      map((r: StrictHttpResponse<{
'isSuccess'?: boolean;
'message'?: string;
'data'?: {
'pageNumber'?: number;
'pageSize'?: number;
'totalRowCount'?: number;
'items'?: Array<UserPerGroupGridModel>;
};
}>) => r.body as {
'isSuccess'?: boolean;
'message'?: string;
'data'?: {
'pageNumber'?: number;
'pageSize'?: number;
'totalRowCount'?: number;
'items'?: Array<UserPerGroupGridModel>;
};
})
    );
  }

  /**
   * Path part for operation titlePerUserGroup
   */
  static readonly TitlePerUserGroupPath = '/v1/userGroup/list/titlePerUserGroup';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `titlePerUserGroup()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  titlePerUserGroup$Response(params: {
    body: TitlePerUserGroupRequest
  }): Observable<StrictHttpResponse<{
'isSuccess'?: boolean;
'message'?: string;
'data'?: {
'pageNumber'?: number;
'pageSize'?: number;
'totalRowCount'?: number;
'items'?: Array<TitlePerUserGroup>;
};
}>> {

    const rb = new RequestBuilder(this.rootUrl, ApiService.TitlePerUserGroupPath, 'post');
    if (params) {
      rb.body(params.body, 'application/json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<{
        'isSuccess'?: boolean;
        'message'?: string;
        'data'?: {
        'pageNumber'?: number;
        'pageSize'?: number;
        'totalRowCount'?: number;
        'items'?: Array<TitlePerUserGroup>;
        };
        }>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `titlePerUserGroup$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  titlePerUserGroup(params: {
    body: TitlePerUserGroupRequest
  }): Observable<{
'isSuccess'?: boolean;
'message'?: string;
'data'?: {
'pageNumber'?: number;
'pageSize'?: number;
'totalRowCount'?: number;
'items'?: Array<TitlePerUserGroup>;
};
}> {

    return this.titlePerUserGroup$Response(params).pipe(
      map((r: StrictHttpResponse<{
'isSuccess'?: boolean;
'message'?: string;
'data'?: {
'pageNumber'?: number;
'pageSize'?: number;
'totalRowCount'?: number;
'items'?: Array<TitlePerUserGroup>;
};
}>) => r.body as {
'isSuccess'?: boolean;
'message'?: string;
'data'?: {
'pageNumber'?: number;
'pageSize'?: number;
'totalRowCount'?: number;
'items'?: Array<TitlePerUserGroup>;
};
})
    );
  }

  /**
   * Path part for operation addOrUpdateTitleForUserGroup
   */
  static readonly AddOrUpdateTitleForUserGroupPath = '/v1/userGroup/addOrUpdate/titlePerUserGroup';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `addOrUpdateTitleForUserGroup()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  addOrUpdateTitleForUserGroup$Response(params: {
    body: TitlePerUserGroupModel
  }): Observable<StrictHttpResponse<{
'isSuccess'?: boolean;
'message'?: string;
'data'?: string;
}>> {

    const rb = new RequestBuilder(this.rootUrl, ApiService.AddOrUpdateTitleForUserGroupPath, 'put');
    if (params) {
      rb.body(params.body, 'application/json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<{
        'isSuccess'?: boolean;
        'message'?: string;
        'data'?: string;
        }>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `addOrUpdateTitleForUserGroup$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  addOrUpdateTitleForUserGroup(params: {
    body: TitlePerUserGroupModel
  }): Observable<{
'isSuccess'?: boolean;
'message'?: string;
'data'?: string;
}> {

    return this.addOrUpdateTitleForUserGroup$Response(params).pipe(
      map((r: StrictHttpResponse<{
'isSuccess'?: boolean;
'message'?: string;
'data'?: string;
}>) => r.body as {
'isSuccess'?: boolean;
'message'?: string;
'data'?: string;
})
    );
  }

  /**
   * Path part for operation updateUserGroupX
   */
  static readonly UpdateUserGroupXPath = '/v1/userGroup/updateUserGroupX';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `updateUserGroupX()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  updateUserGroupX$Response(params: {
    body: UserGroupXAddRequest
  }): Observable<StrictHttpResponse<{
'isSuccess'?: boolean;
'message'?: string;
'data'?: string;
}>> {

    const rb = new RequestBuilder(this.rootUrl, ApiService.UpdateUserGroupXPath, 'post');
    if (params) {
      rb.body(params.body, 'application/json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<{
        'isSuccess'?: boolean;
        'message'?: string;
        'data'?: string;
        }>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `updateUserGroupX$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  updateUserGroupX(params: {
    body: UserGroupXAddRequest
  }): Observable<{
'isSuccess'?: boolean;
'message'?: string;
'data'?: string;
}> {

    return this.updateUserGroupX$Response(params).pipe(
      map((r: StrictHttpResponse<{
'isSuccess'?: boolean;
'message'?: string;
'data'?: string;
}>) => r.body as {
'isSuccess'?: boolean;
'message'?: string;
'data'?: string;
})
    );
  }

  /**
   * Path part for operation statusList
   */
  static readonly StatusListPath = '/v1/referral/list/status';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `statusList()` instead.
   *
   * This method doesn't expect any request body.
   */
  statusList$Response(params?: {
  }): Observable<StrictHttpResponse<{
'isSuccess'?: boolean;
'message'?: string;
'data'?: {
'pageNumber'?: number;
'pageSize'?: number;
'totalRowCount'?: number;
'items'?: Array<ReferralStatusItem>;
};
}>> {

    const rb = new RequestBuilder(this.rootUrl, ApiService.StatusListPath, 'post');
    if (params) {
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<{
        'isSuccess'?: boolean;
        'message'?: string;
        'data'?: {
        'pageNumber'?: number;
        'pageSize'?: number;
        'totalRowCount'?: number;
        'items'?: Array<ReferralStatusItem>;
        };
        }>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `statusList$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  statusList(params?: {
  }): Observable<{
'isSuccess'?: boolean;
'message'?: string;
'data'?: {
'pageNumber'?: number;
'pageSize'?: number;
'totalRowCount'?: number;
'items'?: Array<ReferralStatusItem>;
};
}> {

    return this.statusList$Response(params).pipe(
      map((r: StrictHttpResponse<{
'isSuccess'?: boolean;
'message'?: string;
'data'?: {
'pageNumber'?: number;
'pageSize'?: number;
'totalRowCount'?: number;
'items'?: Array<ReferralStatusItem>;
};
}>) => r.body as {
'isSuccess'?: boolean;
'message'?: string;
'data'?: {
'pageNumber'?: number;
'pageSize'?: number;
'totalRowCount'?: number;
'items'?: Array<ReferralStatusItem>;
};
})
    );
  }

  /**
   * Path part for operation referralContactTypeList
   */
  static readonly ReferralContactTypeListPath = '/v1/referral/list/referralContactType';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `referralContactTypeList()` instead.
   *
   * This method doesn't expect any request body.
   */
  referralContactTypeList$Response(params?: {
  }): Observable<StrictHttpResponse<{
'isSuccess'?: boolean;
'message'?: string;
'data'?: {
'pageNumber'?: number;
'pageSize'?: number;
'totalRowCount'?: number;
'items'?: Array<ReferralContactTypeItem>;
};
}>> {

    const rb = new RequestBuilder(this.rootUrl, ApiService.ReferralContactTypeListPath, 'post');
    if (params) {
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<{
        'isSuccess'?: boolean;
        'message'?: string;
        'data'?: {
        'pageNumber'?: number;
        'pageSize'?: number;
        'totalRowCount'?: number;
        'items'?: Array<ReferralContactTypeItem>;
        };
        }>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `referralContactTypeList$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  referralContactTypeList(params?: {
  }): Observable<{
'isSuccess'?: boolean;
'message'?: string;
'data'?: {
'pageNumber'?: number;
'pageSize'?: number;
'totalRowCount'?: number;
'items'?: Array<ReferralContactTypeItem>;
};
}> {

    return this.referralContactTypeList$Response(params).pipe(
      map((r: StrictHttpResponse<{
'isSuccess'?: boolean;
'message'?: string;
'data'?: {
'pageNumber'?: number;
'pageSize'?: number;
'totalRowCount'?: number;
'items'?: Array<ReferralContactTypeItem>;
};
}>) => r.body as {
'isSuccess'?: boolean;
'message'?: string;
'data'?: {
'pageNumber'?: number;
'pageSize'?: number;
'totalRowCount'?: number;
'items'?: Array<ReferralContactTypeItem>;
};
})
    );
  }

  /**
   * Path part for operation insuranceProductTypeList
   */
  static readonly InsuranceProductTypeListPath = '/v1/referral/list/insuranceProductType';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `insuranceProductTypeList()` instead.
   *
   * This method doesn't expect any request body.
   */
  insuranceProductTypeList$Response(params?: {
  }): Observable<StrictHttpResponse<{
'isSuccess'?: boolean;
'message'?: string;
'data'?: {
'pageNumber'?: number;
'pageSize'?: number;
'totalRowCount'?: number;
'items'?: Array<ReferralInsuranceProductTypeItem>;
};
}>> {

    const rb = new RequestBuilder(this.rootUrl, ApiService.InsuranceProductTypeListPath, 'post');
    if (params) {
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<{
        'isSuccess'?: boolean;
        'message'?: string;
        'data'?: {
        'pageNumber'?: number;
        'pageSize'?: number;
        'totalRowCount'?: number;
        'items'?: Array<ReferralInsuranceProductTypeItem>;
        };
        }>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `insuranceProductTypeList$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  insuranceProductTypeList(params?: {
  }): Observable<{
'isSuccess'?: boolean;
'message'?: string;
'data'?: {
'pageNumber'?: number;
'pageSize'?: number;
'totalRowCount'?: number;
'items'?: Array<ReferralInsuranceProductTypeItem>;
};
}> {

    return this.insuranceProductTypeList$Response(params).pipe(
      map((r: StrictHttpResponse<{
'isSuccess'?: boolean;
'message'?: string;
'data'?: {
'pageNumber'?: number;
'pageSize'?: number;
'totalRowCount'?: number;
'items'?: Array<ReferralInsuranceProductTypeItem>;
};
}>) => r.body as {
'isSuccess'?: boolean;
'message'?: string;
'data'?: {
'pageNumber'?: number;
'pageSize'?: number;
'totalRowCount'?: number;
'items'?: Array<ReferralInsuranceProductTypeItem>;
};
})
    );
  }

  /**
   * Path part for operation treasuryProductTypeList
   */
  static readonly TreasuryProductTypeListPath = '/v1/referral/list/treasuryProductType';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `treasuryProductTypeList()` instead.
   *
   * This method doesn't expect any request body.
   */
  treasuryProductTypeList$Response(params?: {
  }): Observable<StrictHttpResponse<{
'isSuccess'?: boolean;
'message'?: string;
'data'?: {
'pageNumber'?: number;
'pageSize'?: number;
'totalRowCount'?: number;
'items'?: Array<ReferralTreasuryProductTypeItem>;
};
}>> {

    const rb = new RequestBuilder(this.rootUrl, ApiService.TreasuryProductTypeListPath, 'post');
    if (params) {
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<{
        'isSuccess'?: boolean;
        'message'?: string;
        'data'?: {
        'pageNumber'?: number;
        'pageSize'?: number;
        'totalRowCount'?: number;
        'items'?: Array<ReferralTreasuryProductTypeItem>;
        };
        }>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `treasuryProductTypeList$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  treasuryProductTypeList(params?: {
  }): Observable<{
'isSuccess'?: boolean;
'message'?: string;
'data'?: {
'pageNumber'?: number;
'pageSize'?: number;
'totalRowCount'?: number;
'items'?: Array<ReferralTreasuryProductTypeItem>;
};
}> {

    return this.treasuryProductTypeList$Response(params).pipe(
      map((r: StrictHttpResponse<{
'isSuccess'?: boolean;
'message'?: string;
'data'?: {
'pageNumber'?: number;
'pageSize'?: number;
'totalRowCount'?: number;
'items'?: Array<ReferralTreasuryProductTypeItem>;
};
}>) => r.body as {
'isSuccess'?: boolean;
'message'?: string;
'data'?: {
'pageNumber'?: number;
'pageSize'?: number;
'totalRowCount'?: number;
'items'?: Array<ReferralTreasuryProductTypeItem>;
};
})
    );
  }

  /**
   * Path part for operation fxList
   */
  static readonly FxListPath = '/v1/referral/list/fx';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `fxList()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  fxList$Response(params: {
    body: FetchReferralRequest
  }): Observable<StrictHttpResponse<{
'isSuccess'?: boolean;
'message'?: string;
'data'?: {
'pageNumber'?: number;
'pageSize'?: number;
'totalRowCount'?: number;
'items'?: Array<FxReferralItem>;
};
}>> {

    const rb = new RequestBuilder(this.rootUrl, ApiService.FxListPath, 'post');
    if (params) {
      rb.body(params.body, 'application/json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<{
        'isSuccess'?: boolean;
        'message'?: string;
        'data'?: {
        'pageNumber'?: number;
        'pageSize'?: number;
        'totalRowCount'?: number;
        'items'?: Array<FxReferralItem>;
        };
        }>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `fxList$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  fxList(params: {
    body: FetchReferralRequest
  }): Observable<{
'isSuccess'?: boolean;
'message'?: string;
'data'?: {
'pageNumber'?: number;
'pageSize'?: number;
'totalRowCount'?: number;
'items'?: Array<FxReferralItem>;
};
}> {

    return this.fxList$Response(params).pipe(
      map((r: StrictHttpResponse<{
'isSuccess'?: boolean;
'message'?: string;
'data'?: {
'pageNumber'?: number;
'pageSize'?: number;
'totalRowCount'?: number;
'items'?: Array<FxReferralItem>;
};
}>) => r.body as {
'isSuccess'?: boolean;
'message'?: string;
'data'?: {
'pageNumber'?: number;
'pageSize'?: number;
'totalRowCount'?: number;
'items'?: Array<FxReferralItem>;
};
})
    );
  }

  /**
   * Path part for operation fxAdd
   */
  static readonly FxAddPath = '/v1/referral/add/fx';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `fxAdd()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  fxAdd$Response(params: {
    body: FxReferralRequest
  }): Observable<StrictHttpResponse<{
'isSuccess'?: boolean;
'message'?: string;
'data'?: string;
}>> {

    const rb = new RequestBuilder(this.rootUrl, ApiService.FxAddPath, 'post');
    if (params) {
      rb.body(params.body, 'application/json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<{
        'isSuccess'?: boolean;
        'message'?: string;
        'data'?: string;
        }>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `fxAdd$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  fxAdd(params: {
    body: FxReferralRequest
  }): Observable<{
'isSuccess'?: boolean;
'message'?: string;
'data'?: string;
}> {

    return this.fxAdd$Response(params).pipe(
      map((r: StrictHttpResponse<{
'isSuccess'?: boolean;
'message'?: string;
'data'?: string;
}>) => r.body as {
'isSuccess'?: boolean;
'message'?: string;
'data'?: string;
})
    );
  }

  /**
   * Path part for operation fxMaterialList
   */
  static readonly FxMaterialListPath = '/v1/referral/list/fxMaterial';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `fxMaterialList()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  fxMaterialList$Response(params: {
    body: EntityGetRequest
  }): Observable<StrictHttpResponse<{
'isSuccess'?: boolean;
'message'?: string;
'data'?: {
'pageNumber'?: number;
'pageSize'?: number;
'totalRowCount'?: number;
'items'?: Array<FxReferralAttachmentList>;
};
}>> {

    const rb = new RequestBuilder(this.rootUrl, ApiService.FxMaterialListPath, 'post');
    if (params) {
      rb.body(params.body, 'application/json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<{
        'isSuccess'?: boolean;
        'message'?: string;
        'data'?: {
        'pageNumber'?: number;
        'pageSize'?: number;
        'totalRowCount'?: number;
        'items'?: Array<FxReferralAttachmentList>;
        };
        }>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `fxMaterialList$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  fxMaterialList(params: {
    body: EntityGetRequest
  }): Observable<{
'isSuccess'?: boolean;
'message'?: string;
'data'?: {
'pageNumber'?: number;
'pageSize'?: number;
'totalRowCount'?: number;
'items'?: Array<FxReferralAttachmentList>;
};
}> {

    return this.fxMaterialList$Response(params).pipe(
      map((r: StrictHttpResponse<{
'isSuccess'?: boolean;
'message'?: string;
'data'?: {
'pageNumber'?: number;
'pageSize'?: number;
'totalRowCount'?: number;
'items'?: Array<FxReferralAttachmentList>;
};
}>) => r.body as {
'isSuccess'?: boolean;
'message'?: string;
'data'?: {
'pageNumber'?: number;
'pageSize'?: number;
'totalRowCount'?: number;
'items'?: Array<FxReferralAttachmentList>;
};
})
    );
  }

  /**
   * Path part for operation fxMaterialAdd
   */
  static readonly FxMaterialAddPath = '/v1/referral/add/fxMaterial';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `fxMaterialAdd()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  fxMaterialAdd$Response(params: {
    body: FxReferralMaterialRequest
  }): Observable<StrictHttpResponse<{
'isSuccess'?: boolean;
'message'?: string;
'data'?: string;
}>> {

    const rb = new RequestBuilder(this.rootUrl, ApiService.FxMaterialAddPath, 'post');
    if (params) {
      rb.body(params.body, 'application/json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<{
        'isSuccess'?: boolean;
        'message'?: string;
        'data'?: string;
        }>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `fxMaterialAdd$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  fxMaterialAdd(params: {
    body: FxReferralMaterialRequest
  }): Observable<{
'isSuccess'?: boolean;
'message'?: string;
'data'?: string;
}> {

    return this.fxMaterialAdd$Response(params).pipe(
      map((r: StrictHttpResponse<{
'isSuccess'?: boolean;
'message'?: string;
'data'?: string;
}>) => r.body as {
'isSuccess'?: boolean;
'message'?: string;
'data'?: string;
})
    );
  }

  /**
   * Path part for operation fxMaterialDelete
   */
  static readonly FxMaterialDeletePath = '/v1/referral/delete/fxMaterial';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `fxMaterialDelete()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  fxMaterialDelete$Response(params: {
    body: FxReferralMaterialDeleteRequest
  }): Observable<StrictHttpResponse<{
'isSuccess'?: boolean;
'message'?: string;
'data'?: string;
}>> {

    const rb = new RequestBuilder(this.rootUrl, ApiService.FxMaterialDeletePath, 'post');
    if (params) {
      rb.body(params.body, 'application/json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<{
        'isSuccess'?: boolean;
        'message'?: string;
        'data'?: string;
        }>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `fxMaterialDelete$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  fxMaterialDelete(params: {
    body: FxReferralMaterialDeleteRequest
  }): Observable<{
'isSuccess'?: boolean;
'message'?: string;
'data'?: string;
}> {

    return this.fxMaterialDelete$Response(params).pipe(
      map((r: StrictHttpResponse<{
'isSuccess'?: boolean;
'message'?: string;
'data'?: string;
}>) => r.body as {
'isSuccess'?: boolean;
'message'?: string;
'data'?: string;
})
    );
  }

  /**
   * Path part for operation insuranceList
   */
  static readonly InsuranceListPath = '/v1/referral/list/insurance';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `insuranceList()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  insuranceList$Response(params: {
    body: FetchReferralRequest
  }): Observable<StrictHttpResponse<{
'isSuccess'?: boolean;
'message'?: string;
'data'?: {
'pageNumber'?: number;
'pageSize'?: number;
'totalRowCount'?: number;
'items'?: Array<InsuranceReferralItem>;
};
}>> {

    const rb = new RequestBuilder(this.rootUrl, ApiService.InsuranceListPath, 'post');
    if (params) {
      rb.body(params.body, 'application/json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<{
        'isSuccess'?: boolean;
        'message'?: string;
        'data'?: {
        'pageNumber'?: number;
        'pageSize'?: number;
        'totalRowCount'?: number;
        'items'?: Array<InsuranceReferralItem>;
        };
        }>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `insuranceList$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  insuranceList(params: {
    body: FetchReferralRequest
  }): Observable<{
'isSuccess'?: boolean;
'message'?: string;
'data'?: {
'pageNumber'?: number;
'pageSize'?: number;
'totalRowCount'?: number;
'items'?: Array<InsuranceReferralItem>;
};
}> {

    return this.insuranceList$Response(params).pipe(
      map((r: StrictHttpResponse<{
'isSuccess'?: boolean;
'message'?: string;
'data'?: {
'pageNumber'?: number;
'pageSize'?: number;
'totalRowCount'?: number;
'items'?: Array<InsuranceReferralItem>;
};
}>) => r.body as {
'isSuccess'?: boolean;
'message'?: string;
'data'?: {
'pageNumber'?: number;
'pageSize'?: number;
'totalRowCount'?: number;
'items'?: Array<InsuranceReferralItem>;
};
})
    );
  }

  /**
   * Path part for operation insuranceAdd
   */
  static readonly InsuranceAddPath = '/v1/referral/add/insurance';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `insuranceAdd()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  insuranceAdd$Response(params: {
    body: InsuranceReferralRequest
  }): Observable<StrictHttpResponse<{
'isSuccess'?: boolean;
'message'?: string;
'data'?: string;
}>> {

    const rb = new RequestBuilder(this.rootUrl, ApiService.InsuranceAddPath, 'post');
    if (params) {
      rb.body(params.body, 'application/json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<{
        'isSuccess'?: boolean;
        'message'?: string;
        'data'?: string;
        }>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `insuranceAdd$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  insuranceAdd(params: {
    body: InsuranceReferralRequest
  }): Observable<{
'isSuccess'?: boolean;
'message'?: string;
'data'?: string;
}> {

    return this.insuranceAdd$Response(params).pipe(
      map((r: StrictHttpResponse<{
'isSuccess'?: boolean;
'message'?: string;
'data'?: string;
}>) => r.body as {
'isSuccess'?: boolean;
'message'?: string;
'data'?: string;
})
    );
  }

  /**
   * Path part for operation insuranceMaterialAdd
   */
  static readonly InsuranceMaterialAddPath = '/v1/referral/add/insuranceMaterial';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `insuranceMaterialAdd()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  insuranceMaterialAdd$Response(params: {
    body: InsuranceReferralMaterialRequest
  }): Observable<StrictHttpResponse<{
'isSuccess'?: boolean;
'message'?: string;
'data'?: string;
}>> {

    const rb = new RequestBuilder(this.rootUrl, ApiService.InsuranceMaterialAddPath, 'post');
    if (params) {
      rb.body(params.body, 'application/json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<{
        'isSuccess'?: boolean;
        'message'?: string;
        'data'?: string;
        }>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `insuranceMaterialAdd$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  insuranceMaterialAdd(params: {
    body: InsuranceReferralMaterialRequest
  }): Observable<{
'isSuccess'?: boolean;
'message'?: string;
'data'?: string;
}> {

    return this.insuranceMaterialAdd$Response(params).pipe(
      map((r: StrictHttpResponse<{
'isSuccess'?: boolean;
'message'?: string;
'data'?: string;
}>) => r.body as {
'isSuccess'?: boolean;
'message'?: string;
'data'?: string;
})
    );
  }

  /**
   * Path part for operation insuranceMaterialList
   */
  static readonly InsuranceMaterialListPath = '/v1/referral/list/insuranceMaterial';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `insuranceMaterialList()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  insuranceMaterialList$Response(params: {
    body: EntityGetRequest
  }): Observable<StrictHttpResponse<{
'isSuccess'?: boolean;
'message'?: string;
'data'?: {
'pageNumber'?: number;
'pageSize'?: number;
'totalRowCount'?: number;
'items'?: Array<InsuranceReferralAttachmentList>;
};
}>> {

    const rb = new RequestBuilder(this.rootUrl, ApiService.InsuranceMaterialListPath, 'post');
    if (params) {
      rb.body(params.body, 'application/json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<{
        'isSuccess'?: boolean;
        'message'?: string;
        'data'?: {
        'pageNumber'?: number;
        'pageSize'?: number;
        'totalRowCount'?: number;
        'items'?: Array<InsuranceReferralAttachmentList>;
        };
        }>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `insuranceMaterialList$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  insuranceMaterialList(params: {
    body: EntityGetRequest
  }): Observable<{
'isSuccess'?: boolean;
'message'?: string;
'data'?: {
'pageNumber'?: number;
'pageSize'?: number;
'totalRowCount'?: number;
'items'?: Array<InsuranceReferralAttachmentList>;
};
}> {

    return this.insuranceMaterialList$Response(params).pipe(
      map((r: StrictHttpResponse<{
'isSuccess'?: boolean;
'message'?: string;
'data'?: {
'pageNumber'?: number;
'pageSize'?: number;
'totalRowCount'?: number;
'items'?: Array<InsuranceReferralAttachmentList>;
};
}>) => r.body as {
'isSuccess'?: boolean;
'message'?: string;
'data'?: {
'pageNumber'?: number;
'pageSize'?: number;
'totalRowCount'?: number;
'items'?: Array<InsuranceReferralAttachmentList>;
};
})
    );
  }

  /**
   * Path part for operation insuranceMaterialDelete
   */
  static readonly InsuranceMaterialDeletePath = '/v1/referral/delete/insuranceMaterial';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `insuranceMaterialDelete()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  insuranceMaterialDelete$Response(params: {
    body: InsuranceReferralMaterialDeleteRequest
  }): Observable<StrictHttpResponse<{
'isSuccess'?: boolean;
'message'?: string;
'data'?: string;
}>> {

    const rb = new RequestBuilder(this.rootUrl, ApiService.InsuranceMaterialDeletePath, 'post');
    if (params) {
      rb.body(params.body, 'application/json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<{
        'isSuccess'?: boolean;
        'message'?: string;
        'data'?: string;
        }>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `insuranceMaterialDelete$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  insuranceMaterialDelete(params: {
    body: InsuranceReferralMaterialDeleteRequest
  }): Observable<{
'isSuccess'?: boolean;
'message'?: string;
'data'?: string;
}> {

    return this.insuranceMaterialDelete$Response(params).pipe(
      map((r: StrictHttpResponse<{
'isSuccess'?: boolean;
'message'?: string;
'data'?: string;
}>) => r.body as {
'isSuccess'?: boolean;
'message'?: string;
'data'?: string;
})
    );
  }

  /**
   * Path part for operation merchantList
   */
  static readonly MerchantListPath = '/v1/referral/list/merchant';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `merchantList()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  merchantList$Response(params: {
    body: FetchReferralRequest
  }): Observable<StrictHttpResponse<{
'isSuccess'?: boolean;
'message'?: string;
'data'?: {
'pageNumber'?: number;
'pageSize'?: number;
'totalRowCount'?: number;
'items'?: Array<MerchantReferralItem>;
};
}>> {

    const rb = new RequestBuilder(this.rootUrl, ApiService.MerchantListPath, 'post');
    if (params) {
      rb.body(params.body, 'application/json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<{
        'isSuccess'?: boolean;
        'message'?: string;
        'data'?: {
        'pageNumber'?: number;
        'pageSize'?: number;
        'totalRowCount'?: number;
        'items'?: Array<MerchantReferralItem>;
        };
        }>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `merchantList$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  merchantList(params: {
    body: FetchReferralRequest
  }): Observable<{
'isSuccess'?: boolean;
'message'?: string;
'data'?: {
'pageNumber'?: number;
'pageSize'?: number;
'totalRowCount'?: number;
'items'?: Array<MerchantReferralItem>;
};
}> {

    return this.merchantList$Response(params).pipe(
      map((r: StrictHttpResponse<{
'isSuccess'?: boolean;
'message'?: string;
'data'?: {
'pageNumber'?: number;
'pageSize'?: number;
'totalRowCount'?: number;
'items'?: Array<MerchantReferralItem>;
};
}>) => r.body as {
'isSuccess'?: boolean;
'message'?: string;
'data'?: {
'pageNumber'?: number;
'pageSize'?: number;
'totalRowCount'?: number;
'items'?: Array<MerchantReferralItem>;
};
})
    );
  }

  /**
   * Path part for operation merchantAdd
   */
  static readonly MerchantAddPath = '/v1/referral/add/merchant';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `merchantAdd()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  merchantAdd$Response(params: {
    body: MerchantReferralRequest
  }): Observable<StrictHttpResponse<{
'isSuccess'?: boolean;
'message'?: string;
'data'?: string;
}>> {

    const rb = new RequestBuilder(this.rootUrl, ApiService.MerchantAddPath, 'post');
    if (params) {
      rb.body(params.body, 'application/json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<{
        'isSuccess'?: boolean;
        'message'?: string;
        'data'?: string;
        }>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `merchantAdd$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  merchantAdd(params: {
    body: MerchantReferralRequest
  }): Observable<{
'isSuccess'?: boolean;
'message'?: string;
'data'?: string;
}> {

    return this.merchantAdd$Response(params).pipe(
      map((r: StrictHttpResponse<{
'isSuccess'?: boolean;
'message'?: string;
'data'?: string;
}>) => r.body as {
'isSuccess'?: boolean;
'message'?: string;
'data'?: string;
})
    );
  }

  /**
   * Path part for operation merchantUpdate
   */
  static readonly MerchantUpdatePath = '/v1/referral/update/merchant';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `merchantUpdate()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  merchantUpdate$Response(params: {
    body: MerchantReferralRequest
  }): Observable<StrictHttpResponse<{
'isSuccess'?: boolean;
'message'?: string;
'data'?: string;
}>> {

    const rb = new RequestBuilder(this.rootUrl, ApiService.MerchantUpdatePath, 'post');
    if (params) {
      rb.body(params.body, 'application/json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<{
        'isSuccess'?: boolean;
        'message'?: string;
        'data'?: string;
        }>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `merchantUpdate$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  merchantUpdate(params: {
    body: MerchantReferralRequest
  }): Observable<{
'isSuccess'?: boolean;
'message'?: string;
'data'?: string;
}> {

    return this.merchantUpdate$Response(params).pipe(
      map((r: StrictHttpResponse<{
'isSuccess'?: boolean;
'message'?: string;
'data'?: string;
}>) => r.body as {
'isSuccess'?: boolean;
'message'?: string;
'data'?: string;
})
    );
  }

  /**
   * Path part for operation merchantMaterialAdd
   */
  static readonly MerchantMaterialAddPath = '/v1/referral/add/merchantMaterial';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `merchantMaterialAdd()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  merchantMaterialAdd$Response(params: {
    body: MerchantReferralMaterialRequest
  }): Observable<StrictHttpResponse<{
'isSuccess'?: boolean;
'message'?: string;
'data'?: string;
}>> {

    const rb = new RequestBuilder(this.rootUrl, ApiService.MerchantMaterialAddPath, 'post');
    if (params) {
      rb.body(params.body, 'application/json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<{
        'isSuccess'?: boolean;
        'message'?: string;
        'data'?: string;
        }>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `merchantMaterialAdd$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  merchantMaterialAdd(params: {
    body: MerchantReferralMaterialRequest
  }): Observable<{
'isSuccess'?: boolean;
'message'?: string;
'data'?: string;
}> {

    return this.merchantMaterialAdd$Response(params).pipe(
      map((r: StrictHttpResponse<{
'isSuccess'?: boolean;
'message'?: string;
'data'?: string;
}>) => r.body as {
'isSuccess'?: boolean;
'message'?: string;
'data'?: string;
})
    );
  }

  /**
   * Path part for operation merchantMaterialList
   */
  static readonly MerchantMaterialListPath = '/v1/referral/list/merchantMaterial';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `merchantMaterialList()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  merchantMaterialList$Response(params: {
    body: EntityGetRequest
  }): Observable<StrictHttpResponse<{
'isSuccess'?: boolean;
'message'?: string;
'data'?: {
'pageNumber'?: number;
'pageSize'?: number;
'totalRowCount'?: number;
'items'?: Array<MerchantReferralAttachmentList>;
};
}>> {

    const rb = new RequestBuilder(this.rootUrl, ApiService.MerchantMaterialListPath, 'post');
    if (params) {
      rb.body(params.body, 'application/json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<{
        'isSuccess'?: boolean;
        'message'?: string;
        'data'?: {
        'pageNumber'?: number;
        'pageSize'?: number;
        'totalRowCount'?: number;
        'items'?: Array<MerchantReferralAttachmentList>;
        };
        }>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `merchantMaterialList$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  merchantMaterialList(params: {
    body: EntityGetRequest
  }): Observable<{
'isSuccess'?: boolean;
'message'?: string;
'data'?: {
'pageNumber'?: number;
'pageSize'?: number;
'totalRowCount'?: number;
'items'?: Array<MerchantReferralAttachmentList>;
};
}> {

    return this.merchantMaterialList$Response(params).pipe(
      map((r: StrictHttpResponse<{
'isSuccess'?: boolean;
'message'?: string;
'data'?: {
'pageNumber'?: number;
'pageSize'?: number;
'totalRowCount'?: number;
'items'?: Array<MerchantReferralAttachmentList>;
};
}>) => r.body as {
'isSuccess'?: boolean;
'message'?: string;
'data'?: {
'pageNumber'?: number;
'pageSize'?: number;
'totalRowCount'?: number;
'items'?: Array<MerchantReferralAttachmentList>;
};
})
    );
  }

  /**
   * Path part for operation merchantMaterialDelete
   */
  static readonly MerchantMaterialDeletePath = '/v1/referral/delete/merchantMaterial';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `merchantMaterialDelete()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  merchantMaterialDelete$Response(params: {
    body: MerchantReferralMaterialDeleteRequest
  }): Observable<StrictHttpResponse<{
'isSuccess'?: boolean;
'message'?: string;
'data'?: string;
}>> {

    const rb = new RequestBuilder(this.rootUrl, ApiService.MerchantMaterialDeletePath, 'post');
    if (params) {
      rb.body(params.body, 'application/json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<{
        'isSuccess'?: boolean;
        'message'?: string;
        'data'?: string;
        }>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `merchantMaterialDelete$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  merchantMaterialDelete(params: {
    body: MerchantReferralMaterialDeleteRequest
  }): Observable<{
'isSuccess'?: boolean;
'message'?: string;
'data'?: string;
}> {

    return this.merchantMaterialDelete$Response(params).pipe(
      map((r: StrictHttpResponse<{
'isSuccess'?: boolean;
'message'?: string;
'data'?: string;
}>) => r.body as {
'isSuccess'?: boolean;
'message'?: string;
'data'?: string;
})
    );
  }

  /**
   * Path part for operation mortgageList
   */
  static readonly MortgageListPath = '/v1/referral/list/mortgage';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `mortgageList()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  mortgageList$Response(params: {
    body: FetchReferralRequest
  }): Observable<StrictHttpResponse<{
'isSuccess'?: boolean;
'message'?: string;
'data'?: {
'pageNumber'?: number;
'pageSize'?: number;
'totalRowCount'?: number;
'items'?: Array<MortgageReferralItem>;
};
}>> {

    const rb = new RequestBuilder(this.rootUrl, ApiService.MortgageListPath, 'post');
    if (params) {
      rb.body(params.body, 'application/json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<{
        'isSuccess'?: boolean;
        'message'?: string;
        'data'?: {
        'pageNumber'?: number;
        'pageSize'?: number;
        'totalRowCount'?: number;
        'items'?: Array<MortgageReferralItem>;
        };
        }>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `mortgageList$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  mortgageList(params: {
    body: FetchReferralRequest
  }): Observable<{
'isSuccess'?: boolean;
'message'?: string;
'data'?: {
'pageNumber'?: number;
'pageSize'?: number;
'totalRowCount'?: number;
'items'?: Array<MortgageReferralItem>;
};
}> {

    return this.mortgageList$Response(params).pipe(
      map((r: StrictHttpResponse<{
'isSuccess'?: boolean;
'message'?: string;
'data'?: {
'pageNumber'?: number;
'pageSize'?: number;
'totalRowCount'?: number;
'items'?: Array<MortgageReferralItem>;
};
}>) => r.body as {
'isSuccess'?: boolean;
'message'?: string;
'data'?: {
'pageNumber'?: number;
'pageSize'?: number;
'totalRowCount'?: number;
'items'?: Array<MortgageReferralItem>;
};
})
    );
  }

  /**
   * Path part for operation mortgageAdd
   */
  static readonly MortgageAddPath = '/v1/referral/add/mortgage';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `mortgageAdd()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  mortgageAdd$Response(params: {
    body: MortgageReferralRequest
  }): Observable<StrictHttpResponse<{
'isSuccess'?: boolean;
'message'?: string;
'data'?: string;
}>> {

    const rb = new RequestBuilder(this.rootUrl, ApiService.MortgageAddPath, 'post');
    if (params) {
      rb.body(params.body, 'application/json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<{
        'isSuccess'?: boolean;
        'message'?: string;
        'data'?: string;
        }>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `mortgageAdd$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  mortgageAdd(params: {
    body: MortgageReferralRequest
  }): Observable<{
'isSuccess'?: boolean;
'message'?: string;
'data'?: string;
}> {

    return this.mortgageAdd$Response(params).pipe(
      map((r: StrictHttpResponse<{
'isSuccess'?: boolean;
'message'?: string;
'data'?: string;
}>) => r.body as {
'isSuccess'?: boolean;
'message'?: string;
'data'?: string;
})
    );
  }

  /**
   * Path part for operation mortgageUpdate
   */
  static readonly MortgageUpdatePath = '/v1/referral/update/mortgage';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `mortgageUpdate()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  mortgageUpdate$Response(params: {
    body: MortgageReferralRequest
  }): Observable<StrictHttpResponse<{
'isSuccess'?: boolean;
'message'?: string;
'data'?: string;
}>> {

    const rb = new RequestBuilder(this.rootUrl, ApiService.MortgageUpdatePath, 'post');
    if (params) {
      rb.body(params.body, 'application/json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<{
        'isSuccess'?: boolean;
        'message'?: string;
        'data'?: string;
        }>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `mortgageUpdate$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  mortgageUpdate(params: {
    body: MortgageReferralRequest
  }): Observable<{
'isSuccess'?: boolean;
'message'?: string;
'data'?: string;
}> {

    return this.mortgageUpdate$Response(params).pipe(
      map((r: StrictHttpResponse<{
'isSuccess'?: boolean;
'message'?: string;
'data'?: string;
}>) => r.body as {
'isSuccess'?: boolean;
'message'?: string;
'data'?: string;
})
    );
  }

  /**
   * Path part for operation mortgageMaterialAdd
   */
  static readonly MortgageMaterialAddPath = '/v1/referral/add/mortgageMaterial';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `mortgageMaterialAdd()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  mortgageMaterialAdd$Response(params: {
    body: MortgageReferralMaterialRequest
  }): Observable<StrictHttpResponse<{
'isSuccess'?: boolean;
'message'?: string;
'data'?: string;
}>> {

    const rb = new RequestBuilder(this.rootUrl, ApiService.MortgageMaterialAddPath, 'post');
    if (params) {
      rb.body(params.body, 'application/json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<{
        'isSuccess'?: boolean;
        'message'?: string;
        'data'?: string;
        }>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `mortgageMaterialAdd$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  mortgageMaterialAdd(params: {
    body: MortgageReferralMaterialRequest
  }): Observable<{
'isSuccess'?: boolean;
'message'?: string;
'data'?: string;
}> {

    return this.mortgageMaterialAdd$Response(params).pipe(
      map((r: StrictHttpResponse<{
'isSuccess'?: boolean;
'message'?: string;
'data'?: string;
}>) => r.body as {
'isSuccess'?: boolean;
'message'?: string;
'data'?: string;
})
    );
  }

  /**
   * Path part for operation mortgageMaterialList
   */
  static readonly MortgageMaterialListPath = '/v1/referral/list/mortgageMaterial';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `mortgageMaterialList()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  mortgageMaterialList$Response(params: {
    body: EntityGetRequest
  }): Observable<StrictHttpResponse<{
'isSuccess'?: boolean;
'message'?: string;
'data'?: {
'pageNumber'?: number;
'pageSize'?: number;
'totalRowCount'?: number;
'items'?: Array<MortgageReferralAttachmentList>;
};
}>> {

    const rb = new RequestBuilder(this.rootUrl, ApiService.MortgageMaterialListPath, 'post');
    if (params) {
      rb.body(params.body, 'application/json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<{
        'isSuccess'?: boolean;
        'message'?: string;
        'data'?: {
        'pageNumber'?: number;
        'pageSize'?: number;
        'totalRowCount'?: number;
        'items'?: Array<MortgageReferralAttachmentList>;
        };
        }>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `mortgageMaterialList$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  mortgageMaterialList(params: {
    body: EntityGetRequest
  }): Observable<{
'isSuccess'?: boolean;
'message'?: string;
'data'?: {
'pageNumber'?: number;
'pageSize'?: number;
'totalRowCount'?: number;
'items'?: Array<MortgageReferralAttachmentList>;
};
}> {

    return this.mortgageMaterialList$Response(params).pipe(
      map((r: StrictHttpResponse<{
'isSuccess'?: boolean;
'message'?: string;
'data'?: {
'pageNumber'?: number;
'pageSize'?: number;
'totalRowCount'?: number;
'items'?: Array<MortgageReferralAttachmentList>;
};
}>) => r.body as {
'isSuccess'?: boolean;
'message'?: string;
'data'?: {
'pageNumber'?: number;
'pageSize'?: number;
'totalRowCount'?: number;
'items'?: Array<MortgageReferralAttachmentList>;
};
})
    );
  }

  /**
   * Path part for operation mortgageMaterialDelete
   */
  static readonly MortgageMaterialDeletePath = '/v1/referral/delete/mortgageMaterial';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `mortgageMaterialDelete()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  mortgageMaterialDelete$Response(params: {
    body: MortgageReferralMaterialDeleteRequest
  }): Observable<StrictHttpResponse<{
'isSuccess'?: boolean;
'message'?: string;
'data'?: string;
}>> {

    const rb = new RequestBuilder(this.rootUrl, ApiService.MortgageMaterialDeletePath, 'post');
    if (params) {
      rb.body(params.body, 'application/json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<{
        'isSuccess'?: boolean;
        'message'?: string;
        'data'?: string;
        }>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `mortgageMaterialDelete$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  mortgageMaterialDelete(params: {
    body: MortgageReferralMaterialDeleteRequest
  }): Observable<{
'isSuccess'?: boolean;
'message'?: string;
'data'?: string;
}> {

    return this.mortgageMaterialDelete$Response(params).pipe(
      map((r: StrictHttpResponse<{
'isSuccess'?: boolean;
'message'?: string;
'data'?: string;
}>) => r.body as {
'isSuccess'?: boolean;
'message'?: string;
'data'?: string;
})
    );
  }

  /**
   * Path part for operation treasuryList
   */
  static readonly TreasuryListPath = '/v1/referral/list/treasury';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `treasuryList()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  treasuryList$Response(params: {
    body: FetchReferralRequest
  }): Observable<StrictHttpResponse<{
'isSuccess'?: boolean;
'message'?: string;
'data'?: {
'pageNumber'?: number;
'pageSize'?: number;
'totalRowCount'?: number;
'items'?: Array<TreasuryReferralItem>;
};
}>> {

    const rb = new RequestBuilder(this.rootUrl, ApiService.TreasuryListPath, 'post');
    if (params) {
      rb.body(params.body, 'application/json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<{
        'isSuccess'?: boolean;
        'message'?: string;
        'data'?: {
        'pageNumber'?: number;
        'pageSize'?: number;
        'totalRowCount'?: number;
        'items'?: Array<TreasuryReferralItem>;
        };
        }>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `treasuryList$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  treasuryList(params: {
    body: FetchReferralRequest
  }): Observable<{
'isSuccess'?: boolean;
'message'?: string;
'data'?: {
'pageNumber'?: number;
'pageSize'?: number;
'totalRowCount'?: number;
'items'?: Array<TreasuryReferralItem>;
};
}> {

    return this.treasuryList$Response(params).pipe(
      map((r: StrictHttpResponse<{
'isSuccess'?: boolean;
'message'?: string;
'data'?: {
'pageNumber'?: number;
'pageSize'?: number;
'totalRowCount'?: number;
'items'?: Array<TreasuryReferralItem>;
};
}>) => r.body as {
'isSuccess'?: boolean;
'message'?: string;
'data'?: {
'pageNumber'?: number;
'pageSize'?: number;
'totalRowCount'?: number;
'items'?: Array<TreasuryReferralItem>;
};
})
    );
  }

  /**
   * Path part for operation treasuryAdd
   */
  static readonly TreasuryAddPath = '/v1/referral/add/treasury';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `treasuryAdd()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  treasuryAdd$Response(params: {
    body: TreasuryReferralRequest
  }): Observable<StrictHttpResponse<{
'isSuccess'?: boolean;
'message'?: string;
'data'?: string;
}>> {

    const rb = new RequestBuilder(this.rootUrl, ApiService.TreasuryAddPath, 'post');
    if (params) {
      rb.body(params.body, 'application/json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<{
        'isSuccess'?: boolean;
        'message'?: string;
        'data'?: string;
        }>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `treasuryAdd$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  treasuryAdd(params: {
    body: TreasuryReferralRequest
  }): Observable<{
'isSuccess'?: boolean;
'message'?: string;
'data'?: string;
}> {

    return this.treasuryAdd$Response(params).pipe(
      map((r: StrictHttpResponse<{
'isSuccess'?: boolean;
'message'?: string;
'data'?: string;
}>) => r.body as {
'isSuccess'?: boolean;
'message'?: string;
'data'?: string;
})
    );
  }

  /**
   * Path part for operation treasuryUpdate
   */
  static readonly TreasuryUpdatePath = '/v1/referral/update/treasury';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `treasuryUpdate()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  treasuryUpdate$Response(params: {
    body: TreasuryReferralRequest
  }): Observable<StrictHttpResponse<{
'isSuccess'?: boolean;
'message'?: string;
'data'?: string;
}>> {

    const rb = new RequestBuilder(this.rootUrl, ApiService.TreasuryUpdatePath, 'post');
    if (params) {
      rb.body(params.body, 'application/json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<{
        'isSuccess'?: boolean;
        'message'?: string;
        'data'?: string;
        }>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `treasuryUpdate$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  treasuryUpdate(params: {
    body: TreasuryReferralRequest
  }): Observable<{
'isSuccess'?: boolean;
'message'?: string;
'data'?: string;
}> {

    return this.treasuryUpdate$Response(params).pipe(
      map((r: StrictHttpResponse<{
'isSuccess'?: boolean;
'message'?: string;
'data'?: string;
}>) => r.body as {
'isSuccess'?: boolean;
'message'?: string;
'data'?: string;
})
    );
  }

  /**
   * Path part for operation treasuryMaterialAdd
   */
  static readonly TreasuryMaterialAddPath = '/v1/referral/add/treasuryMaterial';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `treasuryMaterialAdd()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  treasuryMaterialAdd$Response(params: {
    body: TreasuryReferralMaterialRequest
  }): Observable<StrictHttpResponse<{
'isSuccess'?: boolean;
'message'?: string;
'data'?: string;
}>> {

    const rb = new RequestBuilder(this.rootUrl, ApiService.TreasuryMaterialAddPath, 'post');
    if (params) {
      rb.body(params.body, 'application/json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<{
        'isSuccess'?: boolean;
        'message'?: string;
        'data'?: string;
        }>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `treasuryMaterialAdd$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  treasuryMaterialAdd(params: {
    body: TreasuryReferralMaterialRequest
  }): Observable<{
'isSuccess'?: boolean;
'message'?: string;
'data'?: string;
}> {

    return this.treasuryMaterialAdd$Response(params).pipe(
      map((r: StrictHttpResponse<{
'isSuccess'?: boolean;
'message'?: string;
'data'?: string;
}>) => r.body as {
'isSuccess'?: boolean;
'message'?: string;
'data'?: string;
})
    );
  }

  /**
   * Path part for operation treasuryMaterialDelete
   */
  static readonly TreasuryMaterialDeletePath = '/v1/referral/delete/treasuryMaterial';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `treasuryMaterialDelete()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  treasuryMaterialDelete$Response(params: {
    body: TreasuryReferralMaterialDeleteRequest
  }): Observable<StrictHttpResponse<{
'isSuccess'?: boolean;
'message'?: string;
'data'?: string;
}>> {

    const rb = new RequestBuilder(this.rootUrl, ApiService.TreasuryMaterialDeletePath, 'post');
    if (params) {
      rb.body(params.body, 'application/json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<{
        'isSuccess'?: boolean;
        'message'?: string;
        'data'?: string;
        }>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `treasuryMaterialDelete$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  treasuryMaterialDelete(params: {
    body: TreasuryReferralMaterialDeleteRequest
  }): Observable<{
'isSuccess'?: boolean;
'message'?: string;
'data'?: string;
}> {

    return this.treasuryMaterialDelete$Response(params).pipe(
      map((r: StrictHttpResponse<{
'isSuccess'?: boolean;
'message'?: string;
'data'?: string;
}>) => r.body as {
'isSuccess'?: boolean;
'message'?: string;
'data'?: string;
})
    );
  }

  /**
   * Path part for operation treasuryMaterialList
   */
  static readonly TreasuryMaterialListPath = '/v1/referral/list/treasuryMaterial';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `treasuryMaterialList()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  treasuryMaterialList$Response(params: {
    body: EntityGetRequest
  }): Observable<StrictHttpResponse<{
'isSuccess'?: boolean;
'message'?: string;
'data'?: {
'pageNumber'?: number;
'pageSize'?: number;
'totalRowCount'?: number;
'items'?: Array<TreasuryReferralAttachmentList>;
};
}>> {

    const rb = new RequestBuilder(this.rootUrl, ApiService.TreasuryMaterialListPath, 'post');
    if (params) {
      rb.body(params.body, 'application/json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<{
        'isSuccess'?: boolean;
        'message'?: string;
        'data'?: {
        'pageNumber'?: number;
        'pageSize'?: number;
        'totalRowCount'?: number;
        'items'?: Array<TreasuryReferralAttachmentList>;
        };
        }>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `treasuryMaterialList$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  treasuryMaterialList(params: {
    body: EntityGetRequest
  }): Observable<{
'isSuccess'?: boolean;
'message'?: string;
'data'?: {
'pageNumber'?: number;
'pageSize'?: number;
'totalRowCount'?: number;
'items'?: Array<TreasuryReferralAttachmentList>;
};
}> {

    return this.treasuryMaterialList$Response(params).pipe(
      map((r: StrictHttpResponse<{
'isSuccess'?: boolean;
'message'?: string;
'data'?: {
'pageNumber'?: number;
'pageSize'?: number;
'totalRowCount'?: number;
'items'?: Array<TreasuryReferralAttachmentList>;
};
}>) => r.body as {
'isSuccess'?: boolean;
'message'?: string;
'data'?: {
'pageNumber'?: number;
'pageSize'?: number;
'totalRowCount'?: number;
'items'?: Array<TreasuryReferralAttachmentList>;
};
})
    );
  }

  /**
   * Path part for operation wealthManagementList
   */
  static readonly WealthManagementListPath = '/v1/referral/list/wealthManagement';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `wealthManagementList()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  wealthManagementList$Response(params: {
    body: FetchReferralRequest
  }): Observable<StrictHttpResponse<{
'isSuccess'?: boolean;
'message'?: string;
'data'?: {
'pageNumber'?: number;
'pageSize'?: number;
'totalRowCount'?: number;
'items'?: Array<WealthManagementReferralItem>;
};
}>> {

    const rb = new RequestBuilder(this.rootUrl, ApiService.WealthManagementListPath, 'post');
    if (params) {
      rb.body(params.body, 'application/json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<{
        'isSuccess'?: boolean;
        'message'?: string;
        'data'?: {
        'pageNumber'?: number;
        'pageSize'?: number;
        'totalRowCount'?: number;
        'items'?: Array<WealthManagementReferralItem>;
        };
        }>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `wealthManagementList$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  wealthManagementList(params: {
    body: FetchReferralRequest
  }): Observable<{
'isSuccess'?: boolean;
'message'?: string;
'data'?: {
'pageNumber'?: number;
'pageSize'?: number;
'totalRowCount'?: number;
'items'?: Array<WealthManagementReferralItem>;
};
}> {

    return this.wealthManagementList$Response(params).pipe(
      map((r: StrictHttpResponse<{
'isSuccess'?: boolean;
'message'?: string;
'data'?: {
'pageNumber'?: number;
'pageSize'?: number;
'totalRowCount'?: number;
'items'?: Array<WealthManagementReferralItem>;
};
}>) => r.body as {
'isSuccess'?: boolean;
'message'?: string;
'data'?: {
'pageNumber'?: number;
'pageSize'?: number;
'totalRowCount'?: number;
'items'?: Array<WealthManagementReferralItem>;
};
})
    );
  }

  /**
   * Path part for operation wealthManagementAdd
   */
  static readonly WealthManagementAddPath = '/v1/referral/add/wealthManagement';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `wealthManagementAdd()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  wealthManagementAdd$Response(params: {
    body: WealthManagementReferralRequest
  }): Observable<StrictHttpResponse<{
'isSuccess'?: boolean;
'message'?: string;
'data'?: string;
}>> {

    const rb = new RequestBuilder(this.rootUrl, ApiService.WealthManagementAddPath, 'post');
    if (params) {
      rb.body(params.body, 'application/json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<{
        'isSuccess'?: boolean;
        'message'?: string;
        'data'?: string;
        }>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `wealthManagementAdd$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  wealthManagementAdd(params: {
    body: WealthManagementReferralRequest
  }): Observable<{
'isSuccess'?: boolean;
'message'?: string;
'data'?: string;
}> {

    return this.wealthManagementAdd$Response(params).pipe(
      map((r: StrictHttpResponse<{
'isSuccess'?: boolean;
'message'?: string;
'data'?: string;
}>) => r.body as {
'isSuccess'?: boolean;
'message'?: string;
'data'?: string;
})
    );
  }

  /**
   * Path part for operation wealthManagementUpdate
   */
  static readonly WealthManagementUpdatePath = '/v1/referral/update/wealthManagement';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `wealthManagementUpdate()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  wealthManagementUpdate$Response(params: {
    body: WealthManagementReferralRequest
  }): Observable<StrictHttpResponse<{
'isSuccess'?: boolean;
'message'?: string;
'data'?: string;
}>> {

    const rb = new RequestBuilder(this.rootUrl, ApiService.WealthManagementUpdatePath, 'post');
    if (params) {
      rb.body(params.body, 'application/json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<{
        'isSuccess'?: boolean;
        'message'?: string;
        'data'?: string;
        }>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `wealthManagementUpdate$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  wealthManagementUpdate(params: {
    body: WealthManagementReferralRequest
  }): Observable<{
'isSuccess'?: boolean;
'message'?: string;
'data'?: string;
}> {

    return this.wealthManagementUpdate$Response(params).pipe(
      map((r: StrictHttpResponse<{
'isSuccess'?: boolean;
'message'?: string;
'data'?: string;
}>) => r.body as {
'isSuccess'?: boolean;
'message'?: string;
'data'?: string;
})
    );
  }

  /**
   * Path part for operation wealthManagementMaterialAdd
   */
  static readonly WealthManagementMaterialAddPath = '/v1/referral/add/wealthManagementMaterial';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `wealthManagementMaterialAdd()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  wealthManagementMaterialAdd$Response(params: {
    body: WealthManagementReferralMaterialRequest
  }): Observable<StrictHttpResponse<{
'isSuccess'?: boolean;
'message'?: string;
'data'?: string;
}>> {

    const rb = new RequestBuilder(this.rootUrl, ApiService.WealthManagementMaterialAddPath, 'post');
    if (params) {
      rb.body(params.body, 'application/json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<{
        'isSuccess'?: boolean;
        'message'?: string;
        'data'?: string;
        }>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `wealthManagementMaterialAdd$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  wealthManagementMaterialAdd(params: {
    body: WealthManagementReferralMaterialRequest
  }): Observable<{
'isSuccess'?: boolean;
'message'?: string;
'data'?: string;
}> {

    return this.wealthManagementMaterialAdd$Response(params).pipe(
      map((r: StrictHttpResponse<{
'isSuccess'?: boolean;
'message'?: string;
'data'?: string;
}>) => r.body as {
'isSuccess'?: boolean;
'message'?: string;
'data'?: string;
})
    );
  }

  /**
   * Path part for operation assignReferralsToUser
   */
  static readonly AssignReferralsToUserPath = '/v1/referral/assignedToUpdate/referrals';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `assignReferralsToUser()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  assignReferralsToUser$Response(params: {
    body: AssignReferralsToUserRequest
  }): Observable<StrictHttpResponse<{
'isSuccess'?: boolean;
'message'?: string;
'data'?: string;
}>> {

    const rb = new RequestBuilder(this.rootUrl, ApiService.AssignReferralsToUserPath, 'post');
    if (params) {
      rb.body(params.body, 'application/json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<{
        'isSuccess'?: boolean;
        'message'?: string;
        'data'?: string;
        }>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `assignReferralsToUser$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  assignReferralsToUser(params: {
    body: AssignReferralsToUserRequest
  }): Observable<{
'isSuccess'?: boolean;
'message'?: string;
'data'?: string;
}> {

    return this.assignReferralsToUser$Response(params).pipe(
      map((r: StrictHttpResponse<{
'isSuccess'?: boolean;
'message'?: string;
'data'?: string;
}>) => r.body as {
'isSuccess'?: boolean;
'message'?: string;
'data'?: string;
})
    );
  }

  /**
   * Path part for operation getReportCount
   */
  static readonly GetReportCountPath = '/v1/referral/report/counts';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getReportCount()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  getReportCount$Response(params: {
    body: ReportCountsRequest
  }): Observable<StrictHttpResponse<{
'isSuccess'?: boolean;
'message'?: string;
'data'?: {
'pageNumber'?: number;
'pageSize'?: number;
'totalRowCount'?: number;
'items'?: Array<ReportCounts>;
};
}>> {

    const rb = new RequestBuilder(this.rootUrl, ApiService.GetReportCountPath, 'post');
    if (params) {
      rb.body(params.body, 'application/json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<{
        'isSuccess'?: boolean;
        'message'?: string;
        'data'?: {
        'pageNumber'?: number;
        'pageSize'?: number;
        'totalRowCount'?: number;
        'items'?: Array<ReportCounts>;
        };
        }>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `getReportCount$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  getReportCount(params: {
    body: ReportCountsRequest
  }): Observable<{
'isSuccess'?: boolean;
'message'?: string;
'data'?: {
'pageNumber'?: number;
'pageSize'?: number;
'totalRowCount'?: number;
'items'?: Array<ReportCounts>;
};
}> {

    return this.getReportCount$Response(params).pipe(
      map((r: StrictHttpResponse<{
'isSuccess'?: boolean;
'message'?: string;
'data'?: {
'pageNumber'?: number;
'pageSize'?: number;
'totalRowCount'?: number;
'items'?: Array<ReportCounts>;
};
}>) => r.body as {
'isSuccess'?: boolean;
'message'?: string;
'data'?: {
'pageNumber'?: number;
'pageSize'?: number;
'totalRowCount'?: number;
'items'?: Array<ReportCounts>;
};
})
    );
  }

  /**
   * Path part for operation getReportMonthWise
   */
  static readonly GetReportMonthWisePath = '/v1/referral/report/monthView';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getReportMonthWise()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  getReportMonthWise$Response(params: {
    body: ReportCountsRequest
  }): Observable<StrictHttpResponse<{
'isSuccess'?: boolean;
'message'?: string;
'data'?: {
'pageNumber'?: number;
'pageSize'?: number;
'totalRowCount'?: number;
'items'?: Array<ReportCounts>;
};
}>> {

    const rb = new RequestBuilder(this.rootUrl, ApiService.GetReportMonthWisePath, 'post');
    if (params) {
      rb.body(params.body, 'application/json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<{
        'isSuccess'?: boolean;
        'message'?: string;
        'data'?: {
        'pageNumber'?: number;
        'pageSize'?: number;
        'totalRowCount'?: number;
        'items'?: Array<ReportCounts>;
        };
        }>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `getReportMonthWise$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  getReportMonthWise(params: {
    body: ReportCountsRequest
  }): Observable<{
'isSuccess'?: boolean;
'message'?: string;
'data'?: {
'pageNumber'?: number;
'pageSize'?: number;
'totalRowCount'?: number;
'items'?: Array<ReportCounts>;
};
}> {

    return this.getReportMonthWise$Response(params).pipe(
      map((r: StrictHttpResponse<{
'isSuccess'?: boolean;
'message'?: string;
'data'?: {
'pageNumber'?: number;
'pageSize'?: number;
'totalRowCount'?: number;
'items'?: Array<ReportCounts>;
};
}>) => r.body as {
'isSuccess'?: boolean;
'message'?: string;
'data'?: {
'pageNumber'?: number;
'pageSize'?: number;
'totalRowCount'?: number;
'items'?: Array<ReportCounts>;
};
})
    );
  }

  /**
   * Path part for operation getClosedWonReports
   */
  static readonly GetClosedWonReportsPath = '/v1/referral/report/closedWon';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getClosedWonReports()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  getClosedWonReports$Response(params: {
    body: ReportCountsRequest
  }): Observable<StrictHttpResponse<{
'isSuccess'?: boolean;
'message'?: string;
'data'?: {
'pageNumber'?: number;
'pageSize'?: number;
'totalRowCount'?: number;
'items'?: Array<ReportCounts>;
};
}>> {

    const rb = new RequestBuilder(this.rootUrl, ApiService.GetClosedWonReportsPath, 'post');
    if (params) {
      rb.body(params.body, 'application/json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<{
        'isSuccess'?: boolean;
        'message'?: string;
        'data'?: {
        'pageNumber'?: number;
        'pageSize'?: number;
        'totalRowCount'?: number;
        'items'?: Array<ReportCounts>;
        };
        }>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `getClosedWonReports$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  getClosedWonReports(params: {
    body: ReportCountsRequest
  }): Observable<{
'isSuccess'?: boolean;
'message'?: string;
'data'?: {
'pageNumber'?: number;
'pageSize'?: number;
'totalRowCount'?: number;
'items'?: Array<ReportCounts>;
};
}> {

    return this.getClosedWonReports$Response(params).pipe(
      map((r: StrictHttpResponse<{
'isSuccess'?: boolean;
'message'?: string;
'data'?: {
'pageNumber'?: number;
'pageSize'?: number;
'totalRowCount'?: number;
'items'?: Array<ReportCounts>;
};
}>) => r.body as {
'isSuccess'?: boolean;
'message'?: string;
'data'?: {
'pageNumber'?: number;
'pageSize'?: number;
'totalRowCount'?: number;
'items'?: Array<ReportCounts>;
};
})
    );
  }

  /**
   * Path part for operation wealthMaterialList
   */
  static readonly WealthMaterialListPath = '/v1/referral/list/wealthMaterial';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `wealthMaterialList()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  wealthMaterialList$Response(params: {
    body: EntityGetRequest
  }): Observable<StrictHttpResponse<{
'isSuccess'?: boolean;
'message'?: string;
'data'?: {
'pageNumber'?: number;
'pageSize'?: number;
'totalRowCount'?: number;
'items'?: Array<WealthReferralAttachmentList>;
};
}>> {

    const rb = new RequestBuilder(this.rootUrl, ApiService.WealthMaterialListPath, 'post');
    if (params) {
      rb.body(params.body, 'application/json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<{
        'isSuccess'?: boolean;
        'message'?: string;
        'data'?: {
        'pageNumber'?: number;
        'pageSize'?: number;
        'totalRowCount'?: number;
        'items'?: Array<WealthReferralAttachmentList>;
        };
        }>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `wealthMaterialList$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  wealthMaterialList(params: {
    body: EntityGetRequest
  }): Observable<{
'isSuccess'?: boolean;
'message'?: string;
'data'?: {
'pageNumber'?: number;
'pageSize'?: number;
'totalRowCount'?: number;
'items'?: Array<WealthReferralAttachmentList>;
};
}> {

    return this.wealthMaterialList$Response(params).pipe(
      map((r: StrictHttpResponse<{
'isSuccess'?: boolean;
'message'?: string;
'data'?: {
'pageNumber'?: number;
'pageSize'?: number;
'totalRowCount'?: number;
'items'?: Array<WealthReferralAttachmentList>;
};
}>) => r.body as {
'isSuccess'?: boolean;
'message'?: string;
'data'?: {
'pageNumber'?: number;
'pageSize'?: number;
'totalRowCount'?: number;
'items'?: Array<WealthReferralAttachmentList>;
};
})
    );
  }

  /**
   * Path part for operation wealthManagementMaterialDelete
   */
  static readonly WealthManagementMaterialDeletePath = '/v1/referral/delete/wealthManagementMaterial';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `wealthManagementMaterialDelete()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  wealthManagementMaterialDelete$Response(params: {
    body: WealthManagementReferralMaterialDeleteRequest
  }): Observable<StrictHttpResponse<{
'isSuccess'?: boolean;
'message'?: string;
'data'?: string;
}>> {

    const rb = new RequestBuilder(this.rootUrl, ApiService.WealthManagementMaterialDeletePath, 'post');
    if (params) {
      rb.body(params.body, 'application/json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<{
        'isSuccess'?: boolean;
        'message'?: string;
        'data'?: string;
        }>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `wealthManagementMaterialDelete$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  wealthManagementMaterialDelete(params: {
    body: WealthManagementReferralMaterialDeleteRequest
  }): Observable<{
'isSuccess'?: boolean;
'message'?: string;
'data'?: string;
}> {

    return this.wealthManagementMaterialDelete$Response(params).pipe(
      map((r: StrictHttpResponse<{
'isSuccess'?: boolean;
'message'?: string;
'data'?: string;
}>) => r.body as {
'isSuccess'?: boolean;
'message'?: string;
'data'?: string;
})
    );
  }

  /**
   * Path part for operation allReferralsForUser
   */
  static readonly AllReferralsForUserPath = '/v1/referral/list/user/referrals';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `allReferralsForUser()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  allReferralsForUser$Response(params: {
    body: ReferralForUserRequest
  }): Observable<StrictHttpResponse<{
'isSuccess'?: boolean;
'message'?: string;
'data'?: {
'pageNumber'?: number;
'pageSize'?: number;
'totalRowCount'?: number;
'items'?: Array<ReferralForUserListItem>;
};
}>> {

    const rb = new RequestBuilder(this.rootUrl, ApiService.AllReferralsForUserPath, 'post');
    if (params) {
      rb.body(params.body, 'application/json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<{
        'isSuccess'?: boolean;
        'message'?: string;
        'data'?: {
        'pageNumber'?: number;
        'pageSize'?: number;
        'totalRowCount'?: number;
        'items'?: Array<ReferralForUserListItem>;
        };
        }>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `allReferralsForUser$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  allReferralsForUser(params: {
    body: ReferralForUserRequest
  }): Observable<{
'isSuccess'?: boolean;
'message'?: string;
'data'?: {
'pageNumber'?: number;
'pageSize'?: number;
'totalRowCount'?: number;
'items'?: Array<ReferralForUserListItem>;
};
}> {

    return this.allReferralsForUser$Response(params).pipe(
      map((r: StrictHttpResponse<{
'isSuccess'?: boolean;
'message'?: string;
'data'?: {
'pageNumber'?: number;
'pageSize'?: number;
'totalRowCount'?: number;
'items'?: Array<ReferralForUserListItem>;
};
}>) => r.body as {
'isSuccess'?: boolean;
'message'?: string;
'data'?: {
'pageNumber'?: number;
'pageSize'?: number;
'totalRowCount'?: number;
'items'?: Array<ReferralForUserListItem>;
};
})
    );
  }

  /**
   * Path part for operation referralMaterialUpdate
   */
  static readonly ReferralMaterialUpdatePath = '/v1/referral/update/referralMaterial';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `referralMaterialUpdate()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  referralMaterialUpdate$Response(params: {
    body: EntityReferralAttachmentUpdateRequest
  }): Observable<StrictHttpResponse<{
'isSuccess'?: boolean;
'message'?: string;
'data'?: string;
}>> {

    const rb = new RequestBuilder(this.rootUrl, ApiService.ReferralMaterialUpdatePath, 'put');
    if (params) {
      rb.body(params.body, 'application/json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<{
        'isSuccess'?: boolean;
        'message'?: string;
        'data'?: string;
        }>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `referralMaterialUpdate$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  referralMaterialUpdate(params: {
    body: EntityReferralAttachmentUpdateRequest
  }): Observable<{
'isSuccess'?: boolean;
'message'?: string;
'data'?: string;
}> {

    return this.referralMaterialUpdate$Response(params).pipe(
      map((r: StrictHttpResponse<{
'isSuccess'?: boolean;
'message'?: string;
'data'?: string;
}>) => r.body as {
'isSuccess'?: boolean;
'message'?: string;
'data'?: string;
})
    );
  }

  /**
   * Path part for operation referralClosedWonReport
   */
  static readonly ReferralClosedWonReportPath = '/v1/referral/list/report/referralClosedWonStatus';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `referralClosedWonReport()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  referralClosedWonReport$Response(params: {
    body: ReferralReportFilterRequest
  }): Observable<StrictHttpResponse<{
'isSuccess'?: boolean;
'message'?: string;
'data'?: {
'pageNumber'?: number;
'pageSize'?: number;
'totalRowCount'?: number;
'items'?: Array<ReferralClosedWonReport>;
};
}>> {

    const rb = new RequestBuilder(this.rootUrl, ApiService.ReferralClosedWonReportPath, 'post');
    if (params) {
      rb.body(params.body, 'application/json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<{
        'isSuccess'?: boolean;
        'message'?: string;
        'data'?: {
        'pageNumber'?: number;
        'pageSize'?: number;
        'totalRowCount'?: number;
        'items'?: Array<ReferralClosedWonReport>;
        };
        }>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `referralClosedWonReport$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  referralClosedWonReport(params: {
    body: ReferralReportFilterRequest
  }): Observable<{
'isSuccess'?: boolean;
'message'?: string;
'data'?: {
'pageNumber'?: number;
'pageSize'?: number;
'totalRowCount'?: number;
'items'?: Array<ReferralClosedWonReport>;
};
}> {

    return this.referralClosedWonReport$Response(params).pipe(
      map((r: StrictHttpResponse<{
'isSuccess'?: boolean;
'message'?: string;
'data'?: {
'pageNumber'?: number;
'pageSize'?: number;
'totalRowCount'?: number;
'items'?: Array<ReferralClosedWonReport>;
};
}>) => r.body as {
'isSuccess'?: boolean;
'message'?: string;
'data'?: {
'pageNumber'?: number;
'pageSize'?: number;
'totalRowCount'?: number;
'items'?: Array<ReferralClosedWonReport>;
};
})
    );
  }

  /**
   * Path part for operation referralStatusAdd
   */
  static readonly ReferralStatusAddPath = '/v1/referral/add/referralStatus';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `referralStatusAdd()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  referralStatusAdd$Response(params: {
    body: ReferralStatusUpdateRequest
  }): Observable<StrictHttpResponse<{
'isSuccess'?: boolean;
'message'?: string;
'data'?: string;
}>> {

    const rb = new RequestBuilder(this.rootUrl, ApiService.ReferralStatusAddPath, 'post');
    if (params) {
      rb.body(params.body, 'application/json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<{
        'isSuccess'?: boolean;
        'message'?: string;
        'data'?: string;
        }>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `referralStatusAdd$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  referralStatusAdd(params: {
    body: ReferralStatusUpdateRequest
  }): Observable<{
'isSuccess'?: boolean;
'message'?: string;
'data'?: string;
}> {

    return this.referralStatusAdd$Response(params).pipe(
      map((r: StrictHttpResponse<{
'isSuccess'?: boolean;
'message'?: string;
'data'?: string;
}>) => r.body as {
'isSuccess'?: boolean;
'message'?: string;
'data'?: string;
})
    );
  }

  /**
   * Path part for operation referralStatusSummaryReport
   */
  static readonly ReferralStatusSummaryReportPath = '/v1/referral/list/report/referralStatusSummary';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `referralStatusSummaryReport()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  referralStatusSummaryReport$Response(params: {
    body: ReferralReportFilterRequest
  }): Observable<StrictHttpResponse<{
'isSuccess'?: boolean;
'message'?: string;
'data'?: {
'pageNumber'?: number;
'pageSize'?: number;
'totalRowCount'?: number;
'items'?: Array<ReferralStatusSummaryReport>;
};
}>> {

    const rb = new RequestBuilder(this.rootUrl, ApiService.ReferralStatusSummaryReportPath, 'post');
    if (params) {
      rb.body(params.body, 'application/json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<{
        'isSuccess'?: boolean;
        'message'?: string;
        'data'?: {
        'pageNumber'?: number;
        'pageSize'?: number;
        'totalRowCount'?: number;
        'items'?: Array<ReferralStatusSummaryReport>;
        };
        }>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `referralStatusSummaryReport$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  referralStatusSummaryReport(params: {
    body: ReferralReportFilterRequest
  }): Observable<{
'isSuccess'?: boolean;
'message'?: string;
'data'?: {
'pageNumber'?: number;
'pageSize'?: number;
'totalRowCount'?: number;
'items'?: Array<ReferralStatusSummaryReport>;
};
}> {

    return this.referralStatusSummaryReport$Response(params).pipe(
      map((r: StrictHttpResponse<{
'isSuccess'?: boolean;
'message'?: string;
'data'?: {
'pageNumber'?: number;
'pageSize'?: number;
'totalRowCount'?: number;
'items'?: Array<ReferralStatusSummaryReport>;
};
}>) => r.body as {
'isSuccess'?: boolean;
'message'?: string;
'data'?: {
'pageNumber'?: number;
'pageSize'?: number;
'totalRowCount'?: number;
'items'?: Array<ReferralStatusSummaryReport>;
};
})
    );
  }

  /**
   * Path part for operation referralCommentsAdd
   */
  static readonly ReferralCommentsAddPath = '/v1/referral/add/referralComment';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `referralCommentsAdd()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  referralCommentsAdd$Response(params: {
    body: ReferralCommentAddRequest
  }): Observable<StrictHttpResponse<{
'isSuccess'?: boolean;
'message'?: string;
'data'?: {
'pageNumber'?: number;
'pageSize'?: number;
'totalRowCount'?: number;
'items'?: Array<ReferralComments>;
};
}>> {

    const rb = new RequestBuilder(this.rootUrl, ApiService.ReferralCommentsAddPath, 'post');
    if (params) {
      rb.body(params.body, 'application/json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<{
        'isSuccess'?: boolean;
        'message'?: string;
        'data'?: {
        'pageNumber'?: number;
        'pageSize'?: number;
        'totalRowCount'?: number;
        'items'?: Array<ReferralComments>;
        };
        }>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `referralCommentsAdd$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  referralCommentsAdd(params: {
    body: ReferralCommentAddRequest
  }): Observable<{
'isSuccess'?: boolean;
'message'?: string;
'data'?: {
'pageNumber'?: number;
'pageSize'?: number;
'totalRowCount'?: number;
'items'?: Array<ReferralComments>;
};
}> {

    return this.referralCommentsAdd$Response(params).pipe(
      map((r: StrictHttpResponse<{
'isSuccess'?: boolean;
'message'?: string;
'data'?: {
'pageNumber'?: number;
'pageSize'?: number;
'totalRowCount'?: number;
'items'?: Array<ReferralComments>;
};
}>) => r.body as {
'isSuccess'?: boolean;
'message'?: string;
'data'?: {
'pageNumber'?: number;
'pageSize'?: number;
'totalRowCount'?: number;
'items'?: Array<ReferralComments>;
};
})
    );
  }

  /**
   * Path part for operation allReferralComments
   */
  static readonly AllReferralCommentsPath = '/v1/referral/list/comment/referralComments';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `allReferralComments()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  allReferralComments$Response(params: {
    body: ReferralCommentRequest
  }): Observable<StrictHttpResponse<{
'isSuccess'?: boolean;
'message'?: string;
'data'?: {
'pageNumber'?: number;
'pageSize'?: number;
'totalRowCount'?: number;
'items'?: Array<ReferralComments>;
};
}>> {

    const rb = new RequestBuilder(this.rootUrl, ApiService.AllReferralCommentsPath, 'post');
    if (params) {
      rb.body(params.body, 'application/json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<{
        'isSuccess'?: boolean;
        'message'?: string;
        'data'?: {
        'pageNumber'?: number;
        'pageSize'?: number;
        'totalRowCount'?: number;
        'items'?: Array<ReferralComments>;
        };
        }>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `allReferralComments$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  allReferralComments(params: {
    body: ReferralCommentRequest
  }): Observable<{
'isSuccess'?: boolean;
'message'?: string;
'data'?: {
'pageNumber'?: number;
'pageSize'?: number;
'totalRowCount'?: number;
'items'?: Array<ReferralComments>;
};
}> {

    return this.allReferralComments$Response(params).pipe(
      map((r: StrictHttpResponse<{
'isSuccess'?: boolean;
'message'?: string;
'data'?: {
'pageNumber'?: number;
'pageSize'?: number;
'totalRowCount'?: number;
'items'?: Array<ReferralComments>;
};
}>) => r.body as {
'isSuccess'?: boolean;
'message'?: string;
'data'?: {
'pageNumber'?: number;
'pageSize'?: number;
'totalRowCount'?: number;
'items'?: Array<ReferralComments>;
};
})
    );
  }

  /**
   * Path part for operation referralMaterialAdd
   */
  static readonly ReferralMaterialAddPath = '/v1/referral/add/referral/material';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `referralMaterialAdd()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  referralMaterialAdd$Response(params: {
    body: ReferralMaterialAddRequest
  }): Observable<StrictHttpResponse<{
'isSuccess'?: boolean;
'message'?: string;
'data'?: string;
}>> {

    const rb = new RequestBuilder(this.rootUrl, ApiService.ReferralMaterialAddPath, 'post');
    if (params) {
      rb.body(params.body, 'application/json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<{
        'isSuccess'?: boolean;
        'message'?: string;
        'data'?: string;
        }>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `referralMaterialAdd$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  referralMaterialAdd(params: {
    body: ReferralMaterialAddRequest
  }): Observable<{
'isSuccess'?: boolean;
'message'?: string;
'data'?: string;
}> {

    return this.referralMaterialAdd$Response(params).pipe(
      map((r: StrictHttpResponse<{
'isSuccess'?: boolean;
'message'?: string;
'data'?: string;
}>) => r.body as {
'isSuccess'?: boolean;
'message'?: string;
'data'?: string;
})
    );
  }

  /**
   * Path part for operation referralMaterialList
   */
  static readonly ReferralMaterialListPath = '/v1/referral/list/referral/materials';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `referralMaterialList()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  referralMaterialList$Response(params: {
    body: FetchReferralAttachmentRequest
  }): Observable<StrictHttpResponse<{
'isSuccess'?: boolean;
'message'?: string;
'data'?: {
'pageNumber'?: number;
'pageSize'?: number;
'totalRowCount'?: number;
'items'?: Array<ReferralMaterialItem>;
};
}>> {

    const rb = new RequestBuilder(this.rootUrl, ApiService.ReferralMaterialListPath, 'post');
    if (params) {
      rb.body(params.body, 'application/json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<{
        'isSuccess'?: boolean;
        'message'?: string;
        'data'?: {
        'pageNumber'?: number;
        'pageSize'?: number;
        'totalRowCount'?: number;
        'items'?: Array<ReferralMaterialItem>;
        };
        }>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `referralMaterialList$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  referralMaterialList(params: {
    body: FetchReferralAttachmentRequest
  }): Observable<{
'isSuccess'?: boolean;
'message'?: string;
'data'?: {
'pageNumber'?: number;
'pageSize'?: number;
'totalRowCount'?: number;
'items'?: Array<ReferralMaterialItem>;
};
}> {

    return this.referralMaterialList$Response(params).pipe(
      map((r: StrictHttpResponse<{
'isSuccess'?: boolean;
'message'?: string;
'data'?: {
'pageNumber'?: number;
'pageSize'?: number;
'totalRowCount'?: number;
'items'?: Array<ReferralMaterialItem>;
};
}>) => r.body as {
'isSuccess'?: boolean;
'message'?: string;
'data'?: {
'pageNumber'?: number;
'pageSize'?: number;
'totalRowCount'?: number;
'items'?: Array<ReferralMaterialItem>;
};
})
    );
  }

  /**
   * Path part for operation employeeReferralAdd
   */
  static readonly EmployeeReferralAddPath = '/v1/referral/add/employeeReferral';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `employeeReferralAdd()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  employeeReferralAdd$Response(params: {
    body: EmployeeReferralRequest
  }): Observable<StrictHttpResponse<{
'isSuccess'?: boolean;
'message'?: string;
'data'?: string;
}>> {

    const rb = new RequestBuilder(this.rootUrl, ApiService.EmployeeReferralAddPath, 'post');
    if (params) {
      rb.body(params.body, 'application/json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<{
        'isSuccess'?: boolean;
        'message'?: string;
        'data'?: string;
        }>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `employeeReferralAdd$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  employeeReferralAdd(params: {
    body: EmployeeReferralRequest
  }): Observable<{
'isSuccess'?: boolean;
'message'?: string;
'data'?: string;
}> {

    return this.employeeReferralAdd$Response(params).pipe(
      map((r: StrictHttpResponse<{
'isSuccess'?: boolean;
'message'?: string;
'data'?: string;
}>) => r.body as {
'isSuccess'?: boolean;
'message'?: string;
'data'?: string;
})
    );
  }

  /**
   * Path part for operation employeeReferralList
   */
  static readonly EmployeeReferralListPath = '/v1/referral/list/employee';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `employeeReferralList()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  employeeReferralList$Response(params: {
    body: FetchReferralRequest
  }): Observable<StrictHttpResponse<{
'isSuccess'?: boolean;
'message'?: string;
'data'?: {
'pageNumber'?: number;
'pageSize'?: number;
'totalRowCount'?: number;
'items'?: Array<EmployeeReferralItem>;
};
}>> {

    const rb = new RequestBuilder(this.rootUrl, ApiService.EmployeeReferralListPath, 'post');
    if (params) {
      rb.body(params.body, 'application/json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<{
        'isSuccess'?: boolean;
        'message'?: string;
        'data'?: {
        'pageNumber'?: number;
        'pageSize'?: number;
        'totalRowCount'?: number;
        'items'?: Array<EmployeeReferralItem>;
        };
        }>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `employeeReferralList$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  employeeReferralList(params: {
    body: FetchReferralRequest
  }): Observable<{
'isSuccess'?: boolean;
'message'?: string;
'data'?: {
'pageNumber'?: number;
'pageSize'?: number;
'totalRowCount'?: number;
'items'?: Array<EmployeeReferralItem>;
};
}> {

    return this.employeeReferralList$Response(params).pipe(
      map((r: StrictHttpResponse<{
'isSuccess'?: boolean;
'message'?: string;
'data'?: {
'pageNumber'?: number;
'pageSize'?: number;
'totalRowCount'?: number;
'items'?: Array<EmployeeReferralItem>;
};
}>) => r.body as {
'isSuccess'?: boolean;
'message'?: string;
'data'?: {
'pageNumber'?: number;
'pageSize'?: number;
'totalRowCount'?: number;
'items'?: Array<EmployeeReferralItem>;
};
})
    );
  }

  /**
   * Path part for operation referralIncentiveConfigList
   */
  static readonly ReferralIncentiveConfigListPath = '/v1/referral/list/incentiveConfigList';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `referralIncentiveConfigList()` instead.
   *
   * This method doesn't expect any request body.
   */
  referralIncentiveConfigList$Response(params?: {
  }): Observable<StrictHttpResponse<{
'isSuccess'?: boolean;
'message'?: string;
'data'?: {
'pageNumber'?: number;
'pageSize'?: number;
'totalRowCount'?: number;
'items'?: Array<ReferralIncentiveConfigItem>;
};
}>> {

    const rb = new RequestBuilder(this.rootUrl, ApiService.ReferralIncentiveConfigListPath, 'post');
    if (params) {
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<{
        'isSuccess'?: boolean;
        'message'?: string;
        'data'?: {
        'pageNumber'?: number;
        'pageSize'?: number;
        'totalRowCount'?: number;
        'items'?: Array<ReferralIncentiveConfigItem>;
        };
        }>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `referralIncentiveConfigList$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  referralIncentiveConfigList(params?: {
  }): Observable<{
'isSuccess'?: boolean;
'message'?: string;
'data'?: {
'pageNumber'?: number;
'pageSize'?: number;
'totalRowCount'?: number;
'items'?: Array<ReferralIncentiveConfigItem>;
};
}> {

    return this.referralIncentiveConfigList$Response(params).pipe(
      map((r: StrictHttpResponse<{
'isSuccess'?: boolean;
'message'?: string;
'data'?: {
'pageNumber'?: number;
'pageSize'?: number;
'totalRowCount'?: number;
'items'?: Array<ReferralIncentiveConfigItem>;
};
}>) => r.body as {
'isSuccess'?: boolean;
'message'?: string;
'data'?: {
'pageNumber'?: number;
'pageSize'?: number;
'totalRowCount'?: number;
'items'?: Array<ReferralIncentiveConfigItem>;
};
})
    );
  }

  /**
   * Path part for operation fetchReferralComments
   */
  static readonly FetchReferralCommentsPath = '/v1/referral/list/comment/fetchReferralComments';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `fetchReferralComments()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  fetchReferralComments$Response(params: {
    body: ReferralByIdCommentRequest
  }): Observable<StrictHttpResponse<{
'isSuccess'?: boolean;
'message'?: string;
'data'?: {
'pageNumber'?: number;
'pageSize'?: number;
'totalRowCount'?: number;
'items'?: Array<ReferralComments>;
};
}>> {

    const rb = new RequestBuilder(this.rootUrl, ApiService.FetchReferralCommentsPath, 'post');
    if (params) {
      rb.body(params.body, 'application/json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<{
        'isSuccess'?: boolean;
        'message'?: string;
        'data'?: {
        'pageNumber'?: number;
        'pageSize'?: number;
        'totalRowCount'?: number;
        'items'?: Array<ReferralComments>;
        };
        }>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `fetchReferralComments$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  fetchReferralComments(params: {
    body: ReferralByIdCommentRequest
  }): Observable<{
'isSuccess'?: boolean;
'message'?: string;
'data'?: {
'pageNumber'?: number;
'pageSize'?: number;
'totalRowCount'?: number;
'items'?: Array<ReferralComments>;
};
}> {

    return this.fetchReferralComments$Response(params).pipe(
      map((r: StrictHttpResponse<{
'isSuccess'?: boolean;
'message'?: string;
'data'?: {
'pageNumber'?: number;
'pageSize'?: number;
'totalRowCount'?: number;
'items'?: Array<ReferralComments>;
};
}>) => r.body as {
'isSuccess'?: boolean;
'message'?: string;
'data'?: {
'pageNumber'?: number;
'pageSize'?: number;
'totalRowCount'?: number;
'items'?: Array<ReferralComments>;
};
})
    );
  }

  /**
   * Path part for operation dashboardIncentives
   */
  static readonly DashboardIncentivesPath = '/v1/referral/list/dashboardIncentives';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `dashboardIncentives()` instead.
   *
   * This method doesn't expect any request body.
   */
  dashboardIncentives$Response(params?: {
  }): Observable<StrictHttpResponse<{
'isSuccess'?: boolean;
'message'?: string;
'data'?: {
'pageNumber'?: number;
'pageSize'?: number;
'totalRowCount'?: number;
'items'?: Array<ReferralForUserListItem>;
};
}>> {

    const rb = new RequestBuilder(this.rootUrl, ApiService.DashboardIncentivesPath, 'post');
    if (params) {
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<{
        'isSuccess'?: boolean;
        'message'?: string;
        'data'?: {
        'pageNumber'?: number;
        'pageSize'?: number;
        'totalRowCount'?: number;
        'items'?: Array<ReferralForUserListItem>;
        };
        }>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `dashboardIncentives$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  dashboardIncentives(params?: {
  }): Observable<{
'isSuccess'?: boolean;
'message'?: string;
'data'?: {
'pageNumber'?: number;
'pageSize'?: number;
'totalRowCount'?: number;
'items'?: Array<ReferralForUserListItem>;
};
}> {

    return this.dashboardIncentives$Response(params).pipe(
      map((r: StrictHttpResponse<{
'isSuccess'?: boolean;
'message'?: string;
'data'?: {
'pageNumber'?: number;
'pageSize'?: number;
'totalRowCount'?: number;
'items'?: Array<ReferralForUserListItem>;
};
}>) => r.body as {
'isSuccess'?: boolean;
'message'?: string;
'data'?: {
'pageNumber'?: number;
'pageSize'?: number;
'totalRowCount'?: number;
'items'?: Array<ReferralForUserListItem>;
};
})
    );
  }

  /**
   * Path part for operation referralIncentiveCriteriaList
   */
  static readonly ReferralIncentiveCriteriaListPath = '/v1/referral/list/referralIncentiveCriteria';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `referralIncentiveCriteriaList()` instead.
   *
   * This method doesn't expect any request body.
   */
  referralIncentiveCriteriaList$Response(params?: {
  }): Observable<StrictHttpResponse<{
'isSuccess'?: boolean;
'message'?: string;
'data'?: {
'pageNumber'?: number;
'pageSize'?: number;
'totalRowCount'?: number;
'items'?: Array<ReferralIncentiveCriteriaItem>;
};
}>> {

    const rb = new RequestBuilder(this.rootUrl, ApiService.ReferralIncentiveCriteriaListPath, 'post');
    if (params) {
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<{
        'isSuccess'?: boolean;
        'message'?: string;
        'data'?: {
        'pageNumber'?: number;
        'pageSize'?: number;
        'totalRowCount'?: number;
        'items'?: Array<ReferralIncentiveCriteriaItem>;
        };
        }>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `referralIncentiveCriteriaList$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  referralIncentiveCriteriaList(params?: {
  }): Observable<{
'isSuccess'?: boolean;
'message'?: string;
'data'?: {
'pageNumber'?: number;
'pageSize'?: number;
'totalRowCount'?: number;
'items'?: Array<ReferralIncentiveCriteriaItem>;
};
}> {

    return this.referralIncentiveCriteriaList$Response(params).pipe(
      map((r: StrictHttpResponse<{
'isSuccess'?: boolean;
'message'?: string;
'data'?: {
'pageNumber'?: number;
'pageSize'?: number;
'totalRowCount'?: number;
'items'?: Array<ReferralIncentiveCriteriaItem>;
};
}>) => r.body as {
'isSuccess'?: boolean;
'message'?: string;
'data'?: {
'pageNumber'?: number;
'pageSize'?: number;
'totalRowCount'?: number;
'items'?: Array<ReferralIncentiveCriteriaItem>;
};
})
    );
  }

  /**
   * Path part for operation referralIncentiveTypeList
   */
  static readonly ReferralIncentiveTypeListPath = '/v1/referral/list/referralIncentiveType';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `referralIncentiveTypeList()` instead.
   *
   * This method doesn't expect any request body.
   */
  referralIncentiveTypeList$Response(params?: {
  }): Observable<StrictHttpResponse<{
'isSuccess'?: boolean;
'message'?: string;
'data'?: {
'pageNumber'?: number;
'pageSize'?: number;
'totalRowCount'?: number;
'items'?: Array<ReferralIncentiveTypeItem>;
};
}>> {

    const rb = new RequestBuilder(this.rootUrl, ApiService.ReferralIncentiveTypeListPath, 'post');
    if (params) {
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<{
        'isSuccess'?: boolean;
        'message'?: string;
        'data'?: {
        'pageNumber'?: number;
        'pageSize'?: number;
        'totalRowCount'?: number;
        'items'?: Array<ReferralIncentiveTypeItem>;
        };
        }>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `referralIncentiveTypeList$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  referralIncentiveTypeList(params?: {
  }): Observable<{
'isSuccess'?: boolean;
'message'?: string;
'data'?: {
'pageNumber'?: number;
'pageSize'?: number;
'totalRowCount'?: number;
'items'?: Array<ReferralIncentiveTypeItem>;
};
}> {

    return this.referralIncentiveTypeList$Response(params).pipe(
      map((r: StrictHttpResponse<{
'isSuccess'?: boolean;
'message'?: string;
'data'?: {
'pageNumber'?: number;
'pageSize'?: number;
'totalRowCount'?: number;
'items'?: Array<ReferralIncentiveTypeItem>;
};
}>) => r.body as {
'isSuccess'?: boolean;
'message'?: string;
'data'?: {
'pageNumber'?: number;
'pageSize'?: number;
'totalRowCount'?: number;
'items'?: Array<ReferralIncentiveTypeItem>;
};
})
    );
  }

  /**
   * Path part for operation referralTypeList
   */
  static readonly ReferralTypeListPath = '/v1/referral/list/referralType';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `referralTypeList()` instead.
   *
   * This method doesn't expect any request body.
   */
  referralTypeList$Response(params?: {
  }): Observable<StrictHttpResponse<{
'isSuccess'?: boolean;
'message'?: string;
'data'?: {
'pageNumber'?: number;
'pageSize'?: number;
'totalRowCount'?: number;
'items'?: Array<ReferralTypeItem>;
};
}>> {

    const rb = new RequestBuilder(this.rootUrl, ApiService.ReferralTypeListPath, 'post');
    if (params) {
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<{
        'isSuccess'?: boolean;
        'message'?: string;
        'data'?: {
        'pageNumber'?: number;
        'pageSize'?: number;
        'totalRowCount'?: number;
        'items'?: Array<ReferralTypeItem>;
        };
        }>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `referralTypeList$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  referralTypeList(params?: {
  }): Observable<{
'isSuccess'?: boolean;
'message'?: string;
'data'?: {
'pageNumber'?: number;
'pageSize'?: number;
'totalRowCount'?: number;
'items'?: Array<ReferralTypeItem>;
};
}> {

    return this.referralTypeList$Response(params).pipe(
      map((r: StrictHttpResponse<{
'isSuccess'?: boolean;
'message'?: string;
'data'?: {
'pageNumber'?: number;
'pageSize'?: number;
'totalRowCount'?: number;
'items'?: Array<ReferralTypeItem>;
};
}>) => r.body as {
'isSuccess'?: boolean;
'message'?: string;
'data'?: {
'pageNumber'?: number;
'pageSize'?: number;
'totalRowCount'?: number;
'items'?: Array<ReferralTypeItem>;
};
})
    );
  }

  /**
   * Path part for operation incentiveAdd
   */
  static readonly IncentiveAddPath = '/v1/referral/add/incentive';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `incentiveAdd()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  incentiveAdd$Response(params: {
    body: ConfigureIncentiveRequestList
  }): Observable<StrictHttpResponse<{
'isSuccess'?: boolean;
'message'?: string;
'data'?: string;
}>> {

    const rb = new RequestBuilder(this.rootUrl, ApiService.IncentiveAddPath, 'post');
    if (params) {
      rb.body(params.body, 'application/json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<{
        'isSuccess'?: boolean;
        'message'?: string;
        'data'?: string;
        }>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `incentiveAdd$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  incentiveAdd(params: {
    body: ConfigureIncentiveRequestList
  }): Observable<{
'isSuccess'?: boolean;
'message'?: string;
'data'?: string;
}> {

    return this.incentiveAdd$Response(params).pipe(
      map((r: StrictHttpResponse<{
'isSuccess'?: boolean;
'message'?: string;
'data'?: string;
}>) => r.body as {
'isSuccess'?: boolean;
'message'?: string;
'data'?: string;
})
    );
  }

  /**
   * Path part for operation countryList
   */
  static readonly CountryListPath = '/v1/referral/list/country';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `countryList()` instead.
   *
   * This method doesn't expect any request body.
   */
  countryList$Response(params?: {
  }): Observable<StrictHttpResponse<{
'isSuccess'?: boolean;
'message'?: string;
'data'?: {
'pageNumber'?: number;
'pageSize'?: number;
'totalRowCount'?: number;
'items'?: Array<CountryList>;
};
}>> {

    const rb = new RequestBuilder(this.rootUrl, ApiService.CountryListPath, 'post');
    if (params) {
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<{
        'isSuccess'?: boolean;
        'message'?: string;
        'data'?: {
        'pageNumber'?: number;
        'pageSize'?: number;
        'totalRowCount'?: number;
        'items'?: Array<CountryList>;
        };
        }>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `countryList$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  countryList(params?: {
  }): Observable<{
'isSuccess'?: boolean;
'message'?: string;
'data'?: {
'pageNumber'?: number;
'pageSize'?: number;
'totalRowCount'?: number;
'items'?: Array<CountryList>;
};
}> {

    return this.countryList$Response(params).pipe(
      map((r: StrictHttpResponse<{
'isSuccess'?: boolean;
'message'?: string;
'data'?: {
'pageNumber'?: number;
'pageSize'?: number;
'totalRowCount'?: number;
'items'?: Array<CountryList>;
};
}>) => r.body as {
'isSuccess'?: boolean;
'message'?: string;
'data'?: {
'pageNumber'?: number;
'pageSize'?: number;
'totalRowCount'?: number;
'items'?: Array<CountryList>;
};
})
    );
  }

  /**
   * Path part for operation stateList
   */
  static readonly StateListPath = '/v1/referral/list/state';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `stateList()` instead.
   *
   * This method doesn't expect any request body.
   */
  stateList$Response(params?: {
  }): Observable<StrictHttpResponse<{
'isSuccess'?: boolean;
'message'?: string;
'data'?: {
'pageNumber'?: number;
'pageSize'?: number;
'totalRowCount'?: number;
'items'?: Array<StateList>;
};
}>> {

    const rb = new RequestBuilder(this.rootUrl, ApiService.StateListPath, 'post');
    if (params) {
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<{
        'isSuccess'?: boolean;
        'message'?: string;
        'data'?: {
        'pageNumber'?: number;
        'pageSize'?: number;
        'totalRowCount'?: number;
        'items'?: Array<StateList>;
        };
        }>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `stateList$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  stateList(params?: {
  }): Observable<{
'isSuccess'?: boolean;
'message'?: string;
'data'?: {
'pageNumber'?: number;
'pageSize'?: number;
'totalRowCount'?: number;
'items'?: Array<StateList>;
};
}> {

    return this.stateList$Response(params).pipe(
      map((r: StrictHttpResponse<{
'isSuccess'?: boolean;
'message'?: string;
'data'?: {
'pageNumber'?: number;
'pageSize'?: number;
'totalRowCount'?: number;
'items'?: Array<StateList>;
};
}>) => r.body as {
'isSuccess'?: boolean;
'message'?: string;
'data'?: {
'pageNumber'?: number;
'pageSize'?: number;
'totalRowCount'?: number;
'items'?: Array<StateList>;
};
})
    );
  }

  /**
   * Path part for operation internationalPrivateClientReferralAdd
   */
  static readonly InternationalPrivateClientReferralAddPath = '/v1/referral/add/internationalPrivateClientReferral';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `internationalPrivateClientReferralAdd()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  internationalPrivateClientReferralAdd$Response(params: {
    body: InternationalPrivateClientReferralRequest
  }): Observable<StrictHttpResponse<{
'isSuccess'?: boolean;
'message'?: string;
'data'?: string;
}>> {

    const rb = new RequestBuilder(this.rootUrl, ApiService.InternationalPrivateClientReferralAddPath, 'post');
    if (params) {
      rb.body(params.body, 'application/json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<{
        'isSuccess'?: boolean;
        'message'?: string;
        'data'?: string;
        }>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `internationalPrivateClientReferralAdd$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  internationalPrivateClientReferralAdd(params: {
    body: InternationalPrivateClientReferralRequest
  }): Observable<{
'isSuccess'?: boolean;
'message'?: string;
'data'?: string;
}> {

    return this.internationalPrivateClientReferralAdd$Response(params).pipe(
      map((r: StrictHttpResponse<{
'isSuccess'?: boolean;
'message'?: string;
'data'?: string;
}>) => r.body as {
'isSuccess'?: boolean;
'message'?: string;
'data'?: string;
})
    );
  }

  /**
   * Path part for operation internationalPrivateClientList
   */
  static readonly InternationalPrivateClientListPath = '/v1/referral/list/internationalPrivateClient';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `internationalPrivateClientList()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  internationalPrivateClientList$Response(params: {
    body: FetchReferralRequest
  }): Observable<StrictHttpResponse<{
'isSuccess'?: boolean;
'message'?: string;
'data'?: {
'pageNumber'?: number;
'pageSize'?: number;
'totalRowCount'?: number;
'items'?: Array<InternationalPrivateClientReferralItem>;
};
}>> {

    const rb = new RequestBuilder(this.rootUrl, ApiService.InternationalPrivateClientListPath, 'post');
    if (params) {
      rb.body(params.body, 'application/json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<{
        'isSuccess'?: boolean;
        'message'?: string;
        'data'?: {
        'pageNumber'?: number;
        'pageSize'?: number;
        'totalRowCount'?: number;
        'items'?: Array<InternationalPrivateClientReferralItem>;
        };
        }>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `internationalPrivateClientList$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  internationalPrivateClientList(params: {
    body: FetchReferralRequest
  }): Observable<{
'isSuccess'?: boolean;
'message'?: string;
'data'?: {
'pageNumber'?: number;
'pageSize'?: number;
'totalRowCount'?: number;
'items'?: Array<InternationalPrivateClientReferralItem>;
};
}> {

    return this.internationalPrivateClientList$Response(params).pipe(
      map((r: StrictHttpResponse<{
'isSuccess'?: boolean;
'message'?: string;
'data'?: {
'pageNumber'?: number;
'pageSize'?: number;
'totalRowCount'?: number;
'items'?: Array<InternationalPrivateClientReferralItem>;
};
}>) => r.body as {
'isSuccess'?: boolean;
'message'?: string;
'data'?: {
'pageNumber'?: number;
'pageSize'?: number;
'totalRowCount'?: number;
'items'?: Array<InternationalPrivateClientReferralItem>;
};
})
    );
  }

  /**
   * Path part for operation creditCardAdd
   */
  static readonly CreditCardAddPath = '/v1/referral/add/creditCard';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `creditCardAdd()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  creditCardAdd$Response(params: {
    body: CreditCardReferralRequest
  }): Observable<StrictHttpResponse<{
'isSuccess'?: boolean;
'message'?: string;
'data'?: string;
}>> {

    const rb = new RequestBuilder(this.rootUrl, ApiService.CreditCardAddPath, 'post');
    if (params) {
      rb.body(params.body, 'application/json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<{
        'isSuccess'?: boolean;
        'message'?: string;
        'data'?: string;
        }>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `creditCardAdd$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  creditCardAdd(params: {
    body: CreditCardReferralRequest
  }): Observable<{
'isSuccess'?: boolean;
'message'?: string;
'data'?: string;
}> {

    return this.creditCardAdd$Response(params).pipe(
      map((r: StrictHttpResponse<{
'isSuccess'?: boolean;
'message'?: string;
'data'?: string;
}>) => r.body as {
'isSuccess'?: boolean;
'message'?: string;
'data'?: string;
})
    );
  }

  /**
   * Path part for operation creditCardList
   */
  static readonly CreditCardListPath = '/v1/referral/list/creditCard';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `creditCardList()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  creditCardList$Response(params: {
    body: FetchReferralRequest
  }): Observable<StrictHttpResponse<{
'isSuccess'?: boolean;
'message'?: string;
'data'?: {
'pageNumber'?: number;
'pageSize'?: number;
'totalRowCount'?: number;
'items'?: Array<CreditCardReferralItem>;
};
}>> {

    const rb = new RequestBuilder(this.rootUrl, ApiService.CreditCardListPath, 'post');
    if (params) {
      rb.body(params.body, 'application/json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<{
        'isSuccess'?: boolean;
        'message'?: string;
        'data'?: {
        'pageNumber'?: number;
        'pageSize'?: number;
        'totalRowCount'?: number;
        'items'?: Array<CreditCardReferralItem>;
        };
        }>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `creditCardList$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  creditCardList(params: {
    body: FetchReferralRequest
  }): Observable<{
'isSuccess'?: boolean;
'message'?: string;
'data'?: {
'pageNumber'?: number;
'pageSize'?: number;
'totalRowCount'?: number;
'items'?: Array<CreditCardReferralItem>;
};
}> {

    return this.creditCardList$Response(params).pipe(
      map((r: StrictHttpResponse<{
'isSuccess'?: boolean;
'message'?: string;
'data'?: {
'pageNumber'?: number;
'pageSize'?: number;
'totalRowCount'?: number;
'items'?: Array<CreditCardReferralItem>;
};
}>) => r.body as {
'isSuccess'?: boolean;
'message'?: string;
'data'?: {
'pageNumber'?: number;
'pageSize'?: number;
'totalRowCount'?: number;
'items'?: Array<CreditCardReferralItem>;
};
})
    );
  }

  /**
   * Path part for operation generateReferralRegionalReport
   */
  static readonly GenerateReferralRegionalReportPath = '/v1/referral/list/regionalReport';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `generateReferralRegionalReport()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  generateReferralRegionalReport$Response(params: {
    body: ReferralRegionalReportRequest
  }): Observable<StrictHttpResponse<{
'isSuccess'?: boolean;
'message'?: string;
'data'?: {
'pageNumber'?: number;
'pageSize'?: number;
'totalRowCount'?: number;
'items'?: Array<ReferralRegionalReportRecord>;
};
}>> {

    const rb = new RequestBuilder(this.rootUrl, ApiService.GenerateReferralRegionalReportPath, 'post');
    if (params) {
      rb.body(params.body, 'application/json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<{
        'isSuccess'?: boolean;
        'message'?: string;
        'data'?: {
        'pageNumber'?: number;
        'pageSize'?: number;
        'totalRowCount'?: number;
        'items'?: Array<ReferralRegionalReportRecord>;
        };
        }>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `generateReferralRegionalReport$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  generateReferralRegionalReport(params: {
    body: ReferralRegionalReportRequest
  }): Observable<{
'isSuccess'?: boolean;
'message'?: string;
'data'?: {
'pageNumber'?: number;
'pageSize'?: number;
'totalRowCount'?: number;
'items'?: Array<ReferralRegionalReportRecord>;
};
}> {

    return this.generateReferralRegionalReport$Response(params).pipe(
      map((r: StrictHttpResponse<{
'isSuccess'?: boolean;
'message'?: string;
'data'?: {
'pageNumber'?: number;
'pageSize'?: number;
'totalRowCount'?: number;
'items'?: Array<ReferralRegionalReportRecord>;
};
}>) => r.body as {
'isSuccess'?: boolean;
'message'?: string;
'data'?: {
'pageNumber'?: number;
'pageSize'?: number;
'totalRowCount'?: number;
'items'?: Array<ReferralRegionalReportRecord>;
};
})
    );
  }

  /**
   * Path part for operation domesticPrivateClientAdd
   */
  static readonly DomesticPrivateClientAddPath = '/v1/referral/add/domesticPrivateClient';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `domesticPrivateClientAdd()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  domesticPrivateClientAdd$Response(params: {
    body: DomesticPrivateClientReferralRequest
  }): Observable<StrictHttpResponse<{
'isSuccess'?: boolean;
'message'?: string;
'data'?: string;
}>> {

    const rb = new RequestBuilder(this.rootUrl, ApiService.DomesticPrivateClientAddPath, 'post');
    if (params) {
      rb.body(params.body, 'application/json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<{
        'isSuccess'?: boolean;
        'message'?: string;
        'data'?: string;
        }>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `domesticPrivateClientAdd$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  domesticPrivateClientAdd(params: {
    body: DomesticPrivateClientReferralRequest
  }): Observable<{
'isSuccess'?: boolean;
'message'?: string;
'data'?: string;
}> {

    return this.domesticPrivateClientAdd$Response(params).pipe(
      map((r: StrictHttpResponse<{
'isSuccess'?: boolean;
'message'?: string;
'data'?: string;
}>) => r.body as {
'isSuccess'?: boolean;
'message'?: string;
'data'?: string;
})
    );
  }

  /**
   * Path part for operation domesticPrivateClientList
   */
  static readonly DomesticPrivateClientListPath = '/v1/referral/list/domesticPrivateClient';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `domesticPrivateClientList()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  domesticPrivateClientList$Response(params: {
    body: FetchReferralRequest
  }): Observable<StrictHttpResponse<{
'isSuccess'?: boolean;
'message'?: string;
'data'?: {
'pageNumber'?: number;
'pageSize'?: number;
'totalRowCount'?: number;
'items'?: Array<DomesticPrivateClientReferralItem>;
};
}>> {

    const rb = new RequestBuilder(this.rootUrl, ApiService.DomesticPrivateClientListPath, 'post');
    if (params) {
      rb.body(params.body, 'application/json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<{
        'isSuccess'?: boolean;
        'message'?: string;
        'data'?: {
        'pageNumber'?: number;
        'pageSize'?: number;
        'totalRowCount'?: number;
        'items'?: Array<DomesticPrivateClientReferralItem>;
        };
        }>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `domesticPrivateClientList$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  domesticPrivateClientList(params: {
    body: FetchReferralRequest
  }): Observable<{
'isSuccess'?: boolean;
'message'?: string;
'data'?: {
'pageNumber'?: number;
'pageSize'?: number;
'totalRowCount'?: number;
'items'?: Array<DomesticPrivateClientReferralItem>;
};
}> {

    return this.domesticPrivateClientList$Response(params).pipe(
      map((r: StrictHttpResponse<{
'isSuccess'?: boolean;
'message'?: string;
'data'?: {
'pageNumber'?: number;
'pageSize'?: number;
'totalRowCount'?: number;
'items'?: Array<DomesticPrivateClientReferralItem>;
};
}>) => r.body as {
'isSuccess'?: boolean;
'message'?: string;
'data'?: {
'pageNumber'?: number;
'pageSize'?: number;
'totalRowCount'?: number;
'items'?: Array<DomesticPrivateClientReferralItem>;
};
})
    );
  }

  /**
   * Path part for operation depositAccountAdd
   */
  static readonly DepositAccountAddPath = '/v1/referral/add/depositAccount';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `depositAccountAdd()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  depositAccountAdd$Response(params: {
    body: DepositAccountReferralRequest
  }): Observable<StrictHttpResponse<{
'isSuccess'?: boolean;
'message'?: string;
'data'?: string;
}>> {

    const rb = new RequestBuilder(this.rootUrl, ApiService.DepositAccountAddPath, 'post');
    if (params) {
      rb.body(params.body, 'application/json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<{
        'isSuccess'?: boolean;
        'message'?: string;
        'data'?: string;
        }>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `depositAccountAdd$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  depositAccountAdd(params: {
    body: DepositAccountReferralRequest
  }): Observable<{
'isSuccess'?: boolean;
'message'?: string;
'data'?: string;
}> {

    return this.depositAccountAdd$Response(params).pipe(
      map((r: StrictHttpResponse<{
'isSuccess'?: boolean;
'message'?: string;
'data'?: string;
}>) => r.body as {
'isSuccess'?: boolean;
'message'?: string;
'data'?: string;
})
    );
  }

  /**
   * Path part for operation depositAccountList
   */
  static readonly DepositAccountListPath = '/v1/referral/list/depositAccount';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `depositAccountList()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  depositAccountList$Response(params: {
    body: FetchReferralRequest
  }): Observable<StrictHttpResponse<{
'isSuccess'?: boolean;
'message'?: string;
'data'?: {
'pageNumber'?: number;
'pageSize'?: number;
'totalRowCount'?: number;
'items'?: Array<DepositAccountReferralItem>;
};
}>> {

    const rb = new RequestBuilder(this.rootUrl, ApiService.DepositAccountListPath, 'post');
    if (params) {
      rb.body(params.body, 'application/json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<{
        'isSuccess'?: boolean;
        'message'?: string;
        'data'?: {
        'pageNumber'?: number;
        'pageSize'?: number;
        'totalRowCount'?: number;
        'items'?: Array<DepositAccountReferralItem>;
        };
        }>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `depositAccountList$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  depositAccountList(params: {
    body: FetchReferralRequest
  }): Observable<{
'isSuccess'?: boolean;
'message'?: string;
'data'?: {
'pageNumber'?: number;
'pageSize'?: number;
'totalRowCount'?: number;
'items'?: Array<DepositAccountReferralItem>;
};
}> {

    return this.depositAccountList$Response(params).pipe(
      map((r: StrictHttpResponse<{
'isSuccess'?: boolean;
'message'?: string;
'data'?: {
'pageNumber'?: number;
'pageSize'?: number;
'totalRowCount'?: number;
'items'?: Array<DepositAccountReferralItem>;
};
}>) => r.body as {
'isSuccess'?: boolean;
'message'?: string;
'data'?: {
'pageNumber'?: number;
'pageSize'?: number;
'totalRowCount'?: number;
'items'?: Array<DepositAccountReferralItem>;
};
})
    );
  }

  /**
   * Path part for operation consumerOrCommercialList
   */
  static readonly ConsumerOrCommercialListPath = '/v1/referral/list/consumerOrCommercial';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `consumerOrCommercialList()` instead.
   *
   * This method doesn't expect any request body.
   */
  consumerOrCommercialList$Response(params?: {
  }): Observable<StrictHttpResponse<{
'isSuccess'?: boolean;
'message'?: string;
'data'?: {
'pageNumber'?: number;
'pageSize'?: number;
'totalRowCount'?: number;
'items'?: Array<ConsumerOrCommercial>;
};
}>> {

    const rb = new RequestBuilder(this.rootUrl, ApiService.ConsumerOrCommercialListPath, 'post');
    if (params) {
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<{
        'isSuccess'?: boolean;
        'message'?: string;
        'data'?: {
        'pageNumber'?: number;
        'pageSize'?: number;
        'totalRowCount'?: number;
        'items'?: Array<ConsumerOrCommercial>;
        };
        }>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `consumerOrCommercialList$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  consumerOrCommercialList(params?: {
  }): Observable<{
'isSuccess'?: boolean;
'message'?: string;
'data'?: {
'pageNumber'?: number;
'pageSize'?: number;
'totalRowCount'?: number;
'items'?: Array<ConsumerOrCommercial>;
};
}> {

    return this.consumerOrCommercialList$Response(params).pipe(
      map((r: StrictHttpResponse<{
'isSuccess'?: boolean;
'message'?: string;
'data'?: {
'pageNumber'?: number;
'pageSize'?: number;
'totalRowCount'?: number;
'items'?: Array<ConsumerOrCommercial>;
};
}>) => r.body as {
'isSuccess'?: boolean;
'message'?: string;
'data'?: {
'pageNumber'?: number;
'pageSize'?: number;
'totalRowCount'?: number;
'items'?: Array<ConsumerOrCommercial>;
};
})
    );
  }

  /**
   * Path part for operation depositReferralAccountTypeList
   */
  static readonly DepositReferralAccountTypeListPath = '/v1/referral/list/depositAccountType';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `depositReferralAccountTypeList()` instead.
   *
   * This method doesn't expect any request body.
   */
  depositReferralAccountTypeList$Response(params?: {
  }): Observable<StrictHttpResponse<{
'isSuccess'?: boolean;
'message'?: string;
'data'?: {
'pageNumber'?: number;
'pageSize'?: number;
'totalRowCount'?: number;
'items'?: Array<DepositReferralAccountType>;
};
}>> {

    const rb = new RequestBuilder(this.rootUrl, ApiService.DepositReferralAccountTypeListPath, 'post');
    if (params) {
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<{
        'isSuccess'?: boolean;
        'message'?: string;
        'data'?: {
        'pageNumber'?: number;
        'pageSize'?: number;
        'totalRowCount'?: number;
        'items'?: Array<DepositReferralAccountType>;
        };
        }>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `depositReferralAccountTypeList$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  depositReferralAccountTypeList(params?: {
  }): Observable<{
'isSuccess'?: boolean;
'message'?: string;
'data'?: {
'pageNumber'?: number;
'pageSize'?: number;
'totalRowCount'?: number;
'items'?: Array<DepositReferralAccountType>;
};
}> {

    return this.depositReferralAccountTypeList$Response(params).pipe(
      map((r: StrictHttpResponse<{
'isSuccess'?: boolean;
'message'?: string;
'data'?: {
'pageNumber'?: number;
'pageSize'?: number;
'totalRowCount'?: number;
'items'?: Array<DepositReferralAccountType>;
};
}>) => r.body as {
'isSuccess'?: boolean;
'message'?: string;
'data'?: {
'pageNumber'?: number;
'pageSize'?: number;
'totalRowCount'?: number;
'items'?: Array<DepositReferralAccountType>;
};
})
    );
  }

  /**
   * Path part for operation loanPurposeList
   */
  static readonly LoanPurposeListPath = '/v1/referral/list/loanPurpose';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `loanPurposeList()` instead.
   *
   * This method doesn't expect any request body.
   */
  loanPurposeList$Response(params?: {
  }): Observable<StrictHttpResponse<{
'isSuccess'?: boolean;
'message'?: string;
'data'?: {
'pageNumber'?: number;
'pageSize'?: number;
'totalRowCount'?: number;
'items'?: Array<LoanPurpose>;
};
}>> {

    const rb = new RequestBuilder(this.rootUrl, ApiService.LoanPurposeListPath, 'post');
    if (params) {
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<{
        'isSuccess'?: boolean;
        'message'?: string;
        'data'?: {
        'pageNumber'?: number;
        'pageSize'?: number;
        'totalRowCount'?: number;
        'items'?: Array<LoanPurpose>;
        };
        }>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `loanPurposeList$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  loanPurposeList(params?: {
  }): Observable<{
'isSuccess'?: boolean;
'message'?: string;
'data'?: {
'pageNumber'?: number;
'pageSize'?: number;
'totalRowCount'?: number;
'items'?: Array<LoanPurpose>;
};
}> {

    return this.loanPurposeList$Response(params).pipe(
      map((r: StrictHttpResponse<{
'isSuccess'?: boolean;
'message'?: string;
'data'?: {
'pageNumber'?: number;
'pageSize'?: number;
'totalRowCount'?: number;
'items'?: Array<LoanPurpose>;
};
}>) => r.body as {
'isSuccess'?: boolean;
'message'?: string;
'data'?: {
'pageNumber'?: number;
'pageSize'?: number;
'totalRowCount'?: number;
'items'?: Array<LoanPurpose>;
};
})
    );
  }

  /**
   * Path part for operation loanReferralAdd
   */
  static readonly LoanReferralAddPath = '/v1/referral/add/loanReferral';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `loanReferralAdd()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  loanReferralAdd$Response(params: {
    body: LoanReferralRequest
  }): Observable<StrictHttpResponse<{
'isSuccess'?: boolean;
'message'?: string;
'data'?: string;
}>> {

    const rb = new RequestBuilder(this.rootUrl, ApiService.LoanReferralAddPath, 'post');
    if (params) {
      rb.body(params.body, 'application/json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<{
        'isSuccess'?: boolean;
        'message'?: string;
        'data'?: string;
        }>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `loanReferralAdd$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  loanReferralAdd(params: {
    body: LoanReferralRequest
  }): Observable<{
'isSuccess'?: boolean;
'message'?: string;
'data'?: string;
}> {

    return this.loanReferralAdd$Response(params).pipe(
      map((r: StrictHttpResponse<{
'isSuccess'?: boolean;
'message'?: string;
'data'?: string;
}>) => r.body as {
'isSuccess'?: boolean;
'message'?: string;
'data'?: string;
})
    );
  }

  /**
   * Path part for operation loanReferralList
   */
  static readonly LoanReferralListPath = '/v1/referral/list/loanReferral';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `loanReferralList()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  loanReferralList$Response(params: {
    body: FetchReferralRequest
  }): Observable<StrictHttpResponse<{
'isSuccess'?: boolean;
'message'?: string;
'data'?: {
'pageNumber'?: number;
'pageSize'?: number;
'totalRowCount'?: number;
'items'?: Array<LoanReferralItem>;
};
}>> {

    const rb = new RequestBuilder(this.rootUrl, ApiService.LoanReferralListPath, 'post');
    if (params) {
      rb.body(params.body, 'application/json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<{
        'isSuccess'?: boolean;
        'message'?: string;
        'data'?: {
        'pageNumber'?: number;
        'pageSize'?: number;
        'totalRowCount'?: number;
        'items'?: Array<LoanReferralItem>;
        };
        }>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `loanReferralList$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  loanReferralList(params: {
    body: FetchReferralRequest
  }): Observable<{
'isSuccess'?: boolean;
'message'?: string;
'data'?: {
'pageNumber'?: number;
'pageSize'?: number;
'totalRowCount'?: number;
'items'?: Array<LoanReferralItem>;
};
}> {

    return this.loanReferralList$Response(params).pipe(
      map((r: StrictHttpResponse<{
'isSuccess'?: boolean;
'message'?: string;
'data'?: {
'pageNumber'?: number;
'pageSize'?: number;
'totalRowCount'?: number;
'items'?: Array<LoanReferralItem>;
};
}>) => r.body as {
'isSuccess'?: boolean;
'message'?: string;
'data'?: {
'pageNumber'?: number;
'pageSize'?: number;
'totalRowCount'?: number;
'items'?: Array<LoanReferralItem>;
};
})
    );
  }

  /**
   * Path part for operation deleteReferral
   */
  static readonly DeleteReferralPath = '/v1/referral/deleteReferral';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `deleteReferral()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  deleteReferral$Response(params: {
    body: ReferralDeleteRequest
  }): Observable<StrictHttpResponse<{
'isSuccess'?: boolean;
'message'?: string;
'data'?: string;
}>> {

    const rb = new RequestBuilder(this.rootUrl, ApiService.DeleteReferralPath, 'post');
    if (params) {
      rb.body(params.body, 'application/json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<{
        'isSuccess'?: boolean;
        'message'?: string;
        'data'?: string;
        }>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `deleteReferral$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  deleteReferral(params: {
    body: ReferralDeleteRequest
  }): Observable<{
'isSuccess'?: boolean;
'message'?: string;
'data'?: string;
}> {

    return this.deleteReferral$Response(params).pipe(
      map((r: StrictHttpResponse<{
'isSuccess'?: boolean;
'message'?: string;
'data'?: string;
}>) => r.body as {
'isSuccess'?: boolean;
'message'?: string;
'data'?: string;
})
    );
  }

  /**
   * Path part for operation referralEmailNotification
   */
  static readonly ReferralEmailNotificationPath = '/v1/referral/emailNotification';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `referralEmailNotification()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  referralEmailNotification$Response(params: {
    body: ReferralEmailNotificationRequest
  }): Observable<StrictHttpResponse<{
'isSuccess'?: boolean;
'message'?: string;
'data'?: string;
}>> {

    const rb = new RequestBuilder(this.rootUrl, ApiService.ReferralEmailNotificationPath, 'post');
    if (params) {
      rb.body(params.body, 'application/json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<{
        'isSuccess'?: boolean;
        'message'?: string;
        'data'?: string;
        }>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `referralEmailNotification$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  referralEmailNotification(params: {
    body: ReferralEmailNotificationRequest
  }): Observable<{
'isSuccess'?: boolean;
'message'?: string;
'data'?: string;
}> {

    return this.referralEmailNotification$Response(params).pipe(
      map((r: StrictHttpResponse<{
'isSuccess'?: boolean;
'message'?: string;
'data'?: string;
}>) => r.body as {
'isSuccess'?: boolean;
'message'?: string;
'data'?: string;
})
    );
  }

  /**
   * Path part for operation fetchAllTrustPermissionEmployee
   */
  static readonly FetchAllTrustPermissionEmployeePath = '/v1/referral/list/fetchAllUserPermission';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `fetchAllTrustPermissionEmployee()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  fetchAllTrustPermissionEmployee$Response(params: {
    body: EntityGetRequest
  }): Observable<StrictHttpResponse<{
'isSuccess'?: boolean;
'message'?: string;
'data'?: {
'pageNumber'?: number;
'pageSize'?: number;
'totalRowCount'?: number;
'items'?: Array<InternalUserItem>;
};
}>> {

    const rb = new RequestBuilder(this.rootUrl, ApiService.FetchAllTrustPermissionEmployeePath, 'post');
    if (params) {
      rb.body(params.body, 'application/json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<{
        'isSuccess'?: boolean;
        'message'?: string;
        'data'?: {
        'pageNumber'?: number;
        'pageSize'?: number;
        'totalRowCount'?: number;
        'items'?: Array<InternalUserItem>;
        };
        }>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `fetchAllTrustPermissionEmployee$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  fetchAllTrustPermissionEmployee(params: {
    body: EntityGetRequest
  }): Observable<{
'isSuccess'?: boolean;
'message'?: string;
'data'?: {
'pageNumber'?: number;
'pageSize'?: number;
'totalRowCount'?: number;
'items'?: Array<InternalUserItem>;
};
}> {

    return this.fetchAllTrustPermissionEmployee$Response(params).pipe(
      map((r: StrictHttpResponse<{
'isSuccess'?: boolean;
'message'?: string;
'data'?: {
'pageNumber'?: number;
'pageSize'?: number;
'totalRowCount'?: number;
'items'?: Array<InternalUserItem>;
};
}>) => r.body as {
'isSuccess'?: boolean;
'message'?: string;
'data'?: {
'pageNumber'?: number;
'pageSize'?: number;
'totalRowCount'?: number;
'items'?: Array<InternalUserItem>;
};
})
    );
  }

  /**
   * Path part for operation insuranceReferralSourceList
   */
  static readonly InsuranceReferralSourceListPath = '/v1/referral/list/insuranceReferralSource';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `insuranceReferralSourceList()` instead.
   *
   * This method doesn't expect any request body.
   */
  insuranceReferralSourceList$Response(params?: {
  }): Observable<StrictHttpResponse<{
'isSuccess'?: boolean;
'message'?: string;
'data'?: {
'pageNumber'?: number;
'pageSize'?: number;
'totalRowCount'?: number;
'items'?: Array<InsuranceReferralSourceItem>;
};
}>> {

    const rb = new RequestBuilder(this.rootUrl, ApiService.InsuranceReferralSourceListPath, 'post');
    if (params) {
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<{
        'isSuccess'?: boolean;
        'message'?: string;
        'data'?: {
        'pageNumber'?: number;
        'pageSize'?: number;
        'totalRowCount'?: number;
        'items'?: Array<InsuranceReferralSourceItem>;
        };
        }>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `insuranceReferralSourceList$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  insuranceReferralSourceList(params?: {
  }): Observable<{
'isSuccess'?: boolean;
'message'?: string;
'data'?: {
'pageNumber'?: number;
'pageSize'?: number;
'totalRowCount'?: number;
'items'?: Array<InsuranceReferralSourceItem>;
};
}> {

    return this.insuranceReferralSourceList$Response(params).pipe(
      map((r: StrictHttpResponse<{
'isSuccess'?: boolean;
'message'?: string;
'data'?: {
'pageNumber'?: number;
'pageSize'?: number;
'totalRowCount'?: number;
'items'?: Array<InsuranceReferralSourceItem>;
};
}>) => r.body as {
'isSuccess'?: boolean;
'message'?: string;
'data'?: {
'pageNumber'?: number;
'pageSize'?: number;
'totalRowCount'?: number;
'items'?: Array<InsuranceReferralSourceItem>;
};
})
    );
  }

  /**
   * Path part for operation referralDisqualificationList
   */
  static readonly ReferralDisqualificationListPath = '/v1/referral/list/referralDisqualification';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `referralDisqualificationList()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  referralDisqualificationList$Response(params: {
    body: FetchReferralDisqualificationRequest
  }): Observable<StrictHttpResponse<{
'isSuccess'?: boolean;
'message'?: string;
'data'?: {
'pageNumber'?: number;
'pageSize'?: number;
'totalRowCount'?: number;
'items'?: Array<ReferralDisqualificationItem>;
};
}>> {

    const rb = new RequestBuilder(this.rootUrl, ApiService.ReferralDisqualificationListPath, 'post');
    if (params) {
      rb.body(params.body, 'application/json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<{
        'isSuccess'?: boolean;
        'message'?: string;
        'data'?: {
        'pageNumber'?: number;
        'pageSize'?: number;
        'totalRowCount'?: number;
        'items'?: Array<ReferralDisqualificationItem>;
        };
        }>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `referralDisqualificationList$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  referralDisqualificationList(params: {
    body: FetchReferralDisqualificationRequest
  }): Observable<{
'isSuccess'?: boolean;
'message'?: string;
'data'?: {
'pageNumber'?: number;
'pageSize'?: number;
'totalRowCount'?: number;
'items'?: Array<ReferralDisqualificationItem>;
};
}> {

    return this.referralDisqualificationList$Response(params).pipe(
      map((r: StrictHttpResponse<{
'isSuccess'?: boolean;
'message'?: string;
'data'?: {
'pageNumber'?: number;
'pageSize'?: number;
'totalRowCount'?: number;
'items'?: Array<ReferralDisqualificationItem>;
};
}>) => r.body as {
'isSuccess'?: boolean;
'message'?: string;
'data'?: {
'pageNumber'?: number;
'pageSize'?: number;
'totalRowCount'?: number;
'items'?: Array<ReferralDisqualificationItem>;
};
})
    );
  }

  /**
   * Path part for operation loanReferralRegionList
   */
  static readonly LoanReferralRegionListPath = '/v1/referral/list/loanReferralRegion';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `loanReferralRegionList()` instead.
   *
   * This method doesn't expect any request body.
   */
  loanReferralRegionList$Response(params?: {
  }): Observable<StrictHttpResponse<{
'isSuccess'?: boolean;
'message'?: string;
'data'?: {
'pageNumber'?: number;
'pageSize'?: number;
'totalRowCount'?: number;
'items'?: Array<LoanReferralRegion>;
};
}>> {

    const rb = new RequestBuilder(this.rootUrl, ApiService.LoanReferralRegionListPath, 'post');
    if (params) {
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<{
        'isSuccess'?: boolean;
        'message'?: string;
        'data'?: {
        'pageNumber'?: number;
        'pageSize'?: number;
        'totalRowCount'?: number;
        'items'?: Array<LoanReferralRegion>;
        };
        }>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `loanReferralRegionList$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  loanReferralRegionList(params?: {
  }): Observable<{
'isSuccess'?: boolean;
'message'?: string;
'data'?: {
'pageNumber'?: number;
'pageSize'?: number;
'totalRowCount'?: number;
'items'?: Array<LoanReferralRegion>;
};
}> {

    return this.loanReferralRegionList$Response(params).pipe(
      map((r: StrictHttpResponse<{
'isSuccess'?: boolean;
'message'?: string;
'data'?: {
'pageNumber'?: number;
'pageSize'?: number;
'totalRowCount'?: number;
'items'?: Array<LoanReferralRegion>;
};
}>) => r.body as {
'isSuccess'?: boolean;
'message'?: string;
'data'?: {
'pageNumber'?: number;
'pageSize'?: number;
'totalRowCount'?: number;
'items'?: Array<LoanReferralRegion>;
};
})
    );
  }

  /**
   * Path part for operation depositReferralSourceList
   */
  static readonly DepositReferralSourceListPath = '/v1/referral/list/depositReferralSource';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `depositReferralSourceList()` instead.
   *
   * This method doesn't expect any request body.
   */
  depositReferralSourceList$Response(params?: {
  }): Observable<StrictHttpResponse<{
'isSuccess'?: boolean;
'message'?: string;
'data'?: {
'pageNumber'?: number;
'pageSize'?: number;
'totalRowCount'?: number;
'items'?: Array<DepositReferralSource>;
};
}>> {

    const rb = new RequestBuilder(this.rootUrl, ApiService.DepositReferralSourceListPath, 'post');
    if (params) {
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<{
        'isSuccess'?: boolean;
        'message'?: string;
        'data'?: {
        'pageNumber'?: number;
        'pageSize'?: number;
        'totalRowCount'?: number;
        'items'?: Array<DepositReferralSource>;
        };
        }>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `depositReferralSourceList$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  depositReferralSourceList(params?: {
  }): Observable<{
'isSuccess'?: boolean;
'message'?: string;
'data'?: {
'pageNumber'?: number;
'pageSize'?: number;
'totalRowCount'?: number;
'items'?: Array<DepositReferralSource>;
};
}> {

    return this.depositReferralSourceList$Response(params).pipe(
      map((r: StrictHttpResponse<{
'isSuccess'?: boolean;
'message'?: string;
'data'?: {
'pageNumber'?: number;
'pageSize'?: number;
'totalRowCount'?: number;
'items'?: Array<DepositReferralSource>;
};
}>) => r.body as {
'isSuccess'?: boolean;
'message'?: string;
'data'?: {
'pageNumber'?: number;
'pageSize'?: number;
'totalRowCount'?: number;
'items'?: Array<DepositReferralSource>;
};
})
    );
  }

  /**
   * Path part for operation loanReferralSourceList
   */
  static readonly LoanReferralSourceListPath = '/v1/referral/list/loanReferralSource';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `loanReferralSourceList()` instead.
   *
   * This method doesn't expect any request body.
   */
  loanReferralSourceList$Response(params?: {
  }): Observable<StrictHttpResponse<{
'isSuccess'?: boolean;
'message'?: string;
'data'?: {
'pageNumber'?: number;
'pageSize'?: number;
'totalRowCount'?: number;
'items'?: Array<LoanReferralSource>;
};
}>> {

    const rb = new RequestBuilder(this.rootUrl, ApiService.LoanReferralSourceListPath, 'post');
    if (params) {
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<{
        'isSuccess'?: boolean;
        'message'?: string;
        'data'?: {
        'pageNumber'?: number;
        'pageSize'?: number;
        'totalRowCount'?: number;
        'items'?: Array<LoanReferralSource>;
        };
        }>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `loanReferralSourceList$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  loanReferralSourceList(params?: {
  }): Observable<{
'isSuccess'?: boolean;
'message'?: string;
'data'?: {
'pageNumber'?: number;
'pageSize'?: number;
'totalRowCount'?: number;
'items'?: Array<LoanReferralSource>;
};
}> {

    return this.loanReferralSourceList$Response(params).pipe(
      map((r: StrictHttpResponse<{
'isSuccess'?: boolean;
'message'?: string;
'data'?: {
'pageNumber'?: number;
'pageSize'?: number;
'totalRowCount'?: number;
'items'?: Array<LoanReferralSource>;
};
}>) => r.body as {
'isSuccess'?: boolean;
'message'?: string;
'data'?: {
'pageNumber'?: number;
'pageSize'?: number;
'totalRowCount'?: number;
'items'?: Array<LoanReferralSource>;
};
})
    );
  }

  /**
   * Path part for operation trainingList
   */
  static readonly TrainingListPath = '/v1/training/list';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `trainingList()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  trainingList$Response(params: {
    body: EntityGetRequest
  }): Observable<StrictHttpResponse<{
'isSuccess'?: boolean;
'message'?: string;
'data'?: {
'pageNumber'?: number;
'pageSize'?: number;
'totalRowCount'?: number;
'items'?: Array<TrainingListItem>;
};
}>> {

    const rb = new RequestBuilder(this.rootUrl, ApiService.TrainingListPath, 'post');
    if (params) {
      rb.body(params.body, 'application/json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<{
        'isSuccess'?: boolean;
        'message'?: string;
        'data'?: {
        'pageNumber'?: number;
        'pageSize'?: number;
        'totalRowCount'?: number;
        'items'?: Array<TrainingListItem>;
        };
        }>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `trainingList$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  trainingList(params: {
    body: EntityGetRequest
  }): Observable<{
'isSuccess'?: boolean;
'message'?: string;
'data'?: {
'pageNumber'?: number;
'pageSize'?: number;
'totalRowCount'?: number;
'items'?: Array<TrainingListItem>;
};
}> {

    return this.trainingList$Response(params).pipe(
      map((r: StrictHttpResponse<{
'isSuccess'?: boolean;
'message'?: string;
'data'?: {
'pageNumber'?: number;
'pageSize'?: number;
'totalRowCount'?: number;
'items'?: Array<TrainingListItem>;
};
}>) => r.body as {
'isSuccess'?: boolean;
'message'?: string;
'data'?: {
'pageNumber'?: number;
'pageSize'?: number;
'totalRowCount'?: number;
'items'?: Array<TrainingListItem>;
};
})
    );
  }

  /**
   * Path part for operation trainingAdd
   */
  static readonly TrainingAddPath = '/v1/training/add';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `trainingAdd()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  trainingAdd$Response(params: {
    body: Training
  }): Observable<StrictHttpResponse<{
'isSuccess'?: boolean;
'message'?: string;
'data'?: string;
}>> {

    const rb = new RequestBuilder(this.rootUrl, ApiService.TrainingAddPath, 'post');
    if (params) {
      rb.body(params.body, 'application/json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<{
        'isSuccess'?: boolean;
        'message'?: string;
        'data'?: string;
        }>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `trainingAdd$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  trainingAdd(params: {
    body: Training
  }): Observable<{
'isSuccess'?: boolean;
'message'?: string;
'data'?: string;
}> {

    return this.trainingAdd$Response(params).pipe(
      map((r: StrictHttpResponse<{
'isSuccess'?: boolean;
'message'?: string;
'data'?: string;
}>) => r.body as {
'isSuccess'?: boolean;
'message'?: string;
'data'?: string;
})
    );
  }

  /**
   * Path part for operation trainingUpdate
   */
  static readonly TrainingUpdatePath = '/v1/training/update';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `trainingUpdate()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  trainingUpdate$Response(params: {
    body: EntityUpdateRequest
  }): Observable<StrictHttpResponse<{
'isSuccess'?: boolean;
'message'?: string;
'data'?: string;
}>> {

    const rb = new RequestBuilder(this.rootUrl, ApiService.TrainingUpdatePath, 'put');
    if (params) {
      rb.body(params.body, 'application/json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<{
        'isSuccess'?: boolean;
        'message'?: string;
        'data'?: string;
        }>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `trainingUpdate$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  trainingUpdate(params: {
    body: EntityUpdateRequest
  }): Observable<{
'isSuccess'?: boolean;
'message'?: string;
'data'?: string;
}> {

    return this.trainingUpdate$Response(params).pipe(
      map((r: StrictHttpResponse<{
'isSuccess'?: boolean;
'message'?: string;
'data'?: string;
}>) => r.body as {
'isSuccess'?: boolean;
'message'?: string;
'data'?: string;
})
    );
  }

  /**
   * Path part for operation trainingDelete
   */
  static readonly TrainingDeletePath = '/v1/training/delete';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `trainingDelete()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  trainingDelete$Response(params: {
    body: DeleteRequest
  }): Observable<StrictHttpResponse<{
'isSuccess'?: boolean;
'message'?: string;
'data'?: string;
}>> {

    const rb = new RequestBuilder(this.rootUrl, ApiService.TrainingDeletePath, 'delete');
    if (params) {
      rb.body(params.body, 'application/json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<{
        'isSuccess'?: boolean;
        'message'?: string;
        'data'?: string;
        }>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `trainingDelete$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  trainingDelete(params: {
    body: DeleteRequest
  }): Observable<{
'isSuccess'?: boolean;
'message'?: string;
'data'?: string;
}> {

    return this.trainingDelete$Response(params).pipe(
      map((r: StrictHttpResponse<{
'isSuccess'?: boolean;
'message'?: string;
'data'?: string;
}>) => r.body as {
'isSuccess'?: boolean;
'message'?: string;
'data'?: string;
})
    );
  }

  /**
   * Path part for operation calendarEventList
   */
  static readonly CalendarEventListPath = '/v1/training/list/calendarEvent';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `calendarEventList()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  calendarEventList$Response(params: {
    body: CalendarEventListRequest
  }): Observable<StrictHttpResponse<{
'isSuccess'?: boolean;
'message'?: string;
'data'?: {
'pageNumber'?: number;
'pageSize'?: number;
'totalRowCount'?: number;
'items'?: Array<CalendarEventListItem>;
};
}>> {

    const rb = new RequestBuilder(this.rootUrl, ApiService.CalendarEventListPath, 'post');
    if (params) {
      rb.body(params.body, 'application/json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<{
        'isSuccess'?: boolean;
        'message'?: string;
        'data'?: {
        'pageNumber'?: number;
        'pageSize'?: number;
        'totalRowCount'?: number;
        'items'?: Array<CalendarEventListItem>;
        };
        }>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `calendarEventList$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  calendarEventList(params: {
    body: CalendarEventListRequest
  }): Observable<{
'isSuccess'?: boolean;
'message'?: string;
'data'?: {
'pageNumber'?: number;
'pageSize'?: number;
'totalRowCount'?: number;
'items'?: Array<CalendarEventListItem>;
};
}> {

    return this.calendarEventList$Response(params).pipe(
      map((r: StrictHttpResponse<{
'isSuccess'?: boolean;
'message'?: string;
'data'?: {
'pageNumber'?: number;
'pageSize'?: number;
'totalRowCount'?: number;
'items'?: Array<CalendarEventListItem>;
};
}>) => r.body as {
'isSuccess'?: boolean;
'message'?: string;
'data'?: {
'pageNumber'?: number;
'pageSize'?: number;
'totalRowCount'?: number;
'items'?: Array<CalendarEventListItem>;
};
})
    );
  }

  /**
   * Path part for operation calendarEventAdd
   */
  static readonly CalendarEventAddPath = '/v1/training/add/calendarEvent';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `calendarEventAdd()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  calendarEventAdd$Response(params: {
    body: CalendarEvent
  }): Observable<StrictHttpResponse<{
'isSuccess'?: boolean;
'message'?: string;
'data'?: string;
}>> {

    const rb = new RequestBuilder(this.rootUrl, ApiService.CalendarEventAddPath, 'post');
    if (params) {
      rb.body(params.body, 'application/json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<{
        'isSuccess'?: boolean;
        'message'?: string;
        'data'?: string;
        }>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `calendarEventAdd$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  calendarEventAdd(params: {
    body: CalendarEvent
  }): Observable<{
'isSuccess'?: boolean;
'message'?: string;
'data'?: string;
}> {

    return this.calendarEventAdd$Response(params).pipe(
      map((r: StrictHttpResponse<{
'isSuccess'?: boolean;
'message'?: string;
'data'?: string;
}>) => r.body as {
'isSuccess'?: boolean;
'message'?: string;
'data'?: string;
})
    );
  }

  /**
   * Path part for operation calendarEventDelete
   */
  static readonly CalendarEventDeletePath = '/v1/training/delete/calendarEvent';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `calendarEventDelete()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  calendarEventDelete$Response(params: {
    body: DeleteRequest
  }): Observable<StrictHttpResponse<{
'isSuccess'?: boolean;
'message'?: string;
'data'?: string;
}>> {

    const rb = new RequestBuilder(this.rootUrl, ApiService.CalendarEventDeletePath, 'delete');
    if (params) {
      rb.body(params.body, 'application/json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<{
        'isSuccess'?: boolean;
        'message'?: string;
        'data'?: string;
        }>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `calendarEventDelete$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  calendarEventDelete(params: {
    body: DeleteRequest
  }): Observable<{
'isSuccess'?: boolean;
'message'?: string;
'data'?: string;
}> {

    return this.calendarEventDelete$Response(params).pipe(
      map((r: StrictHttpResponse<{
'isSuccess'?: boolean;
'message'?: string;
'data'?: string;
}>) => r.body as {
'isSuccess'?: boolean;
'message'?: string;
'data'?: string;
})
    );
  }

  /**
   * Path part for operation trainingMaterialList
   */
  static readonly TrainingMaterialListPath = '/v1/training/list/trainingMaterial';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `trainingMaterialList()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  trainingMaterialList$Response(params: {
    body: EntityGetRequest
  }): Observable<StrictHttpResponse<{
'isSuccess'?: boolean;
'message'?: string;
'data'?: {
'pageNumber'?: number;
'pageSize'?: number;
'totalRowCount'?: number;
'items'?: Array<TrainingMaterialListItem>;
};
}>> {

    const rb = new RequestBuilder(this.rootUrl, ApiService.TrainingMaterialListPath, 'post');
    if (params) {
      rb.body(params.body, 'application/json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<{
        'isSuccess'?: boolean;
        'message'?: string;
        'data'?: {
        'pageNumber'?: number;
        'pageSize'?: number;
        'totalRowCount'?: number;
        'items'?: Array<TrainingMaterialListItem>;
        };
        }>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `trainingMaterialList$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  trainingMaterialList(params: {
    body: EntityGetRequest
  }): Observable<{
'isSuccess'?: boolean;
'message'?: string;
'data'?: {
'pageNumber'?: number;
'pageSize'?: number;
'totalRowCount'?: number;
'items'?: Array<TrainingMaterialListItem>;
};
}> {

    return this.trainingMaterialList$Response(params).pipe(
      map((r: StrictHttpResponse<{
'isSuccess'?: boolean;
'message'?: string;
'data'?: {
'pageNumber'?: number;
'pageSize'?: number;
'totalRowCount'?: number;
'items'?: Array<TrainingMaterialListItem>;
};
}>) => r.body as {
'isSuccess'?: boolean;
'message'?: string;
'data'?: {
'pageNumber'?: number;
'pageSize'?: number;
'totalRowCount'?: number;
'items'?: Array<TrainingMaterialListItem>;
};
})
    );
  }

  /**
   * Path part for operation trainingMaterialAdd
   */
  static readonly TrainingMaterialAddPath = '/v1/training/add/trainingMaterial';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `trainingMaterialAdd()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  trainingMaterialAdd$Response(params: {
    body: TrainingMaterial
  }): Observable<StrictHttpResponse<{
'isSuccess'?: boolean;
'message'?: string;
'data'?: string;
}>> {

    const rb = new RequestBuilder(this.rootUrl, ApiService.TrainingMaterialAddPath, 'post');
    if (params) {
      rb.body(params.body, 'application/json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<{
        'isSuccess'?: boolean;
        'message'?: string;
        'data'?: string;
        }>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `trainingMaterialAdd$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  trainingMaterialAdd(params: {
    body: TrainingMaterial
  }): Observable<{
'isSuccess'?: boolean;
'message'?: string;
'data'?: string;
}> {

    return this.trainingMaterialAdd$Response(params).pipe(
      map((r: StrictHttpResponse<{
'isSuccess'?: boolean;
'message'?: string;
'data'?: string;
}>) => r.body as {
'isSuccess'?: boolean;
'message'?: string;
'data'?: string;
})
    );
  }

  /**
   * Path part for operation trainingMaterialUpdate
   */
  static readonly TrainingMaterialUpdatePath = '/v1/training/update/trainingMaterial';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `trainingMaterialUpdate()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  trainingMaterialUpdate$Response(params: {
    body: TrainingMaterial
  }): Observable<StrictHttpResponse<{
'isSuccess'?: boolean;
'message'?: string;
'data'?: string;
}>> {

    const rb = new RequestBuilder(this.rootUrl, ApiService.TrainingMaterialUpdatePath, 'put');
    if (params) {
      rb.body(params.body, 'application/json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<{
        'isSuccess'?: boolean;
        'message'?: string;
        'data'?: string;
        }>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `trainingMaterialUpdate$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  trainingMaterialUpdate(params: {
    body: TrainingMaterial
  }): Observable<{
'isSuccess'?: boolean;
'message'?: string;
'data'?: string;
}> {

    return this.trainingMaterialUpdate$Response(params).pipe(
      map((r: StrictHttpResponse<{
'isSuccess'?: boolean;
'message'?: string;
'data'?: string;
}>) => r.body as {
'isSuccess'?: boolean;
'message'?: string;
'data'?: string;
})
    );
  }

  /**
   * Path part for operation trainingMaterialDelete
   */
  static readonly TrainingMaterialDeletePath = '/v1/training/delete/trainingMaterial';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `trainingMaterialDelete()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  trainingMaterialDelete$Response(params: {
    body: DeleteRequest
  }): Observable<StrictHttpResponse<{
'isSuccess'?: boolean;
'message'?: string;
'data'?: string;
}>> {

    const rb = new RequestBuilder(this.rootUrl, ApiService.TrainingMaterialDeletePath, 'post');
    if (params) {
      rb.body(params.body, 'application/json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<{
        'isSuccess'?: boolean;
        'message'?: string;
        'data'?: string;
        }>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `trainingMaterialDelete$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  trainingMaterialDelete(params: {
    body: DeleteRequest
  }): Observable<{
'isSuccess'?: boolean;
'message'?: string;
'data'?: string;
}> {

    return this.trainingMaterialDelete$Response(params).pipe(
      map((r: StrictHttpResponse<{
'isSuccess'?: boolean;
'message'?: string;
'data'?: string;
}>) => r.body as {
'isSuccess'?: boolean;
'message'?: string;
'data'?: string;
})
    );
  }

  /**
   * Path part for operation trainingXCalendarEventList
   */
  static readonly TrainingXCalendarEventListPath = '/v1/training/list/trainingXCalendarEvent';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `trainingXCalendarEventList()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  trainingXCalendarEventList$Response(params: {
    body: TrainingXCalendarEventListRequest
  }): Observable<StrictHttpResponse<{
'isSuccess'?: boolean;
'message'?: string;
'data'?: {
'pageNumber'?: number;
'pageSize'?: number;
'totalRowCount'?: number;
'items'?: Array<TrainingXCalendarEventListItem>;
};
}>> {

    const rb = new RequestBuilder(this.rootUrl, ApiService.TrainingXCalendarEventListPath, 'post');
    if (params) {
      rb.body(params.body, 'application/json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<{
        'isSuccess'?: boolean;
        'message'?: string;
        'data'?: {
        'pageNumber'?: number;
        'pageSize'?: number;
        'totalRowCount'?: number;
        'items'?: Array<TrainingXCalendarEventListItem>;
        };
        }>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `trainingXCalendarEventList$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  trainingXCalendarEventList(params: {
    body: TrainingXCalendarEventListRequest
  }): Observable<{
'isSuccess'?: boolean;
'message'?: string;
'data'?: {
'pageNumber'?: number;
'pageSize'?: number;
'totalRowCount'?: number;
'items'?: Array<TrainingXCalendarEventListItem>;
};
}> {

    return this.trainingXCalendarEventList$Response(params).pipe(
      map((r: StrictHttpResponse<{
'isSuccess'?: boolean;
'message'?: string;
'data'?: {
'pageNumber'?: number;
'pageSize'?: number;
'totalRowCount'?: number;
'items'?: Array<TrainingXCalendarEventListItem>;
};
}>) => r.body as {
'isSuccess'?: boolean;
'message'?: string;
'data'?: {
'pageNumber'?: number;
'pageSize'?: number;
'totalRowCount'?: number;
'items'?: Array<TrainingXCalendarEventListItem>;
};
})
    );
  }

  /**
   * Path part for operation trainingXCalendarEventAdd
   */
  static readonly TrainingXCalendarEventAddPath = '/v1/training/add/trainingXCalendarEvent';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `trainingXCalendarEventAdd()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  trainingXCalendarEventAdd$Response(params: {
    body: TrainingXCalendarEvent
  }): Observable<StrictHttpResponse<{
'isSuccess'?: boolean;
'message'?: string;
'data'?: string;
}>> {

    const rb = new RequestBuilder(this.rootUrl, ApiService.TrainingXCalendarEventAddPath, 'post');
    if (params) {
      rb.body(params.body, 'application/json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<{
        'isSuccess'?: boolean;
        'message'?: string;
        'data'?: string;
        }>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `trainingXCalendarEventAdd$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  trainingXCalendarEventAdd(params: {
    body: TrainingXCalendarEvent
  }): Observable<{
'isSuccess'?: boolean;
'message'?: string;
'data'?: string;
}> {

    return this.trainingXCalendarEventAdd$Response(params).pipe(
      map((r: StrictHttpResponse<{
'isSuccess'?: boolean;
'message'?: string;
'data'?: string;
}>) => r.body as {
'isSuccess'?: boolean;
'message'?: string;
'data'?: string;
})
    );
  }

  /**
   * Path part for operation trainingXCalendarEventDelete
   */
  static readonly TrainingXCalendarEventDeletePath = '/v1/training/delete/trainingXCalendarEvent';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `trainingXCalendarEventDelete()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  trainingXCalendarEventDelete$Response(params: {
    body: DeleteRequest
  }): Observable<StrictHttpResponse<{
'isSuccess'?: boolean;
'message'?: string;
'data'?: string;
}>> {

    const rb = new RequestBuilder(this.rootUrl, ApiService.TrainingXCalendarEventDeletePath, 'delete');
    if (params) {
      rb.body(params.body, 'application/json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<{
        'isSuccess'?: boolean;
        'message'?: string;
        'data'?: string;
        }>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `trainingXCalendarEventDelete$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  trainingXCalendarEventDelete(params: {
    body: DeleteRequest
  }): Observable<{
'isSuccess'?: boolean;
'message'?: string;
'data'?: string;
}> {

    return this.trainingXCalendarEventDelete$Response(params).pipe(
      map((r: StrictHttpResponse<{
'isSuccess'?: boolean;
'message'?: string;
'data'?: string;
}>) => r.body as {
'isSuccess'?: boolean;
'message'?: string;
'data'?: string;
})
    );
  }

  /**
   * Path part for operation calendarEventXAttendeeList
   */
  static readonly CalendarEventXAttendeeListPath = '/v1/training/list/calendarEventXAttendee';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `calendarEventXAttendeeList()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  calendarEventXAttendeeList$Response(params: {
    body: CalendarEventXAttendeeListRequest
  }): Observable<StrictHttpResponse<{
'isSuccess'?: boolean;
'message'?: string;
'data'?: {
'pageNumber'?: number;
'pageSize'?: number;
'totalRowCount'?: number;
'items'?: Array<CalendarEventXAttendeeListItem>;
};
}>> {

    const rb = new RequestBuilder(this.rootUrl, ApiService.CalendarEventXAttendeeListPath, 'post');
    if (params) {
      rb.body(params.body, 'application/json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<{
        'isSuccess'?: boolean;
        'message'?: string;
        'data'?: {
        'pageNumber'?: number;
        'pageSize'?: number;
        'totalRowCount'?: number;
        'items'?: Array<CalendarEventXAttendeeListItem>;
        };
        }>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `calendarEventXAttendeeList$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  calendarEventXAttendeeList(params: {
    body: CalendarEventXAttendeeListRequest
  }): Observable<{
'isSuccess'?: boolean;
'message'?: string;
'data'?: {
'pageNumber'?: number;
'pageSize'?: number;
'totalRowCount'?: number;
'items'?: Array<CalendarEventXAttendeeListItem>;
};
}> {

    return this.calendarEventXAttendeeList$Response(params).pipe(
      map((r: StrictHttpResponse<{
'isSuccess'?: boolean;
'message'?: string;
'data'?: {
'pageNumber'?: number;
'pageSize'?: number;
'totalRowCount'?: number;
'items'?: Array<CalendarEventXAttendeeListItem>;
};
}>) => r.body as {
'isSuccess'?: boolean;
'message'?: string;
'data'?: {
'pageNumber'?: number;
'pageSize'?: number;
'totalRowCount'?: number;
'items'?: Array<CalendarEventXAttendeeListItem>;
};
})
    );
  }

  /**
   * Path part for operation calendarEventXAttendeeAdd
   */
  static readonly CalendarEventXAttendeeAddPath = '/v1/training/add/calendarEventXAttendee';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `calendarEventXAttendeeAdd()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  calendarEventXAttendeeAdd$Response(params: {
    body: CalendarEventXAttendee
  }): Observable<StrictHttpResponse<{
'isSuccess'?: boolean;
'message'?: string;
'data'?: string;
}>> {

    const rb = new RequestBuilder(this.rootUrl, ApiService.CalendarEventXAttendeeAddPath, 'post');
    if (params) {
      rb.body(params.body, 'application/json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<{
        'isSuccess'?: boolean;
        'message'?: string;
        'data'?: string;
        }>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `calendarEventXAttendeeAdd$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  calendarEventXAttendeeAdd(params: {
    body: CalendarEventXAttendee
  }): Observable<{
'isSuccess'?: boolean;
'message'?: string;
'data'?: string;
}> {

    return this.calendarEventXAttendeeAdd$Response(params).pipe(
      map((r: StrictHttpResponse<{
'isSuccess'?: boolean;
'message'?: string;
'data'?: string;
}>) => r.body as {
'isSuccess'?: boolean;
'message'?: string;
'data'?: string;
})
    );
  }

  /**
   * Path part for operation calendarEventXAttendeeDelete
   */
  static readonly CalendarEventXAttendeeDeletePath = '/v1/training/delete/calendarEventXAttendee';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `calendarEventXAttendeeDelete()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  calendarEventXAttendeeDelete$Response(params: {
    body: DeleteRequest
  }): Observable<StrictHttpResponse<{
'isSuccess'?: boolean;
'message'?: string;
'data'?: string;
}>> {

    const rb = new RequestBuilder(this.rootUrl, ApiService.CalendarEventXAttendeeDeletePath, 'delete');
    if (params) {
      rb.body(params.body, 'application/json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<{
        'isSuccess'?: boolean;
        'message'?: string;
        'data'?: string;
        }>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `calendarEventXAttendeeDelete$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  calendarEventXAttendeeDelete(params: {
    body: DeleteRequest
  }): Observable<{
'isSuccess'?: boolean;
'message'?: string;
'data'?: string;
}> {

    return this.calendarEventXAttendeeDelete$Response(params).pipe(
      map((r: StrictHttpResponse<{
'isSuccess'?: boolean;
'message'?: string;
'data'?: string;
}>) => r.body as {
'isSuccess'?: boolean;
'message'?: string;
'data'?: string;
})
    );
  }

  /**
   * Path part for operation calendarList
   */
  static readonly CalendarListPath = '/v1/training/list/calendar';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `calendarList()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  calendarList$Response(params: {
    body: CalendarListRequest
  }): Observable<StrictHttpResponse<{
'isSuccess'?: boolean;
'message'?: string;
'data'?: {
'pageNumber'?: number;
'pageSize'?: number;
'totalRowCount'?: number;
'items'?: Array<CalendarListItem>;
};
}>> {

    const rb = new RequestBuilder(this.rootUrl, ApiService.CalendarListPath, 'post');
    if (params) {
      rb.body(params.body, 'application/json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<{
        'isSuccess'?: boolean;
        'message'?: string;
        'data'?: {
        'pageNumber'?: number;
        'pageSize'?: number;
        'totalRowCount'?: number;
        'items'?: Array<CalendarListItem>;
        };
        }>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `calendarList$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  calendarList(params: {
    body: CalendarListRequest
  }): Observable<{
'isSuccess'?: boolean;
'message'?: string;
'data'?: {
'pageNumber'?: number;
'pageSize'?: number;
'totalRowCount'?: number;
'items'?: Array<CalendarListItem>;
};
}> {

    return this.calendarList$Response(params).pipe(
      map((r: StrictHttpResponse<{
'isSuccess'?: boolean;
'message'?: string;
'data'?: {
'pageNumber'?: number;
'pageSize'?: number;
'totalRowCount'?: number;
'items'?: Array<CalendarListItem>;
};
}>) => r.body as {
'isSuccess'?: boolean;
'message'?: string;
'data'?: {
'pageNumber'?: number;
'pageSize'?: number;
'totalRowCount'?: number;
'items'?: Array<CalendarListItem>;
};
})
    );
  }

  /**
   * Path part for operation projectList
   */
  static readonly ProjectListPath = '/v1/performanceManagement/list/project';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `projectList()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  projectList$Response(params: {
    body: EntityGetRequest
  }): Observable<StrictHttpResponse<{
'isSuccess'?: boolean;
'message'?: string;
'data'?: {
'pageNumber'?: number;
'pageSize'?: number;
'totalRowCount'?: number;
'items'?: Array<ProjectItem>;
};
}>> {

    const rb = new RequestBuilder(this.rootUrl, ApiService.ProjectListPath, 'post');
    if (params) {
      rb.body(params.body, 'application/json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<{
        'isSuccess'?: boolean;
        'message'?: string;
        'data'?: {
        'pageNumber'?: number;
        'pageSize'?: number;
        'totalRowCount'?: number;
        'items'?: Array<ProjectItem>;
        };
        }>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `projectList$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  projectList(params: {
    body: EntityGetRequest
  }): Observable<{
'isSuccess'?: boolean;
'message'?: string;
'data'?: {
'pageNumber'?: number;
'pageSize'?: number;
'totalRowCount'?: number;
'items'?: Array<ProjectItem>;
};
}> {

    return this.projectList$Response(params).pipe(
      map((r: StrictHttpResponse<{
'isSuccess'?: boolean;
'message'?: string;
'data'?: {
'pageNumber'?: number;
'pageSize'?: number;
'totalRowCount'?: number;
'items'?: Array<ProjectItem>;
};
}>) => r.body as {
'isSuccess'?: boolean;
'message'?: string;
'data'?: {
'pageNumber'?: number;
'pageSize'?: number;
'totalRowCount'?: number;
'items'?: Array<ProjectItem>;
};
})
    );
  }

  /**
   * Path part for operation projectSnapshotList
   */
  static readonly ProjectSnapshotListPath = '/v1/performanceManagement/list/snapShot/project';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `projectSnapshotList()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  projectSnapshotList$Response(params: {
    body: EntityGetRequest
  }): Observable<StrictHttpResponse<{
'isSuccess'?: boolean;
'message'?: string;
'data'?: {
'pageNumber'?: number;
'pageSize'?: number;
'totalRowCount'?: number;
'items'?: Array<ProjectItem>;
};
}>> {

    const rb = new RequestBuilder(this.rootUrl, ApiService.ProjectSnapshotListPath, 'post');
    if (params) {
      rb.body(params.body, 'application/json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<{
        'isSuccess'?: boolean;
        'message'?: string;
        'data'?: {
        'pageNumber'?: number;
        'pageSize'?: number;
        'totalRowCount'?: number;
        'items'?: Array<ProjectItem>;
        };
        }>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `projectSnapshotList$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  projectSnapshotList(params: {
    body: EntityGetRequest
  }): Observable<{
'isSuccess'?: boolean;
'message'?: string;
'data'?: {
'pageNumber'?: number;
'pageSize'?: number;
'totalRowCount'?: number;
'items'?: Array<ProjectItem>;
};
}> {

    return this.projectSnapshotList$Response(params).pipe(
      map((r: StrictHttpResponse<{
'isSuccess'?: boolean;
'message'?: string;
'data'?: {
'pageNumber'?: number;
'pageSize'?: number;
'totalRowCount'?: number;
'items'?: Array<ProjectItem>;
};
}>) => r.body as {
'isSuccess'?: boolean;
'message'?: string;
'data'?: {
'pageNumber'?: number;
'pageSize'?: number;
'totalRowCount'?: number;
'items'?: Array<ProjectItem>;
};
})
    );
  }

  /**
   * Path part for operation projectListForAUser
   */
  static readonly ProjectListForAUserPath = '/v1/performanceManagement/list/user/project';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `projectListForAUser()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  projectListForAUser$Response(params: {
    body: PerformanceManagementEntityRequest
  }): Observable<StrictHttpResponse<{
'isSuccess'?: boolean;
'message'?: string;
'data'?: {
'pageNumber'?: number;
'pageSize'?: number;
'totalRowCount'?: number;
'items'?: Array<ProjectItem>;
};
}>> {

    const rb = new RequestBuilder(this.rootUrl, ApiService.ProjectListForAUserPath, 'post');
    if (params) {
      rb.body(params.body, 'application/json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<{
        'isSuccess'?: boolean;
        'message'?: string;
        'data'?: {
        'pageNumber'?: number;
        'pageSize'?: number;
        'totalRowCount'?: number;
        'items'?: Array<ProjectItem>;
        };
        }>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `projectListForAUser$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  projectListForAUser(params: {
    body: PerformanceManagementEntityRequest
  }): Observable<{
'isSuccess'?: boolean;
'message'?: string;
'data'?: {
'pageNumber'?: number;
'pageSize'?: number;
'totalRowCount'?: number;
'items'?: Array<ProjectItem>;
};
}> {

    return this.projectListForAUser$Response(params).pipe(
      map((r: StrictHttpResponse<{
'isSuccess'?: boolean;
'message'?: string;
'data'?: {
'pageNumber'?: number;
'pageSize'?: number;
'totalRowCount'?: number;
'items'?: Array<ProjectItem>;
};
}>) => r.body as {
'isSuccess'?: boolean;
'message'?: string;
'data'?: {
'pageNumber'?: number;
'pageSize'?: number;
'totalRowCount'?: number;
'items'?: Array<ProjectItem>;
};
})
    );
  }

  /**
   * Path part for operation projectAdd
   */
  static readonly ProjectAddPath = '/v1/performanceManagement/add/project';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `projectAdd()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  projectAdd$Response(params: {
    body: ProjectItem
  }): Observable<StrictHttpResponse<{
'isSuccess'?: boolean;
'message'?: string;
'data'?: string;
}>> {

    const rb = new RequestBuilder(this.rootUrl, ApiService.ProjectAddPath, 'post');
    if (params) {
      rb.body(params.body, 'application/json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<{
        'isSuccess'?: boolean;
        'message'?: string;
        'data'?: string;
        }>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `projectAdd$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  projectAdd(params: {
    body: ProjectItem
  }): Observable<{
'isSuccess'?: boolean;
'message'?: string;
'data'?: string;
}> {

    return this.projectAdd$Response(params).pipe(
      map((r: StrictHttpResponse<{
'isSuccess'?: boolean;
'message'?: string;
'data'?: string;
}>) => r.body as {
'isSuccess'?: boolean;
'message'?: string;
'data'?: string;
})
    );
  }

  /**
   * Path part for operation projectUpdate
   */
  static readonly ProjectUpdatePath = '/v1/performanceManagement/update/project';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `projectUpdate()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  projectUpdate$Response(params: {
    body: ProjectItem
  }): Observable<StrictHttpResponse<{
'isSuccess'?: boolean;
'message'?: string;
'data'?: string;
}>> {

    const rb = new RequestBuilder(this.rootUrl, ApiService.ProjectUpdatePath, 'put');
    if (params) {
      rb.body(params.body, 'application/json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<{
        'isSuccess'?: boolean;
        'message'?: string;
        'data'?: string;
        }>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `projectUpdate$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  projectUpdate(params: {
    body: ProjectItem
  }): Observable<{
'isSuccess'?: boolean;
'message'?: string;
'data'?: string;
}> {

    return this.projectUpdate$Response(params).pipe(
      map((r: StrictHttpResponse<{
'isSuccess'?: boolean;
'message'?: string;
'data'?: string;
}>) => r.body as {
'isSuccess'?: boolean;
'message'?: string;
'data'?: string;
})
    );
  }

  /**
   * Path part for operation projectDelete
   */
  static readonly ProjectDeletePath = '/v1/performanceManagement/delete/project';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `projectDelete()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  projectDelete$Response(params: {
    body: DeleteRequest
  }): Observable<StrictHttpResponse<{
'isSuccess'?: boolean;
'message'?: string;
'data'?: string;
}>> {

    const rb = new RequestBuilder(this.rootUrl, ApiService.ProjectDeletePath, 'delete');
    if (params) {
      rb.body(params.body, 'application/json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<{
        'isSuccess'?: boolean;
        'message'?: string;
        'data'?: string;
        }>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `projectDelete$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  projectDelete(params: {
    body: DeleteRequest
  }): Observable<{
'isSuccess'?: boolean;
'message'?: string;
'data'?: string;
}> {

    return this.projectDelete$Response(params).pipe(
      map((r: StrictHttpResponse<{
'isSuccess'?: boolean;
'message'?: string;
'data'?: string;
}>) => r.body as {
'isSuccess'?: boolean;
'message'?: string;
'data'?: string;
})
    );
  }

  /**
   * Path part for operation projectFinalStatusUpdate
   */
  static readonly ProjectFinalStatusUpdatePath = '/v1/performanceManagement/update/status/project';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `projectFinalStatusUpdate()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  projectFinalStatusUpdate$Response(params: {
    body: ProjectStatusUpdateRequest
  }): Observable<StrictHttpResponse<{
'isSuccess'?: boolean;
'message'?: string;
'data'?: string;
}>> {

    const rb = new RequestBuilder(this.rootUrl, ApiService.ProjectFinalStatusUpdatePath, 'put');
    if (params) {
      rb.body(params.body, 'application/json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<{
        'isSuccess'?: boolean;
        'message'?: string;
        'data'?: string;
        }>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `projectFinalStatusUpdate$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  projectFinalStatusUpdate(params: {
    body: ProjectStatusUpdateRequest
  }): Observable<{
'isSuccess'?: boolean;
'message'?: string;
'data'?: string;
}> {

    return this.projectFinalStatusUpdate$Response(params).pipe(
      map((r: StrictHttpResponse<{
'isSuccess'?: boolean;
'message'?: string;
'data'?: string;
}>) => r.body as {
'isSuccess'?: boolean;
'message'?: string;
'data'?: string;
})
    );
  }

  /**
   * Path part for operation actionPlanList
   */
  static readonly ActionPlanListPath = '/v1/performanceManagement/list/actionPlan';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `actionPlanList()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  actionPlanList$Response(params: {
    body: ProjectActionRequest
  }): Observable<StrictHttpResponse<{
'isSuccess'?: boolean;
'message'?: string;
'data'?: {
'pageNumber'?: number;
'pageSize'?: number;
'totalRowCount'?: number;
'items'?: Array<ProjectActionPlanDetailItem>;
};
}>> {

    const rb = new RequestBuilder(this.rootUrl, ApiService.ActionPlanListPath, 'post');
    if (params) {
      rb.body(params.body, 'application/json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<{
        'isSuccess'?: boolean;
        'message'?: string;
        'data'?: {
        'pageNumber'?: number;
        'pageSize'?: number;
        'totalRowCount'?: number;
        'items'?: Array<ProjectActionPlanDetailItem>;
        };
        }>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `actionPlanList$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  actionPlanList(params: {
    body: ProjectActionRequest
  }): Observable<{
'isSuccess'?: boolean;
'message'?: string;
'data'?: {
'pageNumber'?: number;
'pageSize'?: number;
'totalRowCount'?: number;
'items'?: Array<ProjectActionPlanDetailItem>;
};
}> {

    return this.actionPlanList$Response(params).pipe(
      map((r: StrictHttpResponse<{
'isSuccess'?: boolean;
'message'?: string;
'data'?: {
'pageNumber'?: number;
'pageSize'?: number;
'totalRowCount'?: number;
'items'?: Array<ProjectActionPlanDetailItem>;
};
}>) => r.body as {
'isSuccess'?: boolean;
'message'?: string;
'data'?: {
'pageNumber'?: number;
'pageSize'?: number;
'totalRowCount'?: number;
'items'?: Array<ProjectActionPlanDetailItem>;
};
})
    );
  }

  /**
   * Path part for operation actionPlanAdd
   */
  static readonly ActionPlanAddPath = '/v1/performanceManagement/add/actionPlan';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `actionPlanAdd()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  actionPlanAdd$Response(params: {
    body: ActionPlanItem
  }): Observable<StrictHttpResponse<{
'isSuccess'?: boolean;
'message'?: string;
'data'?: string;
}>> {

    const rb = new RequestBuilder(this.rootUrl, ApiService.ActionPlanAddPath, 'post');
    if (params) {
      rb.body(params.body, 'application/json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<{
        'isSuccess'?: boolean;
        'message'?: string;
        'data'?: string;
        }>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `actionPlanAdd$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  actionPlanAdd(params: {
    body: ActionPlanItem
  }): Observable<{
'isSuccess'?: boolean;
'message'?: string;
'data'?: string;
}> {

    return this.actionPlanAdd$Response(params).pipe(
      map((r: StrictHttpResponse<{
'isSuccess'?: boolean;
'message'?: string;
'data'?: string;
}>) => r.body as {
'isSuccess'?: boolean;
'message'?: string;
'data'?: string;
})
    );
  }

  /**
   * Path part for operation actionPlanUpdate
   */
  static readonly ActionPlanUpdatePath = '/v1/performanceManagement/update/actionPlan';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `actionPlanUpdate()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  actionPlanUpdate$Response(params: {
    body: ActionPlanItem
  }): Observable<StrictHttpResponse<{
'isSuccess'?: boolean;
'message'?: string;
'data'?: string;
}>> {

    const rb = new RequestBuilder(this.rootUrl, ApiService.ActionPlanUpdatePath, 'put');
    if (params) {
      rb.body(params.body, 'application/json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<{
        'isSuccess'?: boolean;
        'message'?: string;
        'data'?: string;
        }>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `actionPlanUpdate$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  actionPlanUpdate(params: {
    body: ActionPlanItem
  }): Observable<{
'isSuccess'?: boolean;
'message'?: string;
'data'?: string;
}> {

    return this.actionPlanUpdate$Response(params).pipe(
      map((r: StrictHttpResponse<{
'isSuccess'?: boolean;
'message'?: string;
'data'?: string;
}>) => r.body as {
'isSuccess'?: boolean;
'message'?: string;
'data'?: string;
})
    );
  }

  /**
   * Path part for operation actionPlanApprove
   */
  static readonly ActionPlanApprovePath = '/v1/performanceManagement/approve/actionPlan';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `actionPlanApprove()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  actionPlanApprove$Response(params: {
    body: ActionPlanItem
  }): Observable<StrictHttpResponse<{
'isSuccess'?: boolean;
'message'?: string;
'data'?: string;
}>> {

    const rb = new RequestBuilder(this.rootUrl, ApiService.ActionPlanApprovePath, 'put');
    if (params) {
      rb.body(params.body, 'application/json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<{
        'isSuccess'?: boolean;
        'message'?: string;
        'data'?: string;
        }>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `actionPlanApprove$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  actionPlanApprove(params: {
    body: ActionPlanItem
  }): Observable<{
'isSuccess'?: boolean;
'message'?: string;
'data'?: string;
}> {

    return this.actionPlanApprove$Response(params).pipe(
      map((r: StrictHttpResponse<{
'isSuccess'?: boolean;
'message'?: string;
'data'?: string;
}>) => r.body as {
'isSuccess'?: boolean;
'message'?: string;
'data'?: string;
})
    );
  }

  /**
   * Path part for operation actionStepBulkAdd
   */
  static readonly ActionStepBulkAddPath = '/v1/performanceManagement/bulk/add/actionStep';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `actionStepBulkAdd()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  actionStepBulkAdd$Response(params: {
    body: Array<ActionStep>
  }): Observable<StrictHttpResponse<{
'isSuccess'?: boolean;
'message'?: string;
'data'?: string;
}>> {

    const rb = new RequestBuilder(this.rootUrl, ApiService.ActionStepBulkAddPath, 'post');
    if (params) {
      rb.body(params.body, 'application/json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<{
        'isSuccess'?: boolean;
        'message'?: string;
        'data'?: string;
        }>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `actionStepBulkAdd$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  actionStepBulkAdd(params: {
    body: Array<ActionStep>
  }): Observable<{
'isSuccess'?: boolean;
'message'?: string;
'data'?: string;
}> {

    return this.actionStepBulkAdd$Response(params).pipe(
      map((r: StrictHttpResponse<{
'isSuccess'?: boolean;
'message'?: string;
'data'?: string;
}>) => r.body as {
'isSuccess'?: boolean;
'message'?: string;
'data'?: string;
})
    );
  }

  /**
   * Path part for operation actionStepUpdate
   */
  static readonly ActionStepUpdatePath = '/v1/performanceManagement/update/actionStep';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `actionStepUpdate()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  actionStepUpdate$Response(params: {
    body: ActionStep
  }): Observable<StrictHttpResponse<{
'isSuccess'?: boolean;
'message'?: string;
'data'?: string;
}>> {

    const rb = new RequestBuilder(this.rootUrl, ApiService.ActionStepUpdatePath, 'post');
    if (params) {
      rb.body(params.body, 'application/json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<{
        'isSuccess'?: boolean;
        'message'?: string;
        'data'?: string;
        }>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `actionStepUpdate$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  actionStepUpdate(params: {
    body: ActionStep
  }): Observable<{
'isSuccess'?: boolean;
'message'?: string;
'data'?: string;
}> {

    return this.actionStepUpdate$Response(params).pipe(
      map((r: StrictHttpResponse<{
'isSuccess'?: boolean;
'message'?: string;
'data'?: string;
}>) => r.body as {
'isSuccess'?: boolean;
'message'?: string;
'data'?: string;
})
    );
  }

  /**
   * Path part for operation projectCommentList
   */
  static readonly ProjectCommentListPath = '/v1/performanceManagement/list/projectComment';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `projectCommentList()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  projectCommentList$Response(params: {
    body: EntityGetRequest
  }): Observable<StrictHttpResponse<{
'isSuccess'?: boolean;
'message'?: string;
'data'?: {
'pageNumber'?: number;
'pageSize'?: number;
'totalRowCount'?: number;
'items'?: Array<ProjectCommentListItem>;
};
}>> {

    const rb = new RequestBuilder(this.rootUrl, ApiService.ProjectCommentListPath, 'post');
    if (params) {
      rb.body(params.body, 'application/json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<{
        'isSuccess'?: boolean;
        'message'?: string;
        'data'?: {
        'pageNumber'?: number;
        'pageSize'?: number;
        'totalRowCount'?: number;
        'items'?: Array<ProjectCommentListItem>;
        };
        }>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `projectCommentList$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  projectCommentList(params: {
    body: EntityGetRequest
  }): Observable<{
'isSuccess'?: boolean;
'message'?: string;
'data'?: {
'pageNumber'?: number;
'pageSize'?: number;
'totalRowCount'?: number;
'items'?: Array<ProjectCommentListItem>;
};
}> {

    return this.projectCommentList$Response(params).pipe(
      map((r: StrictHttpResponse<{
'isSuccess'?: boolean;
'message'?: string;
'data'?: {
'pageNumber'?: number;
'pageSize'?: number;
'totalRowCount'?: number;
'items'?: Array<ProjectCommentListItem>;
};
}>) => r.body as {
'isSuccess'?: boolean;
'message'?: string;
'data'?: {
'pageNumber'?: number;
'pageSize'?: number;
'totalRowCount'?: number;
'items'?: Array<ProjectCommentListItem>;
};
})
    );
  }

  /**
   * Path part for operation projectCommentAdd
   */
  static readonly ProjectCommentAddPath = '/v1/performanceManagement/add/projectComment';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `projectCommentAdd()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  projectCommentAdd$Response(params: {
    body: ProjectCommentItem
  }): Observable<StrictHttpResponse<{
'isSuccess'?: boolean;
'message'?: string;
'data'?: string;
}>> {

    const rb = new RequestBuilder(this.rootUrl, ApiService.ProjectCommentAddPath, 'post');
    if (params) {
      rb.body(params.body, 'application/json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<{
        'isSuccess'?: boolean;
        'message'?: string;
        'data'?: string;
        }>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `projectCommentAdd$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  projectCommentAdd(params: {
    body: ProjectCommentItem
  }): Observable<{
'isSuccess'?: boolean;
'message'?: string;
'data'?: string;
}> {

    return this.projectCommentAdd$Response(params).pipe(
      map((r: StrictHttpResponse<{
'isSuccess'?: boolean;
'message'?: string;
'data'?: string;
}>) => r.body as {
'isSuccess'?: boolean;
'message'?: string;
'data'?: string;
})
    );
  }

  /**
   * Path part for operation projectCommentUpdate
   */
  static readonly ProjectCommentUpdatePath = '/v1/performanceManagement/update/projectComment';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `projectCommentUpdate()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  projectCommentUpdate$Response(params: {
    body: ProjectCommentItem
  }): Observable<StrictHttpResponse<{
'isSuccess'?: boolean;
'message'?: string;
'data'?: string;
}>> {

    const rb = new RequestBuilder(this.rootUrl, ApiService.ProjectCommentUpdatePath, 'put');
    if (params) {
      rb.body(params.body, 'application/json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<{
        'isSuccess'?: boolean;
        'message'?: string;
        'data'?: string;
        }>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `projectCommentUpdate$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  projectCommentUpdate(params: {
    body: ProjectCommentItem
  }): Observable<{
'isSuccess'?: boolean;
'message'?: string;
'data'?: string;
}> {

    return this.projectCommentUpdate$Response(params).pipe(
      map((r: StrictHttpResponse<{
'isSuccess'?: boolean;
'message'?: string;
'data'?: string;
}>) => r.body as {
'isSuccess'?: boolean;
'message'?: string;
'data'?: string;
})
    );
  }

  /**
   * Path part for operation taskList
   */
  static readonly TaskListPath = '/v1/performanceManagement/list/task';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `taskList()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  taskList$Response(params: {
    body: EntityGetRequest
  }): Observable<StrictHttpResponse<{
'isSuccess'?: boolean;
'message'?: string;
'data'?: {
'pageNumber'?: number;
'pageSize'?: number;
'totalRowCount'?: number;
'items'?: Array<TaskListItem>;
};
}>> {

    const rb = new RequestBuilder(this.rootUrl, ApiService.TaskListPath, 'post');
    if (params) {
      rb.body(params.body, 'application/json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<{
        'isSuccess'?: boolean;
        'message'?: string;
        'data'?: {
        'pageNumber'?: number;
        'pageSize'?: number;
        'totalRowCount'?: number;
        'items'?: Array<TaskListItem>;
        };
        }>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `taskList$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  taskList(params: {
    body: EntityGetRequest
  }): Observable<{
'isSuccess'?: boolean;
'message'?: string;
'data'?: {
'pageNumber'?: number;
'pageSize'?: number;
'totalRowCount'?: number;
'items'?: Array<TaskListItem>;
};
}> {

    return this.taskList$Response(params).pipe(
      map((r: StrictHttpResponse<{
'isSuccess'?: boolean;
'message'?: string;
'data'?: {
'pageNumber'?: number;
'pageSize'?: number;
'totalRowCount'?: number;
'items'?: Array<TaskListItem>;
};
}>) => r.body as {
'isSuccess'?: boolean;
'message'?: string;
'data'?: {
'pageNumber'?: number;
'pageSize'?: number;
'totalRowCount'?: number;
'items'?: Array<TaskListItem>;
};
})
    );
  }

  /**
   * Path part for operation taskListForAUser
   */
  static readonly TaskListForAUserPath = '/v1/performanceManagement/list/user/task';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `taskListForAUser()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  taskListForAUser$Response(params: {
    body: PerformanceManagementEntityRequest
  }): Observable<StrictHttpResponse<{
'isSuccess'?: boolean;
'message'?: string;
'data'?: {
'pageNumber'?: number;
'pageSize'?: number;
'totalRowCount'?: number;
'items'?: Array<TaskListItem>;
};
}>> {

    const rb = new RequestBuilder(this.rootUrl, ApiService.TaskListForAUserPath, 'post');
    if (params) {
      rb.body(params.body, 'application/json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<{
        'isSuccess'?: boolean;
        'message'?: string;
        'data'?: {
        'pageNumber'?: number;
        'pageSize'?: number;
        'totalRowCount'?: number;
        'items'?: Array<TaskListItem>;
        };
        }>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `taskListForAUser$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  taskListForAUser(params: {
    body: PerformanceManagementEntityRequest
  }): Observable<{
'isSuccess'?: boolean;
'message'?: string;
'data'?: {
'pageNumber'?: number;
'pageSize'?: number;
'totalRowCount'?: number;
'items'?: Array<TaskListItem>;
};
}> {

    return this.taskListForAUser$Response(params).pipe(
      map((r: StrictHttpResponse<{
'isSuccess'?: boolean;
'message'?: string;
'data'?: {
'pageNumber'?: number;
'pageSize'?: number;
'totalRowCount'?: number;
'items'?: Array<TaskListItem>;
};
}>) => r.body as {
'isSuccess'?: boolean;
'message'?: string;
'data'?: {
'pageNumber'?: number;
'pageSize'?: number;
'totalRowCount'?: number;
'items'?: Array<TaskListItem>;
};
})
    );
  }

  /**
   * Path part for operation taskListForAManager
   */
  static readonly TaskListForAManagerPath = '/v1/performanceManagement/list/manager/task';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `taskListForAManager()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  taskListForAManager$Response(params: {
    body: PerformanceManagementEntityRequest
  }): Observable<StrictHttpResponse<{
'isSuccess'?: boolean;
'message'?: string;
'data'?: {
'pageNumber'?: number;
'pageSize'?: number;
'totalRowCount'?: number;
'items'?: Array<TaskListItem>;
};
}>> {

    const rb = new RequestBuilder(this.rootUrl, ApiService.TaskListForAManagerPath, 'post');
    if (params) {
      rb.body(params.body, 'application/json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<{
        'isSuccess'?: boolean;
        'message'?: string;
        'data'?: {
        'pageNumber'?: number;
        'pageSize'?: number;
        'totalRowCount'?: number;
        'items'?: Array<TaskListItem>;
        };
        }>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `taskListForAManager$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  taskListForAManager(params: {
    body: PerformanceManagementEntityRequest
  }): Observable<{
'isSuccess'?: boolean;
'message'?: string;
'data'?: {
'pageNumber'?: number;
'pageSize'?: number;
'totalRowCount'?: number;
'items'?: Array<TaskListItem>;
};
}> {

    return this.taskListForAManager$Response(params).pipe(
      map((r: StrictHttpResponse<{
'isSuccess'?: boolean;
'message'?: string;
'data'?: {
'pageNumber'?: number;
'pageSize'?: number;
'totalRowCount'?: number;
'items'?: Array<TaskListItem>;
};
}>) => r.body as {
'isSuccess'?: boolean;
'message'?: string;
'data'?: {
'pageNumber'?: number;
'pageSize'?: number;
'totalRowCount'?: number;
'items'?: Array<TaskListItem>;
};
})
    );
  }

  /**
   * Path part for operation taskAdd
   */
  static readonly TaskAddPath = '/v1/performanceManagement/add/task';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `taskAdd()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  taskAdd$Response(params: {
    body: TaskListItem
  }): Observable<StrictHttpResponse<{
'isSuccess'?: boolean;
'message'?: string;
'data'?: string;
}>> {

    const rb = new RequestBuilder(this.rootUrl, ApiService.TaskAddPath, 'post');
    if (params) {
      rb.body(params.body, 'application/json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<{
        'isSuccess'?: boolean;
        'message'?: string;
        'data'?: string;
        }>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `taskAdd$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  taskAdd(params: {
    body: TaskListItem
  }): Observable<{
'isSuccess'?: boolean;
'message'?: string;
'data'?: string;
}> {

    return this.taskAdd$Response(params).pipe(
      map((r: StrictHttpResponse<{
'isSuccess'?: boolean;
'message'?: string;
'data'?: string;
}>) => r.body as {
'isSuccess'?: boolean;
'message'?: string;
'data'?: string;
})
    );
  }

  /**
   * Path part for operation taskUpdate
   */
  static readonly TaskUpdatePath = '/v1/performanceManagement/update/task';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `taskUpdate()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  taskUpdate$Response(params: {
    body: TaskListItem
  }): Observable<StrictHttpResponse<{
'isSuccess'?: boolean;
'message'?: string;
'data'?: string;
}>> {

    const rb = new RequestBuilder(this.rootUrl, ApiService.TaskUpdatePath, 'put');
    if (params) {
      rb.body(params.body, 'application/json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<{
        'isSuccess'?: boolean;
        'message'?: string;
        'data'?: string;
        }>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `taskUpdate$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  taskUpdate(params: {
    body: TaskListItem
  }): Observable<{
'isSuccess'?: boolean;
'message'?: string;
'data'?: string;
}> {

    return this.taskUpdate$Response(params).pipe(
      map((r: StrictHttpResponse<{
'isSuccess'?: boolean;
'message'?: string;
'data'?: string;
}>) => r.body as {
'isSuccess'?: boolean;
'message'?: string;
'data'?: string;
})
    );
  }

  /**
   * Path part for operation taskDelete
   */
  static readonly TaskDeletePath = '/v1/performanceManagement/delete/task';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `taskDelete()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  taskDelete$Response(params: {
    body: DeleteRequest
  }): Observable<StrictHttpResponse<{
'isSuccess'?: boolean;
'message'?: string;
'data'?: string;
}>> {

    const rb = new RequestBuilder(this.rootUrl, ApiService.TaskDeletePath, 'delete');
    if (params) {
      rb.body(params.body, 'application/json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<{
        'isSuccess'?: boolean;
        'message'?: string;
        'data'?: string;
        }>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `taskDelete$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  taskDelete(params: {
    body: DeleteRequest
  }): Observable<{
'isSuccess'?: boolean;
'message'?: string;
'data'?: string;
}> {

    return this.taskDelete$Response(params).pipe(
      map((r: StrictHttpResponse<{
'isSuccess'?: boolean;
'message'?: string;
'data'?: string;
}>) => r.body as {
'isSuccess'?: boolean;
'message'?: string;
'data'?: string;
})
    );
  }

  /**
   * Path part for operation performanceManagementStatusList
   */
  static readonly PerformanceManagementStatusListPath = '/v1/performanceManagement/list/status';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `performanceManagementStatusList()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  performanceManagementStatusList$Response(params: {
    body: EntityGetRequest
  }): Observable<StrictHttpResponse<{
'isSuccess'?: boolean;
'message'?: string;
'data'?: {
'pageNumber'?: number;
'pageSize'?: number;
'totalRowCount'?: number;
'items'?: Array<PerformanceManagementStatusListItem>;
};
}>> {

    const rb = new RequestBuilder(this.rootUrl, ApiService.PerformanceManagementStatusListPath, 'post');
    if (params) {
      rb.body(params.body, 'application/json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<{
        'isSuccess'?: boolean;
        'message'?: string;
        'data'?: {
        'pageNumber'?: number;
        'pageSize'?: number;
        'totalRowCount'?: number;
        'items'?: Array<PerformanceManagementStatusListItem>;
        };
        }>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `performanceManagementStatusList$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  performanceManagementStatusList(params: {
    body: EntityGetRequest
  }): Observable<{
'isSuccess'?: boolean;
'message'?: string;
'data'?: {
'pageNumber'?: number;
'pageSize'?: number;
'totalRowCount'?: number;
'items'?: Array<PerformanceManagementStatusListItem>;
};
}> {

    return this.performanceManagementStatusList$Response(params).pipe(
      map((r: StrictHttpResponse<{
'isSuccess'?: boolean;
'message'?: string;
'data'?: {
'pageNumber'?: number;
'pageSize'?: number;
'totalRowCount'?: number;
'items'?: Array<PerformanceManagementStatusListItem>;
};
}>) => r.body as {
'isSuccess'?: boolean;
'message'?: string;
'data'?: {
'pageNumber'?: number;
'pageSize'?: number;
'totalRowCount'?: number;
'items'?: Array<PerformanceManagementStatusListItem>;
};
})
    );
  }

  /**
   * Path part for operation commentList
   */
  static readonly CommentListPath = '/v1/performanceManagement/list/comment';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `commentList()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  commentList$Response(params: {
    body: EntityGetRequest
  }): Observable<StrictHttpResponse<{
'isSuccess'?: boolean;
'message'?: string;
'data'?: {
'pageNumber'?: number;
'pageSize'?: number;
'totalRowCount'?: number;
'items'?: Array<CommentListItem>;
};
}>> {

    const rb = new RequestBuilder(this.rootUrl, ApiService.CommentListPath, 'post');
    if (params) {
      rb.body(params.body, 'application/json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<{
        'isSuccess'?: boolean;
        'message'?: string;
        'data'?: {
        'pageNumber'?: number;
        'pageSize'?: number;
        'totalRowCount'?: number;
        'items'?: Array<CommentListItem>;
        };
        }>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `commentList$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  commentList(params: {
    body: EntityGetRequest
  }): Observable<{
'isSuccess'?: boolean;
'message'?: string;
'data'?: {
'pageNumber'?: number;
'pageSize'?: number;
'totalRowCount'?: number;
'items'?: Array<CommentListItem>;
};
}> {

    return this.commentList$Response(params).pipe(
      map((r: StrictHttpResponse<{
'isSuccess'?: boolean;
'message'?: string;
'data'?: {
'pageNumber'?: number;
'pageSize'?: number;
'totalRowCount'?: number;
'items'?: Array<CommentListItem>;
};
}>) => r.body as {
'isSuccess'?: boolean;
'message'?: string;
'data'?: {
'pageNumber'?: number;
'pageSize'?: number;
'totalRowCount'?: number;
'items'?: Array<CommentListItem>;
};
})
    );
  }

  /**
   * Path part for operation commentSnapshotList
   */
  static readonly CommentSnapshotListPath = '/v1/performanceManagement/list/snapShot/comment';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `commentSnapshotList()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  commentSnapshotList$Response(params: {
    body: EntityGetRequest
  }): Observable<StrictHttpResponse<{
'isSuccess'?: boolean;
'message'?: string;
'data'?: {
'pageNumber'?: number;
'pageSize'?: number;
'totalRowCount'?: number;
'items'?: Array<CommentListItem>;
};
}>> {

    const rb = new RequestBuilder(this.rootUrl, ApiService.CommentSnapshotListPath, 'post');
    if (params) {
      rb.body(params.body, 'application/json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<{
        'isSuccess'?: boolean;
        'message'?: string;
        'data'?: {
        'pageNumber'?: number;
        'pageSize'?: number;
        'totalRowCount'?: number;
        'items'?: Array<CommentListItem>;
        };
        }>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `commentSnapshotList$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  commentSnapshotList(params: {
    body: EntityGetRequest
  }): Observable<{
'isSuccess'?: boolean;
'message'?: string;
'data'?: {
'pageNumber'?: number;
'pageSize'?: number;
'totalRowCount'?: number;
'items'?: Array<CommentListItem>;
};
}> {

    return this.commentSnapshotList$Response(params).pipe(
      map((r: StrictHttpResponse<{
'isSuccess'?: boolean;
'message'?: string;
'data'?: {
'pageNumber'?: number;
'pageSize'?: number;
'totalRowCount'?: number;
'items'?: Array<CommentListItem>;
};
}>) => r.body as {
'isSuccess'?: boolean;
'message'?: string;
'data'?: {
'pageNumber'?: number;
'pageSize'?: number;
'totalRowCount'?: number;
'items'?: Array<CommentListItem>;
};
})
    );
  }

  /**
   * Path part for operation commentAdd
   */
  static readonly CommentAddPath = '/v1/performanceManagement/add/comment';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `commentAdd()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  commentAdd$Response(params: {
    body: CommentAddRequest
  }): Observable<StrictHttpResponse<{
'isSuccess'?: boolean;
'message'?: string;
'data'?: string;
}>> {

    const rb = new RequestBuilder(this.rootUrl, ApiService.CommentAddPath, 'post');
    if (params) {
      rb.body(params.body, 'application/json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<{
        'isSuccess'?: boolean;
        'message'?: string;
        'data'?: string;
        }>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `commentAdd$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  commentAdd(params: {
    body: CommentAddRequest
  }): Observable<{
'isSuccess'?: boolean;
'message'?: string;
'data'?: string;
}> {

    return this.commentAdd$Response(params).pipe(
      map((r: StrictHttpResponse<{
'isSuccess'?: boolean;
'message'?: string;
'data'?: string;
}>) => r.body as {
'isSuccess'?: boolean;
'message'?: string;
'data'?: string;
})
    );
  }

  /**
   * Path part for operation commentUpdate
   */
  static readonly CommentUpdatePath = '/v1/performanceManagement/update/comment';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `commentUpdate()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  commentUpdate$Response(params: {
    body: CommentUpdateRequest
  }): Observable<StrictHttpResponse<{
'isSuccess'?: boolean;
'message'?: string;
'data'?: string;
}>> {

    const rb = new RequestBuilder(this.rootUrl, ApiService.CommentUpdatePath, 'put');
    if (params) {
      rb.body(params.body, 'application/json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<{
        'isSuccess'?: boolean;
        'message'?: string;
        'data'?: string;
        }>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `commentUpdate$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  commentUpdate(params: {
    body: CommentUpdateRequest
  }): Observable<{
'isSuccess'?: boolean;
'message'?: string;
'data'?: string;
}> {

    return this.commentUpdate$Response(params).pipe(
      map((r: StrictHttpResponse<{
'isSuccess'?: boolean;
'message'?: string;
'data'?: string;
}>) => r.body as {
'isSuccess'?: boolean;
'message'?: string;
'data'?: string;
})
    );
  }

  /**
   * Path part for operation deleteComment
   */
  static readonly DeleteCommentPath = '/v1/performanceManagement/delete/comment';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `deleteComment()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  deleteComment$Response(params: {
    body: DeleteCommentRequest
  }): Observable<StrictHttpResponse<{
'isSuccess'?: boolean;
'message'?: string;
'data'?: string;
}>> {

    const rb = new RequestBuilder(this.rootUrl, ApiService.DeleteCommentPath, 'delete');
    if (params) {
      rb.body(params.body, 'application/json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<{
        'isSuccess'?: boolean;
        'message'?: string;
        'data'?: string;
        }>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `deleteComment$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  deleteComment(params: {
    body: DeleteCommentRequest
  }): Observable<{
'isSuccess'?: boolean;
'message'?: string;
'data'?: string;
}> {

    return this.deleteComment$Response(params).pipe(
      map((r: StrictHttpResponse<{
'isSuccess'?: boolean;
'message'?: string;
'data'?: string;
}>) => r.body as {
'isSuccess'?: boolean;
'message'?: string;
'data'?: string;
})
    );
  }

  /**
   * Path part for operation monthlyReviewAdd
   */
  static readonly MonthlyReviewAddPath = '/v1/performanceManagement/add/monthlyReview';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `monthlyReviewAdd()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  monthlyReviewAdd$Response(params: {
    body: AddMonthlyReviewRequest
  }): Observable<StrictHttpResponse<{
'isSuccess'?: boolean;
'message'?: string;
'data'?: string;
}>> {

    const rb = new RequestBuilder(this.rootUrl, ApiService.MonthlyReviewAddPath, 'post');
    if (params) {
      rb.body(params.body, 'application/json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<{
        'isSuccess'?: boolean;
        'message'?: string;
        'data'?: string;
        }>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `monthlyReviewAdd$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  monthlyReviewAdd(params: {
    body: AddMonthlyReviewRequest
  }): Observable<{
'isSuccess'?: boolean;
'message'?: string;
'data'?: string;
}> {

    return this.monthlyReviewAdd$Response(params).pipe(
      map((r: StrictHttpResponse<{
'isSuccess'?: boolean;
'message'?: string;
'data'?: string;
}>) => r.body as {
'isSuccess'?: boolean;
'message'?: string;
'data'?: string;
})
    );
  }

  /**
   * Path part for operation monthlyReviewUpdate
   */
  static readonly MonthlyReviewUpdatePath = '/v1/performanceManagement/update/monthlyReview';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `monthlyReviewUpdate()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  monthlyReviewUpdate$Response(params: {
    body: UpdateMonthlyReviewRequest
  }): Observable<StrictHttpResponse<{
'isSuccess'?: boolean;
'message'?: string;
'data'?: string;
}>> {

    const rb = new RequestBuilder(this.rootUrl, ApiService.MonthlyReviewUpdatePath, 'post');
    if (params) {
      rb.body(params.body, 'application/json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<{
        'isSuccess'?: boolean;
        'message'?: string;
        'data'?: string;
        }>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `monthlyReviewUpdate$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  monthlyReviewUpdate(params: {
    body: UpdateMonthlyReviewRequest
  }): Observable<{
'isSuccess'?: boolean;
'message'?: string;
'data'?: string;
}> {

    return this.monthlyReviewUpdate$Response(params).pipe(
      map((r: StrictHttpResponse<{
'isSuccess'?: boolean;
'message'?: string;
'data'?: string;
}>) => r.body as {
'isSuccess'?: boolean;
'message'?: string;
'data'?: string;
})
    );
  }

  /**
   * Path part for operation monthlyReviewList
   */
  static readonly MonthlyReviewListPath = '/v1/performanceManagement/list/monthlyReview';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `monthlyReviewList()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  monthlyReviewList$Response(params: {
    body: PerformanceManagementEntityRequest
  }): Observable<StrictHttpResponse<{
'isSuccess'?: boolean;
'message'?: string;
'data'?: {
'pageNumber'?: number;
'pageSize'?: number;
'totalRowCount'?: number;
'items'?: Array<MonthlyReviewListItem>;
};
}>> {

    const rb = new RequestBuilder(this.rootUrl, ApiService.MonthlyReviewListPath, 'post');
    if (params) {
      rb.body(params.body, 'application/json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<{
        'isSuccess'?: boolean;
        'message'?: string;
        'data'?: {
        'pageNumber'?: number;
        'pageSize'?: number;
        'totalRowCount'?: number;
        'items'?: Array<MonthlyReviewListItem>;
        };
        }>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `monthlyReviewList$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  monthlyReviewList(params: {
    body: PerformanceManagementEntityRequest
  }): Observable<{
'isSuccess'?: boolean;
'message'?: string;
'data'?: {
'pageNumber'?: number;
'pageSize'?: number;
'totalRowCount'?: number;
'items'?: Array<MonthlyReviewListItem>;
};
}> {

    return this.monthlyReviewList$Response(params).pipe(
      map((r: StrictHttpResponse<{
'isSuccess'?: boolean;
'message'?: string;
'data'?: {
'pageNumber'?: number;
'pageSize'?: number;
'totalRowCount'?: number;
'items'?: Array<MonthlyReviewListItem>;
};
}>) => r.body as {
'isSuccess'?: boolean;
'message'?: string;
'data'?: {
'pageNumber'?: number;
'pageSize'?: number;
'totalRowCount'?: number;
'items'?: Array<MonthlyReviewListItem>;
};
})
    );
  }

  /**
   * Path part for operation completedMonthlyReviewList
   */
  static readonly CompletedMonthlyReviewListPath = '/v1/performanceManagement/list/completed/monthlyReview';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `completedMonthlyReviewList()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  completedMonthlyReviewList$Response(params: {
    body: PerformanceManagementEntityRequest
  }): Observable<StrictHttpResponse<{
'isSuccess'?: boolean;
'message'?: string;
'data'?: {
'pageNumber'?: number;
'pageSize'?: number;
'totalRowCount'?: number;
'items'?: Array<MonthlyReviewListItem>;
};
}>> {

    const rb = new RequestBuilder(this.rootUrl, ApiService.CompletedMonthlyReviewListPath, 'post');
    if (params) {
      rb.body(params.body, 'application/json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<{
        'isSuccess'?: boolean;
        'message'?: string;
        'data'?: {
        'pageNumber'?: number;
        'pageSize'?: number;
        'totalRowCount'?: number;
        'items'?: Array<MonthlyReviewListItem>;
        };
        }>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `completedMonthlyReviewList$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  completedMonthlyReviewList(params: {
    body: PerformanceManagementEntityRequest
  }): Observable<{
'isSuccess'?: boolean;
'message'?: string;
'data'?: {
'pageNumber'?: number;
'pageSize'?: number;
'totalRowCount'?: number;
'items'?: Array<MonthlyReviewListItem>;
};
}> {

    return this.completedMonthlyReviewList$Response(params).pipe(
      map((r: StrictHttpResponse<{
'isSuccess'?: boolean;
'message'?: string;
'data'?: {
'pageNumber'?: number;
'pageSize'?: number;
'totalRowCount'?: number;
'items'?: Array<MonthlyReviewListItem>;
};
}>) => r.body as {
'isSuccess'?: boolean;
'message'?: string;
'data'?: {
'pageNumber'?: number;
'pageSize'?: number;
'totalRowCount'?: number;
'items'?: Array<MonthlyReviewListItem>;
};
})
    );
  }

  /**
   * Path part for operation tasksForMonthlyReview
   */
  static readonly TasksForMonthlyReviewPath = '/v1/performanceManagement/list/tasks/monthlyReview';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `tasksForMonthlyReview()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  tasksForMonthlyReview$Response(params: {
    body: PerformanceManagementEntityRequest
  }): Observable<StrictHttpResponse<{
'isSuccess'?: boolean;
'message'?: string;
'data'?: {
'pageNumber'?: number;
'pageSize'?: number;
'totalRowCount'?: number;
'items'?: Array<TaskListItem>;
};
}>> {

    const rb = new RequestBuilder(this.rootUrl, ApiService.TasksForMonthlyReviewPath, 'post');
    if (params) {
      rb.body(params.body, 'application/json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<{
        'isSuccess'?: boolean;
        'message'?: string;
        'data'?: {
        'pageNumber'?: number;
        'pageSize'?: number;
        'totalRowCount'?: number;
        'items'?: Array<TaskListItem>;
        };
        }>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `tasksForMonthlyReview$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  tasksForMonthlyReview(params: {
    body: PerformanceManagementEntityRequest
  }): Observable<{
'isSuccess'?: boolean;
'message'?: string;
'data'?: {
'pageNumber'?: number;
'pageSize'?: number;
'totalRowCount'?: number;
'items'?: Array<TaskListItem>;
};
}> {

    return this.tasksForMonthlyReview$Response(params).pipe(
      map((r: StrictHttpResponse<{
'isSuccess'?: boolean;
'message'?: string;
'data'?: {
'pageNumber'?: number;
'pageSize'?: number;
'totalRowCount'?: number;
'items'?: Array<TaskListItem>;
};
}>) => r.body as {
'isSuccess'?: boolean;
'message'?: string;
'data'?: {
'pageNumber'?: number;
'pageSize'?: number;
'totalRowCount'?: number;
'items'?: Array<TaskListItem>;
};
})
    );
  }

  /**
   * Path part for operation projectsForMonthlyReview
   */
  static readonly ProjectsForMonthlyReviewPath = '/v1/performanceManagement/list/projects/monthlyReview';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `projectsForMonthlyReview()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  projectsForMonthlyReview$Response(params: {
    body: PerformanceManagementEntityRequest
  }): Observable<StrictHttpResponse<{
'isSuccess'?: boolean;
'message'?: string;
'data'?: {
'pageNumber'?: number;
'pageSize'?: number;
'totalRowCount'?: number;
'items'?: Array<ProjectItem>;
};
}>> {

    const rb = new RequestBuilder(this.rootUrl, ApiService.ProjectsForMonthlyReviewPath, 'post');
    if (params) {
      rb.body(params.body, 'application/json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<{
        'isSuccess'?: boolean;
        'message'?: string;
        'data'?: {
        'pageNumber'?: number;
        'pageSize'?: number;
        'totalRowCount'?: number;
        'items'?: Array<ProjectItem>;
        };
        }>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `projectsForMonthlyReview$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  projectsForMonthlyReview(params: {
    body: PerformanceManagementEntityRequest
  }): Observable<{
'isSuccess'?: boolean;
'message'?: string;
'data'?: {
'pageNumber'?: number;
'pageSize'?: number;
'totalRowCount'?: number;
'items'?: Array<ProjectItem>;
};
}> {

    return this.projectsForMonthlyReview$Response(params).pipe(
      map((r: StrictHttpResponse<{
'isSuccess'?: boolean;
'message'?: string;
'data'?: {
'pageNumber'?: number;
'pageSize'?: number;
'totalRowCount'?: number;
'items'?: Array<ProjectItem>;
};
}>) => r.body as {
'isSuccess'?: boolean;
'message'?: string;
'data'?: {
'pageNumber'?: number;
'pageSize'?: number;
'totalRowCount'?: number;
'items'?: Array<ProjectItem>;
};
})
    );
  }

  /**
   * Path part for operation documentsForMonthlyReview
   */
  static readonly DocumentsForMonthlyReviewPath = '/v1/performanceManagement/list/matchingDocuments/monthlyReview';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `documentsForMonthlyReview()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  documentsForMonthlyReview$Response(params: {
    body: PerformanceManagementEntityRequest
  }): Observable<StrictHttpResponse<{
'isSuccess'?: boolean;
'message'?: string;
'data'?: {
'pageNumber'?: number;
'pageSize'?: number;
'totalRowCount'?: number;
'items'?: Array<MonthlyReviewDocumentListItem>;
};
}>> {

    const rb = new RequestBuilder(this.rootUrl, ApiService.DocumentsForMonthlyReviewPath, 'post');
    if (params) {
      rb.body(params.body, 'application/json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<{
        'isSuccess'?: boolean;
        'message'?: string;
        'data'?: {
        'pageNumber'?: number;
        'pageSize'?: number;
        'totalRowCount'?: number;
        'items'?: Array<MonthlyReviewDocumentListItem>;
        };
        }>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `documentsForMonthlyReview$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  documentsForMonthlyReview(params: {
    body: PerformanceManagementEntityRequest
  }): Observable<{
'isSuccess'?: boolean;
'message'?: string;
'data'?: {
'pageNumber'?: number;
'pageSize'?: number;
'totalRowCount'?: number;
'items'?: Array<MonthlyReviewDocumentListItem>;
};
}> {

    return this.documentsForMonthlyReview$Response(params).pipe(
      map((r: StrictHttpResponse<{
'isSuccess'?: boolean;
'message'?: string;
'data'?: {
'pageNumber'?: number;
'pageSize'?: number;
'totalRowCount'?: number;
'items'?: Array<MonthlyReviewDocumentListItem>;
};
}>) => r.body as {
'isSuccess'?: boolean;
'message'?: string;
'data'?: {
'pageNumber'?: number;
'pageSize'?: number;
'totalRowCount'?: number;
'items'?: Array<MonthlyReviewDocumentListItem>;
};
})
    );
  }

  /**
   * Path part for operation documentsForMonthlyReviewAssignedByManager
   */
  static readonly DocumentsForMonthlyReviewAssignedByManagerPath = '/v1/performanceManagement/list/matchingDocuments/manager/monthlyReview';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `documentsForMonthlyReviewAssignedByManager()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  documentsForMonthlyReviewAssignedByManager$Response(params: {
    body: AllDocumentsAssignedByManagerRequest
  }): Observable<StrictHttpResponse<{
'isSuccess'?: boolean;
'message'?: string;
'data'?: {
'pageNumber'?: number;
'pageSize'?: number;
'totalRowCount'?: number;
'items'?: Array<MonthlyReviewDocumentListItem>;
};
}>> {

    const rb = new RequestBuilder(this.rootUrl, ApiService.DocumentsForMonthlyReviewAssignedByManagerPath, 'post');
    if (params) {
      rb.body(params.body, 'application/json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<{
        'isSuccess'?: boolean;
        'message'?: string;
        'data'?: {
        'pageNumber'?: number;
        'pageSize'?: number;
        'totalRowCount'?: number;
        'items'?: Array<MonthlyReviewDocumentListItem>;
        };
        }>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `documentsForMonthlyReviewAssignedByManager$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  documentsForMonthlyReviewAssignedByManager(params: {
    body: AllDocumentsAssignedByManagerRequest
  }): Observable<{
'isSuccess'?: boolean;
'message'?: string;
'data'?: {
'pageNumber'?: number;
'pageSize'?: number;
'totalRowCount'?: number;
'items'?: Array<MonthlyReviewDocumentListItem>;
};
}> {

    return this.documentsForMonthlyReviewAssignedByManager$Response(params).pipe(
      map((r: StrictHttpResponse<{
'isSuccess'?: boolean;
'message'?: string;
'data'?: {
'pageNumber'?: number;
'pageSize'?: number;
'totalRowCount'?: number;
'items'?: Array<MonthlyReviewDocumentListItem>;
};
}>) => r.body as {
'isSuccess'?: boolean;
'message'?: string;
'data'?: {
'pageNumber'?: number;
'pageSize'?: number;
'totalRowCount'?: number;
'items'?: Array<MonthlyReviewDocumentListItem>;
};
})
    );
  }

  /**
   * Path part for operation documentsForMonthlyReviewAssignedForABusinessLine
   */
  static readonly DocumentsForMonthlyReviewAssignedForABusinessLinePath = '/v1/performanceManagement/list/matchingDocuments/businessLine/monthlyReview';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `documentsForMonthlyReviewAssignedForABusinessLine()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  documentsForMonthlyReviewAssignedForABusinessLine$Response(params: {
    body: AllDocumentsAssignedForABusinessLineRequest
  }): Observable<StrictHttpResponse<{
'isSuccess'?: boolean;
'message'?: string;
'data'?: {
'pageNumber'?: number;
'pageSize'?: number;
'totalRowCount'?: number;
'items'?: Array<MonthlyReviewDocumentListItem>;
};
}>> {

    const rb = new RequestBuilder(this.rootUrl, ApiService.DocumentsForMonthlyReviewAssignedForABusinessLinePath, 'post');
    if (params) {
      rb.body(params.body, 'application/json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<{
        'isSuccess'?: boolean;
        'message'?: string;
        'data'?: {
        'pageNumber'?: number;
        'pageSize'?: number;
        'totalRowCount'?: number;
        'items'?: Array<MonthlyReviewDocumentListItem>;
        };
        }>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `documentsForMonthlyReviewAssignedForABusinessLine$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  documentsForMonthlyReviewAssignedForABusinessLine(params: {
    body: AllDocumentsAssignedForABusinessLineRequest
  }): Observable<{
'isSuccess'?: boolean;
'message'?: string;
'data'?: {
'pageNumber'?: number;
'pageSize'?: number;
'totalRowCount'?: number;
'items'?: Array<MonthlyReviewDocumentListItem>;
};
}> {

    return this.documentsForMonthlyReviewAssignedForABusinessLine$Response(params).pipe(
      map((r: StrictHttpResponse<{
'isSuccess'?: boolean;
'message'?: string;
'data'?: {
'pageNumber'?: number;
'pageSize'?: number;
'totalRowCount'?: number;
'items'?: Array<MonthlyReviewDocumentListItem>;
};
}>) => r.body as {
'isSuccess'?: boolean;
'message'?: string;
'data'?: {
'pageNumber'?: number;
'pageSize'?: number;
'totalRowCount'?: number;
'items'?: Array<MonthlyReviewDocumentListItem>;
};
})
    );
  }

  /**
   * Path part for operation tasksForSelectedMonthlyReview
   */
  static readonly TasksForSelectedMonthlyReviewPath = '/v1/performanceManagement/list/monthlyReview/edit/tasks';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `tasksForSelectedMonthlyReview()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  tasksForSelectedMonthlyReview$Response(params: {
    body: MonthlyReviewEditRequest
  }): Observable<StrictHttpResponse<{
'isSuccess'?: boolean;
'message'?: string;
'data'?: {
'pageNumber'?: number;
'pageSize'?: number;
'totalRowCount'?: number;
'items'?: Array<TaskListItem>;
};
}>> {

    const rb = new RequestBuilder(this.rootUrl, ApiService.TasksForSelectedMonthlyReviewPath, 'post');
    if (params) {
      rb.body(params.body, 'application/json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<{
        'isSuccess'?: boolean;
        'message'?: string;
        'data'?: {
        'pageNumber'?: number;
        'pageSize'?: number;
        'totalRowCount'?: number;
        'items'?: Array<TaskListItem>;
        };
        }>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `tasksForSelectedMonthlyReview$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  tasksForSelectedMonthlyReview(params: {
    body: MonthlyReviewEditRequest
  }): Observable<{
'isSuccess'?: boolean;
'message'?: string;
'data'?: {
'pageNumber'?: number;
'pageSize'?: number;
'totalRowCount'?: number;
'items'?: Array<TaskListItem>;
};
}> {

    return this.tasksForSelectedMonthlyReview$Response(params).pipe(
      map((r: StrictHttpResponse<{
'isSuccess'?: boolean;
'message'?: string;
'data'?: {
'pageNumber'?: number;
'pageSize'?: number;
'totalRowCount'?: number;
'items'?: Array<TaskListItem>;
};
}>) => r.body as {
'isSuccess'?: boolean;
'message'?: string;
'data'?: {
'pageNumber'?: number;
'pageSize'?: number;
'totalRowCount'?: number;
'items'?: Array<TaskListItem>;
};
})
    );
  }

  /**
   * Path part for operation documentsForMonthlyReviewList
   */
  static readonly DocumentsForMonthlyReviewListPath = '/v1/performanceManagement/list/documents/monthlyReview';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `documentsForMonthlyReviewList()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  documentsForMonthlyReviewList$Response(params: {
    body: DocumentGetRequest
  }): Observable<StrictHttpResponse<{
'isSuccess'?: boolean;
'message'?: string;
'data'?: {
'pageNumber'?: number;
'pageSize'?: number;
'totalRowCount'?: number;
'items'?: Array<MonthlyReviewDocumentListItem>;
};
}>> {

    const rb = new RequestBuilder(this.rootUrl, ApiService.DocumentsForMonthlyReviewListPath, 'post');
    if (params) {
      rb.body(params.body, 'application/json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<{
        'isSuccess'?: boolean;
        'message'?: string;
        'data'?: {
        'pageNumber'?: number;
        'pageSize'?: number;
        'totalRowCount'?: number;
        'items'?: Array<MonthlyReviewDocumentListItem>;
        };
        }>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `documentsForMonthlyReviewList$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  documentsForMonthlyReviewList(params: {
    body: DocumentGetRequest
  }): Observable<{
'isSuccess'?: boolean;
'message'?: string;
'data'?: {
'pageNumber'?: number;
'pageSize'?: number;
'totalRowCount'?: number;
'items'?: Array<MonthlyReviewDocumentListItem>;
};
}> {

    return this.documentsForMonthlyReviewList$Response(params).pipe(
      map((r: StrictHttpResponse<{
'isSuccess'?: boolean;
'message'?: string;
'data'?: {
'pageNumber'?: number;
'pageSize'?: number;
'totalRowCount'?: number;
'items'?: Array<MonthlyReviewDocumentListItem>;
};
}>) => r.body as {
'isSuccess'?: boolean;
'message'?: string;
'data'?: {
'pageNumber'?: number;
'pageSize'?: number;
'totalRowCount'?: number;
'items'?: Array<MonthlyReviewDocumentListItem>;
};
})
    );
  }

  /**
   * Path part for operation projectsForSelectedMonthlyReview
   */
  static readonly ProjectsForSelectedMonthlyReviewPath = '/v1/performanceManagement/list/monthlyReview/edit/projects';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `projectsForSelectedMonthlyReview()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  projectsForSelectedMonthlyReview$Response(params: {
    body: MonthlyReviewEditRequest
  }): Observable<StrictHttpResponse<{
'isSuccess'?: boolean;
'message'?: string;
'data'?: {
'pageNumber'?: number;
'pageSize'?: number;
'totalRowCount'?: number;
'items'?: Array<ProjectItem>;
};
}>> {

    const rb = new RequestBuilder(this.rootUrl, ApiService.ProjectsForSelectedMonthlyReviewPath, 'post');
    if (params) {
      rb.body(params.body, 'application/json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<{
        'isSuccess'?: boolean;
        'message'?: string;
        'data'?: {
        'pageNumber'?: number;
        'pageSize'?: number;
        'totalRowCount'?: number;
        'items'?: Array<ProjectItem>;
        };
        }>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `projectsForSelectedMonthlyReview$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  projectsForSelectedMonthlyReview(params: {
    body: MonthlyReviewEditRequest
  }): Observable<{
'isSuccess'?: boolean;
'message'?: string;
'data'?: {
'pageNumber'?: number;
'pageSize'?: number;
'totalRowCount'?: number;
'items'?: Array<ProjectItem>;
};
}> {

    return this.projectsForSelectedMonthlyReview$Response(params).pipe(
      map((r: StrictHttpResponse<{
'isSuccess'?: boolean;
'message'?: string;
'data'?: {
'pageNumber'?: number;
'pageSize'?: number;
'totalRowCount'?: number;
'items'?: Array<ProjectItem>;
};
}>) => r.body as {
'isSuccess'?: boolean;
'message'?: string;
'data'?: {
'pageNumber'?: number;
'pageSize'?: number;
'totalRowCount'?: number;
'items'?: Array<ProjectItem>;
};
})
    );
  }

  /**
   * Path part for operation documentsForSelectedMonthlyReview
   */
  static readonly DocumentsForSelectedMonthlyReviewPath = '/v1/performanceManagement/list/monthlyReview/edit/documents';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `documentsForSelectedMonthlyReview()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  documentsForSelectedMonthlyReview$Response(params: {
    body: MonthlyReviewEditRequest
  }): Observable<StrictHttpResponse<{
'isSuccess'?: boolean;
'message'?: string;
'data'?: {
'pageNumber'?: number;
'pageSize'?: number;
'totalRowCount'?: number;
'items'?: Array<MonthlyReviewDocumentListItem>;
};
}>> {

    const rb = new RequestBuilder(this.rootUrl, ApiService.DocumentsForSelectedMonthlyReviewPath, 'post');
    if (params) {
      rb.body(params.body, 'application/json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<{
        'isSuccess'?: boolean;
        'message'?: string;
        'data'?: {
        'pageNumber'?: number;
        'pageSize'?: number;
        'totalRowCount'?: number;
        'items'?: Array<MonthlyReviewDocumentListItem>;
        };
        }>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `documentsForSelectedMonthlyReview$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  documentsForSelectedMonthlyReview(params: {
    body: MonthlyReviewEditRequest
  }): Observable<{
'isSuccess'?: boolean;
'message'?: string;
'data'?: {
'pageNumber'?: number;
'pageSize'?: number;
'totalRowCount'?: number;
'items'?: Array<MonthlyReviewDocumentListItem>;
};
}> {

    return this.documentsForSelectedMonthlyReview$Response(params).pipe(
      map((r: StrictHttpResponse<{
'isSuccess'?: boolean;
'message'?: string;
'data'?: {
'pageNumber'?: number;
'pageSize'?: number;
'totalRowCount'?: number;
'items'?: Array<MonthlyReviewDocumentListItem>;
};
}>) => r.body as {
'isSuccess'?: boolean;
'message'?: string;
'data'?: {
'pageNumber'?: number;
'pageSize'?: number;
'totalRowCount'?: number;
'items'?: Array<MonthlyReviewDocumentListItem>;
};
})
    );
  }

  /**
   * Path part for operation monthlyReviewDelete
   */
  static readonly MonthlyReviewDeletePath = '/v1/performanceManagement/delete/monthlyReview';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `monthlyReviewDelete()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  monthlyReviewDelete$Response(params: {
    body: DeleteRequest
  }): Observable<StrictHttpResponse<{
'isSuccess'?: boolean;
'message'?: string;
'data'?: string;
}>> {

    const rb = new RequestBuilder(this.rootUrl, ApiService.MonthlyReviewDeletePath, 'delete');
    if (params) {
      rb.body(params.body, 'application/json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<{
        'isSuccess'?: boolean;
        'message'?: string;
        'data'?: string;
        }>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `monthlyReviewDelete$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  monthlyReviewDelete(params: {
    body: DeleteRequest
  }): Observable<{
'isSuccess'?: boolean;
'message'?: string;
'data'?: string;
}> {

    return this.monthlyReviewDelete$Response(params).pipe(
      map((r: StrictHttpResponse<{
'isSuccess'?: boolean;
'message'?: string;
'data'?: string;
}>) => r.body as {
'isSuccess'?: boolean;
'message'?: string;
'data'?: string;
})
    );
  }

  /**
   * Path part for operation monthlyReviewCompelete
   */
  static readonly MonthlyReviewCompeletePath = '/v1/performanceManagement/complete/monthlyReview';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `monthlyReviewCompelete()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  monthlyReviewCompelete$Response(params: {
    body: CompleteMonthlyReviewRequest
  }): Observable<StrictHttpResponse<{
'isSuccess'?: boolean;
'message'?: string;
'data'?: string;
}>> {

    const rb = new RequestBuilder(this.rootUrl, ApiService.MonthlyReviewCompeletePath, 'delete');
    if (params) {
      rb.body(params.body, 'application/json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<{
        'isSuccess'?: boolean;
        'message'?: string;
        'data'?: string;
        }>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `monthlyReviewCompelete$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  monthlyReviewCompelete(params: {
    body: CompleteMonthlyReviewRequest
  }): Observable<{
'isSuccess'?: boolean;
'message'?: string;
'data'?: string;
}> {

    return this.monthlyReviewCompelete$Response(params).pipe(
      map((r: StrictHttpResponse<{
'isSuccess'?: boolean;
'message'?: string;
'data'?: string;
}>) => r.body as {
'isSuccess'?: boolean;
'message'?: string;
'data'?: string;
})
    );
  }

  /**
   * Path part for operation monthlyReviewDocument
   */
  static readonly MonthlyReviewDocumentPath = '/v1/performanceManagement/get/documents/monthlyReview';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `monthlyReviewDocument()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  monthlyReviewDocument$Response(params: {
    body: FetchEntityRequest
  }): Observable<StrictHttpResponse<{
'isSuccess'?: boolean;
'message'?: string;
'data'?: {
'pageNumber'?: number;
'pageSize'?: number;
'totalRowCount'?: number;
'items'?: Array<MonthlyReviewDocumentItem>;
};
}>> {

    const rb = new RequestBuilder(this.rootUrl, ApiService.MonthlyReviewDocumentPath, 'post');
    if (params) {
      rb.body(params.body, 'application/json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<{
        'isSuccess'?: boolean;
        'message'?: string;
        'data'?: {
        'pageNumber'?: number;
        'pageSize'?: number;
        'totalRowCount'?: number;
        'items'?: Array<MonthlyReviewDocumentItem>;
        };
        }>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `monthlyReviewDocument$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  monthlyReviewDocument(params: {
    body: FetchEntityRequest
  }): Observable<{
'isSuccess'?: boolean;
'message'?: string;
'data'?: {
'pageNumber'?: number;
'pageSize'?: number;
'totalRowCount'?: number;
'items'?: Array<MonthlyReviewDocumentItem>;
};
}> {

    return this.monthlyReviewDocument$Response(params).pipe(
      map((r: StrictHttpResponse<{
'isSuccess'?: boolean;
'message'?: string;
'data'?: {
'pageNumber'?: number;
'pageSize'?: number;
'totalRowCount'?: number;
'items'?: Array<MonthlyReviewDocumentItem>;
};
}>) => r.body as {
'isSuccess'?: boolean;
'message'?: string;
'data'?: {
'pageNumber'?: number;
'pageSize'?: number;
'totalRowCount'?: number;
'items'?: Array<MonthlyReviewDocumentItem>;
};
})
    );
  }

  /**
   * Path part for operation documentAdd
   */
  static readonly DocumentAddPath = '/v1/performanceManagement/add/document/monthlyReview';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `documentAdd()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  documentAdd$Response(params: {
    body: DocumentAddRequest
  }): Observable<StrictHttpResponse<{
'isSuccess'?: boolean;
'message'?: string;
'data'?: string;
}>> {

    const rb = new RequestBuilder(this.rootUrl, ApiService.DocumentAddPath, 'post');
    if (params) {
      rb.body(params.body, 'application/json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<{
        'isSuccess'?: boolean;
        'message'?: string;
        'data'?: string;
        }>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `documentAdd$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  documentAdd(params: {
    body: DocumentAddRequest
  }): Observable<{
'isSuccess'?: boolean;
'message'?: string;
'data'?: string;
}> {

    return this.documentAdd$Response(params).pipe(
      map((r: StrictHttpResponse<{
'isSuccess'?: boolean;
'message'?: string;
'data'?: string;
}>) => r.body as {
'isSuccess'?: boolean;
'message'?: string;
'data'?: string;
})
    );
  }

  /**
   * Path part for operation documentUpdate
   */
  static readonly DocumentUpdatePath = '/v1/performanceManagement/update/documents/monthlyReview';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `documentUpdate()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  documentUpdate$Response(params: {
    body: DocumentUpdateRequest
  }): Observable<StrictHttpResponse<{
'isSuccess'?: boolean;
'message'?: string;
'data'?: string;
}>> {

    const rb = new RequestBuilder(this.rootUrl, ApiService.DocumentUpdatePath, 'post');
    if (params) {
      rb.body(params.body, 'application/json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<{
        'isSuccess'?: boolean;
        'message'?: string;
        'data'?: string;
        }>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `documentUpdate$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  documentUpdate(params: {
    body: DocumentUpdateRequest
  }): Observable<{
'isSuccess'?: boolean;
'message'?: string;
'data'?: string;
}> {

    return this.documentUpdate$Response(params).pipe(
      map((r: StrictHttpResponse<{
'isSuccess'?: boolean;
'message'?: string;
'data'?: string;
}>) => r.body as {
'isSuccess'?: boolean;
'message'?: string;
'data'?: string;
})
    );
  }

  /**
   * Path part for operation monthlyReviewDocumentUpdate
   */
  static readonly MonthlyReviewDocumentUpdatePath = '/v1/performanceManagement/update/document/monthlyReview';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `monthlyReviewDocumentUpdate()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  monthlyReviewDocumentUpdate$Response(params: {
    body: MonthlyReviewDocumentUpdateRequest
  }): Observable<StrictHttpResponse<{
'isSuccess'?: boolean;
'message'?: string;
'data'?: string;
}>> {

    const rb = new RequestBuilder(this.rootUrl, ApiService.MonthlyReviewDocumentUpdatePath, 'post');
    if (params) {
      rb.body(params.body, 'application/json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<{
        'isSuccess'?: boolean;
        'message'?: string;
        'data'?: string;
        }>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `monthlyReviewDocumentUpdate$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  monthlyReviewDocumentUpdate(params: {
    body: MonthlyReviewDocumentUpdateRequest
  }): Observable<{
'isSuccess'?: boolean;
'message'?: string;
'data'?: string;
}> {

    return this.monthlyReviewDocumentUpdate$Response(params).pipe(
      map((r: StrictHttpResponse<{
'isSuccess'?: boolean;
'message'?: string;
'data'?: string;
}>) => r.body as {
'isSuccess'?: boolean;
'message'?: string;
'data'?: string;
})
    );
  }

  /**
   * Path part for operation monthlyReviewDocumentOrderUpdate
   */
  static readonly MonthlyReviewDocumentOrderUpdatePath = '/v1/performanceManagement/update/documentOrder/monthlyReview';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `monthlyReviewDocumentOrderUpdate()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  monthlyReviewDocumentOrderUpdate$Response(params: {
    body: DocumentOrderUpdateRequest
  }): Observable<StrictHttpResponse<{
'isSuccess'?: boolean;
'message'?: string;
'data'?: string;
}>> {

    const rb = new RequestBuilder(this.rootUrl, ApiService.MonthlyReviewDocumentOrderUpdatePath, 'post');
    if (params) {
      rb.body(params.body, 'application/json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<{
        'isSuccess'?: boolean;
        'message'?: string;
        'data'?: string;
        }>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `monthlyReviewDocumentOrderUpdate$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  monthlyReviewDocumentOrderUpdate(params: {
    body: DocumentOrderUpdateRequest
  }): Observable<{
'isSuccess'?: boolean;
'message'?: string;
'data'?: string;
}> {

    return this.monthlyReviewDocumentOrderUpdate$Response(params).pipe(
      map((r: StrictHttpResponse<{
'isSuccess'?: boolean;
'message'?: string;
'data'?: string;
}>) => r.body as {
'isSuccess'?: boolean;
'message'?: string;
'data'?: string;
})
    );
  }

  /**
   * Path part for operation monthlyReviewDocumentDelete
   */
  static readonly MonthlyReviewDocumentDeletePath = '/v1/performanceManagement/delete/document/monthlyReview';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `monthlyReviewDocumentDelete()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  monthlyReviewDocumentDelete$Response(params: {
    body: DeleteRequest
  }): Observable<StrictHttpResponse<{
'isSuccess'?: boolean;
'message'?: string;
'data'?: string;
}>> {

    const rb = new RequestBuilder(this.rootUrl, ApiService.MonthlyReviewDocumentDeletePath, 'delete');
    if (params) {
      rb.body(params.body, 'application/json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<{
        'isSuccess'?: boolean;
        'message'?: string;
        'data'?: string;
        }>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `monthlyReviewDocumentDelete$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  monthlyReviewDocumentDelete(params: {
    body: DeleteRequest
  }): Observable<{
'isSuccess'?: boolean;
'message'?: string;
'data'?: string;
}> {

    return this.monthlyReviewDocumentDelete$Response(params).pipe(
      map((r: StrictHttpResponse<{
'isSuccess'?: boolean;
'message'?: string;
'data'?: string;
}>) => r.body as {
'isSuccess'?: boolean;
'message'?: string;
'data'?: string;
})
    );
  }

  /**
   * Path part for operation monthlyReviewSnapshotAdd
   */
  static readonly MonthlyReviewSnapshotAddPath = '/v1/performanceManagement/add/snapshot/monthlyReview';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `monthlyReviewSnapshotAdd()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  monthlyReviewSnapshotAdd$Response(params: {
    body: CompleteMonthlyReviewRequest
  }): Observable<StrictHttpResponse<{
'isSuccess'?: boolean;
'message'?: string;
'data'?: string;
}>> {

    const rb = new RequestBuilder(this.rootUrl, ApiService.MonthlyReviewSnapshotAddPath, 'post');
    if (params) {
      rb.body(params.body, 'application/json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<{
        'isSuccess'?: boolean;
        'message'?: string;
        'data'?: string;
        }>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `monthlyReviewSnapshotAdd$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  monthlyReviewSnapshotAdd(params: {
    body: CompleteMonthlyReviewRequest
  }): Observable<{
'isSuccess'?: boolean;
'message'?: string;
'data'?: string;
}> {

    return this.monthlyReviewSnapshotAdd$Response(params).pipe(
      map((r: StrictHttpResponse<{
'isSuccess'?: boolean;
'message'?: string;
'data'?: string;
}>) => r.body as {
'isSuccess'?: boolean;
'message'?: string;
'data'?: string;
})
    );
  }

  /**
   * Path part for operation jobMaintJobList
   */
  static readonly JobMaintJobListPath = '/v1/jobmaintjob/list';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `jobMaintJobList()` instead.
   *
   * This method doesn't expect any request body.
   */
  jobMaintJobList$Response(params?: {
  }): Observable<StrictHttpResponse<{
'isSuccess'?: boolean;
'message'?: string;
'data'?: {
'pageNumber'?: number;
'pageSize'?: number;
'totalRowCount'?: number;
'items'?: Array<Job>;
};
}>> {

    const rb = new RequestBuilder(this.rootUrl, ApiService.JobMaintJobListPath, 'post');
    if (params) {
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<{
        'isSuccess'?: boolean;
        'message'?: string;
        'data'?: {
        'pageNumber'?: number;
        'pageSize'?: number;
        'totalRowCount'?: number;
        'items'?: Array<Job>;
        };
        }>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `jobMaintJobList$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  jobMaintJobList(params?: {
  }): Observable<{
'isSuccess'?: boolean;
'message'?: string;
'data'?: {
'pageNumber'?: number;
'pageSize'?: number;
'totalRowCount'?: number;
'items'?: Array<Job>;
};
}> {

    return this.jobMaintJobList$Response(params).pipe(
      map((r: StrictHttpResponse<{
'isSuccess'?: boolean;
'message'?: string;
'data'?: {
'pageNumber'?: number;
'pageSize'?: number;
'totalRowCount'?: number;
'items'?: Array<Job>;
};
}>) => r.body as {
'isSuccess'?: boolean;
'message'?: string;
'data'?: {
'pageNumber'?: number;
'pageSize'?: number;
'totalRowCount'?: number;
'items'?: Array<Job>;
};
})
    );
  }

  /**
   * Path part for operation updateJob
   */
  static readonly UpdateJobPath = '/v1/jobmaintjob/update/job';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `updateJob()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  updateJob$Response(params: {
    body: Job
  }): Observable<StrictHttpResponse<{
'isSuccess'?: boolean;
'message'?: string;
'data'?: string;
}>> {

    const rb = new RequestBuilder(this.rootUrl, ApiService.UpdateJobPath, 'put');
    if (params) {
      rb.body(params.body, 'application/json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<{
        'isSuccess'?: boolean;
        'message'?: string;
        'data'?: string;
        }>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `updateJob$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  updateJob(params: {
    body: Job
  }): Observable<{
'isSuccess'?: boolean;
'message'?: string;
'data'?: string;
}> {

    return this.updateJob$Response(params).pipe(
      map((r: StrictHttpResponse<{
'isSuccess'?: boolean;
'message'?: string;
'data'?: string;
}>) => r.body as {
'isSuccess'?: boolean;
'message'?: string;
'data'?: string;
})
    );
  }

  /**
   * Path part for operation markNotificationAsRead
   */
  static readonly MarkNotificationAsReadPath = '/v1/notification/update/notification/markAsRead';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `markNotificationAsRead()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  markNotificationAsRead$Response(params: {
    body: NotificationUpdateModel
  }): Observable<StrictHttpResponse<{
'isSuccess'?: boolean;
'message'?: string;
'data'?: string;
}>> {

    const rb = new RequestBuilder(this.rootUrl, ApiService.MarkNotificationAsReadPath, 'put');
    if (params) {
      rb.body(params.body, 'application/json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<{
        'isSuccess'?: boolean;
        'message'?: string;
        'data'?: string;
        }>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `markNotificationAsRead$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  markNotificationAsRead(params: {
    body: NotificationUpdateModel
  }): Observable<{
'isSuccess'?: boolean;
'message'?: string;
'data'?: string;
}> {

    return this.markNotificationAsRead$Response(params).pipe(
      map((r: StrictHttpResponse<{
'isSuccess'?: boolean;
'message'?: string;
'data'?: string;
}>) => r.body as {
'isSuccess'?: boolean;
'message'?: string;
'data'?: string;
})
    );
  }

  /**
   * Path part for operation notificationListReceivedByAUser
   */
  static readonly NotificationListReceivedByAUserPath = '/v1/notification/list/all/notifications/recipient';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `notificationListReceivedByAUser()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  notificationListReceivedByAUser$Response(params: {
    body: AllNotificationGetRequest
  }): Observable<StrictHttpResponse<{
'isSuccess'?: boolean;
'message'?: string;
'data'?: {
'pageNumber'?: number;
'pageSize'?: number;
'totalRowCount'?: number;
'items'?: Array<NotificationInfo>;
};
}>> {

    const rb = new RequestBuilder(this.rootUrl, ApiService.NotificationListReceivedByAUserPath, 'post');
    if (params) {
      rb.body(params.body, 'application/json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<{
        'isSuccess'?: boolean;
        'message'?: string;
        'data'?: {
        'pageNumber'?: number;
        'pageSize'?: number;
        'totalRowCount'?: number;
        'items'?: Array<NotificationInfo>;
        };
        }>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `notificationListReceivedByAUser$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  notificationListReceivedByAUser(params: {
    body: AllNotificationGetRequest
  }): Observable<{
'isSuccess'?: boolean;
'message'?: string;
'data'?: {
'pageNumber'?: number;
'pageSize'?: number;
'totalRowCount'?: number;
'items'?: Array<NotificationInfo>;
};
}> {

    return this.notificationListReceivedByAUser$Response(params).pipe(
      map((r: StrictHttpResponse<{
'isSuccess'?: boolean;
'message'?: string;
'data'?: {
'pageNumber'?: number;
'pageSize'?: number;
'totalRowCount'?: number;
'items'?: Array<NotificationInfo>;
};
}>) => r.body as {
'isSuccess'?: boolean;
'message'?: string;
'data'?: {
'pageNumber'?: number;
'pageSize'?: number;
'totalRowCount'?: number;
'items'?: Array<NotificationInfo>;
};
})
    );
  }

  /**
   * Path part for operation unReadNotificationListReceivedByAUser
   */
  static readonly UnReadNotificationListReceivedByAUserPath = '/v1/notification/list/unread/notifications/recipient';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `unReadNotificationListReceivedByAUser()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  unReadNotificationListReceivedByAUser$Response(params: {
    body: AllNotificationGetRequest
  }): Observable<StrictHttpResponse<{
'isSuccess'?: boolean;
'message'?: string;
'data'?: {
'pageNumber'?: number;
'pageSize'?: number;
'totalRowCount'?: number;
'items'?: Array<NotificationInfo>;
};
}>> {

    const rb = new RequestBuilder(this.rootUrl, ApiService.UnReadNotificationListReceivedByAUserPath, 'post');
    if (params) {
      rb.body(params.body, 'application/json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<{
        'isSuccess'?: boolean;
        'message'?: string;
        'data'?: {
        'pageNumber'?: number;
        'pageSize'?: number;
        'totalRowCount'?: number;
        'items'?: Array<NotificationInfo>;
        };
        }>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `unReadNotificationListReceivedByAUser$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  unReadNotificationListReceivedByAUser(params: {
    body: AllNotificationGetRequest
  }): Observable<{
'isSuccess'?: boolean;
'message'?: string;
'data'?: {
'pageNumber'?: number;
'pageSize'?: number;
'totalRowCount'?: number;
'items'?: Array<NotificationInfo>;
};
}> {

    return this.unReadNotificationListReceivedByAUser$Response(params).pipe(
      map((r: StrictHttpResponse<{
'isSuccess'?: boolean;
'message'?: string;
'data'?: {
'pageNumber'?: number;
'pageSize'?: number;
'totalRowCount'?: number;
'items'?: Array<NotificationInfo>;
};
}>) => r.body as {
'isSuccess'?: boolean;
'message'?: string;
'data'?: {
'pageNumber'?: number;
'pageSize'?: number;
'totalRowCount'?: number;
'items'?: Array<NotificationInfo>;
};
})
    );
  }

  /**
   * Path part for operation notificationAdd
   */
  static readonly NotificationAddPath = '/v1/notification/add/notification';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `notificationAdd()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  notificationAdd$Response(params: {
    body: NotificationModel
  }): Observable<StrictHttpResponse<{
'isSuccess'?: boolean;
'message'?: string;
'data'?: string;
}>> {

    const rb = new RequestBuilder(this.rootUrl, ApiService.NotificationAddPath, 'put');
    if (params) {
      rb.body(params.body, 'application/json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<{
        'isSuccess'?: boolean;
        'message'?: string;
        'data'?: string;
        }>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `notificationAdd$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  notificationAdd(params: {
    body: NotificationModel
  }): Observable<{
'isSuccess'?: boolean;
'message'?: string;
'data'?: string;
}> {

    return this.notificationAdd$Response(params).pipe(
      map((r: StrictHttpResponse<{
'isSuccess'?: boolean;
'message'?: string;
'data'?: string;
}>) => r.body as {
'isSuccess'?: boolean;
'message'?: string;
'data'?: string;
})
    );
  }

  /**
   * Path part for operation allNotificationListForAUser
   */
  static readonly AllNotificationListForAUserPath = '/v1/notification/list/user/notification';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `allNotificationListForAUser()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  allNotificationListForAUser$Response(params: {
    body: NotificationForUserModel
  }): Observable<StrictHttpResponse<{
'isSuccess'?: boolean;
'message'?: string;
'data'?: {
'pageNumber'?: number;
'pageSize'?: number;
'totalRowCount'?: number;
'items'?: Array<NotificationInfo>;
};
}>> {

    const rb = new RequestBuilder(this.rootUrl, ApiService.AllNotificationListForAUserPath, 'post');
    if (params) {
      rb.body(params.body, 'application/json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<{
        'isSuccess'?: boolean;
        'message'?: string;
        'data'?: {
        'pageNumber'?: number;
        'pageSize'?: number;
        'totalRowCount'?: number;
        'items'?: Array<NotificationInfo>;
        };
        }>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `allNotificationListForAUser$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  allNotificationListForAUser(params: {
    body: NotificationForUserModel
  }): Observable<{
'isSuccess'?: boolean;
'message'?: string;
'data'?: {
'pageNumber'?: number;
'pageSize'?: number;
'totalRowCount'?: number;
'items'?: Array<NotificationInfo>;
};
}> {

    return this.allNotificationListForAUser$Response(params).pipe(
      map((r: StrictHttpResponse<{
'isSuccess'?: boolean;
'message'?: string;
'data'?: {
'pageNumber'?: number;
'pageSize'?: number;
'totalRowCount'?: number;
'items'?: Array<NotificationInfo>;
};
}>) => r.body as {
'isSuccess'?: boolean;
'message'?: string;
'data'?: {
'pageNumber'?: number;
'pageSize'?: number;
'totalRowCount'?: number;
'items'?: Array<NotificationInfo>;
};
})
    );
  }

  /**
   * Path part for operation allNotificationListForAUserForAGroup
   */
  static readonly AllNotificationListForAUserForAGroupPath = '/v1/notification/list/userGroup/notification';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `allNotificationListForAUserForAGroup()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  allNotificationListForAUserForAGroup$Response(params: {
    body: NotificationForUserGroupModel
  }): Observable<StrictHttpResponse<{
'isSuccess'?: boolean;
'message'?: string;
'data'?: {
'pageNumber'?: number;
'pageSize'?: number;
'totalRowCount'?: number;
'items'?: Array<NotificationInfo>;
};
}>> {

    const rb = new RequestBuilder(this.rootUrl, ApiService.AllNotificationListForAUserForAGroupPath, 'post');
    if (params) {
      rb.body(params.body, 'application/json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<{
        'isSuccess'?: boolean;
        'message'?: string;
        'data'?: {
        'pageNumber'?: number;
        'pageSize'?: number;
        'totalRowCount'?: number;
        'items'?: Array<NotificationInfo>;
        };
        }>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `allNotificationListForAUserForAGroup$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  allNotificationListForAUserForAGroup(params: {
    body: NotificationForUserGroupModel
  }): Observable<{
'isSuccess'?: boolean;
'message'?: string;
'data'?: {
'pageNumber'?: number;
'pageSize'?: number;
'totalRowCount'?: number;
'items'?: Array<NotificationInfo>;
};
}> {

    return this.allNotificationListForAUserForAGroup$Response(params).pipe(
      map((r: StrictHttpResponse<{
'isSuccess'?: boolean;
'message'?: string;
'data'?: {
'pageNumber'?: number;
'pageSize'?: number;
'totalRowCount'?: number;
'items'?: Array<NotificationInfo>;
};
}>) => r.body as {
'isSuccess'?: boolean;
'message'?: string;
'data'?: {
'pageNumber'?: number;
'pageSize'?: number;
'totalRowCount'?: number;
'items'?: Array<NotificationInfo>;
};
})
    );
  }

  /**
   * Path part for operation notificationGroupAdd
   */
  static readonly NotificationGroupAddPath = '/v1/notification/add/group/notification';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `notificationGroupAdd()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  notificationGroupAdd$Response(params: {
    body: NotificationGroupModel
  }): Observable<StrictHttpResponse<{
'isSuccess'?: boolean;
'message'?: string;
'data'?: string;
}>> {

    const rb = new RequestBuilder(this.rootUrl, ApiService.NotificationGroupAddPath, 'put');
    if (params) {
      rb.body(params.body, 'application/json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<{
        'isSuccess'?: boolean;
        'message'?: string;
        'data'?: string;
        }>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `notificationGroupAdd$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  notificationGroupAdd(params: {
    body: NotificationGroupModel
  }): Observable<{
'isSuccess'?: boolean;
'message'?: string;
'data'?: string;
}> {

    return this.notificationGroupAdd$Response(params).pipe(
      map((r: StrictHttpResponse<{
'isSuccess'?: boolean;
'message'?: string;
'data'?: string;
}>) => r.body as {
'isSuccess'?: boolean;
'message'?: string;
'data'?: string;
})
    );
  }

  /**
   * Path part for operation allNotificationForTopicFromSender
   */
  static readonly AllNotificationForTopicFromSenderPath = '/v1/notification/list/notifications/notificationTopic';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `allNotificationForTopicFromSender()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  allNotificationForTopicFromSender$Response(params: {
    body: NotificationForTopicRequest
  }): Observable<StrictHttpResponse<{
'isSuccess'?: boolean;
'message'?: string;
'data'?: {
'pageNumber'?: number;
'pageSize'?: number;
'totalRowCount'?: number;
'items'?: Array<NotificationInfo>;
};
}>> {

    const rb = new RequestBuilder(this.rootUrl, ApiService.AllNotificationForTopicFromSenderPath, 'post');
    if (params) {
      rb.body(params.body, 'application/json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<{
        'isSuccess'?: boolean;
        'message'?: string;
        'data'?: {
        'pageNumber'?: number;
        'pageSize'?: number;
        'totalRowCount'?: number;
        'items'?: Array<NotificationInfo>;
        };
        }>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `allNotificationForTopicFromSender$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  allNotificationForTopicFromSender(params: {
    body: NotificationForTopicRequest
  }): Observable<{
'isSuccess'?: boolean;
'message'?: string;
'data'?: {
'pageNumber'?: number;
'pageSize'?: number;
'totalRowCount'?: number;
'items'?: Array<NotificationInfo>;
};
}> {

    return this.allNotificationForTopicFromSender$Response(params).pipe(
      map((r: StrictHttpResponse<{
'isSuccess'?: boolean;
'message'?: string;
'data'?: {
'pageNumber'?: number;
'pageSize'?: number;
'totalRowCount'?: number;
'items'?: Array<NotificationInfo>;
};
}>) => r.body as {
'isSuccess'?: boolean;
'message'?: string;
'data'?: {
'pageNumber'?: number;
'pageSize'?: number;
'totalRowCount'?: number;
'items'?: Array<NotificationInfo>;
};
})
    );
  }

  /**
   * Path part for operation allNotificationTopic
   */
  static readonly AllNotificationTopicPath = '/v1/notification/list/notificationTopic';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `allNotificationTopic()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  allNotificationTopic$Response(params: {
    body: EntityGetRequest
  }): Observable<StrictHttpResponse<{
'isSuccess'?: boolean;
'message'?: string;
'data'?: {
'pageNumber'?: number;
'pageSize'?: number;
'totalRowCount'?: number;
'items'?: Array<NotificationTopicInfo>;
};
}>> {

    const rb = new RequestBuilder(this.rootUrl, ApiService.AllNotificationTopicPath, 'post');
    if (params) {
      rb.body(params.body, 'application/json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<{
        'isSuccess'?: boolean;
        'message'?: string;
        'data'?: {
        'pageNumber'?: number;
        'pageSize'?: number;
        'totalRowCount'?: number;
        'items'?: Array<NotificationTopicInfo>;
        };
        }>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `allNotificationTopic$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  allNotificationTopic(params: {
    body: EntityGetRequest
  }): Observable<{
'isSuccess'?: boolean;
'message'?: string;
'data'?: {
'pageNumber'?: number;
'pageSize'?: number;
'totalRowCount'?: number;
'items'?: Array<NotificationTopicInfo>;
};
}> {

    return this.allNotificationTopic$Response(params).pipe(
      map((r: StrictHttpResponse<{
'isSuccess'?: boolean;
'message'?: string;
'data'?: {
'pageNumber'?: number;
'pageSize'?: number;
'totalRowCount'?: number;
'items'?: Array<NotificationTopicInfo>;
};
}>) => r.body as {
'isSuccess'?: boolean;
'message'?: string;
'data'?: {
'pageNumber'?: number;
'pageSize'?: number;
'totalRowCount'?: number;
'items'?: Array<NotificationTopicInfo>;
};
})
    );
  }

  /**
   * Path part for operation notificationTopicAdd
   */
  static readonly NotificationTopicAddPath = '/v1/notification/add/notificationTopic';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `notificationTopicAdd()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  notificationTopicAdd$Response(params: {
    body: NotificationTopicRequestModel
  }): Observable<StrictHttpResponse<{
'isSuccess'?: boolean;
'message'?: string;
'data'?: string;
}>> {

    const rb = new RequestBuilder(this.rootUrl, ApiService.NotificationTopicAddPath, 'put');
    if (params) {
      rb.body(params.body, 'application/json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<{
        'isSuccess'?: boolean;
        'message'?: string;
        'data'?: string;
        }>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `notificationTopicAdd$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  notificationTopicAdd(params: {
    body: NotificationTopicRequestModel
  }): Observable<{
'isSuccess'?: boolean;
'message'?: string;
'data'?: string;
}> {

    return this.notificationTopicAdd$Response(params).pipe(
      map((r: StrictHttpResponse<{
'isSuccess'?: boolean;
'message'?: string;
'data'?: string;
}>) => r.body as {
'isSuccess'?: boolean;
'message'?: string;
'data'?: string;
})
    );
  }

  /**
   * Path part for operation notificationTopicUpdate
   */
  static readonly NotificationTopicUpdatePath = '/v1/notification/update/notificationTopic';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `notificationTopicUpdate()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  notificationTopicUpdate$Response(params: {
    body: NotificationTopicUpdateRequestModel
  }): Observable<StrictHttpResponse<{
'isSuccess'?: boolean;
'message'?: string;
'data'?: string;
}>> {

    const rb = new RequestBuilder(this.rootUrl, ApiService.NotificationTopicUpdatePath, 'put');
    if (params) {
      rb.body(params.body, 'application/json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<{
        'isSuccess'?: boolean;
        'message'?: string;
        'data'?: string;
        }>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `notificationTopicUpdate$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  notificationTopicUpdate(params: {
    body: NotificationTopicUpdateRequestModel
  }): Observable<{
'isSuccess'?: boolean;
'message'?: string;
'data'?: string;
}> {

    return this.notificationTopicUpdate$Response(params).pipe(
      map((r: StrictHttpResponse<{
'isSuccess'?: boolean;
'message'?: string;
'data'?: string;
}>) => r.body as {
'isSuccess'?: boolean;
'message'?: string;
'data'?: string;
})
    );
  }

  /**
   * Path part for operation notificationTopicDelete
   */
  static readonly NotificationTopicDeletePath = '/v1/notification/delete/notificationTopic';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `notificationTopicDelete()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  notificationTopicDelete$Response(params: {
    body: DeleteRequest
  }): Observable<StrictHttpResponse<{
'isSuccess'?: boolean;
'message'?: string;
'data'?: string;
}>> {

    const rb = new RequestBuilder(this.rootUrl, ApiService.NotificationTopicDeletePath, 'delete');
    if (params) {
      rb.body(params.body, 'application/json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<{
        'isSuccess'?: boolean;
        'message'?: string;
        'data'?: string;
        }>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `notificationTopicDelete$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  notificationTopicDelete(params: {
    body: DeleteRequest
  }): Observable<{
'isSuccess'?: boolean;
'message'?: string;
'data'?: string;
}> {

    return this.notificationTopicDelete$Response(params).pipe(
      map((r: StrictHttpResponse<{
'isSuccess'?: boolean;
'message'?: string;
'data'?: string;
}>) => r.body as {
'isSuccess'?: boolean;
'message'?: string;
'data'?: string;
})
    );
  }

  /**
   * Path part for operation notificationTopicSubscriberAdd
   */
  static readonly NotificationTopicSubscriberAddPath = '/v1/notification/add/notificationTopic/subscriber';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `notificationTopicSubscriberAdd()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  notificationTopicSubscriberAdd$Response(params: {
    body: NotificationTopicSubscriberRequestModel
  }): Observable<StrictHttpResponse<{
'isSuccess'?: boolean;
'message'?: string;
'data'?: string;
}>> {

    const rb = new RequestBuilder(this.rootUrl, ApiService.NotificationTopicSubscriberAddPath, 'put');
    if (params) {
      rb.body(params.body, 'application/json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<{
        'isSuccess'?: boolean;
        'message'?: string;
        'data'?: string;
        }>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `notificationTopicSubscriberAdd$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  notificationTopicSubscriberAdd(params: {
    body: NotificationTopicSubscriberRequestModel
  }): Observable<{
'isSuccess'?: boolean;
'message'?: string;
'data'?: string;
}> {

    return this.notificationTopicSubscriberAdd$Response(params).pipe(
      map((r: StrictHttpResponse<{
'isSuccess'?: boolean;
'message'?: string;
'data'?: string;
}>) => r.body as {
'isSuccess'?: boolean;
'message'?: string;
'data'?: string;
})
    );
  }

  /**
   * Path part for operation notificationTopicSubscriberUpdate
   */
  static readonly NotificationTopicSubscriberUpdatePath = '/v1/notification/update/notificationTopic/subscriber';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `notificationTopicSubscriberUpdate()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  notificationTopicSubscriberUpdate$Response(params: {
    body: NotificationTopicSubscriberUpdateInfo
  }): Observable<StrictHttpResponse<{
'isSuccess'?: boolean;
'message'?: string;
'data'?: string;
}>> {

    const rb = new RequestBuilder(this.rootUrl, ApiService.NotificationTopicSubscriberUpdatePath, 'put');
    if (params) {
      rb.body(params.body, 'application/json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<{
        'isSuccess'?: boolean;
        'message'?: string;
        'data'?: string;
        }>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `notificationTopicSubscriberUpdate$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  notificationTopicSubscriberUpdate(params: {
    body: NotificationTopicSubscriberUpdateInfo
  }): Observable<{
'isSuccess'?: boolean;
'message'?: string;
'data'?: string;
}> {

    return this.notificationTopicSubscriberUpdate$Response(params).pipe(
      map((r: StrictHttpResponse<{
'isSuccess'?: boolean;
'message'?: string;
'data'?: string;
}>) => r.body as {
'isSuccess'?: boolean;
'message'?: string;
'data'?: string;
})
    );
  }

  /**
   * Path part for operation selectedNotificationTopic
   */
  static readonly SelectedNotificationTopicPath = '/v1/notification/selected/notificationTopic';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `selectedNotificationTopic()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  selectedNotificationTopic$Response(params: {
    body: FetchNotificationTopicModel
  }): Observable<StrictHttpResponse<{
'isSuccess'?: boolean;
'message'?: string;
'data'?: {
'pageNumber'?: number;
'pageSize'?: number;
'totalRowCount'?: number;
'items'?: Array<NotificationTopicInfo>;
};
}>> {

    const rb = new RequestBuilder(this.rootUrl, ApiService.SelectedNotificationTopicPath, 'post');
    if (params) {
      rb.body(params.body, 'application/json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<{
        'isSuccess'?: boolean;
        'message'?: string;
        'data'?: {
        'pageNumber'?: number;
        'pageSize'?: number;
        'totalRowCount'?: number;
        'items'?: Array<NotificationTopicInfo>;
        };
        }>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `selectedNotificationTopic$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  selectedNotificationTopic(params: {
    body: FetchNotificationTopicModel
  }): Observable<{
'isSuccess'?: boolean;
'message'?: string;
'data'?: {
'pageNumber'?: number;
'pageSize'?: number;
'totalRowCount'?: number;
'items'?: Array<NotificationTopicInfo>;
};
}> {

    return this.selectedNotificationTopic$Response(params).pipe(
      map((r: StrictHttpResponse<{
'isSuccess'?: boolean;
'message'?: string;
'data'?: {
'pageNumber'?: number;
'pageSize'?: number;
'totalRowCount'?: number;
'items'?: Array<NotificationTopicInfo>;
};
}>) => r.body as {
'isSuccess'?: boolean;
'message'?: string;
'data'?: {
'pageNumber'?: number;
'pageSize'?: number;
'totalRowCount'?: number;
'items'?: Array<NotificationTopicInfo>;
};
})
    );
  }

  /**
   * Path part for operation selectedNotificationTopicSubscriber
   */
  static readonly SelectedNotificationTopicSubscriberPath = '/v1/notification/selected/notificationTopic/subscriber';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `selectedNotificationTopicSubscriber()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  selectedNotificationTopicSubscriber$Response(params: {
    body: FetchNotificationTopicModel
  }): Observable<StrictHttpResponse<{
'isSuccess'?: boolean;
'message'?: string;
'data'?: {
'pageNumber'?: number;
'pageSize'?: number;
'totalRowCount'?: number;
'items'?: Array<NotificationTopicSubscriberInfo>;
};
}>> {

    const rb = new RequestBuilder(this.rootUrl, ApiService.SelectedNotificationTopicSubscriberPath, 'post');
    if (params) {
      rb.body(params.body, 'application/json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<{
        'isSuccess'?: boolean;
        'message'?: string;
        'data'?: {
        'pageNumber'?: number;
        'pageSize'?: number;
        'totalRowCount'?: number;
        'items'?: Array<NotificationTopicSubscriberInfo>;
        };
        }>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `selectedNotificationTopicSubscriber$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  selectedNotificationTopicSubscriber(params: {
    body: FetchNotificationTopicModel
  }): Observable<{
'isSuccess'?: boolean;
'message'?: string;
'data'?: {
'pageNumber'?: number;
'pageSize'?: number;
'totalRowCount'?: number;
'items'?: Array<NotificationTopicSubscriberInfo>;
};
}> {

    return this.selectedNotificationTopicSubscriber$Response(params).pipe(
      map((r: StrictHttpResponse<{
'isSuccess'?: boolean;
'message'?: string;
'data'?: {
'pageNumber'?: number;
'pageSize'?: number;
'totalRowCount'?: number;
'items'?: Array<NotificationTopicSubscriberInfo>;
};
}>) => r.body as {
'isSuccess'?: boolean;
'message'?: string;
'data'?: {
'pageNumber'?: number;
'pageSize'?: number;
'totalRowCount'?: number;
'items'?: Array<NotificationTopicSubscriberInfo>;
};
})
    );
  }

  /**
   * Path part for operation markNotificationDelivered
   */
  static readonly MarkNotificationDeliveredPath = '/v1/notification/update/notification/delivered';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `markNotificationDelivered()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  markNotificationDelivered$Response(params: {
    body: MarkNotificationAsDeliveredRequest
  }): Observable<StrictHttpResponse<{
'isSuccess'?: boolean;
'message'?: string;
'data'?: string;
}>> {

    const rb = new RequestBuilder(this.rootUrl, ApiService.MarkNotificationDeliveredPath, 'put');
    if (params) {
      rb.body(params.body, 'application/json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<{
        'isSuccess'?: boolean;
        'message'?: string;
        'data'?: string;
        }>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `markNotificationDelivered$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  markNotificationDelivered(params: {
    body: MarkNotificationAsDeliveredRequest
  }): Observable<{
'isSuccess'?: boolean;
'message'?: string;
'data'?: string;
}> {

    return this.markNotificationDelivered$Response(params).pipe(
      map((r: StrictHttpResponse<{
'isSuccess'?: boolean;
'message'?: string;
'data'?: string;
}>) => r.body as {
'isSuccess'?: boolean;
'message'?: string;
'data'?: string;
})
    );
  }

  /**
   * Path part for operation notificationDetailList
   */
  static readonly NotificationDetailListPath = '/v1/notification/list/notificationDetail';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `notificationDetailList()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  notificationDetailList$Response(params: {
    body: AllNotificationGetRequest
  }): Observable<StrictHttpResponse<{
'isSuccess'?: boolean;
'message'?: string;
'data'?: {
'pageNumber'?: number;
'pageSize'?: number;
'totalRowCount'?: number;
'items'?: Array<NotificationDetailInfo>;
};
}>> {

    const rb = new RequestBuilder(this.rootUrl, ApiService.NotificationDetailListPath, 'post');
    if (params) {
      rb.body(params.body, 'application/json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<{
        'isSuccess'?: boolean;
        'message'?: string;
        'data'?: {
        'pageNumber'?: number;
        'pageSize'?: number;
        'totalRowCount'?: number;
        'items'?: Array<NotificationDetailInfo>;
        };
        }>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `notificationDetailList$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  notificationDetailList(params: {
    body: AllNotificationGetRequest
  }): Observable<{
'isSuccess'?: boolean;
'message'?: string;
'data'?: {
'pageNumber'?: number;
'pageSize'?: number;
'totalRowCount'?: number;
'items'?: Array<NotificationDetailInfo>;
};
}> {

    return this.notificationDetailList$Response(params).pipe(
      map((r: StrictHttpResponse<{
'isSuccess'?: boolean;
'message'?: string;
'data'?: {
'pageNumber'?: number;
'pageSize'?: number;
'totalRowCount'?: number;
'items'?: Array<NotificationDetailInfo>;
};
}>) => r.body as {
'isSuccess'?: boolean;
'message'?: string;
'data'?: {
'pageNumber'?: number;
'pageSize'?: number;
'totalRowCount'?: number;
'items'?: Array<NotificationDetailInfo>;
};
})
    );
  }

  /**
   * Path part for operation notificationDetail
   */
  static readonly NotificationDetailPath = '/v1/notification/single/notificationDetail';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `notificationDetail()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  notificationDetail$Response(params: {
    body: NotificationGetRequest
  }): Observable<StrictHttpResponse<{
'isSuccess'?: boolean;
'message'?: string;
'data'?: {
'pageNumber'?: number;
'pageSize'?: number;
'totalRowCount'?: number;
'items'?: Array<NotificationDetailInfo>;
};
}>> {

    const rb = new RequestBuilder(this.rootUrl, ApiService.NotificationDetailPath, 'post');
    if (params) {
      rb.body(params.body, 'application/json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<{
        'isSuccess'?: boolean;
        'message'?: string;
        'data'?: {
        'pageNumber'?: number;
        'pageSize'?: number;
        'totalRowCount'?: number;
        'items'?: Array<NotificationDetailInfo>;
        };
        }>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `notificationDetail$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  notificationDetail(params: {
    body: NotificationGetRequest
  }): Observable<{
'isSuccess'?: boolean;
'message'?: string;
'data'?: {
'pageNumber'?: number;
'pageSize'?: number;
'totalRowCount'?: number;
'items'?: Array<NotificationDetailInfo>;
};
}> {

    return this.notificationDetail$Response(params).pipe(
      map((r: StrictHttpResponse<{
'isSuccess'?: boolean;
'message'?: string;
'data'?: {
'pageNumber'?: number;
'pageSize'?: number;
'totalRowCount'?: number;
'items'?: Array<NotificationDetailInfo>;
};
}>) => r.body as {
'isSuccess'?: boolean;
'message'?: string;
'data'?: {
'pageNumber'?: number;
'pageSize'?: number;
'totalRowCount'?: number;
'items'?: Array<NotificationDetailInfo>;
};
})
    );
  }

  /**
   * Path part for operation sendUserNotification
   */
  static readonly SendUserNotificationPath = '/v1/notification/user/sendNotification';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `sendUserNotification()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  sendUserNotification$Response(params: {
    body: UserNotificationMessage
  }): Observable<StrictHttpResponse<{
'isSuccess'?: boolean;
'message'?: string;
'data'?: string;
}>> {

    const rb = new RequestBuilder(this.rootUrl, ApiService.SendUserNotificationPath, 'post');
    if (params) {
      rb.body(params.body, 'application/json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<{
        'isSuccess'?: boolean;
        'message'?: string;
        'data'?: string;
        }>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `sendUserNotification$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  sendUserNotification(params: {
    body: UserNotificationMessage
  }): Observable<{
'isSuccess'?: boolean;
'message'?: string;
'data'?: string;
}> {

    return this.sendUserNotification$Response(params).pipe(
      map((r: StrictHttpResponse<{
'isSuccess'?: boolean;
'message'?: string;
'data'?: string;
}>) => r.body as {
'isSuccess'?: boolean;
'message'?: string;
'data'?: string;
})
    );
  }

  /**
   * Path part for operation sendUserGroupNotification
   */
  static readonly SendUserGroupNotificationPath = '/v1/notification/userGroup/sendNotification';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `sendUserGroupNotification()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  sendUserGroupNotification$Response(params: {
    body: UserGroupNotificationMessage
  }): Observable<StrictHttpResponse<{
'isSuccess'?: boolean;
'message'?: string;
'data'?: string;
}>> {

    const rb = new RequestBuilder(this.rootUrl, ApiService.SendUserGroupNotificationPath, 'post');
    if (params) {
      rb.body(params.body, 'application/json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<{
        'isSuccess'?: boolean;
        'message'?: string;
        'data'?: string;
        }>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `sendUserGroupNotification$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  sendUserGroupNotification(params: {
    body: UserGroupNotificationMessage
  }): Observable<{
'isSuccess'?: boolean;
'message'?: string;
'data'?: string;
}> {

    return this.sendUserGroupNotification$Response(params).pipe(
      map((r: StrictHttpResponse<{
'isSuccess'?: boolean;
'message'?: string;
'data'?: string;
}>) => r.body as {
'isSuccess'?: boolean;
'message'?: string;
'data'?: string;
})
    );
  }

  /**
   * Path part for operation allConnectedUsers
   */
  static readonly AllConnectedUsersPath = '/v1/notification/list/connected/users';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `allConnectedUsers()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  allConnectedUsers$Response(params: {
    body: EntityGetRequest
  }): Observable<StrictHttpResponse<{
'isSuccess'?: boolean;
'message'?: string;
'data'?: {
'pageNumber'?: number;
'pageSize'?: number;
'totalRowCount'?: number;
'items'?: Array<ConnectedUserDetail>;
};
}>> {

    const rb = new RequestBuilder(this.rootUrl, ApiService.AllConnectedUsersPath, 'post');
    if (params) {
      rb.body(params.body, 'application/json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<{
        'isSuccess'?: boolean;
        'message'?: string;
        'data'?: {
        'pageNumber'?: number;
        'pageSize'?: number;
        'totalRowCount'?: number;
        'items'?: Array<ConnectedUserDetail>;
        };
        }>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `allConnectedUsers$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  allConnectedUsers(params: {
    body: EntityGetRequest
  }): Observable<{
'isSuccess'?: boolean;
'message'?: string;
'data'?: {
'pageNumber'?: number;
'pageSize'?: number;
'totalRowCount'?: number;
'items'?: Array<ConnectedUserDetail>;
};
}> {

    return this.allConnectedUsers$Response(params).pipe(
      map((r: StrictHttpResponse<{
'isSuccess'?: boolean;
'message'?: string;
'data'?: {
'pageNumber'?: number;
'pageSize'?: number;
'totalRowCount'?: number;
'items'?: Array<ConnectedUserDetail>;
};
}>) => r.body as {
'isSuccess'?: boolean;
'message'?: string;
'data'?: {
'pageNumber'?: number;
'pageSize'?: number;
'totalRowCount'?: number;
'items'?: Array<ConnectedUserDetail>;
};
})
    );
  }

  /**
   * Path part for operation getCurrentOverDraftProgramFramework
   */
  static readonly GetCurrentOverDraftProgramFrameworkPath = '/v1/overDraftProgram/getCurrentOverDraftProgramFramework';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getCurrentOverDraftProgramFramework()` instead.
   *
   * This method doesn't expect any request body.
   */
  getCurrentOverDraftProgramFramework$Response(params?: {
  }): Observable<StrictHttpResponse<{
'isSuccess'?: boolean;
'message'?: string;
'data'?: OverDraftProgramFramework;
}>> {

    const rb = new RequestBuilder(this.rootUrl, ApiService.GetCurrentOverDraftProgramFrameworkPath, 'post');
    if (params) {
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<{
        'isSuccess'?: boolean;
        'message'?: string;
        'data'?: OverDraftProgramFramework;
        }>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `getCurrentOverDraftProgramFramework$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getCurrentOverDraftProgramFramework(params?: {
  }): Observable<{
'isSuccess'?: boolean;
'message'?: string;
'data'?: OverDraftProgramFramework;
}> {

    return this.getCurrentOverDraftProgramFramework$Response(params).pipe(
      map((r: StrictHttpResponse<{
'isSuccess'?: boolean;
'message'?: string;
'data'?: OverDraftProgramFramework;
}>) => r.body as {
'isSuccess'?: boolean;
'message'?: string;
'data'?: OverDraftProgramFramework;
})
    );
  }

  /**
   * Path part for operation postOverDraftProgramFramework
   */
  static readonly PostOverDraftProgramFrameworkPath = '/v1/overDraftProgram/postOverDraftProgramFramework';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `postOverDraftProgramFramework()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  postOverDraftProgramFramework$Response(params: {
    body: OverDraftProgramFramework
  }): Observable<StrictHttpResponse<{
'isSuccess'?: boolean;
'message'?: string;
'data'?: string;
}>> {

    const rb = new RequestBuilder(this.rootUrl, ApiService.PostOverDraftProgramFrameworkPath, 'post');
    if (params) {
      rb.body(params.body, 'application/json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<{
        'isSuccess'?: boolean;
        'message'?: string;
        'data'?: string;
        }>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `postOverDraftProgramFramework$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  postOverDraftProgramFramework(params: {
    body: OverDraftProgramFramework
  }): Observable<{
'isSuccess'?: boolean;
'message'?: string;
'data'?: string;
}> {

    return this.postOverDraftProgramFramework$Response(params).pipe(
      map((r: StrictHttpResponse<{
'isSuccess'?: boolean;
'message'?: string;
'data'?: string;
}>) => r.body as {
'isSuccess'?: boolean;
'message'?: string;
'data'?: string;
})
    );
  }

  /**
   * Path part for operation uploadNewOverDraftNoticeType
   */
  static readonly UploadNewOverDraftNoticeTypePath = '/v1/overDraftProgram/uploadNewOverDraftNoticeType';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `uploadNewOverDraftNoticeType()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  uploadNewOverDraftNoticeType$Response(params: {
    body: OverDraftNoticeType
  }): Observable<StrictHttpResponse<{
'isSuccess'?: boolean;
'message'?: string;
'data'?: string;
}>> {

    const rb = new RequestBuilder(this.rootUrl, ApiService.UploadNewOverDraftNoticeTypePath, 'post');
    if (params) {
      rb.body(params.body, 'application/json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<{
        'isSuccess'?: boolean;
        'message'?: string;
        'data'?: string;
        }>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `uploadNewOverDraftNoticeType$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  uploadNewOverDraftNoticeType(params: {
    body: OverDraftNoticeType
  }): Observable<{
'isSuccess'?: boolean;
'message'?: string;
'data'?: string;
}> {

    return this.uploadNewOverDraftNoticeType$Response(params).pipe(
      map((r: StrictHttpResponse<{
'isSuccess'?: boolean;
'message'?: string;
'data'?: string;
}>) => r.body as {
'isSuccess'?: boolean;
'message'?: string;
'data'?: string;
})
    );
  }

  /**
   * Path part for operation getAllOverDraftNoticeTypes
   */
  static readonly GetAllOverDraftNoticeTypesPath = '/v1/overDraftProgram/list/getAllOverDraftNoticeTypes';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getAllOverDraftNoticeTypes()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  getAllOverDraftNoticeTypes$Response(params: {
    body: EntityGetRequest
  }): Observable<StrictHttpResponse<{
'isSuccess'?: boolean;
'message'?: string;
'data'?: {
'pageNumber'?: number;
'pageSize'?: number;
'totalRowCount'?: number;
'items'?: Array<OverDraftNoticeType>;
};
}>> {

    const rb = new RequestBuilder(this.rootUrl, ApiService.GetAllOverDraftNoticeTypesPath, 'post');
    if (params) {
      rb.body(params.body, 'application/json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<{
        'isSuccess'?: boolean;
        'message'?: string;
        'data'?: {
        'pageNumber'?: number;
        'pageSize'?: number;
        'totalRowCount'?: number;
        'items'?: Array<OverDraftNoticeType>;
        };
        }>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `getAllOverDraftNoticeTypes$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  getAllOverDraftNoticeTypes(params: {
    body: EntityGetRequest
  }): Observable<{
'isSuccess'?: boolean;
'message'?: string;
'data'?: {
'pageNumber'?: number;
'pageSize'?: number;
'totalRowCount'?: number;
'items'?: Array<OverDraftNoticeType>;
};
}> {

    return this.getAllOverDraftNoticeTypes$Response(params).pipe(
      map((r: StrictHttpResponse<{
'isSuccess'?: boolean;
'message'?: string;
'data'?: {
'pageNumber'?: number;
'pageSize'?: number;
'totalRowCount'?: number;
'items'?: Array<OverDraftNoticeType>;
};
}>) => r.body as {
'isSuccess'?: boolean;
'message'?: string;
'data'?: {
'pageNumber'?: number;
'pageSize'?: number;
'totalRowCount'?: number;
'items'?: Array<OverDraftNoticeType>;
};
})
    );
  }

  /**
   * Path part for operation archiveOverDraftNoticeType
   */
  static readonly ArchiveOverDraftNoticeTypePath = '/v1/overDraftProgram/archiveOverDraftNoticeType';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `archiveOverDraftNoticeType()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  archiveOverDraftNoticeType$Response(params: {
    body: EditOverDraftNoticeRequest
  }): Observable<StrictHttpResponse<{
'isSuccess'?: boolean;
'message'?: string;
'data'?: string;
}>> {

    const rb = new RequestBuilder(this.rootUrl, ApiService.ArchiveOverDraftNoticeTypePath, 'post');
    if (params) {
      rb.body(params.body, 'application/json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<{
        'isSuccess'?: boolean;
        'message'?: string;
        'data'?: string;
        }>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `archiveOverDraftNoticeType$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  archiveOverDraftNoticeType(params: {
    body: EditOverDraftNoticeRequest
  }): Observable<{
'isSuccess'?: boolean;
'message'?: string;
'data'?: string;
}> {

    return this.archiveOverDraftNoticeType$Response(params).pipe(
      map((r: StrictHttpResponse<{
'isSuccess'?: boolean;
'message'?: string;
'data'?: string;
}>) => r.body as {
'isSuccess'?: boolean;
'message'?: string;
'data'?: string;
})
    );
  }

  /**
   * Path part for operation editOverDraftNoticeTypeName
   */
  static readonly EditOverDraftNoticeTypeNamePath = '/v1/overDraftProgram/editOverDraftNoticeTypeName';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `editOverDraftNoticeTypeName()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  editOverDraftNoticeTypeName$Response(params: {
    body: EditOverDraftNoticeRequest
  }): Observable<StrictHttpResponse<{
'isSuccess'?: boolean;
'message'?: string;
'data'?: string;
}>> {

    const rb = new RequestBuilder(this.rootUrl, ApiService.EditOverDraftNoticeTypeNamePath, 'post');
    if (params) {
      rb.body(params.body, 'application/json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<{
        'isSuccess'?: boolean;
        'message'?: string;
        'data'?: string;
        }>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `editOverDraftNoticeTypeName$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  editOverDraftNoticeTypeName(params: {
    body: EditOverDraftNoticeRequest
  }): Observable<{
'isSuccess'?: boolean;
'message'?: string;
'data'?: string;
}> {

    return this.editOverDraftNoticeTypeName$Response(params).pipe(
      map((r: StrictHttpResponse<{
'isSuccess'?: boolean;
'message'?: string;
'data'?: string;
}>) => r.body as {
'isSuccess'?: boolean;
'message'?: string;
'data'?: string;
})
    );
  }

  /**
   * Path part for operation searchEntityData
   */
  static readonly SearchEntityDataPath = '/v1/overDraftProgram/searchEntityData';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `searchEntityData()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  searchEntityData$Response(params: {
    body: OdpRequest
  }): Observable<StrictHttpResponse<{
'isSuccess'?: boolean;
'message'?: string;
'data'?: {
'pageNumber'?: number;
'pageSize'?: number;
'totalRowCount'?: number;
'items'?: Array<OdpEntity>;
};
}>> {

    const rb = new RequestBuilder(this.rootUrl, ApiService.SearchEntityDataPath, 'post');
    if (params) {
      rb.body(params.body, 'application/json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<{
        'isSuccess'?: boolean;
        'message'?: string;
        'data'?: {
        'pageNumber'?: number;
        'pageSize'?: number;
        'totalRowCount'?: number;
        'items'?: Array<OdpEntity>;
        };
        }>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `searchEntityData$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  searchEntityData(params: {
    body: OdpRequest
  }): Observable<{
'isSuccess'?: boolean;
'message'?: string;
'data'?: {
'pageNumber'?: number;
'pageSize'?: number;
'totalRowCount'?: number;
'items'?: Array<OdpEntity>;
};
}> {

    return this.searchEntityData$Response(params).pipe(
      map((r: StrictHttpResponse<{
'isSuccess'?: boolean;
'message'?: string;
'data'?: {
'pageNumber'?: number;
'pageSize'?: number;
'totalRowCount'?: number;
'items'?: Array<OdpEntity>;
};
}>) => r.body as {
'isSuccess'?: boolean;
'message'?: string;
'data'?: {
'pageNumber'?: number;
'pageSize'?: number;
'totalRowCount'?: number;
'items'?: Array<OdpEntity>;
};
})
    );
  }

  /**
   * Path part for operation getOdpAccountData
   */
  static readonly GetOdpAccountDataPath = '/v1/overDraftProgram/getOdpAccountData';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getOdpAccountData()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  getOdpAccountData$Response(params: {
    body: OdpAccountDataRequest
  }): Observable<StrictHttpResponse<{
'isSuccess'?: boolean;
'message'?: string;
'data'?: OdpEntity;
}>> {

    const rb = new RequestBuilder(this.rootUrl, ApiService.GetOdpAccountDataPath, 'post');
    if (params) {
      rb.body(params.body, 'application/json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<{
        'isSuccess'?: boolean;
        'message'?: string;
        'data'?: OdpEntity;
        }>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `getOdpAccountData$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  getOdpAccountData(params: {
    body: OdpAccountDataRequest
  }): Observable<{
'isSuccess'?: boolean;
'message'?: string;
'data'?: OdpEntity;
}> {

    return this.getOdpAccountData$Response(params).pipe(
      map((r: StrictHttpResponse<{
'isSuccess'?: boolean;
'message'?: string;
'data'?: OdpEntity;
}>) => r.body as {
'isSuccess'?: boolean;
'message'?: string;
'data'?: OdpEntity;
})
    );
  }

  /**
   * Path part for operation getRegEOverDraftBalanceOption
   */
  static readonly GetRegEOverDraftBalanceOptionPath = '/v1/overDraftProgram/report/RegEOverDraftBalanceOption';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getRegEOverDraftBalanceOption()` instead.
   *
   * This method doesn't expect any request body.
   */
  getRegEOverDraftBalanceOption$Response(params?: {
  }): Observable<StrictHttpResponse<{
'isSuccess'?: boolean;
'message'?: string;
'data'?: {
'pageNumber'?: number;
'pageSize'?: number;
'totalRowCount'?: number;
'items'?: Array<RegEOverDraftBalanceOption>;
};
}>> {

    const rb = new RequestBuilder(this.rootUrl, ApiService.GetRegEOverDraftBalanceOptionPath, 'post');
    if (params) {
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<{
        'isSuccess'?: boolean;
        'message'?: string;
        'data'?: {
        'pageNumber'?: number;
        'pageSize'?: number;
        'totalRowCount'?: number;
        'items'?: Array<RegEOverDraftBalanceOption>;
        };
        }>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `getRegEOverDraftBalanceOption$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getRegEOverDraftBalanceOption(params?: {
  }): Observable<{
'isSuccess'?: boolean;
'message'?: string;
'data'?: {
'pageNumber'?: number;
'pageSize'?: number;
'totalRowCount'?: number;
'items'?: Array<RegEOverDraftBalanceOption>;
};
}> {

    return this.getRegEOverDraftBalanceOption$Response(params).pipe(
      map((r: StrictHttpResponse<{
'isSuccess'?: boolean;
'message'?: string;
'data'?: {
'pageNumber'?: number;
'pageSize'?: number;
'totalRowCount'?: number;
'items'?: Array<RegEOverDraftBalanceOption>;
};
}>) => r.body as {
'isSuccess'?: boolean;
'message'?: string;
'data'?: {
'pageNumber'?: number;
'pageSize'?: number;
'totalRowCount'?: number;
'items'?: Array<RegEOverDraftBalanceOption>;
};
})
    );
  }

  /**
   * Path part for operation generateOdpReportData
   */
  static readonly GenerateOdpReportDataPath = '/v1/overDraftProgram/report/generateODPReportData';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `generateOdpReportData()` instead.
   *
   * This method doesn't expect any request body.
   */
  generateOdpReportData$Response(params?: {
  }): Observable<StrictHttpResponse<{
'isSuccess'?: boolean;
'message'?: string;
'data'?: {
'pageNumber'?: number;
'pageSize'?: number;
'totalRowCount'?: number;
'items'?: Array<OdpReportData>;
};
}>> {

    const rb = new RequestBuilder(this.rootUrl, ApiService.GenerateOdpReportDataPath, 'post');
    if (params) {
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<{
        'isSuccess'?: boolean;
        'message'?: string;
        'data'?: {
        'pageNumber'?: number;
        'pageSize'?: number;
        'totalRowCount'?: number;
        'items'?: Array<OdpReportData>;
        };
        }>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `generateOdpReportData$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  generateOdpReportData(params?: {
  }): Observable<{
'isSuccess'?: boolean;
'message'?: string;
'data'?: {
'pageNumber'?: number;
'pageSize'?: number;
'totalRowCount'?: number;
'items'?: Array<OdpReportData>;
};
}> {

    return this.generateOdpReportData$Response(params).pipe(
      map((r: StrictHttpResponse<{
'isSuccess'?: boolean;
'message'?: string;
'data'?: {
'pageNumber'?: number;
'pageSize'?: number;
'totalRowCount'?: number;
'items'?: Array<OdpReportData>;
};
}>) => r.body as {
'isSuccess'?: boolean;
'message'?: string;
'data'?: {
'pageNumber'?: number;
'pageSize'?: number;
'totalRowCount'?: number;
'items'?: Array<OdpReportData>;
};
})
    );
  }

  /**
   * Path part for operation generateOdpReportFilterData
   */
  static readonly GenerateOdpReportFilterDataPath = '/v1/overDraftProgram/report/generateFilterDataSet';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `generateOdpReportFilterData()` instead.
   *
   * This method doesn't expect any request body.
   */
  generateOdpReportFilterData$Response(params?: {
  }): Observable<StrictHttpResponse<{
'isSuccess'?: boolean;
'message'?: string;
'data'?: {
'pageNumber'?: number;
'pageSize'?: number;
'totalRowCount'?: number;
'items'?: Array<OdpReportBranchXRegion>;
};
}>> {

    const rb = new RequestBuilder(this.rootUrl, ApiService.GenerateOdpReportFilterDataPath, 'post');
    if (params) {
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<{
        'isSuccess'?: boolean;
        'message'?: string;
        'data'?: {
        'pageNumber'?: number;
        'pageSize'?: number;
        'totalRowCount'?: number;
        'items'?: Array<OdpReportBranchXRegion>;
        };
        }>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `generateOdpReportFilterData$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  generateOdpReportFilterData(params?: {
  }): Observable<{
'isSuccess'?: boolean;
'message'?: string;
'data'?: {
'pageNumber'?: number;
'pageSize'?: number;
'totalRowCount'?: number;
'items'?: Array<OdpReportBranchXRegion>;
};
}> {

    return this.generateOdpReportFilterData$Response(params).pipe(
      map((r: StrictHttpResponse<{
'isSuccess'?: boolean;
'message'?: string;
'data'?: {
'pageNumber'?: number;
'pageSize'?: number;
'totalRowCount'?: number;
'items'?: Array<OdpReportBranchXRegion>;
};
}>) => r.body as {
'isSuccess'?: boolean;
'message'?: string;
'data'?: {
'pageNumber'?: number;
'pageSize'?: number;
'totalRowCount'?: number;
'items'?: Array<OdpReportBranchXRegion>;
};
})
    );
  }

  /**
   * Path part for operation getOverdraftLimitHistory
   */
  static readonly GetOverdraftLimitHistoryPath = '/v1/overDraftProgram/report/getOverdraftLimitHistory';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getOverdraftLimitHistory()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  getOverdraftLimitHistory$Response(params: {
    body: OdpRequest
  }): Observable<StrictHttpResponse<{
'isSuccess'?: boolean;
'message'?: string;
'data'?: {
'pageNumber'?: number;
'pageSize'?: number;
'totalRowCount'?: number;
'items'?: Array<OdpEntityOverDraftLimit>;
};
}>> {

    const rb = new RequestBuilder(this.rootUrl, ApiService.GetOverdraftLimitHistoryPath, 'post');
    if (params) {
      rb.body(params.body, 'application/json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<{
        'isSuccess'?: boolean;
        'message'?: string;
        'data'?: {
        'pageNumber'?: number;
        'pageSize'?: number;
        'totalRowCount'?: number;
        'items'?: Array<OdpEntityOverDraftLimit>;
        };
        }>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `getOverdraftLimitHistory$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  getOverdraftLimitHistory(params: {
    body: OdpRequest
  }): Observable<{
'isSuccess'?: boolean;
'message'?: string;
'data'?: {
'pageNumber'?: number;
'pageSize'?: number;
'totalRowCount'?: number;
'items'?: Array<OdpEntityOverDraftLimit>;
};
}> {

    return this.getOverdraftLimitHistory$Response(params).pipe(
      map((r: StrictHttpResponse<{
'isSuccess'?: boolean;
'message'?: string;
'data'?: {
'pageNumber'?: number;
'pageSize'?: number;
'totalRowCount'?: number;
'items'?: Array<OdpEntityOverDraftLimit>;
};
}>) => r.body as {
'isSuccess'?: boolean;
'message'?: string;
'data'?: {
'pageNumber'?: number;
'pageSize'?: number;
'totalRowCount'?: number;
'items'?: Array<OdpEntityOverDraftLimit>;
};
})
    );
  }

  /**
   * Path part for operation getNoticeHistory
   */
  static readonly GetNoticeHistoryPath = '/v1/overDraftProgram/report/getNoticeHistory';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getNoticeHistory()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  getNoticeHistory$Response(params: {
    body: OdpRequest
  }): Observable<StrictHttpResponse<{
'isSuccess'?: boolean;
'message'?: string;
'data'?: {
'pageNumber'?: number;
'pageSize'?: number;
'totalRowCount'?: number;
'items'?: Array<OdpNotice>;
};
}>> {

    const rb = new RequestBuilder(this.rootUrl, ApiService.GetNoticeHistoryPath, 'post');
    if (params) {
      rb.body(params.body, 'application/json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<{
        'isSuccess'?: boolean;
        'message'?: string;
        'data'?: {
        'pageNumber'?: number;
        'pageSize'?: number;
        'totalRowCount'?: number;
        'items'?: Array<OdpNotice>;
        };
        }>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `getNoticeHistory$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  getNoticeHistory(params: {
    body: OdpRequest
  }): Observable<{
'isSuccess'?: boolean;
'message'?: string;
'data'?: {
'pageNumber'?: number;
'pageSize'?: number;
'totalRowCount'?: number;
'items'?: Array<OdpNotice>;
};
}> {

    return this.getNoticeHistory$Response(params).pipe(
      map((r: StrictHttpResponse<{
'isSuccess'?: boolean;
'message'?: string;
'data'?: {
'pageNumber'?: number;
'pageSize'?: number;
'totalRowCount'?: number;
'items'?: Array<OdpNotice>;
};
}>) => r.body as {
'isSuccess'?: boolean;
'message'?: string;
'data'?: {
'pageNumber'?: number;
'pageSize'?: number;
'totalRowCount'?: number;
'items'?: Array<OdpNotice>;
};
})
    );
  }

  /**
   * Path part for operation getRegEHistory
   */
  static readonly GetRegEHistoryPath = '/v1/overDraftProgram/report/getRegEHistory';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getRegEHistory()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  getRegEHistory$Response(params: {
    body: OdpRequest
  }): Observable<StrictHttpResponse<{
'isSuccess'?: boolean;
'message'?: string;
'data'?: {
'pageNumber'?: number;
'pageSize'?: number;
'totalRowCount'?: number;
'items'?: Array<OdpRegE>;
};
}>> {

    const rb = new RequestBuilder(this.rootUrl, ApiService.GetRegEHistoryPath, 'post');
    if (params) {
      rb.body(params.body, 'application/json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<{
        'isSuccess'?: boolean;
        'message'?: string;
        'data'?: {
        'pageNumber'?: number;
        'pageSize'?: number;
        'totalRowCount'?: number;
        'items'?: Array<OdpRegE>;
        };
        }>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `getRegEHistory$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  getRegEHistory(params: {
    body: OdpRequest
  }): Observable<{
'isSuccess'?: boolean;
'message'?: string;
'data'?: {
'pageNumber'?: number;
'pageSize'?: number;
'totalRowCount'?: number;
'items'?: Array<OdpRegE>;
};
}> {

    return this.getRegEHistory$Response(params).pipe(
      map((r: StrictHttpResponse<{
'isSuccess'?: boolean;
'message'?: string;
'data'?: {
'pageNumber'?: number;
'pageSize'?: number;
'totalRowCount'?: number;
'items'?: Array<OdpRegE>;
};
}>) => r.body as {
'isSuccess'?: boolean;
'message'?: string;
'data'?: {
'pageNumber'?: number;
'pageSize'?: number;
'totalRowCount'?: number;
'items'?: Array<OdpRegE>;
};
})
    );
  }

  /**
   * Path part for operation generateOdpSegmentationReportData
   */
  static readonly GenerateOdpSegmentationReportDataPath = '/v1/overDraftProgram/report/generateODPSegmentationReportData';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `generateOdpSegmentationReportData()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  generateOdpSegmentationReportData$Response(params: {
    body: OdpReportNsfSegRequest
  }): Observable<StrictHttpResponse<{
'isSuccess'?: boolean;
'message'?: string;
'data'?: {
'pageNumber'?: number;
'pageSize'?: number;
'totalRowCount'?: number;
'items'?: Array<OdpReportNsfSegData>;
};
}>> {

    const rb = new RequestBuilder(this.rootUrl, ApiService.GenerateOdpSegmentationReportDataPath, 'post');
    if (params) {
      rb.body(params.body, 'application/json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<{
        'isSuccess'?: boolean;
        'message'?: string;
        'data'?: {
        'pageNumber'?: number;
        'pageSize'?: number;
        'totalRowCount'?: number;
        'items'?: Array<OdpReportNsfSegData>;
        };
        }>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `generateOdpSegmentationReportData$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  generateOdpSegmentationReportData(params: {
    body: OdpReportNsfSegRequest
  }): Observable<{
'isSuccess'?: boolean;
'message'?: string;
'data'?: {
'pageNumber'?: number;
'pageSize'?: number;
'totalRowCount'?: number;
'items'?: Array<OdpReportNsfSegData>;
};
}> {

    return this.generateOdpSegmentationReportData$Response(params).pipe(
      map((r: StrictHttpResponse<{
'isSuccess'?: boolean;
'message'?: string;
'data'?: {
'pageNumber'?: number;
'pageSize'?: number;
'totalRowCount'?: number;
'items'?: Array<OdpReportNsfSegData>;
};
}>) => r.body as {
'isSuccess'?: boolean;
'message'?: string;
'data'?: {
'pageNumber'?: number;
'pageSize'?: number;
'totalRowCount'?: number;
'items'?: Array<OdpReportNsfSegData>;
};
})
    );
  }

  /**
   * Path part for operation debitCardApplicationAdd
   */
  static readonly DebitCardApplicationAddPath = '/v1/debitCardApplication/add/debitCardApplication';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `debitCardApplicationAdd()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  debitCardApplicationAdd$Response(params: {
    body: DebitCardApplicationItem
  }): Observable<StrictHttpResponse<{
'isSuccess'?: boolean;
'message'?: string;
'data'?: string;
}>> {

    const rb = new RequestBuilder(this.rootUrl, ApiService.DebitCardApplicationAddPath, 'post');
    if (params) {
      rb.body(params.body, 'application/json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<{
        'isSuccess'?: boolean;
        'message'?: string;
        'data'?: string;
        }>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `debitCardApplicationAdd$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  debitCardApplicationAdd(params: {
    body: DebitCardApplicationItem
  }): Observable<{
'isSuccess'?: boolean;
'message'?: string;
'data'?: string;
}> {

    return this.debitCardApplicationAdd$Response(params).pipe(
      map((r: StrictHttpResponse<{
'isSuccess'?: boolean;
'message'?: string;
'data'?: string;
}>) => r.body as {
'isSuccess'?: boolean;
'message'?: string;
'data'?: string;
})
    );
  }

  /**
   * Path part for operation debitCardApplicationList
   */
  static readonly DebitCardApplicationListPath = '/v1/debitCardApplication/list/debitCardApplication';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `debitCardApplicationList()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  debitCardApplicationList$Response(params: {
    body: DebitCardApplicationRequest
  }): Observable<StrictHttpResponse<{
'isSuccess'?: boolean;
'message'?: string;
'data'?: {
'pageNumber'?: number;
'pageSize'?: number;
'totalRowCount'?: number;
'items'?: Array<DebitCardApplication>;
};
}>> {

    const rb = new RequestBuilder(this.rootUrl, ApiService.DebitCardApplicationListPath, 'post');
    if (params) {
      rb.body(params.body, 'application/json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<{
        'isSuccess'?: boolean;
        'message'?: string;
        'data'?: {
        'pageNumber'?: number;
        'pageSize'?: number;
        'totalRowCount'?: number;
        'items'?: Array<DebitCardApplication>;
        };
        }>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `debitCardApplicationList$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  debitCardApplicationList(params: {
    body: DebitCardApplicationRequest
  }): Observable<{
'isSuccess'?: boolean;
'message'?: string;
'data'?: {
'pageNumber'?: number;
'pageSize'?: number;
'totalRowCount'?: number;
'items'?: Array<DebitCardApplication>;
};
}> {

    return this.debitCardApplicationList$Response(params).pipe(
      map((r: StrictHttpResponse<{
'isSuccess'?: boolean;
'message'?: string;
'data'?: {
'pageNumber'?: number;
'pageSize'?: number;
'totalRowCount'?: number;
'items'?: Array<DebitCardApplication>;
};
}>) => r.body as {
'isSuccess'?: boolean;
'message'?: string;
'data'?: {
'pageNumber'?: number;
'pageSize'?: number;
'totalRowCount'?: number;
'items'?: Array<DebitCardApplication>;
};
})
    );
  }

  /**
   * Path part for operation overrideAndAddDebitCardApplication
   */
  static readonly OverrideAndAddDebitCardApplicationPath = '/v1/debitCardApplication/override/debitCardApplication';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `overrideAndAddDebitCardApplication()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  overrideAndAddDebitCardApplication$Response(params: {
    body: OldDebitCardApplicationRequest
  }): Observable<StrictHttpResponse<{
'isSuccess'?: boolean;
'message'?: string;
'data'?: {
'pageNumber'?: number;
'pageSize'?: number;
'totalRowCount'?: number;
'items'?: Array<DebitCardApplication>;
};
}>> {

    const rb = new RequestBuilder(this.rootUrl, ApiService.OverrideAndAddDebitCardApplicationPath, 'post');
    if (params) {
      rb.body(params.body, 'application/json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<{
        'isSuccess'?: boolean;
        'message'?: string;
        'data'?: {
        'pageNumber'?: number;
        'pageSize'?: number;
        'totalRowCount'?: number;
        'items'?: Array<DebitCardApplication>;
        };
        }>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `overrideAndAddDebitCardApplication$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  overrideAndAddDebitCardApplication(params: {
    body: OldDebitCardApplicationRequest
  }): Observable<{
'isSuccess'?: boolean;
'message'?: string;
'data'?: {
'pageNumber'?: number;
'pageSize'?: number;
'totalRowCount'?: number;
'items'?: Array<DebitCardApplication>;
};
}> {

    return this.overrideAndAddDebitCardApplication$Response(params).pipe(
      map((r: StrictHttpResponse<{
'isSuccess'?: boolean;
'message'?: string;
'data'?: {
'pageNumber'?: number;
'pageSize'?: number;
'totalRowCount'?: number;
'items'?: Array<DebitCardApplication>;
};
}>) => r.body as {
'isSuccess'?: boolean;
'message'?: string;
'data'?: {
'pageNumber'?: number;
'pageSize'?: number;
'totalRowCount'?: number;
'items'?: Array<DebitCardApplication>;
};
})
    );
  }

  /**
   * Path part for operation acquireToken
   */
  static readonly AcquireTokenPath = '/v1/tokenProvider/acquireToken';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `acquireToken()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  acquireToken$Response(params: {
    body: UserOtpValidationRequest
  }): Observable<StrictHttpResponse<{
'isSuccess'?: boolean;
'message'?: string;
'data'?: {
'pageNumber'?: number;
'pageSize'?: number;
'totalRowCount'?: number;
'items'?: Array<Token>;
};
}>> {

    const rb = new RequestBuilder(this.rootUrl, ApiService.AcquireTokenPath, 'post');
    if (params) {
      rb.body(params.body, 'application/json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<{
        'isSuccess'?: boolean;
        'message'?: string;
        'data'?: {
        'pageNumber'?: number;
        'pageSize'?: number;
        'totalRowCount'?: number;
        'items'?: Array<Token>;
        };
        }>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `acquireToken$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  acquireToken(params: {
    body: UserOtpValidationRequest
  }): Observable<{
'isSuccess'?: boolean;
'message'?: string;
'data'?: {
'pageNumber'?: number;
'pageSize'?: number;
'totalRowCount'?: number;
'items'?: Array<Token>;
};
}> {

    return this.acquireToken$Response(params).pipe(
      map((r: StrictHttpResponse<{
'isSuccess'?: boolean;
'message'?: string;
'data'?: {
'pageNumber'?: number;
'pageSize'?: number;
'totalRowCount'?: number;
'items'?: Array<Token>;
};
}>) => r.body as {
'isSuccess'?: boolean;
'message'?: string;
'data'?: {
'pageNumber'?: number;
'pageSize'?: number;
'totalRowCount'?: number;
'items'?: Array<Token>;
};
})
    );
  }

  /**
   * Path part for operation sendVerificationCode
   */
  static readonly SendVerificationCodePath = '/v1/tokenProvider/sendVerificationCode';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `sendVerificationCode()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  sendVerificationCode$Response(params: {
    body: GenerateOtpRequest
  }): Observable<StrictHttpResponse<{
'isSuccess'?: boolean;
'message'?: string;
'data'?: string;
}>> {

    const rb = new RequestBuilder(this.rootUrl, ApiService.SendVerificationCodePath, 'post');
    if (params) {
      rb.body(params.body, 'application/json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<{
        'isSuccess'?: boolean;
        'message'?: string;
        'data'?: string;
        }>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `sendVerificationCode$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  sendVerificationCode(params: {
    body: GenerateOtpRequest
  }): Observable<{
'isSuccess'?: boolean;
'message'?: string;
'data'?: string;
}> {

    return this.sendVerificationCode$Response(params).pipe(
      map((r: StrictHttpResponse<{
'isSuccess'?: boolean;
'message'?: string;
'data'?: string;
}>) => r.body as {
'isSuccess'?: boolean;
'message'?: string;
'data'?: string;
})
    );
  }

  /**
   * Path part for operation sendMessage
   */
  static readonly SendMessagePath = '/v1/tokenProvider/sendMessage';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `sendMessage()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  sendMessage$Response(params: {
    body: SendMessageRequest
  }): Observable<StrictHttpResponse<{
'isSuccess'?: boolean;
'message'?: string;
'data'?: string;
}>> {

    const rb = new RequestBuilder(this.rootUrl, ApiService.SendMessagePath, 'post');
    if (params) {
      rb.body(params.body, 'application/json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<{
        'isSuccess'?: boolean;
        'message'?: string;
        'data'?: string;
        }>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `sendMessage$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  sendMessage(params: {
    body: SendMessageRequest
  }): Observable<{
'isSuccess'?: boolean;
'message'?: string;
'data'?: string;
}> {

    return this.sendMessage$Response(params).pipe(
      map((r: StrictHttpResponse<{
'isSuccess'?: boolean;
'message'?: string;
'data'?: string;
}>) => r.body as {
'isSuccess'?: boolean;
'message'?: string;
'data'?: string;
})
    );
  }

  /**
   * Path part for operation applicationStatusList_1
   */
  static readonly ApplicationStatusList_1Path = '/v1/applicationDecisioning/list/applicationStatuses';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `applicationStatusList_1()` instead.
   *
   * This method doesn't expect any request body.
   */
  applicationStatusList_1$Response(params?: {
  }): Observable<StrictHttpResponse<{
'isSuccess'?: boolean;
'message'?: string;
'data'?: {
'pageNumber'?: number;
'pageSize'?: number;
'totalRowCount'?: number;
'items'?: Array<ApplicationDecisioningStatus>;
};
}>> {

    const rb = new RequestBuilder(this.rootUrl, ApiService.ApplicationStatusList_1Path, 'post');
    if (params) {
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<{
        'isSuccess'?: boolean;
        'message'?: string;
        'data'?: {
        'pageNumber'?: number;
        'pageSize'?: number;
        'totalRowCount'?: number;
        'items'?: Array<ApplicationDecisioningStatus>;
        };
        }>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `applicationStatusList_1$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  applicationStatusList_1(params?: {
  }): Observable<{
'isSuccess'?: boolean;
'message'?: string;
'data'?: {
'pageNumber'?: number;
'pageSize'?: number;
'totalRowCount'?: number;
'items'?: Array<ApplicationDecisioningStatus>;
};
}> {

    return this.applicationStatusList_1$Response(params).pipe(
      map((r: StrictHttpResponse<{
'isSuccess'?: boolean;
'message'?: string;
'data'?: {
'pageNumber'?: number;
'pageSize'?: number;
'totalRowCount'?: number;
'items'?: Array<ApplicationDecisioningStatus>;
};
}>) => r.body as {
'isSuccess'?: boolean;
'message'?: string;
'data'?: {
'pageNumber'?: number;
'pageSize'?: number;
'totalRowCount'?: number;
'items'?: Array<ApplicationDecisioningStatus>;
};
})
    );
  }

  /**
   * Path part for operation applicationTestStatusList
   */
  static readonly ApplicationTestStatusListPath = '/v1/applicationDecisioning/list/applicationTestStatuses';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `applicationTestStatusList()` instead.
   *
   * This method doesn't expect any request body.
   */
  applicationTestStatusList$Response(params?: {
  }): Observable<StrictHttpResponse<{
'isSuccess'?: boolean;
'message'?: string;
'data'?: {
'pageNumber'?: number;
'pageSize'?: number;
'totalRowCount'?: number;
'items'?: Array<ApplicationTestStatus>;
};
}>> {

    const rb = new RequestBuilder(this.rootUrl, ApiService.ApplicationTestStatusListPath, 'post');
    if (params) {
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<{
        'isSuccess'?: boolean;
        'message'?: string;
        'data'?: {
        'pageNumber'?: number;
        'pageSize'?: number;
        'totalRowCount'?: number;
        'items'?: Array<ApplicationTestStatus>;
        };
        }>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `applicationTestStatusList$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  applicationTestStatusList(params?: {
  }): Observable<{
'isSuccess'?: boolean;
'message'?: string;
'data'?: {
'pageNumber'?: number;
'pageSize'?: number;
'totalRowCount'?: number;
'items'?: Array<ApplicationTestStatus>;
};
}> {

    return this.applicationTestStatusList$Response(params).pipe(
      map((r: StrictHttpResponse<{
'isSuccess'?: boolean;
'message'?: string;
'data'?: {
'pageNumber'?: number;
'pageSize'?: number;
'totalRowCount'?: number;
'items'?: Array<ApplicationTestStatus>;
};
}>) => r.body as {
'isSuccess'?: boolean;
'message'?: string;
'data'?: {
'pageNumber'?: number;
'pageSize'?: number;
'totalRowCount'?: number;
'items'?: Array<ApplicationTestStatus>;
};
})
    );
  }

  /**
   * Path part for operation applicationReviewStatusList
   */
  static readonly ApplicationReviewStatusListPath = '/v1/applicationDecisioning/list/applicationReviewStatuses';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `applicationReviewStatusList()` instead.
   *
   * This method doesn't expect any request body.
   */
  applicationReviewStatusList$Response(params?: {
  }): Observable<StrictHttpResponse<{
'isSuccess'?: boolean;
'message'?: string;
'data'?: {
'pageNumber'?: number;
'pageSize'?: number;
'totalRowCount'?: number;
'items'?: Array<ApplicationReviewStatus>;
};
}>> {

    const rb = new RequestBuilder(this.rootUrl, ApiService.ApplicationReviewStatusListPath, 'post');
    if (params) {
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<{
        'isSuccess'?: boolean;
        'message'?: string;
        'data'?: {
        'pageNumber'?: number;
        'pageSize'?: number;
        'totalRowCount'?: number;
        'items'?: Array<ApplicationReviewStatus>;
        };
        }>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `applicationReviewStatusList$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  applicationReviewStatusList(params?: {
  }): Observable<{
'isSuccess'?: boolean;
'message'?: string;
'data'?: {
'pageNumber'?: number;
'pageSize'?: number;
'totalRowCount'?: number;
'items'?: Array<ApplicationReviewStatus>;
};
}> {

    return this.applicationReviewStatusList$Response(params).pipe(
      map((r: StrictHttpResponse<{
'isSuccess'?: boolean;
'message'?: string;
'data'?: {
'pageNumber'?: number;
'pageSize'?: number;
'totalRowCount'?: number;
'items'?: Array<ApplicationReviewStatus>;
};
}>) => r.body as {
'isSuccess'?: boolean;
'message'?: string;
'data'?: {
'pageNumber'?: number;
'pageSize'?: number;
'totalRowCount'?: number;
'items'?: Array<ApplicationReviewStatus>;
};
})
    );
  }

  /**
   * Path part for operation applicationList
   */
  static readonly ApplicationListPath = '/v1/applicationDecisioning/list/applications';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `applicationList()` instead.
   *
   * This method doesn't expect any request body.
   */
  applicationList$Response(params?: {
  }): Observable<StrictHttpResponse<{
'isSuccess'?: boolean;
'message'?: string;
'data'?: {
'pageNumber'?: number;
'pageSize'?: number;
'totalRowCount'?: number;
'items'?: Array<ApplicationDecisioning>;
};
}>> {

    const rb = new RequestBuilder(this.rootUrl, ApiService.ApplicationListPath, 'post');
    if (params) {
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<{
        'isSuccess'?: boolean;
        'message'?: string;
        'data'?: {
        'pageNumber'?: number;
        'pageSize'?: number;
        'totalRowCount'?: number;
        'items'?: Array<ApplicationDecisioning>;
        };
        }>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `applicationList$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  applicationList(params?: {
  }): Observable<{
'isSuccess'?: boolean;
'message'?: string;
'data'?: {
'pageNumber'?: number;
'pageSize'?: number;
'totalRowCount'?: number;
'items'?: Array<ApplicationDecisioning>;
};
}> {

    return this.applicationList$Response(params).pipe(
      map((r: StrictHttpResponse<{
'isSuccess'?: boolean;
'message'?: string;
'data'?: {
'pageNumber'?: number;
'pageSize'?: number;
'totalRowCount'?: number;
'items'?: Array<ApplicationDecisioning>;
};
}>) => r.body as {
'isSuccess'?: boolean;
'message'?: string;
'data'?: {
'pageNumber'?: number;
'pageSize'?: number;
'totalRowCount'?: number;
'items'?: Array<ApplicationDecisioning>;
};
})
    );
  }

  /**
   * Path part for operation accountDecisioningList
   */
  static readonly AccountDecisioningListPath = '/v1/applicationDecisioning/list/accountDecisioning';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `accountDecisioningList()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  accountDecisioningList$Response(params: {
    body: EntityGetRequest
  }): Observable<StrictHttpResponse<{
'isSuccess'?: boolean;
'message'?: string;
'data'?: {
'pageNumber'?: number;
'pageSize'?: number;
'totalRowCount'?: number;
'items'?: Array<ApplicationDecisioning>;
};
}>> {

    const rb = new RequestBuilder(this.rootUrl, ApiService.AccountDecisioningListPath, 'post');
    if (params) {
      rb.body(params.body, 'application/json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<{
        'isSuccess'?: boolean;
        'message'?: string;
        'data'?: {
        'pageNumber'?: number;
        'pageSize'?: number;
        'totalRowCount'?: number;
        'items'?: Array<ApplicationDecisioning>;
        };
        }>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `accountDecisioningList$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  accountDecisioningList(params: {
    body: EntityGetRequest
  }): Observable<{
'isSuccess'?: boolean;
'message'?: string;
'data'?: {
'pageNumber'?: number;
'pageSize'?: number;
'totalRowCount'?: number;
'items'?: Array<ApplicationDecisioning>;
};
}> {

    return this.accountDecisioningList$Response(params).pipe(
      map((r: StrictHttpResponse<{
'isSuccess'?: boolean;
'message'?: string;
'data'?: {
'pageNumber'?: number;
'pageSize'?: number;
'totalRowCount'?: number;
'items'?: Array<ApplicationDecisioning>;
};
}>) => r.body as {
'isSuccess'?: boolean;
'message'?: string;
'data'?: {
'pageNumber'?: number;
'pageSize'?: number;
'totalRowCount'?: number;
'items'?: Array<ApplicationDecisioning>;
};
})
    );
  }

  /**
   * Path part for operation userIdentityDocumentDetails
   */
  static readonly UserIdentityDocumentDetailsPath = '/v1/applicationDecisioning/list/userIdentityDocumentDetails';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `userIdentityDocumentDetails()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  userIdentityDocumentDetails$Response(params: {
    body: UserIdentityDocumentRequest
  }): Observable<StrictHttpResponse<{
'isSuccess'?: boolean;
'message'?: string;
'data'?: {
'pageNumber'?: number;
'pageSize'?: number;
'totalRowCount'?: number;
'items'?: Array<UserIdentityDocumentDetail>;
};
}>> {

    const rb = new RequestBuilder(this.rootUrl, ApiService.UserIdentityDocumentDetailsPath, 'post');
    if (params) {
      rb.body(params.body, 'application/json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<{
        'isSuccess'?: boolean;
        'message'?: string;
        'data'?: {
        'pageNumber'?: number;
        'pageSize'?: number;
        'totalRowCount'?: number;
        'items'?: Array<UserIdentityDocumentDetail>;
        };
        }>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `userIdentityDocumentDetails$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  userIdentityDocumentDetails(params: {
    body: UserIdentityDocumentRequest
  }): Observable<{
'isSuccess'?: boolean;
'message'?: string;
'data'?: {
'pageNumber'?: number;
'pageSize'?: number;
'totalRowCount'?: number;
'items'?: Array<UserIdentityDocumentDetail>;
};
}> {

    return this.userIdentityDocumentDetails$Response(params).pipe(
      map((r: StrictHttpResponse<{
'isSuccess'?: boolean;
'message'?: string;
'data'?: {
'pageNumber'?: number;
'pageSize'?: number;
'totalRowCount'?: number;
'items'?: Array<UserIdentityDocumentDetail>;
};
}>) => r.body as {
'isSuccess'?: boolean;
'message'?: string;
'data'?: {
'pageNumber'?: number;
'pageSize'?: number;
'totalRowCount'?: number;
'items'?: Array<UserIdentityDocumentDetail>;
};
})
    );
  }

  /**
   * Path part for operation updateApplicationDecisioningStatus
   */
  static readonly UpdateApplicationDecisioningStatusPath = '/v1/applicationDecisioning/update/accountDecisioning';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `updateApplicationDecisioningStatus()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  updateApplicationDecisioningStatus$Response(params: {
    body: ApplicationDecisioningStatusUpdateRequest
  }): Observable<StrictHttpResponse<{
'isSuccess'?: boolean;
'message'?: string;
'data'?: string;
}>> {

    const rb = new RequestBuilder(this.rootUrl, ApiService.UpdateApplicationDecisioningStatusPath, 'post');
    if (params) {
      rb.body(params.body, 'application/json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<{
        'isSuccess'?: boolean;
        'message'?: string;
        'data'?: string;
        }>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `updateApplicationDecisioningStatus$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  updateApplicationDecisioningStatus(params: {
    body: ApplicationDecisioningStatusUpdateRequest
  }): Observable<{
'isSuccess'?: boolean;
'message'?: string;
'data'?: string;
}> {

    return this.updateApplicationDecisioningStatus$Response(params).pipe(
      map((r: StrictHttpResponse<{
'isSuccess'?: boolean;
'message'?: string;
'data'?: string;
}>) => r.body as {
'isSuccess'?: boolean;
'message'?: string;
'data'?: string;
})
    );
  }

  /**
   * Path part for operation updateApplicationTestStatus
   */
  static readonly UpdateApplicationTestStatusPath = '/v1/applicationDecisioning/updateTestStatus';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `updateApplicationTestStatus()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  updateApplicationTestStatus$Response(params: {
    body: UpdateApplicationTestRequest
  }): Observable<StrictHttpResponse<{
'isSuccess'?: boolean;
'message'?: string;
'data'?: string;
}>> {

    const rb = new RequestBuilder(this.rootUrl, ApiService.UpdateApplicationTestStatusPath, 'post');
    if (params) {
      rb.body(params.body, 'application/json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<{
        'isSuccess'?: boolean;
        'message'?: string;
        'data'?: string;
        }>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `updateApplicationTestStatus$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  updateApplicationTestStatus(params: {
    body: UpdateApplicationTestRequest
  }): Observable<{
'isSuccess'?: boolean;
'message'?: string;
'data'?: string;
}> {

    return this.updateApplicationTestStatus$Response(params).pipe(
      map((r: StrictHttpResponse<{
'isSuccess'?: boolean;
'message'?: string;
'data'?: string;
}>) => r.body as {
'isSuccess'?: boolean;
'message'?: string;
'data'?: string;
})
    );
  }

  /**
   * Path part for operation updateApplicationStatus
   */
  static readonly UpdateApplicationStatusPath = '/v1/applicationDecisioning/updateApplicationStatus';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `updateApplicationStatus()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  updateApplicationStatus$Response(params: {
    body: UpdateApplicationStatusRequest
  }): Observable<StrictHttpResponse<{
'isSuccess'?: boolean;
'message'?: string;
'data'?: string;
}>> {

    const rb = new RequestBuilder(this.rootUrl, ApiService.UpdateApplicationStatusPath, 'post');
    if (params) {
      rb.body(params.body, 'application/json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<{
        'isSuccess'?: boolean;
        'message'?: string;
        'data'?: string;
        }>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `updateApplicationStatus$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  updateApplicationStatus(params: {
    body: UpdateApplicationStatusRequest
  }): Observable<{
'isSuccess'?: boolean;
'message'?: string;
'data'?: string;
}> {

    return this.updateApplicationStatus$Response(params).pipe(
      map((r: StrictHttpResponse<{
'isSuccess'?: boolean;
'message'?: string;
'data'?: string;
}>) => r.body as {
'isSuccess'?: boolean;
'message'?: string;
'data'?: string;
})
    );
  }

  /**
   * Path part for operation createApplicationTest
   */
  static readonly CreateApplicationTestPath = '/v1/applicationDecisioning/createApplicationStatus';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `createApplicationTest()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  createApplicationTest$Response(params: {
    body: UpdateApplicationTestRequest
  }): Observable<StrictHttpResponse<{
'isSuccess'?: boolean;
'message'?: string;
'data'?: string;
}>> {

    const rb = new RequestBuilder(this.rootUrl, ApiService.CreateApplicationTestPath, 'post');
    if (params) {
      rb.body(params.body, 'application/json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<{
        'isSuccess'?: boolean;
        'message'?: string;
        'data'?: string;
        }>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `createApplicationTest$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  createApplicationTest(params: {
    body: UpdateApplicationTestRequest
  }): Observable<{
'isSuccess'?: boolean;
'message'?: string;
'data'?: string;
}> {

    return this.createApplicationTest$Response(params).pipe(
      map((r: StrictHttpResponse<{
'isSuccess'?: boolean;
'message'?: string;
'data'?: string;
}>) => r.body as {
'isSuccess'?: boolean;
'message'?: string;
'data'?: string;
})
    );
  }

  /**
   * Path part for operation getApplication
   */
  static readonly GetApplicationPath = '/v1/applicationDecisioning/getApplication';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getApplication()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  getApplication$Response(params: {
    body: ApplicationDecisioningRequest
  }): Observable<StrictHttpResponse<{
'isSuccess'?: boolean;
'message'?: string;
'data'?: {
'pageNumber'?: number;
'pageSize'?: number;
'totalRowCount'?: number;
'items'?: Array<ApplicationDecisioning>;
};
}>> {

    const rb = new RequestBuilder(this.rootUrl, ApiService.GetApplicationPath, 'post');
    if (params) {
      rb.body(params.body, 'application/json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<{
        'isSuccess'?: boolean;
        'message'?: string;
        'data'?: {
        'pageNumber'?: number;
        'pageSize'?: number;
        'totalRowCount'?: number;
        'items'?: Array<ApplicationDecisioning>;
        };
        }>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `getApplication$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  getApplication(params: {
    body: ApplicationDecisioningRequest
  }): Observable<{
'isSuccess'?: boolean;
'message'?: string;
'data'?: {
'pageNumber'?: number;
'pageSize'?: number;
'totalRowCount'?: number;
'items'?: Array<ApplicationDecisioning>;
};
}> {

    return this.getApplication$Response(params).pipe(
      map((r: StrictHttpResponse<{
'isSuccess'?: boolean;
'message'?: string;
'data'?: {
'pageNumber'?: number;
'pageSize'?: number;
'totalRowCount'?: number;
'items'?: Array<ApplicationDecisioning>;
};
}>) => r.body as {
'isSuccess'?: boolean;
'message'?: string;
'data'?: {
'pageNumber'?: number;
'pageSize'?: number;
'totalRowCount'?: number;
'items'?: Array<ApplicationDecisioning>;
};
})
    );
  }

  /**
   * Path part for operation saveApplicationFileReference
   */
  static readonly SaveApplicationFileReferencePath = '/v1/applicationDecisioning/saveApplicationFileReference';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `saveApplicationFileReference()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  saveApplicationFileReference$Response(params: {
    body: ApplicationFileSaveRequest
  }): Observable<StrictHttpResponse<{
'isSuccess'?: boolean;
'message'?: string;
'data'?: string;
}>> {

    const rb = new RequestBuilder(this.rootUrl, ApiService.SaveApplicationFileReferencePath, 'post');
    if (params) {
      rb.body(params.body, 'application/json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<{
        'isSuccess'?: boolean;
        'message'?: string;
        'data'?: string;
        }>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `saveApplicationFileReference$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  saveApplicationFileReference(params: {
    body: ApplicationFileSaveRequest
  }): Observable<{
'isSuccess'?: boolean;
'message'?: string;
'data'?: string;
}> {

    return this.saveApplicationFileReference$Response(params).pipe(
      map((r: StrictHttpResponse<{
'isSuccess'?: boolean;
'message'?: string;
'data'?: string;
}>) => r.body as {
'isSuccess'?: boolean;
'message'?: string;
'data'?: string;
})
    );
  }

  /**
   * Path part for operation getComments
   */
  static readonly GetCommentsPath = '/v1/applicationDecisioning/getComments';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getComments()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  getComments$Response(params: {
    body: ChatRequest
  }): Observable<StrictHttpResponse<{
'isSuccess'?: boolean;
'message'?: string;
'data'?: {
'pageNumber'?: number;
'pageSize'?: number;
'totalRowCount'?: number;
'items'?: Array<ChatModel>;
};
}>> {

    const rb = new RequestBuilder(this.rootUrl, ApiService.GetCommentsPath, 'post');
    if (params) {
      rb.body(params.body, 'application/json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<{
        'isSuccess'?: boolean;
        'message'?: string;
        'data'?: {
        'pageNumber'?: number;
        'pageSize'?: number;
        'totalRowCount'?: number;
        'items'?: Array<ChatModel>;
        };
        }>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `getComments$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  getComments(params: {
    body: ChatRequest
  }): Observable<{
'isSuccess'?: boolean;
'message'?: string;
'data'?: {
'pageNumber'?: number;
'pageSize'?: number;
'totalRowCount'?: number;
'items'?: Array<ChatModel>;
};
}> {

    return this.getComments$Response(params).pipe(
      map((r: StrictHttpResponse<{
'isSuccess'?: boolean;
'message'?: string;
'data'?: {
'pageNumber'?: number;
'pageSize'?: number;
'totalRowCount'?: number;
'items'?: Array<ChatModel>;
};
}>) => r.body as {
'isSuccess'?: boolean;
'message'?: string;
'data'?: {
'pageNumber'?: number;
'pageSize'?: number;
'totalRowCount'?: number;
'items'?: Array<ChatModel>;
};
})
    );
  }

  /**
   * Path part for operation addAccountOpeningChatMessage
   */
  static readonly AddAccountOpeningChatMessagePath = '/v1/applicationDecisioning/add/accountOpeningChats';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `addAccountOpeningChatMessage()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  addAccountOpeningChatMessage$Response(params: {
    body: ChatAddRequest
  }): Observable<StrictHttpResponse<{
'isSuccess'?: boolean;
'message'?: string;
'data'?: {
'pageNumber'?: number;
'pageSize'?: number;
'totalRowCount'?: number;
'items'?: Array<ChatModel>;
};
}>> {

    const rb = new RequestBuilder(this.rootUrl, ApiService.AddAccountOpeningChatMessagePath, 'post');
    if (params) {
      rb.body(params.body, 'application/json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<{
        'isSuccess'?: boolean;
        'message'?: string;
        'data'?: {
        'pageNumber'?: number;
        'pageSize'?: number;
        'totalRowCount'?: number;
        'items'?: Array<ChatModel>;
        };
        }>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `addAccountOpeningChatMessage$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  addAccountOpeningChatMessage(params: {
    body: ChatAddRequest
  }): Observable<{
'isSuccess'?: boolean;
'message'?: string;
'data'?: {
'pageNumber'?: number;
'pageSize'?: number;
'totalRowCount'?: number;
'items'?: Array<ChatModel>;
};
}> {

    return this.addAccountOpeningChatMessage$Response(params).pipe(
      map((r: StrictHttpResponse<{
'isSuccess'?: boolean;
'message'?: string;
'data'?: {
'pageNumber'?: number;
'pageSize'?: number;
'totalRowCount'?: number;
'items'?: Array<ChatModel>;
};
}>) => r.body as {
'isSuccess'?: boolean;
'message'?: string;
'data'?: {
'pageNumber'?: number;
'pageSize'?: number;
'totalRowCount'?: number;
'items'?: Array<ChatModel>;
};
})
    );
  }

  /**
   * Path part for operation chatHistoryAdd
   */
  static readonly ChatHistoryAddPath = '/v1/chat/add/chat';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `chatHistoryAdd()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  chatHistoryAdd$Response(params: {
    body: ChatMessageModel
  }): Observable<StrictHttpResponse<{
'isSuccess'?: boolean;
'message'?: string;
'data'?: string;
}>> {

    const rb = new RequestBuilder(this.rootUrl, ApiService.ChatHistoryAddPath, 'put');
    if (params) {
      rb.body(params.body, 'application/json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<{
        'isSuccess'?: boolean;
        'message'?: string;
        'data'?: string;
        }>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `chatHistoryAdd$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  chatHistoryAdd(params: {
    body: ChatMessageModel
  }): Observable<{
'isSuccess'?: boolean;
'message'?: string;
'data'?: string;
}> {

    return this.chatHistoryAdd$Response(params).pipe(
      map((r: StrictHttpResponse<{
'isSuccess'?: boolean;
'message'?: string;
'data'?: string;
}>) => r.body as {
'isSuccess'?: boolean;
'message'?: string;
'data'?: string;
})
    );
  }

  /**
   * Path part for operation chatHistoryListBetweenUsers
   */
  static readonly ChatHistoryListBetweenUsersPath = '/v1/chat/list/chatHistory/users';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `chatHistoryListBetweenUsers()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  chatHistoryListBetweenUsers$Response(params: {
    body: ChatMessagesForUser
  }): Observable<StrictHttpResponse<{
'isSuccess'?: boolean;
'message'?: string;
'data'?: {
'pageNumber'?: number;
'pageSize'?: number;
'totalRowCount'?: number;
'items'?: Array<ChatMessageResponseModel>;
};
}>> {

    const rb = new RequestBuilder(this.rootUrl, ApiService.ChatHistoryListBetweenUsersPath, 'post');
    if (params) {
      rb.body(params.body, 'application/json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<{
        'isSuccess'?: boolean;
        'message'?: string;
        'data'?: {
        'pageNumber'?: number;
        'pageSize'?: number;
        'totalRowCount'?: number;
        'items'?: Array<ChatMessageResponseModel>;
        };
        }>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `chatHistoryListBetweenUsers$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  chatHistoryListBetweenUsers(params: {
    body: ChatMessagesForUser
  }): Observable<{
'isSuccess'?: boolean;
'message'?: string;
'data'?: {
'pageNumber'?: number;
'pageSize'?: number;
'totalRowCount'?: number;
'items'?: Array<ChatMessageResponseModel>;
};
}> {

    return this.chatHistoryListBetweenUsers$Response(params).pipe(
      map((r: StrictHttpResponse<{
'isSuccess'?: boolean;
'message'?: string;
'data'?: {
'pageNumber'?: number;
'pageSize'?: number;
'totalRowCount'?: number;
'items'?: Array<ChatMessageResponseModel>;
};
}>) => r.body as {
'isSuccess'?: boolean;
'message'?: string;
'data'?: {
'pageNumber'?: number;
'pageSize'?: number;
'totalRowCount'?: number;
'items'?: Array<ChatMessageResponseModel>;
};
})
    );
  }

  /**
   * Path part for operation markChatMessageAsDelivered
   */
  static readonly MarkChatMessageAsDeliveredPath = '/v1/chat/update/chatHistory/delivered';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `markChatMessageAsDelivered()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  markChatMessageAsDelivered$Response(params: {
    body: MarkChatMessageAsDelivered
  }): Observable<StrictHttpResponse<{
'isSuccess'?: boolean;
'message'?: string;
'data'?: string;
}>> {

    const rb = new RequestBuilder(this.rootUrl, ApiService.MarkChatMessageAsDeliveredPath, 'put');
    if (params) {
      rb.body(params.body, 'application/json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<{
        'isSuccess'?: boolean;
        'message'?: string;
        'data'?: string;
        }>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `markChatMessageAsDelivered$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  markChatMessageAsDelivered(params: {
    body: MarkChatMessageAsDelivered
  }): Observable<{
'isSuccess'?: boolean;
'message'?: string;
'data'?: string;
}> {

    return this.markChatMessageAsDelivered$Response(params).pipe(
      map((r: StrictHttpResponse<{
'isSuccess'?: boolean;
'message'?: string;
'data'?: string;
}>) => r.body as {
'isSuccess'?: boolean;
'message'?: string;
'data'?: string;
})
    );
  }

  /**
   * Path part for operation searchEntitiesByName
   */
  static readonly SearchEntitiesByNamePath = '/v1/relationshipAnalysis/searchEntitiesByName';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `searchEntitiesByName()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  searchEntitiesByName$Response(params: {
    body: RelationshipAnalysisSearchRequest
  }): Observable<StrictHttpResponse<{
'isSuccess'?: boolean;
'message'?: string;
'data'?: {
'pageNumber'?: number;
'pageSize'?: number;
'totalRowCount'?: number;
'items'?: Array<RelationshipAnalysisEntityItem>;
};
}>> {

    const rb = new RequestBuilder(this.rootUrl, ApiService.SearchEntitiesByNamePath, 'post');
    if (params) {
      rb.body(params.body, 'application/json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<{
        'isSuccess'?: boolean;
        'message'?: string;
        'data'?: {
        'pageNumber'?: number;
        'pageSize'?: number;
        'totalRowCount'?: number;
        'items'?: Array<RelationshipAnalysisEntityItem>;
        };
        }>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `searchEntitiesByName$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  searchEntitiesByName(params: {
    body: RelationshipAnalysisSearchRequest
  }): Observable<{
'isSuccess'?: boolean;
'message'?: string;
'data'?: {
'pageNumber'?: number;
'pageSize'?: number;
'totalRowCount'?: number;
'items'?: Array<RelationshipAnalysisEntityItem>;
};
}> {

    return this.searchEntitiesByName$Response(params).pipe(
      map((r: StrictHttpResponse<{
'isSuccess'?: boolean;
'message'?: string;
'data'?: {
'pageNumber'?: number;
'pageSize'?: number;
'totalRowCount'?: number;
'items'?: Array<RelationshipAnalysisEntityItem>;
};
}>) => r.body as {
'isSuccess'?: boolean;
'message'?: string;
'data'?: {
'pageNumber'?: number;
'pageSize'?: number;
'totalRowCount'?: number;
'items'?: Array<RelationshipAnalysisEntityItem>;
};
})
    );
  }

  /**
   * Path part for operation searchEntitiesByTin
   */
  static readonly SearchEntitiesByTinPath = '/v1/relationshipAnalysis/searchEntitiesByTin';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `searchEntitiesByTin()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  searchEntitiesByTin$Response(params: {
    body: RelationshipAnalysisSearchRequest
  }): Observable<StrictHttpResponse<{
'isSuccess'?: boolean;
'message'?: string;
'data'?: {
'pageNumber'?: number;
'pageSize'?: number;
'totalRowCount'?: number;
'items'?: Array<RelationshipAnalysisEntityItem>;
};
}>> {

    const rb = new RequestBuilder(this.rootUrl, ApiService.SearchEntitiesByTinPath, 'post');
    if (params) {
      rb.body(params.body, 'application/json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<{
        'isSuccess'?: boolean;
        'message'?: string;
        'data'?: {
        'pageNumber'?: number;
        'pageSize'?: number;
        'totalRowCount'?: number;
        'items'?: Array<RelationshipAnalysisEntityItem>;
        };
        }>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `searchEntitiesByTin$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  searchEntitiesByTin(params: {
    body: RelationshipAnalysisSearchRequest
  }): Observable<{
'isSuccess'?: boolean;
'message'?: string;
'data'?: {
'pageNumber'?: number;
'pageSize'?: number;
'totalRowCount'?: number;
'items'?: Array<RelationshipAnalysisEntityItem>;
};
}> {

    return this.searchEntitiesByTin$Response(params).pipe(
      map((r: StrictHttpResponse<{
'isSuccess'?: boolean;
'message'?: string;
'data'?: {
'pageNumber'?: number;
'pageSize'?: number;
'totalRowCount'?: number;
'items'?: Array<RelationshipAnalysisEntityItem>;
};
}>) => r.body as {
'isSuccess'?: boolean;
'message'?: string;
'data'?: {
'pageNumber'?: number;
'pageSize'?: number;
'totalRowCount'?: number;
'items'?: Array<RelationshipAnalysisEntityItem>;
};
})
    );
  }

  /**
   * Path part for operation getDepositAccountsList
   */
  static readonly GetDepositAccountsListPath = '/v1/relationshipAnalysis/getDepositAccountsList';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getDepositAccountsList()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  getDepositAccountsList$Response(params: {
    body: RelationshipAnalysisFetchRequest
  }): Observable<StrictHttpResponse<{
'isSuccess'?: boolean;
'message'?: string;
'data'?: {
'pageNumber'?: number;
'pageSize'?: number;
'totalRowCount'?: number;
'items'?: Array<RelationshipAnalysisDepositAccountItem>;
};
}>> {

    const rb = new RequestBuilder(this.rootUrl, ApiService.GetDepositAccountsListPath, 'post');
    if (params) {
      rb.body(params.body, 'application/json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<{
        'isSuccess'?: boolean;
        'message'?: string;
        'data'?: {
        'pageNumber'?: number;
        'pageSize'?: number;
        'totalRowCount'?: number;
        'items'?: Array<RelationshipAnalysisDepositAccountItem>;
        };
        }>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `getDepositAccountsList$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  getDepositAccountsList(params: {
    body: RelationshipAnalysisFetchRequest
  }): Observable<{
'isSuccess'?: boolean;
'message'?: string;
'data'?: {
'pageNumber'?: number;
'pageSize'?: number;
'totalRowCount'?: number;
'items'?: Array<RelationshipAnalysisDepositAccountItem>;
};
}> {

    return this.getDepositAccountsList$Response(params).pipe(
      map((r: StrictHttpResponse<{
'isSuccess'?: boolean;
'message'?: string;
'data'?: {
'pageNumber'?: number;
'pageSize'?: number;
'totalRowCount'?: number;
'items'?: Array<RelationshipAnalysisDepositAccountItem>;
};
}>) => r.body as {
'isSuccess'?: boolean;
'message'?: string;
'data'?: {
'pageNumber'?: number;
'pageSize'?: number;
'totalRowCount'?: number;
'items'?: Array<RelationshipAnalysisDepositAccountItem>;
};
})
    );
  }

  /**
   * Path part for operation getLoanAccountsList
   */
  static readonly GetLoanAccountsListPath = '/v1/relationshipAnalysis/getLoanAccountsList';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getLoanAccountsList()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  getLoanAccountsList$Response(params: {
    body: RelationshipAnalysisFetchRequest
  }): Observable<StrictHttpResponse<{
'isSuccess'?: boolean;
'message'?: string;
'data'?: {
'pageNumber'?: number;
'pageSize'?: number;
'totalRowCount'?: number;
'items'?: Array<RelationshipAnalysisLoanAccountItem>;
};
}>> {

    const rb = new RequestBuilder(this.rootUrl, ApiService.GetLoanAccountsListPath, 'post');
    if (params) {
      rb.body(params.body, 'application/json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<{
        'isSuccess'?: boolean;
        'message'?: string;
        'data'?: {
        'pageNumber'?: number;
        'pageSize'?: number;
        'totalRowCount'?: number;
        'items'?: Array<RelationshipAnalysisLoanAccountItem>;
        };
        }>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `getLoanAccountsList$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  getLoanAccountsList(params: {
    body: RelationshipAnalysisFetchRequest
  }): Observable<{
'isSuccess'?: boolean;
'message'?: string;
'data'?: {
'pageNumber'?: number;
'pageSize'?: number;
'totalRowCount'?: number;
'items'?: Array<RelationshipAnalysisLoanAccountItem>;
};
}> {

    return this.getLoanAccountsList$Response(params).pipe(
      map((r: StrictHttpResponse<{
'isSuccess'?: boolean;
'message'?: string;
'data'?: {
'pageNumber'?: number;
'pageSize'?: number;
'totalRowCount'?: number;
'items'?: Array<RelationshipAnalysisLoanAccountItem>;
};
}>) => r.body as {
'isSuccess'?: boolean;
'message'?: string;
'data'?: {
'pageNumber'?: number;
'pageSize'?: number;
'totalRowCount'?: number;
'items'?: Array<RelationshipAnalysisLoanAccountItem>;
};
})
    );
  }

  /**
   * Path part for operation entityList
   */
  static readonly EntityListPath = '/v1/relationshipAnalysis/list/entities';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `entityList()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  entityList$Response(params: {
    body: EntityGetRequest
  }): Observable<StrictHttpResponse<{
'isSuccess'?: boolean;
'message'?: string;
'data'?: {
'pageNumber'?: number;
'pageSize'?: number;
'totalRowCount'?: number;
'items'?: Array<RelationshipAnalysisEntityItem>;
};
}>> {

    const rb = new RequestBuilder(this.rootUrl, ApiService.EntityListPath, 'post');
    if (params) {
      rb.body(params.body, 'application/json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<{
        'isSuccess'?: boolean;
        'message'?: string;
        'data'?: {
        'pageNumber'?: number;
        'pageSize'?: number;
        'totalRowCount'?: number;
        'items'?: Array<RelationshipAnalysisEntityItem>;
        };
        }>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `entityList$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  entityList(params: {
    body: EntityGetRequest
  }): Observable<{
'isSuccess'?: boolean;
'message'?: string;
'data'?: {
'pageNumber'?: number;
'pageSize'?: number;
'totalRowCount'?: number;
'items'?: Array<RelationshipAnalysisEntityItem>;
};
}> {

    return this.entityList$Response(params).pipe(
      map((r: StrictHttpResponse<{
'isSuccess'?: boolean;
'message'?: string;
'data'?: {
'pageNumber'?: number;
'pageSize'?: number;
'totalRowCount'?: number;
'items'?: Array<RelationshipAnalysisEntityItem>;
};
}>) => r.body as {
'isSuccess'?: boolean;
'message'?: string;
'data'?: {
'pageNumber'?: number;
'pageSize'?: number;
'totalRowCount'?: number;
'items'?: Array<RelationshipAnalysisEntityItem>;
};
})
    );
  }

  /**
   * Path part for operation getIraAccountsList
   */
  static readonly GetIraAccountsListPath = '/v1/relationshipAnalysis/getIRAAccountsList';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getIraAccountsList()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  getIraAccountsList$Response(params: {
    body: RelationshipAnalysisFetchRequest
  }): Observable<StrictHttpResponse<{
'isSuccess'?: boolean;
'message'?: string;
'data'?: {
'pageNumber'?: number;
'pageSize'?: number;
'totalRowCount'?: number;
'items'?: Array<RelationshipAnalysisIraAccountItem>;
};
}>> {

    const rb = new RequestBuilder(this.rootUrl, ApiService.GetIraAccountsListPath, 'post');
    if (params) {
      rb.body(params.body, 'application/json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<{
        'isSuccess'?: boolean;
        'message'?: string;
        'data'?: {
        'pageNumber'?: number;
        'pageSize'?: number;
        'totalRowCount'?: number;
        'items'?: Array<RelationshipAnalysisIraAccountItem>;
        };
        }>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `getIraAccountsList$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  getIraAccountsList(params: {
    body: RelationshipAnalysisFetchRequest
  }): Observable<{
'isSuccess'?: boolean;
'message'?: string;
'data'?: {
'pageNumber'?: number;
'pageSize'?: number;
'totalRowCount'?: number;
'items'?: Array<RelationshipAnalysisIraAccountItem>;
};
}> {

    return this.getIraAccountsList$Response(params).pipe(
      map((r: StrictHttpResponse<{
'isSuccess'?: boolean;
'message'?: string;
'data'?: {
'pageNumber'?: number;
'pageSize'?: number;
'totalRowCount'?: number;
'items'?: Array<RelationshipAnalysisIraAccountItem>;
};
}>) => r.body as {
'isSuccess'?: boolean;
'message'?: string;
'data'?: {
'pageNumber'?: number;
'pageSize'?: number;
'totalRowCount'?: number;
'items'?: Array<RelationshipAnalysisIraAccountItem>;
};
})
    );
  }

  /**
   * Path part for operation getWikiModuleData
   */
  static readonly GetWikiModuleDataPath = '/v1/wikiManual/getWikiModuleData';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getWikiModuleData()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  getWikiModuleData$Response(params: {
    body: UserPeerRequest
  }): Observable<StrictHttpResponse<{
'isSuccess'?: boolean;
'message'?: string;
'data'?: {
'pageNumber'?: number;
'pageSize'?: number;
'totalRowCount'?: number;
'items'?: Array<WikiManualBookResponse>;
};
}>> {

    const rb = new RequestBuilder(this.rootUrl, ApiService.GetWikiModuleDataPath, 'post');
    if (params) {
      rb.body(params.body, 'application/json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<{
        'isSuccess'?: boolean;
        'message'?: string;
        'data'?: {
        'pageNumber'?: number;
        'pageSize'?: number;
        'totalRowCount'?: number;
        'items'?: Array<WikiManualBookResponse>;
        };
        }>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `getWikiModuleData$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  getWikiModuleData(params: {
    body: UserPeerRequest
  }): Observable<{
'isSuccess'?: boolean;
'message'?: string;
'data'?: {
'pageNumber'?: number;
'pageSize'?: number;
'totalRowCount'?: number;
'items'?: Array<WikiManualBookResponse>;
};
}> {

    return this.getWikiModuleData$Response(params).pipe(
      map((r: StrictHttpResponse<{
'isSuccess'?: boolean;
'message'?: string;
'data'?: {
'pageNumber'?: number;
'pageSize'?: number;
'totalRowCount'?: number;
'items'?: Array<WikiManualBookResponse>;
};
}>) => r.body as {
'isSuccess'?: boolean;
'message'?: string;
'data'?: {
'pageNumber'?: number;
'pageSize'?: number;
'totalRowCount'?: number;
'items'?: Array<WikiManualBookResponse>;
};
})
    );
  }

  /**
   * Path part for operation updateWikiModuleEditorContentData
   */
  static readonly UpdateWikiModuleEditorContentDataPath = '/v1/wikiManual/updateWikiModuleEditorContentData';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `updateWikiModuleEditorContentData()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  updateWikiModuleEditorContentData$Response(params: {
    body: InternalWikiUpdateResponseModel
  }): Observable<StrictHttpResponse<{
'isSuccess'?: boolean;
'message'?: string;
'data'?: string;
}>> {

    const rb = new RequestBuilder(this.rootUrl, ApiService.UpdateWikiModuleEditorContentDataPath, 'post');
    if (params) {
      rb.body(params.body, 'application/json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<{
        'isSuccess'?: boolean;
        'message'?: string;
        'data'?: string;
        }>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `updateWikiModuleEditorContentData$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  updateWikiModuleEditorContentData(params: {
    body: InternalWikiUpdateResponseModel
  }): Observable<{
'isSuccess'?: boolean;
'message'?: string;
'data'?: string;
}> {

    return this.updateWikiModuleEditorContentData$Response(params).pipe(
      map((r: StrictHttpResponse<{
'isSuccess'?: boolean;
'message'?: string;
'data'?: string;
}>) => r.body as {
'isSuccess'?: boolean;
'message'?: string;
'data'?: string;
})
    );
  }

  /**
   * Path part for operation addNewOrUpdateWikiModuleData
   */
  static readonly AddNewOrUpdateWikiModuleDataPath = '/v1/wikiManual/addNewOrUpdateWikiModuleData';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `addNewOrUpdateWikiModuleData()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  addNewOrUpdateWikiModuleData$Response(params: {
    body: UpdateInternalWikiModuleRequest
  }): Observable<StrictHttpResponse<{
'isSuccess'?: boolean;
'message'?: string;
'data'?: {
'pageNumber'?: number;
'pageSize'?: number;
'totalRowCount'?: number;
'items'?: Array<WikiManualBookResponse>;
};
}>> {

    const rb = new RequestBuilder(this.rootUrl, ApiService.AddNewOrUpdateWikiModuleDataPath, 'post');
    if (params) {
      rb.body(params.body, 'application/json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<{
        'isSuccess'?: boolean;
        'message'?: string;
        'data'?: {
        'pageNumber'?: number;
        'pageSize'?: number;
        'totalRowCount'?: number;
        'items'?: Array<WikiManualBookResponse>;
        };
        }>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `addNewOrUpdateWikiModuleData$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  addNewOrUpdateWikiModuleData(params: {
    body: UpdateInternalWikiModuleRequest
  }): Observable<{
'isSuccess'?: boolean;
'message'?: string;
'data'?: {
'pageNumber'?: number;
'pageSize'?: number;
'totalRowCount'?: number;
'items'?: Array<WikiManualBookResponse>;
};
}> {

    return this.addNewOrUpdateWikiModuleData$Response(params).pipe(
      map((r: StrictHttpResponse<{
'isSuccess'?: boolean;
'message'?: string;
'data'?: {
'pageNumber'?: number;
'pageSize'?: number;
'totalRowCount'?: number;
'items'?: Array<WikiManualBookResponse>;
};
}>) => r.body as {
'isSuccess'?: boolean;
'message'?: string;
'data'?: {
'pageNumber'?: number;
'pageSize'?: number;
'totalRowCount'?: number;
'items'?: Array<WikiManualBookResponse>;
};
})
    );
  }

  /**
   * Path part for operation getAllInternalWikiModuleSearchKeywordData
   */
  static readonly GetAllInternalWikiModuleSearchKeywordDataPath = '/v1/wikiManual/getAllInternalWikiModuleSearchKeywordData';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getAllInternalWikiModuleSearchKeywordData()` instead.
   *
   * This method doesn't expect any request body.
   */
  getAllInternalWikiModuleSearchKeywordData$Response(params?: {
  }): Observable<StrictHttpResponse<{
'isSuccess'?: boolean;
'message'?: string;
'data'?: {
'pageNumber'?: number;
'pageSize'?: number;
'totalRowCount'?: number;
'items'?: Array<WikiManualKeywordResponse>;
};
}>> {

    const rb = new RequestBuilder(this.rootUrl, ApiService.GetAllInternalWikiModuleSearchKeywordDataPath, 'post');
    if (params) {
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<{
        'isSuccess'?: boolean;
        'message'?: string;
        'data'?: {
        'pageNumber'?: number;
        'pageSize'?: number;
        'totalRowCount'?: number;
        'items'?: Array<WikiManualKeywordResponse>;
        };
        }>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `getAllInternalWikiModuleSearchKeywordData$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getAllInternalWikiModuleSearchKeywordData(params?: {
  }): Observable<{
'isSuccess'?: boolean;
'message'?: string;
'data'?: {
'pageNumber'?: number;
'pageSize'?: number;
'totalRowCount'?: number;
'items'?: Array<WikiManualKeywordResponse>;
};
}> {

    return this.getAllInternalWikiModuleSearchKeywordData$Response(params).pipe(
      map((r: StrictHttpResponse<{
'isSuccess'?: boolean;
'message'?: string;
'data'?: {
'pageNumber'?: number;
'pageSize'?: number;
'totalRowCount'?: number;
'items'?: Array<WikiManualKeywordResponse>;
};
}>) => r.body as {
'isSuccess'?: boolean;
'message'?: string;
'data'?: {
'pageNumber'?: number;
'pageSize'?: number;
'totalRowCount'?: number;
'items'?: Array<WikiManualKeywordResponse>;
};
})
    );
  }

  /**
   * Path part for operation deleteWikiModuleData
   */
  static readonly DeleteWikiModuleDataPath = '/v1/wikiManual/deleteWikiModuleData';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `deleteWikiModuleData()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  deleteWikiModuleData$Response(params: {
    body: UpdateInternalWikiModuleRequest
  }): Observable<StrictHttpResponse<{
'isSuccess'?: boolean;
'message'?: string;
'data'?: string;
}>> {

    const rb = new RequestBuilder(this.rootUrl, ApiService.DeleteWikiModuleDataPath, 'post');
    if (params) {
      rb.body(params.body, 'application/json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<{
        'isSuccess'?: boolean;
        'message'?: string;
        'data'?: string;
        }>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `deleteWikiModuleData$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  deleteWikiModuleData(params: {
    body: UpdateInternalWikiModuleRequest
  }): Observable<{
'isSuccess'?: boolean;
'message'?: string;
'data'?: string;
}> {

    return this.deleteWikiModuleData$Response(params).pipe(
      map((r: StrictHttpResponse<{
'isSuccess'?: boolean;
'message'?: string;
'data'?: string;
}>) => r.body as {
'isSuccess'?: boolean;
'message'?: string;
'data'?: string;
})
    );
  }

  /**
   * Path part for operation addOrUpdateWikiFavoriteMenu
   */
  static readonly AddOrUpdateWikiFavoriteMenuPath = '/v1/wikiManual/addOrUpdateWikiFavoriteMenu';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `addOrUpdateWikiFavoriteMenu()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  addOrUpdateWikiFavoriteMenu$Response(params: {
    body: InternalUserWikiFavoriteModel
  }): Observable<StrictHttpResponse<{
'isSuccess'?: boolean;
'message'?: string;
'data'?: string;
}>> {

    const rb = new RequestBuilder(this.rootUrl, ApiService.AddOrUpdateWikiFavoriteMenuPath, 'post');
    if (params) {
      rb.body(params.body, 'application/json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<{
        'isSuccess'?: boolean;
        'message'?: string;
        'data'?: string;
        }>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `addOrUpdateWikiFavoriteMenu$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  addOrUpdateWikiFavoriteMenu(params: {
    body: InternalUserWikiFavoriteModel
  }): Observable<{
'isSuccess'?: boolean;
'message'?: string;
'data'?: string;
}> {

    return this.addOrUpdateWikiFavoriteMenu$Response(params).pipe(
      map((r: StrictHttpResponse<{
'isSuccess'?: boolean;
'message'?: string;
'data'?: string;
}>) => r.body as {
'isSuccess'?: boolean;
'message'?: string;
'data'?: string;
})
    );
  }

  /**
   * Path part for operation getInternalWikiModuleContent
   */
  static readonly GetInternalWikiModuleContentPath = '/v1/wikiManual/getInternalWikiModuleContent';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getInternalWikiModuleContent()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  getInternalWikiModuleContent$Response(params: {
    body: FetchInternalWikiModuleRequest
  }): Observable<StrictHttpResponse<{
'isSuccess'?: boolean;
'message'?: string;
'data'?: WikiManualBookResponse;
}>> {

    const rb = new RequestBuilder(this.rootUrl, ApiService.GetInternalWikiModuleContentPath, 'post');
    if (params) {
      rb.body(params.body, 'application/json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<{
        'isSuccess'?: boolean;
        'message'?: string;
        'data'?: WikiManualBookResponse;
        }>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `getInternalWikiModuleContent$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  getInternalWikiModuleContent(params: {
    body: FetchInternalWikiModuleRequest
  }): Observable<{
'isSuccess'?: boolean;
'message'?: string;
'data'?: WikiManualBookResponse;
}> {

    return this.getInternalWikiModuleContent$Response(params).pipe(
      map((r: StrictHttpResponse<{
'isSuccess'?: boolean;
'message'?: string;
'data'?: WikiManualBookResponse;
}>) => r.body as {
'isSuccess'?: boolean;
'message'?: string;
'data'?: WikiManualBookResponse;
})
    );
  }

  /**
   * Path part for operation updateWikiFavorite
   */
  static readonly UpdateWikiFavoritePath = '/v1/wikiManual/updateWikiFavorite';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `updateWikiFavorite()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  updateWikiFavorite$Response(params: {
    body: UpdateFavoriteOrderForUserRequest
  }): Observable<StrictHttpResponse<{
'isSuccess'?: boolean;
'message'?: string;
'data'?: string;
}>> {

    const rb = new RequestBuilder(this.rootUrl, ApiService.UpdateWikiFavoritePath, 'post');
    if (params) {
      rb.body(params.body, 'application/json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<{
        'isSuccess'?: boolean;
        'message'?: string;
        'data'?: string;
        }>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `updateWikiFavorite$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  updateWikiFavorite(params: {
    body: UpdateFavoriteOrderForUserRequest
  }): Observable<{
'isSuccess'?: boolean;
'message'?: string;
'data'?: string;
}> {

    return this.updateWikiFavorite$Response(params).pipe(
      map((r: StrictHttpResponse<{
'isSuccess'?: boolean;
'message'?: string;
'data'?: string;
}>) => r.body as {
'isSuccess'?: boolean;
'message'?: string;
'data'?: string;
})
    );
  }

  /**
   * Path part for operation getInternalWikiBookLevelData
   */
  static readonly GetInternalWikiBookLevelDataPath = '/v1/wikiManual/getInternalWikiBookLevelData';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getInternalWikiBookLevelData()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  getInternalWikiBookLevelData$Response(params: {
    body: FetchInternalWikiModuleRequest
  }): Observable<StrictHttpResponse<{
'isSuccess'?: boolean;
'message'?: string;
'data'?: {
'pageNumber'?: number;
'pageSize'?: number;
'totalRowCount'?: number;
'items'?: Array<WikiManualBookResponse>;
};
}>> {

    const rb = new RequestBuilder(this.rootUrl, ApiService.GetInternalWikiBookLevelDataPath, 'post');
    if (params) {
      rb.body(params.body, 'application/json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<{
        'isSuccess'?: boolean;
        'message'?: string;
        'data'?: {
        'pageNumber'?: number;
        'pageSize'?: number;
        'totalRowCount'?: number;
        'items'?: Array<WikiManualBookResponse>;
        };
        }>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `getInternalWikiBookLevelData$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  getInternalWikiBookLevelData(params: {
    body: FetchInternalWikiModuleRequest
  }): Observable<{
'isSuccess'?: boolean;
'message'?: string;
'data'?: {
'pageNumber'?: number;
'pageSize'?: number;
'totalRowCount'?: number;
'items'?: Array<WikiManualBookResponse>;
};
}> {

    return this.getInternalWikiBookLevelData$Response(params).pipe(
      map((r: StrictHttpResponse<{
'isSuccess'?: boolean;
'message'?: string;
'data'?: {
'pageNumber'?: number;
'pageSize'?: number;
'totalRowCount'?: number;
'items'?: Array<WikiManualBookResponse>;
};
}>) => r.body as {
'isSuccess'?: boolean;
'message'?: string;
'data'?: {
'pageNumber'?: number;
'pageSize'?: number;
'totalRowCount'?: number;
'items'?: Array<WikiManualBookResponse>;
};
})
    );
  }

  /**
   * Path part for operation getInternalWikiModuleS3Video
   */
  static readonly GetInternalWikiModuleS3VideoPath = '/v1/wikiManual/getInternalWikiModuleS3Video';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getInternalWikiModuleS3Video()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  getInternalWikiModuleS3Video$Response(params: {
    body: FetchInternalWikiModuleRequest
  }): Observable<StrictHttpResponse<{
'isSuccess'?: boolean;
'message'?: string;
'data'?: {
'pageNumber'?: number;
'pageSize'?: number;
'totalRowCount'?: number;
'items'?: Array<InternalWikiModuleXs3Video>;
};
}>> {

    const rb = new RequestBuilder(this.rootUrl, ApiService.GetInternalWikiModuleS3VideoPath, 'post');
    if (params) {
      rb.body(params.body, 'application/json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<{
        'isSuccess'?: boolean;
        'message'?: string;
        'data'?: {
        'pageNumber'?: number;
        'pageSize'?: number;
        'totalRowCount'?: number;
        'items'?: Array<InternalWikiModuleXs3Video>;
        };
        }>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `getInternalWikiModuleS3Video$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  getInternalWikiModuleS3Video(params: {
    body: FetchInternalWikiModuleRequest
  }): Observable<{
'isSuccess'?: boolean;
'message'?: string;
'data'?: {
'pageNumber'?: number;
'pageSize'?: number;
'totalRowCount'?: number;
'items'?: Array<InternalWikiModuleXs3Video>;
};
}> {

    return this.getInternalWikiModuleS3Video$Response(params).pipe(
      map((r: StrictHttpResponse<{
'isSuccess'?: boolean;
'message'?: string;
'data'?: {
'pageNumber'?: number;
'pageSize'?: number;
'totalRowCount'?: number;
'items'?: Array<InternalWikiModuleXs3Video>;
};
}>) => r.body as {
'isSuccess'?: boolean;
'message'?: string;
'data'?: {
'pageNumber'?: number;
'pageSize'?: number;
'totalRowCount'?: number;
'items'?: Array<InternalWikiModuleXs3Video>;
};
})
    );
  }

  /**
   * Path part for operation getResourceCenterList
   */
  static readonly GetResourceCenterListPath = '/v1/wikiManual/list/getResourceCenterList';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getResourceCenterList()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  getResourceCenterList$Response(params: {
    body: EntityGetRequest
  }): Observable<StrictHttpResponse<{
'isSuccess'?: boolean;
'message'?: string;
'data'?: {
'pageNumber'?: number;
'pageSize'?: number;
'totalRowCount'?: number;
'items'?: Array<WikiManualBookResponse>;
};
}>> {

    const rb = new RequestBuilder(this.rootUrl, ApiService.GetResourceCenterListPath, 'post');
    if (params) {
      rb.body(params.body, 'application/json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<{
        'isSuccess'?: boolean;
        'message'?: string;
        'data'?: {
        'pageNumber'?: number;
        'pageSize'?: number;
        'totalRowCount'?: number;
        'items'?: Array<WikiManualBookResponse>;
        };
        }>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `getResourceCenterList$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  getResourceCenterList(params: {
    body: EntityGetRequest
  }): Observable<{
'isSuccess'?: boolean;
'message'?: string;
'data'?: {
'pageNumber'?: number;
'pageSize'?: number;
'totalRowCount'?: number;
'items'?: Array<WikiManualBookResponse>;
};
}> {

    return this.getResourceCenterList$Response(params).pipe(
      map((r: StrictHttpResponse<{
'isSuccess'?: boolean;
'message'?: string;
'data'?: {
'pageNumber'?: number;
'pageSize'?: number;
'totalRowCount'?: number;
'items'?: Array<WikiManualBookResponse>;
};
}>) => r.body as {
'isSuccess'?: boolean;
'message'?: string;
'data'?: {
'pageNumber'?: number;
'pageSize'?: number;
'totalRowCount'?: number;
'items'?: Array<WikiManualBookResponse>;
};
})
    );
  }

  /**
   * Path part for operation getWikiRolePermissionData
   */
  static readonly GetWikiRolePermissionDataPath = '/v1/wikiManual/getWikiRolePermissionData';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getWikiRolePermissionData()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  getWikiRolePermissionData$Response(params: {
    body: WikiPermissionGetRequest
  }): Observable<StrictHttpResponse<{
'isSuccess'?: boolean;
'message'?: string;
'data'?: {
'pageNumber'?: number;
'pageSize'?: number;
'totalRowCount'?: number;
'items'?: Array<WikiRoleXPermissionItem>;
};
}>> {

    const rb = new RequestBuilder(this.rootUrl, ApiService.GetWikiRolePermissionDataPath, 'post');
    if (params) {
      rb.body(params.body, 'application/json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<{
        'isSuccess'?: boolean;
        'message'?: string;
        'data'?: {
        'pageNumber'?: number;
        'pageSize'?: number;
        'totalRowCount'?: number;
        'items'?: Array<WikiRoleXPermissionItem>;
        };
        }>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `getWikiRolePermissionData$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  getWikiRolePermissionData(params: {
    body: WikiPermissionGetRequest
  }): Observable<{
'isSuccess'?: boolean;
'message'?: string;
'data'?: {
'pageNumber'?: number;
'pageSize'?: number;
'totalRowCount'?: number;
'items'?: Array<WikiRoleXPermissionItem>;
};
}> {

    return this.getWikiRolePermissionData$Response(params).pipe(
      map((r: StrictHttpResponse<{
'isSuccess'?: boolean;
'message'?: string;
'data'?: {
'pageNumber'?: number;
'pageSize'?: number;
'totalRowCount'?: number;
'items'?: Array<WikiRoleXPermissionItem>;
};
}>) => r.body as {
'isSuccess'?: boolean;
'message'?: string;
'data'?: {
'pageNumber'?: number;
'pageSize'?: number;
'totalRowCount'?: number;
'items'?: Array<WikiRoleXPermissionItem>;
};
})
    );
  }

  /**
   * Path part for operation addNewWikiPermissionData
   */
  static readonly AddNewWikiPermissionDataPath = '/v1/wikiManual/addNewWikiPermissionData';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `addNewWikiPermissionData()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  addNewWikiPermissionData$Response(params: {
    body: WikiPermissionRequest
  }): Observable<StrictHttpResponse<{
'isSuccess'?: boolean;
'message'?: string;
'data'?: {
'pageNumber'?: number;
'pageSize'?: number;
'totalRowCount'?: number;
'items'?: Array<WikiManualBookResponse>;
};
}>> {

    const rb = new RequestBuilder(this.rootUrl, ApiService.AddNewWikiPermissionDataPath, 'post');
    if (params) {
      rb.body(params.body, 'application/json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<{
        'isSuccess'?: boolean;
        'message'?: string;
        'data'?: {
        'pageNumber'?: number;
        'pageSize'?: number;
        'totalRowCount'?: number;
        'items'?: Array<WikiManualBookResponse>;
        };
        }>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `addNewWikiPermissionData$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  addNewWikiPermissionData(params: {
    body: WikiPermissionRequest
  }): Observable<{
'isSuccess'?: boolean;
'message'?: string;
'data'?: {
'pageNumber'?: number;
'pageSize'?: number;
'totalRowCount'?: number;
'items'?: Array<WikiManualBookResponse>;
};
}> {

    return this.addNewWikiPermissionData$Response(params).pipe(
      map((r: StrictHttpResponse<{
'isSuccess'?: boolean;
'message'?: string;
'data'?: {
'pageNumber'?: number;
'pageSize'?: number;
'totalRowCount'?: number;
'items'?: Array<WikiManualBookResponse>;
};
}>) => r.body as {
'isSuccess'?: boolean;
'message'?: string;
'data'?: {
'pageNumber'?: number;
'pageSize'?: number;
'totalRowCount'?: number;
'items'?: Array<WikiManualBookResponse>;
};
})
    );
  }

  /**
   * Path part for operation getInternalPermissionType
   */
  static readonly GetInternalPermissionTypePath = '/v1/wikiManual/getInternalPermissionType';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getInternalPermissionType()` instead.
   *
   * This method doesn't expect any request body.
   */
  getInternalPermissionType$Response(params?: {
  }): Observable<StrictHttpResponse<{
'isSuccess'?: boolean;
'message'?: string;
'data'?: {
'pageNumber'?: number;
'pageSize'?: number;
'totalRowCount'?: number;
'items'?: Array<InternalPermissionTypeItem>;
};
}>> {

    const rb = new RequestBuilder(this.rootUrl, ApiService.GetInternalPermissionTypePath, 'post');
    if (params) {
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<{
        'isSuccess'?: boolean;
        'message'?: string;
        'data'?: {
        'pageNumber'?: number;
        'pageSize'?: number;
        'totalRowCount'?: number;
        'items'?: Array<InternalPermissionTypeItem>;
        };
        }>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `getInternalPermissionType$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getInternalPermissionType(params?: {
  }): Observable<{
'isSuccess'?: boolean;
'message'?: string;
'data'?: {
'pageNumber'?: number;
'pageSize'?: number;
'totalRowCount'?: number;
'items'?: Array<InternalPermissionTypeItem>;
};
}> {

    return this.getInternalPermissionType$Response(params).pipe(
      map((r: StrictHttpResponse<{
'isSuccess'?: boolean;
'message'?: string;
'data'?: {
'pageNumber'?: number;
'pageSize'?: number;
'totalRowCount'?: number;
'items'?: Array<InternalPermissionTypeItem>;
};
}>) => r.body as {
'isSuccess'?: boolean;
'message'?: string;
'data'?: {
'pageNumber'?: number;
'pageSize'?: number;
'totalRowCount'?: number;
'items'?: Array<InternalPermissionTypeItem>;
};
})
    );
  }

  /**
   * Path part for operation getLinkedWikiModuleList
   */
  static readonly GetLinkedWikiModuleListPath = '/v1/wikiManual/getLinkedWikiModuleList';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getLinkedWikiModuleList()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  getLinkedWikiModuleList$Response(params: {
    body: FetchInternalWikiModuleRequest
  }): Observable<StrictHttpResponse<{
'isSuccess'?: boolean;
'message'?: string;
'data'?: {
'pageNumber'?: number;
'pageSize'?: number;
'totalRowCount'?: number;
'items'?: Array<WikiManualBookResponse>;
};
}>> {

    const rb = new RequestBuilder(this.rootUrl, ApiService.GetLinkedWikiModuleListPath, 'post');
    if (params) {
      rb.body(params.body, 'application/json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<{
        'isSuccess'?: boolean;
        'message'?: string;
        'data'?: {
        'pageNumber'?: number;
        'pageSize'?: number;
        'totalRowCount'?: number;
        'items'?: Array<WikiManualBookResponse>;
        };
        }>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `getLinkedWikiModuleList$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  getLinkedWikiModuleList(params: {
    body: FetchInternalWikiModuleRequest
  }): Observable<{
'isSuccess'?: boolean;
'message'?: string;
'data'?: {
'pageNumber'?: number;
'pageSize'?: number;
'totalRowCount'?: number;
'items'?: Array<WikiManualBookResponse>;
};
}> {

    return this.getLinkedWikiModuleList$Response(params).pipe(
      map((r: StrictHttpResponse<{
'isSuccess'?: boolean;
'message'?: string;
'data'?: {
'pageNumber'?: number;
'pageSize'?: number;
'totalRowCount'?: number;
'items'?: Array<WikiManualBookResponse>;
};
}>) => r.body as {
'isSuccess'?: boolean;
'message'?: string;
'data'?: {
'pageNumber'?: number;
'pageSize'?: number;
'totalRowCount'?: number;
'items'?: Array<WikiManualBookResponse>;
};
})
    );
  }

  /**
   * Path part for operation getInternalToDoEventData
   */
  static readonly GetInternalToDoEventDataPath = '/v1/todo/getInternalToDoEventData';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getInternalToDoEventData()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  getInternalToDoEventData$Response(params: {
    body: FetchToDoRequest
  }): Observable<StrictHttpResponse<{
'isSuccess'?: boolean;
'message'?: string;
'data'?: {
'pageNumber'?: number;
'pageSize'?: number;
'totalRowCount'?: number;
'items'?: Array<InternalToDoEventModel>;
};
}>> {

    const rb = new RequestBuilder(this.rootUrl, ApiService.GetInternalToDoEventDataPath, 'post');
    if (params) {
      rb.body(params.body, 'application/json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<{
        'isSuccess'?: boolean;
        'message'?: string;
        'data'?: {
        'pageNumber'?: number;
        'pageSize'?: number;
        'totalRowCount'?: number;
        'items'?: Array<InternalToDoEventModel>;
        };
        }>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `getInternalToDoEventData$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  getInternalToDoEventData(params: {
    body: FetchToDoRequest
  }): Observable<{
'isSuccess'?: boolean;
'message'?: string;
'data'?: {
'pageNumber'?: number;
'pageSize'?: number;
'totalRowCount'?: number;
'items'?: Array<InternalToDoEventModel>;
};
}> {

    return this.getInternalToDoEventData$Response(params).pipe(
      map((r: StrictHttpResponse<{
'isSuccess'?: boolean;
'message'?: string;
'data'?: {
'pageNumber'?: number;
'pageSize'?: number;
'totalRowCount'?: number;
'items'?: Array<InternalToDoEventModel>;
};
}>) => r.body as {
'isSuccess'?: boolean;
'message'?: string;
'data'?: {
'pageNumber'?: number;
'pageSize'?: number;
'totalRowCount'?: number;
'items'?: Array<InternalToDoEventModel>;
};
})
    );
  }

  /**
   * Path part for operation getAllToDoStatus
   */
  static readonly GetAllToDoStatusPath = '/v1/todo/getAllToDoStatus';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getAllToDoStatus()` instead.
   *
   * This method doesn't expect any request body.
   */
  getAllToDoStatus$Response(params?: {
  }): Observable<StrictHttpResponse<{
'isSuccess'?: boolean;
'message'?: string;
'data'?: {
'pageNumber'?: number;
'pageSize'?: number;
'totalRowCount'?: number;
'items'?: Array<InternalToDoStatusModel>;
};
}>> {

    const rb = new RequestBuilder(this.rootUrl, ApiService.GetAllToDoStatusPath, 'post');
    if (params) {
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<{
        'isSuccess'?: boolean;
        'message'?: string;
        'data'?: {
        'pageNumber'?: number;
        'pageSize'?: number;
        'totalRowCount'?: number;
        'items'?: Array<InternalToDoStatusModel>;
        };
        }>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `getAllToDoStatus$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getAllToDoStatus(params?: {
  }): Observable<{
'isSuccess'?: boolean;
'message'?: string;
'data'?: {
'pageNumber'?: number;
'pageSize'?: number;
'totalRowCount'?: number;
'items'?: Array<InternalToDoStatusModel>;
};
}> {

    return this.getAllToDoStatus$Response(params).pipe(
      map((r: StrictHttpResponse<{
'isSuccess'?: boolean;
'message'?: string;
'data'?: {
'pageNumber'?: number;
'pageSize'?: number;
'totalRowCount'?: number;
'items'?: Array<InternalToDoStatusModel>;
};
}>) => r.body as {
'isSuccess'?: boolean;
'message'?: string;
'data'?: {
'pageNumber'?: number;
'pageSize'?: number;
'totalRowCount'?: number;
'items'?: Array<InternalToDoStatusModel>;
};
})
    );
  }

  /**
   * Path part for operation updateToDoEventData
   */
  static readonly UpdateToDoEventDataPath = '/v1/todo/updateToDoEventData';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `updateToDoEventData()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  updateToDoEventData$Response(params: {
    body: InternalToDoEventModel
  }): Observable<StrictHttpResponse<{
'isSuccess'?: boolean;
'message'?: string;
'data'?: string;
}>> {

    const rb = new RequestBuilder(this.rootUrl, ApiService.UpdateToDoEventDataPath, 'post');
    if (params) {
      rb.body(params.body, 'application/json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<{
        'isSuccess'?: boolean;
        'message'?: string;
        'data'?: string;
        }>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `updateToDoEventData$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  updateToDoEventData(params: {
    body: InternalToDoEventModel
  }): Observable<{
'isSuccess'?: boolean;
'message'?: string;
'data'?: string;
}> {

    return this.updateToDoEventData$Response(params).pipe(
      map((r: StrictHttpResponse<{
'isSuccess'?: boolean;
'message'?: string;
'data'?: string;
}>) => r.body as {
'isSuccess'?: boolean;
'message'?: string;
'data'?: string;
})
    );
  }

  /**
   * Path part for operation getAllToDoEventTypes
   */
  static readonly GetAllToDoEventTypesPath = '/v1/todo/getAllToDoEventTypes';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getAllToDoEventTypes()` instead.
   *
   * This method doesn't expect any request body.
   */
  getAllToDoEventTypes$Response(params?: {
  }): Observable<StrictHttpResponse<{
'isSuccess'?: boolean;
'message'?: string;
'data'?: {
'pageNumber'?: number;
'pageSize'?: number;
'totalRowCount'?: number;
'items'?: Array<InternalToDoEventTypeModel>;
};
}>> {

    const rb = new RequestBuilder(this.rootUrl, ApiService.GetAllToDoEventTypesPath, 'post');
    if (params) {
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<{
        'isSuccess'?: boolean;
        'message'?: string;
        'data'?: {
        'pageNumber'?: number;
        'pageSize'?: number;
        'totalRowCount'?: number;
        'items'?: Array<InternalToDoEventTypeModel>;
        };
        }>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `getAllToDoEventTypes$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getAllToDoEventTypes(params?: {
  }): Observable<{
'isSuccess'?: boolean;
'message'?: string;
'data'?: {
'pageNumber'?: number;
'pageSize'?: number;
'totalRowCount'?: number;
'items'?: Array<InternalToDoEventTypeModel>;
};
}> {

    return this.getAllToDoEventTypes$Response(params).pipe(
      map((r: StrictHttpResponse<{
'isSuccess'?: boolean;
'message'?: string;
'data'?: {
'pageNumber'?: number;
'pageSize'?: number;
'totalRowCount'?: number;
'items'?: Array<InternalToDoEventTypeModel>;
};
}>) => r.body as {
'isSuccess'?: boolean;
'message'?: string;
'data'?: {
'pageNumber'?: number;
'pageSize'?: number;
'totalRowCount'?: number;
'items'?: Array<InternalToDoEventTypeModel>;
};
})
    );
  }

  /**
   * Path part for operation getToDoDetails
   */
  static readonly GetToDoDetailsPath = '/v1/todo/getToDoDetails';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getToDoDetails()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  getToDoDetails$Response(params: {
    body: FetchToDoEventRequest
  }): Observable<StrictHttpResponse<{
'isSuccess'?: boolean;
'message'?: string;
'data'?: {
'pageNumber'?: number;
'pageSize'?: number;
'totalRowCount'?: number;
'items'?: Array<InternalToDoEvent>;
};
}>> {

    const rb = new RequestBuilder(this.rootUrl, ApiService.GetToDoDetailsPath, 'post');
    if (params) {
      rb.body(params.body, 'application/json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<{
        'isSuccess'?: boolean;
        'message'?: string;
        'data'?: {
        'pageNumber'?: number;
        'pageSize'?: number;
        'totalRowCount'?: number;
        'items'?: Array<InternalToDoEvent>;
        };
        }>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `getToDoDetails$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  getToDoDetails(params: {
    body: FetchToDoEventRequest
  }): Observable<{
'isSuccess'?: boolean;
'message'?: string;
'data'?: {
'pageNumber'?: number;
'pageSize'?: number;
'totalRowCount'?: number;
'items'?: Array<InternalToDoEvent>;
};
}> {

    return this.getToDoDetails$Response(params).pipe(
      map((r: StrictHttpResponse<{
'isSuccess'?: boolean;
'message'?: string;
'data'?: {
'pageNumber'?: number;
'pageSize'?: number;
'totalRowCount'?: number;
'items'?: Array<InternalToDoEvent>;
};
}>) => r.body as {
'isSuccess'?: boolean;
'message'?: string;
'data'?: {
'pageNumber'?: number;
'pageSize'?: number;
'totalRowCount'?: number;
'items'?: Array<InternalToDoEvent>;
};
})
    );
  }

  /**
   * Path part for operation toDoEventAdd
   */
  static readonly ToDoEventAddPath = '/v1/todo/add/toDoEvent';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `toDoEventAdd()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  toDoEventAdd$Response(params: {
    body: ToDoEventRequest
  }): Observable<StrictHttpResponse<{
'isSuccess'?: boolean;
'message'?: string;
'data'?: string;
}>> {

    const rb = new RequestBuilder(this.rootUrl, ApiService.ToDoEventAddPath, 'post');
    if (params) {
      rb.body(params.body, 'application/json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<{
        'isSuccess'?: boolean;
        'message'?: string;
        'data'?: string;
        }>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `toDoEventAdd$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  toDoEventAdd(params: {
    body: ToDoEventRequest
  }): Observable<{
'isSuccess'?: boolean;
'message'?: string;
'data'?: string;
}> {

    return this.toDoEventAdd$Response(params).pipe(
      map((r: StrictHttpResponse<{
'isSuccess'?: boolean;
'message'?: string;
'data'?: string;
}>) => r.body as {
'isSuccess'?: boolean;
'message'?: string;
'data'?: string;
})
    );
  }

  /**
   * Path part for operation addTodoSnoozeHistory
   */
  static readonly AddTodoSnoozeHistoryPath = '/v1/todo/addTodoSnoozeHistory';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `addTodoSnoozeHistory()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  addTodoSnoozeHistory$Response(params: {
    body: ToDoEventSnoozeHistory
  }): Observable<StrictHttpResponse<{
'isSuccess'?: boolean;
'message'?: string;
'data'?: string;
}>> {

    const rb = new RequestBuilder(this.rootUrl, ApiService.AddTodoSnoozeHistoryPath, 'post');
    if (params) {
      rb.body(params.body, 'application/json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<{
        'isSuccess'?: boolean;
        'message'?: string;
        'data'?: string;
        }>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `addTodoSnoozeHistory$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  addTodoSnoozeHistory(params: {
    body: ToDoEventSnoozeHistory
  }): Observable<{
'isSuccess'?: boolean;
'message'?: string;
'data'?: string;
}> {

    return this.addTodoSnoozeHistory$Response(params).pipe(
      map((r: StrictHttpResponse<{
'isSuccess'?: boolean;
'message'?: string;
'data'?: string;
}>) => r.body as {
'isSuccess'?: boolean;
'message'?: string;
'data'?: string;
})
    );
  }

  /**
   * Path part for operation todoTemplateList
   */
  static readonly TodoTemplateListPath = '/v1/todo/list/todoTemplate';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `todoTemplateList()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  todoTemplateList$Response(params: {
    body: EntityGetRequest
  }): Observable<StrictHttpResponse<{
'isSuccess'?: boolean;
'message'?: string;
'data'?: {
'pageNumber'?: number;
'pageSize'?: number;
'totalRowCount'?: number;
'items'?: Array<TodoTemplate>;
};
}>> {

    const rb = new RequestBuilder(this.rootUrl, ApiService.TodoTemplateListPath, 'post');
    if (params) {
      rb.body(params.body, 'application/json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<{
        'isSuccess'?: boolean;
        'message'?: string;
        'data'?: {
        'pageNumber'?: number;
        'pageSize'?: number;
        'totalRowCount'?: number;
        'items'?: Array<TodoTemplate>;
        };
        }>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `todoTemplateList$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  todoTemplateList(params: {
    body: EntityGetRequest
  }): Observable<{
'isSuccess'?: boolean;
'message'?: string;
'data'?: {
'pageNumber'?: number;
'pageSize'?: number;
'totalRowCount'?: number;
'items'?: Array<TodoTemplate>;
};
}> {

    return this.todoTemplateList$Response(params).pipe(
      map((r: StrictHttpResponse<{
'isSuccess'?: boolean;
'message'?: string;
'data'?: {
'pageNumber'?: number;
'pageSize'?: number;
'totalRowCount'?: number;
'items'?: Array<TodoTemplate>;
};
}>) => r.body as {
'isSuccess'?: boolean;
'message'?: string;
'data'?: {
'pageNumber'?: number;
'pageSize'?: number;
'totalRowCount'?: number;
'items'?: Array<TodoTemplate>;
};
})
    );
  }

  /**
   * Path part for operation todoTemplateAddOrUpdate
   */
  static readonly TodoTemplateAddOrUpdatePath = '/v1/todo/addOrUpdate/todoTemplate';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `todoTemplateAddOrUpdate()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  todoTemplateAddOrUpdate$Response(params: {
    body: TodoTemplate
  }): Observable<StrictHttpResponse<{
'isSuccess'?: boolean;
'message'?: string;
'data'?: string;
}>> {

    const rb = new RequestBuilder(this.rootUrl, ApiService.TodoTemplateAddOrUpdatePath, 'post');
    if (params) {
      rb.body(params.body, 'application/json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<{
        'isSuccess'?: boolean;
        'message'?: string;
        'data'?: string;
        }>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `todoTemplateAddOrUpdate$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  todoTemplateAddOrUpdate(params: {
    body: TodoTemplate
  }): Observable<{
'isSuccess'?: boolean;
'message'?: string;
'data'?: string;
}> {

    return this.todoTemplateAddOrUpdate$Response(params).pipe(
      map((r: StrictHttpResponse<{
'isSuccess'?: boolean;
'message'?: string;
'data'?: string;
}>) => r.body as {
'isSuccess'?: boolean;
'message'?: string;
'data'?: string;
})
    );
  }

  /**
   * Path part for operation todoTemplateDelete
   */
  static readonly TodoTemplateDeletePath = '/v1/todo/delete/todoTemplate';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `todoTemplateDelete()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  todoTemplateDelete$Response(params: {
    body: DeleteRequest
  }): Observable<StrictHttpResponse<{
'isSuccess'?: boolean;
'message'?: string;
'data'?: string;
}>> {

    const rb = new RequestBuilder(this.rootUrl, ApiService.TodoTemplateDeletePath, 'delete');
    if (params) {
      rb.body(params.body, 'application/json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<{
        'isSuccess'?: boolean;
        'message'?: string;
        'data'?: string;
        }>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `todoTemplateDelete$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  todoTemplateDelete(params: {
    body: DeleteRequest
  }): Observable<{
'isSuccess'?: boolean;
'message'?: string;
'data'?: string;
}> {

    return this.todoTemplateDelete$Response(params).pipe(
      map((r: StrictHttpResponse<{
'isSuccess'?: boolean;
'message'?: string;
'data'?: string;
}>) => r.body as {
'isSuccess'?: boolean;
'message'?: string;
'data'?: string;
})
    );
  }

  /**
   * Path part for operation getSession
   */
  static readonly GetSessionPath = '/v1/session/getSession';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getSession()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  getSession$Response(params: {
    body: GetSessionRequest
  }): Observable<StrictHttpResponse<{
'isSuccess'?: boolean;
'message'?: string;
'data'?: string;
}>> {

    const rb = new RequestBuilder(this.rootUrl, ApiService.GetSessionPath, 'post');
    if (params) {
      rb.body(params.body, 'application/json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<{
        'isSuccess'?: boolean;
        'message'?: string;
        'data'?: string;
        }>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `getSession$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  getSession(params: {
    body: GetSessionRequest
  }): Observable<{
'isSuccess'?: boolean;
'message'?: string;
'data'?: string;
}> {

    return this.getSession$Response(params).pipe(
      map((r: StrictHttpResponse<{
'isSuccess'?: boolean;
'message'?: string;
'data'?: string;
}>) => r.body as {
'isSuccess'?: boolean;
'message'?: string;
'data'?: string;
})
    );
  }

  /**
   * Path part for operation listWorkLocations
   */
  static readonly ListWorkLocationsPath = '/v1/workLocations/listWorkLocations';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `listWorkLocations()` instead.
   *
   * This method doesn't expect any request body.
   */
  listWorkLocations$Response(params?: {
  }): Observable<StrictHttpResponse<{
'isSuccess'?: boolean;
'message'?: string;
'data'?: {
'pageNumber'?: number;
'pageSize'?: number;
'totalRowCount'?: number;
'items'?: Array<WorkLocationBranch>;
};
}>> {

    const rb = new RequestBuilder(this.rootUrl, ApiService.ListWorkLocationsPath, 'post');
    if (params) {
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<{
        'isSuccess'?: boolean;
        'message'?: string;
        'data'?: {
        'pageNumber'?: number;
        'pageSize'?: number;
        'totalRowCount'?: number;
        'items'?: Array<WorkLocationBranch>;
        };
        }>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `listWorkLocations$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  listWorkLocations(params?: {
  }): Observable<{
'isSuccess'?: boolean;
'message'?: string;
'data'?: {
'pageNumber'?: number;
'pageSize'?: number;
'totalRowCount'?: number;
'items'?: Array<WorkLocationBranch>;
};
}> {

    return this.listWorkLocations$Response(params).pipe(
      map((r: StrictHttpResponse<{
'isSuccess'?: boolean;
'message'?: string;
'data'?: {
'pageNumber'?: number;
'pageSize'?: number;
'totalRowCount'?: number;
'items'?: Array<WorkLocationBranch>;
};
}>) => r.body as {
'isSuccess'?: boolean;
'message'?: string;
'data'?: {
'pageNumber'?: number;
'pageSize'?: number;
'totalRowCount'?: number;
'items'?: Array<WorkLocationBranch>;
};
})
    );
  }

  /**
   * Path part for operation updateWorkLocation
   */
  static readonly UpdateWorkLocationPath = '/v1/workLocations/updateWorkLocation';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `updateWorkLocation()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  updateWorkLocation$Response(params: {
    body: UpdateWorkLocationRequest
  }): Observable<StrictHttpResponse<{
'isSuccess'?: boolean;
'message'?: string;
'data'?: string;
}>> {

    const rb = new RequestBuilder(this.rootUrl, ApiService.UpdateWorkLocationPath, 'post');
    if (params) {
      rb.body(params.body, 'application/json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<{
        'isSuccess'?: boolean;
        'message'?: string;
        'data'?: string;
        }>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `updateWorkLocation$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  updateWorkLocation(params: {
    body: UpdateWorkLocationRequest
  }): Observable<{
'isSuccess'?: boolean;
'message'?: string;
'data'?: string;
}> {

    return this.updateWorkLocation$Response(params).pipe(
      map((r: StrictHttpResponse<{
'isSuccess'?: boolean;
'message'?: string;
'data'?: string;
}>) => r.body as {
'isSuccess'?: boolean;
'message'?: string;
'data'?: string;
})
    );
  }

  /**
   * Path part for operation deleteBranch
   */
  static readonly DeleteBranchPath = '/v1/workLocations/deleteBranch';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `deleteBranch()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  deleteBranch$Response(params: {
    body: UpdateWorkLocationRequest
  }): Observable<StrictHttpResponse<{
'isSuccess'?: boolean;
'message'?: string;
'data'?: string;
}>> {

    const rb = new RequestBuilder(this.rootUrl, ApiService.DeleteBranchPath, 'post');
    if (params) {
      rb.body(params.body, 'application/json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<{
        'isSuccess'?: boolean;
        'message'?: string;
        'data'?: string;
        }>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `deleteBranch$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  deleteBranch(params: {
    body: UpdateWorkLocationRequest
  }): Observable<{
'isSuccess'?: boolean;
'message'?: string;
'data'?: string;
}> {

    return this.deleteBranch$Response(params).pipe(
      map((r: StrictHttpResponse<{
'isSuccess'?: boolean;
'message'?: string;
'data'?: string;
}>) => r.body as {
'isSuccess'?: boolean;
'message'?: string;
'data'?: string;
})
    );
  }

  /**
   * Path part for operation listMloRecords
   */
  static readonly ListMloRecordsPath = '/v1/reports/list/MLORecords';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `listMloRecords()` instead.
   *
   * This method doesn't expect any request body.
   */
  listMloRecords$Response(params?: {
  }): Observable<StrictHttpResponse<{
'isSuccess'?: boolean;
'message'?: string;
'data'?: {
'pageNumber'?: number;
'pageSize'?: number;
'totalRowCount'?: number;
'items'?: Array<MloRecord>;
};
}>> {

    const rb = new RequestBuilder(this.rootUrl, ApiService.ListMloRecordsPath, 'post');
    if (params) {
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<{
        'isSuccess'?: boolean;
        'message'?: string;
        'data'?: {
        'pageNumber'?: number;
        'pageSize'?: number;
        'totalRowCount'?: number;
        'items'?: Array<MloRecord>;
        };
        }>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `listMloRecords$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  listMloRecords(params?: {
  }): Observable<{
'isSuccess'?: boolean;
'message'?: string;
'data'?: {
'pageNumber'?: number;
'pageSize'?: number;
'totalRowCount'?: number;
'items'?: Array<MloRecord>;
};
}> {

    return this.listMloRecords$Response(params).pipe(
      map((r: StrictHttpResponse<{
'isSuccess'?: boolean;
'message'?: string;
'data'?: {
'pageNumber'?: number;
'pageSize'?: number;
'totalRowCount'?: number;
'items'?: Array<MloRecord>;
};
}>) => r.body as {
'isSuccess'?: boolean;
'message'?: string;
'data'?: {
'pageNumber'?: number;
'pageSize'?: number;
'totalRowCount'?: number;
'items'?: Array<MloRecord>;
};
})
    );
  }

  /**
   * Path part for operation listDashboardMenuReport
   */
  static readonly ListDashboardMenuReportPath = '/v1/reports/list/Dashboard';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `listDashboardMenuReport()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  listDashboardMenuReport$Response(params: {
    body: DashboardMenuReportRequest
  }): Observable<StrictHttpResponse<{
'isSuccess'?: boolean;
'message'?: string;
'data'?: {
'pageNumber'?: number;
'pageSize'?: number;
'totalRowCount'?: number;
'items'?: Array<DashboardMenuReportOption>;
};
}>> {

    const rb = new RequestBuilder(this.rootUrl, ApiService.ListDashboardMenuReportPath, 'post');
    if (params) {
      rb.body(params.body, 'application/json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<{
        'isSuccess'?: boolean;
        'message'?: string;
        'data'?: {
        'pageNumber'?: number;
        'pageSize'?: number;
        'totalRowCount'?: number;
        'items'?: Array<DashboardMenuReportOption>;
        };
        }>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `listDashboardMenuReport$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  listDashboardMenuReport(params: {
    body: DashboardMenuReportRequest
  }): Observable<{
'isSuccess'?: boolean;
'message'?: string;
'data'?: {
'pageNumber'?: number;
'pageSize'?: number;
'totalRowCount'?: number;
'items'?: Array<DashboardMenuReportOption>;
};
}> {

    return this.listDashboardMenuReport$Response(params).pipe(
      map((r: StrictHttpResponse<{
'isSuccess'?: boolean;
'message'?: string;
'data'?: {
'pageNumber'?: number;
'pageSize'?: number;
'totalRowCount'?: number;
'items'?: Array<DashboardMenuReportOption>;
};
}>) => r.body as {
'isSuccess'?: boolean;
'message'?: string;
'data'?: {
'pageNumber'?: number;
'pageSize'?: number;
'totalRowCount'?: number;
'items'?: Array<DashboardMenuReportOption>;
};
})
    );
  }

  /**
   * Path part for operation listDashboards
   */
  static readonly ListDashboardsPath = '/v1/reports/list/dashboards';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `listDashboards()` instead.
   *
   * This method doesn't expect any request body.
   */
  listDashboards$Response(params?: {
  }): Observable<StrictHttpResponse<{
'isSuccess'?: boolean;
'message'?: string;
'data'?: {
'pageNumber'?: number;
'pageSize'?: number;
'totalRowCount'?: number;
'items'?: Array<DashboardModel>;
};
}>> {

    const rb = new RequestBuilder(this.rootUrl, ApiService.ListDashboardsPath, 'post');
    if (params) {
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<{
        'isSuccess'?: boolean;
        'message'?: string;
        'data'?: {
        'pageNumber'?: number;
        'pageSize'?: number;
        'totalRowCount'?: number;
        'items'?: Array<DashboardModel>;
        };
        }>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `listDashboards$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  listDashboards(params?: {
  }): Observable<{
'isSuccess'?: boolean;
'message'?: string;
'data'?: {
'pageNumber'?: number;
'pageSize'?: number;
'totalRowCount'?: number;
'items'?: Array<DashboardModel>;
};
}> {

    return this.listDashboards$Response(params).pipe(
      map((r: StrictHttpResponse<{
'isSuccess'?: boolean;
'message'?: string;
'data'?: {
'pageNumber'?: number;
'pageSize'?: number;
'totalRowCount'?: number;
'items'?: Array<DashboardModel>;
};
}>) => r.body as {
'isSuccess'?: boolean;
'message'?: string;
'data'?: {
'pageNumber'?: number;
'pageSize'?: number;
'totalRowCount'?: number;
'items'?: Array<DashboardModel>;
};
})
    );
  }

  /**
   * Path part for operation singleDashboard
   */
  static readonly SingleDashboardPath = '/v1/reports/dashboard/{dashboardGUID}';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `singleDashboard()` instead.
   *
   * This method doesn't expect any request body.
   */
  singleDashboard$Response(params: {
    dashboardGUID: string;
  }): Observable<StrictHttpResponse<{
'isSuccess'?: boolean;
'message'?: string;
'data'?: {
'pageNumber'?: number;
'pageSize'?: number;
'totalRowCount'?: number;
'items'?: Array<DashboardUiModel>;
};
}>> {

    const rb = new RequestBuilder(this.rootUrl, ApiService.SingleDashboardPath, 'get');
    if (params) {
      rb.path('dashboardGUID', params.dashboardGUID, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<{
        'isSuccess'?: boolean;
        'message'?: string;
        'data'?: {
        'pageNumber'?: number;
        'pageSize'?: number;
        'totalRowCount'?: number;
        'items'?: Array<DashboardUiModel>;
        };
        }>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `singleDashboard$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  singleDashboard(params: {
    dashboardGUID: string;
  }): Observable<{
'isSuccess'?: boolean;
'message'?: string;
'data'?: {
'pageNumber'?: number;
'pageSize'?: number;
'totalRowCount'?: number;
'items'?: Array<DashboardUiModel>;
};
}> {

    return this.singleDashboard$Response(params).pipe(
      map((r: StrictHttpResponse<{
'isSuccess'?: boolean;
'message'?: string;
'data'?: {
'pageNumber'?: number;
'pageSize'?: number;
'totalRowCount'?: number;
'items'?: Array<DashboardUiModel>;
};
}>) => r.body as {
'isSuccess'?: boolean;
'message'?: string;
'data'?: {
'pageNumber'?: number;
'pageSize'?: number;
'totalRowCount'?: number;
'items'?: Array<DashboardUiModel>;
};
})
    );
  }

  /**
   * Path part for operation linkedDashboardMenu
   */
  static readonly LinkedDashboardMenuPath = '/v1/reports/linkedDashboard/{dashboardGUID}';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `linkedDashboardMenu()` instead.
   *
   * This method doesn't expect any request body.
   */
  linkedDashboardMenu$Response(params: {
    dashboardGUID: string;
  }): Observable<StrictHttpResponse<{
'isSuccess'?: boolean;
'message'?: string;
'data'?: {
'pageNumber'?: number;
'pageSize'?: number;
'totalRowCount'?: number;
'items'?: Array<DashboardLinkedMenu>;
};
}>> {

    const rb = new RequestBuilder(this.rootUrl, ApiService.LinkedDashboardMenuPath, 'get');
    if (params) {
      rb.path('dashboardGUID', params.dashboardGUID, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<{
        'isSuccess'?: boolean;
        'message'?: string;
        'data'?: {
        'pageNumber'?: number;
        'pageSize'?: number;
        'totalRowCount'?: number;
        'items'?: Array<DashboardLinkedMenu>;
        };
        }>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `linkedDashboardMenu$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  linkedDashboardMenu(params: {
    dashboardGUID: string;
  }): Observable<{
'isSuccess'?: boolean;
'message'?: string;
'data'?: {
'pageNumber'?: number;
'pageSize'?: number;
'totalRowCount'?: number;
'items'?: Array<DashboardLinkedMenu>;
};
}> {

    return this.linkedDashboardMenu$Response(params).pipe(
      map((r: StrictHttpResponse<{
'isSuccess'?: boolean;
'message'?: string;
'data'?: {
'pageNumber'?: number;
'pageSize'?: number;
'totalRowCount'?: number;
'items'?: Array<DashboardLinkedMenu>;
};
}>) => r.body as {
'isSuccess'?: boolean;
'message'?: string;
'data'?: {
'pageNumber'?: number;
'pageSize'?: number;
'totalRowCount'?: number;
'items'?: Array<DashboardLinkedMenu>;
};
})
    );
  }

  /**
   * Path part for operation copyDashboardMetadata
   */
  static readonly CopyDashboardMetadataPath = '/v1/reports/copy/dashboard/{dashboardGUID}';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `copyDashboardMetadata()` instead.
   *
   * This method doesn't expect any request body.
   */
  copyDashboardMetadata$Response(params: {
    dashboardGUID: string;
  }): Observable<StrictHttpResponse<{
'isSuccess'?: boolean;
'message'?: string;
'data'?: string;
}>> {

    const rb = new RequestBuilder(this.rootUrl, ApiService.CopyDashboardMetadataPath, 'post');
    if (params) {
      rb.path('dashboardGUID', params.dashboardGUID, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<{
        'isSuccess'?: boolean;
        'message'?: string;
        'data'?: string;
        }>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `copyDashboardMetadata$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  copyDashboardMetadata(params: {
    dashboardGUID: string;
  }): Observable<{
'isSuccess'?: boolean;
'message'?: string;
'data'?: string;
}> {

    return this.copyDashboardMetadata$Response(params).pipe(
      map((r: StrictHttpResponse<{
'isSuccess'?: boolean;
'message'?: string;
'data'?: string;
}>) => r.body as {
'isSuccess'?: boolean;
'message'?: string;
'data'?: string;
})
    );
  }

  /**
   * Path part for operation updateDashboardMetadata
   */
  static readonly UpdateDashboardMetadataPath = '/v1/reports/update/dashboard';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `updateDashboardMetadata()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  updateDashboardMetadata$Response(params: {
    body: DashboardUpdateRequest
  }): Observable<StrictHttpResponse<{
'isSuccess'?: boolean;
'message'?: string;
'data'?: string;
}>> {

    const rb = new RequestBuilder(this.rootUrl, ApiService.UpdateDashboardMetadataPath, 'post');
    if (params) {
      rb.body(params.body, 'application/json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<{
        'isSuccess'?: boolean;
        'message'?: string;
        'data'?: string;
        }>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `updateDashboardMetadata$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  updateDashboardMetadata(params: {
    body: DashboardUpdateRequest
  }): Observable<{
'isSuccess'?: boolean;
'message'?: string;
'data'?: string;
}> {

    return this.updateDashboardMetadata$Response(params).pipe(
      map((r: StrictHttpResponse<{
'isSuccess'?: boolean;
'message'?: string;
'data'?: string;
}>) => r.body as {
'isSuccess'?: boolean;
'message'?: string;
'data'?: string;
})
    );
  }

  /**
   * Path part for operation addDashboardReport
   */
  static readonly AddDashboardReportPath = '/v1/reports/add/dashboard';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `addDashboardReport()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  addDashboardReport$Response(params: {
    body: DashboardUiModel
  }): Observable<StrictHttpResponse<{
'isSuccess'?: boolean;
'message'?: string;
'data'?: string;
}>> {

    const rb = new RequestBuilder(this.rootUrl, ApiService.AddDashboardReportPath, 'post');
    if (params) {
      rb.body(params.body, 'application/json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<{
        'isSuccess'?: boolean;
        'message'?: string;
        'data'?: string;
        }>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `addDashboardReport$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  addDashboardReport(params: {
    body: DashboardUiModel
  }): Observable<{
'isSuccess'?: boolean;
'message'?: string;
'data'?: string;
}> {

    return this.addDashboardReport$Response(params).pipe(
      map((r: StrictHttpResponse<{
'isSuccess'?: boolean;
'message'?: string;
'data'?: string;
}>) => r.body as {
'isSuccess'?: boolean;
'message'?: string;
'data'?: string;
})
    );
  }

  /**
   * Path part for operation listReportMenu
   */
  static readonly ListReportMenuPath = '/v1/reports/list/menu/report';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `listReportMenu()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  listReportMenu$Response(params: {
    body: ReportMenuRequest
  }): Observable<StrictHttpResponse<{
'isSuccess'?: boolean;
'message'?: string;
'data'?: {
'pageNumber'?: number;
'pageSize'?: number;
'totalRowCount'?: number;
'items'?: Array<ReportMenuOption>;
};
}>> {

    const rb = new RequestBuilder(this.rootUrl, ApiService.ListReportMenuPath, 'post');
    if (params) {
      rb.body(params.body, 'application/json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<{
        'isSuccess'?: boolean;
        'message'?: string;
        'data'?: {
        'pageNumber'?: number;
        'pageSize'?: number;
        'totalRowCount'?: number;
        'items'?: Array<ReportMenuOption>;
        };
        }>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `listReportMenu$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  listReportMenu(params: {
    body: ReportMenuRequest
  }): Observable<{
'isSuccess'?: boolean;
'message'?: string;
'data'?: {
'pageNumber'?: number;
'pageSize'?: number;
'totalRowCount'?: number;
'items'?: Array<ReportMenuOption>;
};
}> {

    return this.listReportMenu$Response(params).pipe(
      map((r: StrictHttpResponse<{
'isSuccess'?: boolean;
'message'?: string;
'data'?: {
'pageNumber'?: number;
'pageSize'?: number;
'totalRowCount'?: number;
'items'?: Array<ReportMenuOption>;
};
}>) => r.body as {
'isSuccess'?: boolean;
'message'?: string;
'data'?: {
'pageNumber'?: number;
'pageSize'?: number;
'totalRowCount'?: number;
'items'?: Array<ReportMenuOption>;
};
})
    );
  }

  /**
   * Path part for operation listReports
   */
  static readonly ListReportsPath = '/v1/reports/list/reports';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `listReports()` instead.
   *
   * This method doesn't expect any request body.
   */
  listReports$Response(params?: {
  }): Observable<StrictHttpResponse<{
'isSuccess'?: boolean;
'message'?: string;
'data'?: {
'pageNumber'?: number;
'pageSize'?: number;
'totalRowCount'?: number;
'items'?: Array<ReportModel>;
};
}>> {

    const rb = new RequestBuilder(this.rootUrl, ApiService.ListReportsPath, 'post');
    if (params) {
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<{
        'isSuccess'?: boolean;
        'message'?: string;
        'data'?: {
        'pageNumber'?: number;
        'pageSize'?: number;
        'totalRowCount'?: number;
        'items'?: Array<ReportModel>;
        };
        }>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `listReports$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  listReports(params?: {
  }): Observable<{
'isSuccess'?: boolean;
'message'?: string;
'data'?: {
'pageNumber'?: number;
'pageSize'?: number;
'totalRowCount'?: number;
'items'?: Array<ReportModel>;
};
}> {

    return this.listReports$Response(params).pipe(
      map((r: StrictHttpResponse<{
'isSuccess'?: boolean;
'message'?: string;
'data'?: {
'pageNumber'?: number;
'pageSize'?: number;
'totalRowCount'?: number;
'items'?: Array<ReportModel>;
};
}>) => r.body as {
'isSuccess'?: boolean;
'message'?: string;
'data'?: {
'pageNumber'?: number;
'pageSize'?: number;
'totalRowCount'?: number;
'items'?: Array<ReportModel>;
};
})
    );
  }

  /**
   * Path part for operation singleReport
   */
  static readonly SingleReportPath = '/v1/reports/report/{reportGUID}';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `singleReport()` instead.
   *
   * This method doesn't expect any request body.
   */
  singleReport$Response(params: {
    reportGUID: string;
  }): Observable<StrictHttpResponse<{
'isSuccess'?: boolean;
'message'?: string;
'data'?: {
'pageNumber'?: number;
'pageSize'?: number;
'totalRowCount'?: number;
'items'?: Array<ReportUiModel>;
};
}>> {

    const rb = new RequestBuilder(this.rootUrl, ApiService.SingleReportPath, 'get');
    if (params) {
      rb.path('reportGUID', params.reportGUID, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<{
        'isSuccess'?: boolean;
        'message'?: string;
        'data'?: {
        'pageNumber'?: number;
        'pageSize'?: number;
        'totalRowCount'?: number;
        'items'?: Array<ReportUiModel>;
        };
        }>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `singleReport$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  singleReport(params: {
    reportGUID: string;
  }): Observable<{
'isSuccess'?: boolean;
'message'?: string;
'data'?: {
'pageNumber'?: number;
'pageSize'?: number;
'totalRowCount'?: number;
'items'?: Array<ReportUiModel>;
};
}> {

    return this.singleReport$Response(params).pipe(
      map((r: StrictHttpResponse<{
'isSuccess'?: boolean;
'message'?: string;
'data'?: {
'pageNumber'?: number;
'pageSize'?: number;
'totalRowCount'?: number;
'items'?: Array<ReportUiModel>;
};
}>) => r.body as {
'isSuccess'?: boolean;
'message'?: string;
'data'?: {
'pageNumber'?: number;
'pageSize'?: number;
'totalRowCount'?: number;
'items'?: Array<ReportUiModel>;
};
})
    );
  }

  /**
   * Path part for operation linkedReportMenu
   */
  static readonly LinkedReportMenuPath = '/v1/reports/linkedReport/{reportGUID}';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `linkedReportMenu()` instead.
   *
   * This method doesn't expect any request body.
   */
  linkedReportMenu$Response(params: {
    reportGUID: string;
  }): Observable<StrictHttpResponse<{
'isSuccess'?: boolean;
'message'?: string;
'data'?: {
'pageNumber'?: number;
'pageSize'?: number;
'totalRowCount'?: number;
'items'?: Array<ReportLinkedMenu>;
};
}>> {

    const rb = new RequestBuilder(this.rootUrl, ApiService.LinkedReportMenuPath, 'get');
    if (params) {
      rb.path('reportGUID', params.reportGUID, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<{
        'isSuccess'?: boolean;
        'message'?: string;
        'data'?: {
        'pageNumber'?: number;
        'pageSize'?: number;
        'totalRowCount'?: number;
        'items'?: Array<ReportLinkedMenu>;
        };
        }>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `linkedReportMenu$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  linkedReportMenu(params: {
    reportGUID: string;
  }): Observable<{
'isSuccess'?: boolean;
'message'?: string;
'data'?: {
'pageNumber'?: number;
'pageSize'?: number;
'totalRowCount'?: number;
'items'?: Array<ReportLinkedMenu>;
};
}> {

    return this.linkedReportMenu$Response(params).pipe(
      map((r: StrictHttpResponse<{
'isSuccess'?: boolean;
'message'?: string;
'data'?: {
'pageNumber'?: number;
'pageSize'?: number;
'totalRowCount'?: number;
'items'?: Array<ReportLinkedMenu>;
};
}>) => r.body as {
'isSuccess'?: boolean;
'message'?: string;
'data'?: {
'pageNumber'?: number;
'pageSize'?: number;
'totalRowCount'?: number;
'items'?: Array<ReportLinkedMenu>;
};
})
    );
  }

  /**
   * Path part for operation copyReportMetadata
   */
  static readonly CopyReportMetadataPath = '/v1/reports/copy/report/{reportGUID}';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `copyReportMetadata()` instead.
   *
   * This method doesn't expect any request body.
   */
  copyReportMetadata$Response(params: {
    reportGUID: string;
  }): Observable<StrictHttpResponse<{
'isSuccess'?: boolean;
'message'?: string;
'data'?: string;
}>> {

    const rb = new RequestBuilder(this.rootUrl, ApiService.CopyReportMetadataPath, 'post');
    if (params) {
      rb.path('reportGUID', params.reportGUID, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<{
        'isSuccess'?: boolean;
        'message'?: string;
        'data'?: string;
        }>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `copyReportMetadata$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  copyReportMetadata(params: {
    reportGUID: string;
  }): Observable<{
'isSuccess'?: boolean;
'message'?: string;
'data'?: string;
}> {

    return this.copyReportMetadata$Response(params).pipe(
      map((r: StrictHttpResponse<{
'isSuccess'?: boolean;
'message'?: string;
'data'?: string;
}>) => r.body as {
'isSuccess'?: boolean;
'message'?: string;
'data'?: string;
})
    );
  }

  /**
   * Path part for operation updateReportMetadata
   */
  static readonly UpdateReportMetadataPath = '/v1/reports/update/report';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `updateReportMetadata()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  updateReportMetadata$Response(params: {
    body: ReportUpdateRequest
  }): Observable<StrictHttpResponse<{
'isSuccess'?: boolean;
'message'?: string;
'data'?: string;
}>> {

    const rb = new RequestBuilder(this.rootUrl, ApiService.UpdateReportMetadataPath, 'post');
    if (params) {
      rb.body(params.body, 'application/json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<{
        'isSuccess'?: boolean;
        'message'?: string;
        'data'?: string;
        }>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `updateReportMetadata$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  updateReportMetadata(params: {
    body: ReportUpdateRequest
  }): Observable<{
'isSuccess'?: boolean;
'message'?: string;
'data'?: string;
}> {

    return this.updateReportMetadata$Response(params).pipe(
      map((r: StrictHttpResponse<{
'isSuccess'?: boolean;
'message'?: string;
'data'?: string;
}>) => r.body as {
'isSuccess'?: boolean;
'message'?: string;
'data'?: string;
})
    );
  }

  /**
   * Path part for operation addReport
   */
  static readonly AddReportPath = '/v1/reports/add/report';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `addReport()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  addReport$Response(params: {
    body: ReportUiModel
  }): Observable<StrictHttpResponse<{
'isSuccess'?: boolean;
'message'?: string;
'data'?: string;
}>> {

    const rb = new RequestBuilder(this.rootUrl, ApiService.AddReportPath, 'post');
    if (params) {
      rb.body(params.body, 'application/json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<{
        'isSuccess'?: boolean;
        'message'?: string;
        'data'?: string;
        }>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `addReport$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  addReport(params: {
    body: ReportUiModel
  }): Observable<{
'isSuccess'?: boolean;
'message'?: string;
'data'?: string;
}> {

    return this.addReport$Response(params).pipe(
      map((r: StrictHttpResponse<{
'isSuccess'?: boolean;
'message'?: string;
'data'?: string;
}>) => r.body as {
'isSuccess'?: boolean;
'message'?: string;
'data'?: string;
})
    );
  }

  /**
   * Path part for operation viewSuggestionList
   */
  static readonly ViewSuggestionListPath = '/v1/feedback/list/viewSuggestion';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `viewSuggestionList()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  viewSuggestionList$Response(params: {
    body: SuggestionSummaryRequest
  }): Observable<StrictHttpResponse<{
'isSuccess'?: boolean;
'message'?: string;
'data'?: {
'pageNumber'?: number;
'pageSize'?: number;
'totalRowCount'?: number;
'items'?: Array<SuggestionItem>;
};
}>> {

    const rb = new RequestBuilder(this.rootUrl, ApiService.ViewSuggestionListPath, 'post');
    if (params) {
      rb.body(params.body, 'application/json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<{
        'isSuccess'?: boolean;
        'message'?: string;
        'data'?: {
        'pageNumber'?: number;
        'pageSize'?: number;
        'totalRowCount'?: number;
        'items'?: Array<SuggestionItem>;
        };
        }>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `viewSuggestionList$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  viewSuggestionList(params: {
    body: SuggestionSummaryRequest
  }): Observable<{
'isSuccess'?: boolean;
'message'?: string;
'data'?: {
'pageNumber'?: number;
'pageSize'?: number;
'totalRowCount'?: number;
'items'?: Array<SuggestionItem>;
};
}> {

    return this.viewSuggestionList$Response(params).pipe(
      map((r: StrictHttpResponse<{
'isSuccess'?: boolean;
'message'?: string;
'data'?: {
'pageNumber'?: number;
'pageSize'?: number;
'totalRowCount'?: number;
'items'?: Array<SuggestionItem>;
};
}>) => r.body as {
'isSuccess'?: boolean;
'message'?: string;
'data'?: {
'pageNumber'?: number;
'pageSize'?: number;
'totalRowCount'?: number;
'items'?: Array<SuggestionItem>;
};
})
    );
  }

  /**
   * Path part for operation viewSuggestionCount
   */
  static readonly ViewSuggestionCountPath = '/v1/feedback/list/viewSuggestionCount';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `viewSuggestionCount()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  viewSuggestionCount$Response(params: {
    body: SuggestionSummaryRequest
  }): Observable<StrictHttpResponse<{
'isSuccess'?: boolean;
'message'?: string;
'data'?: SuggestionItemSummary;
}>> {

    const rb = new RequestBuilder(this.rootUrl, ApiService.ViewSuggestionCountPath, 'post');
    if (params) {
      rb.body(params.body, 'application/json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<{
        'isSuccess'?: boolean;
        'message'?: string;
        'data'?: SuggestionItemSummary;
        }>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `viewSuggestionCount$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  viewSuggestionCount(params: {
    body: SuggestionSummaryRequest
  }): Observable<{
'isSuccess'?: boolean;
'message'?: string;
'data'?: SuggestionItemSummary;
}> {

    return this.viewSuggestionCount$Response(params).pipe(
      map((r: StrictHttpResponse<{
'isSuccess'?: boolean;
'message'?: string;
'data'?: SuggestionItemSummary;
}>) => r.body as {
'isSuccess'?: boolean;
'message'?: string;
'data'?: SuggestionItemSummary;
})
    );
  }

  /**
   * Path part for operation getFeedbackType
   */
  static readonly GetFeedbackTypePath = '/v1/feedback/list/getFeedbackType';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getFeedbackType()` instead.
   *
   * This method doesn't expect any request body.
   */
  getFeedbackType$Response(params?: {
  }): Observable<StrictHttpResponse<{
'isSuccess'?: boolean;
'message'?: string;
'data'?: {
'pageNumber'?: number;
'pageSize'?: number;
'totalRowCount'?: number;
'items'?: Array<FeedbackTypeItem>;
};
}>> {

    const rb = new RequestBuilder(this.rootUrl, ApiService.GetFeedbackTypePath, 'post');
    if (params) {
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<{
        'isSuccess'?: boolean;
        'message'?: string;
        'data'?: {
        'pageNumber'?: number;
        'pageSize'?: number;
        'totalRowCount'?: number;
        'items'?: Array<FeedbackTypeItem>;
        };
        }>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `getFeedbackType$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getFeedbackType(params?: {
  }): Observable<{
'isSuccess'?: boolean;
'message'?: string;
'data'?: {
'pageNumber'?: number;
'pageSize'?: number;
'totalRowCount'?: number;
'items'?: Array<FeedbackTypeItem>;
};
}> {

    return this.getFeedbackType$Response(params).pipe(
      map((r: StrictHttpResponse<{
'isSuccess'?: boolean;
'message'?: string;
'data'?: {
'pageNumber'?: number;
'pageSize'?: number;
'totalRowCount'?: number;
'items'?: Array<FeedbackTypeItem>;
};
}>) => r.body as {
'isSuccess'?: boolean;
'message'?: string;
'data'?: {
'pageNumber'?: number;
'pageSize'?: number;
'totalRowCount'?: number;
'items'?: Array<FeedbackTypeItem>;
};
})
    );
  }

  /**
   * Path part for operation feedbackAdd
   */
  static readonly FeedbackAddPath = '/v1/feedback/add/feedback';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `feedbackAdd()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  feedbackAdd$Response(params: {
    body: FeedbackRequest
  }): Observable<StrictHttpResponse<{
'isSuccess'?: boolean;
'message'?: string;
'data'?: string;
}>> {

    const rb = new RequestBuilder(this.rootUrl, ApiService.FeedbackAddPath, 'post');
    if (params) {
      rb.body(params.body, 'application/json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<{
        'isSuccess'?: boolean;
        'message'?: string;
        'data'?: string;
        }>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `feedbackAdd$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  feedbackAdd(params: {
    body: FeedbackRequest
  }): Observable<{
'isSuccess'?: boolean;
'message'?: string;
'data'?: string;
}> {

    return this.feedbackAdd$Response(params).pipe(
      map((r: StrictHttpResponse<{
'isSuccess'?: boolean;
'message'?: string;
'data'?: string;
}>) => r.body as {
'isSuccess'?: boolean;
'message'?: string;
'data'?: string;
})
    );
  }

  /**
   * Path part for operation feedbackDelete
   */
  static readonly FeedbackDeletePath = '/v1/feedback/delete/feedback';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `feedbackDelete()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  feedbackDelete$Response(params: {
    body: DeleteRequest
  }): Observable<StrictHttpResponse<{
'isSuccess'?: boolean;
'message'?: string;
'data'?: string;
}>> {

    const rb = new RequestBuilder(this.rootUrl, ApiService.FeedbackDeletePath, 'post');
    if (params) {
      rb.body(params.body, 'application/json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<{
        'isSuccess'?: boolean;
        'message'?: string;
        'data'?: string;
        }>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `feedbackDelete$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  feedbackDelete(params: {
    body: DeleteRequest
  }): Observable<{
'isSuccess'?: boolean;
'message'?: string;
'data'?: string;
}> {

    return this.feedbackDelete$Response(params).pipe(
      map((r: StrictHttpResponse<{
'isSuccess'?: boolean;
'message'?: string;
'data'?: string;
}>) => r.body as {
'isSuccess'?: boolean;
'message'?: string;
'data'?: string;
})
    );
  }

  /**
   * Path part for operation suggestionAdd
   */
  static readonly SuggestionAddPath = '/v1/feedback/add/suggestion';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `suggestionAdd()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  suggestionAdd$Response(params: {
    body: SuggestionRequest
  }): Observable<StrictHttpResponse<{
'isSuccess'?: boolean;
'message'?: string;
'data'?: string;
}>> {

    const rb = new RequestBuilder(this.rootUrl, ApiService.SuggestionAddPath, 'post');
    if (params) {
      rb.body(params.body, 'application/json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<{
        'isSuccess'?: boolean;
        'message'?: string;
        'data'?: string;
        }>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `suggestionAdd$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  suggestionAdd(params: {
    body: SuggestionRequest
  }): Observable<{
'isSuccess'?: boolean;
'message'?: string;
'data'?: string;
}> {

    return this.suggestionAdd$Response(params).pipe(
      map((r: StrictHttpResponse<{
'isSuccess'?: boolean;
'message'?: string;
'data'?: string;
}>) => r.body as {
'isSuccess'?: boolean;
'message'?: string;
'data'?: string;
})
    );
  }

  /**
   * Path part for operation updateSuggestionReplyView
   */
  static readonly UpdateSuggestionReplyViewPath = '/v1/feedback/update/suggestion';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `updateSuggestionReplyView()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  updateSuggestionReplyView$Response(params: {
    body: SuggestionRequest
  }): Observable<StrictHttpResponse<{
'isSuccess'?: boolean;
'message'?: string;
'data'?: string;
}>> {

    const rb = new RequestBuilder(this.rootUrl, ApiService.UpdateSuggestionReplyViewPath, 'post');
    if (params) {
      rb.body(params.body, 'application/json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<{
        'isSuccess'?: boolean;
        'message'?: string;
        'data'?: string;
        }>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `updateSuggestionReplyView$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  updateSuggestionReplyView(params: {
    body: SuggestionRequest
  }): Observable<{
'isSuccess'?: boolean;
'message'?: string;
'data'?: string;
}> {

    return this.updateSuggestionReplyView$Response(params).pipe(
      map((r: StrictHttpResponse<{
'isSuccess'?: boolean;
'message'?: string;
'data'?: string;
}>) => r.body as {
'isSuccess'?: boolean;
'message'?: string;
'data'?: string;
})
    );
  }

  /**
   * Path part for operation suggestionReplyAdd
   */
  static readonly SuggestionReplyAddPath = '/v1/feedback/add/suggestionReply';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `suggestionReplyAdd()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  suggestionReplyAdd$Response(params: {
    body: SuggestionRequest
  }): Observable<StrictHttpResponse<{
'isSuccess'?: boolean;
'message'?: string;
'data'?: string;
}>> {

    const rb = new RequestBuilder(this.rootUrl, ApiService.SuggestionReplyAddPath, 'post');
    if (params) {
      rb.body(params.body, 'application/json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<{
        'isSuccess'?: boolean;
        'message'?: string;
        'data'?: string;
        }>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `suggestionReplyAdd$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  suggestionReplyAdd(params: {
    body: SuggestionRequest
  }): Observable<{
'isSuccess'?: boolean;
'message'?: string;
'data'?: string;
}> {

    return this.suggestionReplyAdd$Response(params).pipe(
      map((r: StrictHttpResponse<{
'isSuccess'?: boolean;
'message'?: string;
'data'?: string;
}>) => r.body as {
'isSuccess'?: boolean;
'message'?: string;
'data'?: string;
})
    );
  }

  /**
   * Path part for operation fetchSuggestionReplies
   */
  static readonly FetchSuggestionRepliesPath = '/v1/feedback/list/fetchSuggestionReplies';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `fetchSuggestionReplies()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  fetchSuggestionReplies$Response(params: {
    body: SuggestionChatRequest
  }): Observable<StrictHttpResponse<{
'isSuccess'?: boolean;
'message'?: string;
'data'?: {
'pageNumber'?: number;
'pageSize'?: number;
'totalRowCount'?: number;
'items'?: Array<SuggestionItem>;
};
}>> {

    const rb = new RequestBuilder(this.rootUrl, ApiService.FetchSuggestionRepliesPath, 'post');
    if (params) {
      rb.body(params.body, 'application/json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<{
        'isSuccess'?: boolean;
        'message'?: string;
        'data'?: {
        'pageNumber'?: number;
        'pageSize'?: number;
        'totalRowCount'?: number;
        'items'?: Array<SuggestionItem>;
        };
        }>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `fetchSuggestionReplies$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  fetchSuggestionReplies(params: {
    body: SuggestionChatRequest
  }): Observable<{
'isSuccess'?: boolean;
'message'?: string;
'data'?: {
'pageNumber'?: number;
'pageSize'?: number;
'totalRowCount'?: number;
'items'?: Array<SuggestionItem>;
};
}> {

    return this.fetchSuggestionReplies$Response(params).pipe(
      map((r: StrictHttpResponse<{
'isSuccess'?: boolean;
'message'?: string;
'data'?: {
'pageNumber'?: number;
'pageSize'?: number;
'totalRowCount'?: number;
'items'?: Array<SuggestionItem>;
};
}>) => r.body as {
'isSuccess'?: boolean;
'message'?: string;
'data'?: {
'pageNumber'?: number;
'pageSize'?: number;
'totalRowCount'?: number;
'items'?: Array<SuggestionItem>;
};
})
    );
  }

  /**
   * Path part for operation listBranches
   */
  static readonly ListBranchesPath = '/v1/branch/list/branches';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `listBranches()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  listBranches$Response(params: {
    body: EntityGetRequest
  }): Observable<StrictHttpResponse<{
'isSuccess'?: boolean;
'message'?: string;
'data'?: {
'pageNumber'?: number;
'pageSize'?: number;
'totalRowCount'?: number;
'items'?: Array<BranchItem>;
};
}>> {

    const rb = new RequestBuilder(this.rootUrl, ApiService.ListBranchesPath, 'post');
    if (params) {
      rb.body(params.body, 'application/json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<{
        'isSuccess'?: boolean;
        'message'?: string;
        'data'?: {
        'pageNumber'?: number;
        'pageSize'?: number;
        'totalRowCount'?: number;
        'items'?: Array<BranchItem>;
        };
        }>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `listBranches$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  listBranches(params: {
    body: EntityGetRequest
  }): Observable<{
'isSuccess'?: boolean;
'message'?: string;
'data'?: {
'pageNumber'?: number;
'pageSize'?: number;
'totalRowCount'?: number;
'items'?: Array<BranchItem>;
};
}> {

    return this.listBranches$Response(params).pipe(
      map((r: StrictHttpResponse<{
'isSuccess'?: boolean;
'message'?: string;
'data'?: {
'pageNumber'?: number;
'pageSize'?: number;
'totalRowCount'?: number;
'items'?: Array<BranchItem>;
};
}>) => r.body as {
'isSuccess'?: boolean;
'message'?: string;
'data'?: {
'pageNumber'?: number;
'pageSize'?: number;
'totalRowCount'?: number;
'items'?: Array<BranchItem>;
};
})
    );
  }

  /**
   * Path part for operation regionList
   */
  static readonly RegionListPath = '/v1/branch/list/internalRegion';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `regionList()` instead.
   *
   * This method doesn't expect any request body.
   */
  regionList$Response(params?: {
  }): Observable<StrictHttpResponse<{
'isSuccess'?: boolean;
'message'?: string;
'data'?: {
'pageNumber'?: number;
'pageSize'?: number;
'totalRowCount'?: number;
'items'?: Array<InternalRegion>;
};
}>> {

    const rb = new RequestBuilder(this.rootUrl, ApiService.RegionListPath, 'post');
    if (params) {
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<{
        'isSuccess'?: boolean;
        'message'?: string;
        'data'?: {
        'pageNumber'?: number;
        'pageSize'?: number;
        'totalRowCount'?: number;
        'items'?: Array<InternalRegion>;
        };
        }>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `regionList$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  regionList(params?: {
  }): Observable<{
'isSuccess'?: boolean;
'message'?: string;
'data'?: {
'pageNumber'?: number;
'pageSize'?: number;
'totalRowCount'?: number;
'items'?: Array<InternalRegion>;
};
}> {

    return this.regionList$Response(params).pipe(
      map((r: StrictHttpResponse<{
'isSuccess'?: boolean;
'message'?: string;
'data'?: {
'pageNumber'?: number;
'pageSize'?: number;
'totalRowCount'?: number;
'items'?: Array<InternalRegion>;
};
}>) => r.body as {
'isSuccess'?: boolean;
'message'?: string;
'data'?: {
'pageNumber'?: number;
'pageSize'?: number;
'totalRowCount'?: number;
'items'?: Array<InternalRegion>;
};
})
    );
  }

  /**
   * Path part for operation getBranchDetails
   */
  static readonly GetBranchDetailsPath = '/v1/branch/getBranchDetails';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getBranchDetails()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  getBranchDetails$Response(params: {
    body: FetchBranchRequest
  }): Observable<StrictHttpResponse<{
'isSuccess'?: boolean;
'message'?: string;
'data'?: BranchItem;
}>> {

    const rb = new RequestBuilder(this.rootUrl, ApiService.GetBranchDetailsPath, 'post');
    if (params) {
      rb.body(params.body, 'application/json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<{
        'isSuccess'?: boolean;
        'message'?: string;
        'data'?: BranchItem;
        }>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `getBranchDetails$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  getBranchDetails(params: {
    body: FetchBranchRequest
  }): Observable<{
'isSuccess'?: boolean;
'message'?: string;
'data'?: BranchItem;
}> {

    return this.getBranchDetails$Response(params).pipe(
      map((r: StrictHttpResponse<{
'isSuccess'?: boolean;
'message'?: string;
'data'?: BranchItem;
}>) => r.body as {
'isSuccess'?: boolean;
'message'?: string;
'data'?: BranchItem;
})
    );
  }

  /**
   * Path part for operation branchAdd
   */
  static readonly BranchAddPath = '/v1/branch/add/branch';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `branchAdd()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  branchAdd$Response(params: {
    body: BranchItem
  }): Observable<StrictHttpResponse<{
'isSuccess'?: boolean;
'message'?: string;
'data'?: string;
}>> {

    const rb = new RequestBuilder(this.rootUrl, ApiService.BranchAddPath, 'post');
    if (params) {
      rb.body(params.body, 'application/json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<{
        'isSuccess'?: boolean;
        'message'?: string;
        'data'?: string;
        }>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `branchAdd$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  branchAdd(params: {
    body: BranchItem
  }): Observable<{
'isSuccess'?: boolean;
'message'?: string;
'data'?: string;
}> {

    return this.branchAdd$Response(params).pipe(
      map((r: StrictHttpResponse<{
'isSuccess'?: boolean;
'message'?: string;
'data'?: string;
}>) => r.body as {
'isSuccess'?: boolean;
'message'?: string;
'data'?: string;
})
    );
  }

  /**
   * Path part for operation listBranchServices
   */
  static readonly ListBranchServicesPath = '/v1/branch/list/branchServices';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `listBranchServices()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  listBranchServices$Response(params: {
    body: EntityGetRequest
  }): Observable<StrictHttpResponse<{
'isSuccess'?: boolean;
'message'?: string;
'data'?: {
'pageNumber'?: number;
'pageSize'?: number;
'totalRowCount'?: number;
'items'?: Array<BranchItem>;
};
}>> {

    const rb = new RequestBuilder(this.rootUrl, ApiService.ListBranchServicesPath, 'post');
    if (params) {
      rb.body(params.body, 'application/json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<{
        'isSuccess'?: boolean;
        'message'?: string;
        'data'?: {
        'pageNumber'?: number;
        'pageSize'?: number;
        'totalRowCount'?: number;
        'items'?: Array<BranchItem>;
        };
        }>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `listBranchServices$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  listBranchServices(params: {
    body: EntityGetRequest
  }): Observable<{
'isSuccess'?: boolean;
'message'?: string;
'data'?: {
'pageNumber'?: number;
'pageSize'?: number;
'totalRowCount'?: number;
'items'?: Array<BranchItem>;
};
}> {

    return this.listBranchServices$Response(params).pipe(
      map((r: StrictHttpResponse<{
'isSuccess'?: boolean;
'message'?: string;
'data'?: {
'pageNumber'?: number;
'pageSize'?: number;
'totalRowCount'?: number;
'items'?: Array<BranchItem>;
};
}>) => r.body as {
'isSuccess'?: boolean;
'message'?: string;
'data'?: {
'pageNumber'?: number;
'pageSize'?: number;
'totalRowCount'?: number;
'items'?: Array<BranchItem>;
};
})
    );
  }

  /**
   * Path part for operation campaignManagementDetailsList
   */
  static readonly CampaignManagementDetailsListPath = '/v1/campaignManagement/list/campaignManagementDetails';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `campaignManagementDetailsList()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  campaignManagementDetailsList$Response(params: {
    body: EntityGetRequest
  }): Observable<StrictHttpResponse<{
'isSuccess'?: boolean;
'message'?: string;
'data'?: {
'pageNumber'?: number;
'pageSize'?: number;
'totalRowCount'?: number;
'items'?: Array<CampaignManagementDetails>;
};
}>> {

    const rb = new RequestBuilder(this.rootUrl, ApiService.CampaignManagementDetailsListPath, 'post');
    if (params) {
      rb.body(params.body, 'application/json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<{
        'isSuccess'?: boolean;
        'message'?: string;
        'data'?: {
        'pageNumber'?: number;
        'pageSize'?: number;
        'totalRowCount'?: number;
        'items'?: Array<CampaignManagementDetails>;
        };
        }>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `campaignManagementDetailsList$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  campaignManagementDetailsList(params: {
    body: EntityGetRequest
  }): Observable<{
'isSuccess'?: boolean;
'message'?: string;
'data'?: {
'pageNumber'?: number;
'pageSize'?: number;
'totalRowCount'?: number;
'items'?: Array<CampaignManagementDetails>;
};
}> {

    return this.campaignManagementDetailsList$Response(params).pipe(
      map((r: StrictHttpResponse<{
'isSuccess'?: boolean;
'message'?: string;
'data'?: {
'pageNumber'?: number;
'pageSize'?: number;
'totalRowCount'?: number;
'items'?: Array<CampaignManagementDetails>;
};
}>) => r.body as {
'isSuccess'?: boolean;
'message'?: string;
'data'?: {
'pageNumber'?: number;
'pageSize'?: number;
'totalRowCount'?: number;
'items'?: Array<CampaignManagementDetails>;
};
})
    );
  }

  /**
   * Path part for operation getCampaignManagementDropdownData
   */
  static readonly GetCampaignManagementDropdownDataPath = '/v1/campaignManagement/getCampaignManagementDropdownData';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getCampaignManagementDropdownData()` instead.
   *
   * This method doesn't expect any request body.
   */
  getCampaignManagementDropdownData$Response(params?: {
  }): Observable<StrictHttpResponse<{
'isSuccess'?: boolean;
'message'?: string;
'data'?: CampaignManagementDropdownDetailsModel;
}>> {

    const rb = new RequestBuilder(this.rootUrl, ApiService.GetCampaignManagementDropdownDataPath, 'post');
    if (params) {
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<{
        'isSuccess'?: boolean;
        'message'?: string;
        'data'?: CampaignManagementDropdownDetailsModel;
        }>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `getCampaignManagementDropdownData$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getCampaignManagementDropdownData(params?: {
  }): Observable<{
'isSuccess'?: boolean;
'message'?: string;
'data'?: CampaignManagementDropdownDetailsModel;
}> {

    return this.getCampaignManagementDropdownData$Response(params).pipe(
      map((r: StrictHttpResponse<{
'isSuccess'?: boolean;
'message'?: string;
'data'?: CampaignManagementDropdownDetailsModel;
}>) => r.body as {
'isSuccess'?: boolean;
'message'?: string;
'data'?: CampaignManagementDropdownDetailsModel;
})
    );
  }

  /**
   * Path part for operation campaignAdd
   */
  static readonly CampaignAddPath = '/v1/campaignManagement/add/campaign';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `campaignAdd()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  campaignAdd$Response(params: {
    body: CampaignManagementDetails
  }): Observable<StrictHttpResponse<{
'isSuccess'?: boolean;
'message'?: string;
'data'?: string;
}>> {

    const rb = new RequestBuilder(this.rootUrl, ApiService.CampaignAddPath, 'post');
    if (params) {
      rb.body(params.body, 'application/json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<{
        'isSuccess'?: boolean;
        'message'?: string;
        'data'?: string;
        }>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `campaignAdd$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  campaignAdd(params: {
    body: CampaignManagementDetails
  }): Observable<{
'isSuccess'?: boolean;
'message'?: string;
'data'?: string;
}> {

    return this.campaignAdd$Response(params).pipe(
      map((r: StrictHttpResponse<{
'isSuccess'?: boolean;
'message'?: string;
'data'?: string;
}>) => r.body as {
'isSuccess'?: boolean;
'message'?: string;
'data'?: string;
})
    );
  }

  /**
   * Path part for operation listMailchimpCampaign
   */
  static readonly ListMailchimpCampaignPath = '/v1/mailchimpCampaign/list/mailchimpCampaign';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `listMailchimpCampaign()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  listMailchimpCampaign$Response(params: {
    body: EntityGetRequest
  }): Observable<StrictHttpResponse<{
'isSuccess'?: boolean;
'message'?: string;
'data'?: {
'pageNumber'?: number;
'pageSize'?: number;
'totalRowCount'?: number;
'items'?: Array<MailchimpCampaignItem>;
};
}>> {

    const rb = new RequestBuilder(this.rootUrl, ApiService.ListMailchimpCampaignPath, 'post');
    if (params) {
      rb.body(params.body, 'application/json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<{
        'isSuccess'?: boolean;
        'message'?: string;
        'data'?: {
        'pageNumber'?: number;
        'pageSize'?: number;
        'totalRowCount'?: number;
        'items'?: Array<MailchimpCampaignItem>;
        };
        }>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `listMailchimpCampaign$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  listMailchimpCampaign(params: {
    body: EntityGetRequest
  }): Observable<{
'isSuccess'?: boolean;
'message'?: string;
'data'?: {
'pageNumber'?: number;
'pageSize'?: number;
'totalRowCount'?: number;
'items'?: Array<MailchimpCampaignItem>;
};
}> {

    return this.listMailchimpCampaign$Response(params).pipe(
      map((r: StrictHttpResponse<{
'isSuccess'?: boolean;
'message'?: string;
'data'?: {
'pageNumber'?: number;
'pageSize'?: number;
'totalRowCount'?: number;
'items'?: Array<MailchimpCampaignItem>;
};
}>) => r.body as {
'isSuccess'?: boolean;
'message'?: string;
'data'?: {
'pageNumber'?: number;
'pageSize'?: number;
'totalRowCount'?: number;
'items'?: Array<MailchimpCampaignItem>;
};
})
    );
  }

  /**
   * Path part for operation getMailchimpCampaignDetails
   */
  static readonly GetMailchimpCampaignDetailsPath = '/v1/mailchimpCampaign/getMailchimpCampaignDetails';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getMailchimpCampaignDetails()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  getMailchimpCampaignDetails$Response(params: {
    body: FetchMailchimpCampaignRequest
  }): Observable<StrictHttpResponse<{
'isSuccess'?: boolean;
'message'?: string;
'data'?: MailchimpCampaignItem;
}>> {

    const rb = new RequestBuilder(this.rootUrl, ApiService.GetMailchimpCampaignDetailsPath, 'post');
    if (params) {
      rb.body(params.body, 'application/json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<{
        'isSuccess'?: boolean;
        'message'?: string;
        'data'?: MailchimpCampaignItem;
        }>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `getMailchimpCampaignDetails$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  getMailchimpCampaignDetails(params: {
    body: FetchMailchimpCampaignRequest
  }): Observable<{
'isSuccess'?: boolean;
'message'?: string;
'data'?: MailchimpCampaignItem;
}> {

    return this.getMailchimpCampaignDetails$Response(params).pipe(
      map((r: StrictHttpResponse<{
'isSuccess'?: boolean;
'message'?: string;
'data'?: MailchimpCampaignItem;
}>) => r.body as {
'isSuccess'?: boolean;
'message'?: string;
'data'?: MailchimpCampaignItem;
})
    );
  }

  /**
   * Path part for operation mailchimpCampaignAdd
   */
  static readonly MailchimpCampaignAddPath = '/v1/mailchimpCampaign/add/mailchimpCampaign';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `mailchimpCampaignAdd()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  mailchimpCampaignAdd$Response(params: {
    body: MailchimpCampaignItem
  }): Observable<StrictHttpResponse<{
'isSuccess'?: boolean;
'message'?: string;
'data'?: string;
}>> {

    const rb = new RequestBuilder(this.rootUrl, ApiService.MailchimpCampaignAddPath, 'post');
    if (params) {
      rb.body(params.body, 'application/json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<{
        'isSuccess'?: boolean;
        'message'?: string;
        'data'?: string;
        }>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `mailchimpCampaignAdd$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  mailchimpCampaignAdd(params: {
    body: MailchimpCampaignItem
  }): Observable<{
'isSuccess'?: boolean;
'message'?: string;
'data'?: string;
}> {

    return this.mailchimpCampaignAdd$Response(params).pipe(
      map((r: StrictHttpResponse<{
'isSuccess'?: boolean;
'message'?: string;
'data'?: string;
}>) => r.body as {
'isSuccess'?: boolean;
'message'?: string;
'data'?: string;
})
    );
  }

  /**
   * Path part for operation getGsJobHistory
   */
  static readonly GetGsJobHistoryPath = '/v1/gsJob/getJobHistory';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getGsJobHistory()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  getGsJobHistory$Response(params: {
    body: GsJobHistoryRequest
  }): Observable<StrictHttpResponse<{
'isSuccess'?: boolean;
'message'?: string;
'data'?: {
'pageNumber'?: number;
'pageSize'?: number;
'totalRowCount'?: number;
'items'?: Array<GsJobHistory>;
};
}>> {

    const rb = new RequestBuilder(this.rootUrl, ApiService.GetGsJobHistoryPath, 'post');
    if (params) {
      rb.body(params.body, 'application/json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<{
        'isSuccess'?: boolean;
        'message'?: string;
        'data'?: {
        'pageNumber'?: number;
        'pageSize'?: number;
        'totalRowCount'?: number;
        'items'?: Array<GsJobHistory>;
        };
        }>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `getGsJobHistory$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  getGsJobHistory(params: {
    body: GsJobHistoryRequest
  }): Observable<{
'isSuccess'?: boolean;
'message'?: string;
'data'?: {
'pageNumber'?: number;
'pageSize'?: number;
'totalRowCount'?: number;
'items'?: Array<GsJobHistory>;
};
}> {

    return this.getGsJobHistory$Response(params).pipe(
      map((r: StrictHttpResponse<{
'isSuccess'?: boolean;
'message'?: string;
'data'?: {
'pageNumber'?: number;
'pageSize'?: number;
'totalRowCount'?: number;
'items'?: Array<GsJobHistory>;
};
}>) => r.body as {
'isSuccess'?: boolean;
'message'?: string;
'data'?: {
'pageNumber'?: number;
'pageSize'?: number;
'totalRowCount'?: number;
'items'?: Array<GsJobHistory>;
};
})
    );
  }

  /**
   * Path part for operation getGsJobLastRun
   */
  static readonly GetGsJobLastRunPath = '/v1/gsJob/getJobStatus';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getGsJobLastRun()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  getGsJobLastRun$Response(params: {
    body: GsJobHistoryRequest
  }): Observable<StrictHttpResponse<{
'isSuccess'?: boolean;
'message'?: string;
'data'?: {
'pageNumber'?: number;
'pageSize'?: number;
'totalRowCount'?: number;
'items'?: Array<GsJobHistory>;
};
}>> {

    const rb = new RequestBuilder(this.rootUrl, ApiService.GetGsJobLastRunPath, 'post');
    if (params) {
      rb.body(params.body, 'application/json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<{
        'isSuccess'?: boolean;
        'message'?: string;
        'data'?: {
        'pageNumber'?: number;
        'pageSize'?: number;
        'totalRowCount'?: number;
        'items'?: Array<GsJobHistory>;
        };
        }>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `getGsJobLastRun$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  getGsJobLastRun(params: {
    body: GsJobHistoryRequest
  }): Observable<{
'isSuccess'?: boolean;
'message'?: string;
'data'?: {
'pageNumber'?: number;
'pageSize'?: number;
'totalRowCount'?: number;
'items'?: Array<GsJobHistory>;
};
}> {

    return this.getGsJobLastRun$Response(params).pipe(
      map((r: StrictHttpResponse<{
'isSuccess'?: boolean;
'message'?: string;
'data'?: {
'pageNumber'?: number;
'pageSize'?: number;
'totalRowCount'?: number;
'items'?: Array<GsJobHistory>;
};
}>) => r.body as {
'isSuccess'?: boolean;
'message'?: string;
'data'?: {
'pageNumber'?: number;
'pageSize'?: number;
'totalRowCount'?: number;
'items'?: Array<GsJobHistory>;
};
})
    );
  }

  /**
   * Path part for operation toggleJobStatus
   */
  static readonly ToggleJobStatusPath = '/v1/gsJob/add/toggleJobStatus';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `toggleJobStatus()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  toggleJobStatus$Response(params: {
    body: ToggleJobStatusRequest
  }): Observable<StrictHttpResponse<{
'isSuccess'?: boolean;
'message'?: string;
'data'?: string;
}>> {

    const rb = new RequestBuilder(this.rootUrl, ApiService.ToggleJobStatusPath, 'post');
    if (params) {
      rb.body(params.body, 'application/json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<{
        'isSuccess'?: boolean;
        'message'?: string;
        'data'?: string;
        }>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `toggleJobStatus$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  toggleJobStatus(params: {
    body: ToggleJobStatusRequest
  }): Observable<{
'isSuccess'?: boolean;
'message'?: string;
'data'?: string;
}> {

    return this.toggleJobStatus$Response(params).pipe(
      map((r: StrictHttpResponse<{
'isSuccess'?: boolean;
'message'?: string;
'data'?: string;
}>) => r.body as {
'isSuccess'?: boolean;
'message'?: string;
'data'?: string;
})
    );
  }

  /**
   * Path part for operation listGsJobs
   */
  static readonly ListGsJobsPath = '/v1/gsJob/list/Jobs';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `listGsJobs()` instead.
   *
   * This method doesn't expect any request body.
   */
  listGsJobs$Response(params?: {
  }): Observable<StrictHttpResponse<{
'isSuccess'?: boolean;
'message'?: string;
'data'?: {
'pageNumber'?: number;
'pageSize'?: number;
'totalRowCount'?: number;
'items'?: Array<GsJob>;
};
}>> {

    const rb = new RequestBuilder(this.rootUrl, ApiService.ListGsJobsPath, 'post');
    if (params) {
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<{
        'isSuccess'?: boolean;
        'message'?: string;
        'data'?: {
        'pageNumber'?: number;
        'pageSize'?: number;
        'totalRowCount'?: number;
        'items'?: Array<GsJob>;
        };
        }>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `listGsJobs$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  listGsJobs(params?: {
  }): Observable<{
'isSuccess'?: boolean;
'message'?: string;
'data'?: {
'pageNumber'?: number;
'pageSize'?: number;
'totalRowCount'?: number;
'items'?: Array<GsJob>;
};
}> {

    return this.listGsJobs$Response(params).pipe(
      map((r: StrictHttpResponse<{
'isSuccess'?: boolean;
'message'?: string;
'data'?: {
'pageNumber'?: number;
'pageSize'?: number;
'totalRowCount'?: number;
'items'?: Array<GsJob>;
};
}>) => r.body as {
'isSuccess'?: boolean;
'message'?: string;
'data'?: {
'pageNumber'?: number;
'pageSize'?: number;
'totalRowCount'?: number;
'items'?: Array<GsJob>;
};
})
    );
  }

  /**
   * Path part for operation listGsJobTypes
   */
  static readonly ListGsJobTypesPath = '/v1/gsJob/list/jobsType';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `listGsJobTypes()` instead.
   *
   * This method doesn't expect any request body.
   */
  listGsJobTypes$Response(params?: {
  }): Observable<StrictHttpResponse<{
'isSuccess'?: boolean;
'message'?: string;
'data'?: {
'pageNumber'?: number;
'pageSize'?: number;
'totalRowCount'?: number;
'items'?: Array<GsJobType>;
};
}>> {

    const rb = new RequestBuilder(this.rootUrl, ApiService.ListGsJobTypesPath, 'post');
    if (params) {
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<{
        'isSuccess'?: boolean;
        'message'?: string;
        'data'?: {
        'pageNumber'?: number;
        'pageSize'?: number;
        'totalRowCount'?: number;
        'items'?: Array<GsJobType>;
        };
        }>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `listGsJobTypes$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  listGsJobTypes(params?: {
  }): Observable<{
'isSuccess'?: boolean;
'message'?: string;
'data'?: {
'pageNumber'?: number;
'pageSize'?: number;
'totalRowCount'?: number;
'items'?: Array<GsJobType>;
};
}> {

    return this.listGsJobTypes$Response(params).pipe(
      map((r: StrictHttpResponse<{
'isSuccess'?: boolean;
'message'?: string;
'data'?: {
'pageNumber'?: number;
'pageSize'?: number;
'totalRowCount'?: number;
'items'?: Array<GsJobType>;
};
}>) => r.body as {
'isSuccess'?: boolean;
'message'?: string;
'data'?: {
'pageNumber'?: number;
'pageSize'?: number;
'totalRowCount'?: number;
'items'?: Array<GsJobType>;
};
})
    );
  }

  /**
   * Path part for operation listGsJobResultTypes
   */
  static readonly ListGsJobResultTypesPath = '/v1/gsJob/list/jobsResultType';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `listGsJobResultTypes()` instead.
   *
   * This method doesn't expect any request body.
   */
  listGsJobResultTypes$Response(params?: {
  }): Observable<StrictHttpResponse<{
'isSuccess'?: boolean;
'message'?: string;
'data'?: {
'pageNumber'?: number;
'pageSize'?: number;
'totalRowCount'?: number;
'items'?: Array<GsJobResultType>;
};
}>> {

    const rb = new RequestBuilder(this.rootUrl, ApiService.ListGsJobResultTypesPath, 'post');
    if (params) {
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<{
        'isSuccess'?: boolean;
        'message'?: string;
        'data'?: {
        'pageNumber'?: number;
        'pageSize'?: number;
        'totalRowCount'?: number;
        'items'?: Array<GsJobResultType>;
        };
        }>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `listGsJobResultTypes$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  listGsJobResultTypes(params?: {
  }): Observable<{
'isSuccess'?: boolean;
'message'?: string;
'data'?: {
'pageNumber'?: number;
'pageSize'?: number;
'totalRowCount'?: number;
'items'?: Array<GsJobResultType>;
};
}> {

    return this.listGsJobResultTypes$Response(params).pipe(
      map((r: StrictHttpResponse<{
'isSuccess'?: boolean;
'message'?: string;
'data'?: {
'pageNumber'?: number;
'pageSize'?: number;
'totalRowCount'?: number;
'items'?: Array<GsJobResultType>;
};
}>) => r.body as {
'isSuccess'?: boolean;
'message'?: string;
'data'?: {
'pageNumber'?: number;
'pageSize'?: number;
'totalRowCount'?: number;
'items'?: Array<GsJobResultType>;
};
})
    );
  }

  /**
   * Path part for operation listGsJobScheduleTypes
   */
  static readonly ListGsJobScheduleTypesPath = '/v1/gsJob/list/jobScheduleType';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `listGsJobScheduleTypes()` instead.
   *
   * This method doesn't expect any request body.
   */
  listGsJobScheduleTypes$Response(params?: {
  }): Observable<StrictHttpResponse<{
'isSuccess'?: boolean;
'message'?: string;
'data'?: {
'pageNumber'?: number;
'pageSize'?: number;
'totalRowCount'?: number;
'items'?: Array<GsJobScheduleType>;
};
}>> {

    const rb = new RequestBuilder(this.rootUrl, ApiService.ListGsJobScheduleTypesPath, 'post');
    if (params) {
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<{
        'isSuccess'?: boolean;
        'message'?: string;
        'data'?: {
        'pageNumber'?: number;
        'pageSize'?: number;
        'totalRowCount'?: number;
        'items'?: Array<GsJobScheduleType>;
        };
        }>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `listGsJobScheduleTypes$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  listGsJobScheduleTypes(params?: {
  }): Observable<{
'isSuccess'?: boolean;
'message'?: string;
'data'?: {
'pageNumber'?: number;
'pageSize'?: number;
'totalRowCount'?: number;
'items'?: Array<GsJobScheduleType>;
};
}> {

    return this.listGsJobScheduleTypes$Response(params).pipe(
      map((r: StrictHttpResponse<{
'isSuccess'?: boolean;
'message'?: string;
'data'?: {
'pageNumber'?: number;
'pageSize'?: number;
'totalRowCount'?: number;
'items'?: Array<GsJobScheduleType>;
};
}>) => r.body as {
'isSuccess'?: boolean;
'message'?: string;
'data'?: {
'pageNumber'?: number;
'pageSize'?: number;
'totalRowCount'?: number;
'items'?: Array<GsJobScheduleType>;
};
})
    );
  }

  /**
   * Path part for operation listJobHolidayAdjustments
   */
  static readonly ListJobHolidayAdjustmentsPath = '/v1/gsJob/list/jobHolidayAdjustment';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `listJobHolidayAdjustments()` instead.
   *
   * This method doesn't expect any request body.
   */
  listJobHolidayAdjustments$Response(params?: {
  }): Observable<StrictHttpResponse<{
'isSuccess'?: boolean;
'message'?: string;
'data'?: {
'pageNumber'?: number;
'pageSize'?: number;
'totalRowCount'?: number;
'items'?: Array<GsJobScheduleHolidayAdjustment>;
};
}>> {

    const rb = new RequestBuilder(this.rootUrl, ApiService.ListJobHolidayAdjustmentsPath, 'post');
    if (params) {
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<{
        'isSuccess'?: boolean;
        'message'?: string;
        'data'?: {
        'pageNumber'?: number;
        'pageSize'?: number;
        'totalRowCount'?: number;
        'items'?: Array<GsJobScheduleHolidayAdjustment>;
        };
        }>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `listJobHolidayAdjustments$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  listJobHolidayAdjustments(params?: {
  }): Observable<{
'isSuccess'?: boolean;
'message'?: string;
'data'?: {
'pageNumber'?: number;
'pageSize'?: number;
'totalRowCount'?: number;
'items'?: Array<GsJobScheduleHolidayAdjustment>;
};
}> {

    return this.listJobHolidayAdjustments$Response(params).pipe(
      map((r: StrictHttpResponse<{
'isSuccess'?: boolean;
'message'?: string;
'data'?: {
'pageNumber'?: number;
'pageSize'?: number;
'totalRowCount'?: number;
'items'?: Array<GsJobScheduleHolidayAdjustment>;
};
}>) => r.body as {
'isSuccess'?: boolean;
'message'?: string;
'data'?: {
'pageNumber'?: number;
'pageSize'?: number;
'totalRowCount'?: number;
'items'?: Array<GsJobScheduleHolidayAdjustment>;
};
})
    );
  }

  /**
   * Path part for operation listGsScheduleDowTypes
   */
  static readonly ListGsScheduleDowTypesPath = '/v1/gsJob/list/gsScheduleDOWType';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `listGsScheduleDowTypes()` instead.
   *
   * This method doesn't expect any request body.
   */
  listGsScheduleDowTypes$Response(params?: {
  }): Observable<StrictHttpResponse<{
'isSuccess'?: boolean;
'message'?: string;
'data'?: {
'pageNumber'?: number;
'pageSize'?: number;
'totalRowCount'?: number;
'items'?: Array<GsScheduleDowType>;
};
}>> {

    const rb = new RequestBuilder(this.rootUrl, ApiService.ListGsScheduleDowTypesPath, 'post');
    if (params) {
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<{
        'isSuccess'?: boolean;
        'message'?: string;
        'data'?: {
        'pageNumber'?: number;
        'pageSize'?: number;
        'totalRowCount'?: number;
        'items'?: Array<GsScheduleDowType>;
        };
        }>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `listGsScheduleDowTypes$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  listGsScheduleDowTypes(params?: {
  }): Observable<{
'isSuccess'?: boolean;
'message'?: string;
'data'?: {
'pageNumber'?: number;
'pageSize'?: number;
'totalRowCount'?: number;
'items'?: Array<GsScheduleDowType>;
};
}> {

    return this.listGsScheduleDowTypes$Response(params).pipe(
      map((r: StrictHttpResponse<{
'isSuccess'?: boolean;
'message'?: string;
'data'?: {
'pageNumber'?: number;
'pageSize'?: number;
'totalRowCount'?: number;
'items'?: Array<GsScheduleDowType>;
};
}>) => r.body as {
'isSuccess'?: boolean;
'message'?: string;
'data'?: {
'pageNumber'?: number;
'pageSize'?: number;
'totalRowCount'?: number;
'items'?: Array<GsScheduleDowType>;
};
})
    );
  }

  /**
   * Path part for operation gsJobAdd
   */
  static readonly GsJobAddPath = '/v1/gsJob/add/gsJob';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `gsJobAdd()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  gsJobAdd$Response(params: {
    body: GsJobItem
  }): Observable<StrictHttpResponse<{
'isSuccess'?: boolean;
'message'?: string;
'data'?: string;
}>> {

    const rb = new RequestBuilder(this.rootUrl, ApiService.GsJobAddPath, 'post');
    if (params) {
      rb.body(params.body, 'application/json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<{
        'isSuccess'?: boolean;
        'message'?: string;
        'data'?: string;
        }>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `gsJobAdd$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  gsJobAdd(params: {
    body: GsJobItem
  }): Observable<{
'isSuccess'?: boolean;
'message'?: string;
'data'?: string;
}> {

    return this.gsJobAdd$Response(params).pipe(
      map((r: StrictHttpResponse<{
'isSuccess'?: boolean;
'message'?: string;
'data'?: string;
}>) => r.body as {
'isSuccess'?: boolean;
'message'?: string;
'data'?: string;
})
    );
  }

  /**
   * Path part for operation gsJobTemplateAdd
   */
  static readonly GsJobTemplateAddPath = '/v1/gsJob/add/gsNotificationTemplate';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `gsJobTemplateAdd()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  gsJobTemplateAdd$Response(params: {
    body: GsJobNotificationTemplate
  }): Observable<StrictHttpResponse<{
'isSuccess'?: boolean;
'message'?: string;
'data'?: string;
}>> {

    const rb = new RequestBuilder(this.rootUrl, ApiService.GsJobTemplateAddPath, 'post');
    if (params) {
      rb.body(params.body, 'application/json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<{
        'isSuccess'?: boolean;
        'message'?: string;
        'data'?: string;
        }>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `gsJobTemplateAdd$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  gsJobTemplateAdd(params: {
    body: GsJobNotificationTemplate
  }): Observable<{
'isSuccess'?: boolean;
'message'?: string;
'data'?: string;
}> {

    return this.gsJobTemplateAdd$Response(params).pipe(
      map((r: StrictHttpResponse<{
'isSuccess'?: boolean;
'message'?: string;
'data'?: string;
}>) => r.body as {
'isSuccess'?: boolean;
'message'?: string;
'data'?: string;
})
    );
  }

  /**
   * Path part for operation gsJobTypeAdd
   */
  static readonly GsJobTypeAddPath = '/v1/gsJob/add/gsJobType';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `gsJobTypeAdd()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  gsJobTypeAdd$Response(params: {
    body: GsJobTypeDetail
  }): Observable<StrictHttpResponse<{
'isSuccess'?: boolean;
'message'?: string;
'data'?: string;
}>> {

    const rb = new RequestBuilder(this.rootUrl, ApiService.GsJobTypeAddPath, 'post');
    if (params) {
      rb.body(params.body, 'application/json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<{
        'isSuccess'?: boolean;
        'message'?: string;
        'data'?: string;
        }>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `gsJobTypeAdd$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  gsJobTypeAdd(params: {
    body: GsJobTypeDetail
  }): Observable<{
'isSuccess'?: boolean;
'message'?: string;
'data'?: string;
}> {

    return this.gsJobTypeAdd$Response(params).pipe(
      map((r: StrictHttpResponse<{
'isSuccess'?: boolean;
'message'?: string;
'data'?: string;
}>) => r.body as {
'isSuccess'?: boolean;
'message'?: string;
'data'?: string;
})
    );
  }

  /**
   * Path part for operation listParameterTypes
   */
  static readonly ListParameterTypesPath = '/v1/gsJob/list/gsParameterType';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `listParameterTypes()` instead.
   *
   * This method doesn't expect any request body.
   */
  listParameterTypes$Response(params?: {
  }): Observable<StrictHttpResponse<{
'isSuccess'?: boolean;
'message'?: string;
'data'?: {
'pageNumber'?: number;
'pageSize'?: number;
'totalRowCount'?: number;
'items'?: Array<GsParameterType>;
};
}>> {

    const rb = new RequestBuilder(this.rootUrl, ApiService.ListParameterTypesPath, 'post');
    if (params) {
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<{
        'isSuccess'?: boolean;
        'message'?: string;
        'data'?: {
        'pageNumber'?: number;
        'pageSize'?: number;
        'totalRowCount'?: number;
        'items'?: Array<GsParameterType>;
        };
        }>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `listParameterTypes$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  listParameterTypes(params?: {
  }): Observable<{
'isSuccess'?: boolean;
'message'?: string;
'data'?: {
'pageNumber'?: number;
'pageSize'?: number;
'totalRowCount'?: number;
'items'?: Array<GsParameterType>;
};
}> {

    return this.listParameterTypes$Response(params).pipe(
      map((r: StrictHttpResponse<{
'isSuccess'?: boolean;
'message'?: string;
'data'?: {
'pageNumber'?: number;
'pageSize'?: number;
'totalRowCount'?: number;
'items'?: Array<GsParameterType>;
};
}>) => r.body as {
'isSuccess'?: boolean;
'message'?: string;
'data'?: {
'pageNumber'?: number;
'pageSize'?: number;
'totalRowCount'?: number;
'items'?: Array<GsParameterType>;
};
})
    );
  }

  /**
   * Path part for operation listGsJobCategories
   */
  static readonly ListGsJobCategoriesPath = '/v1/gsJob/list/gsJobCategory';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `listGsJobCategories()` instead.
   *
   * This method doesn't expect any request body.
   */
  listGsJobCategories$Response(params?: {
  }): Observable<StrictHttpResponse<{
'isSuccess'?: boolean;
'message'?: string;
'data'?: {
'pageNumber'?: number;
'pageSize'?: number;
'totalRowCount'?: number;
'items'?: Array<GsJobCategory>;
};
}>> {

    const rb = new RequestBuilder(this.rootUrl, ApiService.ListGsJobCategoriesPath, 'post');
    if (params) {
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<{
        'isSuccess'?: boolean;
        'message'?: string;
        'data'?: {
        'pageNumber'?: number;
        'pageSize'?: number;
        'totalRowCount'?: number;
        'items'?: Array<GsJobCategory>;
        };
        }>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `listGsJobCategories$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  listGsJobCategories(params?: {
  }): Observable<{
'isSuccess'?: boolean;
'message'?: string;
'data'?: {
'pageNumber'?: number;
'pageSize'?: number;
'totalRowCount'?: number;
'items'?: Array<GsJobCategory>;
};
}> {

    return this.listGsJobCategories$Response(params).pipe(
      map((r: StrictHttpResponse<{
'isSuccess'?: boolean;
'message'?: string;
'data'?: {
'pageNumber'?: number;
'pageSize'?: number;
'totalRowCount'?: number;
'items'?: Array<GsJobCategory>;
};
}>) => r.body as {
'isSuccess'?: boolean;
'message'?: string;
'data'?: {
'pageNumber'?: number;
'pageSize'?: number;
'totalRowCount'?: number;
'items'?: Array<GsJobCategory>;
};
})
    );
  }

  /**
   * Path part for operation listGsJobNotificationTemplates
   */
  static readonly ListGsJobNotificationTemplatesPath = '/v1/gsJob/list/gsJobNotificationTemplate';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `listGsJobNotificationTemplates()` instead.
   *
   * This method doesn't expect any request body.
   */
  listGsJobNotificationTemplates$Response(params?: {
  }): Observable<StrictHttpResponse<{
'isSuccess'?: boolean;
'message'?: string;
'data'?: {
'pageNumber'?: number;
'pageSize'?: number;
'totalRowCount'?: number;
'items'?: Array<GsJobNotificationTemplate>;
};
}>> {

    const rb = new RequestBuilder(this.rootUrl, ApiService.ListGsJobNotificationTemplatesPath, 'post');
    if (params) {
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<{
        'isSuccess'?: boolean;
        'message'?: string;
        'data'?: {
        'pageNumber'?: number;
        'pageSize'?: number;
        'totalRowCount'?: number;
        'items'?: Array<GsJobNotificationTemplate>;
        };
        }>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `listGsJobNotificationTemplates$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  listGsJobNotificationTemplates(params?: {
  }): Observable<{
'isSuccess'?: boolean;
'message'?: string;
'data'?: {
'pageNumber'?: number;
'pageSize'?: number;
'totalRowCount'?: number;
'items'?: Array<GsJobNotificationTemplate>;
};
}> {

    return this.listGsJobNotificationTemplates$Response(params).pipe(
      map((r: StrictHttpResponse<{
'isSuccess'?: boolean;
'message'?: string;
'data'?: {
'pageNumber'?: number;
'pageSize'?: number;
'totalRowCount'?: number;
'items'?: Array<GsJobNotificationTemplate>;
};
}>) => r.body as {
'isSuccess'?: boolean;
'message'?: string;
'data'?: {
'pageNumber'?: number;
'pageSize'?: number;
'totalRowCount'?: number;
'items'?: Array<GsJobNotificationTemplate>;
};
})
    );
  }

  /**
   * Path part for operation listGsJobNotificationTemplateTypes
   */
  static readonly ListGsJobNotificationTemplateTypesPath = '/v1/gsJob/list/gsJobNotificationTemplateType';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `listGsJobNotificationTemplateTypes()` instead.
   *
   * This method doesn't expect any request body.
   */
  listGsJobNotificationTemplateTypes$Response(params?: {
  }): Observable<StrictHttpResponse<{
'isSuccess'?: boolean;
'message'?: string;
'data'?: {
'pageNumber'?: number;
'pageSize'?: number;
'totalRowCount'?: number;
'items'?: Array<GsJobNotificationTemplateType>;
};
}>> {

    const rb = new RequestBuilder(this.rootUrl, ApiService.ListGsJobNotificationTemplateTypesPath, 'post');
    if (params) {
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<{
        'isSuccess'?: boolean;
        'message'?: string;
        'data'?: {
        'pageNumber'?: number;
        'pageSize'?: number;
        'totalRowCount'?: number;
        'items'?: Array<GsJobNotificationTemplateType>;
        };
        }>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `listGsJobNotificationTemplateTypes$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  listGsJobNotificationTemplateTypes(params?: {
  }): Observable<{
'isSuccess'?: boolean;
'message'?: string;
'data'?: {
'pageNumber'?: number;
'pageSize'?: number;
'totalRowCount'?: number;
'items'?: Array<GsJobNotificationTemplateType>;
};
}> {

    return this.listGsJobNotificationTemplateTypes$Response(params).pipe(
      map((r: StrictHttpResponse<{
'isSuccess'?: boolean;
'message'?: string;
'data'?: {
'pageNumber'?: number;
'pageSize'?: number;
'totalRowCount'?: number;
'items'?: Array<GsJobNotificationTemplateType>;
};
}>) => r.body as {
'isSuccess'?: boolean;
'message'?: string;
'data'?: {
'pageNumber'?: number;
'pageSize'?: number;
'totalRowCount'?: number;
'items'?: Array<GsJobNotificationTemplateType>;
};
})
    );
  }

  /**
   * Path part for operation viewJobDetails
   */
  static readonly ViewJobDetailsPath = '/v1/gsJob/view/jobDetails';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `viewJobDetails()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  viewJobDetails$Response(params: {
    body: GsJobRequest
  }): Observable<StrictHttpResponse<{
'isSuccess'?: boolean;
'message'?: string;
'data'?: GsJobItem;
}>> {

    const rb = new RequestBuilder(this.rootUrl, ApiService.ViewJobDetailsPath, 'post');
    if (params) {
      rb.body(params.body, 'application/json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<{
        'isSuccess'?: boolean;
        'message'?: string;
        'data'?: GsJobItem;
        }>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `viewJobDetails$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  viewJobDetails(params: {
    body: GsJobRequest
  }): Observable<{
'isSuccess'?: boolean;
'message'?: string;
'data'?: GsJobItem;
}> {

    return this.viewJobDetails$Response(params).pipe(
      map((r: StrictHttpResponse<{
'isSuccess'?: boolean;
'message'?: string;
'data'?: GsJobItem;
}>) => r.body as {
'isSuccess'?: boolean;
'message'?: string;
'data'?: GsJobItem;
})
    );
  }

  /**
   * Path part for operation viewJobTypeDetails
   */
  static readonly ViewJobTypeDetailsPath = '/v1/gsJob/view/jobTypeDetails';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `viewJobTypeDetails()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  viewJobTypeDetails$Response(params: {
    body: GsJobTypeRequest
  }): Observable<StrictHttpResponse<{
'isSuccess'?: boolean;
'message'?: string;
'data'?: GsJobTypeDetail;
}>> {

    const rb = new RequestBuilder(this.rootUrl, ApiService.ViewJobTypeDetailsPath, 'post');
    if (params) {
      rb.body(params.body, 'application/json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<{
        'isSuccess'?: boolean;
        'message'?: string;
        'data'?: GsJobTypeDetail;
        }>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `viewJobTypeDetails$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  viewJobTypeDetails(params: {
    body: GsJobTypeRequest
  }): Observable<{
'isSuccess'?: boolean;
'message'?: string;
'data'?: GsJobTypeDetail;
}> {

    return this.viewJobTypeDetails$Response(params).pipe(
      map((r: StrictHttpResponse<{
'isSuccess'?: boolean;
'message'?: string;
'data'?: GsJobTypeDetail;
}>) => r.body as {
'isSuccess'?: boolean;
'message'?: string;
'data'?: GsJobTypeDetail;
})
    );
  }

  /**
   * Path part for operation getGoldstarEmailHistory
   */
  static readonly GetGoldstarEmailHistoryPath = '/v1/gsJob/getGoldstarEmailHistory';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getGoldstarEmailHistory()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  getGoldstarEmailHistory$Response(params: {
    body: EntityGetRequest
  }): Observable<StrictHttpResponse<{
'isSuccess'?: boolean;
'message'?: string;
'data'?: {
'pageNumber'?: number;
'pageSize'?: number;
'totalRowCount'?: number;
'items'?: Array<GoldstarEmail>;
};
}>> {

    const rb = new RequestBuilder(this.rootUrl, ApiService.GetGoldstarEmailHistoryPath, 'post');
    if (params) {
      rb.body(params.body, 'application/json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<{
        'isSuccess'?: boolean;
        'message'?: string;
        'data'?: {
        'pageNumber'?: number;
        'pageSize'?: number;
        'totalRowCount'?: number;
        'items'?: Array<GoldstarEmail>;
        };
        }>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `getGoldstarEmailHistory$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  getGoldstarEmailHistory(params: {
    body: EntityGetRequest
  }): Observable<{
'isSuccess'?: boolean;
'message'?: string;
'data'?: {
'pageNumber'?: number;
'pageSize'?: number;
'totalRowCount'?: number;
'items'?: Array<GoldstarEmail>;
};
}> {

    return this.getGoldstarEmailHistory$Response(params).pipe(
      map((r: StrictHttpResponse<{
'isSuccess'?: boolean;
'message'?: string;
'data'?: {
'pageNumber'?: number;
'pageSize'?: number;
'totalRowCount'?: number;
'items'?: Array<GoldstarEmail>;
};
}>) => r.body as {
'isSuccess'?: boolean;
'message'?: string;
'data'?: {
'pageNumber'?: number;
'pageSize'?: number;
'totalRowCount'?: number;
'items'?: Array<GoldstarEmail>;
};
})
    );
  }

  /**
   * Path part for operation getGoldstarEmailStatus
   */
  static readonly GetGoldstarEmailStatusPath = '/v1/gsJob/getGoldstarEmailStatus';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getGoldstarEmailStatus()` instead.
   *
   * This method doesn't expect any request body.
   */
  getGoldstarEmailStatus$Response(params?: {
  }): Observable<StrictHttpResponse<{
'isSuccess'?: boolean;
'message'?: string;
'data'?: {
'pageNumber'?: number;
'pageSize'?: number;
'totalRowCount'?: number;
'items'?: Array<GoldstarEmailStatus>;
};
}>> {

    const rb = new RequestBuilder(this.rootUrl, ApiService.GetGoldstarEmailStatusPath, 'post');
    if (params) {
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<{
        'isSuccess'?: boolean;
        'message'?: string;
        'data'?: {
        'pageNumber'?: number;
        'pageSize'?: number;
        'totalRowCount'?: number;
        'items'?: Array<GoldstarEmailStatus>;
        };
        }>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `getGoldstarEmailStatus$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getGoldstarEmailStatus(params?: {
  }): Observable<{
'isSuccess'?: boolean;
'message'?: string;
'data'?: {
'pageNumber'?: number;
'pageSize'?: number;
'totalRowCount'?: number;
'items'?: Array<GoldstarEmailStatus>;
};
}> {

    return this.getGoldstarEmailStatus$Response(params).pipe(
      map((r: StrictHttpResponse<{
'isSuccess'?: boolean;
'message'?: string;
'data'?: {
'pageNumber'?: number;
'pageSize'?: number;
'totalRowCount'?: number;
'items'?: Array<GoldstarEmailStatus>;
};
}>) => r.body as {
'isSuccess'?: boolean;
'message'?: string;
'data'?: {
'pageNumber'?: number;
'pageSize'?: number;
'totalRowCount'?: number;
'items'?: Array<GoldstarEmailStatus>;
};
})
    );
  }

  /**
   * Path part for operation getJobNotifications
   */
  static readonly GetJobNotificationsPath = '/v1/gsJob/getJobNotifications';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getJobNotifications()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  getJobNotifications$Response(params: {
    body: EntityGetRequest
  }): Observable<StrictHttpResponse<{
'isSuccess'?: boolean;
'message'?: string;
'data'?: {
'pageNumber'?: number;
'pageSize'?: number;
'totalRowCount'?: number;
'items'?: Array<GsJobNotification>;
};
}>> {

    const rb = new RequestBuilder(this.rootUrl, ApiService.GetJobNotificationsPath, 'post');
    if (params) {
      rb.body(params.body, 'application/json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<{
        'isSuccess'?: boolean;
        'message'?: string;
        'data'?: {
        'pageNumber'?: number;
        'pageSize'?: number;
        'totalRowCount'?: number;
        'items'?: Array<GsJobNotification>;
        };
        }>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `getJobNotifications$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  getJobNotifications(params: {
    body: EntityGetRequest
  }): Observable<{
'isSuccess'?: boolean;
'message'?: string;
'data'?: {
'pageNumber'?: number;
'pageSize'?: number;
'totalRowCount'?: number;
'items'?: Array<GsJobNotification>;
};
}> {

    return this.getJobNotifications$Response(params).pipe(
      map((r: StrictHttpResponse<{
'isSuccess'?: boolean;
'message'?: string;
'data'?: {
'pageNumber'?: number;
'pageSize'?: number;
'totalRowCount'?: number;
'items'?: Array<GsJobNotification>;
};
}>) => r.body as {
'isSuccess'?: boolean;
'message'?: string;
'data'?: {
'pageNumber'?: number;
'pageSize'?: number;
'totalRowCount'?: number;
'items'?: Array<GsJobNotification>;
};
})
    );
  }

  /**
   * Path part for operation getJobCategoryStatus
   */
  static readonly GetJobCategoryStatusPath = '/v1/gsJob/getJobCategoryStatus';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getJobCategoryStatus()` instead.
   *
   * This method doesn't expect any request body.
   */
  getJobCategoryStatus$Response(params?: {
  }): Observable<StrictHttpResponse<{
'isSuccess'?: boolean;
'message'?: string;
'data'?: {
'pageNumber'?: number;
'pageSize'?: number;
'totalRowCount'?: number;
'items'?: Array<GsJobHistory>;
};
}>> {

    const rb = new RequestBuilder(this.rootUrl, ApiService.GetJobCategoryStatusPath, 'post');
    if (params) {
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<{
        'isSuccess'?: boolean;
        'message'?: string;
        'data'?: {
        'pageNumber'?: number;
        'pageSize'?: number;
        'totalRowCount'?: number;
        'items'?: Array<GsJobHistory>;
        };
        }>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `getJobCategoryStatus$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getJobCategoryStatus(params?: {
  }): Observable<{
'isSuccess'?: boolean;
'message'?: string;
'data'?: {
'pageNumber'?: number;
'pageSize'?: number;
'totalRowCount'?: number;
'items'?: Array<GsJobHistory>;
};
}> {

    return this.getJobCategoryStatus$Response(params).pipe(
      map((r: StrictHttpResponse<{
'isSuccess'?: boolean;
'message'?: string;
'data'?: {
'pageNumber'?: number;
'pageSize'?: number;
'totalRowCount'?: number;
'items'?: Array<GsJobHistory>;
};
}>) => r.body as {
'isSuccess'?: boolean;
'message'?: string;
'data'?: {
'pageNumber'?: number;
'pageSize'?: number;
'totalRowCount'?: number;
'items'?: Array<GsJobHistory>;
};
})
    );
  }

  /**
   * Path part for operation runGsJob
   */
  static readonly RunGsJobPath = '/v1/gsJob/runGsJob/{gsJobGUID}';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `runGsJob()` instead.
   *
   * This method doesn't expect any request body.
   */
  runGsJob$Response(params: {
    gsJobGUID: string;
  }): Observable<StrictHttpResponse<{
'isSuccess'?: boolean;
'message'?: string;
'data'?: string;
}>> {

    const rb = new RequestBuilder(this.rootUrl, ApiService.RunGsJobPath, 'post');
    if (params) {
      rb.path('gsJobGUID', params.gsJobGUID, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<{
        'isSuccess'?: boolean;
        'message'?: string;
        'data'?: string;
        }>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `runGsJob$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  runGsJob(params: {
    gsJobGUID: string;
  }): Observable<{
'isSuccess'?: boolean;
'message'?: string;
'data'?: string;
}> {

    return this.runGsJob$Response(params).pipe(
      map((r: StrictHttpResponse<{
'isSuccess'?: boolean;
'message'?: string;
'data'?: string;
}>) => r.body as {
'isSuccess'?: boolean;
'message'?: string;
'data'?: string;
})
    );
  }

  /**
   * Path part for operation listJobTypeParameter
   */
  static readonly ListJobTypeParameterPath = '/v1/gsJob/list/gsJobTypeParameter';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `listJobTypeParameter()` instead.
   *
   * This method doesn't expect any request body.
   */
  listJobTypeParameter$Response(params?: {
  }): Observable<StrictHttpResponse<{
'isSuccess'?: boolean;
'message'?: string;
'data'?: {
'pageNumber'?: number;
'pageSize'?: number;
'totalRowCount'?: number;
'items'?: Array<GsJobParameterItem>;
};
}>> {

    const rb = new RequestBuilder(this.rootUrl, ApiService.ListJobTypeParameterPath, 'post');
    if (params) {
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<{
        'isSuccess'?: boolean;
        'message'?: string;
        'data'?: {
        'pageNumber'?: number;
        'pageSize'?: number;
        'totalRowCount'?: number;
        'items'?: Array<GsJobParameterItem>;
        };
        }>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `listJobTypeParameter$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  listJobTypeParameter(params?: {
  }): Observable<{
'isSuccess'?: boolean;
'message'?: string;
'data'?: {
'pageNumber'?: number;
'pageSize'?: number;
'totalRowCount'?: number;
'items'?: Array<GsJobParameterItem>;
};
}> {

    return this.listJobTypeParameter$Response(params).pipe(
      map((r: StrictHttpResponse<{
'isSuccess'?: boolean;
'message'?: string;
'data'?: {
'pageNumber'?: number;
'pageSize'?: number;
'totalRowCount'?: number;
'items'?: Array<GsJobParameterItem>;
};
}>) => r.body as {
'isSuccess'?: boolean;
'message'?: string;
'data'?: {
'pageNumber'?: number;
'pageSize'?: number;
'totalRowCount'?: number;
'items'?: Array<GsJobParameterItem>;
};
})
    );
  }

  /**
   * Path part for operation reProcessOldFiles
   */
  static readonly ReProcessOldFilesPath = '/v1/gsJob/reProcessOldFiles/{gsJobGUID}';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `reProcessOldFiles()` instead.
   *
   * This method doesn't expect any request body.
   */
  reProcessOldFiles$Response(params: {
    gsJobGUID: string;
  }): Observable<StrictHttpResponse<{
'isSuccess'?: boolean;
'message'?: string;
'data'?: string;
}>> {

    const rb = new RequestBuilder(this.rootUrl, ApiService.ReProcessOldFilesPath, 'post');
    if (params) {
      rb.path('gsJobGUID', params.gsJobGUID, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<{
        'isSuccess'?: boolean;
        'message'?: string;
        'data'?: string;
        }>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `reProcessOldFiles$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  reProcessOldFiles(params: {
    gsJobGUID: string;
  }): Observable<{
'isSuccess'?: boolean;
'message'?: string;
'data'?: string;
}> {

    return this.reProcessOldFiles$Response(params).pipe(
      map((r: StrictHttpResponse<{
'isSuccess'?: boolean;
'message'?: string;
'data'?: string;
}>) => r.body as {
'isSuccess'?: boolean;
'message'?: string;
'data'?: string;
})
    );
  }

  /**
   * Path part for operation listGsJobMaintenance
   */
  static readonly ListGsJobMaintenancePath = '/v1/gsJob/list/jobMaintenance';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `listGsJobMaintenance()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  listGsJobMaintenance$Response(params: {
    body: EntityGetRequest
  }): Observable<StrictHttpResponse<{
'isSuccess'?: boolean;
'message'?: string;
'data'?: {
'pageNumber'?: number;
'pageSize'?: number;
'totalRowCount'?: number;
'items'?: Array<GsJob>;
};
}>> {

    const rb = new RequestBuilder(this.rootUrl, ApiService.ListGsJobMaintenancePath, 'post');
    if (params) {
      rb.body(params.body, 'application/json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<{
        'isSuccess'?: boolean;
        'message'?: string;
        'data'?: {
        'pageNumber'?: number;
        'pageSize'?: number;
        'totalRowCount'?: number;
        'items'?: Array<GsJob>;
        };
        }>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `listGsJobMaintenance$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  listGsJobMaintenance(params: {
    body: EntityGetRequest
  }): Observable<{
'isSuccess'?: boolean;
'message'?: string;
'data'?: {
'pageNumber'?: number;
'pageSize'?: number;
'totalRowCount'?: number;
'items'?: Array<GsJob>;
};
}> {

    return this.listGsJobMaintenance$Response(params).pipe(
      map((r: StrictHttpResponse<{
'isSuccess'?: boolean;
'message'?: string;
'data'?: {
'pageNumber'?: number;
'pageSize'?: number;
'totalRowCount'?: number;
'items'?: Array<GsJob>;
};
}>) => r.body as {
'isSuccess'?: boolean;
'message'?: string;
'data'?: {
'pageNumber'?: number;
'pageSize'?: number;
'totalRowCount'?: number;
'items'?: Array<GsJob>;
};
})
    );
  }

  /**
   * Path part for operation marketingServiceDeskCategoryList
   */
  static readonly MarketingServiceDeskCategoryListPath = '/v1/serviceDesk/list/marketingServiceDeskCategoryList';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `marketingServiceDeskCategoryList()` instead.
   *
   * This method doesn't expect any request body.
   */
  marketingServiceDeskCategoryList$Response(params?: {
  }): Observable<StrictHttpResponse<{
'isSuccess'?: boolean;
'message'?: string;
'data'?: {
'pageNumber'?: number;
'pageSize'?: number;
'totalRowCount'?: number;
'items'?: Array<MarketingServiceDeskCategoryType>;
};
}>> {

    const rb = new RequestBuilder(this.rootUrl, ApiService.MarketingServiceDeskCategoryListPath, 'post');
    if (params) {
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<{
        'isSuccess'?: boolean;
        'message'?: string;
        'data'?: {
        'pageNumber'?: number;
        'pageSize'?: number;
        'totalRowCount'?: number;
        'items'?: Array<MarketingServiceDeskCategoryType>;
        };
        }>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `marketingServiceDeskCategoryList$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  marketingServiceDeskCategoryList(params?: {
  }): Observable<{
'isSuccess'?: boolean;
'message'?: string;
'data'?: {
'pageNumber'?: number;
'pageSize'?: number;
'totalRowCount'?: number;
'items'?: Array<MarketingServiceDeskCategoryType>;
};
}> {

    return this.marketingServiceDeskCategoryList$Response(params).pipe(
      map((r: StrictHttpResponse<{
'isSuccess'?: boolean;
'message'?: string;
'data'?: {
'pageNumber'?: number;
'pageSize'?: number;
'totalRowCount'?: number;
'items'?: Array<MarketingServiceDeskCategoryType>;
};
}>) => r.body as {
'isSuccess'?: boolean;
'message'?: string;
'data'?: {
'pageNumber'?: number;
'pageSize'?: number;
'totalRowCount'?: number;
'items'?: Array<MarketingServiceDeskCategoryType>;
};
})
    );
  }

  /**
   * Path part for operation marketingServiceDeskTicketSave
   */
  static readonly MarketingServiceDeskTicketSavePath = '/v1/serviceDesk/save/marketingServiceDeskTicket';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `marketingServiceDeskTicketSave()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  marketingServiceDeskTicketSave$Response(params: {
    body: MarketingServiceDeskTicketRequest
  }): Observable<StrictHttpResponse<{
'isSuccess'?: boolean;
'message'?: string;
'data'?: string;
}>> {

    const rb = new RequestBuilder(this.rootUrl, ApiService.MarketingServiceDeskTicketSavePath, 'post');
    if (params) {
      rb.body(params.body, 'application/json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<{
        'isSuccess'?: boolean;
        'message'?: string;
        'data'?: string;
        }>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `marketingServiceDeskTicketSave$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  marketingServiceDeskTicketSave(params: {
    body: MarketingServiceDeskTicketRequest
  }): Observable<{
'isSuccess'?: boolean;
'message'?: string;
'data'?: string;
}> {

    return this.marketingServiceDeskTicketSave$Response(params).pipe(
      map((r: StrictHttpResponse<{
'isSuccess'?: boolean;
'message'?: string;
'data'?: string;
}>) => r.body as {
'isSuccess'?: boolean;
'message'?: string;
'data'?: string;
})
    );
  }

  /**
   * Path part for operation facilitiesCategoryList
   */
  static readonly FacilitiesCategoryListPath = '/v1/serviceDesk/list/facilitiesServiceDeskCategoryType';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `facilitiesCategoryList()` instead.
   *
   * This method doesn't expect any request body.
   */
  facilitiesCategoryList$Response(params?: {
  }): Observable<StrictHttpResponse<{
'isSuccess'?: boolean;
'message'?: string;
'data'?: {
'pageNumber'?: number;
'pageSize'?: number;
'totalRowCount'?: number;
'items'?: Array<FacilitiesServiceDeskCategoryType>;
};
}>> {

    const rb = new RequestBuilder(this.rootUrl, ApiService.FacilitiesCategoryListPath, 'post');
    if (params) {
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<{
        'isSuccess'?: boolean;
        'message'?: string;
        'data'?: {
        'pageNumber'?: number;
        'pageSize'?: number;
        'totalRowCount'?: number;
        'items'?: Array<FacilitiesServiceDeskCategoryType>;
        };
        }>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `facilitiesCategoryList$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  facilitiesCategoryList(params?: {
  }): Observable<{
'isSuccess'?: boolean;
'message'?: string;
'data'?: {
'pageNumber'?: number;
'pageSize'?: number;
'totalRowCount'?: number;
'items'?: Array<FacilitiesServiceDeskCategoryType>;
};
}> {

    return this.facilitiesCategoryList$Response(params).pipe(
      map((r: StrictHttpResponse<{
'isSuccess'?: boolean;
'message'?: string;
'data'?: {
'pageNumber'?: number;
'pageSize'?: number;
'totalRowCount'?: number;
'items'?: Array<FacilitiesServiceDeskCategoryType>;
};
}>) => r.body as {
'isSuccess'?: boolean;
'message'?: string;
'data'?: {
'pageNumber'?: number;
'pageSize'?: number;
'totalRowCount'?: number;
'items'?: Array<FacilitiesServiceDeskCategoryType>;
};
})
    );
  }

  /**
   * Path part for operation facilitiesServiceDeskTicketSave
   */
  static readonly FacilitiesServiceDeskTicketSavePath = '/v1/serviceDesk/save/facilitiesServiceDeskTicket';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `facilitiesServiceDeskTicketSave()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  facilitiesServiceDeskTicketSave$Response(params: {
    body: FacilitiesServiceDeskTicketRequest
  }): Observable<StrictHttpResponse<{
'isSuccess'?: boolean;
'message'?: string;
'data'?: string;
}>> {

    const rb = new RequestBuilder(this.rootUrl, ApiService.FacilitiesServiceDeskTicketSavePath, 'post');
    if (params) {
      rb.body(params.body, 'application/json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<{
        'isSuccess'?: boolean;
        'message'?: string;
        'data'?: string;
        }>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `facilitiesServiceDeskTicketSave$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  facilitiesServiceDeskTicketSave(params: {
    body: FacilitiesServiceDeskTicketRequest
  }): Observable<{
'isSuccess'?: boolean;
'message'?: string;
'data'?: string;
}> {

    return this.facilitiesServiceDeskTicketSave$Response(params).pipe(
      map((r: StrictHttpResponse<{
'isSuccess'?: boolean;
'message'?: string;
'data'?: string;
}>) => r.body as {
'isSuccess'?: boolean;
'message'?: string;
'data'?: string;
})
    );
  }

  /**
   * Path part for operation facilitiesList
   */
  static readonly FacilitiesListPath = '/v1/serviceDesk/list/facilities';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `facilitiesList()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  facilitiesList$Response(params: {
    body: FetchServiceDeskRequest
  }): Observable<StrictHttpResponse<{
'isSuccess'?: boolean;
'message'?: string;
'data'?: {
'pageNumber'?: number;
'pageSize'?: number;
'totalRowCount'?: number;
'items'?: Array<FacilitiesServiceItem>;
};
}>> {

    const rb = new RequestBuilder(this.rootUrl, ApiService.FacilitiesListPath, 'post');
    if (params) {
      rb.body(params.body, 'application/json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<{
        'isSuccess'?: boolean;
        'message'?: string;
        'data'?: {
        'pageNumber'?: number;
        'pageSize'?: number;
        'totalRowCount'?: number;
        'items'?: Array<FacilitiesServiceItem>;
        };
        }>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `facilitiesList$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  facilitiesList(params: {
    body: FetchServiceDeskRequest
  }): Observable<{
'isSuccess'?: boolean;
'message'?: string;
'data'?: {
'pageNumber'?: number;
'pageSize'?: number;
'totalRowCount'?: number;
'items'?: Array<FacilitiesServiceItem>;
};
}> {

    return this.facilitiesList$Response(params).pipe(
      map((r: StrictHttpResponse<{
'isSuccess'?: boolean;
'message'?: string;
'data'?: {
'pageNumber'?: number;
'pageSize'?: number;
'totalRowCount'?: number;
'items'?: Array<FacilitiesServiceItem>;
};
}>) => r.body as {
'isSuccess'?: boolean;
'message'?: string;
'data'?: {
'pageNumber'?: number;
'pageSize'?: number;
'totalRowCount'?: number;
'items'?: Array<FacilitiesServiceItem>;
};
})
    );
  }

  /**
   * Path part for operation serviceDeskTypeList
   */
  static readonly ServiceDeskTypeListPath = '/v1/serviceDesk/list/serviceDeskType';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `serviceDeskTypeList()` instead.
   *
   * This method doesn't expect any request body.
   */
  serviceDeskTypeList$Response(params?: {
  }): Observable<StrictHttpResponse<{
'isSuccess'?: boolean;
'message'?: string;
'data'?: {
'pageNumber'?: number;
'pageSize'?: number;
'totalRowCount'?: number;
'items'?: Array<ServiceDeskTypeItem>;
};
}>> {

    const rb = new RequestBuilder(this.rootUrl, ApiService.ServiceDeskTypeListPath, 'post');
    if (params) {
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<{
        'isSuccess'?: boolean;
        'message'?: string;
        'data'?: {
        'pageNumber'?: number;
        'pageSize'?: number;
        'totalRowCount'?: number;
        'items'?: Array<ServiceDeskTypeItem>;
        };
        }>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `serviceDeskTypeList$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  serviceDeskTypeList(params?: {
  }): Observable<{
'isSuccess'?: boolean;
'message'?: string;
'data'?: {
'pageNumber'?: number;
'pageSize'?: number;
'totalRowCount'?: number;
'items'?: Array<ServiceDeskTypeItem>;
};
}> {

    return this.serviceDeskTypeList$Response(params).pipe(
      map((r: StrictHttpResponse<{
'isSuccess'?: boolean;
'message'?: string;
'data'?: {
'pageNumber'?: number;
'pageSize'?: number;
'totalRowCount'?: number;
'items'?: Array<ServiceDeskTypeItem>;
};
}>) => r.body as {
'isSuccess'?: boolean;
'message'?: string;
'data'?: {
'pageNumber'?: number;
'pageSize'?: number;
'totalRowCount'?: number;
'items'?: Array<ServiceDeskTypeItem>;
};
})
    );
  }

  /**
   * Path part for operation branchList
   */
  static readonly BranchListPath = '/v1/serviceDesk/list/branchType';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `branchList()` instead.
   *
   * This method doesn't expect any request body.
   */
  branchList$Response(params?: {
  }): Observable<StrictHttpResponse<{
'isSuccess'?: boolean;
'message'?: string;
'data'?: {
'pageNumber'?: number;
'pageSize'?: number;
'totalRowCount'?: number;
'items'?: Array<BranchType>;
};
}>> {

    const rb = new RequestBuilder(this.rootUrl, ApiService.BranchListPath, 'post');
    if (params) {
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<{
        'isSuccess'?: boolean;
        'message'?: string;
        'data'?: {
        'pageNumber'?: number;
        'pageSize'?: number;
        'totalRowCount'?: number;
        'items'?: Array<BranchType>;
        };
        }>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `branchList$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  branchList(params?: {
  }): Observable<{
'isSuccess'?: boolean;
'message'?: string;
'data'?: {
'pageNumber'?: number;
'pageSize'?: number;
'totalRowCount'?: number;
'items'?: Array<BranchType>;
};
}> {

    return this.branchList$Response(params).pipe(
      map((r: StrictHttpResponse<{
'isSuccess'?: boolean;
'message'?: string;
'data'?: {
'pageNumber'?: number;
'pageSize'?: number;
'totalRowCount'?: number;
'items'?: Array<BranchType>;
};
}>) => r.body as {
'isSuccess'?: boolean;
'message'?: string;
'data'?: {
'pageNumber'?: number;
'pageSize'?: number;
'totalRowCount'?: number;
'items'?: Array<BranchType>;
};
})
    );
  }

  /**
   * Path part for operation marketingList
   */
  static readonly MarketingListPath = '/v1/serviceDesk/list/marketing';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `marketingList()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  marketingList$Response(params: {
    body: FetchServiceDeskRequest
  }): Observable<StrictHttpResponse<{
'isSuccess'?: boolean;
'message'?: string;
'data'?: {
'pageNumber'?: number;
'pageSize'?: number;
'totalRowCount'?: number;
'items'?: Array<MarketingServiceItem>;
};
}>> {

    const rb = new RequestBuilder(this.rootUrl, ApiService.MarketingListPath, 'post');
    if (params) {
      rb.body(params.body, 'application/json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<{
        'isSuccess'?: boolean;
        'message'?: string;
        'data'?: {
        'pageNumber'?: number;
        'pageSize'?: number;
        'totalRowCount'?: number;
        'items'?: Array<MarketingServiceItem>;
        };
        }>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `marketingList$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  marketingList(params: {
    body: FetchServiceDeskRequest
  }): Observable<{
'isSuccess'?: boolean;
'message'?: string;
'data'?: {
'pageNumber'?: number;
'pageSize'?: number;
'totalRowCount'?: number;
'items'?: Array<MarketingServiceItem>;
};
}> {

    return this.marketingList$Response(params).pipe(
      map((r: StrictHttpResponse<{
'isSuccess'?: boolean;
'message'?: string;
'data'?: {
'pageNumber'?: number;
'pageSize'?: number;
'totalRowCount'?: number;
'items'?: Array<MarketingServiceItem>;
};
}>) => r.body as {
'isSuccess'?: boolean;
'message'?: string;
'data'?: {
'pageNumber'?: number;
'pageSize'?: number;
'totalRowCount'?: number;
'items'?: Array<MarketingServiceItem>;
};
})
    );
  }

  /**
   * Path part for operation fetchServiceDeskTickets
   */
  static readonly FetchServiceDeskTicketsPath = '/v1/serviceDesk/list/user/tickets';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `fetchServiceDeskTickets()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  fetchServiceDeskTickets$Response(params: {
    body: ServiceDeskTicketsRequest
  }): Observable<StrictHttpResponse<{
'isSuccess'?: boolean;
'message'?: string;
'data'?: {
'pageNumber'?: number;
'pageSize'?: number;
'totalRowCount'?: number;
'items'?: Array<ServiceDeskTicketListItem>;
};
}>> {

    const rb = new RequestBuilder(this.rootUrl, ApiService.FetchServiceDeskTicketsPath, 'post');
    if (params) {
      rb.body(params.body, 'application/json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<{
        'isSuccess'?: boolean;
        'message'?: string;
        'data'?: {
        'pageNumber'?: number;
        'pageSize'?: number;
        'totalRowCount'?: number;
        'items'?: Array<ServiceDeskTicketListItem>;
        };
        }>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `fetchServiceDeskTickets$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  fetchServiceDeskTickets(params: {
    body: ServiceDeskTicketsRequest
  }): Observable<{
'isSuccess'?: boolean;
'message'?: string;
'data'?: {
'pageNumber'?: number;
'pageSize'?: number;
'totalRowCount'?: number;
'items'?: Array<ServiceDeskTicketListItem>;
};
}> {

    return this.fetchServiceDeskTickets$Response(params).pipe(
      map((r: StrictHttpResponse<{
'isSuccess'?: boolean;
'message'?: string;
'data'?: {
'pageNumber'?: number;
'pageSize'?: number;
'totalRowCount'?: number;
'items'?: Array<ServiceDeskTicketListItem>;
};
}>) => r.body as {
'isSuccess'?: boolean;
'message'?: string;
'data'?: {
'pageNumber'?: number;
'pageSize'?: number;
'totalRowCount'?: number;
'items'?: Array<ServiceDeskTicketListItem>;
};
})
    );
  }

  /**
   * Path part for operation serviceDeskStatusList
   */
  static readonly ServiceDeskStatusListPath = '/v1/serviceDesk/list/serviceDeskStatus';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `serviceDeskStatusList()` instead.
   *
   * This method doesn't expect any request body.
   */
  serviceDeskStatusList$Response(params?: {
  }): Observable<StrictHttpResponse<{
'isSuccess'?: boolean;
'message'?: string;
'data'?: {
'pageNumber'?: number;
'pageSize'?: number;
'totalRowCount'?: number;
'items'?: Array<ServiceDeskStatusItem>;
};
}>> {

    const rb = new RequestBuilder(this.rootUrl, ApiService.ServiceDeskStatusListPath, 'post');
    if (params) {
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<{
        'isSuccess'?: boolean;
        'message'?: string;
        'data'?: {
        'pageNumber'?: number;
        'pageSize'?: number;
        'totalRowCount'?: number;
        'items'?: Array<ServiceDeskStatusItem>;
        };
        }>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `serviceDeskStatusList$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  serviceDeskStatusList(params?: {
  }): Observable<{
'isSuccess'?: boolean;
'message'?: string;
'data'?: {
'pageNumber'?: number;
'pageSize'?: number;
'totalRowCount'?: number;
'items'?: Array<ServiceDeskStatusItem>;
};
}> {

    return this.serviceDeskStatusList$Response(params).pipe(
      map((r: StrictHttpResponse<{
'isSuccess'?: boolean;
'message'?: string;
'data'?: {
'pageNumber'?: number;
'pageSize'?: number;
'totalRowCount'?: number;
'items'?: Array<ServiceDeskStatusItem>;
};
}>) => r.body as {
'isSuccess'?: boolean;
'message'?: string;
'data'?: {
'pageNumber'?: number;
'pageSize'?: number;
'totalRowCount'?: number;
'items'?: Array<ServiceDeskStatusItem>;
};
})
    );
  }

  /**
   * Path part for operation assignServiceDeskToUser
   */
  static readonly AssignServiceDeskToUserPath = '/v1/serviceDesk/add/assignServiceDeskToUser';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `assignServiceDeskToUser()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  assignServiceDeskToUser$Response(params: {
    body: AssignServiceDeskTicketToUserRequest
  }): Observable<StrictHttpResponse<{
'isSuccess'?: boolean;
'message'?: string;
'data'?: string;
}>> {

    const rb = new RequestBuilder(this.rootUrl, ApiService.AssignServiceDeskToUserPath, 'post');
    if (params) {
      rb.body(params.body, 'application/json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<{
        'isSuccess'?: boolean;
        'message'?: string;
        'data'?: string;
        }>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `assignServiceDeskToUser$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  assignServiceDeskToUser(params: {
    body: AssignServiceDeskTicketToUserRequest
  }): Observable<{
'isSuccess'?: boolean;
'message'?: string;
'data'?: string;
}> {

    return this.assignServiceDeskToUser$Response(params).pipe(
      map((r: StrictHttpResponse<{
'isSuccess'?: boolean;
'message'?: string;
'data'?: string;
}>) => r.body as {
'isSuccess'?: boolean;
'message'?: string;
'data'?: string;
})
    );
  }

  /**
   * Path part for operation addServiceDeskChats
   */
  static readonly AddServiceDeskChatsPath = '/v1/serviceDesk/add/serviceDeskChats';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `addServiceDeskChats()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  addServiceDeskChats$Response(params: {
    body: ChatAddRequest
  }): Observable<StrictHttpResponse<{
'isSuccess'?: boolean;
'message'?: string;
'data'?: {
'pageNumber'?: number;
'pageSize'?: number;
'totalRowCount'?: number;
'items'?: Array<ChatModel>;
};
}>> {

    const rb = new RequestBuilder(this.rootUrl, ApiService.AddServiceDeskChatsPath, 'post');
    if (params) {
      rb.body(params.body, 'application/json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<{
        'isSuccess'?: boolean;
        'message'?: string;
        'data'?: {
        'pageNumber'?: number;
        'pageSize'?: number;
        'totalRowCount'?: number;
        'items'?: Array<ChatModel>;
        };
        }>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `addServiceDeskChats$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  addServiceDeskChats(params: {
    body: ChatAddRequest
  }): Observable<{
'isSuccess'?: boolean;
'message'?: string;
'data'?: {
'pageNumber'?: number;
'pageSize'?: number;
'totalRowCount'?: number;
'items'?: Array<ChatModel>;
};
}> {

    return this.addServiceDeskChats$Response(params).pipe(
      map((r: StrictHttpResponse<{
'isSuccess'?: boolean;
'message'?: string;
'data'?: {
'pageNumber'?: number;
'pageSize'?: number;
'totalRowCount'?: number;
'items'?: Array<ChatModel>;
};
}>) => r.body as {
'isSuccess'?: boolean;
'message'?: string;
'data'?: {
'pageNumber'?: number;
'pageSize'?: number;
'totalRowCount'?: number;
'items'?: Array<ChatModel>;
};
})
    );
  }

  /**
   * Path part for operation serviceDeskMaterialAdd
   */
  static readonly ServiceDeskMaterialAddPath = '/v1/serviceDesk/add/serviceDesk/material';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `serviceDeskMaterialAdd()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  serviceDeskMaterialAdd$Response(params: {
    body: ServiceDeskMaterialAddRequest
  }): Observable<StrictHttpResponse<{
'isSuccess'?: boolean;
'message'?: string;
'data'?: string;
}>> {

    const rb = new RequestBuilder(this.rootUrl, ApiService.ServiceDeskMaterialAddPath, 'post');
    if (params) {
      rb.body(params.body, 'application/json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<{
        'isSuccess'?: boolean;
        'message'?: string;
        'data'?: string;
        }>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `serviceDeskMaterialAdd$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  serviceDeskMaterialAdd(params: {
    body: ServiceDeskMaterialAddRequest
  }): Observable<{
'isSuccess'?: boolean;
'message'?: string;
'data'?: string;
}> {

    return this.serviceDeskMaterialAdd$Response(params).pipe(
      map((r: StrictHttpResponse<{
'isSuccess'?: boolean;
'message'?: string;
'data'?: string;
}>) => r.body as {
'isSuccess'?: boolean;
'message'?: string;
'data'?: string;
})
    );
  }

  /**
   * Path part for operation branchAdminServiceDeskTicketSave
   */
  static readonly BranchAdminServiceDeskTicketSavePath = '/v1/serviceDesk/save/branchAdminServiceDeskTicket';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `branchAdminServiceDeskTicketSave()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  branchAdminServiceDeskTicketSave$Response(params: {
    body: BranchAdminServiceDeskTicketRequest
  }): Observable<StrictHttpResponse<{
'isSuccess'?: boolean;
'message'?: string;
'data'?: string;
}>> {

    const rb = new RequestBuilder(this.rootUrl, ApiService.BranchAdminServiceDeskTicketSavePath, 'post');
    if (params) {
      rb.body(params.body, 'application/json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<{
        'isSuccess'?: boolean;
        'message'?: string;
        'data'?: string;
        }>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `branchAdminServiceDeskTicketSave$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  branchAdminServiceDeskTicketSave(params: {
    body: BranchAdminServiceDeskTicketRequest
  }): Observable<{
'isSuccess'?: boolean;
'message'?: string;
'data'?: string;
}> {

    return this.branchAdminServiceDeskTicketSave$Response(params).pipe(
      map((r: StrictHttpResponse<{
'isSuccess'?: boolean;
'message'?: string;
'data'?: string;
}>) => r.body as {
'isSuccess'?: boolean;
'message'?: string;
'data'?: string;
})
    );
  }

  /**
   * Path part for operation branchAdminList
   */
  static readonly BranchAdminListPath = '/v1/serviceDesk/list/branchAdmin';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `branchAdminList()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  branchAdminList$Response(params: {
    body: FetchServiceDeskRequest
  }): Observable<StrictHttpResponse<{
'isSuccess'?: boolean;
'message'?: string;
'data'?: {
'pageNumber'?: number;
'pageSize'?: number;
'totalRowCount'?: number;
'items'?: Array<BranchAdminServiceItem>;
};
}>> {

    const rb = new RequestBuilder(this.rootUrl, ApiService.BranchAdminListPath, 'post');
    if (params) {
      rb.body(params.body, 'application/json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<{
        'isSuccess'?: boolean;
        'message'?: string;
        'data'?: {
        'pageNumber'?: number;
        'pageSize'?: number;
        'totalRowCount'?: number;
        'items'?: Array<BranchAdminServiceItem>;
        };
        }>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `branchAdminList$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  branchAdminList(params: {
    body: FetchServiceDeskRequest
  }): Observable<{
'isSuccess'?: boolean;
'message'?: string;
'data'?: {
'pageNumber'?: number;
'pageSize'?: number;
'totalRowCount'?: number;
'items'?: Array<BranchAdminServiceItem>;
};
}> {

    return this.branchAdminList$Response(params).pipe(
      map((r: StrictHttpResponse<{
'isSuccess'?: boolean;
'message'?: string;
'data'?: {
'pageNumber'?: number;
'pageSize'?: number;
'totalRowCount'?: number;
'items'?: Array<BranchAdminServiceItem>;
};
}>) => r.body as {
'isSuccess'?: boolean;
'message'?: string;
'data'?: {
'pageNumber'?: number;
'pageSize'?: number;
'totalRowCount'?: number;
'items'?: Array<BranchAdminServiceItem>;
};
})
    );
  }

  /**
   * Path part for operation branchAdminCategoryList
   */
  static readonly BranchAdminCategoryListPath = '/v1/serviceDesk/list/branchAdminServiceDeskCategoryType';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `branchAdminCategoryList()` instead.
   *
   * This method doesn't expect any request body.
   */
  branchAdminCategoryList$Response(params?: {
  }): Observable<StrictHttpResponse<{
'isSuccess'?: boolean;
'message'?: string;
'data'?: {
'pageNumber'?: number;
'pageSize'?: number;
'totalRowCount'?: number;
'items'?: Array<BranchAdminServiceDeskCategoryType>;
};
}>> {

    const rb = new RequestBuilder(this.rootUrl, ApiService.BranchAdminCategoryListPath, 'post');
    if (params) {
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<{
        'isSuccess'?: boolean;
        'message'?: string;
        'data'?: {
        'pageNumber'?: number;
        'pageSize'?: number;
        'totalRowCount'?: number;
        'items'?: Array<BranchAdminServiceDeskCategoryType>;
        };
        }>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `branchAdminCategoryList$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  branchAdminCategoryList(params?: {
  }): Observable<{
'isSuccess'?: boolean;
'message'?: string;
'data'?: {
'pageNumber'?: number;
'pageSize'?: number;
'totalRowCount'?: number;
'items'?: Array<BranchAdminServiceDeskCategoryType>;
};
}> {

    return this.branchAdminCategoryList$Response(params).pipe(
      map((r: StrictHttpResponse<{
'isSuccess'?: boolean;
'message'?: string;
'data'?: {
'pageNumber'?: number;
'pageSize'?: number;
'totalRowCount'?: number;
'items'?: Array<BranchAdminServiceDeskCategoryType>;
};
}>) => r.body as {
'isSuccess'?: boolean;
'message'?: string;
'data'?: {
'pageNumber'?: number;
'pageSize'?: number;
'totalRowCount'?: number;
'items'?: Array<BranchAdminServiceDeskCategoryType>;
};
})
    );
  }

  /**
   * Path part for operation serviceDeskPriorityList
   */
  static readonly ServiceDeskPriorityListPath = '/v1/serviceDesk/list/serviceDeskPriorityList';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `serviceDeskPriorityList()` instead.
   *
   * This method doesn't expect any request body.
   */
  serviceDeskPriorityList$Response(params?: {
  }): Observable<StrictHttpResponse<{
'isSuccess'?: boolean;
'message'?: string;
'data'?: {
'pageNumber'?: number;
'pageSize'?: number;
'totalRowCount'?: number;
'items'?: Array<ServiceDeskPriorityType>;
};
}>> {

    const rb = new RequestBuilder(this.rootUrl, ApiService.ServiceDeskPriorityListPath, 'post');
    if (params) {
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<{
        'isSuccess'?: boolean;
        'message'?: string;
        'data'?: {
        'pageNumber'?: number;
        'pageSize'?: number;
        'totalRowCount'?: number;
        'items'?: Array<ServiceDeskPriorityType>;
        };
        }>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `serviceDeskPriorityList$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  serviceDeskPriorityList(params?: {
  }): Observable<{
'isSuccess'?: boolean;
'message'?: string;
'data'?: {
'pageNumber'?: number;
'pageSize'?: number;
'totalRowCount'?: number;
'items'?: Array<ServiceDeskPriorityType>;
};
}> {

    return this.serviceDeskPriorityList$Response(params).pipe(
      map((r: StrictHttpResponse<{
'isSuccess'?: boolean;
'message'?: string;
'data'?: {
'pageNumber'?: number;
'pageSize'?: number;
'totalRowCount'?: number;
'items'?: Array<ServiceDeskPriorityType>;
};
}>) => r.body as {
'isSuccess'?: boolean;
'message'?: string;
'data'?: {
'pageNumber'?: number;
'pageSize'?: number;
'totalRowCount'?: number;
'items'?: Array<ServiceDeskPriorityType>;
};
})
    );
  }

  /**
   * Path part for operation deleteServiceDeskTicket
   */
  static readonly DeleteServiceDeskTicketPath = '/v1/serviceDesk/deleteServiceDeskTicket';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `deleteServiceDeskTicket()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  deleteServiceDeskTicket$Response(params: {
    body: ServiceDeskDeleteRequest
  }): Observable<StrictHttpResponse<{
'isSuccess'?: boolean;
'message'?: string;
'data'?: string;
}>> {

    const rb = new RequestBuilder(this.rootUrl, ApiService.DeleteServiceDeskTicketPath, 'post');
    if (params) {
      rb.body(params.body, 'application/json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<{
        'isSuccess'?: boolean;
        'message'?: string;
        'data'?: string;
        }>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `deleteServiceDeskTicket$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  deleteServiceDeskTicket(params: {
    body: ServiceDeskDeleteRequest
  }): Observable<{
'isSuccess'?: boolean;
'message'?: string;
'data'?: string;
}> {

    return this.deleteServiceDeskTicket$Response(params).pipe(
      map((r: StrictHttpResponse<{
'isSuccess'?: boolean;
'message'?: string;
'data'?: string;
}>) => r.body as {
'isSuccess'?: boolean;
'message'?: string;
'data'?: string;
})
    );
  }

  /**
   * Path part for operation serviceDeskFacilitiesNotificationEmail
   */
  static readonly ServiceDeskFacilitiesNotificationEmailPath = '/v1/serviceDesk/notify/serviceDeskFacilities';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `serviceDeskFacilitiesNotificationEmail()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  serviceDeskFacilitiesNotificationEmail$Response(params: {
    body: ServiceDeskEmailNotificationRequest
  }): Observable<StrictHttpResponse<void>> {

    const rb = new RequestBuilder(this.rootUrl, ApiService.ServiceDeskFacilitiesNotificationEmailPath, 'post');
    if (params) {
      rb.body(params.body, 'application/json');
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: '*/*'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return (r as HttpResponse<any>).clone({ body: undefined }) as StrictHttpResponse<void>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `serviceDeskFacilitiesNotificationEmail$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  serviceDeskFacilitiesNotificationEmail(params: {
    body: ServiceDeskEmailNotificationRequest
  }): Observable<void> {

    return this.serviceDeskFacilitiesNotificationEmail$Response(params).pipe(
      map((r: StrictHttpResponse<void>) => r.body as void)
    );
  }

  /**
   * Path part for operation serviceDeskBranchAdminNotificationEmail
   */
  static readonly ServiceDeskBranchAdminNotificationEmailPath = '/v1/serviceDesk/notify/serviceDeskBranchAdmin';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `serviceDeskBranchAdminNotificationEmail()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  serviceDeskBranchAdminNotificationEmail$Response(params: {
    body: ServiceDeskEmailNotificationRequest
  }): Observable<StrictHttpResponse<void>> {

    const rb = new RequestBuilder(this.rootUrl, ApiService.ServiceDeskBranchAdminNotificationEmailPath, 'post');
    if (params) {
      rb.body(params.body, 'application/json');
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: '*/*'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return (r as HttpResponse<any>).clone({ body: undefined }) as StrictHttpResponse<void>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `serviceDeskBranchAdminNotificationEmail$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  serviceDeskBranchAdminNotificationEmail(params: {
    body: ServiceDeskEmailNotificationRequest
  }): Observable<void> {

    return this.serviceDeskBranchAdminNotificationEmail$Response(params).pipe(
      map((r: StrictHttpResponse<void>) => r.body as void)
    );
  }

  /**
   * Path part for operation marketingServiceDeskStatus
   */
  static readonly MarketingServiceDeskStatusPath = '/v1/serviceDesk/list/marketingServiceDeskStatus';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `marketingServiceDeskStatus()` instead.
   *
   * This method doesn't expect any request body.
   */
  marketingServiceDeskStatus$Response(params?: {
  }): Observable<StrictHttpResponse<{
'isSuccess'?: boolean;
'message'?: string;
'data'?: {
'pageNumber'?: number;
'pageSize'?: number;
'totalRowCount'?: number;
'items'?: Array<ServiceDeskStatusItem>;
};
}>> {

    const rb = new RequestBuilder(this.rootUrl, ApiService.MarketingServiceDeskStatusPath, 'post');
    if (params) {
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<{
        'isSuccess'?: boolean;
        'message'?: string;
        'data'?: {
        'pageNumber'?: number;
        'pageSize'?: number;
        'totalRowCount'?: number;
        'items'?: Array<ServiceDeskStatusItem>;
        };
        }>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `marketingServiceDeskStatus$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  marketingServiceDeskStatus(params?: {
  }): Observable<{
'isSuccess'?: boolean;
'message'?: string;
'data'?: {
'pageNumber'?: number;
'pageSize'?: number;
'totalRowCount'?: number;
'items'?: Array<ServiceDeskStatusItem>;
};
}> {

    return this.marketingServiceDeskStatus$Response(params).pipe(
      map((r: StrictHttpResponse<{
'isSuccess'?: boolean;
'message'?: string;
'data'?: {
'pageNumber'?: number;
'pageSize'?: number;
'totalRowCount'?: number;
'items'?: Array<ServiceDeskStatusItem>;
};
}>) => r.body as {
'isSuccess'?: boolean;
'message'?: string;
'data'?: {
'pageNumber'?: number;
'pageSize'?: number;
'totalRowCount'?: number;
'items'?: Array<ServiceDeskStatusItem>;
};
})
    );
  }

  /**
   * Path part for operation facilitiesVendorAdd
   */
  static readonly FacilitiesVendorAddPath = '/v1/serviceDesk/save/facilitiesVendor';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `facilitiesVendorAdd()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  facilitiesVendorAdd$Response(params: {
    body: FacilitiesVendorServiceDeskItem
  }): Observable<StrictHttpResponse<{
'isSuccess'?: boolean;
'message'?: string;
'data'?: string;
}>> {

    const rb = new RequestBuilder(this.rootUrl, ApiService.FacilitiesVendorAddPath, 'post');
    if (params) {
      rb.body(params.body, 'application/json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<{
        'isSuccess'?: boolean;
        'message'?: string;
        'data'?: string;
        }>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `facilitiesVendorAdd$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  facilitiesVendorAdd(params: {
    body: FacilitiesVendorServiceDeskItem
  }): Observable<{
'isSuccess'?: boolean;
'message'?: string;
'data'?: string;
}> {

    return this.facilitiesVendorAdd$Response(params).pipe(
      map((r: StrictHttpResponse<{
'isSuccess'?: boolean;
'message'?: string;
'data'?: string;
}>) => r.body as {
'isSuccess'?: boolean;
'message'?: string;
'data'?: string;
})
    );
  }

  /**
   * Path part for operation facilitiesVendorList
   */
  static readonly FacilitiesVendorListPath = '/v1/serviceDesk/list/facilitiesVendor';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `facilitiesVendorList()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  facilitiesVendorList$Response(params: {
    body: FetchServiceDeskRequest
  }): Observable<StrictHttpResponse<{
'isSuccess'?: boolean;
'message'?: string;
'data'?: {
'pageNumber'?: number;
'pageSize'?: number;
'totalRowCount'?: number;
'items'?: Array<FacilitiesVendorServiceDeskItem>;
};
}>> {

    const rb = new RequestBuilder(this.rootUrl, ApiService.FacilitiesVendorListPath, 'post');
    if (params) {
      rb.body(params.body, 'application/json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<{
        'isSuccess'?: boolean;
        'message'?: string;
        'data'?: {
        'pageNumber'?: number;
        'pageSize'?: number;
        'totalRowCount'?: number;
        'items'?: Array<FacilitiesVendorServiceDeskItem>;
        };
        }>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `facilitiesVendorList$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  facilitiesVendorList(params: {
    body: FetchServiceDeskRequest
  }): Observable<{
'isSuccess'?: boolean;
'message'?: string;
'data'?: {
'pageNumber'?: number;
'pageSize'?: number;
'totalRowCount'?: number;
'items'?: Array<FacilitiesVendorServiceDeskItem>;
};
}> {

    return this.facilitiesVendorList$Response(params).pipe(
      map((r: StrictHttpResponse<{
'isSuccess'?: boolean;
'message'?: string;
'data'?: {
'pageNumber'?: number;
'pageSize'?: number;
'totalRowCount'?: number;
'items'?: Array<FacilitiesVendorServiceDeskItem>;
};
}>) => r.body as {
'isSuccess'?: boolean;
'message'?: string;
'data'?: {
'pageNumber'?: number;
'pageSize'?: number;
'totalRowCount'?: number;
'items'?: Array<FacilitiesVendorServiceDeskItem>;
};
})
    );
  }

  /**
   * Path part for operation phoneType
   */
  static readonly PhoneTypePath = '/v1/serviceDesk/list/phoneType';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `phoneType()` instead.
   *
   * This method doesn't expect any request body.
   */
  phoneType$Response(params?: {
  }): Observable<StrictHttpResponse<{
'isSuccess'?: boolean;
'message'?: string;
'data'?: {
'pageNumber'?: number;
'pageSize'?: number;
'totalRowCount'?: number;
'items'?: Array<PhoneType>;
};
}>> {

    const rb = new RequestBuilder(this.rootUrl, ApiService.PhoneTypePath, 'post');
    if (params) {
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<{
        'isSuccess'?: boolean;
        'message'?: string;
        'data'?: {
        'pageNumber'?: number;
        'pageSize'?: number;
        'totalRowCount'?: number;
        'items'?: Array<PhoneType>;
        };
        }>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `phoneType$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  phoneType(params?: {
  }): Observable<{
'isSuccess'?: boolean;
'message'?: string;
'data'?: {
'pageNumber'?: number;
'pageSize'?: number;
'totalRowCount'?: number;
'items'?: Array<PhoneType>;
};
}> {

    return this.phoneType$Response(params).pipe(
      map((r: StrictHttpResponse<{
'isSuccess'?: boolean;
'message'?: string;
'data'?: {
'pageNumber'?: number;
'pageSize'?: number;
'totalRowCount'?: number;
'items'?: Array<PhoneType>;
};
}>) => r.body as {
'isSuccess'?: boolean;
'message'?: string;
'data'?: {
'pageNumber'?: number;
'pageSize'?: number;
'totalRowCount'?: number;
'items'?: Array<PhoneType>;
};
})
    );
  }

  /**
   * Path part for operation deleteFacilitiesVendorServiceDesk
   */
  static readonly DeleteFacilitiesVendorServiceDeskPath = '/v1/serviceDesk/delete/facilitiesVendorServiceDesk';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `deleteFacilitiesVendorServiceDesk()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  deleteFacilitiesVendorServiceDesk$Response(params: {
    body: ServiceDeskDeleteRequest
  }): Observable<StrictHttpResponse<{
'isSuccess'?: boolean;
'message'?: string;
'data'?: string;
}>> {

    const rb = new RequestBuilder(this.rootUrl, ApiService.DeleteFacilitiesVendorServiceDeskPath, 'post');
    if (params) {
      rb.body(params.body, 'application/json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<{
        'isSuccess'?: boolean;
        'message'?: string;
        'data'?: string;
        }>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `deleteFacilitiesVendorServiceDesk$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  deleteFacilitiesVendorServiceDesk(params: {
    body: ServiceDeskDeleteRequest
  }): Observable<{
'isSuccess'?: boolean;
'message'?: string;
'data'?: string;
}> {

    return this.deleteFacilitiesVendorServiceDesk$Response(params).pipe(
      map((r: StrictHttpResponse<{
'isSuccess'?: boolean;
'message'?: string;
'data'?: string;
}>) => r.body as {
'isSuccess'?: boolean;
'message'?: string;
'data'?: string;
})
    );
  }

  /**
   * Path part for operation facilitiesServiceDeskXVendorEmailAdd
   */
  static readonly FacilitiesServiceDeskXVendorEmailAddPath = '/v1/serviceDesk/save/facilitiesServiceDeskXVendorEmail';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `facilitiesServiceDeskXVendorEmailAdd()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  facilitiesServiceDeskXVendorEmailAdd$Response(params: {
    body: FacilitiesServiceDeskXVendorEmail
  }): Observable<StrictHttpResponse<{
'isSuccess'?: boolean;
'message'?: string;
'data'?: string;
}>> {

    const rb = new RequestBuilder(this.rootUrl, ApiService.FacilitiesServiceDeskXVendorEmailAddPath, 'post');
    if (params) {
      rb.body(params.body, 'application/json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<{
        'isSuccess'?: boolean;
        'message'?: string;
        'data'?: string;
        }>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `facilitiesServiceDeskXVendorEmailAdd$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  facilitiesServiceDeskXVendorEmailAdd(params: {
    body: FacilitiesServiceDeskXVendorEmail
  }): Observable<{
'isSuccess'?: boolean;
'message'?: string;
'data'?: string;
}> {

    return this.facilitiesServiceDeskXVendorEmailAdd$Response(params).pipe(
      map((r: StrictHttpResponse<{
'isSuccess'?: boolean;
'message'?: string;
'data'?: string;
}>) => r.body as {
'isSuccess'?: boolean;
'message'?: string;
'data'?: string;
})
    );
  }

  /**
   * Path part for operation facilitiesServiceDeskXVendorEmailList
   */
  static readonly FacilitiesServiceDeskXVendorEmailListPath = '/v1/serviceDesk/list/facilitiesServiceDeskXVendorEmail';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `facilitiesServiceDeskXVendorEmailList()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  facilitiesServiceDeskXVendorEmailList$Response(params: {
    body: FetchServiceDeskRequest
  }): Observable<StrictHttpResponse<{
'isSuccess'?: boolean;
'message'?: string;
'data'?: {
'pageNumber'?: number;
'pageSize'?: number;
'totalRowCount'?: number;
'items'?: Array<FacilitiesServiceDeskXVendorEmail>;
};
}>> {

    const rb = new RequestBuilder(this.rootUrl, ApiService.FacilitiesServiceDeskXVendorEmailListPath, 'post');
    if (params) {
      rb.body(params.body, 'application/json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<{
        'isSuccess'?: boolean;
        'message'?: string;
        'data'?: {
        'pageNumber'?: number;
        'pageSize'?: number;
        'totalRowCount'?: number;
        'items'?: Array<FacilitiesServiceDeskXVendorEmail>;
        };
        }>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `facilitiesServiceDeskXVendorEmailList$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  facilitiesServiceDeskXVendorEmailList(params: {
    body: FetchServiceDeskRequest
  }): Observable<{
'isSuccess'?: boolean;
'message'?: string;
'data'?: {
'pageNumber'?: number;
'pageSize'?: number;
'totalRowCount'?: number;
'items'?: Array<FacilitiesServiceDeskXVendorEmail>;
};
}> {

    return this.facilitiesServiceDeskXVendorEmailList$Response(params).pipe(
      map((r: StrictHttpResponse<{
'isSuccess'?: boolean;
'message'?: string;
'data'?: {
'pageNumber'?: number;
'pageSize'?: number;
'totalRowCount'?: number;
'items'?: Array<FacilitiesServiceDeskXVendorEmail>;
};
}>) => r.body as {
'isSuccess'?: boolean;
'message'?: string;
'data'?: {
'pageNumber'?: number;
'pageSize'?: number;
'totalRowCount'?: number;
'items'?: Array<FacilitiesServiceDeskXVendorEmail>;
};
})
    );
  }

  /**
   * Path part for operation generateServiceDeskReport
   */
  static readonly GenerateServiceDeskReportPath = '/v1/serviceDesk/list/serviceDeskReport';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `generateServiceDeskReport()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  generateServiceDeskReport$Response(params: {
    body: ServiceDeskReportRequest
  }): Observable<StrictHttpResponse<{
'isSuccess'?: boolean;
'message'?: string;
'data'?: {
'pageNumber'?: number;
'pageSize'?: number;
'totalRowCount'?: number;
'items'?: Array<ServiceReportRecord>;
};
}>> {

    const rb = new RequestBuilder(this.rootUrl, ApiService.GenerateServiceDeskReportPath, 'post');
    if (params) {
      rb.body(params.body, 'application/json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<{
        'isSuccess'?: boolean;
        'message'?: string;
        'data'?: {
        'pageNumber'?: number;
        'pageSize'?: number;
        'totalRowCount'?: number;
        'items'?: Array<ServiceReportRecord>;
        };
        }>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `generateServiceDeskReport$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  generateServiceDeskReport(params: {
    body: ServiceDeskReportRequest
  }): Observable<{
'isSuccess'?: boolean;
'message'?: string;
'data'?: {
'pageNumber'?: number;
'pageSize'?: number;
'totalRowCount'?: number;
'items'?: Array<ServiceReportRecord>;
};
}> {

    return this.generateServiceDeskReport$Response(params).pipe(
      map((r: StrictHttpResponse<{
'isSuccess'?: boolean;
'message'?: string;
'data'?: {
'pageNumber'?: number;
'pageSize'?: number;
'totalRowCount'?: number;
'items'?: Array<ServiceReportRecord>;
};
}>) => r.body as {
'isSuccess'?: boolean;
'message'?: string;
'data'?: {
'pageNumber'?: number;
'pageSize'?: number;
'totalRowCount'?: number;
'items'?: Array<ServiceReportRecord>;
};
})
    );
  }

  /**
   * Path part for operation collateralServiceDeskTicketSave
   */
  static readonly CollateralServiceDeskTicketSavePath = '/v1/serviceDesk/save/collateralServiceDeskTicket';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `collateralServiceDeskTicketSave()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  collateralServiceDeskTicketSave$Response(params: {
    body: CollateralServiceDeskTicketRequest
  }): Observable<StrictHttpResponse<{
'isSuccess'?: boolean;
'message'?: string;
'data'?: string;
}>> {

    const rb = new RequestBuilder(this.rootUrl, ApiService.CollateralServiceDeskTicketSavePath, 'post');
    if (params) {
      rb.body(params.body, 'application/json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<{
        'isSuccess'?: boolean;
        'message'?: string;
        'data'?: string;
        }>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `collateralServiceDeskTicketSave$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  collateralServiceDeskTicketSave(params: {
    body: CollateralServiceDeskTicketRequest
  }): Observable<{
'isSuccess'?: boolean;
'message'?: string;
'data'?: string;
}> {

    return this.collateralServiceDeskTicketSave$Response(params).pipe(
      map((r: StrictHttpResponse<{
'isSuccess'?: boolean;
'message'?: string;
'data'?: string;
}>) => r.body as {
'isSuccess'?: boolean;
'message'?: string;
'data'?: string;
})
    );
  }

  /**
   * Path part for operation collateralServiceDeskSubCategoryList
   */
  static readonly CollateralServiceDeskSubCategoryListPath = '/v1/serviceDesk/list/collateralServiceDeskSubCategory';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `collateralServiceDeskSubCategoryList()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  collateralServiceDeskSubCategoryList$Response(params: {
    body: CollateralServiceDeskSubCategoryRequest
  }): Observable<StrictHttpResponse<{
'isSuccess'?: boolean;
'message'?: string;
'data'?: {
'pageNumber'?: number;
'pageSize'?: number;
'totalRowCount'?: number;
'items'?: Array<CollateralServiceDeskSubCategory>;
};
}>> {

    const rb = new RequestBuilder(this.rootUrl, ApiService.CollateralServiceDeskSubCategoryListPath, 'post');
    if (params) {
      rb.body(params.body, 'application/json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<{
        'isSuccess'?: boolean;
        'message'?: string;
        'data'?: {
        'pageNumber'?: number;
        'pageSize'?: number;
        'totalRowCount'?: number;
        'items'?: Array<CollateralServiceDeskSubCategory>;
        };
        }>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `collateralServiceDeskSubCategoryList$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  collateralServiceDeskSubCategoryList(params: {
    body: CollateralServiceDeskSubCategoryRequest
  }): Observable<{
'isSuccess'?: boolean;
'message'?: string;
'data'?: {
'pageNumber'?: number;
'pageSize'?: number;
'totalRowCount'?: number;
'items'?: Array<CollateralServiceDeskSubCategory>;
};
}> {

    return this.collateralServiceDeskSubCategoryList$Response(params).pipe(
      map((r: StrictHttpResponse<{
'isSuccess'?: boolean;
'message'?: string;
'data'?: {
'pageNumber'?: number;
'pageSize'?: number;
'totalRowCount'?: number;
'items'?: Array<CollateralServiceDeskSubCategory>;
};
}>) => r.body as {
'isSuccess'?: boolean;
'message'?: string;
'data'?: {
'pageNumber'?: number;
'pageSize'?: number;
'totalRowCount'?: number;
'items'?: Array<CollateralServiceDeskSubCategory>;
};
})
    );
  }

  /**
   * Path part for operation collateralServiceDeskCategoryList
   */
  static readonly CollateralServiceDeskCategoryListPath = '/v1/serviceDesk/list/collateralServiceDeskCategory';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `collateralServiceDeskCategoryList()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  collateralServiceDeskCategoryList$Response(params: {
    body: FetchServiceDeskCategoryListRequest
  }): Observable<StrictHttpResponse<{
'isSuccess'?: boolean;
'message'?: string;
'data'?: {
'pageNumber'?: number;
'pageSize'?: number;
'totalRowCount'?: number;
'items'?: Array<CollateralServiceDeskCategory>;
};
}>> {

    const rb = new RequestBuilder(this.rootUrl, ApiService.CollateralServiceDeskCategoryListPath, 'post');
    if (params) {
      rb.body(params.body, 'application/json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<{
        'isSuccess'?: boolean;
        'message'?: string;
        'data'?: {
        'pageNumber'?: number;
        'pageSize'?: number;
        'totalRowCount'?: number;
        'items'?: Array<CollateralServiceDeskCategory>;
        };
        }>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `collateralServiceDeskCategoryList$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  collateralServiceDeskCategoryList(params: {
    body: FetchServiceDeskCategoryListRequest
  }): Observable<{
'isSuccess'?: boolean;
'message'?: string;
'data'?: {
'pageNumber'?: number;
'pageSize'?: number;
'totalRowCount'?: number;
'items'?: Array<CollateralServiceDeskCategory>;
};
}> {

    return this.collateralServiceDeskCategoryList$Response(params).pipe(
      map((r: StrictHttpResponse<{
'isSuccess'?: boolean;
'message'?: string;
'data'?: {
'pageNumber'?: number;
'pageSize'?: number;
'totalRowCount'?: number;
'items'?: Array<CollateralServiceDeskCategory>;
};
}>) => r.body as {
'isSuccess'?: boolean;
'message'?: string;
'data'?: {
'pageNumber'?: number;
'pageSize'?: number;
'totalRowCount'?: number;
'items'?: Array<CollateralServiceDeskCategory>;
};
})
    );
  }

  /**
   * Path part for operation collateralServiceDeskStatusList
   */
  static readonly CollateralServiceDeskStatusListPath = '/v1/serviceDesk/list/collateralServiceDeskStatus';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `collateralServiceDeskStatusList()` instead.
   *
   * This method doesn't expect any request body.
   */
  collateralServiceDeskStatusList$Response(params?: {
  }): Observable<StrictHttpResponse<{
'isSuccess'?: boolean;
'message'?: string;
'data'?: {
'pageNumber'?: number;
'pageSize'?: number;
'totalRowCount'?: number;
'items'?: Array<CollateralServiceDeskStatus>;
};
}>> {

    const rb = new RequestBuilder(this.rootUrl, ApiService.CollateralServiceDeskStatusListPath, 'post');
    if (params) {
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<{
        'isSuccess'?: boolean;
        'message'?: string;
        'data'?: {
        'pageNumber'?: number;
        'pageSize'?: number;
        'totalRowCount'?: number;
        'items'?: Array<CollateralServiceDeskStatus>;
        };
        }>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `collateralServiceDeskStatusList$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  collateralServiceDeskStatusList(params?: {
  }): Observable<{
'isSuccess'?: boolean;
'message'?: string;
'data'?: {
'pageNumber'?: number;
'pageSize'?: number;
'totalRowCount'?: number;
'items'?: Array<CollateralServiceDeskStatus>;
};
}> {

    return this.collateralServiceDeskStatusList$Response(params).pipe(
      map((r: StrictHttpResponse<{
'isSuccess'?: boolean;
'message'?: string;
'data'?: {
'pageNumber'?: number;
'pageSize'?: number;
'totalRowCount'?: number;
'items'?: Array<CollateralServiceDeskStatus>;
};
}>) => r.body as {
'isSuccess'?: boolean;
'message'?: string;
'data'?: {
'pageNumber'?: number;
'pageSize'?: number;
'totalRowCount'?: number;
'items'?: Array<CollateralServiceDeskStatus>;
};
})
    );
  }

  /**
   * Path part for operation fetchLoanOfficerList
   */
  static readonly FetchLoanOfficerListPath = '/v1/serviceDesk/list/fetchLoanOfficer';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `fetchLoanOfficerList()` instead.
   *
   * This method doesn't expect any request body.
   */
  fetchLoanOfficerList$Response(params?: {
  }): Observable<StrictHttpResponse<{
'isSuccess'?: boolean;
'message'?: string;
'data'?: {
'pageNumber'?: number;
'pageSize'?: number;
'totalRowCount'?: number;
'items'?: Array<InternalUserItem>;
};
}>> {

    const rb = new RequestBuilder(this.rootUrl, ApiService.FetchLoanOfficerListPath, 'post');
    if (params) {
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<{
        'isSuccess'?: boolean;
        'message'?: string;
        'data'?: {
        'pageNumber'?: number;
        'pageSize'?: number;
        'totalRowCount'?: number;
        'items'?: Array<InternalUserItem>;
        };
        }>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `fetchLoanOfficerList$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  fetchLoanOfficerList(params?: {
  }): Observable<{
'isSuccess'?: boolean;
'message'?: string;
'data'?: {
'pageNumber'?: number;
'pageSize'?: number;
'totalRowCount'?: number;
'items'?: Array<InternalUserItem>;
};
}> {

    return this.fetchLoanOfficerList$Response(params).pipe(
      map((r: StrictHttpResponse<{
'isSuccess'?: boolean;
'message'?: string;
'data'?: {
'pageNumber'?: number;
'pageSize'?: number;
'totalRowCount'?: number;
'items'?: Array<InternalUserItem>;
};
}>) => r.body as {
'isSuccess'?: boolean;
'message'?: string;
'data'?: {
'pageNumber'?: number;
'pageSize'?: number;
'totalRowCount'?: number;
'items'?: Array<InternalUserItem>;
};
})
    );
  }

  /**
   * Path part for operation collateralList
   */
  static readonly CollateralListPath = '/v1/serviceDesk/list/collateral';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `collateralList()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  collateralList$Response(params: {
    body: FetchServiceDeskRequest
  }): Observable<StrictHttpResponse<{
'isSuccess'?: boolean;
'message'?: string;
'data'?: {
'pageNumber'?: number;
'pageSize'?: number;
'totalRowCount'?: number;
'items'?: Array<CollateralServiceDeskItem>;
};
}>> {

    const rb = new RequestBuilder(this.rootUrl, ApiService.CollateralListPath, 'post');
    if (params) {
      rb.body(params.body, 'application/json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<{
        'isSuccess'?: boolean;
        'message'?: string;
        'data'?: {
        'pageNumber'?: number;
        'pageSize'?: number;
        'totalRowCount'?: number;
        'items'?: Array<CollateralServiceDeskItem>;
        };
        }>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `collateralList$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  collateralList(params: {
    body: FetchServiceDeskRequest
  }): Observable<{
'isSuccess'?: boolean;
'message'?: string;
'data'?: {
'pageNumber'?: number;
'pageSize'?: number;
'totalRowCount'?: number;
'items'?: Array<CollateralServiceDeskItem>;
};
}> {

    return this.collateralList$Response(params).pipe(
      map((r: StrictHttpResponse<{
'isSuccess'?: boolean;
'message'?: string;
'data'?: {
'pageNumber'?: number;
'pageSize'?: number;
'totalRowCount'?: number;
'items'?: Array<CollateralServiceDeskItem>;
};
}>) => r.body as {
'isSuccess'?: boolean;
'message'?: string;
'data'?: {
'pageNumber'?: number;
'pageSize'?: number;
'totalRowCount'?: number;
'items'?: Array<CollateralServiceDeskItem>;
};
})
    );
  }

  /**
   * Path part for operation fetchCollateralServiceDeskLoan
   */
  static readonly FetchCollateralServiceDeskLoanPath = '/v1/serviceDesk/list/fetchCollateralServiceDeskLoan';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `fetchCollateralServiceDeskLoan()` instead.
   *
   * This method doesn't expect any request body.
   */
  fetchCollateralServiceDeskLoan$Response(params?: {
  }): Observable<StrictHttpResponse<{
'isSuccess'?: boolean;
'message'?: string;
'data'?: {
'pageNumber'?: number;
'pageSize'?: number;
'totalRowCount'?: number;
'items'?: Array<CollateralServiceDeskLoanItem>;
};
}>> {

    const rb = new RequestBuilder(this.rootUrl, ApiService.FetchCollateralServiceDeskLoanPath, 'post');
    if (params) {
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<{
        'isSuccess'?: boolean;
        'message'?: string;
        'data'?: {
        'pageNumber'?: number;
        'pageSize'?: number;
        'totalRowCount'?: number;
        'items'?: Array<CollateralServiceDeskLoanItem>;
        };
        }>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `fetchCollateralServiceDeskLoan$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  fetchCollateralServiceDeskLoan(params?: {
  }): Observable<{
'isSuccess'?: boolean;
'message'?: string;
'data'?: {
'pageNumber'?: number;
'pageSize'?: number;
'totalRowCount'?: number;
'items'?: Array<CollateralServiceDeskLoanItem>;
};
}> {

    return this.fetchCollateralServiceDeskLoan$Response(params).pipe(
      map((r: StrictHttpResponse<{
'isSuccess'?: boolean;
'message'?: string;
'data'?: {
'pageNumber'?: number;
'pageSize'?: number;
'totalRowCount'?: number;
'items'?: Array<CollateralServiceDeskLoanItem>;
};
}>) => r.body as {
'isSuccess'?: boolean;
'message'?: string;
'data'?: {
'pageNumber'?: number;
'pageSize'?: number;
'totalRowCount'?: number;
'items'?: Array<CollateralServiceDeskLoanItem>;
};
})
    );
  }

  /**
   * Path part for operation fetchServiceDeskCollateralSubCategories
   */
  static readonly FetchServiceDeskCollateralSubCategoriesPath = '/v1/serviceDesk/list/collateralSubCategories';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `fetchServiceDeskCollateralSubCategories()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  fetchServiceDeskCollateralSubCategories$Response(params: {
    body: EntityGetRequest
  }): Observable<StrictHttpResponse<{
'isSuccess'?: boolean;
'message'?: string;
'data'?: {
'pageNumber'?: number;
'pageSize'?: number;
'totalRowCount'?: number;
'items'?: Array<CollateralServiceDeskSubCategory>;
};
}>> {

    const rb = new RequestBuilder(this.rootUrl, ApiService.FetchServiceDeskCollateralSubCategoriesPath, 'post');
    if (params) {
      rb.body(params.body, 'application/json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<{
        'isSuccess'?: boolean;
        'message'?: string;
        'data'?: {
        'pageNumber'?: number;
        'pageSize'?: number;
        'totalRowCount'?: number;
        'items'?: Array<CollateralServiceDeskSubCategory>;
        };
        }>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `fetchServiceDeskCollateralSubCategories$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  fetchServiceDeskCollateralSubCategories(params: {
    body: EntityGetRequest
  }): Observable<{
'isSuccess'?: boolean;
'message'?: string;
'data'?: {
'pageNumber'?: number;
'pageSize'?: number;
'totalRowCount'?: number;
'items'?: Array<CollateralServiceDeskSubCategory>;
};
}> {

    return this.fetchServiceDeskCollateralSubCategories$Response(params).pipe(
      map((r: StrictHttpResponse<{
'isSuccess'?: boolean;
'message'?: string;
'data'?: {
'pageNumber'?: number;
'pageSize'?: number;
'totalRowCount'?: number;
'items'?: Array<CollateralServiceDeskSubCategory>;
};
}>) => r.body as {
'isSuccess'?: boolean;
'message'?: string;
'data'?: {
'pageNumber'?: number;
'pageSize'?: number;
'totalRowCount'?: number;
'items'?: Array<CollateralServiceDeskSubCategory>;
};
})
    );
  }

  /**
   * Path part for operation collateralServiceDeskCategorySave
   */
  static readonly CollateralServiceDeskCategorySavePath = '/v1/serviceDesk/save/collateralServiceDeskCategory';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `collateralServiceDeskCategorySave()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  collateralServiceDeskCategorySave$Response(params: {
    body: CollateralServiceDeskCategory
  }): Observable<StrictHttpResponse<{
'isSuccess'?: boolean;
'message'?: string;
'data'?: CollateralServiceDeskCategory;
}>> {

    const rb = new RequestBuilder(this.rootUrl, ApiService.CollateralServiceDeskCategorySavePath, 'post');
    if (params) {
      rb.body(params.body, 'application/json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<{
        'isSuccess'?: boolean;
        'message'?: string;
        'data'?: CollateralServiceDeskCategory;
        }>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `collateralServiceDeskCategorySave$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  collateralServiceDeskCategorySave(params: {
    body: CollateralServiceDeskCategory
  }): Observable<{
'isSuccess'?: boolean;
'message'?: string;
'data'?: CollateralServiceDeskCategory;
}> {

    return this.collateralServiceDeskCategorySave$Response(params).pipe(
      map((r: StrictHttpResponse<{
'isSuccess'?: boolean;
'message'?: string;
'data'?: CollateralServiceDeskCategory;
}>) => r.body as {
'isSuccess'?: boolean;
'message'?: string;
'data'?: CollateralServiceDeskCategory;
})
    );
  }

  /**
   * Path part for operation unarchiveCollateralSubCategories
   */
  static readonly UnarchiveCollateralSubCategoriesPath = '/v1/serviceDesk/add/unarchiveCollateralSubCategories';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `unarchiveCollateralSubCategories()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  unarchiveCollateralSubCategories$Response(params: {
    body: CollateralServiceDeskSubCategoryRequest
  }): Observable<StrictHttpResponse<{
'isSuccess'?: boolean;
'message'?: string;
'data'?: string;
}>> {

    const rb = new RequestBuilder(this.rootUrl, ApiService.UnarchiveCollateralSubCategoriesPath, 'post');
    if (params) {
      rb.body(params.body, 'application/json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<{
        'isSuccess'?: boolean;
        'message'?: string;
        'data'?: string;
        }>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `unarchiveCollateralSubCategories$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  unarchiveCollateralSubCategories(params: {
    body: CollateralServiceDeskSubCategoryRequest
  }): Observable<{
'isSuccess'?: boolean;
'message'?: string;
'data'?: string;
}> {

    return this.unarchiveCollateralSubCategories$Response(params).pipe(
      map((r: StrictHttpResponse<{
'isSuccess'?: boolean;
'message'?: string;
'data'?: string;
}>) => r.body as {
'isSuccess'?: boolean;
'message'?: string;
'data'?: string;
})
    );
  }

  /**
   * Path part for operation saveCollateralSubCategoryAction
   */
  static readonly SaveCollateralSubCategoryActionPath = '/v1/serviceDesk/save/collateralSubCategoryAction';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `saveCollateralSubCategoryAction()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  saveCollateralSubCategoryAction$Response(params: {
    body: CollateralServiceDeskSubCategoryActionRequest
  }): Observable<StrictHttpResponse<{
'isSuccess'?: boolean;
'message'?: string;
'data'?: string;
}>> {

    const rb = new RequestBuilder(this.rootUrl, ApiService.SaveCollateralSubCategoryActionPath, 'post');
    if (params) {
      rb.body(params.body, 'application/json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<{
        'isSuccess'?: boolean;
        'message'?: string;
        'data'?: string;
        }>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `saveCollateralSubCategoryAction$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  saveCollateralSubCategoryAction(params: {
    body: CollateralServiceDeskSubCategoryActionRequest
  }): Observable<{
'isSuccess'?: boolean;
'message'?: string;
'data'?: string;
}> {

    return this.saveCollateralSubCategoryAction$Response(params).pipe(
      map((r: StrictHttpResponse<{
'isSuccess'?: boolean;
'message'?: string;
'data'?: string;
}>) => r.body as {
'isSuccess'?: boolean;
'message'?: string;
'data'?: string;
})
    );
  }

  /**
   * Path part for operation collateralServiceDeskSubCategorySave
   */
  static readonly CollateralServiceDeskSubCategorySavePath = '/v1/serviceDesk/save/collateralServiceDeskSubCategory';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `collateralServiceDeskSubCategorySave()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  collateralServiceDeskSubCategorySave$Response(params: {
    body: CollateralServiceDeskSubCategory
  }): Observable<StrictHttpResponse<{
'isSuccess'?: boolean;
'message'?: string;
'data'?: CollateralServiceDeskSubCategory;
}>> {

    const rb = new RequestBuilder(this.rootUrl, ApiService.CollateralServiceDeskSubCategorySavePath, 'post');
    if (params) {
      rb.body(params.body, 'application/json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<{
        'isSuccess'?: boolean;
        'message'?: string;
        'data'?: CollateralServiceDeskSubCategory;
        }>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `collateralServiceDeskSubCategorySave$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  collateralServiceDeskSubCategorySave(params: {
    body: CollateralServiceDeskSubCategory
  }): Observable<{
'isSuccess'?: boolean;
'message'?: string;
'data'?: CollateralServiceDeskSubCategory;
}> {

    return this.collateralServiceDeskSubCategorySave$Response(params).pipe(
      map((r: StrictHttpResponse<{
'isSuccess'?: boolean;
'message'?: string;
'data'?: CollateralServiceDeskSubCategory;
}>) => r.body as {
'isSuccess'?: boolean;
'message'?: string;
'data'?: CollateralServiceDeskSubCategory;
})
    );
  }

  /**
   * Path part for operation deleteCollateralCategory
   */
  static readonly DeleteCollateralCategoryPath = '/v1/serviceDesk/delete/deleteCollateralCategory/{collateralServiceDeskCategoryGUID}';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `deleteCollateralCategory()` instead.
   *
   * This method doesn't expect any request body.
   */
  deleteCollateralCategory$Response(params: {
    collateralServiceDeskCategoryGUID: string;
  }): Observable<StrictHttpResponse<{
'isSuccess'?: boolean;
'message'?: string;
'data'?: string;
}>> {

    const rb = new RequestBuilder(this.rootUrl, ApiService.DeleteCollateralCategoryPath, 'get');
    if (params) {
      rb.path('collateralServiceDeskCategoryGUID', params.collateralServiceDeskCategoryGUID, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<{
        'isSuccess'?: boolean;
        'message'?: string;
        'data'?: string;
        }>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `deleteCollateralCategory$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  deleteCollateralCategory(params: {
    collateralServiceDeskCategoryGUID: string;
  }): Observable<{
'isSuccess'?: boolean;
'message'?: string;
'data'?: string;
}> {

    return this.deleteCollateralCategory$Response(params).pipe(
      map((r: StrictHttpResponse<{
'isSuccess'?: boolean;
'message'?: string;
'data'?: string;
}>) => r.body as {
'isSuccess'?: boolean;
'message'?: string;
'data'?: string;
})
    );
  }

  /**
   * Path part for operation validateCollateralServiceDeskCategoryName
   */
  static readonly ValidateCollateralServiceDeskCategoryNamePath = '/v1/serviceDesk/validate/CollateralServiceDeskCategoryName';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `validateCollateralServiceDeskCategoryName()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  validateCollateralServiceDeskCategoryName$Response(params: {
    body: CollateralServiceDeskCategory
  }): Observable<StrictHttpResponse<{
'isSuccess'?: boolean;
'message'?: string;
'data'?: string;
}>> {

    const rb = new RequestBuilder(this.rootUrl, ApiService.ValidateCollateralServiceDeskCategoryNamePath, 'post');
    if (params) {
      rb.body(params.body, 'application/json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<{
        'isSuccess'?: boolean;
        'message'?: string;
        'data'?: string;
        }>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `validateCollateralServiceDeskCategoryName$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  validateCollateralServiceDeskCategoryName(params: {
    body: CollateralServiceDeskCategory
  }): Observable<{
'isSuccess'?: boolean;
'message'?: string;
'data'?: string;
}> {

    return this.validateCollateralServiceDeskCategoryName$Response(params).pipe(
      map((r: StrictHttpResponse<{
'isSuccess'?: boolean;
'message'?: string;
'data'?: string;
}>) => r.body as {
'isSuccess'?: boolean;
'message'?: string;
'data'?: string;
})
    );
  }

  /**
   * Path part for operation validateCollateralServiceDeskSubCategoryName
   */
  static readonly ValidateCollateralServiceDeskSubCategoryNamePath = '/v1/serviceDesk/validateCollateralServiceDeskSubCategoryName';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `validateCollateralServiceDeskSubCategoryName()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  validateCollateralServiceDeskSubCategoryName$Response(params: {
    body: CollateralServiceDeskSubCategoryRequest
  }): Observable<StrictHttpResponse<{
'isSuccess'?: boolean;
'message'?: string;
'data'?: string;
}>> {

    const rb = new RequestBuilder(this.rootUrl, ApiService.ValidateCollateralServiceDeskSubCategoryNamePath, 'post');
    if (params) {
      rb.body(params.body, 'application/json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<{
        'isSuccess'?: boolean;
        'message'?: string;
        'data'?: string;
        }>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `validateCollateralServiceDeskSubCategoryName$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  validateCollateralServiceDeskSubCategoryName(params: {
    body: CollateralServiceDeskSubCategoryRequest
  }): Observable<{
'isSuccess'?: boolean;
'message'?: string;
'data'?: string;
}> {

    return this.validateCollateralServiceDeskSubCategoryName$Response(params).pipe(
      map((r: StrictHttpResponse<{
'isSuccess'?: boolean;
'message'?: string;
'data'?: string;
}>) => r.body as {
'isSuccess'?: boolean;
'message'?: string;
'data'?: string;
})
    );
  }

  /**
   * Path part for operation serviceDeskCommentStatusSave
   */
  static readonly ServiceDeskCommentStatusSavePath = '/v1/serviceDesk/save/serviceDeskCommentStatusSave';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `serviceDeskCommentStatusSave()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  serviceDeskCommentStatusSave$Response(params: {
    body: ServiceDeskUserCommentStatus
  }): Observable<StrictHttpResponse<{
'isSuccess'?: boolean;
'message'?: string;
'data'?: string;
}>> {

    const rb = new RequestBuilder(this.rootUrl, ApiService.ServiceDeskCommentStatusSavePath, 'post');
    if (params) {
      rb.body(params.body, 'application/json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<{
        'isSuccess'?: boolean;
        'message'?: string;
        'data'?: string;
        }>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `serviceDeskCommentStatusSave$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  serviceDeskCommentStatusSave(params: {
    body: ServiceDeskUserCommentStatus
  }): Observable<{
'isSuccess'?: boolean;
'message'?: string;
'data'?: string;
}> {

    return this.serviceDeskCommentStatusSave$Response(params).pipe(
      map((r: StrictHttpResponse<{
'isSuccess'?: boolean;
'message'?: string;
'data'?: string;
}>) => r.body as {
'isSuccess'?: boolean;
'message'?: string;
'data'?: string;
})
    );
  }

  /**
   * Path part for operation archiveCollateralCategory
   */
  static readonly ArchiveCollateralCategoryPath = '/v1/serviceDesk/archiveCollateralCategory/{collateralServiceDeskCategoryGUID}';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `archiveCollateralCategory()` instead.
   *
   * This method doesn't expect any request body.
   */
  archiveCollateralCategory$Response(params: {
    collateralServiceDeskCategoryGUID: string;
  }): Observable<StrictHttpResponse<{
'isSuccess'?: boolean;
'message'?: string;
'data'?: string;
}>> {

    const rb = new RequestBuilder(this.rootUrl, ApiService.ArchiveCollateralCategoryPath, 'get');
    if (params) {
      rb.path('collateralServiceDeskCategoryGUID', params.collateralServiceDeskCategoryGUID, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<{
        'isSuccess'?: boolean;
        'message'?: string;
        'data'?: string;
        }>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `archiveCollateralCategory$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  archiveCollateralCategory(params: {
    collateralServiceDeskCategoryGUID: string;
  }): Observable<{
'isSuccess'?: boolean;
'message'?: string;
'data'?: string;
}> {

    return this.archiveCollateralCategory$Response(params).pipe(
      map((r: StrictHttpResponse<{
'isSuccess'?: boolean;
'message'?: string;
'data'?: string;
}>) => r.body as {
'isSuccess'?: boolean;
'message'?: string;
'data'?: string;
})
    );
  }

  /**
   * Path part for operation unarchiveCollateralCategory
   */
  static readonly UnarchiveCollateralCategoryPath = '/v1/serviceDesk/unarchiveCollateralCategory/{collateralServiceDeskCategoryGUID}';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `unarchiveCollateralCategory()` instead.
   *
   * This method doesn't expect any request body.
   */
  unarchiveCollateralCategory$Response(params: {
    collateralServiceDeskCategoryGUID: string;
  }): Observable<StrictHttpResponse<{
'isSuccess'?: boolean;
'message'?: string;
'data'?: string;
}>> {

    const rb = new RequestBuilder(this.rootUrl, ApiService.UnarchiveCollateralCategoryPath, 'get');
    if (params) {
      rb.path('collateralServiceDeskCategoryGUID', params.collateralServiceDeskCategoryGUID, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<{
        'isSuccess'?: boolean;
        'message'?: string;
        'data'?: string;
        }>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `unarchiveCollateralCategory$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  unarchiveCollateralCategory(params: {
    collateralServiceDeskCategoryGUID: string;
  }): Observable<{
'isSuccess'?: boolean;
'message'?: string;
'data'?: string;
}> {

    return this.unarchiveCollateralCategory$Response(params).pipe(
      map((r: StrictHttpResponse<{
'isSuccess'?: boolean;
'message'?: string;
'data'?: string;
}>) => r.body as {
'isSuccess'?: boolean;
'message'?: string;
'data'?: string;
})
    );
  }

  /**
   * Path part for operation workflowList
   */
  static readonly WorkflowListPath = '/v1/workflow/list';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `workflowList()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  workflowList$Response(params: {
    body: EntityGetRequest
  }): Observable<StrictHttpResponse<{
'isSuccess'?: boolean;
'message'?: string;
'data'?: {
'pageNumber'?: number;
'pageSize'?: number;
'totalRowCount'?: number;
'items'?: Array<WorkflowItem>;
};
}>> {

    const rb = new RequestBuilder(this.rootUrl, ApiService.WorkflowListPath, 'post');
    if (params) {
      rb.body(params.body, 'application/json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<{
        'isSuccess'?: boolean;
        'message'?: string;
        'data'?: {
        'pageNumber'?: number;
        'pageSize'?: number;
        'totalRowCount'?: number;
        'items'?: Array<WorkflowItem>;
        };
        }>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `workflowList$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  workflowList(params: {
    body: EntityGetRequest
  }): Observable<{
'isSuccess'?: boolean;
'message'?: string;
'data'?: {
'pageNumber'?: number;
'pageSize'?: number;
'totalRowCount'?: number;
'items'?: Array<WorkflowItem>;
};
}> {

    return this.workflowList$Response(params).pipe(
      map((r: StrictHttpResponse<{
'isSuccess'?: boolean;
'message'?: string;
'data'?: {
'pageNumber'?: number;
'pageSize'?: number;
'totalRowCount'?: number;
'items'?: Array<WorkflowItem>;
};
}>) => r.body as {
'isSuccess'?: boolean;
'message'?: string;
'data'?: {
'pageNumber'?: number;
'pageSize'?: number;
'totalRowCount'?: number;
'items'?: Array<WorkflowItem>;
};
})
    );
  }

  /**
   * Path part for operation workflowAddOrUpdate
   */
  static readonly WorkflowAddOrUpdatePath = '/v1/workflow/addOrUpdate';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `workflowAddOrUpdate()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  workflowAddOrUpdate$Response(params: {
    body: WorkflowRequest
  }): Observable<StrictHttpResponse<{
'isSuccess'?: boolean;
'message'?: string;
'data'?: string;
}>> {

    const rb = new RequestBuilder(this.rootUrl, ApiService.WorkflowAddOrUpdatePath, 'post');
    if (params) {
      rb.body(params.body, 'application/json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<{
        'isSuccess'?: boolean;
        'message'?: string;
        'data'?: string;
        }>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `workflowAddOrUpdate$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  workflowAddOrUpdate(params: {
    body: WorkflowRequest
  }): Observable<{
'isSuccess'?: boolean;
'message'?: string;
'data'?: string;
}> {

    return this.workflowAddOrUpdate$Response(params).pipe(
      map((r: StrictHttpResponse<{
'isSuccess'?: boolean;
'message'?: string;
'data'?: string;
}>) => r.body as {
'isSuccess'?: boolean;
'message'?: string;
'data'?: string;
})
    );
  }

  /**
   * Path part for operation workflowDelete
   */
  static readonly WorkflowDeletePath = '/v1/workflow/delete';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `workflowDelete()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  workflowDelete$Response(params: {
    body: DeleteRequest
  }): Observable<StrictHttpResponse<{
'isSuccess'?: boolean;
'message'?: string;
'data'?: string;
}>> {

    const rb = new RequestBuilder(this.rootUrl, ApiService.WorkflowDeletePath, 'delete');
    if (params) {
      rb.body(params.body, 'application/json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<{
        'isSuccess'?: boolean;
        'message'?: string;
        'data'?: string;
        }>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `workflowDelete$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  workflowDelete(params: {
    body: DeleteRequest
  }): Observable<{
'isSuccess'?: boolean;
'message'?: string;
'data'?: string;
}> {

    return this.workflowDelete$Response(params).pipe(
      map((r: StrictHttpResponse<{
'isSuccess'?: boolean;
'message'?: string;
'data'?: string;
}>) => r.body as {
'isSuccess'?: boolean;
'message'?: string;
'data'?: string;
})
    );
  }

  /**
   * Path part for operation workflowForVersion
   */
  static readonly WorkflowForVersionPath = '/v1/workflow/single/workflowVersion';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `workflowForVersion()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  workflowForVersion$Response(params: {
    body: WorkflowVersionRequest
  }): Observable<StrictHttpResponse<{
'isSuccess'?: boolean;
'message'?: string;
'data'?: {
'pageNumber'?: number;
'pageSize'?: number;
'totalRowCount'?: number;
'items'?: Array<WorkflowVersionItem>;
};
}>> {

    const rb = new RequestBuilder(this.rootUrl, ApiService.WorkflowForVersionPath, 'post');
    if (params) {
      rb.body(params.body, 'application/json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<{
        'isSuccess'?: boolean;
        'message'?: string;
        'data'?: {
        'pageNumber'?: number;
        'pageSize'?: number;
        'totalRowCount'?: number;
        'items'?: Array<WorkflowVersionItem>;
        };
        }>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `workflowForVersion$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  workflowForVersion(params: {
    body: WorkflowVersionRequest
  }): Observable<{
'isSuccess'?: boolean;
'message'?: string;
'data'?: {
'pageNumber'?: number;
'pageSize'?: number;
'totalRowCount'?: number;
'items'?: Array<WorkflowVersionItem>;
};
}> {

    return this.workflowForVersion$Response(params).pipe(
      map((r: StrictHttpResponse<{
'isSuccess'?: boolean;
'message'?: string;
'data'?: {
'pageNumber'?: number;
'pageSize'?: number;
'totalRowCount'?: number;
'items'?: Array<WorkflowVersionItem>;
};
}>) => r.body as {
'isSuccess'?: boolean;
'message'?: string;
'data'?: {
'pageNumber'?: number;
'pageSize'?: number;
'totalRowCount'?: number;
'items'?: Array<WorkflowVersionItem>;
};
})
    );
  }

  /**
   * Path part for operation workflowVersionList
   */
  static readonly WorkflowVersionListPath = '/v1/workflow/list/workflowVersion';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `workflowVersionList()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  workflowVersionList$Response(params: {
    body: WorkflowVersionRequest
  }): Observable<StrictHttpResponse<{
'isSuccess'?: boolean;
'message'?: string;
'data'?: {
'pageNumber'?: number;
'pageSize'?: number;
'totalRowCount'?: number;
'items'?: Array<WorkflowVersionItem>;
};
}>> {

    const rb = new RequestBuilder(this.rootUrl, ApiService.WorkflowVersionListPath, 'post');
    if (params) {
      rb.body(params.body, 'application/json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<{
        'isSuccess'?: boolean;
        'message'?: string;
        'data'?: {
        'pageNumber'?: number;
        'pageSize'?: number;
        'totalRowCount'?: number;
        'items'?: Array<WorkflowVersionItem>;
        };
        }>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `workflowVersionList$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  workflowVersionList(params: {
    body: WorkflowVersionRequest
  }): Observable<{
'isSuccess'?: boolean;
'message'?: string;
'data'?: {
'pageNumber'?: number;
'pageSize'?: number;
'totalRowCount'?: number;
'items'?: Array<WorkflowVersionItem>;
};
}> {

    return this.workflowVersionList$Response(params).pipe(
      map((r: StrictHttpResponse<{
'isSuccess'?: boolean;
'message'?: string;
'data'?: {
'pageNumber'?: number;
'pageSize'?: number;
'totalRowCount'?: number;
'items'?: Array<WorkflowVersionItem>;
};
}>) => r.body as {
'isSuccess'?: boolean;
'message'?: string;
'data'?: {
'pageNumber'?: number;
'pageSize'?: number;
'totalRowCount'?: number;
'items'?: Array<WorkflowVersionItem>;
};
})
    );
  }

  /**
   * Path part for operation workflowJobList
   */
  static readonly WorkflowJobListPath = '/v1/workflowJob/list';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `workflowJobList()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  workflowJobList$Response(params: {
    body: EntityGetRequest
  }): Observable<StrictHttpResponse<{
'isSuccess'?: boolean;
'message'?: string;
'data'?: {
'pageNumber'?: number;
'pageSize'?: number;
'totalRowCount'?: number;
'items'?: Array<JobItem>;
};
}>> {

    const rb = new RequestBuilder(this.rootUrl, ApiService.WorkflowJobListPath, 'post');
    if (params) {
      rb.body(params.body, 'application/json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<{
        'isSuccess'?: boolean;
        'message'?: string;
        'data'?: {
        'pageNumber'?: number;
        'pageSize'?: number;
        'totalRowCount'?: number;
        'items'?: Array<JobItem>;
        };
        }>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `workflowJobList$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  workflowJobList(params: {
    body: EntityGetRequest
  }): Observable<{
'isSuccess'?: boolean;
'message'?: string;
'data'?: {
'pageNumber'?: number;
'pageSize'?: number;
'totalRowCount'?: number;
'items'?: Array<JobItem>;
};
}> {

    return this.workflowJobList$Response(params).pipe(
      map((r: StrictHttpResponse<{
'isSuccess'?: boolean;
'message'?: string;
'data'?: {
'pageNumber'?: number;
'pageSize'?: number;
'totalRowCount'?: number;
'items'?: Array<JobItem>;
};
}>) => r.body as {
'isSuccess'?: boolean;
'message'?: string;
'data'?: {
'pageNumber'?: number;
'pageSize'?: number;
'totalRowCount'?: number;
'items'?: Array<JobItem>;
};
})
    );
  }

  /**
   * Path part for operation workflowJobTaskList
   */
  static readonly WorkflowJobTaskListPath = '/v1/workflowJob/list/workflowJobXTask';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `workflowJobTaskList()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  workflowJobTaskList$Response(params: {
    body: EntityGetRequest
  }): Observable<StrictHttpResponse<{
'isSuccess'?: boolean;
'message'?: string;
'data'?: {
'pageNumber'?: number;
'pageSize'?: number;
'totalRowCount'?: number;
'items'?: Array<WorkflowJobTaskItem>;
};
}>> {

    const rb = new RequestBuilder(this.rootUrl, ApiService.WorkflowJobTaskListPath, 'post');
    if (params) {
      rb.body(params.body, 'application/json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<{
        'isSuccess'?: boolean;
        'message'?: string;
        'data'?: {
        'pageNumber'?: number;
        'pageSize'?: number;
        'totalRowCount'?: number;
        'items'?: Array<WorkflowJobTaskItem>;
        };
        }>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `workflowJobTaskList$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  workflowJobTaskList(params: {
    body: EntityGetRequest
  }): Observable<{
'isSuccess'?: boolean;
'message'?: string;
'data'?: {
'pageNumber'?: number;
'pageSize'?: number;
'totalRowCount'?: number;
'items'?: Array<WorkflowJobTaskItem>;
};
}> {

    return this.workflowJobTaskList$Response(params).pipe(
      map((r: StrictHttpResponse<{
'isSuccess'?: boolean;
'message'?: string;
'data'?: {
'pageNumber'?: number;
'pageSize'?: number;
'totalRowCount'?: number;
'items'?: Array<WorkflowJobTaskItem>;
};
}>) => r.body as {
'isSuccess'?: boolean;
'message'?: string;
'data'?: {
'pageNumber'?: number;
'pageSize'?: number;
'totalRowCount'?: number;
'items'?: Array<WorkflowJobTaskItem>;
};
})
    );
  }

  /**
   * Path part for operation workflowJobLogList
   */
  static readonly WorkflowJobLogListPath = '/v1/workflowJob/list/workflowJobLogs';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `workflowJobLogList()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  workflowJobLogList$Response(params: {
    body: EntityGetRequest
  }): Observable<StrictHttpResponse<{
'isSuccess'?: boolean;
'message'?: string;
'data'?: {
'pageNumber'?: number;
'pageSize'?: number;
'totalRowCount'?: number;
'items'?: Array<WorkflowJobLogItem>;
};
}>> {

    const rb = new RequestBuilder(this.rootUrl, ApiService.WorkflowJobLogListPath, 'post');
    if (params) {
      rb.body(params.body, 'application/json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<{
        'isSuccess'?: boolean;
        'message'?: string;
        'data'?: {
        'pageNumber'?: number;
        'pageSize'?: number;
        'totalRowCount'?: number;
        'items'?: Array<WorkflowJobLogItem>;
        };
        }>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `workflowJobLogList$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  workflowJobLogList(params: {
    body: EntityGetRequest
  }): Observable<{
'isSuccess'?: boolean;
'message'?: string;
'data'?: {
'pageNumber'?: number;
'pageSize'?: number;
'totalRowCount'?: number;
'items'?: Array<WorkflowJobLogItem>;
};
}> {

    return this.workflowJobLogList$Response(params).pipe(
      map((r: StrictHttpResponse<{
'isSuccess'?: boolean;
'message'?: string;
'data'?: {
'pageNumber'?: number;
'pageSize'?: number;
'totalRowCount'?: number;
'items'?: Array<WorkflowJobLogItem>;
};
}>) => r.body as {
'isSuccess'?: boolean;
'message'?: string;
'data'?: {
'pageNumber'?: number;
'pageSize'?: number;
'totalRowCount'?: number;
'items'?: Array<WorkflowJobLogItem>;
};
})
    );
  }

  /**
   * Path part for operation workflowJobAdd
   */
  static readonly WorkflowJobAddPath = '/v1/workflowJob/add';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `workflowJobAdd()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  workflowJobAdd$Response(params: {
    body: JobRequest
  }): Observable<StrictHttpResponse<{
'isSuccess'?: boolean;
'message'?: string;
'data'?: string;
}>> {

    const rb = new RequestBuilder(this.rootUrl, ApiService.WorkflowJobAddPath, 'post');
    if (params) {
      rb.body(params.body, 'application/json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<{
        'isSuccess'?: boolean;
        'message'?: string;
        'data'?: string;
        }>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `workflowJobAdd$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  workflowJobAdd(params: {
    body: JobRequest
  }): Observable<{
'isSuccess'?: boolean;
'message'?: string;
'data'?: string;
}> {

    return this.workflowJobAdd$Response(params).pipe(
      map((r: StrictHttpResponse<{
'isSuccess'?: boolean;
'message'?: string;
'data'?: string;
}>) => r.body as {
'isSuccess'?: boolean;
'message'?: string;
'data'?: string;
})
    );
  }

  /**
   * Path part for operation triggerWorkflow
   */
  static readonly TriggerWorkflowPath = '/v1/workflowJob/trigger/workflow';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `triggerWorkflow()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  triggerWorkflow$Response(params: {
    body: JobRequest
  }): Observable<StrictHttpResponse<{
'isSuccess'?: boolean;
'message'?: string;
'data'?: string;
}>> {

    const rb = new RequestBuilder(this.rootUrl, ApiService.TriggerWorkflowPath, 'post');
    if (params) {
      rb.body(params.body, 'application/json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<{
        'isSuccess'?: boolean;
        'message'?: string;
        'data'?: string;
        }>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `triggerWorkflow$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  triggerWorkflow(params: {
    body: JobRequest
  }): Observable<{
'isSuccess'?: boolean;
'message'?: string;
'data'?: string;
}> {

    return this.triggerWorkflow$Response(params).pipe(
      map((r: StrictHttpResponse<{
'isSuccess'?: boolean;
'message'?: string;
'data'?: string;
}>) => r.body as {
'isSuccess'?: boolean;
'message'?: string;
'data'?: string;
})
    );
  }

  /**
   * Path part for operation workflowTaskList
   */
  static readonly WorkflowTaskListPath = '/v1/workflowTask/list';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `workflowTaskList()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  workflowTaskList$Response(params: {
    body: EntityGetRequest
  }): Observable<StrictHttpResponse<{
'isSuccess'?: boolean;
'message'?: string;
'data'?: {
'pageNumber'?: number;
'pageSize'?: number;
'totalRowCount'?: number;
'items'?: Array<TaskItem>;
};
}>> {

    const rb = new RequestBuilder(this.rootUrl, ApiService.WorkflowTaskListPath, 'post');
    if (params) {
      rb.body(params.body, 'application/json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<{
        'isSuccess'?: boolean;
        'message'?: string;
        'data'?: {
        'pageNumber'?: number;
        'pageSize'?: number;
        'totalRowCount'?: number;
        'items'?: Array<TaskItem>;
        };
        }>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `workflowTaskList$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  workflowTaskList(params: {
    body: EntityGetRequest
  }): Observable<{
'isSuccess'?: boolean;
'message'?: string;
'data'?: {
'pageNumber'?: number;
'pageSize'?: number;
'totalRowCount'?: number;
'items'?: Array<TaskItem>;
};
}> {

    return this.workflowTaskList$Response(params).pipe(
      map((r: StrictHttpResponse<{
'isSuccess'?: boolean;
'message'?: string;
'data'?: {
'pageNumber'?: number;
'pageSize'?: number;
'totalRowCount'?: number;
'items'?: Array<TaskItem>;
};
}>) => r.body as {
'isSuccess'?: boolean;
'message'?: string;
'data'?: {
'pageNumber'?: number;
'pageSize'?: number;
'totalRowCount'?: number;
'items'?: Array<TaskItem>;
};
})
    );
  }

  /**
   * Path part for operation workflowTaskAddOrUpdate
   */
  static readonly WorkflowTaskAddOrUpdatePath = '/v1/workflowTask/addOrUpdate';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `workflowTaskAddOrUpdate()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  workflowTaskAddOrUpdate$Response(params: {
    body: WorkflowTaskRequest
  }): Observable<StrictHttpResponse<{
'isSuccess'?: boolean;
'message'?: string;
'data'?: string;
}>> {

    const rb = new RequestBuilder(this.rootUrl, ApiService.WorkflowTaskAddOrUpdatePath, 'post');
    if (params) {
      rb.body(params.body, 'application/json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<{
        'isSuccess'?: boolean;
        'message'?: string;
        'data'?: string;
        }>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `workflowTaskAddOrUpdate$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  workflowTaskAddOrUpdate(params: {
    body: WorkflowTaskRequest
  }): Observable<{
'isSuccess'?: boolean;
'message'?: string;
'data'?: string;
}> {

    return this.workflowTaskAddOrUpdate$Response(params).pipe(
      map((r: StrictHttpResponse<{
'isSuccess'?: boolean;
'message'?: string;
'data'?: string;
}>) => r.body as {
'isSuccess'?: boolean;
'message'?: string;
'data'?: string;
})
    );
  }

  /**
   * Path part for operation workflowTaskInputOutputList
   */
  static readonly WorkflowTaskInputOutputListPath = '/v1/workflowTask/list/inputOutputs';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `workflowTaskInputOutputList()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  workflowTaskInputOutputList$Response(params: {
    body: EntityGetRequest
  }): Observable<StrictHttpResponse<{
'isSuccess'?: boolean;
'message'?: string;
'data'?: {
'pageNumber'?: number;
'pageSize'?: number;
'totalRowCount'?: number;
'items'?: Array<WorkflowTaskInputOutputItem>;
};
}>> {

    const rb = new RequestBuilder(this.rootUrl, ApiService.WorkflowTaskInputOutputListPath, 'post');
    if (params) {
      rb.body(params.body, 'application/json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<{
        'isSuccess'?: boolean;
        'message'?: string;
        'data'?: {
        'pageNumber'?: number;
        'pageSize'?: number;
        'totalRowCount'?: number;
        'items'?: Array<WorkflowTaskInputOutputItem>;
        };
        }>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `workflowTaskInputOutputList$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  workflowTaskInputOutputList(params: {
    body: EntityGetRequest
  }): Observable<{
'isSuccess'?: boolean;
'message'?: string;
'data'?: {
'pageNumber'?: number;
'pageSize'?: number;
'totalRowCount'?: number;
'items'?: Array<WorkflowTaskInputOutputItem>;
};
}> {

    return this.workflowTaskInputOutputList$Response(params).pipe(
      map((r: StrictHttpResponse<{
'isSuccess'?: boolean;
'message'?: string;
'data'?: {
'pageNumber'?: number;
'pageSize'?: number;
'totalRowCount'?: number;
'items'?: Array<WorkflowTaskInputOutputItem>;
};
}>) => r.body as {
'isSuccess'?: boolean;
'message'?: string;
'data'?: {
'pageNumber'?: number;
'pageSize'?: number;
'totalRowCount'?: number;
'items'?: Array<WorkflowTaskInputOutputItem>;
};
})
    );
  }

  /**
   * Path part for operation userCreditList
   */
  static readonly UserCreditListPath = '/v1/user-credit/list';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `userCreditList()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  userCreditList$Response(params: {
    body: EntityGetRequest
  }): Observable<StrictHttpResponse<{
'isSuccess'?: boolean;
'message'?: string;
'data'?: {
'pageNumber'?: number;
'pageSize'?: number;
'totalRowCount'?: number;
'items'?: Array<UserCreditItem>;
};
}>> {

    const rb = new RequestBuilder(this.rootUrl, ApiService.UserCreditListPath, 'post');
    if (params) {
      rb.body(params.body, 'application/json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<{
        'isSuccess'?: boolean;
        'message'?: string;
        'data'?: {
        'pageNumber'?: number;
        'pageSize'?: number;
        'totalRowCount'?: number;
        'items'?: Array<UserCreditItem>;
        };
        }>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `userCreditList$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  userCreditList(params: {
    body: EntityGetRequest
  }): Observable<{
'isSuccess'?: boolean;
'message'?: string;
'data'?: {
'pageNumber'?: number;
'pageSize'?: number;
'totalRowCount'?: number;
'items'?: Array<UserCreditItem>;
};
}> {

    return this.userCreditList$Response(params).pipe(
      map((r: StrictHttpResponse<{
'isSuccess'?: boolean;
'message'?: string;
'data'?: {
'pageNumber'?: number;
'pageSize'?: number;
'totalRowCount'?: number;
'items'?: Array<UserCreditItem>;
};
}>) => r.body as {
'isSuccess'?: boolean;
'message'?: string;
'data'?: {
'pageNumber'?: number;
'pageSize'?: number;
'totalRowCount'?: number;
'items'?: Array<UserCreditItem>;
};
})
    );
  }

  /**
   * Path part for operation userCreditAddOrUpdate
   */
  static readonly UserCreditAddOrUpdatePath = '/v1/user-credit/addOrUpdate';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `userCreditAddOrUpdate()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  userCreditAddOrUpdate$Response(params: {
    body: UserCreditRequest
  }): Observable<StrictHttpResponse<{
'isSuccess'?: boolean;
'message'?: string;
'data'?: string;
}>> {

    const rb = new RequestBuilder(this.rootUrl, ApiService.UserCreditAddOrUpdatePath, 'post');
    if (params) {
      rb.body(params.body, 'application/json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<{
        'isSuccess'?: boolean;
        'message'?: string;
        'data'?: string;
        }>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `userCreditAddOrUpdate$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  userCreditAddOrUpdate(params: {
    body: UserCreditRequest
  }): Observable<{
'isSuccess'?: boolean;
'message'?: string;
'data'?: string;
}> {

    return this.userCreditAddOrUpdate$Response(params).pipe(
      map((r: StrictHttpResponse<{
'isSuccess'?: boolean;
'message'?: string;
'data'?: string;
}>) => r.body as {
'isSuccess'?: boolean;
'message'?: string;
'data'?: string;
})
    );
  }

  /**
   * Path part for operation deleteUserCredit
   */
  static readonly DeleteUserCreditPath = '/v1/user-credit/delete/userCredit';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `deleteUserCredit()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  deleteUserCredit$Response(params: {
    body: UserCreditDeleteRequest
  }): Observable<StrictHttpResponse<{
'isSuccess'?: boolean;
'message'?: string;
'data'?: string;
}>> {

    const rb = new RequestBuilder(this.rootUrl, ApiService.DeleteUserCreditPath, 'post');
    if (params) {
      rb.body(params.body, 'application/json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<{
        'isSuccess'?: boolean;
        'message'?: string;
        'data'?: string;
        }>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `deleteUserCredit$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  deleteUserCredit(params: {
    body: UserCreditDeleteRequest
  }): Observable<{
'isSuccess'?: boolean;
'message'?: string;
'data'?: string;
}> {

    return this.deleteUserCredit$Response(params).pipe(
      map((r: StrictHttpResponse<{
'isSuccess'?: boolean;
'message'?: string;
'data'?: string;
}>) => r.body as {
'isSuccess'?: boolean;
'message'?: string;
'data'?: string;
})
    );
  }

  /**
   * Path part for operation getVehicleLienMaintenanceList
   */
  static readonly GetVehicleLienMaintenanceListPath = '/v1/vehicleLien/list/vehicleLienMaintenanceList';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getVehicleLienMaintenanceList()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  getVehicleLienMaintenanceList$Response(params: {
    body: EntityGetRequest
  }): Observable<StrictHttpResponse<{
'isSuccess'?: boolean;
'message'?: string;
'data'?: {
'pageNumber'?: number;
'pageSize'?: number;
'totalRowCount'?: number;
'items'?: Array<VehicleLienMaintenanceListItem>;
};
}>> {

    const rb = new RequestBuilder(this.rootUrl, ApiService.GetVehicleLienMaintenanceListPath, 'post');
    if (params) {
      rb.body(params.body, 'application/json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<{
        'isSuccess'?: boolean;
        'message'?: string;
        'data'?: {
        'pageNumber'?: number;
        'pageSize'?: number;
        'totalRowCount'?: number;
        'items'?: Array<VehicleLienMaintenanceListItem>;
        };
        }>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `getVehicleLienMaintenanceList$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  getVehicleLienMaintenanceList(params: {
    body: EntityGetRequest
  }): Observable<{
'isSuccess'?: boolean;
'message'?: string;
'data'?: {
'pageNumber'?: number;
'pageSize'?: number;
'totalRowCount'?: number;
'items'?: Array<VehicleLienMaintenanceListItem>;
};
}> {

    return this.getVehicleLienMaintenanceList$Response(params).pipe(
      map((r: StrictHttpResponse<{
'isSuccess'?: boolean;
'message'?: string;
'data'?: {
'pageNumber'?: number;
'pageSize'?: number;
'totalRowCount'?: number;
'items'?: Array<VehicleLienMaintenanceListItem>;
};
}>) => r.body as {
'isSuccess'?: boolean;
'message'?: string;
'data'?: {
'pageNumber'?: number;
'pageSize'?: number;
'totalRowCount'?: number;
'items'?: Array<VehicleLienMaintenanceListItem>;
};
})
    );
  }

  /**
   * Path part for operation vehicleLienSave
   */
  static readonly VehicleLienSavePath = '/v1/vehicleLien/add/vehicleLien';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `vehicleLienSave()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  vehicleLienSave$Response(params: {
    body: VehicleLienRequest
  }): Observable<StrictHttpResponse<{
'isSuccess'?: boolean;
'message'?: string;
'data'?: string;
}>> {

    const rb = new RequestBuilder(this.rootUrl, ApiService.VehicleLienSavePath, 'post');
    if (params) {
      rb.body(params.body, 'application/json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<{
        'isSuccess'?: boolean;
        'message'?: string;
        'data'?: string;
        }>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `vehicleLienSave$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  vehicleLienSave(params: {
    body: VehicleLienRequest
  }): Observable<{
'isSuccess'?: boolean;
'message'?: string;
'data'?: string;
}> {

    return this.vehicleLienSave$Response(params).pipe(
      map((r: StrictHttpResponse<{
'isSuccess'?: boolean;
'message'?: string;
'data'?: string;
}>) => r.body as {
'isSuccess'?: boolean;
'message'?: string;
'data'?: string;
})
    );
  }

  /**
   * Path part for operation listVehicleMake
   */
  static readonly ListVehicleMakePath = '/v1/vehicleLien/list/vehicleMake';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `listVehicleMake()` instead.
   *
   * This method doesn't expect any request body.
   */
  listVehicleMake$Response(params?: {
  }): Observable<StrictHttpResponse<{
'isSuccess'?: boolean;
'message'?: string;
'data'?: {
'pageNumber'?: number;
'pageSize'?: number;
'totalRowCount'?: number;
'items'?: Array<VehicleMake>;
};
}>> {

    const rb = new RequestBuilder(this.rootUrl, ApiService.ListVehicleMakePath, 'post');
    if (params) {
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<{
        'isSuccess'?: boolean;
        'message'?: string;
        'data'?: {
        'pageNumber'?: number;
        'pageSize'?: number;
        'totalRowCount'?: number;
        'items'?: Array<VehicleMake>;
        };
        }>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `listVehicleMake$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  listVehicleMake(params?: {
  }): Observable<{
'isSuccess'?: boolean;
'message'?: string;
'data'?: {
'pageNumber'?: number;
'pageSize'?: number;
'totalRowCount'?: number;
'items'?: Array<VehicleMake>;
};
}> {

    return this.listVehicleMake$Response(params).pipe(
      map((r: StrictHttpResponse<{
'isSuccess'?: boolean;
'message'?: string;
'data'?: {
'pageNumber'?: number;
'pageSize'?: number;
'totalRowCount'?: number;
'items'?: Array<VehicleMake>;
};
}>) => r.body as {
'isSuccess'?: boolean;
'message'?: string;
'data'?: {
'pageNumber'?: number;
'pageSize'?: number;
'totalRowCount'?: number;
'items'?: Array<VehicleMake>;
};
})
    );
  }

  /**
   * Path part for operation listVehicleBodyType
   */
  static readonly ListVehicleBodyTypePath = '/v1/vehicleLien/list/vehicleBodyType';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `listVehicleBodyType()` instead.
   *
   * This method doesn't expect any request body.
   */
  listVehicleBodyType$Response(params?: {
  }): Observable<StrictHttpResponse<{
'isSuccess'?: boolean;
'message'?: string;
'data'?: {
'pageNumber'?: number;
'pageSize'?: number;
'totalRowCount'?: number;
'items'?: Array<VehicleBodyType>;
};
}>> {

    const rb = new RequestBuilder(this.rootUrl, ApiService.ListVehicleBodyTypePath, 'post');
    if (params) {
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<{
        'isSuccess'?: boolean;
        'message'?: string;
        'data'?: {
        'pageNumber'?: number;
        'pageSize'?: number;
        'totalRowCount'?: number;
        'items'?: Array<VehicleBodyType>;
        };
        }>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `listVehicleBodyType$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  listVehicleBodyType(params?: {
  }): Observable<{
'isSuccess'?: boolean;
'message'?: string;
'data'?: {
'pageNumber'?: number;
'pageSize'?: number;
'totalRowCount'?: number;
'items'?: Array<VehicleBodyType>;
};
}> {

    return this.listVehicleBodyType$Response(params).pipe(
      map((r: StrictHttpResponse<{
'isSuccess'?: boolean;
'message'?: string;
'data'?: {
'pageNumber'?: number;
'pageSize'?: number;
'totalRowCount'?: number;
'items'?: Array<VehicleBodyType>;
};
}>) => r.body as {
'isSuccess'?: boolean;
'message'?: string;
'data'?: {
'pageNumber'?: number;
'pageSize'?: number;
'totalRowCount'?: number;
'items'?: Array<VehicleBodyType>;
};
})
    );
  }

  /**
   * Path part for operation listVehicleFuelType
   */
  static readonly ListVehicleFuelTypePath = '/v1/vehicleLien/list/vehicleFuelType';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `listVehicleFuelType()` instead.
   *
   * This method doesn't expect any request body.
   */
  listVehicleFuelType$Response(params?: {
  }): Observable<StrictHttpResponse<{
'isSuccess'?: boolean;
'message'?: string;
'data'?: {
'pageNumber'?: number;
'pageSize'?: number;
'totalRowCount'?: number;
'items'?: Array<VehicleFuelType>;
};
}>> {

    const rb = new RequestBuilder(this.rootUrl, ApiService.ListVehicleFuelTypePath, 'post');
    if (params) {
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<{
        'isSuccess'?: boolean;
        'message'?: string;
        'data'?: {
        'pageNumber'?: number;
        'pageSize'?: number;
        'totalRowCount'?: number;
        'items'?: Array<VehicleFuelType>;
        };
        }>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `listVehicleFuelType$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  listVehicleFuelType(params?: {
  }): Observable<{
'isSuccess'?: boolean;
'message'?: string;
'data'?: {
'pageNumber'?: number;
'pageSize'?: number;
'totalRowCount'?: number;
'items'?: Array<VehicleFuelType>;
};
}> {

    return this.listVehicleFuelType$Response(params).pipe(
      map((r: StrictHttpResponse<{
'isSuccess'?: boolean;
'message'?: string;
'data'?: {
'pageNumber'?: number;
'pageSize'?: number;
'totalRowCount'?: number;
'items'?: Array<VehicleFuelType>;
};
}>) => r.body as {
'isSuccess'?: boolean;
'message'?: string;
'data'?: {
'pageNumber'?: number;
'pageSize'?: number;
'totalRowCount'?: number;
'items'?: Array<VehicleFuelType>;
};
})
    );
  }

  /**
   * Path part for operation listVehicleLienStatus
   */
  static readonly ListVehicleLienStatusPath = '/v1/vehicleLien/list/vehicleLienStatus';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `listVehicleLienStatus()` instead.
   *
   * This method doesn't expect any request body.
   */
  listVehicleLienStatus$Response(params?: {
  }): Observable<StrictHttpResponse<{
'isSuccess'?: boolean;
'message'?: string;
'data'?: {
'pageNumber'?: number;
'pageSize'?: number;
'totalRowCount'?: number;
'items'?: Array<VehicleLienStatus>;
};
}>> {

    const rb = new RequestBuilder(this.rootUrl, ApiService.ListVehicleLienStatusPath, 'post');
    if (params) {
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<{
        'isSuccess'?: boolean;
        'message'?: string;
        'data'?: {
        'pageNumber'?: number;
        'pageSize'?: number;
        'totalRowCount'?: number;
        'items'?: Array<VehicleLienStatus>;
        };
        }>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `listVehicleLienStatus$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  listVehicleLienStatus(params?: {
  }): Observable<{
'isSuccess'?: boolean;
'message'?: string;
'data'?: {
'pageNumber'?: number;
'pageSize'?: number;
'totalRowCount'?: number;
'items'?: Array<VehicleLienStatus>;
};
}> {

    return this.listVehicleLienStatus$Response(params).pipe(
      map((r: StrictHttpResponse<{
'isSuccess'?: boolean;
'message'?: string;
'data'?: {
'pageNumber'?: number;
'pageSize'?: number;
'totalRowCount'?: number;
'items'?: Array<VehicleLienStatus>;
};
}>) => r.body as {
'isSuccess'?: boolean;
'message'?: string;
'data'?: {
'pageNumber'?: number;
'pageSize'?: number;
'totalRowCount'?: number;
'items'?: Array<VehicleLienStatus>;
};
})
    );
  }

  /**
   * Path part for operation listVehicleErrorMessage
   */
  static readonly ListVehicleErrorMessagePath = '/v1/vehicleLien/list/vehicleErrorMessage';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `listVehicleErrorMessage()` instead.
   *
   * This method doesn't expect any request body.
   */
  listVehicleErrorMessage$Response(params?: {
  }): Observable<StrictHttpResponse<{
'isSuccess'?: boolean;
'message'?: string;
'data'?: {
'pageNumber'?: number;
'pageSize'?: number;
'totalRowCount'?: number;
'items'?: Array<VehicleErrorMessage>;
};
}>> {

    const rb = new RequestBuilder(this.rootUrl, ApiService.ListVehicleErrorMessagePath, 'post');
    if (params) {
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<{
        'isSuccess'?: boolean;
        'message'?: string;
        'data'?: {
        'pageNumber'?: number;
        'pageSize'?: number;
        'totalRowCount'?: number;
        'items'?: Array<VehicleErrorMessage>;
        };
        }>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `listVehicleErrorMessage$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  listVehicleErrorMessage(params?: {
  }): Observable<{
'isSuccess'?: boolean;
'message'?: string;
'data'?: {
'pageNumber'?: number;
'pageSize'?: number;
'totalRowCount'?: number;
'items'?: Array<VehicleErrorMessage>;
};
}> {

    return this.listVehicleErrorMessage$Response(params).pipe(
      map((r: StrictHttpResponse<{
'isSuccess'?: boolean;
'message'?: string;
'data'?: {
'pageNumber'?: number;
'pageSize'?: number;
'totalRowCount'?: number;
'items'?: Array<VehicleErrorMessage>;
};
}>) => r.body as {
'isSuccess'?: boolean;
'message'?: string;
'data'?: {
'pageNumber'?: number;
'pageSize'?: number;
'totalRowCount'?: number;
'items'?: Array<VehicleErrorMessage>;
};
})
    );
  }

  /**
   * Path part for operation listVehicleLienReleaseReason
   */
  static readonly ListVehicleLienReleaseReasonPath = '/v1/vehicleLien/list/vehicleLienReleaseReason';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `listVehicleLienReleaseReason()` instead.
   *
   * This method doesn't expect any request body.
   */
  listVehicleLienReleaseReason$Response(params?: {
  }): Observable<StrictHttpResponse<{
'isSuccess'?: boolean;
'message'?: string;
'data'?: {
'pageNumber'?: number;
'pageSize'?: number;
'totalRowCount'?: number;
'items'?: Array<VehicleLienReleaseReason>;
};
}>> {

    const rb = new RequestBuilder(this.rootUrl, ApiService.ListVehicleLienReleaseReasonPath, 'post');
    if (params) {
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<{
        'isSuccess'?: boolean;
        'message'?: string;
        'data'?: {
        'pageNumber'?: number;
        'pageSize'?: number;
        'totalRowCount'?: number;
        'items'?: Array<VehicleLienReleaseReason>;
        };
        }>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `listVehicleLienReleaseReason$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  listVehicleLienReleaseReason(params?: {
  }): Observable<{
'isSuccess'?: boolean;
'message'?: string;
'data'?: {
'pageNumber'?: number;
'pageSize'?: number;
'totalRowCount'?: number;
'items'?: Array<VehicleLienReleaseReason>;
};
}> {

    return this.listVehicleLienReleaseReason$Response(params).pipe(
      map((r: StrictHttpResponse<{
'isSuccess'?: boolean;
'message'?: string;
'data'?: {
'pageNumber'?: number;
'pageSize'?: number;
'totalRowCount'?: number;
'items'?: Array<VehicleLienReleaseReason>;
};
}>) => r.body as {
'isSuccess'?: boolean;
'message'?: string;
'data'?: {
'pageNumber'?: number;
'pageSize'?: number;
'totalRowCount'?: number;
'items'?: Array<VehicleLienReleaseReason>;
};
})
    );
  }

  /**
   * Path part for operation listVehicleModel
   */
  static readonly ListVehicleModelPath = '/v1/vehicleLien/list/vehicleModel';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `listVehicleModel()` instead.
   *
   * This method doesn't expect any request body.
   */
  listVehicleModel$Response(params?: {
  }): Observable<StrictHttpResponse<{
'isSuccess'?: boolean;
'message'?: string;
'data'?: {
'pageNumber'?: number;
'pageSize'?: number;
'totalRowCount'?: number;
'items'?: Array<VehicleModel>;
};
}>> {

    const rb = new RequestBuilder(this.rootUrl, ApiService.ListVehicleModelPath, 'post');
    if (params) {
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<{
        'isSuccess'?: boolean;
        'message'?: string;
        'data'?: {
        'pageNumber'?: number;
        'pageSize'?: number;
        'totalRowCount'?: number;
        'items'?: Array<VehicleModel>;
        };
        }>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `listVehicleModel$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  listVehicleModel(params?: {
  }): Observable<{
'isSuccess'?: boolean;
'message'?: string;
'data'?: {
'pageNumber'?: number;
'pageSize'?: number;
'totalRowCount'?: number;
'items'?: Array<VehicleModel>;
};
}> {

    return this.listVehicleModel$Response(params).pipe(
      map((r: StrictHttpResponse<{
'isSuccess'?: boolean;
'message'?: string;
'data'?: {
'pageNumber'?: number;
'pageSize'?: number;
'totalRowCount'?: number;
'items'?: Array<VehicleModel>;
};
}>) => r.body as {
'isSuccess'?: boolean;
'message'?: string;
'data'?: {
'pageNumber'?: number;
'pageSize'?: number;
'totalRowCount'?: number;
'items'?: Array<VehicleModel>;
};
})
    );
  }

  /**
   * Path part for operation getVehicleLienList
   */
  static readonly GetVehicleLienListPath = '/v1/vehicleLien/vehicleLienList';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getVehicleLienList()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  getVehicleLienList$Response(params: {
    body: FetchVehicleLienRequest
  }): Observable<StrictHttpResponse<{
'isSuccess'?: boolean;
'message'?: string;
'data'?: {
'pageNumber'?: number;
'pageSize'?: number;
'totalRowCount'?: number;
'items'?: Array<VehicleLienDetails>;
};
}>> {

    const rb = new RequestBuilder(this.rootUrl, ApiService.GetVehicleLienListPath, 'post');
    if (params) {
      rb.body(params.body, 'application/json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<{
        'isSuccess'?: boolean;
        'message'?: string;
        'data'?: {
        'pageNumber'?: number;
        'pageSize'?: number;
        'totalRowCount'?: number;
        'items'?: Array<VehicleLienDetails>;
        };
        }>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `getVehicleLienList$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  getVehicleLienList(params: {
    body: FetchVehicleLienRequest
  }): Observable<{
'isSuccess'?: boolean;
'message'?: string;
'data'?: {
'pageNumber'?: number;
'pageSize'?: number;
'totalRowCount'?: number;
'items'?: Array<VehicleLienDetails>;
};
}> {

    return this.getVehicleLienList$Response(params).pipe(
      map((r: StrictHttpResponse<{
'isSuccess'?: boolean;
'message'?: string;
'data'?: {
'pageNumber'?: number;
'pageSize'?: number;
'totalRowCount'?: number;
'items'?: Array<VehicleLienDetails>;
};
}>) => r.body as {
'isSuccess'?: boolean;
'message'?: string;
'data'?: {
'pageNumber'?: number;
'pageSize'?: number;
'totalRowCount'?: number;
'items'?: Array<VehicleLienDetails>;
};
})
    );
  }

  /**
   * Path part for operation listOwnerStateList
   */
  static readonly ListOwnerStateListPath = '/v1/vehicleLien/list/ownerStateList';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `listOwnerStateList()` instead.
   *
   * This method doesn't expect any request body.
   */
  listOwnerStateList$Response(params?: {
  }): Observable<StrictHttpResponse<{
'isSuccess'?: boolean;
'message'?: string;
'data'?: {
'pageNumber'?: number;
'pageSize'?: number;
'totalRowCount'?: number;
'items'?: Array<OwnerStateList>;
};
}>> {

    const rb = new RequestBuilder(this.rootUrl, ApiService.ListOwnerStateListPath, 'post');
    if (params) {
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<{
        'isSuccess'?: boolean;
        'message'?: string;
        'data'?: {
        'pageNumber'?: number;
        'pageSize'?: number;
        'totalRowCount'?: number;
        'items'?: Array<OwnerStateList>;
        };
        }>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `listOwnerStateList$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  listOwnerStateList(params?: {
  }): Observable<{
'isSuccess'?: boolean;
'message'?: string;
'data'?: {
'pageNumber'?: number;
'pageSize'?: number;
'totalRowCount'?: number;
'items'?: Array<OwnerStateList>;
};
}> {

    return this.listOwnerStateList$Response(params).pipe(
      map((r: StrictHttpResponse<{
'isSuccess'?: boolean;
'message'?: string;
'data'?: {
'pageNumber'?: number;
'pageSize'?: number;
'totalRowCount'?: number;
'items'?: Array<OwnerStateList>;
};
}>) => r.body as {
'isSuccess'?: boolean;
'message'?: string;
'data'?: {
'pageNumber'?: number;
'pageSize'?: number;
'totalRowCount'?: number;
'items'?: Array<OwnerStateList>;
};
})
    );
  }

  /**
   * Path part for operation releaseVehicleLien
   */
  static readonly ReleaseVehicleLienPath = '/v1/vehicleLien/releaseVehicleLien';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `releaseVehicleLien()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  releaseVehicleLien$Response(params: {
    body: VehicleLienRequest
  }): Observable<StrictHttpResponse<{
'isSuccess'?: boolean;
'message'?: string;
'data'?: string;
}>> {

    const rb = new RequestBuilder(this.rootUrl, ApiService.ReleaseVehicleLienPath, 'post');
    if (params) {
      rb.body(params.body, 'application/json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<{
        'isSuccess'?: boolean;
        'message'?: string;
        'data'?: string;
        }>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `releaseVehicleLien$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  releaseVehicleLien(params: {
    body: VehicleLienRequest
  }): Observable<{
'isSuccess'?: boolean;
'message'?: string;
'data'?: string;
}> {

    return this.releaseVehicleLien$Response(params).pipe(
      map((r: StrictHttpResponse<{
'isSuccess'?: boolean;
'message'?: string;
'data'?: string;
}>) => r.body as {
'isSuccess'?: boolean;
'message'?: string;
'data'?: string;
})
    );
  }

  /**
   * Path part for operation getTravelRequestList
   */
  static readonly GetTravelRequestListPath = '/v1/travelRequest/list/travelRequest';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getTravelRequestList()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  getTravelRequestList$Response(params: {
    body: FetchTravelRequest
  }): Observable<StrictHttpResponse<{
'isSuccess'?: boolean;
'message'?: string;
'data'?: {
'pageNumber'?: number;
'pageSize'?: number;
'totalRowCount'?: number;
'items'?: Array<TravelRequestItem>;
};
}>> {

    const rb = new RequestBuilder(this.rootUrl, ApiService.GetTravelRequestListPath, 'post');
    if (params) {
      rb.body(params.body, 'application/json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<{
        'isSuccess'?: boolean;
        'message'?: string;
        'data'?: {
        'pageNumber'?: number;
        'pageSize'?: number;
        'totalRowCount'?: number;
        'items'?: Array<TravelRequestItem>;
        };
        }>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `getTravelRequestList$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  getTravelRequestList(params: {
    body: FetchTravelRequest
  }): Observable<{
'isSuccess'?: boolean;
'message'?: string;
'data'?: {
'pageNumber'?: number;
'pageSize'?: number;
'totalRowCount'?: number;
'items'?: Array<TravelRequestItem>;
};
}> {

    return this.getTravelRequestList$Response(params).pipe(
      map((r: StrictHttpResponse<{
'isSuccess'?: boolean;
'message'?: string;
'data'?: {
'pageNumber'?: number;
'pageSize'?: number;
'totalRowCount'?: number;
'items'?: Array<TravelRequestItem>;
};
}>) => r.body as {
'isSuccess'?: boolean;
'message'?: string;
'data'?: {
'pageNumber'?: number;
'pageSize'?: number;
'totalRowCount'?: number;
'items'?: Array<TravelRequestItem>;
};
})
    );
  }

  /**
   * Path part for operation travelRequestAdd
   */
  static readonly TravelRequestAddPath = '/v1/travelRequest/add/travelRequest';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `travelRequestAdd()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  travelRequestAdd$Response(params: {
    body: TravelRequestItem
  }): Observable<StrictHttpResponse<{
'isSuccess'?: boolean;
'message'?: string;
'data'?: string;
}>> {

    const rb = new RequestBuilder(this.rootUrl, ApiService.TravelRequestAddPath, 'post');
    if (params) {
      rb.body(params.body, 'application/json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<{
        'isSuccess'?: boolean;
        'message'?: string;
        'data'?: string;
        }>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `travelRequestAdd$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  travelRequestAdd(params: {
    body: TravelRequestItem
  }): Observable<{
'isSuccess'?: boolean;
'message'?: string;
'data'?: string;
}> {

    return this.travelRequestAdd$Response(params).pipe(
      map((r: StrictHttpResponse<{
'isSuccess'?: boolean;
'message'?: string;
'data'?: string;
}>) => r.body as {
'isSuccess'?: boolean;
'message'?: string;
'data'?: string;
})
    );
  }

  /**
   * Path part for operation travelRequestStatusList
   */
  static readonly TravelRequestStatusListPath = '/v1/travelRequest/list/travelRequestStatus';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `travelRequestStatusList()` instead.
   *
   * This method doesn't expect any request body.
   */
  travelRequestStatusList$Response(params?: {
  }): Observable<StrictHttpResponse<{
'isSuccess'?: boolean;
'message'?: string;
'data'?: {
'pageNumber'?: number;
'pageSize'?: number;
'totalRowCount'?: number;
'items'?: Array<TravelRequestStatusItem>;
};
}>> {

    const rb = new RequestBuilder(this.rootUrl, ApiService.TravelRequestStatusListPath, 'post');
    if (params) {
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<{
        'isSuccess'?: boolean;
        'message'?: string;
        'data'?: {
        'pageNumber'?: number;
        'pageSize'?: number;
        'totalRowCount'?: number;
        'items'?: Array<TravelRequestStatusItem>;
        };
        }>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `travelRequestStatusList$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  travelRequestStatusList(params?: {
  }): Observable<{
'isSuccess'?: boolean;
'message'?: string;
'data'?: {
'pageNumber'?: number;
'pageSize'?: number;
'totalRowCount'?: number;
'items'?: Array<TravelRequestStatusItem>;
};
}> {

    return this.travelRequestStatusList$Response(params).pipe(
      map((r: StrictHttpResponse<{
'isSuccess'?: boolean;
'message'?: string;
'data'?: {
'pageNumber'?: number;
'pageSize'?: number;
'totalRowCount'?: number;
'items'?: Array<TravelRequestStatusItem>;
};
}>) => r.body as {
'isSuccess'?: boolean;
'message'?: string;
'data'?: {
'pageNumber'?: number;
'pageSize'?: number;
'totalRowCount'?: number;
'items'?: Array<TravelRequestStatusItem>;
};
})
    );
  }

  /**
   * Path part for operation travelRequestMaterialAdd
   */
  static readonly TravelRequestMaterialAddPath = '/v1/travelRequest/add/travelRequest/material';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `travelRequestMaterialAdd()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  travelRequestMaterialAdd$Response(params: {
    body: TravelRequestMaterialAddRequest
  }): Observable<StrictHttpResponse<{
'isSuccess'?: boolean;
'message'?: string;
'data'?: string;
}>> {

    const rb = new RequestBuilder(this.rootUrl, ApiService.TravelRequestMaterialAddPath, 'post');
    if (params) {
      rb.body(params.body, 'application/json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<{
        'isSuccess'?: boolean;
        'message'?: string;
        'data'?: string;
        }>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `travelRequestMaterialAdd$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  travelRequestMaterialAdd(params: {
    body: TravelRequestMaterialAddRequest
  }): Observable<{
'isSuccess'?: boolean;
'message'?: string;
'data'?: string;
}> {

    return this.travelRequestMaterialAdd$Response(params).pipe(
      map((r: StrictHttpResponse<{
'isSuccess'?: boolean;
'message'?: string;
'data'?: string;
}>) => r.body as {
'isSuccess'?: boolean;
'message'?: string;
'data'?: string;
})
    );
  }

  /**
   * Path part for operation updateTravelRequestStatus
   */
  static readonly UpdateTravelRequestStatusPath = '/v1/travelRequest/add/updateTravelRequestStatus';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `updateTravelRequestStatus()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  updateTravelRequestStatus$Response(params: {
    body: TravelRequestItem
  }): Observable<StrictHttpResponse<{
'isSuccess'?: boolean;
'message'?: string;
'data'?: string;
}>> {

    const rb = new RequestBuilder(this.rootUrl, ApiService.UpdateTravelRequestStatusPath, 'post');
    if (params) {
      rb.body(params.body, 'application/json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<{
        'isSuccess'?: boolean;
        'message'?: string;
        'data'?: string;
        }>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `updateTravelRequestStatus$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  updateTravelRequestStatus(params: {
    body: TravelRequestItem
  }): Observable<{
'isSuccess'?: boolean;
'message'?: string;
'data'?: string;
}> {

    return this.updateTravelRequestStatus$Response(params).pipe(
      map((r: StrictHttpResponse<{
'isSuccess'?: boolean;
'message'?: string;
'data'?: string;
}>) => r.body as {
'isSuccess'?: boolean;
'message'?: string;
'data'?: string;
})
    );
  }

  /**
   * Path part for operation getCustomerStaffPreferenceList
   */
  static readonly GetCustomerStaffPreferenceListPath = '/v1/scheduleAppointment/getCustomerStaffPreferenceList';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getCustomerStaffPreferenceList()` instead.
   *
   * This method doesn't expect any request body.
   */
  getCustomerStaffPreferenceList$Response(params?: {
  }): Observable<StrictHttpResponse<{
'isSuccess'?: boolean;
'message'?: string;
'data'?: {
'pageNumber'?: number;
'pageSize'?: number;
'totalRowCount'?: number;
'items'?: Array<CustomerAppointmentStaffPreferenceItem>;
};
}>> {

    const rb = new RequestBuilder(this.rootUrl, ApiService.GetCustomerStaffPreferenceListPath, 'post');
    if (params) {
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<{
        'isSuccess'?: boolean;
        'message'?: string;
        'data'?: {
        'pageNumber'?: number;
        'pageSize'?: number;
        'totalRowCount'?: number;
        'items'?: Array<CustomerAppointmentStaffPreferenceItem>;
        };
        }>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `getCustomerStaffPreferenceList$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getCustomerStaffPreferenceList(params?: {
  }): Observable<{
'isSuccess'?: boolean;
'message'?: string;
'data'?: {
'pageNumber'?: number;
'pageSize'?: number;
'totalRowCount'?: number;
'items'?: Array<CustomerAppointmentStaffPreferenceItem>;
};
}> {

    return this.getCustomerStaffPreferenceList$Response(params).pipe(
      map((r: StrictHttpResponse<{
'isSuccess'?: boolean;
'message'?: string;
'data'?: {
'pageNumber'?: number;
'pageSize'?: number;
'totalRowCount'?: number;
'items'?: Array<CustomerAppointmentStaffPreferenceItem>;
};
}>) => r.body as {
'isSuccess'?: boolean;
'message'?: string;
'data'?: {
'pageNumber'?: number;
'pageSize'?: number;
'totalRowCount'?: number;
'items'?: Array<CustomerAppointmentStaffPreferenceItem>;
};
})
    );
  }

  /**
   * Path part for operation scheduleAppointmentCategoryList
   */
  static readonly ScheduleAppointmentCategoryListPath = '/v1/scheduleAppointment/list/scheduleAppointmentCategory';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `scheduleAppointmentCategoryList()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  scheduleAppointmentCategoryList$Response(params: {
    body: EntityGetRequest
  }): Observable<StrictHttpResponse<{
'isSuccess'?: boolean;
'message'?: string;
'data'?: {
'pageNumber'?: number;
'pageSize'?: number;
'totalRowCount'?: number;
'items'?: Array<ScheduleAppointmentCategoryItem>;
};
}>> {

    const rb = new RequestBuilder(this.rootUrl, ApiService.ScheduleAppointmentCategoryListPath, 'post');
    if (params) {
      rb.body(params.body, 'application/json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<{
        'isSuccess'?: boolean;
        'message'?: string;
        'data'?: {
        'pageNumber'?: number;
        'pageSize'?: number;
        'totalRowCount'?: number;
        'items'?: Array<ScheduleAppointmentCategoryItem>;
        };
        }>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `scheduleAppointmentCategoryList$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  scheduleAppointmentCategoryList(params: {
    body: EntityGetRequest
  }): Observable<{
'isSuccess'?: boolean;
'message'?: string;
'data'?: {
'pageNumber'?: number;
'pageSize'?: number;
'totalRowCount'?: number;
'items'?: Array<ScheduleAppointmentCategoryItem>;
};
}> {

    return this.scheduleAppointmentCategoryList$Response(params).pipe(
      map((r: StrictHttpResponse<{
'isSuccess'?: boolean;
'message'?: string;
'data'?: {
'pageNumber'?: number;
'pageSize'?: number;
'totalRowCount'?: number;
'items'?: Array<ScheduleAppointmentCategoryItem>;
};
}>) => r.body as {
'isSuccess'?: boolean;
'message'?: string;
'data'?: {
'pageNumber'?: number;
'pageSize'?: number;
'totalRowCount'?: number;
'items'?: Array<ScheduleAppointmentCategoryItem>;
};
})
    );
  }

  /**
   * Path part for operation languagePreferenceList_1
   */
  static readonly LanguagePreferenceList_1Path = '/v1/scheduleAppointment/list/languagePreference';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `languagePreferenceList_1()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  languagePreferenceList_1$Response(params: {
    body: LanguagePreferenceRequest
  }): Observable<StrictHttpResponse<{
'isSuccess'?: boolean;
'message'?: string;
'data'?: {
'pageNumber'?: number;
'pageSize'?: number;
'totalRowCount'?: number;
'items'?: Array<LanguagePreferenceItem>;
};
}>> {

    const rb = new RequestBuilder(this.rootUrl, ApiService.LanguagePreferenceList_1Path, 'post');
    if (params) {
      rb.body(params.body, 'application/json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<{
        'isSuccess'?: boolean;
        'message'?: string;
        'data'?: {
        'pageNumber'?: number;
        'pageSize'?: number;
        'totalRowCount'?: number;
        'items'?: Array<LanguagePreferenceItem>;
        };
        }>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `languagePreferenceList_1$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  languagePreferenceList_1(params: {
    body: LanguagePreferenceRequest
  }): Observable<{
'isSuccess'?: boolean;
'message'?: string;
'data'?: {
'pageNumber'?: number;
'pageSize'?: number;
'totalRowCount'?: number;
'items'?: Array<LanguagePreferenceItem>;
};
}> {

    return this.languagePreferenceList_1$Response(params).pipe(
      map((r: StrictHttpResponse<{
'isSuccess'?: boolean;
'message'?: string;
'data'?: {
'pageNumber'?: number;
'pageSize'?: number;
'totalRowCount'?: number;
'items'?: Array<LanguagePreferenceItem>;
};
}>) => r.body as {
'isSuccess'?: boolean;
'message'?: string;
'data'?: {
'pageNumber'?: number;
'pageSize'?: number;
'totalRowCount'?: number;
'items'?: Array<LanguagePreferenceItem>;
};
})
    );
  }

  /**
   * Path part for operation customerAppointmentAttendeeAdd
   */
  static readonly CustomerAppointmentAttendeeAddPath = '/v1/scheduleAppointment/add/customerAppointmentAttendee';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `customerAppointmentAttendeeAdd()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  customerAppointmentAttendeeAdd$Response(params: {
    body: Array<string>
  }): Observable<StrictHttpResponse<{
'isSuccess'?: boolean;
'message'?: string;
'data'?: string;
}>> {

    const rb = new RequestBuilder(this.rootUrl, ApiService.CustomerAppointmentAttendeeAddPath, 'post');
    if (params) {
      rb.body(params.body, 'application/json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<{
        'isSuccess'?: boolean;
        'message'?: string;
        'data'?: string;
        }>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `customerAppointmentAttendeeAdd$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  customerAppointmentAttendeeAdd(params: {
    body: Array<string>
  }): Observable<{
'isSuccess'?: boolean;
'message'?: string;
'data'?: string;
}> {

    return this.customerAppointmentAttendeeAdd$Response(params).pipe(
      map((r: StrictHttpResponse<{
'isSuccess'?: boolean;
'message'?: string;
'data'?: string;
}>) => r.body as {
'isSuccess'?: boolean;
'message'?: string;
'data'?: string;
})
    );
  }

  /**
   * Path part for operation holidayList
   */
  static readonly HolidayListPath = '/v1/scheduleAppointment/list/holiday';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `holidayList()` instead.
   *
   * This method doesn't expect any request body.
   */
  holidayList$Response(params?: {
  }): Observable<StrictHttpResponse<{
'isSuccess'?: boolean;
'message'?: string;
'data'?: {
'pageNumber'?: number;
'pageSize'?: number;
'totalRowCount'?: number;
'items'?: Array<HolidayItem>;
};
}>> {

    const rb = new RequestBuilder(this.rootUrl, ApiService.HolidayListPath, 'post');
    if (params) {
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<{
        'isSuccess'?: boolean;
        'message'?: string;
        'data'?: {
        'pageNumber'?: number;
        'pageSize'?: number;
        'totalRowCount'?: number;
        'items'?: Array<HolidayItem>;
        };
        }>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `holidayList$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  holidayList(params?: {
  }): Observable<{
'isSuccess'?: boolean;
'message'?: string;
'data'?: {
'pageNumber'?: number;
'pageSize'?: number;
'totalRowCount'?: number;
'items'?: Array<HolidayItem>;
};
}> {

    return this.holidayList$Response(params).pipe(
      map((r: StrictHttpResponse<{
'isSuccess'?: boolean;
'message'?: string;
'data'?: {
'pageNumber'?: number;
'pageSize'?: number;
'totalRowCount'?: number;
'items'?: Array<HolidayItem>;
};
}>) => r.body as {
'isSuccess'?: boolean;
'message'?: string;
'data'?: {
'pageNumber'?: number;
'pageSize'?: number;
'totalRowCount'?: number;
'items'?: Array<HolidayItem>;
};
})
    );
  }

  /**
   * Path part for operation customerAppointmentAddOrUpdate
   */
  static readonly CustomerAppointmentAddOrUpdatePath = '/v1/scheduleAppointment/addOrUpdate/customerAppointment';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `customerAppointmentAddOrUpdate()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  customerAppointmentAddOrUpdate$Response(params: {
    body: CustomerAppointmentItem
  }): Observable<StrictHttpResponse<{
'isSuccess'?: boolean;
'message'?: string;
'data'?: string;
}>> {

    const rb = new RequestBuilder(this.rootUrl, ApiService.CustomerAppointmentAddOrUpdatePath, 'post');
    if (params) {
      rb.body(params.body, 'application/json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<{
        'isSuccess'?: boolean;
        'message'?: string;
        'data'?: string;
        }>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `customerAppointmentAddOrUpdate$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  customerAppointmentAddOrUpdate(params: {
    body: CustomerAppointmentItem
  }): Observable<{
'isSuccess'?: boolean;
'message'?: string;
'data'?: string;
}> {

    return this.customerAppointmentAddOrUpdate$Response(params).pipe(
      map((r: StrictHttpResponse<{
'isSuccess'?: boolean;
'message'?: string;
'data'?: string;
}>) => r.body as {
'isSuccess'?: boolean;
'message'?: string;
'data'?: string;
})
    );
  }

  /**
   * Path part for operation getScheduledCustomerAppointmentDetails
   */
  static readonly GetScheduledCustomerAppointmentDetailsPath = '/v1/scheduleAppointment/getScheduledCustomerAppointmentDetails';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getScheduledCustomerAppointmentDetails()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  getScheduledCustomerAppointmentDetails$Response(params: {
    body: FetchCustomerAppointmentRequest
  }): Observable<StrictHttpResponse<{
'isSuccess'?: boolean;
'message'?: string;
'data'?: {
'pageNumber'?: number;
'pageSize'?: number;
'totalRowCount'?: number;
'items'?: Array<CustomerAppointmentItem>;
};
}>> {

    const rb = new RequestBuilder(this.rootUrl, ApiService.GetScheduledCustomerAppointmentDetailsPath, 'post');
    if (params) {
      rb.body(params.body, 'application/json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<{
        'isSuccess'?: boolean;
        'message'?: string;
        'data'?: {
        'pageNumber'?: number;
        'pageSize'?: number;
        'totalRowCount'?: number;
        'items'?: Array<CustomerAppointmentItem>;
        };
        }>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `getScheduledCustomerAppointmentDetails$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  getScheduledCustomerAppointmentDetails(params: {
    body: FetchCustomerAppointmentRequest
  }): Observable<{
'isSuccess'?: boolean;
'message'?: string;
'data'?: {
'pageNumber'?: number;
'pageSize'?: number;
'totalRowCount'?: number;
'items'?: Array<CustomerAppointmentItem>;
};
}> {

    return this.getScheduledCustomerAppointmentDetails$Response(params).pipe(
      map((r: StrictHttpResponse<{
'isSuccess'?: boolean;
'message'?: string;
'data'?: {
'pageNumber'?: number;
'pageSize'?: number;
'totalRowCount'?: number;
'items'?: Array<CustomerAppointmentItem>;
};
}>) => r.body as {
'isSuccess'?: boolean;
'message'?: string;
'data'?: {
'pageNumber'?: number;
'pageSize'?: number;
'totalRowCount'?: number;
'items'?: Array<CustomerAppointmentItem>;
};
})
    );
  }

  /**
   * Path part for operation getUserAppointmentAvailabilityList
   */
  static readonly GetUserAppointmentAvailabilityListPath = '/v1/scheduleAppointment/list/userAppointmentAvailability';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getUserAppointmentAvailabilityList()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  getUserAppointmentAvailabilityList$Response(params: {
    body: UserAvailabilityRequest
  }): Observable<StrictHttpResponse<{
'isSuccess'?: boolean;
'message'?: string;
'data'?: AvailableTimeSlotDay;
}>> {

    const rb = new RequestBuilder(this.rootUrl, ApiService.GetUserAppointmentAvailabilityListPath, 'post');
    if (params) {
      rb.body(params.body, 'application/json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<{
        'isSuccess'?: boolean;
        'message'?: string;
        'data'?: AvailableTimeSlotDay;
        }>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `getUserAppointmentAvailabilityList$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  getUserAppointmentAvailabilityList(params: {
    body: UserAvailabilityRequest
  }): Observable<{
'isSuccess'?: boolean;
'message'?: string;
'data'?: AvailableTimeSlotDay;
}> {

    return this.getUserAppointmentAvailabilityList$Response(params).pipe(
      map((r: StrictHttpResponse<{
'isSuccess'?: boolean;
'message'?: string;
'data'?: AvailableTimeSlotDay;
}>) => r.body as {
'isSuccess'?: boolean;
'message'?: string;
'data'?: AvailableTimeSlotDay;
})
    );
  }

  /**
   * Path part for operation isUserWorkingElsewhere
   */
  static readonly IsUserWorkingElsewherePath = '/v1/scheduleAppointment/userWorkingElsewhereStatus';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `isUserWorkingElsewhere()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  isUserWorkingElsewhere$Response(params: {
    body: IsUserWorkingElsewhereRequest
  }): Observable<StrictHttpResponse<{
'isSuccess'?: boolean;
'message'?: string;
'data'?: IsUserWorkingElsewhere;
}>> {

    const rb = new RequestBuilder(this.rootUrl, ApiService.IsUserWorkingElsewherePath, 'post');
    if (params) {
      rb.body(params.body, 'application/json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<{
        'isSuccess'?: boolean;
        'message'?: string;
        'data'?: IsUserWorkingElsewhere;
        }>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `isUserWorkingElsewhere$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  isUserWorkingElsewhere(params: {
    body: IsUserWorkingElsewhereRequest
  }): Observable<{
'isSuccess'?: boolean;
'message'?: string;
'data'?: IsUserWorkingElsewhere;
}> {

    return this.isUserWorkingElsewhere$Response(params).pipe(
      map((r: StrictHttpResponse<{
'isSuccess'?: boolean;
'message'?: string;
'data'?: IsUserWorkingElsewhere;
}>) => r.body as {
'isSuccess'?: boolean;
'message'?: string;
'data'?: IsUserWorkingElsewhere;
})
    );
  }

  /**
   * Path part for operation getCustomerAppointmentList
   */
  static readonly GetCustomerAppointmentListPath = '/v1/scheduleAppointment/getCustomerAppointmentList';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getCustomerAppointmentList()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  getCustomerAppointmentList$Response(params: {
    body: FetchCustomerAppointmentRequest
  }): Observable<StrictHttpResponse<{
'isSuccess'?: boolean;
'message'?: string;
'data'?: {
'pageNumber'?: number;
'pageSize'?: number;
'totalRowCount'?: number;
'items'?: Array<CustomerAppointmentItem>;
};
}>> {

    const rb = new RequestBuilder(this.rootUrl, ApiService.GetCustomerAppointmentListPath, 'post');
    if (params) {
      rb.body(params.body, 'application/json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<{
        'isSuccess'?: boolean;
        'message'?: string;
        'data'?: {
        'pageNumber'?: number;
        'pageSize'?: number;
        'totalRowCount'?: number;
        'items'?: Array<CustomerAppointmentItem>;
        };
        }>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `getCustomerAppointmentList$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  getCustomerAppointmentList(params: {
    body: FetchCustomerAppointmentRequest
  }): Observable<{
'isSuccess'?: boolean;
'message'?: string;
'data'?: {
'pageNumber'?: number;
'pageSize'?: number;
'totalRowCount'?: number;
'items'?: Array<CustomerAppointmentItem>;
};
}> {

    return this.getCustomerAppointmentList$Response(params).pipe(
      map((r: StrictHttpResponse<{
'isSuccess'?: boolean;
'message'?: string;
'data'?: {
'pageNumber'?: number;
'pageSize'?: number;
'totalRowCount'?: number;
'items'?: Array<CustomerAppointmentItem>;
};
}>) => r.body as {
'isSuccess'?: boolean;
'message'?: string;
'data'?: {
'pageNumber'?: number;
'pageSize'?: number;
'totalRowCount'?: number;
'items'?: Array<CustomerAppointmentItem>;
};
})
    );
  }

  /**
   * Path part for operation getMeetingPreferenceList
   */
  static readonly GetMeetingPreferenceListPath = '/v1/scheduleAppointment/getMeetingPreferenceList';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getMeetingPreferenceList()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  getMeetingPreferenceList$Response(params: {
    body: EntityGetRequest
  }): Observable<StrictHttpResponse<{
'isSuccess'?: boolean;
'message'?: string;
'data'?: {
'pageNumber'?: number;
'pageSize'?: number;
'totalRowCount'?: number;
'items'?: Array<MeetingPreferenceItem>;
};
}>> {

    const rb = new RequestBuilder(this.rootUrl, ApiService.GetMeetingPreferenceListPath, 'post');
    if (params) {
      rb.body(params.body, 'application/json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<{
        'isSuccess'?: boolean;
        'message'?: string;
        'data'?: {
        'pageNumber'?: number;
        'pageSize'?: number;
        'totalRowCount'?: number;
        'items'?: Array<MeetingPreferenceItem>;
        };
        }>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `getMeetingPreferenceList$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  getMeetingPreferenceList(params: {
    body: EntityGetRequest
  }): Observable<{
'isSuccess'?: boolean;
'message'?: string;
'data'?: {
'pageNumber'?: number;
'pageSize'?: number;
'totalRowCount'?: number;
'items'?: Array<MeetingPreferenceItem>;
};
}> {

    return this.getMeetingPreferenceList$Response(params).pipe(
      map((r: StrictHttpResponse<{
'isSuccess'?: boolean;
'message'?: string;
'data'?: {
'pageNumber'?: number;
'pageSize'?: number;
'totalRowCount'?: number;
'items'?: Array<MeetingPreferenceItem>;
};
}>) => r.body as {
'isSuccess'?: boolean;
'message'?: string;
'data'?: {
'pageNumber'?: number;
'pageSize'?: number;
'totalRowCount'?: number;
'items'?: Array<MeetingPreferenceItem>;
};
})
    );
  }

  /**
   * Path part for operation updateCustomerAppointmentMeetingPreference
   */
  static readonly UpdateCustomerAppointmentMeetingPreferencePath = '/v1/scheduleAppointment/update/customerAppointmentMeetingPreference';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `updateCustomerAppointmentMeetingPreference()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  updateCustomerAppointmentMeetingPreference$Response(params: {
    body: CustomerAppointmentUpdateRequest
  }): Observable<StrictHttpResponse<{
'isSuccess'?: boolean;
'message'?: string;
'data'?: string;
}>> {

    const rb = new RequestBuilder(this.rootUrl, ApiService.UpdateCustomerAppointmentMeetingPreferencePath, 'post');
    if (params) {
      rb.body(params.body, 'application/json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<{
        'isSuccess'?: boolean;
        'message'?: string;
        'data'?: string;
        }>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `updateCustomerAppointmentMeetingPreference$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  updateCustomerAppointmentMeetingPreference(params: {
    body: CustomerAppointmentUpdateRequest
  }): Observable<{
'isSuccess'?: boolean;
'message'?: string;
'data'?: string;
}> {

    return this.updateCustomerAppointmentMeetingPreference$Response(params).pipe(
      map((r: StrictHttpResponse<{
'isSuccess'?: boolean;
'message'?: string;
'data'?: string;
}>) => r.body as {
'isSuccess'?: boolean;
'message'?: string;
'data'?: string;
})
    );
  }

  /**
   * Path part for operation updateCustomerAppointmentSchedule
   */
  static readonly UpdateCustomerAppointmentSchedulePath = '/v1/scheduleAppointment/update/customerAppointmentSchedule';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `updateCustomerAppointmentSchedule()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  updateCustomerAppointmentSchedule$Response(params: {
    body: CustomerAppointmentUpdateRequest
  }): Observable<StrictHttpResponse<{
'isSuccess'?: boolean;
'message'?: string;
'data'?: string;
}>> {

    const rb = new RequestBuilder(this.rootUrl, ApiService.UpdateCustomerAppointmentSchedulePath, 'post');
    if (params) {
      rb.body(params.body, 'application/json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<{
        'isSuccess'?: boolean;
        'message'?: string;
        'data'?: string;
        }>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `updateCustomerAppointmentSchedule$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  updateCustomerAppointmentSchedule(params: {
    body: CustomerAppointmentUpdateRequest
  }): Observable<{
'isSuccess'?: boolean;
'message'?: string;
'data'?: string;
}> {

    return this.updateCustomerAppointmentSchedule$Response(params).pipe(
      map((r: StrictHttpResponse<{
'isSuccess'?: boolean;
'message'?: string;
'data'?: string;
}>) => r.body as {
'isSuccess'?: boolean;
'message'?: string;
'data'?: string;
})
    );
  }

  /**
   * Path part for operation cancelCustomerAppointment
   */
  static readonly CancelCustomerAppointmentPath = '/v1/scheduleAppointment/cancel/customerAppointment';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `cancelCustomerAppointment()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  cancelCustomerAppointment$Response(params: {
    body: CustomerAppointmentUpdateRequest
  }): Observable<StrictHttpResponse<{
'isSuccess'?: boolean;
'message'?: string;
'data'?: string;
}>> {

    const rb = new RequestBuilder(this.rootUrl, ApiService.CancelCustomerAppointmentPath, 'post');
    if (params) {
      rb.body(params.body, 'application/json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<{
        'isSuccess'?: boolean;
        'message'?: string;
        'data'?: string;
        }>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `cancelCustomerAppointment$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  cancelCustomerAppointment(params: {
    body: CustomerAppointmentUpdateRequest
  }): Observable<{
'isSuccess'?: boolean;
'message'?: string;
'data'?: string;
}> {

    return this.cancelCustomerAppointment$Response(params).pipe(
      map((r: StrictHttpResponse<{
'isSuccess'?: boolean;
'message'?: string;
'data'?: string;
}>) => r.body as {
'isSuccess'?: boolean;
'message'?: string;
'data'?: string;
})
    );
  }

  /**
   * Path part for operation verifyCustomerAppointment
   */
  static readonly VerifyCustomerAppointmentPath = '/v1/scheduleAppointment/verify/customerAppointment';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `verifyCustomerAppointment()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  verifyCustomerAppointment$Response(params: {
    body: CustomerAppointmentVerificationRequest
  }): Observable<StrictHttpResponse<{
'isSuccess'?: boolean;
'message'?: string;
'data'?: CustomerAppointmentVerificationItem;
}>> {

    const rb = new RequestBuilder(this.rootUrl, ApiService.VerifyCustomerAppointmentPath, 'post');
    if (params) {
      rb.body(params.body, 'application/json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<{
        'isSuccess'?: boolean;
        'message'?: string;
        'data'?: CustomerAppointmentVerificationItem;
        }>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `verifyCustomerAppointment$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  verifyCustomerAppointment(params: {
    body: CustomerAppointmentVerificationRequest
  }): Observable<{
'isSuccess'?: boolean;
'message'?: string;
'data'?: CustomerAppointmentVerificationItem;
}> {

    return this.verifyCustomerAppointment$Response(params).pipe(
      map((r: StrictHttpResponse<{
'isSuccess'?: boolean;
'message'?: string;
'data'?: CustomerAppointmentVerificationItem;
}>) => r.body as {
'isSuccess'?: boolean;
'message'?: string;
'data'?: CustomerAppointmentVerificationItem;
})
    );
  }

  /**
   * Path part for operation sendAppointmentMessage
   */
  static readonly SendAppointmentMessagePath = '/v1/scheduleAppointment/sendAppointmentMessage';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `sendAppointmentMessage()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  sendAppointmentMessage$Response(params: {
    body: CustomerAppointmentUpdateRequest
  }): Observable<StrictHttpResponse<{
'isSuccess'?: boolean;
'message'?: string;
'data'?: string;
}>> {

    const rb = new RequestBuilder(this.rootUrl, ApiService.SendAppointmentMessagePath, 'post');
    if (params) {
      rb.body(params.body, 'application/json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<{
        'isSuccess'?: boolean;
        'message'?: string;
        'data'?: string;
        }>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `sendAppointmentMessage$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  sendAppointmentMessage(params: {
    body: CustomerAppointmentUpdateRequest
  }): Observable<{
'isSuccess'?: boolean;
'message'?: string;
'data'?: string;
}> {

    return this.sendAppointmentMessage$Response(params).pipe(
      map((r: StrictHttpResponse<{
'isSuccess'?: boolean;
'message'?: string;
'data'?: string;
}>) => r.body as {
'isSuccess'?: boolean;
'message'?: string;
'data'?: string;
})
    );
  }

  /**
   * Path part for operation getEmbedUrlWithDynamicAccount
   */
  static readonly GetEmbedUrlWithDynamicAccountPath = '/v1/quickSight/getEmbedUrlWithDynamicAccount';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getEmbedUrlWithDynamicAccount()` instead.
   *
   * This method doesn't expect any request body.
   */
  getEmbedUrlWithDynamicAccount$Response(params?: {
  }): Observable<StrictHttpResponse<{
'isSuccess'?: boolean;
'message'?: string;
'data'?: string;
}>> {

    const rb = new RequestBuilder(this.rootUrl, ApiService.GetEmbedUrlWithDynamicAccountPath, 'get');
    if (params) {
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<{
        'isSuccess'?: boolean;
        'message'?: string;
        'data'?: string;
        }>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `getEmbedUrlWithDynamicAccount$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getEmbedUrlWithDynamicAccount(params?: {
  }): Observable<{
'isSuccess'?: boolean;
'message'?: string;
'data'?: string;
}> {

    return this.getEmbedUrlWithDynamicAccount$Response(params).pipe(
      map((r: StrictHttpResponse<{
'isSuccess'?: boolean;
'message'?: string;
'data'?: string;
}>) => r.body as {
'isSuccess'?: boolean;
'message'?: string;
'data'?: string;
})
    );
  }

  /**
   * Path part for operation getEmbedUrlWithStaticAccount
   */
  static readonly GetEmbedUrlWithStaticAccountPath = '/v1/quickSight/getEmbedUrlWithStaticAccount';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getEmbedUrlWithStaticAccount()` instead.
   *
   * This method doesn't expect any request body.
   */
  getEmbedUrlWithStaticAccount$Response(params?: {
  }): Observable<StrictHttpResponse<{
'isSuccess'?: boolean;
'message'?: string;
'data'?: string;
}>> {

    const rb = new RequestBuilder(this.rootUrl, ApiService.GetEmbedUrlWithStaticAccountPath, 'get');
    if (params) {
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<{
        'isSuccess'?: boolean;
        'message'?: string;
        'data'?: string;
        }>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `getEmbedUrlWithStaticAccount$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getEmbedUrlWithStaticAccount(params?: {
  }): Observable<{
'isSuccess'?: boolean;
'message'?: string;
'data'?: string;
}> {

    return this.getEmbedUrlWithStaticAccount$Response(params).pipe(
      map((r: StrictHttpResponse<{
'isSuccess'?: boolean;
'message'?: string;
'data'?: string;
}>) => r.body as {
'isSuccess'?: boolean;
'message'?: string;
'data'?: string;
})
    );
  }

  /**
   * Path part for operation privateBankingRelationshipList
   */
  static readonly PrivateBankingRelationshipListPath = '/v1/privateBanking/list/privateBankingRelationship';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `privateBankingRelationshipList()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  privateBankingRelationshipList$Response(params: {
    body: PrivateBankingFetchRequest
  }): Observable<StrictHttpResponse<{
'isSuccess'?: boolean;
'message'?: string;
'data'?: {
'pageNumber'?: number;
'pageSize'?: number;
'totalRowCount'?: number;
'items'?: Array<PrivateBankingDashboardItem>;
};
}>> {

    const rb = new RequestBuilder(this.rootUrl, ApiService.PrivateBankingRelationshipListPath, 'post');
    if (params) {
      rb.body(params.body, 'application/json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<{
        'isSuccess'?: boolean;
        'message'?: string;
        'data'?: {
        'pageNumber'?: number;
        'pageSize'?: number;
        'totalRowCount'?: number;
        'items'?: Array<PrivateBankingDashboardItem>;
        };
        }>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `privateBankingRelationshipList$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  privateBankingRelationshipList(params: {
    body: PrivateBankingFetchRequest
  }): Observable<{
'isSuccess'?: boolean;
'message'?: string;
'data'?: {
'pageNumber'?: number;
'pageSize'?: number;
'totalRowCount'?: number;
'items'?: Array<PrivateBankingDashboardItem>;
};
}> {

    return this.privateBankingRelationshipList$Response(params).pipe(
      map((r: StrictHttpResponse<{
'isSuccess'?: boolean;
'message'?: string;
'data'?: {
'pageNumber'?: number;
'pageSize'?: number;
'totalRowCount'?: number;
'items'?: Array<PrivateBankingDashboardItem>;
};
}>) => r.body as {
'isSuccess'?: boolean;
'message'?: string;
'data'?: {
'pageNumber'?: number;
'pageSize'?: number;
'totalRowCount'?: number;
'items'?: Array<PrivateBankingDashboardItem>;
};
})
    );
  }

  /**
   * Path part for operation getPrivateBankingDashboardData
   */
  static readonly GetPrivateBankingDashboardDataPath = '/v1/privateBanking/getPrivateBankingDashboardData';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getPrivateBankingDashboardData()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  getPrivateBankingDashboardData$Response(params: {
    body: PrivateBankingFetchRequest
  }): Observable<StrictHttpResponse<{
'isSuccess'?: boolean;
'message'?: string;
'data'?: PrivateBankingDashboardItem;
}>> {

    const rb = new RequestBuilder(this.rootUrl, ApiService.GetPrivateBankingDashboardDataPath, 'post');
    if (params) {
      rb.body(params.body, 'application/json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<{
        'isSuccess'?: boolean;
        'message'?: string;
        'data'?: PrivateBankingDashboardItem;
        }>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `getPrivateBankingDashboardData$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  getPrivateBankingDashboardData(params: {
    body: PrivateBankingFetchRequest
  }): Observable<{
'isSuccess'?: boolean;
'message'?: string;
'data'?: PrivateBankingDashboardItem;
}> {

    return this.getPrivateBankingDashboardData$Response(params).pipe(
      map((r: StrictHttpResponse<{
'isSuccess'?: boolean;
'message'?: string;
'data'?: PrivateBankingDashboardItem;
}>) => r.body as {
'isSuccess'?: boolean;
'message'?: string;
'data'?: PrivateBankingDashboardItem;
})
    );
  }

  /**
   * Path part for operation getPrivateBankingDepositsAccountList
   */
  static readonly GetPrivateBankingDepositsAccountListPath = '/v1/privateBanking/list/depositAccounts';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getPrivateBankingDepositsAccountList()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  getPrivateBankingDepositsAccountList$Response(params: {
    body: PrivateBankingFetchRequest
  }): Observable<StrictHttpResponse<{
'isSuccess'?: boolean;
'message'?: string;
'data'?: {
'pageNumber'?: number;
'pageSize'?: number;
'totalRowCount'?: number;
'items'?: Array<PrivateBankingAccountItem>;
};
}>> {

    const rb = new RequestBuilder(this.rootUrl, ApiService.GetPrivateBankingDepositsAccountListPath, 'post');
    if (params) {
      rb.body(params.body, 'application/json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<{
        'isSuccess'?: boolean;
        'message'?: string;
        'data'?: {
        'pageNumber'?: number;
        'pageSize'?: number;
        'totalRowCount'?: number;
        'items'?: Array<PrivateBankingAccountItem>;
        };
        }>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `getPrivateBankingDepositsAccountList$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  getPrivateBankingDepositsAccountList(params: {
    body: PrivateBankingFetchRequest
  }): Observable<{
'isSuccess'?: boolean;
'message'?: string;
'data'?: {
'pageNumber'?: number;
'pageSize'?: number;
'totalRowCount'?: number;
'items'?: Array<PrivateBankingAccountItem>;
};
}> {

    return this.getPrivateBankingDepositsAccountList$Response(params).pipe(
      map((r: StrictHttpResponse<{
'isSuccess'?: boolean;
'message'?: string;
'data'?: {
'pageNumber'?: number;
'pageSize'?: number;
'totalRowCount'?: number;
'items'?: Array<PrivateBankingAccountItem>;
};
}>) => r.body as {
'isSuccess'?: boolean;
'message'?: string;
'data'?: {
'pageNumber'?: number;
'pageSize'?: number;
'totalRowCount'?: number;
'items'?: Array<PrivateBankingAccountItem>;
};
})
    );
  }

  /**
   * Path part for operation getPrivateBankingLoansAccountList
   */
  static readonly GetPrivateBankingLoansAccountListPath = '/v1/privateBanking/list/loanAccounts';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getPrivateBankingLoansAccountList()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  getPrivateBankingLoansAccountList$Response(params: {
    body: PrivateBankingFetchRequest
  }): Observable<StrictHttpResponse<{
'isSuccess'?: boolean;
'message'?: string;
'data'?: {
'pageNumber'?: number;
'pageSize'?: number;
'totalRowCount'?: number;
'items'?: Array<PrivateBankingAccountItem>;
};
}>> {

    const rb = new RequestBuilder(this.rootUrl, ApiService.GetPrivateBankingLoansAccountListPath, 'post');
    if (params) {
      rb.body(params.body, 'application/json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<{
        'isSuccess'?: boolean;
        'message'?: string;
        'data'?: {
        'pageNumber'?: number;
        'pageSize'?: number;
        'totalRowCount'?: number;
        'items'?: Array<PrivateBankingAccountItem>;
        };
        }>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `getPrivateBankingLoansAccountList$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  getPrivateBankingLoansAccountList(params: {
    body: PrivateBankingFetchRequest
  }): Observable<{
'isSuccess'?: boolean;
'message'?: string;
'data'?: {
'pageNumber'?: number;
'pageSize'?: number;
'totalRowCount'?: number;
'items'?: Array<PrivateBankingAccountItem>;
};
}> {

    return this.getPrivateBankingLoansAccountList$Response(params).pipe(
      map((r: StrictHttpResponse<{
'isSuccess'?: boolean;
'message'?: string;
'data'?: {
'pageNumber'?: number;
'pageSize'?: number;
'totalRowCount'?: number;
'items'?: Array<PrivateBankingAccountItem>;
};
}>) => r.body as {
'isSuccess'?: boolean;
'message'?: string;
'data'?: {
'pageNumber'?: number;
'pageSize'?: number;
'totalRowCount'?: number;
'items'?: Array<PrivateBankingAccountItem>;
};
})
    );
  }

  /**
   * Path part for operation getPrivateBankingInsuranceAccountList
   */
  static readonly GetPrivateBankingInsuranceAccountListPath = '/v1/privateBanking/list/insuranceAccounts';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getPrivateBankingInsuranceAccountList()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  getPrivateBankingInsuranceAccountList$Response(params: {
    body: PrivateBankingFetchRequest
  }): Observable<StrictHttpResponse<{
'isSuccess'?: boolean;
'message'?: string;
'data'?: {
'pageNumber'?: number;
'pageSize'?: number;
'totalRowCount'?: number;
'items'?: Array<PrivateBankingAccountItem>;
};
}>> {

    const rb = new RequestBuilder(this.rootUrl, ApiService.GetPrivateBankingInsuranceAccountListPath, 'post');
    if (params) {
      rb.body(params.body, 'application/json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<{
        'isSuccess'?: boolean;
        'message'?: string;
        'data'?: {
        'pageNumber'?: number;
        'pageSize'?: number;
        'totalRowCount'?: number;
        'items'?: Array<PrivateBankingAccountItem>;
        };
        }>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `getPrivateBankingInsuranceAccountList$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  getPrivateBankingInsuranceAccountList(params: {
    body: PrivateBankingFetchRequest
  }): Observable<{
'isSuccess'?: boolean;
'message'?: string;
'data'?: {
'pageNumber'?: number;
'pageSize'?: number;
'totalRowCount'?: number;
'items'?: Array<PrivateBankingAccountItem>;
};
}> {

    return this.getPrivateBankingInsuranceAccountList$Response(params).pipe(
      map((r: StrictHttpResponse<{
'isSuccess'?: boolean;
'message'?: string;
'data'?: {
'pageNumber'?: number;
'pageSize'?: number;
'totalRowCount'?: number;
'items'?: Array<PrivateBankingAccountItem>;
};
}>) => r.body as {
'isSuccess'?: boolean;
'message'?: string;
'data'?: {
'pageNumber'?: number;
'pageSize'?: number;
'totalRowCount'?: number;
'items'?: Array<PrivateBankingAccountItem>;
};
})
    );
  }

  /**
   * Path part for operation getPrivateBankingTrustAccountList
   */
  static readonly GetPrivateBankingTrustAccountListPath = '/v1/privateBanking/list/trustAccounts';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getPrivateBankingTrustAccountList()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  getPrivateBankingTrustAccountList$Response(params: {
    body: PrivateBankingFetchRequest
  }): Observable<StrictHttpResponse<{
'isSuccess'?: boolean;
'message'?: string;
'data'?: {
'pageNumber'?: number;
'pageSize'?: number;
'totalRowCount'?: number;
'items'?: Array<PrivateBankingAccountItem>;
};
}>> {

    const rb = new RequestBuilder(this.rootUrl, ApiService.GetPrivateBankingTrustAccountListPath, 'post');
    if (params) {
      rb.body(params.body, 'application/json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<{
        'isSuccess'?: boolean;
        'message'?: string;
        'data'?: {
        'pageNumber'?: number;
        'pageSize'?: number;
        'totalRowCount'?: number;
        'items'?: Array<PrivateBankingAccountItem>;
        };
        }>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `getPrivateBankingTrustAccountList$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  getPrivateBankingTrustAccountList(params: {
    body: PrivateBankingFetchRequest
  }): Observable<{
'isSuccess'?: boolean;
'message'?: string;
'data'?: {
'pageNumber'?: number;
'pageSize'?: number;
'totalRowCount'?: number;
'items'?: Array<PrivateBankingAccountItem>;
};
}> {

    return this.getPrivateBankingTrustAccountList$Response(params).pipe(
      map((r: StrictHttpResponse<{
'isSuccess'?: boolean;
'message'?: string;
'data'?: {
'pageNumber'?: number;
'pageSize'?: number;
'totalRowCount'?: number;
'items'?: Array<PrivateBankingAccountItem>;
};
}>) => r.body as {
'isSuccess'?: boolean;
'message'?: string;
'data'?: {
'pageNumber'?: number;
'pageSize'?: number;
'totalRowCount'?: number;
'items'?: Array<PrivateBankingAccountItem>;
};
})
    );
  }

  /**
   * Path part for operation getPrivateBankingMortgageAccountList
   */
  static readonly GetPrivateBankingMortgageAccountListPath = '/v1/privateBanking/list/mortgageAccounts';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getPrivateBankingMortgageAccountList()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  getPrivateBankingMortgageAccountList$Response(params: {
    body: PrivateBankingFetchRequest
  }): Observable<StrictHttpResponse<{
'isSuccess'?: boolean;
'message'?: string;
'data'?: {
'pageNumber'?: number;
'pageSize'?: number;
'totalRowCount'?: number;
'items'?: Array<PrivateBankingAccountItem>;
};
}>> {

    const rb = new RequestBuilder(this.rootUrl, ApiService.GetPrivateBankingMortgageAccountListPath, 'post');
    if (params) {
      rb.body(params.body, 'application/json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<{
        'isSuccess'?: boolean;
        'message'?: string;
        'data'?: {
        'pageNumber'?: number;
        'pageSize'?: number;
        'totalRowCount'?: number;
        'items'?: Array<PrivateBankingAccountItem>;
        };
        }>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `getPrivateBankingMortgageAccountList$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  getPrivateBankingMortgageAccountList(params: {
    body: PrivateBankingFetchRequest
  }): Observable<{
'isSuccess'?: boolean;
'message'?: string;
'data'?: {
'pageNumber'?: number;
'pageSize'?: number;
'totalRowCount'?: number;
'items'?: Array<PrivateBankingAccountItem>;
};
}> {

    return this.getPrivateBankingMortgageAccountList$Response(params).pipe(
      map((r: StrictHttpResponse<{
'isSuccess'?: boolean;
'message'?: string;
'data'?: {
'pageNumber'?: number;
'pageSize'?: number;
'totalRowCount'?: number;
'items'?: Array<PrivateBankingAccountItem>;
};
}>) => r.body as {
'isSuccess'?: boolean;
'message'?: string;
'data'?: {
'pageNumber'?: number;
'pageSize'?: number;
'totalRowCount'?: number;
'items'?: Array<PrivateBankingAccountItem>;
};
})
    );
  }

  /**
   * Path part for operation getPrivateBankingWealthAccountList
   */
  static readonly GetPrivateBankingWealthAccountListPath = '/v1/privateBanking/list/wealthAccounts';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getPrivateBankingWealthAccountList()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  getPrivateBankingWealthAccountList$Response(params: {
    body: PrivateBankingFetchRequest
  }): Observable<StrictHttpResponse<{
'isSuccess'?: boolean;
'message'?: string;
'data'?: {
'pageNumber'?: number;
'pageSize'?: number;
'totalRowCount'?: number;
'items'?: Array<PrivateBankingAccountItem>;
};
}>> {

    const rb = new RequestBuilder(this.rootUrl, ApiService.GetPrivateBankingWealthAccountListPath, 'post');
    if (params) {
      rb.body(params.body, 'application/json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<{
        'isSuccess'?: boolean;
        'message'?: string;
        'data'?: {
        'pageNumber'?: number;
        'pageSize'?: number;
        'totalRowCount'?: number;
        'items'?: Array<PrivateBankingAccountItem>;
        };
        }>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `getPrivateBankingWealthAccountList$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  getPrivateBankingWealthAccountList(params: {
    body: PrivateBankingFetchRequest
  }): Observable<{
'isSuccess'?: boolean;
'message'?: string;
'data'?: {
'pageNumber'?: number;
'pageSize'?: number;
'totalRowCount'?: number;
'items'?: Array<PrivateBankingAccountItem>;
};
}> {

    return this.getPrivateBankingWealthAccountList$Response(params).pipe(
      map((r: StrictHttpResponse<{
'isSuccess'?: boolean;
'message'?: string;
'data'?: {
'pageNumber'?: number;
'pageSize'?: number;
'totalRowCount'?: number;
'items'?: Array<PrivateBankingAccountItem>;
};
}>) => r.body as {
'isSuccess'?: boolean;
'message'?: string;
'data'?: {
'pageNumber'?: number;
'pageSize'?: number;
'totalRowCount'?: number;
'items'?: Array<PrivateBankingAccountItem>;
};
})
    );
  }

  /**
   * Path part for operation updatePrivateBankingRelationshipData
   */
  static readonly UpdatePrivateBankingRelationshipDataPath = '/v1/privateBanking/updatePrivateBankingRelationshipData';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `updatePrivateBankingRelationshipData()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  updatePrivateBankingRelationshipData$Response(params: {
    body: PrivateBankingRelationshipItem
  }): Observable<StrictHttpResponse<{
'isSuccess'?: boolean;
'message'?: string;
'data'?: string;
}>> {

    const rb = new RequestBuilder(this.rootUrl, ApiService.UpdatePrivateBankingRelationshipDataPath, 'post');
    if (params) {
      rb.body(params.body, 'application/json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<{
        'isSuccess'?: boolean;
        'message'?: string;
        'data'?: string;
        }>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `updatePrivateBankingRelationshipData$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  updatePrivateBankingRelationshipData(params: {
    body: PrivateBankingRelationshipItem
  }): Observable<{
'isSuccess'?: boolean;
'message'?: string;
'data'?: string;
}> {

    return this.updatePrivateBankingRelationshipData$Response(params).pipe(
      map((r: StrictHttpResponse<{
'isSuccess'?: boolean;
'message'?: string;
'data'?: string;
}>) => r.body as {
'isSuccess'?: boolean;
'message'?: string;
'data'?: string;
})
    );
  }

  /**
   * Path part for operation savePrivateBankingRelationshipAccounts
   */
  static readonly SavePrivateBankingRelationshipAccountsPath = '/v1/privateBanking/savePrivateBankingRelationshipAccounts';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `savePrivateBankingRelationshipAccounts()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  savePrivateBankingRelationshipAccounts$Response(params: {
    body: PrivateBankingRelationshipAccountRequest
  }): Observable<StrictHttpResponse<{
'isSuccess'?: boolean;
'message'?: string;
'data'?: string;
}>> {

    const rb = new RequestBuilder(this.rootUrl, ApiService.SavePrivateBankingRelationshipAccountsPath, 'post');
    if (params) {
      rb.body(params.body, 'application/json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<{
        'isSuccess'?: boolean;
        'message'?: string;
        'data'?: string;
        }>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `savePrivateBankingRelationshipAccounts$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  savePrivateBankingRelationshipAccounts(params: {
    body: PrivateBankingRelationshipAccountRequest
  }): Observable<{
'isSuccess'?: boolean;
'message'?: string;
'data'?: string;
}> {

    return this.savePrivateBankingRelationshipAccounts$Response(params).pipe(
      map((r: StrictHttpResponse<{
'isSuccess'?: boolean;
'message'?: string;
'data'?: string;
}>) => r.body as {
'isSuccess'?: boolean;
'message'?: string;
'data'?: string;
})
    );
  }

  /**
   * Path part for operation getPrivateBankingRelationshipList
   */
  static readonly GetPrivateBankingRelationshipListPath = '/v1/privateBanking/getPrivateBankingRelationshipList';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getPrivateBankingRelationshipList()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  getPrivateBankingRelationshipList$Response(params: {
    body: PrivateBankingRequest
  }): Observable<StrictHttpResponse<{
'isSuccess'?: boolean;
'message'?: string;
'data'?: {
'pageNumber'?: number;
'pageSize'?: number;
'totalRowCount'?: number;
'items'?: Array<PrivateBankingRelationshipItem>;
};
}>> {

    const rb = new RequestBuilder(this.rootUrl, ApiService.GetPrivateBankingRelationshipListPath, 'post');
    if (params) {
      rb.body(params.body, 'application/json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<{
        'isSuccess'?: boolean;
        'message'?: string;
        'data'?: {
        'pageNumber'?: number;
        'pageSize'?: number;
        'totalRowCount'?: number;
        'items'?: Array<PrivateBankingRelationshipItem>;
        };
        }>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `getPrivateBankingRelationshipList$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  getPrivateBankingRelationshipList(params: {
    body: PrivateBankingRequest
  }): Observable<{
'isSuccess'?: boolean;
'message'?: string;
'data'?: {
'pageNumber'?: number;
'pageSize'?: number;
'totalRowCount'?: number;
'items'?: Array<PrivateBankingRelationshipItem>;
};
}> {

    return this.getPrivateBankingRelationshipList$Response(params).pipe(
      map((r: StrictHttpResponse<{
'isSuccess'?: boolean;
'message'?: string;
'data'?: {
'pageNumber'?: number;
'pageSize'?: number;
'totalRowCount'?: number;
'items'?: Array<PrivateBankingRelationshipItem>;
};
}>) => r.body as {
'isSuccess'?: boolean;
'message'?: string;
'data'?: {
'pageNumber'?: number;
'pageSize'?: number;
'totalRowCount'?: number;
'items'?: Array<PrivateBankingRelationshipItem>;
};
})
    );
  }

  /**
   * Path part for operation privateBankingRelationshipAdd
   */
  static readonly PrivateBankingRelationshipAddPath = '/v1/privateBanking/add/privateBankingRelationship';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `privateBankingRelationshipAdd()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  privateBankingRelationshipAdd$Response(params: {
    body: PrivateBankingRelationshipRequest
  }): Observable<StrictHttpResponse<{
'isSuccess'?: boolean;
'message'?: string;
'data'?: string;
}>> {

    const rb = new RequestBuilder(this.rootUrl, ApiService.PrivateBankingRelationshipAddPath, 'post');
    if (params) {
      rb.body(params.body, 'application/json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<{
        'isSuccess'?: boolean;
        'message'?: string;
        'data'?: string;
        }>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `privateBankingRelationshipAdd$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  privateBankingRelationshipAdd(params: {
    body: PrivateBankingRelationshipRequest
  }): Observable<{
'isSuccess'?: boolean;
'message'?: string;
'data'?: string;
}> {

    return this.privateBankingRelationshipAdd$Response(params).pipe(
      map((r: StrictHttpResponse<{
'isSuccess'?: boolean;
'message'?: string;
'data'?: string;
}>) => r.body as {
'isSuccess'?: boolean;
'message'?: string;
'data'?: string;
})
    );
  }

  /**
   * Path part for operation privateBankingTeamMemberAddOrUpdate
   */
  static readonly PrivateBankingTeamMemberAddOrUpdatePath = '/v1/privateBanking/add/privateBankingTeamMemberAddOrUpdate';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `privateBankingTeamMemberAddOrUpdate()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  privateBankingTeamMemberAddOrUpdate$Response(params: {
    body: RelationshipDepartmentInternalUserRequest
  }): Observable<StrictHttpResponse<{
'isSuccess'?: boolean;
'message'?: string;
'data'?: string;
}>> {

    const rb = new RequestBuilder(this.rootUrl, ApiService.PrivateBankingTeamMemberAddOrUpdatePath, 'post');
    if (params) {
      rb.body(params.body, 'application/json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<{
        'isSuccess'?: boolean;
        'message'?: string;
        'data'?: string;
        }>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `privateBankingTeamMemberAddOrUpdate$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  privateBankingTeamMemberAddOrUpdate(params: {
    body: RelationshipDepartmentInternalUserRequest
  }): Observable<{
'isSuccess'?: boolean;
'message'?: string;
'data'?: string;
}> {

    return this.privateBankingTeamMemberAddOrUpdate$Response(params).pipe(
      map((r: StrictHttpResponse<{
'isSuccess'?: boolean;
'message'?: string;
'data'?: string;
}>) => r.body as {
'isSuccess'?: boolean;
'message'?: string;
'data'?: string;
})
    );
  }

  /**
   * Path part for operation getPrivateBankingDepartmentList
   */
  static readonly GetPrivateBankingDepartmentListPath = '/v1/privateBanking/getPrivateBankingDepartmentList';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getPrivateBankingDepartmentList()` instead.
   *
   * This method doesn't expect any request body.
   */
  getPrivateBankingDepartmentList$Response(params?: {
  }): Observable<StrictHttpResponse<{
'isSuccess'?: boolean;
'message'?: string;
'data'?: {
'pageNumber'?: number;
'pageSize'?: number;
'totalRowCount'?: number;
'items'?: Array<PrivateBankingDepartmentItem>;
};
}>> {

    const rb = new RequestBuilder(this.rootUrl, ApiService.GetPrivateBankingDepartmentListPath, 'post');
    if (params) {
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<{
        'isSuccess'?: boolean;
        'message'?: string;
        'data'?: {
        'pageNumber'?: number;
        'pageSize'?: number;
        'totalRowCount'?: number;
        'items'?: Array<PrivateBankingDepartmentItem>;
        };
        }>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `getPrivateBankingDepartmentList$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getPrivateBankingDepartmentList(params?: {
  }): Observable<{
'isSuccess'?: boolean;
'message'?: string;
'data'?: {
'pageNumber'?: number;
'pageSize'?: number;
'totalRowCount'?: number;
'items'?: Array<PrivateBankingDepartmentItem>;
};
}> {

    return this.getPrivateBankingDepartmentList$Response(params).pipe(
      map((r: StrictHttpResponse<{
'isSuccess'?: boolean;
'message'?: string;
'data'?: {
'pageNumber'?: number;
'pageSize'?: number;
'totalRowCount'?: number;
'items'?: Array<PrivateBankingDepartmentItem>;
};
}>) => r.body as {
'isSuccess'?: boolean;
'message'?: string;
'data'?: {
'pageNumber'?: number;
'pageSize'?: number;
'totalRowCount'?: number;
'items'?: Array<PrivateBankingDepartmentItem>;
};
})
    );
  }

  /**
   * Path part for operation privateBankingXInternalUserList
   */
  static readonly PrivateBankingXInternalUserListPath = '/v1/privateBanking/list/privateBankingXInternalUserList';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `privateBankingXInternalUserList()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  privateBankingXInternalUserList$Response(params: {
    body: PrivateBankingRequest
  }): Observable<StrictHttpResponse<{
'isSuccess'?: boolean;
'message'?: string;
'data'?: {
'pageNumber'?: number;
'pageSize'?: number;
'totalRowCount'?: number;
'items'?: Array<PrivateBankingXInternalUserItem>;
};
}>> {

    const rb = new RequestBuilder(this.rootUrl, ApiService.PrivateBankingXInternalUserListPath, 'post');
    if (params) {
      rb.body(params.body, 'application/json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<{
        'isSuccess'?: boolean;
        'message'?: string;
        'data'?: {
        'pageNumber'?: number;
        'pageSize'?: number;
        'totalRowCount'?: number;
        'items'?: Array<PrivateBankingXInternalUserItem>;
        };
        }>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `privateBankingXInternalUserList$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  privateBankingXInternalUserList(params: {
    body: PrivateBankingRequest
  }): Observable<{
'isSuccess'?: boolean;
'message'?: string;
'data'?: {
'pageNumber'?: number;
'pageSize'?: number;
'totalRowCount'?: number;
'items'?: Array<PrivateBankingXInternalUserItem>;
};
}> {

    return this.privateBankingXInternalUserList$Response(params).pipe(
      map((r: StrictHttpResponse<{
'isSuccess'?: boolean;
'message'?: string;
'data'?: {
'pageNumber'?: number;
'pageSize'?: number;
'totalRowCount'?: number;
'items'?: Array<PrivateBankingXInternalUserItem>;
};
}>) => r.body as {
'isSuccess'?: boolean;
'message'?: string;
'data'?: {
'pageNumber'?: number;
'pageSize'?: number;
'totalRowCount'?: number;
'items'?: Array<PrivateBankingXInternalUserItem>;
};
})
    );
  }

  /**
   * Path part for operation accountOpeningApplicationAdd
   */
  static readonly AccountOpeningApplicationAddPath = '/v1/account-opening/add/accountOpening';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `accountOpeningApplicationAdd()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  accountOpeningApplicationAdd$Response(params: {
    body: AccountOpeningApplicationItem
  }): Observable<StrictHttpResponse<{
'isSuccess'?: boolean;
'message'?: string;
'data'?: string;
}>> {

    const rb = new RequestBuilder(this.rootUrl, ApiService.AccountOpeningApplicationAddPath, 'post');
    if (params) {
      rb.body(params.body, 'application/json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<{
        'isSuccess'?: boolean;
        'message'?: string;
        'data'?: string;
        }>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `accountOpeningApplicationAdd$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  accountOpeningApplicationAdd(params: {
    body: AccountOpeningApplicationItem
  }): Observable<{
'isSuccess'?: boolean;
'message'?: string;
'data'?: string;
}> {

    return this.accountOpeningApplicationAdd$Response(params).pipe(
      map((r: StrictHttpResponse<{
'isSuccess'?: boolean;
'message'?: string;
'data'?: string;
}>) => r.body as {
'isSuccess'?: boolean;
'message'?: string;
'data'?: string;
})
    );
  }

  /**
   * Path part for operation notifyBranchForNewApplication
   */
  static readonly NotifyBranchForNewApplicationPath = '/v1/account-opening/put/notifyBranch';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `notifyBranchForNewApplication()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  notifyBranchForNewApplication$Response(params: {
    body: AccountOpeningApplicationItem
  }): Observable<StrictHttpResponse<{
'isSuccess'?: boolean;
'message'?: string;
'data'?: string;
}>> {

    const rb = new RequestBuilder(this.rootUrl, ApiService.NotifyBranchForNewApplicationPath, 'post');
    if (params) {
      rb.body(params.body, 'application/json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<{
        'isSuccess'?: boolean;
        'message'?: string;
        'data'?: string;
        }>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `notifyBranchForNewApplication$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  notifyBranchForNewApplication(params: {
    body: AccountOpeningApplicationItem
  }): Observable<{
'isSuccess'?: boolean;
'message'?: string;
'data'?: string;
}> {

    return this.notifyBranchForNewApplication$Response(params).pipe(
      map((r: StrictHttpResponse<{
'isSuccess'?: boolean;
'message'?: string;
'data'?: string;
}>) => r.body as {
'isSuccess'?: boolean;
'message'?: string;
'data'?: string;
})
    );
  }

  /**
   * Path part for operation accountOpeningApplicationList
   */
  static readonly AccountOpeningApplicationListPath = '/v1/account-opening/list/accountOpeningApplication';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `accountOpeningApplicationList()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  accountOpeningApplicationList$Response(params: {
    body: AccountOpeningApplicationRequest
  }): Observable<StrictHttpResponse<{
'isSuccess'?: boolean;
'message'?: string;
'data'?: {
'pageNumber'?: number;
'pageSize'?: number;
'totalRowCount'?: number;
'items'?: Array<AccountOpeningApplication>;
};
}>> {

    const rb = new RequestBuilder(this.rootUrl, ApiService.AccountOpeningApplicationListPath, 'post');
    if (params) {
      rb.body(params.body, 'application/json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<{
        'isSuccess'?: boolean;
        'message'?: string;
        'data'?: {
        'pageNumber'?: number;
        'pageSize'?: number;
        'totalRowCount'?: number;
        'items'?: Array<AccountOpeningApplication>;
        };
        }>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `accountOpeningApplicationList$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  accountOpeningApplicationList(params: {
    body: AccountOpeningApplicationRequest
  }): Observable<{
'isSuccess'?: boolean;
'message'?: string;
'data'?: {
'pageNumber'?: number;
'pageSize'?: number;
'totalRowCount'?: number;
'items'?: Array<AccountOpeningApplication>;
};
}> {

    return this.accountOpeningApplicationList$Response(params).pipe(
      map((r: StrictHttpResponse<{
'isSuccess'?: boolean;
'message'?: string;
'data'?: {
'pageNumber'?: number;
'pageSize'?: number;
'totalRowCount'?: number;
'items'?: Array<AccountOpeningApplication>;
};
}>) => r.body as {
'isSuccess'?: boolean;
'message'?: string;
'data'?: {
'pageNumber'?: number;
'pageSize'?: number;
'totalRowCount'?: number;
'items'?: Array<AccountOpeningApplication>;
};
})
    );
  }

  /**
   * Path part for operation overrideAndAddAccountOpeningApplication
   */
  static readonly OverrideAndAddAccountOpeningApplicationPath = '/v1/account-opening/override/accountOpening';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `overrideAndAddAccountOpeningApplication()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  overrideAndAddAccountOpeningApplication$Response(params: {
    body: OldAccountOpeningApplicationRequest
  }): Observable<StrictHttpResponse<{
'isSuccess'?: boolean;
'message'?: string;
'data'?: {
'pageNumber'?: number;
'pageSize'?: number;
'totalRowCount'?: number;
'items'?: Array<AccountOpeningApplication>;
};
}>> {

    const rb = new RequestBuilder(this.rootUrl, ApiService.OverrideAndAddAccountOpeningApplicationPath, 'post');
    if (params) {
      rb.body(params.body, 'application/json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<{
        'isSuccess'?: boolean;
        'message'?: string;
        'data'?: {
        'pageNumber'?: number;
        'pageSize'?: number;
        'totalRowCount'?: number;
        'items'?: Array<AccountOpeningApplication>;
        };
        }>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `overrideAndAddAccountOpeningApplication$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  overrideAndAddAccountOpeningApplication(params: {
    body: OldAccountOpeningApplicationRequest
  }): Observable<{
'isSuccess'?: boolean;
'message'?: string;
'data'?: {
'pageNumber'?: number;
'pageSize'?: number;
'totalRowCount'?: number;
'items'?: Array<AccountOpeningApplication>;
};
}> {

    return this.overrideAndAddAccountOpeningApplication$Response(params).pipe(
      map((r: StrictHttpResponse<{
'isSuccess'?: boolean;
'message'?: string;
'data'?: {
'pageNumber'?: number;
'pageSize'?: number;
'totalRowCount'?: number;
'items'?: Array<AccountOpeningApplication>;
};
}>) => r.body as {
'isSuccess'?: boolean;
'message'?: string;
'data'?: {
'pageNumber'?: number;
'pageSize'?: number;
'totalRowCount'?: number;
'items'?: Array<AccountOpeningApplication>;
};
})
    );
  }

  /**
   * Path part for operation validateAccountOpeningApplication
   */
  static readonly ValidateAccountOpeningApplicationPath = '/v1/account-opening/lookupPhoneNumber';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `validateAccountOpeningApplication()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  validateAccountOpeningApplication$Response(params: {
    body: AccountOpeningApplicationValidationRequest
  }): Observable<StrictHttpResponse<{
'isSuccess'?: boolean;
'message'?: string;
'data'?: {
'pageNumber'?: number;
'pageSize'?: number;
'totalRowCount'?: number;
'items'?: Array<AccountOpeningApplicationValidation>;
};
}>> {

    const rb = new RequestBuilder(this.rootUrl, ApiService.ValidateAccountOpeningApplicationPath, 'post');
    if (params) {
      rb.body(params.body, 'application/json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<{
        'isSuccess'?: boolean;
        'message'?: string;
        'data'?: {
        'pageNumber'?: number;
        'pageSize'?: number;
        'totalRowCount'?: number;
        'items'?: Array<AccountOpeningApplicationValidation>;
        };
        }>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `validateAccountOpeningApplication$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  validateAccountOpeningApplication(params: {
    body: AccountOpeningApplicationValidationRequest
  }): Observable<{
'isSuccess'?: boolean;
'message'?: string;
'data'?: {
'pageNumber'?: number;
'pageSize'?: number;
'totalRowCount'?: number;
'items'?: Array<AccountOpeningApplicationValidation>;
};
}> {

    return this.validateAccountOpeningApplication$Response(params).pipe(
      map((r: StrictHttpResponse<{
'isSuccess'?: boolean;
'message'?: string;
'data'?: {
'pageNumber'?: number;
'pageSize'?: number;
'totalRowCount'?: number;
'items'?: Array<AccountOpeningApplicationValidation>;
};
}>) => r.body as {
'isSuccess'?: boolean;
'message'?: string;
'data'?: {
'pageNumber'?: number;
'pageSize'?: number;
'totalRowCount'?: number;
'items'?: Array<AccountOpeningApplicationValidation>;
};
})
    );
  }

  /**
   * Path part for operation emailSavedApplicationToUser
   */
  static readonly EmailSavedApplicationToUserPath = '/v1/account-opening/put/emailUserForSavedApplication';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `emailSavedApplicationToUser()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  emailSavedApplicationToUser$Response(params: {
    body: EmailApplicationLinkToUserRequest
  }): Observable<StrictHttpResponse<{
'isSuccess'?: boolean;
'message'?: string;
'data'?: string;
}>> {

    const rb = new RequestBuilder(this.rootUrl, ApiService.EmailSavedApplicationToUserPath, 'post');
    if (params) {
      rb.body(params.body, 'application/json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<{
        'isSuccess'?: boolean;
        'message'?: string;
        'data'?: string;
        }>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `emailSavedApplicationToUser$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  emailSavedApplicationToUser(params: {
    body: EmailApplicationLinkToUserRequest
  }): Observable<{
'isSuccess'?: boolean;
'message'?: string;
'data'?: string;
}> {

    return this.emailSavedApplicationToUser$Response(params).pipe(
      map((r: StrictHttpResponse<{
'isSuccess'?: boolean;
'message'?: string;
'data'?: string;
}>) => r.body as {
'isSuccess'?: boolean;
'message'?: string;
'data'?: string;
})
    );
  }

  /**
   * Path part for operation loanMaturityList
   */
  static readonly LoanMaturityListPath = '/v1/loan-maturity/list';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `loanMaturityList()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  loanMaturityList$Response(params: {
    body: EntityGetRequest
  }): Observable<StrictHttpResponse<{
'isSuccess'?: boolean;
'message'?: string;
'data'?: {
'pageNumber'?: number;
'pageSize'?: number;
'totalRowCount'?: number;
'items'?: Array<LoanMaturityItem>;
};
}>> {

    const rb = new RequestBuilder(this.rootUrl, ApiService.LoanMaturityListPath, 'post');
    if (params) {
      rb.body(params.body, 'application/json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<{
        'isSuccess'?: boolean;
        'message'?: string;
        'data'?: {
        'pageNumber'?: number;
        'pageSize'?: number;
        'totalRowCount'?: number;
        'items'?: Array<LoanMaturityItem>;
        };
        }>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `loanMaturityList$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  loanMaturityList(params: {
    body: EntityGetRequest
  }): Observable<{
'isSuccess'?: boolean;
'message'?: string;
'data'?: {
'pageNumber'?: number;
'pageSize'?: number;
'totalRowCount'?: number;
'items'?: Array<LoanMaturityItem>;
};
}> {

    return this.loanMaturityList$Response(params).pipe(
      map((r: StrictHttpResponse<{
'isSuccess'?: boolean;
'message'?: string;
'data'?: {
'pageNumber'?: number;
'pageSize'?: number;
'totalRowCount'?: number;
'items'?: Array<LoanMaturityItem>;
};
}>) => r.body as {
'isSuccess'?: boolean;
'message'?: string;
'data'?: {
'pageNumber'?: number;
'pageSize'?: number;
'totalRowCount'?: number;
'items'?: Array<LoanMaturityItem>;
};
})
    );
  }

  /**
   * Path part for operation getLoanMaturityDetails
   */
  static readonly GetLoanMaturityDetailsPath = '/v1/loan-maturity/getLoanMaturityDetails';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getLoanMaturityDetails()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  getLoanMaturityDetails$Response(params: {
    body: FetchLoanMaturityRequest
  }): Observable<StrictHttpResponse<{
'isSuccess'?: boolean;
'message'?: string;
'data'?: LoanMaturityItem;
}>> {

    const rb = new RequestBuilder(this.rootUrl, ApiService.GetLoanMaturityDetailsPath, 'post');
    if (params) {
      rb.body(params.body, 'application/json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<{
        'isSuccess'?: boolean;
        'message'?: string;
        'data'?: LoanMaturityItem;
        }>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `getLoanMaturityDetails$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  getLoanMaturityDetails(params: {
    body: FetchLoanMaturityRequest
  }): Observable<{
'isSuccess'?: boolean;
'message'?: string;
'data'?: LoanMaturityItem;
}> {

    return this.getLoanMaturityDetails$Response(params).pipe(
      map((r: StrictHttpResponse<{
'isSuccess'?: boolean;
'message'?: string;
'data'?: LoanMaturityItem;
}>) => r.body as {
'isSuccess'?: boolean;
'message'?: string;
'data'?: LoanMaturityItem;
})
    );
  }

  /**
   * Path part for operation loansMaturingWithinNinetyDays
   */
  static readonly LoansMaturingWithinNinetyDaysPath = '/v1/loan-maturity/list/maturingWithinNinetyDays';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `loansMaturingWithinNinetyDays()` instead.
   *
   * This method doesn't expect any request body.
   */
  loansMaturingWithinNinetyDays$Response(params?: {
  }): Observable<StrictHttpResponse<{
'isSuccess'?: boolean;
'message'?: string;
'data'?: {
'pageNumber'?: number;
'pageSize'?: number;
'totalRowCount'?: number;
'items'?: Array<TotalCount>;
};
}>> {

    const rb = new RequestBuilder(this.rootUrl, ApiService.LoansMaturingWithinNinetyDaysPath, 'get');
    if (params) {
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<{
        'isSuccess'?: boolean;
        'message'?: string;
        'data'?: {
        'pageNumber'?: number;
        'pageSize'?: number;
        'totalRowCount'?: number;
        'items'?: Array<TotalCount>;
        };
        }>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `loansMaturingWithinNinetyDays$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  loansMaturingWithinNinetyDays(params?: {
  }): Observable<{
'isSuccess'?: boolean;
'message'?: string;
'data'?: {
'pageNumber'?: number;
'pageSize'?: number;
'totalRowCount'?: number;
'items'?: Array<TotalCount>;
};
}> {

    return this.loansMaturingWithinNinetyDays$Response(params).pipe(
      map((r: StrictHttpResponse<{
'isSuccess'?: boolean;
'message'?: string;
'data'?: {
'pageNumber'?: number;
'pageSize'?: number;
'totalRowCount'?: number;
'items'?: Array<TotalCount>;
};
}>) => r.body as {
'isSuccess'?: boolean;
'message'?: string;
'data'?: {
'pageNumber'?: number;
'pageSize'?: number;
'totalRowCount'?: number;
'items'?: Array<TotalCount>;
};
})
    );
  }

  /**
   * Path part for operation loanGuarantorList
   */
  static readonly LoanGuarantorListPath = '/v1/loan-maturity/list/guarantor';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `loanGuarantorList()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  loanGuarantorList$Response(params: {
    body: LoanAccountRequest
  }): Observable<StrictHttpResponse<{
'isSuccess'?: boolean;
'message'?: string;
'data'?: {
'pageNumber'?: number;
'pageSize'?: number;
'totalRowCount'?: number;
'items'?: Array<LoanGuarantorItem>;
};
}>> {

    const rb = new RequestBuilder(this.rootUrl, ApiService.LoanGuarantorListPath, 'post');
    if (params) {
      rb.body(params.body, 'application/json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<{
        'isSuccess'?: boolean;
        'message'?: string;
        'data'?: {
        'pageNumber'?: number;
        'pageSize'?: number;
        'totalRowCount'?: number;
        'items'?: Array<LoanGuarantorItem>;
        };
        }>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `loanGuarantorList$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  loanGuarantorList(params: {
    body: LoanAccountRequest
  }): Observable<{
'isSuccess'?: boolean;
'message'?: string;
'data'?: {
'pageNumber'?: number;
'pageSize'?: number;
'totalRowCount'?: number;
'items'?: Array<LoanGuarantorItem>;
};
}> {

    return this.loanGuarantorList$Response(params).pipe(
      map((r: StrictHttpResponse<{
'isSuccess'?: boolean;
'message'?: string;
'data'?: {
'pageNumber'?: number;
'pageSize'?: number;
'totalRowCount'?: number;
'items'?: Array<LoanGuarantorItem>;
};
}>) => r.body as {
'isSuccess'?: boolean;
'message'?: string;
'data'?: {
'pageNumber'?: number;
'pageSize'?: number;
'totalRowCount'?: number;
'items'?: Array<LoanGuarantorItem>;
};
})
    );
  }

  /**
   * Path part for operation guarantorLoanList
   */
  static readonly GuarantorLoanListPath = '/v1/loan-maturity/list/guarantorLoan';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `guarantorLoanList()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  guarantorLoanList$Response(params: {
    body: LoanAccountRequest
  }): Observable<StrictHttpResponse<{
'isSuccess'?: boolean;
'message'?: string;
'data'?: {
'pageNumber'?: number;
'pageSize'?: number;
'totalRowCount'?: number;
'items'?: Array<LoanGuarantorItem>;
};
}>> {

    const rb = new RequestBuilder(this.rootUrl, ApiService.GuarantorLoanListPath, 'post');
    if (params) {
      rb.body(params.body, 'application/json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<{
        'isSuccess'?: boolean;
        'message'?: string;
        'data'?: {
        'pageNumber'?: number;
        'pageSize'?: number;
        'totalRowCount'?: number;
        'items'?: Array<LoanGuarantorItem>;
        };
        }>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `guarantorLoanList$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  guarantorLoanList(params: {
    body: LoanAccountRequest
  }): Observable<{
'isSuccess'?: boolean;
'message'?: string;
'data'?: {
'pageNumber'?: number;
'pageSize'?: number;
'totalRowCount'?: number;
'items'?: Array<LoanGuarantorItem>;
};
}> {

    return this.guarantorLoanList$Response(params).pipe(
      map((r: StrictHttpResponse<{
'isSuccess'?: boolean;
'message'?: string;
'data'?: {
'pageNumber'?: number;
'pageSize'?: number;
'totalRowCount'?: number;
'items'?: Array<LoanGuarantorItem>;
};
}>) => r.body as {
'isSuccess'?: boolean;
'message'?: string;
'data'?: {
'pageNumber'?: number;
'pageSize'?: number;
'totalRowCount'?: number;
'items'?: Array<LoanGuarantorItem>;
};
})
    );
  }

  /**
   * Path part for operation ddaAccountList
   */
  static readonly DdaAccountListPath = '/v1/loan-maturity/list/guarantor/ddaAccounts';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `ddaAccountList()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  ddaAccountList$Response(params: {
    body: LoanAccountRequest
  }): Observable<StrictHttpResponse<{
'isSuccess'?: boolean;
'message'?: string;
'data'?: {
'pageNumber'?: number;
'pageSize'?: number;
'totalRowCount'?: number;
'items'?: Array<LoanDdaAccountItem>;
};
}>> {

    const rb = new RequestBuilder(this.rootUrl, ApiService.DdaAccountListPath, 'post');
    if (params) {
      rb.body(params.body, 'application/json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<{
        'isSuccess'?: boolean;
        'message'?: string;
        'data'?: {
        'pageNumber'?: number;
        'pageSize'?: number;
        'totalRowCount'?: number;
        'items'?: Array<LoanDdaAccountItem>;
        };
        }>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `ddaAccountList$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  ddaAccountList(params: {
    body: LoanAccountRequest
  }): Observable<{
'isSuccess'?: boolean;
'message'?: string;
'data'?: {
'pageNumber'?: number;
'pageSize'?: number;
'totalRowCount'?: number;
'items'?: Array<LoanDdaAccountItem>;
};
}> {

    return this.ddaAccountList$Response(params).pipe(
      map((r: StrictHttpResponse<{
'isSuccess'?: boolean;
'message'?: string;
'data'?: {
'pageNumber'?: number;
'pageSize'?: number;
'totalRowCount'?: number;
'items'?: Array<LoanDdaAccountItem>;
};
}>) => r.body as {
'isSuccess'?: boolean;
'message'?: string;
'data'?: {
'pageNumber'?: number;
'pageSize'?: number;
'totalRowCount'?: number;
'items'?: Array<LoanDdaAccountItem>;
};
})
    );
  }

  /**
   * Path part for operation codAccountList
   */
  static readonly CodAccountListPath = '/v1/loan-maturity/list/guarantor/codAccounts';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `codAccountList()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  codAccountList$Response(params: {
    body: LoanAccountRequest
  }): Observable<StrictHttpResponse<{
'isSuccess'?: boolean;
'message'?: string;
'data'?: {
'pageNumber'?: number;
'pageSize'?: number;
'totalRowCount'?: number;
'items'?: Array<LoanCodAccountItem>;
};
}>> {

    const rb = new RequestBuilder(this.rootUrl, ApiService.CodAccountListPath, 'post');
    if (params) {
      rb.body(params.body, 'application/json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<{
        'isSuccess'?: boolean;
        'message'?: string;
        'data'?: {
        'pageNumber'?: number;
        'pageSize'?: number;
        'totalRowCount'?: number;
        'items'?: Array<LoanCodAccountItem>;
        };
        }>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `codAccountList$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  codAccountList(params: {
    body: LoanAccountRequest
  }): Observable<{
'isSuccess'?: boolean;
'message'?: string;
'data'?: {
'pageNumber'?: number;
'pageSize'?: number;
'totalRowCount'?: number;
'items'?: Array<LoanCodAccountItem>;
};
}> {

    return this.codAccountList$Response(params).pipe(
      map((r: StrictHttpResponse<{
'isSuccess'?: boolean;
'message'?: string;
'data'?: {
'pageNumber'?: number;
'pageSize'?: number;
'totalRowCount'?: number;
'items'?: Array<LoanCodAccountItem>;
};
}>) => r.body as {
'isSuccess'?: boolean;
'message'?: string;
'data'?: {
'pageNumber'?: number;
'pageSize'?: number;
'totalRowCount'?: number;
'items'?: Array<LoanCodAccountItem>;
};
})
    );
  }

  /**
   * Path part for operation insuranceAccountList
   */
  static readonly InsuranceAccountListPath = '/v1/loan-maturity/list/guarantor/insuranceAccounts';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `insuranceAccountList()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  insuranceAccountList$Response(params: {
    body: LoanAccountRequest
  }): Observable<StrictHttpResponse<{
'isSuccess'?: boolean;
'message'?: string;
'data'?: {
'pageNumber'?: number;
'pageSize'?: number;
'totalRowCount'?: number;
'items'?: Array<LoanInsuranceAccountItem>;
};
}>> {

    const rb = new RequestBuilder(this.rootUrl, ApiService.InsuranceAccountListPath, 'post');
    if (params) {
      rb.body(params.body, 'application/json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<{
        'isSuccess'?: boolean;
        'message'?: string;
        'data'?: {
        'pageNumber'?: number;
        'pageSize'?: number;
        'totalRowCount'?: number;
        'items'?: Array<LoanInsuranceAccountItem>;
        };
        }>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `insuranceAccountList$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  insuranceAccountList(params: {
    body: LoanAccountRequest
  }): Observable<{
'isSuccess'?: boolean;
'message'?: string;
'data'?: {
'pageNumber'?: number;
'pageSize'?: number;
'totalRowCount'?: number;
'items'?: Array<LoanInsuranceAccountItem>;
};
}> {

    return this.insuranceAccountList$Response(params).pipe(
      map((r: StrictHttpResponse<{
'isSuccess'?: boolean;
'message'?: string;
'data'?: {
'pageNumber'?: number;
'pageSize'?: number;
'totalRowCount'?: number;
'items'?: Array<LoanInsuranceAccountItem>;
};
}>) => r.body as {
'isSuccess'?: boolean;
'message'?: string;
'data'?: {
'pageNumber'?: number;
'pageSize'?: number;
'totalRowCount'?: number;
'items'?: Array<LoanInsuranceAccountItem>;
};
})
    );
  }

  /**
   * Path part for operation trustManagementAccountList
   */
  static readonly TrustManagementAccountListPath = '/v1/loan-maturity/list/guarantor/trustManagementAccounts';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `trustManagementAccountList()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  trustManagementAccountList$Response(params: {
    body: LoanAccountRequest
  }): Observable<StrictHttpResponse<{
'isSuccess'?: boolean;
'message'?: string;
'data'?: {
'pageNumber'?: number;
'pageSize'?: number;
'totalRowCount'?: number;
'items'?: Array<LoanTrustManagementAccountItem>;
};
}>> {

    const rb = new RequestBuilder(this.rootUrl, ApiService.TrustManagementAccountListPath, 'post');
    if (params) {
      rb.body(params.body, 'application/json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<{
        'isSuccess'?: boolean;
        'message'?: string;
        'data'?: {
        'pageNumber'?: number;
        'pageSize'?: number;
        'totalRowCount'?: number;
        'items'?: Array<LoanTrustManagementAccountItem>;
        };
        }>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `trustManagementAccountList$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  trustManagementAccountList(params: {
    body: LoanAccountRequest
  }): Observable<{
'isSuccess'?: boolean;
'message'?: string;
'data'?: {
'pageNumber'?: number;
'pageSize'?: number;
'totalRowCount'?: number;
'items'?: Array<LoanTrustManagementAccountItem>;
};
}> {

    return this.trustManagementAccountList$Response(params).pipe(
      map((r: StrictHttpResponse<{
'isSuccess'?: boolean;
'message'?: string;
'data'?: {
'pageNumber'?: number;
'pageSize'?: number;
'totalRowCount'?: number;
'items'?: Array<LoanTrustManagementAccountItem>;
};
}>) => r.body as {
'isSuccess'?: boolean;
'message'?: string;
'data'?: {
'pageNumber'?: number;
'pageSize'?: number;
'totalRowCount'?: number;
'items'?: Array<LoanTrustManagementAccountItem>;
};
})
    );
  }

  /**
   * Path part for operation wealthManagementAccountList
   */
  static readonly WealthManagementAccountListPath = '/v1/loan-maturity/list/guarantor/wealthManagementAccounts';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `wealthManagementAccountList()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  wealthManagementAccountList$Response(params: {
    body: LoanAccountRequest
  }): Observable<StrictHttpResponse<{
'isSuccess'?: boolean;
'message'?: string;
'data'?: {
'pageNumber'?: number;
'pageSize'?: number;
'totalRowCount'?: number;
'items'?: Array<LoanWealthManagementAccountItem>;
};
}>> {

    const rb = new RequestBuilder(this.rootUrl, ApiService.WealthManagementAccountListPath, 'post');
    if (params) {
      rb.body(params.body, 'application/json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<{
        'isSuccess'?: boolean;
        'message'?: string;
        'data'?: {
        'pageNumber'?: number;
        'pageSize'?: number;
        'totalRowCount'?: number;
        'items'?: Array<LoanWealthManagementAccountItem>;
        };
        }>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `wealthManagementAccountList$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  wealthManagementAccountList(params: {
    body: LoanAccountRequest
  }): Observable<{
'isSuccess'?: boolean;
'message'?: string;
'data'?: {
'pageNumber'?: number;
'pageSize'?: number;
'totalRowCount'?: number;
'items'?: Array<LoanWealthManagementAccountItem>;
};
}> {

    return this.wealthManagementAccountList$Response(params).pipe(
      map((r: StrictHttpResponse<{
'isSuccess'?: boolean;
'message'?: string;
'data'?: {
'pageNumber'?: number;
'pageSize'?: number;
'totalRowCount'?: number;
'items'?: Array<LoanWealthManagementAccountItem>;
};
}>) => r.body as {
'isSuccess'?: boolean;
'message'?: string;
'data'?: {
'pageNumber'?: number;
'pageSize'?: number;
'totalRowCount'?: number;
'items'?: Array<LoanWealthManagementAccountItem>;
};
})
    );
  }

  /**
   * Path part for operation mortgageAccountList
   */
  static readonly MortgageAccountListPath = '/v1/loan-maturity/list/guarantor/mortgageAccounts';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `mortgageAccountList()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  mortgageAccountList$Response(params: {
    body: LoanAccountRequest
  }): Observable<StrictHttpResponse<{
'isSuccess'?: boolean;
'message'?: string;
'data'?: {
'pageNumber'?: number;
'pageSize'?: number;
'totalRowCount'?: number;
'items'?: Array<LoanMortgageManagementAccountItem>;
};
}>> {

    const rb = new RequestBuilder(this.rootUrl, ApiService.MortgageAccountListPath, 'post');
    if (params) {
      rb.body(params.body, 'application/json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<{
        'isSuccess'?: boolean;
        'message'?: string;
        'data'?: {
        'pageNumber'?: number;
        'pageSize'?: number;
        'totalRowCount'?: number;
        'items'?: Array<LoanMortgageManagementAccountItem>;
        };
        }>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `mortgageAccountList$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  mortgageAccountList(params: {
    body: LoanAccountRequest
  }): Observable<{
'isSuccess'?: boolean;
'message'?: string;
'data'?: {
'pageNumber'?: number;
'pageSize'?: number;
'totalRowCount'?: number;
'items'?: Array<LoanMortgageManagementAccountItem>;
};
}> {

    return this.mortgageAccountList$Response(params).pipe(
      map((r: StrictHttpResponse<{
'isSuccess'?: boolean;
'message'?: string;
'data'?: {
'pageNumber'?: number;
'pageSize'?: number;
'totalRowCount'?: number;
'items'?: Array<LoanMortgageManagementAccountItem>;
};
}>) => r.body as {
'isSuccess'?: boolean;
'message'?: string;
'data'?: {
'pageNumber'?: number;
'pageSize'?: number;
'totalRowCount'?: number;
'items'?: Array<LoanMortgageManagementAccountItem>;
};
})
    );
  }

  /**
   * Path part for operation loanOfficerList
   */
  static readonly LoanOfficerListPath = '/v1/loan-maturity/list/loanOfficer';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `loanOfficerList()` instead.
   *
   * This method doesn't expect any request body.
   */
  loanOfficerList$Response(params?: {
  }): Observable<StrictHttpResponse<{
'isSuccess'?: boolean;
'message'?: string;
'data'?: {
'pageNumber'?: number;
'pageSize'?: number;
'totalRowCount'?: number;
'items'?: Array<LoanOfficerItem>;
};
}>> {

    const rb = new RequestBuilder(this.rootUrl, ApiService.LoanOfficerListPath, 'get');
    if (params) {
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<{
        'isSuccess'?: boolean;
        'message'?: string;
        'data'?: {
        'pageNumber'?: number;
        'pageSize'?: number;
        'totalRowCount'?: number;
        'items'?: Array<LoanOfficerItem>;
        };
        }>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `loanOfficerList$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  loanOfficerList(params?: {
  }): Observable<{
'isSuccess'?: boolean;
'message'?: string;
'data'?: {
'pageNumber'?: number;
'pageSize'?: number;
'totalRowCount'?: number;
'items'?: Array<LoanOfficerItem>;
};
}> {

    return this.loanOfficerList$Response(params).pipe(
      map((r: StrictHttpResponse<{
'isSuccess'?: boolean;
'message'?: string;
'data'?: {
'pageNumber'?: number;
'pageSize'?: number;
'totalRowCount'?: number;
'items'?: Array<LoanOfficerItem>;
};
}>) => r.body as {
'isSuccess'?: boolean;
'message'?: string;
'data'?: {
'pageNumber'?: number;
'pageSize'?: number;
'totalRowCount'?: number;
'items'?: Array<LoanOfficerItem>;
};
})
    );
  }

  /**
   * Path part for operation renewalStatusList
   */
  static readonly RenewalStatusListPath = '/v1/loan-maturity/list/renewalStatus';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `renewalStatusList()` instead.
   *
   * This method doesn't expect any request body.
   */
  renewalStatusList$Response(params?: {
  }): Observable<StrictHttpResponse<{
'isSuccess'?: boolean;
'message'?: string;
'data'?: {
'pageNumber'?: number;
'pageSize'?: number;
'totalRowCount'?: number;
'items'?: Array<LasAccountRenewalStatusItem>;
};
}>> {

    const rb = new RequestBuilder(this.rootUrl, ApiService.RenewalStatusListPath, 'get');
    if (params) {
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<{
        'isSuccess'?: boolean;
        'message'?: string;
        'data'?: {
        'pageNumber'?: number;
        'pageSize'?: number;
        'totalRowCount'?: number;
        'items'?: Array<LasAccountRenewalStatusItem>;
        };
        }>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `renewalStatusList$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  renewalStatusList(params?: {
  }): Observable<{
'isSuccess'?: boolean;
'message'?: string;
'data'?: {
'pageNumber'?: number;
'pageSize'?: number;
'totalRowCount'?: number;
'items'?: Array<LasAccountRenewalStatusItem>;
};
}> {

    return this.renewalStatusList$Response(params).pipe(
      map((r: StrictHttpResponse<{
'isSuccess'?: boolean;
'message'?: string;
'data'?: {
'pageNumber'?: number;
'pageSize'?: number;
'totalRowCount'?: number;
'items'?: Array<LasAccountRenewalStatusItem>;
};
}>) => r.body as {
'isSuccess'?: boolean;
'message'?: string;
'data'?: {
'pageNumber'?: number;
'pageSize'?: number;
'totalRowCount'?: number;
'items'?: Array<LasAccountRenewalStatusItem>;
};
})
    );
  }

  /**
   * Path part for operation loanMaturityCommentTypeList
   */
  static readonly LoanMaturityCommentTypeListPath = '/v1/loan-maturity/list/loanMaturityCommentType';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `loanMaturityCommentTypeList()` instead.
   *
   * This method doesn't expect any request body.
   */
  loanMaturityCommentTypeList$Response(params?: {
  }): Observable<StrictHttpResponse<{
'isSuccess'?: boolean;
'message'?: string;
'data'?: {
'pageNumber'?: number;
'pageSize'?: number;
'totalRowCount'?: number;
'items'?: Array<LoanMaturityCommentTypeItem>;
};
}>> {

    const rb = new RequestBuilder(this.rootUrl, ApiService.LoanMaturityCommentTypeListPath, 'get');
    if (params) {
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<{
        'isSuccess'?: boolean;
        'message'?: string;
        'data'?: {
        'pageNumber'?: number;
        'pageSize'?: number;
        'totalRowCount'?: number;
        'items'?: Array<LoanMaturityCommentTypeItem>;
        };
        }>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `loanMaturityCommentTypeList$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  loanMaturityCommentTypeList(params?: {
  }): Observable<{
'isSuccess'?: boolean;
'message'?: string;
'data'?: {
'pageNumber'?: number;
'pageSize'?: number;
'totalRowCount'?: number;
'items'?: Array<LoanMaturityCommentTypeItem>;
};
}> {

    return this.loanMaturityCommentTypeList$Response(params).pipe(
      map((r: StrictHttpResponse<{
'isSuccess'?: boolean;
'message'?: string;
'data'?: {
'pageNumber'?: number;
'pageSize'?: number;
'totalRowCount'?: number;
'items'?: Array<LoanMaturityCommentTypeItem>;
};
}>) => r.body as {
'isSuccess'?: boolean;
'message'?: string;
'data'?: {
'pageNumber'?: number;
'pageSize'?: number;
'totalRowCount'?: number;
'items'?: Array<LoanMaturityCommentTypeItem>;
};
})
    );
  }

  /**
   * Path part for operation updateRenewalStatus
   */
  static readonly UpdateRenewalStatusPath = '/v1/loan-maturity/update/renewalStatus';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `updateRenewalStatus()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  updateRenewalStatus$Response(params: {
    body: UpdateRenewalStatusRequest
  }): Observable<StrictHttpResponse<{
'isSuccess'?: boolean;
'message'?: string;
'data'?: string;
}>> {

    const rb = new RequestBuilder(this.rootUrl, ApiService.UpdateRenewalStatusPath, 'post');
    if (params) {
      rb.body(params.body, 'application/json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<{
        'isSuccess'?: boolean;
        'message'?: string;
        'data'?: string;
        }>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `updateRenewalStatus$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  updateRenewalStatus(params: {
    body: UpdateRenewalStatusRequest
  }): Observable<{
'isSuccess'?: boolean;
'message'?: string;
'data'?: string;
}> {

    return this.updateRenewalStatus$Response(params).pipe(
      map((r: StrictHttpResponse<{
'isSuccess'?: boolean;
'message'?: string;
'data'?: string;
}>) => r.body as {
'isSuccess'?: boolean;
'message'?: string;
'data'?: string;
})
    );
  }

  /**
   * Path part for operation addLoanMaturityNotes
   */
  static readonly AddLoanMaturityNotesPath = '/v1/loan-maturity/add/loanMaturityNotes';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `addLoanMaturityNotes()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  addLoanMaturityNotes$Response(params: {
    body: AddLoanMaturityNotesRequest
  }): Observable<StrictHttpResponse<{
'isSuccess'?: boolean;
'message'?: string;
'data'?: string;
}>> {

    const rb = new RequestBuilder(this.rootUrl, ApiService.AddLoanMaturityNotesPath, 'post');
    if (params) {
      rb.body(params.body, 'application/json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<{
        'isSuccess'?: boolean;
        'message'?: string;
        'data'?: string;
        }>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `addLoanMaturityNotes$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  addLoanMaturityNotes(params: {
    body: AddLoanMaturityNotesRequest
  }): Observable<{
'isSuccess'?: boolean;
'message'?: string;
'data'?: string;
}> {

    return this.addLoanMaturityNotes$Response(params).pipe(
      map((r: StrictHttpResponse<{
'isSuccess'?: boolean;
'message'?: string;
'data'?: string;
}>) => r.body as {
'isSuccess'?: boolean;
'message'?: string;
'data'?: string;
})
    );
  }

  /**
   * Path part for operation fetchLoanMaturityCommentList
   */
  static readonly FetchLoanMaturityCommentListPath = '/v1/loan-maturity/list/loanMaturityComment';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `fetchLoanMaturityCommentList()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  fetchLoanMaturityCommentList$Response(params: {
    body: EntityGetRequest
  }): Observable<StrictHttpResponse<{
'isSuccess'?: boolean;
'message'?: string;
'data'?: {
'pageNumber'?: number;
'pageSize'?: number;
'totalRowCount'?: number;
'items'?: Array<LoanMaturityCommentItem>;
};
}>> {

    const rb = new RequestBuilder(this.rootUrl, ApiService.FetchLoanMaturityCommentListPath, 'post');
    if (params) {
      rb.body(params.body, 'application/json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<{
        'isSuccess'?: boolean;
        'message'?: string;
        'data'?: {
        'pageNumber'?: number;
        'pageSize'?: number;
        'totalRowCount'?: number;
        'items'?: Array<LoanMaturityCommentItem>;
        };
        }>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `fetchLoanMaturityCommentList$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  fetchLoanMaturityCommentList(params: {
    body: EntityGetRequest
  }): Observable<{
'isSuccess'?: boolean;
'message'?: string;
'data'?: {
'pageNumber'?: number;
'pageSize'?: number;
'totalRowCount'?: number;
'items'?: Array<LoanMaturityCommentItem>;
};
}> {

    return this.fetchLoanMaturityCommentList$Response(params).pipe(
      map((r: StrictHttpResponse<{
'isSuccess'?: boolean;
'message'?: string;
'data'?: {
'pageNumber'?: number;
'pageSize'?: number;
'totalRowCount'?: number;
'items'?: Array<LoanMaturityCommentItem>;
};
}>) => r.body as {
'isSuccess'?: boolean;
'message'?: string;
'data'?: {
'pageNumber'?: number;
'pageSize'?: number;
'totalRowCount'?: number;
'items'?: Array<LoanMaturityCommentItem>;
};
})
    );
  }

  /**
   * Path part for operation deleteLoanMaturityNotes
   */
  static readonly DeleteLoanMaturityNotesPath = '/v1/loan-maturity/delete/loanMaturityNotes/{loanMaturityNotesGUID}';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `deleteLoanMaturityNotes()` instead.
   *
   * This method doesn't expect any request body.
   */
  deleteLoanMaturityNotes$Response(params: {
    loanMaturityNotesGUID: string;
  }): Observable<StrictHttpResponse<{
'isSuccess'?: boolean;
'message'?: string;
'data'?: string;
}>> {

    const rb = new RequestBuilder(this.rootUrl, ApiService.DeleteLoanMaturityNotesPath, 'post');
    if (params) {
      rb.path('loanMaturityNotesGUID', params.loanMaturityNotesGUID, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<{
        'isSuccess'?: boolean;
        'message'?: string;
        'data'?: string;
        }>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `deleteLoanMaturityNotes$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  deleteLoanMaturityNotes(params: {
    loanMaturityNotesGUID: string;
  }): Observable<{
'isSuccess'?: boolean;
'message'?: string;
'data'?: string;
}> {

    return this.deleteLoanMaturityNotes$Response(params).pipe(
      map((r: StrictHttpResponse<{
'isSuccess'?: boolean;
'message'?: string;
'data'?: string;
}>) => r.body as {
'isSuccess'?: boolean;
'message'?: string;
'data'?: string;
})
    );
  }

  /**
   * Path part for operation listManageAppointment
   */
  static readonly ListManageAppointmentPath = '/v1/manageAppointment/list/manageAppointment';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `listManageAppointment()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  listManageAppointment$Response(params: {
    body: FetchCustomerAppointmentManagerRequest
  }): Observable<StrictHttpResponse<{
'isSuccess'?: boolean;
'message'?: string;
'data'?: {
'pageNumber'?: number;
'pageSize'?: number;
'totalRowCount'?: number;
'items'?: Array<ManageAppointmentItem>;
};
}>> {

    const rb = new RequestBuilder(this.rootUrl, ApiService.ListManageAppointmentPath, 'post');
    if (params) {
      rb.body(params.body, 'application/json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<{
        'isSuccess'?: boolean;
        'message'?: string;
        'data'?: {
        'pageNumber'?: number;
        'pageSize'?: number;
        'totalRowCount'?: number;
        'items'?: Array<ManageAppointmentItem>;
        };
        }>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `listManageAppointment$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  listManageAppointment(params: {
    body: FetchCustomerAppointmentManagerRequest
  }): Observable<{
'isSuccess'?: boolean;
'message'?: string;
'data'?: {
'pageNumber'?: number;
'pageSize'?: number;
'totalRowCount'?: number;
'items'?: Array<ManageAppointmentItem>;
};
}> {

    return this.listManageAppointment$Response(params).pipe(
      map((r: StrictHttpResponse<{
'isSuccess'?: boolean;
'message'?: string;
'data'?: {
'pageNumber'?: number;
'pageSize'?: number;
'totalRowCount'?: number;
'items'?: Array<ManageAppointmentItem>;
};
}>) => r.body as {
'isSuccess'?: boolean;
'message'?: string;
'data'?: {
'pageNumber'?: number;
'pageSize'?: number;
'totalRowCount'?: number;
'items'?: Array<ManageAppointmentItem>;
};
})
    );
  }

  /**
   * Path part for operation customerAppointmentMeetingPreferenceList
   */
  static readonly CustomerAppointmentMeetingPreferenceListPath = '/v1/manageAppointment/list/customerAppointmentMeetingPreference';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `customerAppointmentMeetingPreferenceList()` instead.
   *
   * This method doesn't expect any request body.
   */
  customerAppointmentMeetingPreferenceList$Response(params?: {
  }): Observable<StrictHttpResponse<{
'isSuccess'?: boolean;
'message'?: string;
'data'?: {
'pageNumber'?: number;
'pageSize'?: number;
'totalRowCount'?: number;
'items'?: Array<CustomerAppointmentMeetingPreferenceItem>;
};
}>> {

    const rb = new RequestBuilder(this.rootUrl, ApiService.CustomerAppointmentMeetingPreferenceListPath, 'post');
    if (params) {
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<{
        'isSuccess'?: boolean;
        'message'?: string;
        'data'?: {
        'pageNumber'?: number;
        'pageSize'?: number;
        'totalRowCount'?: number;
        'items'?: Array<CustomerAppointmentMeetingPreferenceItem>;
        };
        }>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `customerAppointmentMeetingPreferenceList$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  customerAppointmentMeetingPreferenceList(params?: {
  }): Observable<{
'isSuccess'?: boolean;
'message'?: string;
'data'?: {
'pageNumber'?: number;
'pageSize'?: number;
'totalRowCount'?: number;
'items'?: Array<CustomerAppointmentMeetingPreferenceItem>;
};
}> {

    return this.customerAppointmentMeetingPreferenceList$Response(params).pipe(
      map((r: StrictHttpResponse<{
'isSuccess'?: boolean;
'message'?: string;
'data'?: {
'pageNumber'?: number;
'pageSize'?: number;
'totalRowCount'?: number;
'items'?: Array<CustomerAppointmentMeetingPreferenceItem>;
};
}>) => r.body as {
'isSuccess'?: boolean;
'message'?: string;
'data'?: {
'pageNumber'?: number;
'pageSize'?: number;
'totalRowCount'?: number;
'items'?: Array<CustomerAppointmentMeetingPreferenceItem>;
};
})
    );
  }

  /**
   * Path part for operation updateAppointmentDetails
   */
  static readonly UpdateAppointmentDetailsPath = '/v1/manageAppointment/update/appointment';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `updateAppointmentDetails()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  updateAppointmentDetails$Response(params: {
    body: ManageAppointmentItem
  }): Observable<StrictHttpResponse<{
'isSuccess'?: boolean;
'message'?: string;
'data'?: string;
}>> {

    const rb = new RequestBuilder(this.rootUrl, ApiService.UpdateAppointmentDetailsPath, 'post');
    if (params) {
      rb.body(params.body, 'application/json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<{
        'isSuccess'?: boolean;
        'message'?: string;
        'data'?: string;
        }>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `updateAppointmentDetails$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  updateAppointmentDetails(params: {
    body: ManageAppointmentItem
  }): Observable<{
'isSuccess'?: boolean;
'message'?: string;
'data'?: string;
}> {

    return this.updateAppointmentDetails$Response(params).pipe(
      map((r: StrictHttpResponse<{
'isSuccess'?: boolean;
'message'?: string;
'data'?: string;
}>) => r.body as {
'isSuccess'?: boolean;
'message'?: string;
'data'?: string;
})
    );
  }

  /**
   * Path part for operation sendConfirmationEmail
   */
  static readonly SendConfirmationEmailPath = '/v1/manageAppointment/send/confirmationEmail';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `sendConfirmationEmail()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  sendConfirmationEmail$Response(params: {
    body: CustomerAppointmentUpdateRequest
  }): Observable<StrictHttpResponse<{
'isSuccess'?: boolean;
'message'?: string;
'data'?: string;
}>> {

    const rb = new RequestBuilder(this.rootUrl, ApiService.SendConfirmationEmailPath, 'post');
    if (params) {
      rb.body(params.body, 'application/json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<{
        'isSuccess'?: boolean;
        'message'?: string;
        'data'?: string;
        }>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `sendConfirmationEmail$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  sendConfirmationEmail(params: {
    body: CustomerAppointmentUpdateRequest
  }): Observable<{
'isSuccess'?: boolean;
'message'?: string;
'data'?: string;
}> {

    return this.sendConfirmationEmail$Response(params).pipe(
      map((r: StrictHttpResponse<{
'isSuccess'?: boolean;
'message'?: string;
'data'?: string;
}>) => r.body as {
'isSuccess'?: boolean;
'message'?: string;
'data'?: string;
})
    );
  }

  /**
   * Path part for operation projectTrackerList
   */
  static readonly ProjectTrackerListPath = '/v1/projectTracker/list/projectTracker';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `projectTrackerList()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  projectTrackerList$Response(params: {
    body: EntityGetRequest
  }): Observable<StrictHttpResponse<{
'isSuccess'?: boolean;
'message'?: string;
'data'?: {
'pageNumber'?: number;
'pageSize'?: number;
'totalRowCount'?: number;
'items'?: Array<ProjectTrackerItem>;
};
}>> {

    const rb = new RequestBuilder(this.rootUrl, ApiService.ProjectTrackerListPath, 'post');
    if (params) {
      rb.body(params.body, 'application/json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<{
        'isSuccess'?: boolean;
        'message'?: string;
        'data'?: {
        'pageNumber'?: number;
        'pageSize'?: number;
        'totalRowCount'?: number;
        'items'?: Array<ProjectTrackerItem>;
        };
        }>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `projectTrackerList$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  projectTrackerList(params: {
    body: EntityGetRequest
  }): Observable<{
'isSuccess'?: boolean;
'message'?: string;
'data'?: {
'pageNumber'?: number;
'pageSize'?: number;
'totalRowCount'?: number;
'items'?: Array<ProjectTrackerItem>;
};
}> {

    return this.projectTrackerList$Response(params).pipe(
      map((r: StrictHttpResponse<{
'isSuccess'?: boolean;
'message'?: string;
'data'?: {
'pageNumber'?: number;
'pageSize'?: number;
'totalRowCount'?: number;
'items'?: Array<ProjectTrackerItem>;
};
}>) => r.body as {
'isSuccess'?: boolean;
'message'?: string;
'data'?: {
'pageNumber'?: number;
'pageSize'?: number;
'totalRowCount'?: number;
'items'?: Array<ProjectTrackerItem>;
};
})
    );
  }

  /**
   * Path part for operation projectTrackerStatusList
   */
  static readonly ProjectTrackerStatusListPath = '/v1/projectTracker/list/projectTrackerStatus';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `projectTrackerStatusList()` instead.
   *
   * This method doesn't expect any request body.
   */
  projectTrackerStatusList$Response(params?: {
  }): Observable<StrictHttpResponse<{
'isSuccess'?: boolean;
'message'?: string;
'data'?: {
'pageNumber'?: number;
'pageSize'?: number;
'totalRowCount'?: number;
'items'?: Array<ProjectTrackerStatusItem>;
};
}>> {

    const rb = new RequestBuilder(this.rootUrl, ApiService.ProjectTrackerStatusListPath, 'post');
    if (params) {
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<{
        'isSuccess'?: boolean;
        'message'?: string;
        'data'?: {
        'pageNumber'?: number;
        'pageSize'?: number;
        'totalRowCount'?: number;
        'items'?: Array<ProjectTrackerStatusItem>;
        };
        }>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `projectTrackerStatusList$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  projectTrackerStatusList(params?: {
  }): Observable<{
'isSuccess'?: boolean;
'message'?: string;
'data'?: {
'pageNumber'?: number;
'pageSize'?: number;
'totalRowCount'?: number;
'items'?: Array<ProjectTrackerStatusItem>;
};
}> {

    return this.projectTrackerStatusList$Response(params).pipe(
      map((r: StrictHttpResponse<{
'isSuccess'?: boolean;
'message'?: string;
'data'?: {
'pageNumber'?: number;
'pageSize'?: number;
'totalRowCount'?: number;
'items'?: Array<ProjectTrackerStatusItem>;
};
}>) => r.body as {
'isSuccess'?: boolean;
'message'?: string;
'data'?: {
'pageNumber'?: number;
'pageSize'?: number;
'totalRowCount'?: number;
'items'?: Array<ProjectTrackerStatusItem>;
};
})
    );
  }

  /**
   * Path part for operation projectTrackerCategoryList
   */
  static readonly ProjectTrackerCategoryListPath = '/v1/projectTracker/list/projectTrackerCategory';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `projectTrackerCategoryList()` instead.
   *
   * This method doesn't expect any request body.
   */
  projectTrackerCategoryList$Response(params?: {
  }): Observable<StrictHttpResponse<{
'isSuccess'?: boolean;
'message'?: string;
'data'?: {
'pageNumber'?: number;
'pageSize'?: number;
'totalRowCount'?: number;
'items'?: Array<ProjectTrackerCategoryItem>;
};
}>> {

    const rb = new RequestBuilder(this.rootUrl, ApiService.ProjectTrackerCategoryListPath, 'post');
    if (params) {
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<{
        'isSuccess'?: boolean;
        'message'?: string;
        'data'?: {
        'pageNumber'?: number;
        'pageSize'?: number;
        'totalRowCount'?: number;
        'items'?: Array<ProjectTrackerCategoryItem>;
        };
        }>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `projectTrackerCategoryList$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  projectTrackerCategoryList(params?: {
  }): Observable<{
'isSuccess'?: boolean;
'message'?: string;
'data'?: {
'pageNumber'?: number;
'pageSize'?: number;
'totalRowCount'?: number;
'items'?: Array<ProjectTrackerCategoryItem>;
};
}> {

    return this.projectTrackerCategoryList$Response(params).pipe(
      map((r: StrictHttpResponse<{
'isSuccess'?: boolean;
'message'?: string;
'data'?: {
'pageNumber'?: number;
'pageSize'?: number;
'totalRowCount'?: number;
'items'?: Array<ProjectTrackerCategoryItem>;
};
}>) => r.body as {
'isSuccess'?: boolean;
'message'?: string;
'data'?: {
'pageNumber'?: number;
'pageSize'?: number;
'totalRowCount'?: number;
'items'?: Array<ProjectTrackerCategoryItem>;
};
})
    );
  }

  /**
   * Path part for operation projectSave
   */
  static readonly ProjectSavePath = '/v1/projectTracker/save/project';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `projectSave()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  projectSave$Response(params: {
    body: ProjectRequest
  }): Observable<StrictHttpResponse<{
'isSuccess'?: boolean;
'message'?: string;
'data'?: string;
}>> {

    const rb = new RequestBuilder(this.rootUrl, ApiService.ProjectSavePath, 'post');
    if (params) {
      rb.body(params.body, 'application/json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<{
        'isSuccess'?: boolean;
        'message'?: string;
        'data'?: string;
        }>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `projectSave$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  projectSave(params: {
    body: ProjectRequest
  }): Observable<{
'isSuccess'?: boolean;
'message'?: string;
'data'?: string;
}> {

    return this.projectSave$Response(params).pipe(
      map((r: StrictHttpResponse<{
'isSuccess'?: boolean;
'message'?: string;
'data'?: string;
}>) => r.body as {
'isSuccess'?: boolean;
'message'?: string;
'data'?: string;
})
    );
  }

  /**
   * Path part for operation getProjectDetails
   */
  static readonly GetProjectDetailsPath = '/v1/projectTracker/fetch/project{projectGUID}';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getProjectDetails()` instead.
   *
   * This method doesn't expect any request body.
   */
  getProjectDetails$Response(params: {
    projectGUID: string;
  }): Observable<StrictHttpResponse<{
'isSuccess'?: boolean;
'message'?: string;
'data'?: ProjectTrackerItem;
}>> {

    const rb = new RequestBuilder(this.rootUrl, ApiService.GetProjectDetailsPath, 'get');
    if (params) {
      rb.path('projectGUID', params.projectGUID, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<{
        'isSuccess'?: boolean;
        'message'?: string;
        'data'?: ProjectTrackerItem;
        }>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `getProjectDetails$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getProjectDetails(params: {
    projectGUID: string;
  }): Observable<{
'isSuccess'?: boolean;
'message'?: string;
'data'?: ProjectTrackerItem;
}> {

    return this.getProjectDetails$Response(params).pipe(
      map((r: StrictHttpResponse<{
'isSuccess'?: boolean;
'message'?: string;
'data'?: ProjectTrackerItem;
}>) => r.body as {
'isSuccess'?: boolean;
'message'?: string;
'data'?: ProjectTrackerItem;
})
    );
  }

  /**
   * Path part for operation projectAttachmentAdd
   */
  static readonly ProjectAttachmentAddPath = '/v1/projectTracker/add/projectAttachment';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `projectAttachmentAdd()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  projectAttachmentAdd$Response(params: {
    body: ProjectMaterialAddRequest
  }): Observable<StrictHttpResponse<{
'isSuccess'?: boolean;
'message'?: string;
'data'?: string;
}>> {

    const rb = new RequestBuilder(this.rootUrl, ApiService.ProjectAttachmentAddPath, 'post');
    if (params) {
      rb.body(params.body, 'application/json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<{
        'isSuccess'?: boolean;
        'message'?: string;
        'data'?: string;
        }>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `projectAttachmentAdd$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  projectAttachmentAdd(params: {
    body: ProjectMaterialAddRequest
  }): Observable<{
'isSuccess'?: boolean;
'message'?: string;
'data'?: string;
}> {

    return this.projectAttachmentAdd$Response(params).pipe(
      map((r: StrictHttpResponse<{
'isSuccess'?: boolean;
'message'?: string;
'data'?: string;
}>) => r.body as {
'isSuccess'?: boolean;
'message'?: string;
'data'?: string;
})
    );
  }

  /**
   * Path part for operation projectTrackerStakeHolderRoleList
   */
  static readonly ProjectTrackerStakeHolderRoleListPath = '/v1/projectTracker/list/projectTrackerStakeHolderRole';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `projectTrackerStakeHolderRoleList()` instead.
   *
   * This method doesn't expect any request body.
   */
  projectTrackerStakeHolderRoleList$Response(params?: {
  }): Observable<StrictHttpResponse<{
'isSuccess'?: boolean;
'message'?: string;
'data'?: {
'pageNumber'?: number;
'pageSize'?: number;
'totalRowCount'?: number;
'items'?: Array<ProjectStakeholderRoleItem>;
};
}>> {

    const rb = new RequestBuilder(this.rootUrl, ApiService.ProjectTrackerStakeHolderRoleListPath, 'post');
    if (params) {
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<{
        'isSuccess'?: boolean;
        'message'?: string;
        'data'?: {
        'pageNumber'?: number;
        'pageSize'?: number;
        'totalRowCount'?: number;
        'items'?: Array<ProjectStakeholderRoleItem>;
        };
        }>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `projectTrackerStakeHolderRoleList$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  projectTrackerStakeHolderRoleList(params?: {
  }): Observable<{
'isSuccess'?: boolean;
'message'?: string;
'data'?: {
'pageNumber'?: number;
'pageSize'?: number;
'totalRowCount'?: number;
'items'?: Array<ProjectStakeholderRoleItem>;
};
}> {

    return this.projectTrackerStakeHolderRoleList$Response(params).pipe(
      map((r: StrictHttpResponse<{
'isSuccess'?: boolean;
'message'?: string;
'data'?: {
'pageNumber'?: number;
'pageSize'?: number;
'totalRowCount'?: number;
'items'?: Array<ProjectStakeholderRoleItem>;
};
}>) => r.body as {
'isSuccess'?: boolean;
'message'?: string;
'data'?: {
'pageNumber'?: number;
'pageSize'?: number;
'totalRowCount'?: number;
'items'?: Array<ProjectStakeholderRoleItem>;
};
})
    );
  }

  /**
   * Path part for operation projectStakeholderSave
   */
  static readonly ProjectStakeholderSavePath = '/v1/projectTracker/save/projectStakeholder';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `projectStakeholderSave()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  projectStakeholderSave$Response(params: {
    body: ProjectStakeholderRequest
  }): Observable<StrictHttpResponse<{
'isSuccess'?: boolean;
'message'?: string;
'data'?: string;
}>> {

    const rb = new RequestBuilder(this.rootUrl, ApiService.ProjectStakeholderSavePath, 'post');
    if (params) {
      rb.body(params.body, 'application/json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<{
        'isSuccess'?: boolean;
        'message'?: string;
        'data'?: string;
        }>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `projectStakeholderSave$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  projectStakeholderSave(params: {
    body: ProjectStakeholderRequest
  }): Observable<{
'isSuccess'?: boolean;
'message'?: string;
'data'?: string;
}> {

    return this.projectStakeholderSave$Response(params).pipe(
      map((r: StrictHttpResponse<{
'isSuccess'?: boolean;
'message'?: string;
'data'?: string;
}>) => r.body as {
'isSuccess'?: boolean;
'message'?: string;
'data'?: string;
})
    );
  }

  /**
   * Path part for operation fetchProjectStakeholderList
   */
  static readonly FetchProjectStakeholderListPath = '/v1/projectTracker/list/fetchProjectStakeholder';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `fetchProjectStakeholderList()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  fetchProjectStakeholderList$Response(params: {
    body: EntityGetRequest
  }): Observable<StrictHttpResponse<{
'isSuccess'?: boolean;
'message'?: string;
'data'?: {
'pageNumber'?: number;
'pageSize'?: number;
'totalRowCount'?: number;
'items'?: Array<ProjectStakeholderItem>;
};
}>> {

    const rb = new RequestBuilder(this.rootUrl, ApiService.FetchProjectStakeholderListPath, 'post');
    if (params) {
      rb.body(params.body, 'application/json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<{
        'isSuccess'?: boolean;
        'message'?: string;
        'data'?: {
        'pageNumber'?: number;
        'pageSize'?: number;
        'totalRowCount'?: number;
        'items'?: Array<ProjectStakeholderItem>;
        };
        }>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `fetchProjectStakeholderList$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  fetchProjectStakeholderList(params: {
    body: EntityGetRequest
  }): Observable<{
'isSuccess'?: boolean;
'message'?: string;
'data'?: {
'pageNumber'?: number;
'pageSize'?: number;
'totalRowCount'?: number;
'items'?: Array<ProjectStakeholderItem>;
};
}> {

    return this.fetchProjectStakeholderList$Response(params).pipe(
      map((r: StrictHttpResponse<{
'isSuccess'?: boolean;
'message'?: string;
'data'?: {
'pageNumber'?: number;
'pageSize'?: number;
'totalRowCount'?: number;
'items'?: Array<ProjectStakeholderItem>;
};
}>) => r.body as {
'isSuccess'?: boolean;
'message'?: string;
'data'?: {
'pageNumber'?: number;
'pageSize'?: number;
'totalRowCount'?: number;
'items'?: Array<ProjectStakeholderItem>;
};
})
    );
  }

  /**
   * Path part for operation milestoneStatusList
   */
  static readonly MilestoneStatusListPath = '/v1/projectTracker/list/milestoneStatusList';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `milestoneStatusList()` instead.
   *
   * This method doesn't expect any request body.
   */
  milestoneStatusList$Response(params?: {
  }): Observable<StrictHttpResponse<{
'isSuccess'?: boolean;
'message'?: string;
'data'?: {
'pageNumber'?: number;
'pageSize'?: number;
'totalRowCount'?: number;
'items'?: Array<ProjectMilestoneStatusItem>;
};
}>> {

    const rb = new RequestBuilder(this.rootUrl, ApiService.MilestoneStatusListPath, 'post');
    if (params) {
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<{
        'isSuccess'?: boolean;
        'message'?: string;
        'data'?: {
        'pageNumber'?: number;
        'pageSize'?: number;
        'totalRowCount'?: number;
        'items'?: Array<ProjectMilestoneStatusItem>;
        };
        }>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `milestoneStatusList$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  milestoneStatusList(params?: {
  }): Observable<{
'isSuccess'?: boolean;
'message'?: string;
'data'?: {
'pageNumber'?: number;
'pageSize'?: number;
'totalRowCount'?: number;
'items'?: Array<ProjectMilestoneStatusItem>;
};
}> {

    return this.milestoneStatusList$Response(params).pipe(
      map((r: StrictHttpResponse<{
'isSuccess'?: boolean;
'message'?: string;
'data'?: {
'pageNumber'?: number;
'pageSize'?: number;
'totalRowCount'?: number;
'items'?: Array<ProjectMilestoneStatusItem>;
};
}>) => r.body as {
'isSuccess'?: boolean;
'message'?: string;
'data'?: {
'pageNumber'?: number;
'pageSize'?: number;
'totalRowCount'?: number;
'items'?: Array<ProjectMilestoneStatusItem>;
};
})
    );
  }

  /**
   * Path part for operation fetchProjectMilestoneList
   */
  static readonly FetchProjectMilestoneListPath = '/v1/projectTracker/list/fetchProjectMilestoneList';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `fetchProjectMilestoneList()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  fetchProjectMilestoneList$Response(params: {
    body: EntityGetRequest
  }): Observable<StrictHttpResponse<{
'isSuccess'?: boolean;
'message'?: string;
'data'?: {
'pageNumber'?: number;
'pageSize'?: number;
'totalRowCount'?: number;
'items'?: Array<ProjectMilestoneItem>;
};
}>> {

    const rb = new RequestBuilder(this.rootUrl, ApiService.FetchProjectMilestoneListPath, 'post');
    if (params) {
      rb.body(params.body, 'application/json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<{
        'isSuccess'?: boolean;
        'message'?: string;
        'data'?: {
        'pageNumber'?: number;
        'pageSize'?: number;
        'totalRowCount'?: number;
        'items'?: Array<ProjectMilestoneItem>;
        };
        }>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `fetchProjectMilestoneList$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  fetchProjectMilestoneList(params: {
    body: EntityGetRequest
  }): Observable<{
'isSuccess'?: boolean;
'message'?: string;
'data'?: {
'pageNumber'?: number;
'pageSize'?: number;
'totalRowCount'?: number;
'items'?: Array<ProjectMilestoneItem>;
};
}> {

    return this.fetchProjectMilestoneList$Response(params).pipe(
      map((r: StrictHttpResponse<{
'isSuccess'?: boolean;
'message'?: string;
'data'?: {
'pageNumber'?: number;
'pageSize'?: number;
'totalRowCount'?: number;
'items'?: Array<ProjectMilestoneItem>;
};
}>) => r.body as {
'isSuccess'?: boolean;
'message'?: string;
'data'?: {
'pageNumber'?: number;
'pageSize'?: number;
'totalRowCount'?: number;
'items'?: Array<ProjectMilestoneItem>;
};
})
    );
  }

  /**
   * Path part for operation projectMilestoneSave
   */
  static readonly ProjectMilestoneSavePath = '/v1/projectTracker/save/projectMilestone';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `projectMilestoneSave()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  projectMilestoneSave$Response(params: {
    body: ProjectMilestoneRequest
  }): Observable<StrictHttpResponse<{
'isSuccess'?: boolean;
'message'?: string;
'data'?: ProjectMilestoneItem;
}>> {

    const rb = new RequestBuilder(this.rootUrl, ApiService.ProjectMilestoneSavePath, 'post');
    if (params) {
      rb.body(params.body, 'application/json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<{
        'isSuccess'?: boolean;
        'message'?: string;
        'data'?: ProjectMilestoneItem;
        }>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `projectMilestoneSave$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  projectMilestoneSave(params: {
    body: ProjectMilestoneRequest
  }): Observable<{
'isSuccess'?: boolean;
'message'?: string;
'data'?: ProjectMilestoneItem;
}> {

    return this.projectMilestoneSave$Response(params).pipe(
      map((r: StrictHttpResponse<{
'isSuccess'?: boolean;
'message'?: string;
'data'?: ProjectMilestoneItem;
}>) => r.body as {
'isSuccess'?: boolean;
'message'?: string;
'data'?: ProjectMilestoneItem;
})
    );
  }

  /**
   * Path part for operation projectStakeholderDelete
   */
  static readonly ProjectStakeholderDeletePath = '/v1/projectTracker/delete/projectStakeholder';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `projectStakeholderDelete()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  projectStakeholderDelete$Response(params: {
    body: ProjectStakeholderDeleteRequest
  }): Observable<StrictHttpResponse<{
'isSuccess'?: boolean;
'message'?: string;
'data'?: string;
}>> {

    const rb = new RequestBuilder(this.rootUrl, ApiService.ProjectStakeholderDeletePath, 'post');
    if (params) {
      rb.body(params.body, 'application/json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<{
        'isSuccess'?: boolean;
        'message'?: string;
        'data'?: string;
        }>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `projectStakeholderDelete$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  projectStakeholderDelete(params: {
    body: ProjectStakeholderDeleteRequest
  }): Observable<{
'isSuccess'?: boolean;
'message'?: string;
'data'?: string;
}> {

    return this.projectStakeholderDelete$Response(params).pipe(
      map((r: StrictHttpResponse<{
'isSuccess'?: boolean;
'message'?: string;
'data'?: string;
}>) => r.body as {
'isSuccess'?: boolean;
'message'?: string;
'data'?: string;
})
    );
  }

  /**
   * Path part for operation projectMilestoneNotesSave
   */
  static readonly ProjectMilestoneNotesSavePath = '/v1/projectTracker/save/projectMilestoneNotes';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `projectMilestoneNotesSave()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  projectMilestoneNotesSave$Response(params: {
    body: ProjectMilestoneNotesRequest
  }): Observable<StrictHttpResponse<{
'isSuccess'?: boolean;
'message'?: string;
'data'?: ProjectMilestoneNotesItem;
}>> {

    const rb = new RequestBuilder(this.rootUrl, ApiService.ProjectMilestoneNotesSavePath, 'post');
    if (params) {
      rb.body(params.body, 'application/json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<{
        'isSuccess'?: boolean;
        'message'?: string;
        'data'?: ProjectMilestoneNotesItem;
        }>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `projectMilestoneNotesSave$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  projectMilestoneNotesSave(params: {
    body: ProjectMilestoneNotesRequest
  }): Observable<{
'isSuccess'?: boolean;
'message'?: string;
'data'?: ProjectMilestoneNotesItem;
}> {

    return this.projectMilestoneNotesSave$Response(params).pipe(
      map((r: StrictHttpResponse<{
'isSuccess'?: boolean;
'message'?: string;
'data'?: ProjectMilestoneNotesItem;
}>) => r.body as {
'isSuccess'?: boolean;
'message'?: string;
'data'?: ProjectMilestoneNotesItem;
})
    );
  }

}
